import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import FooterPageTitle from '../layout/FooterPageTitle'
import Footer from '../Footer'
import Header from '../PublicHeader'

class DynamicGlobalBlend extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut} />
        <div>
          <FooterPageTitle title='DYNAMIC GLOBAL BLEND' />
          <Container>
            <Row className='terms-top-paragraph'>
              <h3>Dynamic Global Blend</h3>
            </Row>
            <Row>
              <p className='terms-text'>
                Disclaimers and Disclosures continued:
              </p>
              <p className='terms-text'>
                *Back-tested Performance Disclosure Statement- AllocateRite, LLC
                is a registered investment adviser. Information presented herein
                is for educational purposes only and does not intend to make an
                offer or solicitation for the sale or purchase of any specific
                securities, investments, or investment strategies. Investments
                involve risk and unless otherwise stated, are not guaranteed.
                The performance indicated for the model portfolios is
                back-tested. Back-tested performance is NOT an indicator of
                future actual results. There are limitations inherent in
                hypothetical results particularly that the performance results
                do not represent the results of actual trading using client
                assets, however were achieved by means of retroactive
                application of a back-tested model that was designed with the
                benefit of hindsight. The results reflect performance of a
                strategy not historically offered to investors and do NOT
                represent returns that any investor actually achieved.
                Back-tested results are calculated by the retroactive
                application of a model constructed on the basis of historical
                data and based on assumptions integral to the model which may or
                may not be testable and are subject to losses. Back-tested
                performance is developed with the benefit of hindsight and has
                inherent limitations. Specifically, back-tested results do not
                reflect actual trading, or the effect of material economic and
                market factors on the decision-making process, or the skill of
                the adviser. Since trades have not actually been executed,
                results may have under- or over-compensated for the impact, if
                any, of certain market factors, such as lack of liquidity, and
                may not reflect the impact that certain economic or market
                factors may have had on the decision-making process. Further,
                back-testing allows the security selection methodology to be
                adjusted until past returns are maximized. Actual performance
                may differ significantly from back-tested performance.
              </p>
              <p className='terms-text'>
                <span>Model Performance</span> – The performance shown
                represents only the results of AllocateRite, LLC’s model
                portfolio for the relevant time period and does not represent
                the results of actual trading of investor assets. Model
                portfolio performance is the result of the application of the
                AllocateRite proprietary investment process. Model performance
                has inherent limitations. The results are theoretical and do not
                reflect any investor’s actual experience with owning, trading or
                managing an actual investment account. Thus, the performance
                shown does not reflect the impact that material economic and
                market factors had or might have had on decision making if
                actual investor money had been managed.
              </p>
              <p className='terms-text'>
                Model portfolio performance is shown net of management fees by
                AllocateRite,LLC. Performance does not reflect the deduction of
                other fees or expenses, including but not limited to
                brokerage/trading fees, custodial fees and fees and expenses
                charged by other investment companies. Performance shown for the
                composite, as well as the benchmarks, excludes the reinvestment
                of dividends and interest on cash balances where applicable. The
                data used to calculate the model performance was obtained from
                sources deemed reliable and then organized and presented by
                AllocateRite, LLC.
              </p>
              <p className='terms-text'>
                MSCI World Index/Barclays Global Aggregate Bond Index is a
                60%:40% stock/bond portfolio index . MSCI World Index is a broad
                equity index that represents large and mid-cap equity
                performance across all 23 developed markets’ countries. Barclays
                Global Aggregate Index is a flagship measure of global
                investment grade debt from 25 local currency markets. The
                multi-currency benchmark includes treasury, government related,
                corporate and securitized fixed rate bonds from developed and
                emerging market issuers. The 60:40 blended index is rebalanced
                monthly. The SPX/Barclays Index is a blend composted of 60%
                Standard & Poor's 500 Index (S&P) and 40% Bloomberg Barclays
                U.S. Aggregate Bond Index (BC Agg) and assumes monthly
                reblancing. The S&P is regarded as the standard for measuring
                large-cap U.S. stock market performance. The BC Agg is an
                unmanaged Index that is widley regarded as the standard for
                measuring U.S. Investment grade bond market performance.
              </p>
              <p className='terms-text'>
                The performance calculations have not been audited by any third
                party. Actual performance of client portfolios may differ
                materially due to the timing related to additional client
                deposits or withdrawals and the actual deployment and investment
                of a client portfolio, the reinvestment of dividends, the length
                of time that various positions are held, the client’s objectives
                and restrictions, and fees and expenses incurred by any specific
                individual portfolio.
              </p>
              <p className='terms-text'>
                The indices/funds used by AllocateRite, LLC have not been
                selected to represent an appropriate benchmark to compare an
                investor’s performance, but rather are disclosed to allow for
                comparison of the investor’s performance to that of certain
                well-known and widely recognized indices/funds. Indices are
                typically not available for direct investment, are unmanaged and
                do not incur fees or expenses.
              </p>
              <p className='terms-text'>
                The results do not represent actual trading and actual results
                may significantly differ from the theoretical results presented.
                Past performance is not indicative of future performance.
              </p>
              <p className='terms-text'>
                Any information you receive from AllocateRite is believed to be
                accurate. Nevertheless, neither AllocateRite nor its agents are
                liable for any deficiencies in the accuracy, completeness,
                availability or timeliness of such information. The information
                provided on this document does not necessarily reflect the most
                up to date or current information available on the product or
                service. The information contained herein is provided without
                any warranty of any kind. All the information above cannot be
                transmitted without AllocateRite’s consent. All the above
                materials are for information purposes only.
              </p>
              <p className='terms-text'>
                <span>Actual Performance</span> – Any performance shown for the
                relevant time periods is based upon composite results of
                AllocateRite’s Global Dynamic Blend portfolios. Portfolio
                performance is the result of the application of the
                AllocateRite, LLC’s investment process. The composite
                incorporates the relevant accounts managed by AllocateRite, LLC.
              </p>
              <p className='terms-text'>
                Portfolio performance is shown net of the advisory fees of
                0.50%, the highest fee charged by AllocateRite, LLC and trading
                costs charged by their Custodian’s TD Ameritrade, Fidelity, etc.
                Performance does not reflect the deduction of other fees or
                expenses, including but not limited to brokerage fees, custodial
                fees and fees and expenses charged by other investment
                companies. Performance results shown include the reinvestment of
                dividends and interest on cash balances where applicable. The
                data used to calculate the portfolio performance was obtained
                from sources deemed reliable and then organized and presented by
                AllocateRite, LLC.
              </p>
              <p className='terms-text'>
                Actual performance of client portfolios may differ materially
                due to the timing related to additional client deposits or
                withdrawals and the actual deployment and investment of a client
                portfolio, the reinvestment of dividends, the length of time
                that various positions are held, the client’s objectives and
                restrictions, and fees and expenses incurred by any specific
                individual portfolio.
              </p>
            </Row>
            <Row className='page-blank-row' />
          </Container>
          <Footer t={this.props.t} />
        </div>
      </div>
    )
  }
}

export default DynamicGlobalBlend
