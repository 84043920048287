import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { searchTicker } from '../../services/generalService'
import '../../scss/components/CreateWatchlist.scss'
import Loader from './accountInfo/Loader'
import { addToWatchlist } from '../../services/userServices'

export default function CreateWatchlist(props) {
  const [name, setName] = useState('')
  const [stocks, setStocks] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (searchText) {
      fetchResults()
    } else {
      setSearchResults([])
    }
  }, [searchText])

  const fetchResults = async () => {
    setTimeout(async () => {
      const res = await searchTicker(searchText)
      setSearchResults(res)
    }, 300)
  }

  const validateName = () => {
    return !props.names.includes(name)
  }

  const validateForm = () => {
    if (name) {
      if (stocks && stocks.length > 0) {
        if (validateName()) {
          createWatchlist()
        } else {
          setErrorMsg(props.t('watchlistwiththisnamealreadyexists'))
        }
      } else {
        setErrorMsg(props.t('pleaseselectatleastonesymbol'))
      }
    } else {
      setErrorMsg(props.t('pleaseenternameofwatchlist'))
    }
  }

  const createWatchlist = async () => {
    setLoading(true)
    setErrorMsg('')
    const res = await addToWatchlist(
      [name],
      stocks,
      props.user.idToken.jwtToken
    )
    if (res) {
      await props.refreshWl()
      setSuccessMsg(props.t('watchlistcreatesuccessfully'))
    } else {
      setErrorMsg(props.t('somethingwentwrong'))
    }
    setLoading(false)
  }

  if (loading) {
    return <Loader isSmallScreen={props.isSmallScreen} logoLoader={false} />
  } else {
    return (
      <div className='create-watchlist'>
        <div>
          <div className='name-container'>
            <p>{props.t('nameofwatchlist')}</p>
            <InputText
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={props.t('entername')}
            />
          </div>
          <div className='search-container'>
            <p>{props.t('searchbysymbol')}</p>
            <InputText
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={props.t('entersymbol')}
            />
            <div className='results-container'>
              {searchResults?.map((item) => {
                return (
                  <div
                    className='result-item'
                    onClick={() => {
                      setSearchText('')
                      setStocks((prevState) => [...prevState, item.symbol])
                    }}
                    key={item.symbol}
                  >
                    <p className='ticker'>{`${item.company} (${item.symbol})`}</p>
                  </div>
                )
              })}
            </div>
            <div className='selected-stocks'>
              {stocks.map((stock) => {
                return (
                  <div className='selected-stock' key={stock}>
                    {stock}
                    <p
                      className='close-btn'
                      onClick={() => {
                        setStocks((prevState) => {
                          return prevState.filter((item) => item !== stock)
                        })
                      }}
                    >
                      X
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='create-button-container'>
          <button
            className='create-button'
            onClick={() => {
              validateForm()
            }}
          >
            {props.t('submit')}
          </button>
          {successMsg && <p className='success'>{successMsg}</p>}
          {errorMsg && <p className='error'>{errorMsg}</p>}
        </div>
      </div>
    )
  }
}
