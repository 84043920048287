//REACT
import React from 'react'
import { useEffect } from 'react'
import { useState, createContext } from 'react'

//SCSS
import '../../../scss/pages/ProductPage.scss'

//COMPONENTS
import MainNavBar from '../../components/MainNavBar'
import PublicFooter from '../../PublicFooter'
import AssetsIncome from './AssetsIncome'
import AutomateTrading from './AutomateTrading'
import BuyFractionalShares from './BuyFractionalShares'
import DownloadUpgrade from './DownloadUpgrade'
import GetInsights from './GetInsights'
import GetPersonalizedDashboard from './GetPersonalizedDashboard'
import InvestallRobo from './InvestallRobo'
import KnowWorth from './KnowWorth'
import UpgradeTools from './UpgradeTools'

export const ProductPageContext = createContext()

export default function ProductPage(props) {
  if (props.t) {
    return (
      <>
        <MainNavBar {...props} colorChange={true} />
        <ProductPageContext.Provider
          value={{
            t: props.t,
            isSmallScreen: props.isSmallScreen,
            user: props.user,
          }}
        >
          <div className='product-page'>
            <GetPersonalizedDashboard />
            <GetInsights />
            <AssetsIncome />
            <KnowWorth />
            <BuyFractionalShares />
            <AutomateTrading />
            <InvestallRobo />
            <UpgradeTools />
            <DownloadUpgrade />
            <div className='disclaimer-section-background'>
              <div className='disclaimer-section'>
                <p>*</p>
                <p>{props.t('subscription_disclaimer')}</p>
              </div>
            </div>
          </div>
          <PublicFooter t={props.t} isSmallScreen={props.isSmallScreen} />
        </ProductPageContext.Provider>
      </>
    )
  } else {
    return null
  }
}
