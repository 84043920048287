import React, { useState } from 'react'
import '../scss/components/PasswordInput.scss'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export default function PasswordInput({
  password,
  setPassword,
  className,
  placeHolder,
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className='password-container'>
      <input
        placeholder={placeHolder}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        type={showPassword ? 'text' : 'password'}
        className={className}
      />
      {showPassword ? (
        <FaEyeSlash
          className='password-toggle-icon'
          onClick={() => {
            setShowPassword(false)
          }}
        />
      ) : (
        <FaEye
          className='password-toggle-icon'
          onClick={() => {
            setShowPassword(true)
          }}
        />
      )}
    </div>
  )
}
