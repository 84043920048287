import React from 'react'

export default function ForesciteDisclosures() {
  return (
    <div className='mobile-disclosure-main-conatiner'>
      <p className='title'>ForeSCITE Disclosures</p>
      <ul>
        <li>
          <a
            href='/Legal/ForeSCITE-Investment-Advisory-Agreement.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investment Advisory Agreement
          </a>
        </li>
      </ul>
    </div>
  )
}
