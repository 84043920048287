import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/goals/GoalsActivation.scss'
import Loader from '../../Loader'
import { Button } from 'primereact/button'
import GoalInvestments from './GoalInvestments'
import GoalResults from './GoalResults'
import GoalSummary from './GoalSummary'
import ChooseGoal from './ChooseGoal'
import { TailSpin } from 'react-loader-spinner'
import ProductActivationBackIcon from '../../../../../assets/product-activation-back-icon.svg'
export default class GoalsActivation extends Component {
  state = {
    pageHistory: [],

    //Choose Goal
    selectedGoal: null,

    //Goal Investments
    initialAmount: Math.max(
      500,
      Math.floor(this.props.selectedAccount.balances[0].virtualAccountValue)
    ),
    goalAmount:
      Math.max(
        500,
        Math.floor(this.props.selectedAccount.balances[0].virtualAccountValue)
      ) * 2,
    yearsAccumulate: 3,
    yearsOptions: [],

    //Goal Results
    selectedStrategy: null,
  }

  addHistory = (page) => {
    let pageHistory = this.state.pageHistory
    pageHistory.push(page)
    this.setState({
      pageHistory: pageHistory,
    })
  }

  goPrevious = () => {
    let pageHistory = this.state.pageHistory
    pageHistory.pop()
    this.setState({
      pageHistory: pageHistory,
    })
  }

  showCurrentPage = () => {
    switch (this.state.pageHistory[this.state.pageHistory.length - 1]) {
      case 'Goal Investments':
        return (
          <GoalInvestments
            {...this.props}
            {...this.state}
            handleInitialAmountChange={this.handleInitialAmountChange}
            handleGoalAmountChange={this.handleGoalAmountChange}
            handleYearsChange={this.handleYearsChange}
            addHistory={this.addHistory}
          />
        )
      case 'Goal Results':
        return (
          <GoalResults
            {...this.props}
            {...this.state}
            handleStrategyChange={this.handleStrategyChange}
            addHistory={this.addHistory}
          />
        )
      case 'Goal Summary':
        return <GoalSummary {...this.props} {...this.state} />
      default:
        return (
          <ChooseGoal {...this.props} {...this.state} setGoal={this.setGoal} />
        )
    }
  }

  //Choose Goal
  setGoal = (goal) => {
    let newState = { selectedGoal: goal }
    if (goal === 'Short') {
      newState.yearsAccumulate = 3
      newState.yearsOptions = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
      ]
    } else if (goal === 'Medium') {
      newState.yearsAccumulate = 5
      newState.yearsOptions = [
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
      ]
    } else {
      newState.yearsAccumulate = 10
      newState.yearsOptions = [
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
      ]
    }
    this.setState(newState)
    this.addHistory('Goal Investments')
  }

  //Goal Investments
  handleInitialAmountChange = (value) => {
    this.setState({ initialAmount: value })
  }

  handleGoalAmountChange = (value) => {
    this.setState({ goalAmount: value })
  }

  handleYearsChange = (value) => {
    this.setState({ yearsAccumulate: value })
  }

  //Goal Results
  handleStrategyChange = (value) => {
    // try {
    //   value = parseInt(value)
    //   this.setState({selectedStrategy: value})
    // } catch (error) {
    //   console.log(error)
    // }
    // console.log(typeof(value))
    this.setState({ selectedStrategy: value })
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          {/* <Button
          label="CANCEL ACTIVATION"
          onClick={() => {
            this.props.toggleActivation();
          }}
        /> */}
          <img
            src={ProductActivationBackIcon}
            height={25}
            width={25}
            onClick={() => {
              if (this.state.pageHistory.length > 0) {
                this.goPrevious()
              } else {
                this.props.toggleActivation()
              }
            }}
            style={{ cursor: 'pointer', marginTop: '25px' }}
          />
          {/* {this.state.pageHistory.length > 0 ? (
          <Button
            label="PREVIOUS"
            onClick={() => {
              this.goPrevious();
            }}
          />
        ) : null} */}
          {this.props.productInstanceData && this.props.strategyInfo ? (
            <div className='goals-activation-container'>
              {this.showCurrentPage()}
            </div>
          ) : (
            <div className='loader'>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    } else return null
  }
}
