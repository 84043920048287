import '../scss/components/ApexKYC.scss'
import { FileUpload } from 'primereact/fileupload'
import { Dropdown } from 'primereact/dropdown'
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import Endpoint from './Endpoint'
import Axios from 'axios'
export default function ApexKYC(props) {
  const [frontDocument, setFrontDocument] = useState(null)
  const [backDocument, setBackDocument] = useState(null)
  const [documentType, setDocumentType] = useState('')
  const [loading, setLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const fileTypeOptions = [
    {
      label: 'Drivers License',
      value: 'DRIVERS_LICENSE',
    },
    {
      label: 'State ID Card',
      value: 'STATE_ID_CARD',
    },
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'Military ID',
      value: 'MILITARY_ID',
    },
    {
      label: 'SSN Card',
      value: 'SSN_CARD',
    },
    {
      label: 'SSA Letter',
      value: 'SSA_LETTER',
    },
    {
      label: 'IRS Itin. Letter',
      value: 'IRS_ITIN_LETTER',
    },
    {
      label: 'Other Government ID',
      value: 'OTHER_GOVERNMENT_ID',
    },
    {
      label: 'CDD Document',
      value: 'CDD_DOCUMENT',
    },
    {
      label: 'All Passing CIP Results',
      value: 'ALL_PASSING_CIP_RESULTS',
    },
  ]

  const uploadFrontFile = async ({ files }) => {
    setErrorMsg('')
    if (files.length > 1) {
      files.pop()
      setErrorMsg(props.t('pleaseremovepreviousimagetouploadanotherone'))
    } else {
      setFrontDocument(files[0])
    }
  }
  const uploadBackFile = async ({ files }) => {
    setErrorMsg('')
    if (files.length > 1) {
      files.pop()
      setErrorMsg(props.t('pleaseremovepreviousimagetouploadanotherone'))
    } else {
      setBackDocument(files[0])
    }
  }
  const submitDocuments = async () => {
    setLoading(true)
    setErrorMsg('')
    setSuccessMsg('')
    const formData = new FormData()
    formData.append('file', frontDocument)
    let metaData = JSON.stringify({
      correspondent: 'FCTE',
      documentType: documentType,
      tag: 'ID_DOCUMENT',
      account:
        props.apexKYCunapprovedIds[props.apexKYCunapprovedIds?.length - 1].id,
    })
    formData.append('metadata', metaData)
    // `${Endpoint}/apex-crypto/upload/kyc/documents`
    await Axios.post(`${Endpoint}/apex-cp/upload/kyc/documents`, formData, {
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res?.data?.snapId) {
          setSuccessMsg(props.t('documentupdatesuccessfully'))
          let status = props.getAllAccountInfoV2()
          if (status) {
            setLoading(false)
            props.getAllAccountInfoV2()
          }
        } else {
          setLoading(false)
          setErrorMsg(props.t('failedtosenddocument'))
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setErrorMsg(props.t('failedtosenddocument'))
      })
  }

  if (props.t) {
    return (
      <div className='apex-kyc-container'>
        {props.apexKYCunapprovedIds?.length === 0 && (
          <p className='kyc-status-message'>{props.t('kycapproved')}</p>
        )}
        <p className='apex-kyc-header'>{props.t('selectdocumenttype')}</p>
        <div className='file-options-container'>
          {/* <p className="file-options-header">Select file type</p> */}
          <Dropdown
            value={documentType}
            onChange={(e) => {
              setDocumentType(e.target.value)
            }}
            placeholder={props.t('documenttype')}
            options={fileTypeOptions}
          />
        </div>
        <div className='file-upload-container'>
          <FileUpload
            name='file'
            customUpload={true}
            uploadHandler={uploadFrontFile}
            auto
            mode='advanced'
            chooseLabel={props.t('uploadfrontside')}
            accept='.jpg,.png'
          />
        </div>
        <div className='apex-kyc-submit-container'>
          <Button
            label={props.t('submit')}
            className='apex-kyc-submit-btn'
            onClick={() => {
              submitDocuments()
            }}
            loading={loading}
            disabled={props.apexKYCunapprovedIds?.length === 0}
          />
        </div>
        <p className='error-msg'>{errorMsg}</p>
        <p className='success-msg'>{successMsg}</p>
      </div>
    )
  } else return null
}
