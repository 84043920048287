import React, { Component } from 'react'

export default class OrderHistory extends Component {
  state = {
    showAllOrderHistory: false,
  }

  displayOrderHistory() {
    const orderHistoryList = [
      ...this.props.accountTransactionsData.accountOrderHistory.orderHistory,
    ]
    // orderHistoryList.reverse()
    if (orderHistoryList && orderHistoryList.length > 0) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            height: '100%',
          }}
        >
          {/* <p
            style={{
              textAlign: "center",
              fontFamily: "Akkurat-Bold",
              fontSize: "15px",
            }}
          >
            Order History
          </p> */}
          <div style={{}}>
            {orderHistoryList.map((order, index) => {
              return this.displayOrderHistoryRow(order, index)
            })}
          </div>
        </div>
      )
    } else return <p>{this.props.t('noorderhistory')}</p>
    // if (orderHistoryList.length > 0) {
    //   return (
    //     <div
    //       style={{
    //         width: this.props.isSmallScreen ? "100%" : "100%",
    //         padding: '50px 0'
    //       }}
    //     >
    //       <p
    //         style={{
    //           textAlign: "center",
    //           fontWeight: "bold",
    //           fontSize: "15px",
    //         }}
    //       >
    //         Order History
    //       </p>
    //       <div
    //         style={{
    //           padding: "20px",
    //           boxShadow:
    //             "-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)",
    //           borderRadius: "19px",
    //         }}
    //       >
    //         <div
    //           style={{ width: "100%", display: "flex", marginBottom: "15px" }}
    //         ></div>
    //         {orderHistoryList.length > 5 ? (
    //           <div>
    //             {this.state.showAllOrderHistory ? (
    //               <div>
    //                 {orderHistoryList.map((order) => {
    //                   return this.displayOrderHistoryRow(order);
    //                 })}
    //               </div>
    //             ) : (
    //               <div>
    //                 {orderHistoryList.map((order, index) => {
    //                   if (index < 5) return this.displayOrderHistoryRow(order);
    //                 })}
    //               </div>
    //             )}
    //           </div>
    //         ) : (
    //           orderHistoryList.map((order) => {
    //             return this.displayOrderHistoryRow(order);
    //           })
    //         )}
    //       </div>
    //       {orderHistoryList.length > 5 ? (
    //         <div>
    //           {this.state.showAllOrderHistory ? (
    //             <button
    //               style={{
    //                 width: "100%",
    //                 borderRadius: "0 0 25px 25px",
    //                 background: "black",
    //                 color: "grey",
    //                 padding: "8px",
    //               }}
    //               onClick={() => {
    //                 this.setState({ showAllOrderHistory: false });
    //               }}
    //             >
    //               Show Less
    //             </button>
    //           ) : (
    //             <button
    //               style={{
    //                 width: "100%",
    //                 borderRadius: "0 0 25px 25px",
    //                 background: "black",
    //                 color: "grey",
    //                 padding: "8px",
    //               }}
    //               onClick={() => {
    //                 this.setState({ showAllOrderHistory: true });
    //               }}
    //             >
    //               Show More
    //             </button>
    //           )}
    //         </div>
    //       ) : null}
    //     </div>
    //   );
    // } else
    //   return (
    //     <div
    //       style={{
    //         width: this.props.isSmallScreen ? "100%" : "30%",
    //         height: 250,
    //         boxShadow: "0px 5px 15px 0px #c4c4c4",
    //         borderRadius: "25px",
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         justifyItems: "center",
    //       }}
    //     >
    //       <p
    //         style={{
    //           textAlign: "center",
    //           fontWeight: "bold",
    //           fontSize: "25px",
    //         }}
    //       >
    //         No Order History
    //       </p>
    //     </div>
    //   );
  }

  displayOrderHistoryRow(order, index) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
        key={index}
      >
        <div
          style={{
            alignContent: 'center',
            width: '50%',
            wordWrap: 'break-word',
          }}
        >
          <p
            style={{
              color: order.side === 'BUY' ? '#3F46F6' : '#3D5062',
              fontSize: '15px',
              marginBottom: '0',
            }}
          >
            {this.props.t(order.type.toLowerCase())}
          </p>
          <p
            style={{
              marginBottom: '0',
              marginRight: '0',
              fontSize: '15px',
            }}
          >
            {order.name}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
            }}
          >
            {order.createdOn}
          </p>
        </div>
        <div style={{ alignContent: 'center' }}>
          <p style={{ fontSize: '15px', marginBottom: '0' }}>
            {this.props.formatCurrency(Math.abs(order.transactionAmount))}
          </p>
          <p
            style={{
              fontSize: '10px',
              color: 'rgba(128,128,128,.8)',
              textAlign: 'right',
            }}
          >
            {this.props.t('marketvalue')}
          </p>
        </div>
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return <div style={{ height: '100%' }}>{this.displayOrderHistory()}</div>
    } else return null
  }
}
