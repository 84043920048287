//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import RightArrow from '../../assets/right-arrow.png'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

//STYLES
import '../../scss/components/budgeting/NotificationsCard.scss'

export default function NotificationsCard() {
  const { t, budgetingData, isSmallScreen, setShowDisclaimer } =
    useContext(BudgetingContext)

  const notification = budgetingData?.payload?.notifications[0]

  return (
    <div className='shadow-card notifications-space-top'>
      <div className='notifications-circle'>
        <p className='notifications-count'>
          {budgetingData?.payload?.notifications?.length}
        </p>
      </div>
      <div className='notifications-card'>
        <p className='notifications-card-header'>{notification?.type}</p>
        <p className='notifications-card-info'>{notification?.content}</p>
        <p className='notifications-card-info'>
          {formatCurrency(notification?.amount)}
        </p>
        <div className='budgeting-row'>
          <div></div>
          <img src={RightArrow} className='right-arrow' />
        </div>
      </div>
    </div>
  )
}
