import React from "react";

export default function DWDisclosures() {
  return (
    <div className='mobile-disclosure-main-conatiner'>
      <p className='title'>DriveWealth Disclosures</p>
      <ul>
        <li>
          <a
            href='https://apps.drivewealth.com/disclosures/?extendedHours=true'
            target='_blank'
            rel='noopener noreferrer'
          >
            Disclosures
          </a>
        </li>
        <li>
          <a
            href='https://legal.drivewealth.com/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            href='https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true'
            target='_blank'
            rel='noopener noreferrer'
          >
            U.S. Citizen Disclosures
          </a>
        </li>
        <li>
          <a
            href='https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true&citizenship=false'
            target='_blank'
            rel='noopener noreferrer'
          >
            Non U.S. Citizen Disclosures
          </a>
        </li>
      </ul>
    </div>
  )
}
