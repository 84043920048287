import React, { Component } from 'react'
import axios from 'axios'
import '../../scss/pages/cp3/Analysis.scss'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'
import formatCurrency from '../../utility-functions/formatCurrency'
import SearchEndpoint from '../SearchEndpoint'
import BalanceHistoryGraph from '../components/BalanceHistoryGraph'
import moment from 'moment'

export default class Analysis extends Component {
  state = {
    activeTab: 'standard',
    graphData3M: null,
    graphLoading: false,
    graphIndicatorsStandard: [],
    graphIndicatorsFibonacci: [],
    min: null,
    max: null,
  }

  componentDidMount() {
    this.getGraphata('3M')
  }

  getGraphata = async (period) => {
    this.setState({ graphLoading: true })
    await axios
      .get(`${SearchEndpoint}/alpha/price`, {
        params: {
          ticker: this.props.symbol,
          period: period,
        },
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const { min, max } = this.getMinMax(response?.data)
        const graphIndicatorsFibonacci = this.getGraphIndicatorsFibonacci(
          min,
          max
        )
        const graphIndicatorsStandard = this.getGraphIndicatorsStandard()
        this.setState({
          graphData3M: Object.assign({}, response.data),
          graphLoading: false,
          min: min,
          max: max,
          graphIndicatorsFibonacci: graphIndicatorsFibonacci,
          graphIndicatorsStandard: graphIndicatorsStandard,
        })
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setState({ graphLoading: false })
      })
  }

  getConfig() {
    let rawData = this.state.graphData3M?.prices
    let coordinates = []

    for (let i = 0; i < rawData?.length; i++) {
      if (rawData[i].length >= 2) {
        coordinates.push({
          x: moment(rawData[i][0] * 1000).format('MMM DD'),
          Price: parseFloat(rawData[i][1]),
        })
      }
    }
    return coordinates
  }

  bearOrBull = (stat) => {
    switch (stat) {
      case 'rsi':
        if (parseFloat(this.props.selectedStock.rsi_9_value) < 20.0) {
          return 'Bearish'
        } else if (parseFloat(this.props.selectedStock.rsi_9_value) > 80.0) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'ema':
        if (this.props.selectedStock.ema_3 < this.props.selectedStock.ema_10) {
          return 'Bearish'
        } else if (
          this.props.selectedStock.ema_3 > this.props.selectedStock.ema_10
        ) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'cmf':
        if (this.props.selectedStock.cmf_21 < -0.1) return 'Bearish'
        else if (this.props.selectedStock.cmf_21 > 0.1) return 'Bullish'
        else return 'Neutral'
      default:
        return 'Neutral'
    }
  }

  getStandardTable = () => {
    const s1 = this.props.selectedStock?.s1
    const s2 = this.props.selectedStock?.s2
    const s3 = this.props.selectedStock?.bollinger_down
    const r1 = this.props.selectedStock?.r1
    const r2 = this.props.selectedStock?.r2
    const r3 = this.props.selectedStock?.bollinger_up
    return (
      <div className='technical-levels-table'>
        <div className='technical-levels-left'>
          <p className='technical-levels-table-header'>
            {this.props.t('support')}
          </p>
          {s1 > s2 && (
            <div className='technical-levels-table-row'>
              <p className='technical-levels-row-value'>S1</p>
              <p className='technical-levels-row-value'>{formatCurrency(s1)}</p>
            </div>
          )}
          {s2 > s3 && (
            <div className='technical-levels-table-row'>
              <p className='technical-levels-row-value'>S2</p>
              <p className='technical-levels-row-value'>{formatCurrency(s2)}</p>
            </div>
          )}
          <div className='technical-levels-table-row'>
            <p className='technical-levels-row-value'>S3</p>
            <p className='technical-levels-row-value'>{formatCurrency(s3)}</p>
          </div>
        </div>
        <div className='vertical-line'></div>
        <div className='technical-levels-right'>
          <p className='technical-levels-table-header'>
            {this.props.t('resistance')}
          </p>
          {r1 < r2 && (
            <div className='technical-levels-table-row'>
              <p className='technical-levels-row-value'>R1</p>
              <p className='technical-levels-row-value'>{formatCurrency(r1)}</p>
            </div>
          )}
          {r2 < r3 && (
            <div className='technical-levels-table-row'>
              <p className='technical-levels-row-value'>R2</p>
              <p className='technical-levels-row-value'>{formatCurrency(r2)}</p>
            </div>
          )}
          <div className='technical-levels-table-row'>
            <p className='technical-levels-row-value'>R3</p>
            <p className='technical-levels-row-value'>{formatCurrency(r3)}</p>
          </div>
        </div>
      </div>
    )
  }

  getFibonacciTable = () => {
    // const { min, max } = this.getMinMax()
    const price = this.props.selectedStock?.price
    // const graphIndicators = this.getGraphIndicators(min, max)
    if (this.state.graphIndicatorsFibonacci) {
      return (
        <div className='technical-levels-table'>
          <div className='technical-levels-left'>
            <p className='technical-levels-table-header'>
              {this.props.t('support')}
            </p>
            {this.state.graphIndicatorsFibonacci?.map(
              ([label, value], index) => {
                if (value <= price) {
                  return (
                    <div className='technical-levels-table-row' key={index}>
                      <p className='technical-levels-row-value'>{label}</p>
                      <p className='technical-levels-row-value'>
                        {formatCurrency(value)}
                      </p>
                    </div>
                  )
                }
              }
            )}
          </div>
          <div className='vertical-line'></div>
          <div className='technical-levels-right'>
            <p className='technical-levels-table-header'>
              {this.props.t('resistance')}
            </p>
            {this.state.graphIndicatorsFibonacci?.map(
              ([label, value], index) => {
                if (value > price) {
                  return (
                    <div className='technical-levels-table-row' key={index}>
                      <p className='technical-levels-row-value'>{label}</p>
                      <p className='technical-levels-row-value'>
                        {formatCurrency(value)}
                      </p>
                    </div>
                  )
                }
              }
            )}
          </div>
        </div>
      )
    }
  }

  getGraphIndicatorsStandard = () => {
    const s1 = this.props.selectedStock?.s1
    const s2 = this.props.selectedStock?.s2
    const s3 = this.props.selectedStock?.bollinger_down
    const r1 = this.props.selectedStock?.r1
    const r2 = this.props.selectedStock?.r2
    const r3 = this.props.selectedStock?.bollinger_up
    const graphIndicatorsStandard = []
    if (s1 > s2) {
      graphIndicatorsStandard.push(['s1', s1])
    }
    if (s2 > s3) {
      graphIndicatorsStandard.push(['s2', s2])
    }
    graphIndicatorsStandard.push(['s3', s3])
    if (r1 < r2) {
      graphIndicatorsStandard.push(['r1', r1])
    }
    if (r2 < r3) {
      graphIndicatorsStandard.push(['r2', r2])
    }
    graphIndicatorsStandard.push(['r3', r3])
    return graphIndicatorsStandard
  }

  getGraphIndicatorsFibonacci = (min, max) => {
    const graphIndicatorsFibonacci = []
    const percentages = [0, 23.6, 38.2, 61.8, 76.4, 100]
    const diff = Math.abs(min - max)

    for (let i = 0; i < percentages.length; i++) {
      const val = (percentages[i] / 100) * diff
      const curr = [`${percentages[i]}%`, val + min]
      graphIndicatorsFibonacci.push(curr)
    }

    return graphIndicatorsFibonacci
  }

  getMinMax = (graphData3M) => {
    let min = Number.MAX_VALUE
    let max = Number.MIN_VALUE
    if (graphData3M) {
      for (let i = 0; i < graphData3M?.prices?.length; i++) {
        const price = graphData3M?.prices[i][1]
        min = Math.min(min, price)
        max = Math.max(max, price)
      }
    }

    return { min, max }
  }

  render() {
    let stockTrend = this.props.selectedStock?.trend
      ? this.props.selectedStock.trend.charAt(0).toUpperCase() +
        this.props.selectedStock.trend.slice(1)
      : '-'
    let realitveBearOrBull = this.bearOrBull('rsi')
      ? this.bearOrBull('rsi')
      : '-'
    let expBearOrBull = this.bearOrBull('ema') ? this.bearOrBull('ema') : '-'
    let chalkinBearOrBull = this.bearOrBull('cmf')
      ? this.bearOrBull('cmf')
      : '-'
    if (this.props.t) {
      return (
        <div className='analysis-container'>
          <p className='analysis-sub-header'>
            {this.props.t('technicalanalysis')}
          </p>
          <div className='analysis-header'>
            <p className='header-label'>{this.props.t('currenttrend')}:</p>
            <p
              className={`header-value ${
                stockTrend === 'Bullish'
                  ? 'positive'
                  : stockTrend === 'Bearish'
                  ? 'negative'
                  : 'neutral'
              }`}
            >
              {this.props.t(stockTrend.toLowerCase())}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label bold'>
              {this.props.t('realtiveindexstrength')}
            </p>
            <p
              className={`analysis-value ${
                realitveBearOrBull === 'Bullish'
                  ? 'positive'
                  : realitveBearOrBull === 'Bearish'
                  ? 'negative'
                  : 'neutral'
              }`}
            >
              {/* {this.bearOrBull("rsi") ? this.bearOrBull("rsi") : "-"} */}
              {this.props.t(realitveBearOrBull.toLowerCase())}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label shift'>
              9 {this.props.t('day')} {this.props.t('rsi')}:{' '}
            </p>
            <p className='analysis-value'>
              {this.props.selectedStock.rsi_9_value
                ? parseFloat(this.props.selectedStock.rsi_9_value).toFixed(0)
                : '-'}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label shift'>
              14 {this.props.t('day')} {this.props.t('rsi')}:{' '}
            </p>
            <p className='analysis-value'>
              {this.props.selectedStock.rsi_14_value
                ? parseFloat(this.props.selectedStock.rsi_14_value).toFixed(0)
                : '-'}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label bold'>
              {this.props.t('expmovingaverage')}
            </p>
            <p
              className={`analysis-value ${
                expBearOrBull === 'Bullish'
                  ? 'positive'
                  : expBearOrBull === 'Bearish'
                  ? 'negative'
                  : 'neutral'
              }`}
            >
              {/* {this.bearOrBull("ema") ? this.bearOrBull("ema") : "-"} */}
              {this.props.t(expBearOrBull.toLowerCase())}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label shift'>3 {this.props.t('day')}: </p>
            <p className='analysis-value'>
              {this.props.selectedStock.ema_3
                ? `$${parseFloat(this.props.selectedStock.ema_3).toFixed(2)}`
                : '-'}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label shift'>10 {this.props.t('day')}: </p>
            <p className='analysis-value'>
              {this.props.selectedStock.ema_10
                ? `$${parseFloat(this.props.selectedStock.ema_10).toFixed(2)}`
                : '-'}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label bold'>
              {this.props.t('chaikinmoneyflow')}
            </p>
            <p
              className={`analysis-value ${
                chalkinBearOrBull === 'Bullish'
                  ? 'positive'
                  : chalkinBearOrBull === 'Bearish'
                  ? 'negative'
                  : 'neutral'
              }`}
            >
              {/* {this.bearOrBull("cmf") ? this.bearOrBull("cmf") : "-"} */}
              {this.props.t(chalkinBearOrBull.toLowerCase())}
            </p>
          </div>
          <div className='analysis-row'>
            <p className='analysis-label shift'>21 {this.props.t('day')}: </p>
            <p className='analysis-value'>
              {this.props.selectedStock.cmf_21
                ? parseFloat(this.props.selectedStock.cmf_21).toFixed(2)
                : '-'}
            </p>
          </div>

          <div className='analysis-technical-levels'>
            <p className='header-label'>{this.props.t('technicallevels')}</p>
            <div className='techical-levels-tab-container'>
              <div
                onClick={() => this.setState({ activeTab: 'standard' })}
                style={
                  this.state.activeTab === 'standard'
                    ? selectedTab
                    : unSelectedTab
                }
              >
                <p
                  style={
                    this.state.activeTab === 'standard'
                      ? selectedText
                      : unSelectedText
                  }
                >
                  {this.props.t('standard')}
                </p>
              </div>
              <div
                onClick={() => this.setState({ activeTab: 'fibonacci' })}
                style={
                  this.state.activeTab === 'fibonacci'
                    ? selectedTab
                    : unSelectedTab
                }
              >
                <p
                  style={
                    this.state.activeTab === 'fibonacci'
                      ? selectedText
                      : unSelectedText
                  }
                >
                  {this.props.t('fibonacci')}
                </p>
              </div>
            </div>

            {this.state.activeTab === 'standard' && this.getStandardTable()}
            {this.state.activeTab === 'fibonacci' && this.getFibonacciTable()}
            {this.state.graphData3M && (
              <div style={{ height: '40vh', width: '100%' }}>
                <BalanceHistoryGraph
                  seriesData={this.getConfig()}
                  t={this.props.t}
                  formatCurrency={this.props.formatCurrency}
                  xLabel={'x'}
                  yLabel={'Price'}
                  indicators={
                    this.state.activeTab === 'standard'
                      ? this.state.graphIndicatorsStandard
                      : this.state.graphIndicatorsFibonacci
                  }
                  price={this.props.selectedStock?.price}
                />
              </div>
            )}
          </div>
        </div>
      )
    } else return null
  }
}
