import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import '../../scss/components/WireWithdrawDialog.scss'
import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import Endpoint from '../Endpoint'
import Axios from 'axios'

export default class WireWithdrawDialog extends Component {
  state = {
    selectedBankAccount: null,
    dwAccounts: null,
    errorMessage: '',
    loading: false,
    name: null,
    accNumber: null,
    accType: null,
    swift: null,
    bankName: null,
    bankAddress: null,
    bankCity: null,
    bankProvince: null,
    bankZipcode: null,
    bankCountry: null,
    amount: 0,
    successMsg: '',
    notes: null,
  }

  componentDidMount() {
    let dwAccounts = []
    if (this.props.accounts) {
      this.props.accounts.forEach((account) => {
        if (account.inst === 'DriveWealth' && account.product == null) {
          let name = ''
          switch (account.product_assigned) {
            case 'equity':
              name = 'STOCK'
              break
            case 'goal':
              name = 'GOAL'
              break
            case 'group':
              name = 'AI STOCK TRADER'
              break
            case 'robo':
              name = 'ROBO'
              break
            default:
              break
          }
          dwAccounts.push({
            label: `${name} (${account.name_unmasked})`,
            value: account,
          })
        }
      })
    }
    this.setState({ dwAccounts: dwAccounts })
  }

  withdrawAPI = async () => {
    const body = {
      dwAccountID: this.state.selectedBankAccount.accountId,
      amount: this.state.amount,
      beneficiaryName: this.state.name,
      beneficiaryAccountNumber: this.state.accNumber.toString(),
      beneficiaryAccountType: this.state.accType,
      beneficiarySwiftABA: this.state.swift,
      beneficiaryBankName: this.state.bankName,
      beneficiaryBankAddress: this.state.bankAddress,
      beneficiaryBankCity: this.state.bankCity,
      beneficiaryBankProvince: this.state.bankProvince,
      beneficiaryBankZip: this.state.bankZipcode,
      beneficiaryBankCountry: this.state.bankCountry,
      note: this.state.notes,
      currency: 'USD',
    }
    return await Axios.post(`${Endpoint}/mobile/withdraw-wire`, body, {
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
      },
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
    // return fetch(`${Endpoint}/mobile/withdraw-wire`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(body),
    // })
    //   .then((res) => {
    //     return res
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     Promise.reject(err)
    //   })
  }

  handleFormChanges(value, target) {
    let newState = {}
    newState[target] = value
    this.setState(newState)
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 2500)
  }

  handleBankAccountChange = (value) => {
    this.setState({ selectedBankAccount: value })
  }

  fromAccountForm() {
    return (
      <div className='from-account-container'>
        <p className='withdraw-header'>{this.props.t('fromaccount')}:</p>
        <Dropdown
          value={this.state.selectedBankAccount}
          options={this.state.dwAccounts}
          onChange={(e) => this.handleBankAccountChange(e.value)}
          placeholder={this.props.t('selectaccounttoview')}
        />
        {this.state.selectedBankAccount ? (
          <p className='available-value'>
            {this.props.t('availabletowithdraw')}:{' '}
            {this.props.formatCurrency(
              this.state.selectedBankAccount.balances[0]
                .cashAvailableForWithdrawal
            )}
          </p>
        ) : null}
      </div>
    )
  }

  // textInput(label, value, placeholder, target) {
  //   return (
  //     <div className="input-form">
  //       <p className="input-label">{label}</p>
  //       <InputText
  //         value={value}
  //         onChange={(e) => this.handleFormChanges(e.target.value, target)}
  //         placeholder={placeholder}
  //       />
  //     </div>
  //   );
  // }

  textInput(label, value, placeholder, target) {
    return (
      <div className='input-form'>
        <p className='input-label'>{label}</p>
        <InputText
          value={value}
          onChange={(e) =>
            this.handleFormChanges(
              e.target.value.replace(/[^a-zA-Z\d\s:]/, ''),
              target
            )
          }
          placeholder={placeholder}
        />
      </div>
    )
  }

  numberInput(label, value, placeholder, target) {
    return (
      <div className='input-form'>
        <p className='input-label'>{label}</p>
        <InputNumber
          value={value}
          onValueChange={(e) => this.handleFormChanges(e.value, target)}
          placeholder={placeholder}
          useGrouping={false}
        />
      </div>
    )
  }

  amountForm() {
    return (
      <div className='input-form'>
        <p className='input-label'>{this.props.t('amounttowithdraw')}</p>
        <InputNumber
          value={this.state.amount}
          onValueChange={(e) => this.handleFormChanges(e.value, 'amount')}
          mode='currency'
          currency='USD'
          locale='en-US'
          maxFractionDigits={2}
        />
      </div>
    )
  }

  notesInput(label, value, placeholder, target) {
    return (
      <div className='input-form'>
        <p className='input-label'>{label}</p>
        <InputText
          value={value}
          onChange={(e) => this.handleFormChanges(e.target.value, target)}
          placeholder={placeholder}
        />
      </div>
    )
  }

  submitButton() {
    return (
      <div className='withdraw-btn-container'>
        <Button
          label={this.props.t('withdraw')}
          className='withdraw-btn'
          onClick={() => {
            this.validateForm()
          }}
          loading={this.state.loading}
        />
      </div>
    )
  }

  validateForm = async () => {
    if (
      this.state.selectedBankAccount &&
      this.state.name &&
      this.state.accNumber &&
      this.state.accType &&
      this.state.swift &&
      this.state.bankName &&
      this.state.bankAddress &&
      this.state.bankCity &&
      this.state.bankProvince &&
      this.state.bankZipcode &&
      this.state.bankCountry &&
      this.state.amount > 0 &&
      this.state.notes
    ) {
      if (
        this.state.amount <=
        this.state.selectedBankAccount.balances[0].cashAvailableForWithdrawal
      ) {
        this.setState({ loading: true })
        await this.withdrawAPI().then((res, err) => {
          if (res?.data?.Withdraw.errorCode) {
            this.setState({ errorMessage: res.data.Withdraw.message })
          } else if (res?.data?.Withdraw?.status?.message) {
            this.setState({ successMsg: 'Withdraw successfully placed' })
          } else {
            this.setState({
              errorMessage: this.props.t('somethingwentwrong'),
            })
          }
        })
        this.setState({ loading: false })
      } else {
        this.toggleErrorMessage('Insufficient funds')
      }
    } else {
      this.toggleErrorMessage(this.props.t('pleasecompleteallfields'))
    }
  }

  render() {
    if (this.state.dwAccounts && this.props.t) {
      return (
        <div style={{ height: '65vh' }}>
          {' '}
          {this.fromAccountForm()}
          <p className='withdraw-header'>{this.props.t('to')}:</p>
          {this.textInput(
            this.props.t('beneficiaryname'),
            this.state.name,
            this.props.t('fullname'),
            'name'
          )}
          <p className='notice-info'>
            &#x0002A;{this.props.t('wiredepositinstructions4')}
          </p>
          {this.textInput(
            this.props.t('beneficiaryaccountnumber'),
            this.state.accNumber,
            this.props.t('accountnumber'),
            'accNumber'
          )}
          {this.textInput(
            this.props.t('beneficiaryaccounttype'),
            this.state.accType,
            this.props.t('accounttype'),
            'accType'
          )}
          {this.textInput(
            this.props.t('beneficiaryswiftaba'),
            this.state.swift,
            this.props.t('swiftcodeoraba'),
            'swift'
          )}
          {this.textInput(
            this.props.t('beneficiarybankname'),
            this.state.bankName,
            this.props.t('bankname'),
            'bankName'
          )}
          {this.textInput(
            this.props.t('beneficiarybankaddress'),
            this.state.bankAddress,
            this.props.t('bankaddress'),
            'bankAddress'
          )}
          {this.textInput(
            this.props.t('beneficiarybankcity'),
            this.state.bankCity,
            this.props.t('bankcity'),
            'bankCity'
          )}
          {this.textInput(
            this.props.t('beneficiarybankprovince'),
            this.state.bankProvince,
            this.props.t('bankprovincestate'),
            'bankProvince'
          )}
          {this.textInput(
            this.props.t('beneficiarybankzipcode'),
            this.state.bankZipcode,
            this.props.t('bankzipcode'),
            'bankZipcode'
          )}
          {this.textInput(
            this.props.t('beneficiarybankcountry'),
            this.state.bankCountry,
            this.props.t('bankcountry'),
            'bankCountry'
          )}
          {this.amountForm()}
          {this.notesInput(
            this.props.t('notes'),
            this.state.notes,
            this.props.t('enternoteshere'),
            'notes'
          )}
          <p className='error'>{this.state.errorMessage}</p>
          <p className='success-msg'>{this.state.successMsg}</p>
          {this.submitButton()}
        </div>
        // <div>
        // <Dialog
        //   visible={this.props.showWireWithdrawDialog}
        //   style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
        //   header={
        //     <p className='dialog-title'>{this.props.t('wirewithdrawal')}</p>
        //   }
        //   onHide={() => this.props.toggleWireWithdrawDialog()}
        // >
        //     {this.fromAccountForm()}
        //     <p className='withdraw-header'>{this.props.t('to')}:</p>
        //     {this.textInput(
        //       this.props.t('beneficiaryname'),
        //       this.state.name,
        //       this.props.t('fullname'),
        //       'name'
        //     )}
        //     {this.numberInput(
        //       this.props.t('beneficiaryaccountnumber'),
        //       this.state.accNumber,
        //       this.props.t('accountnumber'),
        //       'accNumber'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiaryaccounttype'),
        //       this.state.accType,
        //       this.props.t('accounttype'),
        //       'accType'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiaryswiftaba'),
        //       this.state.swift,
        //       this.props.t('swiftcodeoraba'),
        //       'swift'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiarybankname'),
        //       this.state.bankName,
        //       this.props.t('bankname'),
        //       'bankName'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiarybankaddress'),
        //       this.state.bankAddress,
        //       this.props.t('bankaddress'),
        //       'bankAddress'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiarybankcity'),
        //       this.state.bankCity,
        //       this.props.t('bankcity'),
        //       'bankCity'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiarybankprovince'),
        //       this.state.bankProvince,
        //       this.props.t('bankprovincestate'),
        //       'bankProvince'
        //     )}
        //     {this.numberInput(
        //       this.props.t('beneficiarybankzipcode'),
        //       this.state.bankZipcode,
        //       this.props.t('bankzipcode'),
        //       'bankZipcode'
        //     )}
        //     {this.textInput(
        //       this.props.t('beneficiarybankcountry'),
        //       this.state.bankCountry,
        //       this.props.t('bankcountry'),
        //       'bankCountry'
        //     )}
        //     {this.amountForm()}
        //     {this.textInput(
        //       this.props.t('notes'),
        //       this.state.notes,
        //       this.props.t('enternoteshere'),
        //       'notes'
        //     )}
        //     <p className='error'>{this.state.errorMessage}</p>
        //     <p className='success-msg'>{this.state.successMsg}</p>
        //     {this.submitButton()}
        //   </Dialog>
        // </div>
      )
    } else return null
  }
}
