import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class FeaturePart extends Component {
    render() {
        return (
            <Container className="features-details">
                {this.props.value.content.map(data =>
                    <Row key={data.id} className="features-content-detail">
                        <Col xs={1} className="features-content-detail"><img src={data.iconUrl} width="39px" alt="feature-details-icon" /></Col>
                        <Col xs={11} className=".col-auto">
                            <p className="features-content-detail-title">{data.title}</p>
                            <p className="features-content-detail-text" dangerouslySetInnerHTML={{ __html: data.detail }}></p>
                        </Col>
                    </Row>
                )}
            </Container>
        )
    }
}

export default FeaturePart
