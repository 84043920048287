import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { LandingPageContext } from '.'

export default function Products({ tab, setTab, productImage }) {
  const { t } = useContext(LandingPageContext)
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general

  const getContent = () => {
    switch (tab) {
      case 0:
        return t('roboinfo')
      case 1:
        return t('aitraderinfo')
      case 2:
        return t('goalsinfo')
      case 3:
        return t('budgetinginfo')
      default:
        return ''
    }
  }
  return (
    <div className='products'>
      <div className='tabs'>
        <p
          className={tab === 0 ? 'tab active' : 'tab'}
          onClick={() => {
            setTab(0)
          }}
        >
          {t('trade')}
        </p>
        <p
          className={tab === 1 ? 'tab active' : 'tab'}
          onClick={() => {
            setTab(1)
          }}
        >
          {t('robo')}
        </p>
        <p
          className={tab === 2 ? 'tab active' : 'tab'}
          onClick={() => {
            setTab(2)
          }}
        >
          {t('goals')}
        </p>
        <p
          className={tab === 3 ? 'tab active' : 'tab'}
          onClick={() => {
            setTab(3)
          }}
        >
          {t('aitrader')}
        </p>
      </div>
      <div className='tab-content-container'>
        <p className='tab-content'>{getContent()}</p>
      </div>
      {isSmallScreen && (
        <div className='phone-img-container'>
          <img src={productImage} className='phone-img' />
        </div>
      )}
    </div>
  )
}
