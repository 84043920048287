import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import axios from 'axios'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import ENDPOINT from '../Endpoint'
import '../../scss/components/LinkACH.scss'

export default class LinkACH extends Component {
  state = {
    //Link ACHForm Fields
    bankType: this.props.achAccount?.bankAccountType,
    bankAccountNumber: null,
    bankHolderName: this.props.achAccount?.bankAccountOwnerName,
    bankRouting: this.props.achAccount?.bankRoutingNumber,
    bankNickname: this.props.achAccount?.nickname,

    //Form Loading
    formLoading: false,

    //Form Error Message
    formErrorMessage: '',

    //Form Success Message
    formSuccessMessage: '',
  }

  componentDidUpdate(prevProps) {
    if (prevProps.achAccount !== this.props.achAccount) {
      this.setState({
        bankType: this.props.achAccount?.bankAccountType,
        bankAccountNumber: null,
        bankHolderName: this.props.achAccount?.bankAccountOwnerName,
        bankRouting: this.props.achAccount?.bankRoutingNumber,
        bankNickname: this.props.achAccount?.nickname,
      })
    }
  }

  linkACHapi = async () => {
    this.setState({ formLoading: true })
    await axios
      .post(
        `${ENDPOINT}/apex-crypto/ach-relationships/create?account_id=${this.props.cryptoAccount.accountId}`,
        {
          bankAccount: this.state.bankAccountNumber,
          bankRoutingNumber: this.state.bankRouting,
          bankAccountOwnerName: this.state.bankHolderName,
          bankAccountType: this.state.bankType,
          nickname: this.state.bankNickname,
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        await this.props.getAllAccountInfo()
        this.setState({ formLoading: false })
        this.toggleSuccessMessage('Successfully Linked Account')
      })
      .catch((err) => {
        this.setState({ formLoading: false })
        this.toggleErrorMessage('Could not link account')
      })
  }

  handleBankTypeChange = (value) => {
    this.setState({ bankType: value })
  }

  handleBankAccountNumberChange = (value) => {
    this.setState({ bankAccountNumber: value })
  }

  handleBankHolderNameChange = (value) => {
    this.setState({ bankHolderName: value })
  }

  handleBankRoutingChange = (value) => {
    this.setState({ bankRouting: value })
  }

  handleBankNicknameChange = (value) => {
    this.setState({ bankNickname: value })
  }

  validateForm() {
    if (
      this.state.bankType &&
      this.state.bankAccountNumber &&
      this.state.bankHolderName &&
      this.state.bankRouting &&
      this.state.bankNickname
    ) {
      this.linkACHapi()
    } else {
      this.toggleErrorMessage('Please complete all fields')
    }
  }

  toggleErrorMessage(msg) {
    this.setState({ formErrorMessage: msg })
    setTimeout(() => {
      this.setState({ formErrorMessage: '' })
    }, 2500)
  }

  toggleSuccessMessage(msg) {
    this.setState({ formSuccessMessage: msg })
    setTimeout(() => {
      this.setState({ formSuccessMessage: '' })
    }, 2500)
  }

  linkACHForm() {
    return (
      <div className='link-ach-container'>
        <p className='label'>Bank Account Type</p>
        <Dropdown
          value={this.state.bankType}
          options={[
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' },
          ]}
          onChange={(e) => this.handleBankTypeChange(e.value)}
          placeholder='Select Account Type'
        />
        <p className='label'>Bank Account Number</p>
        <InputText
          value={this.state.bankAccountNumber}
          onChange={(e) => this.handleBankAccountNumberChange(e.target.value)}
        />
        <p className='label'>Account Holder Name</p>
        <InputText
          value={this.state.bankHolderName}
          onChange={(e) => this.handleBankHolderNameChange(e.target.value)}
        />
        <p className='label'>Bank Routing Number</p>
        <InputText
          value={this.state.bankRouting}
          onChange={(e) => this.handleBankRoutingChange(e.target.value)}
        />
        <p className='label'>Bank Nickname</p>
        <InputText
          value={this.state.bankNickname}
          onChange={(e) => this.handleBankNicknameChange(e.target.value)}
        />
        <p className='failure'>{this.state.formErrorMessage}</p>
        <p className='success'>{this.state.formSuccessMessage}</p>
        <div className='buttons-row'>
          {!this.state.formLoading ? (
            <Button
              label='Submit'
              style={{
                color: 'white',
                background: '#3525e4',
                borderRadius: '15px',
              }}
              onClick={() => {
                this.validateForm()
              }}
            />
          ) : (
            <Button loading />
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <Dialog
        header='ACH Details'
        visible={this.props.showDialog}
        style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
        onHide={() => this.props.toggleShowLinkACHdialog()}
      >
        {this.linkACHForm()}
      </Dialog>
    )
  }
}
