import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../aws-config'
import VerificationInput from 'react-verification-input'
import Spinner from 'react-bootstrap/Spinner'
import PhoneInput from 'react-phone-input-2'

import { IoIosClose } from 'react-icons/io'

import Logo from '../../assets/newLandingPage/InvestAll_logo.png'
import ProfilePicture from '../../assets/newLandingPage/profile.svg'
import Bars from '../../assets/newLandingPage/Bars.svg'
import Modal from 'react-modal'

import Footer from '../PublicFooter'
import MainNavbar from '../components/MainNavBar'

Amplify.configure(awsExports)

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

export default class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      showSignUpPopup: false,
      isSmallScreen: false,
      signUpModalOpen: false,
      loginModalOpen: false,
      showLoginPopup: false,
      showCode: false,
      showForgetPassword: false,
      username: '',
      errors: '',
      authCode: '',
      functionDropdown: false,
      tradeDropdown: false,
      cryptoDropdown: false,
      financialDropdown: false,
      loginLoading: false,
      loginUsername: '',
      changePasswordStep: 0,
    }
    this.topOfPage = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.signUpModalOpen === false &&
      this.state.signUpModalOpen === true
    ) {
      document.body.style.overflow = 'hidden'
    }
    if (
      prevState.signUpModalOpen === true &&
      this.state.signUpModalOpen === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showCode: false,
        errors: '',
        username: '',
        password: '',
        passwordConfirm: '',
        authCode: '',
      })
    }
    if (
      prevState.showSignUpPopup === false &&
      this.state.showSignUpPopup === true
    ) {
      document.body.style.overflow = 'hidden'
    }
    if (
      prevState.showSignUpPopup === true &&
      this.state.showSignUpPopup === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showCode: false,
        errors: '',
        username: '',
        password: '',
        passwordConfirm: '',
        authCode: '',
      })
    }
    if (
      prevState.showLoginPopup === true &&
      this.state.showLoginPopup === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showForgetPassword: false,
        errors: '',
        username: '',
        password: '',
      })
    }
    if (prevState.dropdown === false && this.state.dropdown === true) {
      document.body.style.overflow = 'hidden'
    }
    if (prevState.dropdown === true && this.state.dropdown === false) {
      document.body.style.overflow = 'unset'
    }
    if (
      prevState.changePasswordStep === 2 &&
      this.state.changePasswordStep === 3
    ) {
      setTimeout(() => this.setState({ showForgetPassword: false }), 5000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = () => {
    if (window.scrollY > window.screen.height - 100) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  handleAppRedirect = () => {
    // if(window.navigator.userAgent)
    console.log(window.navigator.userAgent)
    if (window.navigator.userAgent.toLowerCase().includes('iphone')) {
      window.open(
        'https://apps.apple.com/us/app/forescite/id1567589436',
        '_blank'
      )
    }
    if (window.navigator.userAgent.toLowerCase().includes('android')) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.forescite',
        '_blank'
      )
    }
  }

  validateUsername = () => {
    const { username } = this.state
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    console.log('Username test', re.test(String(username).toLowerCase()))
    if (re.test(String(username).toLowerCase())) {
      return true
    } else {
      this.setState({ errors: 'Please enter a valid email' })
      return false
    }
  }
  validatePassword = () => {
    const { password } = this.state
    if (password) {
      if (password.length < 5) {
        this.setState({ errors: 'Invalid Password' })
        return false
      } else {
        return true
      }
    }
    this.setState({ errors: 'Please Enter Password(s)' })
    return false
  }
  validatePhoneNumber = () => {
    console.log('type of ', typeof this.state.phoneNumber)
    if (typeof this.state.phoneNumber === 'undefined') {
      this.setState({ errors: this.props.t('invalidphonenumber') })
      return false
    } else {
      if (this.state.phoneNumber.length > 6) {
        return true
      }
      this.setState({ errors: this.props.t('invalidphonenumber') })
      return false
    }
  }

  validateForm() {
    const { agree } = this.state
    if (agree) {
      return (
        this.validateUsername() &&
        this.validatePhoneNumber() &&
        this.validatePassword()
      )
    } else {
      this.setState({ errors: 'Please agree to the Privacy Policy and EULA' })
      return false
    }
  }

  confirmSignUp() {
    const { username, authCode } = this.state
    // const username = email;
    console.log('username', username)
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        window.alert(
          'Sign up successful! Please Log In in the top left-hand corner.\nYou can also manage your account, using the same credentials, with your mobile device using our iOS or Android apps!'
        )
        // this.props.signIn(this.state.username, this.state.password);
        if (this.state.isSmallScreen) {
          this.setState({ showSignUpPopup: false })
        }
      })
      .catch((err) => {
        if (!err.message) {
          console.log('Error when entering confirmation code: ', err)
          window.alert('Error when entering confirmation code: ', err)
          this.setState({ authCodeText: 'Resend Code' })
        } else {
          console.log('Error when entering confirmation code: ', err.message)
          window.alert('Error when entering confirmation code: ', err.message)
          this.setState({ authCodeText: 'Resend Code' })
        }
      })
  }

  inputCode = (e, type) => {
    if (type === 'password') {
      this.setState({ passwordCode: e }, () => {
        if (this.state.passwordCode.length === 6) {
          this.setState({ changePasswordStep: 2 })
        }
      })
    } else {
      this.setState({ authCode: e }, () => {
        if (this.state.authCode.length === 6) {
          this.confirmSignUp()
        }
      })
    }
  }

  resendCode = () => {
    console.log('resend')
    Auth.resendSignUp(this.state.username)
      .then((res) => {
        console.log('resend code response: ' + JSON.stringify(res))
        window.alert('Your code has been resent to your phone number.')
      })
      .catch((error) =>
        console.log('resend code error: ' + JSON.stringify(error))
      )
  }

  nextStep = () => {
    console.log('got here')
    const { username, password, phoneNumber } = this.state
    let email = username
    const phone_number = phoneNumber
    if (this.validateForm()) {
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number: `+${phone_number}`,
        },
      })
        .then((resp) => console.log('signup response', resp)) //CATCH ERRORS
        .catch((error) => this.setState({ errors: error.message }))
        .then(() => {
          this.setState({ showCode: true })
        })
      console.log('show Code')
      this.setState({ showCode: true })
    }
  }

  displayPhoneNumber = () => {
    return (
      '+' +
      this.state.phoneNumber.substring(0, 1) +
      '-' +
      this.state.phoneNumber.substring(1, 4) +
      '-' +
      this.state.phoneNumber.substring(4, 7) +
      '-' +
      this.state.phoneNumber.substring(7, this.state.phoneNumber.length)
    )
  }

  login = async () => {
    this.setState({ loginLoading: true })
    Auth.signIn(this.state.loginUsername, this.state.password)
      .catch((error) => {
        switch (error.message) {
          case 'User does not exist.':
            error = 'Username not found'
            break
          case 'Incorrect username or password.':
            error = 'Incorrect Password'
            break
          case 'User is not confirmed.':
            error =
              'User is not confirmed. Please enter the confirmation code sent previously or request a new one.'
            this.setState({ retryUser: this.state.username })
            break
          default:
            // code block
            console.log('Not Caught Error: ' + error.message)
            break
        }
        this.setState({ errors: error, loginLoading: false })
      })
      .then(() => Auth.currentSession())
      .then((resp) => {
        let user = resp
        if (user) {
          this.props.setUser(user)
        }
      })
    // .then(() => {
    //   window.location.replace('/Dashboard')
    // })
  }

  sendChangePasswordCode = () => {
    if (this.state.email.length > 5) {
      Auth.forgotPassword(this.state.email)
        .then((resp) => {
          this.setState({
            sendCodeClicked: resp.CodeDeliveryDetails.Destination,
            changePasswordStep: 1,
            errors: false,
          })
        })
        .catch((error) => this.setState({ errors: 'email address not found' }))
    } else {
      this.setState({ errors: 'Please enter a valid email address' })
    }
  }

  submitNewPassword = () => {
    Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.passwordCode,
      this.state.newPassword
    )
      .catch((err) => this.setState({ errors: err.message }))
      .then(() => this.setState({ changePasswordStep: 3 }))
  }

  renderChangePasswordSteps = () => {
    switch (this.state.changePasswordStep) {
      case 1:
        return (
          <div className='codeConfirm__container'>
            <p>
              We sent a verification code to{' '}
              <span className='bold'>{this.state.email}</span>. Please enter the
              code to verify this is your email.
            </p>
            <VerificationInput
              autoFocus
              removeDefaultStyles
              classNames={{
                container: 'verificationInput__container',
                character: 'verificationInputCharacter__container',
                characterInactive:
                  'verificationInputCharacter__container__inactive',
                characterSelected:
                  'verificationInputCharacter__container__selected',
              }}
              validChars='0-9'
              placeholder=''
              onChange={(e) => this.inputCode(e, 'password')}
              value={this.state.passwordCode}
            />
          </div>
        )
      case 2:
        return (
          <div className='codeConfirm__container'>
            <div className='container'>
              <p>Enter your new password below</p>
              <input
                name='newPassword'
                autoComplete='username'
                value={this.state.newPassword}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <div
                className='signup__button'
                onClick={() => this.submitNewPassword()}
              >
                Change your password
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className='codeConfirm__container'>
            <p>
              Your password as been reset. Please log in with your new
              credentials.
            </p>
          </div>
        )
      default:
        return (
          <div className='codeConfirm__container'>
            <div className='top__container'>
              <div
                className='close__container'
                onClick={() => this.setState({ showForgetPassword: false })}
              >
                <IoIosClose className='icon' />
              </div>
            </div>
            <div className='container'>
              <p>Please enter your email down below</p>
              <input
                name='email'
                autoComplete='username'
                value={this.state.email}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <div className='errors__container'>{this.state.errors}</div>
              <div
                className='signup__button'
                onClick={() => this.sendChangePasswordCode()}
              >
                Confirm
              </div>
            </div>
          </div>
        )
    }
  }

  handleNavigation = (link) => {
    document.body.style.overflow = 'unset'
    window.location.replace(link)
  }

  showDropdown = () => {
    return (
      <div className='navbar__dropdown__container'>
        <div className='main__container'>
          <div className='top__container'>
            <NavLink className='logo' to='/'>
              <img src={Logo} alt='Logo' />
            </NavLink>
            <div
              className='icon'
              onClick={() => this.setState({ dropdown: false })}
            >
              <IoIosClose />
            </div>
          </div>
          <div className='bottom__container'>
            <div
              className='item__container'
              onClick={() =>
                window.open('https://www.allocaterite.com/home.jsp', '_blank')
              }
            >
              Business
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/')}
            >
              Home
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/products-info')}
            >
              Products
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/stocks-info')}
            >
              Stocks
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/crypto-info')}
            >
              Crypto
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/ai-tools-info')}
            >
              Tools
            </div>
            {this.props.user ? (
              <div
                className='item__container'
                onClick={() => this.handleNavigation('/Dashboard')}
              >
                Dashboard
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <>
        <MainNavbar {...this.props} isSmallScreen={this.state.isSmallScreen} />

        {/* PAGE CONTENT START */}
        <div className='gradientBackground'>
          <div className='aboutMain__container'>
            <div className='wrapper__container'>
              <p className='title-text'>About Us</p>
              <div className='section'>
                <p className='subtitle__text'>Our Goal</p>
                <p>
                  To bring AI and the power of Predictive Analytics for
                  investment and trading to the masses.
                </p>
              </div>
              <div className='section'>
                <p className='subtitle__text'>Who we are</p>
                <p style={{ fontWeight: 'bold' }}>
                  We are building the world’s first truly AI-powered digital
                  financial SuperApp.
                </p>
                <p>
                  In 2016, AllocateRite started out as an institutional digital
                  wealth management platform. Today, our customers around the
                  world use Investall’s Automated Robo investment and trader
                  tools, originally developed for large institutions, to
                  securely invest their assets without losing custody and
                  control.
                </p>
              </div>
              <div className='section'>
                <p>Investall's other innovative products are:</p>
                <ul>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>
                      Risk and position aggregation
                    </span>{' '}
                    across disparate investment and banking accounts which
                    provide a comprehensive view of a client’s financial
                    picture.
                  </li>
                  <li>
                    AI-powered{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      intelligent budgeting
                    </span>{' '}
                    that provides dynamic and powerful insights into a client’s
                    income spending and savings activities.
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>Goals planning</span>{' '}
                    for short term, medium term and long term goals, ranging
                    from saving and investing for a short term rainy day fund
                    for health emergencies to down payment for a dream house and
                    retirement.
                  </li>
                  <li>
                    Investall’s powerful strategies with six years of GIPS
                    verified track record are at our client’s disposal to assist
                    them in accomplishing their financial management objectives.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* PAGE CONTENT END */}

        <Modal
          style={customStyles}
          isOpen={this.state.signUpModalOpen}
          ariaHideApp={false}
          onRequestClose={() => this.setState({ signUpModalOpen: false })}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='top__container'>
              Create an Account
              <IoIosClose
                className='icon'
                onClick={() => this.setState({ signUpModalOpen: false })}
              />
            </div>
            {!this.state.showCode ? (
              <div className='form__container'>
                <p>Email:</p>
                <input
                  name='username'
                  autoComplete='username'
                  value={this.state.username}
                  className='input__line'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <p>Phone Number:</p>
                <PhoneInput
                  containerClass='phone__input__container'
                  inputClass='phone__input'
                  disableDropdown
                  country={'us'}
                  onChange={(e) => this.setState({ phoneNumber: e })}
                />
                <p>Password:</p>
                <input
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  value={this.state.password}
                  className='input__line'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <div className='check__container'>
                  <input
                    type='checkbox'
                    onClick={() => this.setState({ agree: true })}
                  />
                  <span>
                    I agree to the{' '}
                    <NavLink to='/PrivacyPolicy'>Privacy Policy</NavLink> &{' '}
                    <a href='/Legal/EULA.pdf'>{this.props.t('eula')}</a>
                  </span>
                </div>
                <div onClick={() => this.nextStep()} className='signup__button'>
                  Create Account
                </div>
                <div className='errors__container'>{this.state.errors}</div>
              </div>
            ) : (
              <div className='codeConfirm__container'>
                <p>
                  We sent a verification code to{' '}
                  <span className='bold'>{this.displayPhoneNumber()}</span>.
                  Please enter the code to get started.
                </p>
                <VerificationInput
                  autoFocus
                  removeDefaultStyles
                  classNames={{
                    container: 'verificationInput__container',
                    character: 'verificationInputCharacter__container',
                    characterInactive:
                      'verificationInputCharacter__container__inactive',
                    characterSelected:
                      'verificationInputCharacter__container__selected',
                  }}
                  validChars='0-9'
                  placeholder=''
                  onChange={(e) => this.inputCode(e)}
                  value={this.state.authCode}
                />
                <p>
                  <span
                    className='resend__button'
                    onClick={() => this.resendCode()}
                  >
                    Didn't get the code?
                  </span>
                </p>
              </div>
            )}
          </div>
        </Modal>
        {/* Login Modal */}
        <Modal
          style={customStyles}
          isOpen={this.state.loginModalOpen}
          ariaHideApp={false}
          onRequestClose={() => this.setState({ loginModalOpen: false })}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='top__container'>
              Log In
              <IoIosClose
                className='icon'
                onClick={() => this.setState({ loginModalOpen: false })}
              />
            </div>
            <div className='form__container'>
              <p>Email:</p>
              <input
                name='loginUsername'
                autoComplete='username'
                value={this.state.loginUsername}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <p>Password:</p>
              <input
                name='password'
                type='password'
                autoComplete='new-password'
                value={this.state.password}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              {this.state.loginLoading ? (
                <Spinner
                  animation='border'
                  id='spinner'
                  variant='dark'
                  role='status'
                  style={{ marginTop: '20px' }}
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                <div onClick={() => this.login()} className='login__button__2'>
                  Log In
                </div>
              )}
              <div className='errors__container'>{this.state.errors}</div>
            </div>
          </div>
        </Modal>
        <Footer isSmallScreen={this.state.isSmallScreen} t={this.props.t} />
      </>
    )
  }
  // renderBig(){
  //     return(
  //         <div>
  //             <Header user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.state.isSmallScreen}/>
  //             <div style={{marginTop:75}}>
  //                <div style={{backgroundColor:'rgb(230,230,230)', paddingTop: '4.5%',  paddingLeft:'7%',}}>
  //                     <div style={{width:'100%', display:'flex'}}>
  //                         <div style={{width:'70%',paddingBottom: '10%',}}>
  //                             <p style={{fontSize:32, fontWeight:'bolder', color:'rgb(250,250,250)',  textShadow: '1px 1.7px 2.8px rgba(20, 20, 20, 0.13)'}}>About</p>
  //                             <p style={{fontSize:30, fontWeight:'bold'}}>We took the guessing out of investing</p>
  //                             <p style={{fontSize:26}}>ForeSCITE empowers the masses to manage and invest their money like a pro. Trusted by the top financial institutions, our <strong>AI-powered investing platform</strong> offers users the <strong>most powerful risk analysis tool</strong> on the market with the technology to manage your money more accurately than a financial advisor.</p>
  //                             <p style={{fontSize:24}}>Whether you’re looking to monitor your portfolio across all your accounts, place <strong>self-directed trades</strong>, or take advantage of our <strong>industry-leading robo adviser</strong> - we give you the tools to always make the most educated investing decision. </p>
  //                         </div>
  //                         <div style={{width:'30%',display:"flex", justifyContent:'flex-end', alignItems:'flex-end'}}>
  //                             <img
  //                                 src={RiskGauge}
  //                                 style={{width:'100%'}}
  //                             />
  //                         </div>
  //                     </div>
  //                </div>
  //                <div style={{backgroundImage: 'linear-gradient(to right, rgb(105,190,182) , rgb(157,199,125))', }}>
  //                    <div style={{width:'100%',display:'flex', justifyContent:'space-evenly'}}>
  //                         <div style={{width:'50%', paddingTop:50, paddingBottom:50}}>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Access the technology that financial institutions use</p>
  //                                 <p style={{fontSize:16}}>We have a track record of supplying banks with AI-Driven investment technology and are trusted by the top financial institutions. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>See into the future with state-of-the-art stock price trend predictions</p>
  //                                 <p style={{fontSize:16}}>Get access to price trend predictions of thousands of stocks worldwide and add your favorites to a watchlist for automated price trend notifications. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Aggregate and monitor all of your financial accounts in one place</p>
  //                                 <p style={{fontSize:16}}>ForeSCITE not only supercharges your investing, but aggregates all of your financial accounts. The more accounts you link, the more powerful our tool is to help you assess your risk, spending habits, and more.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Keep total control of your funds</p>
  //                                 <p style={{fontSize:16}}>You maintain custody of your capital and are the ultimate arbiter on all investment decisions. You can unlink from the robo or group trader whenever you want.</p>
  //                             </div>

  //                         </div>
  //                         <div style={{width:'30%',paddingTop:50}}>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%', marginRight:100 }}
  //                                     src={LinkExistingSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginLeft:100}}
  //                                     src={CreateDwSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginRight:100}}
  //                                     src={StockSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginLeft:100}}
  //                                     src={PriceForecastSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginRight:100}}
  //                                     src={GoalsSnippit}
  //                                 />
  //                             </div>
  //                         </div>
  //                    </div>
  //                    <div style={{width:'100%',display:'flex', justifyContent:'space-between'}}>
  //                         <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
  //                             <img
  //                                 style={{width:'60%',}}
  //                                 src={PhoneHand}
  //                             />
  //                         </div>
  //                         <div style={{width:'50%', paddingTop:50, paddingBottom:50, paddingRight:50}}>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Link to to thousands of financial institutions</p>
  //                                 <p style={{fontSize:16}}>With ForeSCITE, users can easily link any brokerage account to start trading or simply create a new one in the app with DriveWealth, an award winning independent custodian and broker-dealer.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Grow your savings with AI-Powered financial planning</p>
  //                                 <p style={{fontSize:16}}>Set a dollar amount goal for your portfolio and ForeSCITE guides your investing and spending habits.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Outperform your traditional financial advisor</p>
  //                                 <p style={{fontSize:16}}>ForeSCITE is the top-performing robo advisor with an 8.18% two-year annualized return. We help you better manage your investment accounts with AI-powered cutting-edge tools.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Stay informed with 24/7 Risk Monitoring & Reporting</p>
  //                                 <p style={{fontSize:16}}>Our AI provides proactive risk monitoring and disciplined execution of investment decisions. Keeping you informed about your portfolio every step of the way.</p>
  //                             </div>

  //                         </div>
  //                     </div>
  //                </div>
  //             </div>
  //             <Footer isSmallScreen={this.state.isSmallScreen}/>
  //         </div>
  //     )
  // }

  // renderSmall(){
  //     return(
  //         <div>
  //             <Header user={this.props.user} signOut={this.props.signOut} isSmallScreen={this.state.isSmallScreen}/>
  //             <div style={{marginTop:75}}>
  //                <div style={{backgroundColor:'rgb(240,240,240)', paddingTop:'10%',  paddingLeft:'7%',}}>
  //                     <div style={{width:'100%',}}>
  //                         <div style={{width:'90%',margin:'auto',paddingBottom: '10%',}}>
  //                             <p style={{fontSize:32, fontWeight:'bolder', color:'rgb(255,255,255)',textShadow: '1px 1.7px 2.8px rgba(20, 20, 20, 0.13)'}}>About</p>
  //                             <p style={{fontSize:30, fontWeight:'bold'}}>We took the guessing out of investing</p>
  //                             <p style={{fontSize:26}}>ForeSCITE empowers the masses to manage and invest their money like a pro. Trusted by the top financial institutions, our <strong>AI-powered investing platform</strong> offers users the <strong>most powerful risk analysis tool</strong> on the market with the technology to manage your money more accurately than a financial advisor.</p>
  //                             <p style={{fontSize:24}}>Whether you’re looking to monitor your portfolio across all your accounts, place <strong>self-directed trades</strong>, or take advantage of our <strong>industry-leading robo adviser</strong> - we give you the tools to always make the most educated investing decision. </p>
  //                         </div>
  //                         <div style={{width:'100%',display:"flex", justifyContent:'flex-end', alignItems:'flex-end'}}>
  //                             <img
  //                                 src={RiskGauge}
  //                                 style={{width:'100%'}}
  //                             />
  //                         </div>
  //                     </div>
  //                </div>
  //                <div style={{backgroundImage: 'linear-gradient(to right, rgb(105,190,182) , rgb(157,199,125))', }}>
  //                    <div style={{width:'100%',}}>
  //                         <div style={{width:'90%',margin:'auto', paddingTop:50, paddingBottom:50}}>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Access the technology that financial institutions use</p>
  //                                 <p style={{fontSize:16}}>We have a track record of supplying banks with AI-Driven investment technology and are trusted by the top financial institutions. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>See into the future with state-of-the-art stock price trend predictions</p>
  //                                 <p style={{fontSize:16}}>Get access to price trend predictions of thousands of stocks worldwide and add your favorites to a watchlist for automated price trend notifications. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Aggregate and monitor all of your financial accounts in one place</p>
  //                                 <p style={{fontSize:16}}>ForeSCITE not only supercharges your investing, but aggregates all of your financial accounts. The more accounts you link, the more powerful our tool is to help you assess your risk, spending habits, and more.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Keep total control of your funds</p>
  //                                 <p style={{fontSize:16}}>You maintain custody of your capital and are the ultimate arbiter on all investment decisions. You can unlink from the robo or group trader whenever you want.</p>
  //                             </div>

  //                         </div>
  //                         <div style={{width:'90%',paddingTop:10,margin:'auto'}}>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%', marginRight:100 }}
  //                                     src={LinkExistingSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginLeft:100}}
  //                                     src={CreateDwSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginRight:100}}
  //                                     src={StockSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginLeft:100}}
  //                                     src={PriceForecastSnippit}
  //                                 />
  //                             </div>
  //                             <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
  //                                 <img
  //                                     style={{width:'65%',marginTop:'-10%',marginRight:100}}
  //                                     src={GoalsSnippit}
  //                                 />
  //                             </div>
  //                         </div>
  //                    </div>
  //                    <div style={{width:'100%',}}>

  //                         <div style={{width:'90%', paddingTop:50, paddingBottom:50, margin:'auto'}}>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Access the technology that financial institutions use</p>
  //                                 <p style={{fontSize:16}}>We have a track record of supplying banks with AI-Driven investment technology and are trusted by the top financial institutions. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>See into the future with state-of-the-art stock price trend predictions</p>
  //                                 <p style={{fontSize:16}}>Get access to price trend predictions of thousands of stocks worldwide and add your favorites to a watchlist for automated price trend notifications. </p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Aggregate and monitor all of your financial accounts in one place</p>
  //                                 <p style={{fontSize:16}}>ForeSCITE not only supercharges your investing, but aggregates all of your financial accounts. The more accounts you link, the more powerful our tool is to help you assess your risk, spending habits, and more.</p>
  //                             </div>
  //                             <div style={{  borderRadius:5, border:'solid',borderWidth:'0.25px',borderColor: '#ffffff',paddingLeft:'7.5%',paddingRight:'7.5%',paddingTop:'3%',paddingBottom:'3%',backgroundColor: 'rgba(255, 255, 255, 0.5)', marginBottom:10 }}>
  //                                 <p style={{fontSize:20,fontWeight:'bold'}}>Keep total control of your funds</p>
  //                                 <p style={{fontSize:16}}>You maintain custody of your capital and are the ultimate arbiter on all investment decisions. You can unlink from the robo or group trader whenever you want.</p>
  //                             </div>

  //                         </div>
  //                         <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center'}}>
  //                             <img
  //                                 style={{width:'80%',}}
  //                                 src={PhoneHand}
  //                             />
  //                         </div>
  //                     </div>
  //                </div>
  //             </div>
  //             <Footer isSmallScreen={this.state.isSmallScreen}/>
  //         </div>
  //     )
  // }
  // render(){
  //     return this.state.isSmallScreen? this.renderSmall() : this.renderBig()
  // }
}
