import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class DetailOfPlatform extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <p className="platform-element-details-title">
              {this.props.value.title}
            </p>
          </Col>
          <Col sm={12}>
            <p className="platform-element-details-text">
              {this.props.value.text}
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DetailOfPlatform;
