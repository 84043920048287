import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import FooterPageTitle from '../layout/FooterPageTitle'
import Footer from '../layout/Footer'
import Header from '../layout/Header'

class DisclaimersDisclosures extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut} />
        <div>
          <FooterPageTitle title='Disclaimer and Disclosures' />
          <Container>
            <Row />
            <Row className='terms-top-paragraph'>
              <h3>AllocateRite LLC Disclaimers and Disclosure</h3>
            </Row>
            <Row>
              <p className='terms-text'>
                1. AllocateRite LLC (‘AllocateRite”) is an Investment Adviser.
                Information pertaining to AllocateRite’s advisory operations,
                services, and fees is set forth in a separate Brochure, a copy
                of which is available upon request.
              </p>
              <p className='terms-text'>
                2. The performance information presented in certain charts or
                tables represents backtested performance based on simulated
                index data of fund results from January 1, 2005 to the current
                period end date shown, using the strategy of buy and hold and at
                the beginning of each monthly or intra-monthly rebalancing of
                the fund. Backtested performance is hypothetical (it does not
                reflect trading in actual accounts) and is provided for
                informational purposes only to indicate historical performance
                had the index portfolios been available over the relevant time
                period. AllocateRite refers to this hypothetical data as a
                Simulated Passive Returns (“SPR”). Monthly SPR was calculated
                from January 1, 2005 through the most current date. These
                simulated results, prepared by AllocateRite, are in the process
                of being verified by a third-party source. All performance
                returns, other than where noted as actual, should be assumed as
                simulated hypothetical returns.
              </p>
              <p className='terms-text'>
                3. Backtested performance does not represent actual performance
                and should not be interpreted as an indication of such
                performance. Actual performance for client accounts may be
                materially lower than that of the index portfolios. Backtested
                performance results have certain inherent limitations.
                Backtested performance differs from actual performance because
                it is achieved through the retroactive application of model
                portfolio designed with the benefit of hindsight.
              </p>
              <p className='terms-text'>
                4. Backtested performance results assume the reinvestment of
                dividends and capital gains and monthly rebalancing occurring at
                or around the beginning of each month. It is important to
                understand that the assumption of monthly and stop loss
                rebalancing has an impact on the monthly returns reported for
                the AllocateRite Portfolio. For monthly rebalancing, the monthly
                and year-to-date return is calculated with the assumption that
                the portfolio is perfectly in balance at and around the
                beginning of each month. The latter assumption underlies the
                returns shown for the AllocateRite Portfolio. Backtested risk
                and return data uses simulated index data that does not deduct
                fund fees and other expenses, which reduce returns.
              </p>
              <p className='terms-text'>
                5. Actual results are shown separately, and are net of the
                effect of all fees including AllocateRite’s quarterly investment
                consulting fee, billed quarterly, unless stated otherwise.
                Actual AllocateRite consulting fees are deducted quarterly, in
                arrears. Depending on the amount of your assets under
                management, your investment management fee may be less. Actual
                risk and return data uses live (or actual) fund results that
                includes deductions for fund fees and expenses. We choose this
                method because the costs of creation, choice, monitoring and
                rebalancing of a diversified portfolio are appropriate items to
                deduct from the whole portfolio returns. Actual results are
                shown for the period January 6, 2016 through the most current
                reporting date. These returns have been calculated based on
                real-time traded market performance data, and are net of all
                fees. Tax liabilities from short and long-term capital gains, if
                any, are not deducted from performance results.
              </p>
              <p className='terms-text'>
                6. For all data periods, annualized standard deviation is
                presented as an approximation by multiplying the monthly
                standard deviation number by the square root of 12. Please note
                that the number computed from annual data may differ materially
                from this estimate. We have chosen this methodology because
                Morningstar uses the same method. Go to{' '}
                <a className='terms-link' href='https://www.allocaterite.com'>
                  allocaterite
                </a>{' '}
                for details. In those charts and tables where the standard
                deviation of daily returns is shown, it is estimated as the
                standard deviation of monthly returns divided by the square root
                of 22.
              </p>
              <p className='terms-text'>
                7. Performance results for clients that invested in accordance
                with the AllocateRite Portfolio will vary from the backtested
                performance due to market conditions and other factors,
                including investments frequency and precision of rebalancing,
                varying advisory fees, varying custodian fees, and/or the timing
                of fee deductions. As the result of these and potentially other
                variances, actual performance for client accounts may differ
                materially from (and may be lower than) that of the AllocateRite
                index portfolio. Clients should consult their account statements
                for information about how their actual performance compares to
                that of the index portfolio.
              </p>
              <p className='terms-text'>
                8. As with any investment strategy, there is potential for
                profit as well as the possibility of loss. AllocateRite does not
                guarantee any minimum level of investment performance or the
                success of the investment strategy. All investments involve risk
                and investment recommendations will not always be profitable.
              </p>
              <p className='terms-text'>
                9. Past performance, both simulated and actual, does not
                guarantee future results.
              </p>
              <p className='terms-text'>
                10. AllocateRite Index Portfolio Value Data is based on a
                starting value of $100,000 as of January 3, 2005.
              </p>
              <p className='terms-text'>
                11. DISCLAIMER: THERE ARE NO WARRANTIES, EXPRESSED OR IMPLIED,
                AS TO ACCURACY, COMPLETENESS, OR RESULTS OBTAINED FROM ANY
                INFORMATION PROVIDED HEREIN OR ON THE MATERIAL PROVIDED. This
                document does not constitute a complete description of our
                investment services and is for informational purposes only. It
                is in no way a solicitation or an offer to sell securities or
                investment advisory services. Any statements regarding market or
                other financial information is obtained from sources which we
                and our suppliers believe to be reliable, but we do not warrant
                or guarantee the timeliness or accuracy of this information.
                Neither our information providers nor we shall be liable for any
                errors or inaccuracies, regardless of cause, or the lack of
                timeliness of, or for any delay or interruption in the
                transmission thereof to the user. All investments involve risk,
                including foreign currency exchange rates, political risks,
                market risk, different methods of accounting and financial
                reporting, and foreign taxes. Your use of these materials,
                including www.AllocateRite.com website is your acknowledgement
                that you have read and understood the full disclaimer as stated
                above. AllocateRite Index Portfolio, times series, standard
                deviations, and returns calculations are determined using an
                internal program.
              </p>
            </Row>
            <Row className='page-blank-row' />
          </Container>
          <Footer t={this.props.t} />
        </div>
      </div>
    )
  }
}

export default DisclaimersDisclosures
