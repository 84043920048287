import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import '../../scss/pages/account-page/robo-account-info/ContributionEditModal.scss'
import Loader from './accountInfo/Loader'

export default class ContributionEditModal extends Component {
  state = {
    amountValue: this.props.periodicContributions
      ? this.props.periodicContributions
      : 0,
    roundUp: this.props.roundUp,
    selectedAccountId: null,
    message: '',
  }

  handleAmountChange = (val) => {
    this.setState({ amountValue: val })
  }

  handleRoundUpChange = () => {
    this.setState({
      roundUp: !this.state.roundUp,
    })
  }

  handleAccountChange = (val) => {
    this.setState({
      selectedAccountId: val,
    })
  }

  showMessage() {
    if (this.props.editContributionStatus === 'success') {
      return (
        <div style={{ padding: '15px' }}>
          <p style={{ fontSize: '15px', textAlign: 'center' }}>
            {this.props.t('periodiccontributionupdatedsuccessfully')}
          </p>
        </div>
      )
    } else if (this.props.editContributionStatus === 'error') {
      return (
        <div style={{ padding: '15px' }}>
          <p style={{ fontSize: '15px', color: 'red', textAlign: 'center' }}>
            {this.props.t('somethingwentwrong')}
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ width: '10%', margin: 'auto' }}>
          <Loader logoLoader={false} />
        </div>
      )
    }
  }

  editContributionModal() {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          backgroundColor: 'rgba(0,0,0, 0.3)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: this.props.isSmallScreen ? '5%' : '20%',
            right: '25%',
            top: '25%',
            bottom: '25%',
            margin: 'auto',
            borderRadius: '20px',
            background: 'white',
            padding: '15px',
            height: this.props.isSmallScreen
              ? '60%'
              : this.state.roundUp
              ? '70%'
              : '50%',
            width: this.props.isSmallScreen ? '90%' : '35%',
            boxShadow: '0px 4px 14px -5px rgba(0, 0, 0, 0.3)',
          }}
        >
          <div
            style={{
              width: '10%',
              justifyContent: 'right',
              display: 'flex',
              margin: 'auto',
              marginRight: '0',
              textAlign: 'right',
              background: 'transparent',
              border: 'none',
              padding: '1px',
            }}
          >
            <p
              onClick={() => this.props.closeAndResetEditContributionModal()}
              style={{ fontSize: '25px', cursor: 'pointer' }}
            >
              X
            </p>
          </div>
          <p
            style={{
              textAlign: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            {this.props.t('periodiccontribution')}
          </p>
          {this.props.editContributionStatus ? (
            this.showMessage()
          ) : (
            <div style={{ width: '80%', margin: 'auto' }}>
              {this.amountForm()}
              {this.roundUpForm()}
              {this.selectAccountForm()}
              {this.submitButton()}
            </div>
          )}
        </div>
      </div>
    )
  }

  amountForm() {
    return (
      <div
        style={{
          padding: '15px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ position: 'relative' }}>
          {/* <input type="checkbox" style={{marginRight: '15px'}}></input> */}
          <i
            style={{
              position: 'absolute',
              display: 'block',
              transform: 'translate(0, -50%)',
              top: '50%',
              left: '4%',
              pointerEvents: 'none',
              width: '25px',
              textElign: 'center',
              fontStyle: 'normal',
            }}
          >
            $
          </i>
          <input
            style={{
              padding: '5px 10px 5px 25px',
              borderRadius: '10px',
              border: '1px solid black',
            }}
            type='number'
            name='amountInput'
            value={this.state.amountValue}
            onChange={this.handleAmountChange}
            disabled={this.state.roundUp}
          ></input>
        </div>
      </div>
    )
  }

  roundUpForm() {
    return (
      <div
        style={{
          padding: '15px',
          display: this.state.roundUp ? 'block' : 'flex',
          justifyContent: 'center',
        }}
      >
        <label>
          <input
            style={{ marginRight: '5px', border: '1px solid black' }}
            type='checkbox'
            value={this.state.roundUp}
            onChange={this.handleRoundUpChange}
            defaultChecked={this.state.roundUp}
          />
          {this.props.t('roundup')}
        </label>
        {this.state.roundUp && !this.props.isSmallScreen ? (
          <div
            style={{
              border: '1px solid rgb(8, 143, 143)',
              background: 'rgb(175, 225, 175)',
              color: 'rgb(8, 143, 143)',
              padding: '15px',
              borderRadius: '15px',
            }}
          >
            <p>{this.props.t('sparemessage1')}</p>
            <p>{this.props.t('sparemessage2')}</p>
          </div>
        ) : null}
      </div>
    )
  }

  selectAccountForm = () => {
    let accountList =
      this.props.accounts.length > 0 &&
      this.props.accounts.map((account, i) => {
        if (account.type === 'depository')
          return (
            <option key={i} value={account.accountId}>
              {account.name}(${account.cash_account})
            </option>
          )
      })
    return (
      <div
        style={{
          padding: '15px',
          display: this.props.isSmallScreen ? 'block' : 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <label
          style={{ paddingRight: '5px', marginTop: '15px' }}
          for='chooseAccount'
        >
          {this.props.t('selectanaccount')}:
        </label>
        <select
          id='chooseAccount'
          name='chooseAccount'
          style={{
            borderRadius: '10px',
            padding: '15px',
            width: this.props.isSmallScreen ? '100%' : '100%',
            border: '1px solid black',
          }}
          onChange={this.handleAccountChange}
        >
          {accountList}
        </select>
      </div>
    )
  }

  submitButton() {
    return (
      <div
        style={{ padding: '15px', display: 'flex', justifyContent: 'center' }}
      >
        <button
          style={{
            color: 'white',
            background: '#3F46F6',
            borderRadius: '25px',
            padding: '10px 15px 10px 15px',
            fontSize: '15px',
            border: 'none',
          }}
          onClick={() => {
            this.callAPI()
          }}
        >
          SAVE
        </button>
      </div>
    )
  }

  callAPI() {
    if (this.state.roundUp) {
      this.props.editContributionRoundUp(
        this.props.selectedAccount.accountId,
        this.state.selectedAccountId
      )
    } else {
      this.props.editContributionPeriodic(
        this.props.selectedAccount.accountId,
        this.state.selectedAccountId,
        this.state.amountValue
      )
    }
  }
  validateForm = () => {
    if (this.state.selectedAccountId) {
      if (this.state.roundUp) {
        this.callAPI()
      } else {
        if (this.state.amountValue > 0) {
          this.callAPI()
        } else {
          this.setState({
            message: this.props.t('pleaseenteramountgreaterthan0'),
          })
        }
      }
    } else {
      this.setState({ message: this.props.t('selectanaccount') })
    }
  }
  render() {
    let accountList = []
    this.props.accounts.length > 0 &&
      this.props.accounts.map((account, i) => {
        if (account.type === 'depository')
          accountList.push({
            label: `${account.name}(${account.cash_account})`,
            value: account.accountId,
          })
      })
    if (this.props.t) {
      return (
        <>
          <Dialog
            visible={this.props.showDialog}
            header={this.props.t('periodiccontribution')}
            onHide={() => this.props.closeAndResetEditContributionModal()}
            style={{ width: this.props.isSmallScreen ? '90vw' : '40vw' }}
          >
            {this.props.editContributionStatus ? (
              this.showMessage()
            ) : (
              <div className='edit-contribution-container'>
                <div className='amount-form-container'>
                  <InputNumber
                    value={this.state.amountValue}
                    onValueChange={(e) => this.handleAmountChange(e.value)}
                    mode='currency'
                    currency='USD'
                    locale='en-US'
                    disabled={this.state.roundUp}
                    minFractionDigits={0}
                    maxFractionDigits={2}
                  />
                </div>
                <div className='round-up-form-container'>
                  <p className='round-up-label'>{this.props.t('roundup')}: </p>
                  <Checkbox
                    onChange={(e) => this.handleRoundUpChange()}
                    checked={this.state.roundUp}
                    inputId='roundup'
                  />
                </div>
                {this.state.roundUp && !this.props.isSmallScreen ? (
                  <div
                    style={{
                      border: '1px solid rgb(8, 143, 143)',
                      background: 'rgb(175, 225, 175)',
                      color: 'rgb(8, 143, 143)',
                      padding: '15px',
                      borderRadius: '15px',
                    }}
                  >
                    <p>{this.props.t('thesparechangemessage1')}</p>
                    <p>{this.props.t('thesparechangemessage2')}</p>
                  </div>
                ) : null}
                <div className='account-form-container'>
                  <Dropdown
                    value={this.state.selectedAccountId}
                    options={accountList}
                    onChange={(e) => this.handleAccountChange(e.value)}
                    placeholder={this.props.t('selectanaccount')}
                  />
                </div>
                <p className='message'>{this.state.message}</p>
                <Button
                  label={this.props.t('submit')}
                  className='save-button'
                  onClick={() => {
                    this.validateForm()
                  }}
                />
              </div>
            )}
          </Dialog>
        </>
      )
    } else return null
  }
}
