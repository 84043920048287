export const validatePassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#\\'"])[A-Za-z\d-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#\\'"]{8,}$/
  return password && regex.test(password)
}

export const validateLength = (password) => {
  return password && password.length >= 8
}

export const validateUppercase = (password) => {
  const regex = /(?=.*?[A-Z])/
  return password && regex.test(password)
}

export const validateLowercase = (password) => {
  const regex = /(?=.*?[a-z])/
  return password && regex.test(password)
}

export const validateNumber = (password) => {
  const regex = /(?=.*?[0-9])/
  return password && regex.test(password)
}

export const validateSpecialCharacter = (password) => {
  // const regex = /(?=.*?[#?!@$%^&*-])/
  const regex = /(?=.*?[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#\\'"])/
  return password && regex.test(password)
}
// -!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#
