import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import { SpeedDial } from 'primereact/speeddial'
import { Dropdown } from 'primereact/dropdown'
import '../../scss/components/ChangeLanguage.scss'
import CN from '../../assets/cn.svg'
import US from '../../assets/us.svg'
import PT from '../../assets/br.svg'

export default function ChangeLanguage(props) {
  const changeLanguage = async (value) => {
    // if (i18n.language === 'zh') {
    //   i18n.changeLanguage('en')
    //   changeLanguageAPI('en')
    // } else if (i18n.language === 'pt') {
    //   i18n.changeLanguage('pt')
    //   changeLanguageAPI('pt')
    // } else {
    //   i18n.changeLanguage('zh')
    //   changeLanguageAPI('zh')
    // }
    if (value === 'zh') {
      await changeLanguageAPI('zh')
      i18n.changeLanguage('zh')
    } else if (value === 'pt') {
      await changeLanguageAPI('pt')
      i18n.changeLanguage('pt')
    } else {
      await changeLanguageAPI('en')
      i18n.changeLanguage('en')
    }
  }

  const changeLanguageAPI = async (language) => {
    Axios.get(`${Endpoint}/mobile/get-email?lang=${language}`, {
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res) {
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (props.t) {
    return (
      <div className='language-toggle-container'>
        <p className='language-header'>{props.t('language')}</p>
        <Dropdown
          value={i18n.language}
          options={[
            { label: <img src={US} className='flag' />, value: 'en' },
            { label: <img src={CN} className='flag' />, value: 'zh' },
            { label: <img src={PT} className='flag' />, value: 'pt' },
          ]}
          onChange={(e) => changeLanguage(e.value)}
        />
      </div>
    )
  } else return null
}
