import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: { timeOut: false },
  reducers: {
    login: (state, action) => {},
    setIsCryptoAllowed: (state, action) => {
      return { ...state, isCryptoAllowed: action.payload }
    },
    setUserData: (state, action) => {
      return { ...state, userData: action.payload }
    },
    setTimeOut: (state, action) => {
      return { ...state, timeOut: action.payload }
    },
  },
})

export const { setIsCryptoAllowed, setUserData, setTimeOut } = userSlice.actions

export default userSlice.reducer
