import React, { useEffect, useState } from 'react'
import '../scss/components/DownloadBanner.scss'
import InverstallLogo from '../assets/blue-logo-only.svg'
import { handleAppRedirect } from '../utility-functions/handleAppRedirect'
import BannerIcon from '../assets/banner-x-icon.svg'
import { browserName } from 'react-device-detect'

export default function DownloadBanner({ setShowBanner }) {
  const [isSafari, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(browserName.toLowerCase().includes('safari'))
  }, [])
  if (!isSafari) {
    return (
      <div className='download-banner'>
        <div className='banner-logo-container'>
          <img src={InverstallLogo} className='banner-logo' />
        </div>
        <p className='banner-text'>
          Invest in Stocks, Cryptos, and get automated tools.
        </p>
        <button
          className='banner-btn'
          onClick={() => {
            handleAppRedirect()
            // window.location.replace(
            //   'intent://www.allocaterite.com/#Intent;scheme=https;package=com.forescite;end'
            // )
          }}
        >
          Download
        </button>
        <img
          className='banner-remove-btn'
          onClick={() => {
            setShowBanner(false)
          }}
          src={BannerIcon}
        />
      </div>
    )
  } else return null
}
