import React from 'react'
import ENDPOINT from '../Endpoint'
import { FaExclamationCircle } from 'react-icons/fa';
import { PlaidLink } from 'react-plaid-link';



const brandColor = "rgb(70,182,179)"


export default class RelinkAcc extends React.Component{
    state={
        tgData:'loading',
        show:'more',
        stratData:{},
        stratSelected:false,
        activeDropdown:false,
        showDesc:false,
        showAllStock:false,
    }

    componentDidMount(){
       
    }

  



    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    utcToLocal(timeAndDate){

        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }




    accButtons = ()=>{
        let acc = this.props.acc
        return (
            <div style={{display:'flex', width:'51%', justifyContent:'flex-end', flexWrap:'wrap'}}>
                            {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.orders()}>
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                } */}
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.deposit()}>
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Transfer</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.redemption()}>
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Redeem</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.transactions()}>
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Transactions</p>
                                    </div>
                                :null
                                }
                                
                                {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                } */}
                                <div 
                                style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                onClick={()=>this.props.unlink()}
                                >
                                    <p style={{margin:0,fontWeight:'',fontSize:18, color:'#ee5e68'}}>Unlink</p>
                                </div>
                            </div>
        )
    }



    buttonDropdown = (acc)=>{
        if(this.state.activeDropdown){
            return(
                <div>
                    <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Menu</p>
                    </div>
                    <div style={{position: 'absolute', right:'5%',backgroundColor: '#f9f9f9',minWidth: '10%',overflow: 'auto',boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',zIndex: 100, marginRight:25}}>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer', backgroundColor:this.state.hover === 'orders'?'rgb(200,200,200)':'',}}onClick={()=>this.props.orders()}  onMouseEnter={()=>this.setState({hover:'orders'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer', backgroundColor:this.state.hover === 'transactions'?'rgb(200,200,200)':'',}}onClick={()=>this.props.transactions()} onMouseEnter={()=>this.setState({hover:'transactions'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                        </div>
                        {acc.inst === "DriveWealth"?
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer', backgroundColor:this.state.hover === 'statements'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showStatements:acc})} onMouseEnter={()=>this.setState({hover:'statements'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                        </div>
                        :null}
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer', backgroundColor:this.state.hover === 'unlink'?'rgb(200,200,200)':'',}}onClick={()=>this.setState({showUnlink:acc})} onMouseEnter={()=>this.setState({hover:'unlink'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Unlink</p>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div>
                    <div 
                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}>
                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Menu</p>
                    </div>
                </div>
            )
        }
    }

    plaidHandleOnSuccess = (public_token, metadata)=> {
        let accountId = metadata.account_id
        let itemId = metadata.institution.institution_id
        this.linkPlaidAccount(public_token, metadata)
        
    }

    linkPlaidAccount = (token, meta)=>{
        // console.log('plaid token' ,token)
        // console.log('plaid meta' ,meta)
        let id = meta.account_id
        let mask = meta.account.mask
        let name = meta.account.name
        let subType = meta.account.subtype

        let inst_id = meta.institution.institution_id
        let inst_name = meta.institution.name
        let public_token = token

        let body = { 
            accounts:[ 
                {  
                    id, 
                    mask,
                    name,
                    subType,
                    type:this.props.acc.type === 'depository' || this.props.acc.type=== 'credit'? "depository":"investment",
                }
            ],
            inst_id,
            inst_name,
            relinking:true,
            public_token,
            type:this.props.acc.type === 'depository' || this.props.acc.type === 'credit'? "depository":"investment",
        }
        // console.log('body: ', body)

        // fetch(`${ENDPOINT}/plaid/link`,{
        fetch(`${ENDPOINT}/mobile-plaid/plaid/link`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(respJSON=>{
            console.log('respJSON: ', respJSON)
            if(respJSON.Error === 'Successful Linked'){
                this.props.updateAccs()
                this.setState({errorsSucceses:'Your account has been linked',plusDropdown:false,})
            }else{
                this.setState({errorsSucceses:respJSON.Error,plusDropdown:false,})
            }
        })
        .catch(err=>console.log('catching: ', err))
    }

    showRelink = ()=>{
        let acc = this.props.acc
        console.log('relink!')
        return(
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column', }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '50px',  backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)', width:'100%',  marginTop:25}}>
                    <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                        <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                            <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.product==='group'?'Trade Group': acc.ria? 'Directed Account':'Brokerage Account'}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full :`${acc.inst} ${acc.mask}`}</p>
                            <FaExclamationCircle size={35} color={'#ee5e68'}/>
                        </div>
                        
                        <div style={{ width:'100%', marginTop:10}}>
                            <p style={{fontWeight:'bold', fontSize:22, margin:0}}>This account needs to be relinked</p>
                            <p style={{fontWeight:'normal', fontSize:22, margin:0}}>This can occur when you change your account credentials at {acc.inst} or for security purposes when your account has been inactive for an extended period of time.</p>
                            <PlaidLink
                                clientName="ForeSCITE"
                                // env="sandbox"
                                env="production"
                                product={acc.type === 'depository' || acc.type=== 'credit'?["investments"]:['transactions']}
                                publicKey="3e65a03171afbab180fa7198f87cef"
                                onSuccess={this.plaidHandleOnSuccess}
                                linkCustomizationName={acc.type === 'depository' || acc.type=== 'credit'? 'bank':'brokerage'}
                                // className="test"
                                style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none',}}
                                // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                                // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                            >
                                <a onClick={()=>null} href='#'><p style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor, fontSize:22}}>Click Here to Relink</p></a>
                            </PlaidLink>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }

    formatPnLDollars(val){
        let x = '0.00'
        if (!val){
            x = '0.00'
        }
        else{
             x = this.addCommas(val.toFixed(2))
             if (val < 0){
                x = '-$'  + x.substring(1)
             }
             else{
                x = '$'  + x 
             }
        }
        return <strong style={{color:val<0?'rgb(238,94,104)':brandColor}}>({x})</strong>
    }
    formatPnLPercent(val){
        let x = '0.00'
        if (!val){
            x = '0.00'
        }
        else{
             x = this.addCommas(val.toFixed(2))
             
        }
        return <strong style={{color:val<0?'rgb(238,94,104)':brandColor}}>({x}%)</strong>
    }

    dateOnly(dt){
        let arr = dt.split(" ")
        return arr[2] + ' ' + arr[1] + ', ' + arr[3]
    }

    render(){        
        return this.showRelink()
    }
            
} 