import React from 'react'
import ENDPOINT from '../Endpoint'

export default class KeyStats extends React.Component{

    state={}

    componentDidMount(){
        // this.excessSearchForDing()
    }

    // excessSearchForDing = () => {
    //     const { symbol } = this.props.sourceData;
    //     this.setState({ isMomDataLoading: true });
    //     // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    //     let url = `${ENDPOINT}/search/ticker?search=${symbol.toUpperCase()}`;
    //     fetch(url)
    //         .then((res) => res.json())
    //         .then((responseJson) => {
    //             // console.log('kys search resp:', responseJson)
    //             let returnForecastValue = responseJson.content[0].returnForecastValue;
    //             if(responseJson.content[0].needOptimization){
    //                 this.setState({ returnForecastValue});
    //             }
    //             else{
    //                 this.setState({ returnForecastValue:'HIGH RISK'});
    //             }
    //         })
    //         .catch((err) => {
    //             this.setState({ isMomDataLoading: false });
    //             console.log(err);
    //         });
    // };

    parseDate=(dt)=>{
        if(!dt){
            return false
        }else{
            let full = new Date(dt * 1000)
            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            let month = full.getMonth()
            let day = full.getDate()
            let year = full.getFullYear()
            // console.log('dt: ', dt, `${months[month]} ${day}, ${year}`)
            return `${months[month]} ${day}, ${year}`
        }
      }

    

    formatCash=(cash)=>{
        let str
        if (cash > 1000 || cash < -1000){
          str= `$${(Math.abs(cash)/1000).toFixed(2)}B`
        }else{
          str= `$${Math.abs(cash).toFixed(2)}M`
        }
        if (cash < 0){
          return '-' + str
        }
        else{
          return str
        }
      }

    formatPcnt(value){
        return `${(value*100).toFixed(2)}%`
    }
    

    formatForecast(value){
        // let str = (value * this.props.sourceData.currentPrice.currentPrice + this.props.sourceData.currentPrice.currentPrice).toFixed(0)
        // let str = (value*this.props.sourceData.currentPrice.currentPrice+this.props.sourceData.currentPrice.currentPrice).toFixed(0)
        // return '$'+ this.addCommas(str)
        if(value === 'HIGH RISK'){
            return value
        }
        else{
            return '$'+ this.addCommas(value.toFixed(2))
        }
    }

    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatValue= (title, value)=>{
        // console.log(title,value)
        switch (title){
            case 'Correlation with S&P':
                return value.toFixed(2)
            case 'Revenue':
                return this.formatCash(value)
            case 'Operating cash flow':
                return this.formatCash(value)
            case 'Shares outstanding':
                return value
            case 'Coins outstanding':
                return value
            case 'Market capitalization':
                return value
            case 'Dividend yield':
                return this.formatPcnt(value)
            case 'Dividend amount':
                return value.toFixed(2)
            case 'Short interest rate':
                // console.log('SI val', value)
                return this.formatPcnt(value/100)
            case 'ForeSCITE Medium Term Forecast':
                // console.log("ValueForecast2",value)
                // return this.formatForecast(value)
                return this.formatForecast(value)
                case 'Ex dividend date':
                return this.parseDate(value)
            case 'Next earning date':
                return this.parseDate(value)
            case 'P/E Ratio':
                return value
            case 'Volumne 10D Average':
                return value
            case 'Daily Volumne':
                return this.addCommas(value)
        }
    }

    createRow = (title, value)=>{
        if(value && value !== 'N/A'){
            let date
            if (title === 'Ex dividend date' || title === 'Next earning date'){
                date = true
            }else{
                date = false
            }
            return(
                <div style={{width: '95%', display:'flex', justifyContent:'space-between', alignItems:'center', margin:'auto', borderRadius: 7,backgroundColor: 'rgba(247, 247, 247, 0.73)', padding:10, marginTop:'3%', marginBottom:'3%'}}>
                    <div style={{width:'60%'}}>
                        <p style={{color:'#242a38' ,margin:0}}>
                            {title}
                        </p>
                    </div>
                    <div style={{borderRadius: 15, backgroundColor: date? '#9295a1' : 'rgb(36, 42, 56)', padding: '2%',width:'38%'}}>
                        <p style={{color:'rgb(255,255,255)', margin:0, fontSize:'13px', textAlign:'center'}}>
                            {this.formatValue(title,value)} 
                        </p>
                    </div>
                </div>
            )
        }
    }

    render(){
        // console.log( 'ks props',this.props)
        const { leverage, shortInterest, dividendsAmount, nextEarningsDate, nextDividendsDate, currentPrice, cashFlow, revenue,returnForecast,returnForecastValue,sharesOutstanding_string,marketCap_string, dividend, isCrypto, PERatio, volume, volume10DAverage_string,  } = this.props.sourceData
        let correlation 
        if(this.props.sourceData.rSquared){
          correlation = this.props.sourceData.rSquared 
        }else{
          correlation = 0
        }
        if(this.props.isSmallScreen){
            return(
                <div style={{padding:10, width:'100%', display:'flex', flexDirection:"column"}}>
                    <div>
                        <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', textAlign: 'left', color: 'rgb(36, 42, 56)',marginBottom:5}}>
                            KEY STATISTICS AND EVENTS
                        </p>
                    </div>
                    <div>
                        {this.createRow('ForeSCITE Medium Term Forecast',returnForecastValue)}
                        {/* {this.createRow('Correlation with S&P',correlation)} */}
                        {this.createRow('Revenue',revenue)}
                        {this.createRow('Operating cash flow',cashFlow)}
                        {this.createRow('Dividend amount',dividendsAmount)}
                        {this.createRow('Dividend yield',dividend)}
                        {isCrypto? this.createRow('Coins outstanding',sharesOutstanding_string): this.createRow('Shares outstanding',sharesOutstanding_string)}
                        {this.createRow('Market capitalization',marketCap_string)}
                        {this.createRow('Ex dividend date',nextDividendsDate)}
                        {this.createRow('Next earning date',nextEarningsDate)}
                        {this.createRow('Short interest rate',shortInterest)}   
                    </div>
                </div>
            )
        }else{
            return(
                <div style={{padding:this.props.isSmallScreen?5: 10, height:'100%'}}>
                    <div>
                        <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)',marginBottom:5}}>
                            KEY STATISTICS AND EVENTS
                        </p>
                    </div>
                    <div>
                        {/* {this.createRow('ForeSCITE 30 day Forecast',returnForecast)} */}
                        {this.createRow('ForeSCITE Medium Term Forecast',returnForecastValue)}
                        {/* {this.createRow('Correlation with S&P',correlation)} */}
                        {this.createRow('Market capitalization',marketCap_string)}
                        {this.createRow('P/E Ratio', PERatio)}
                        {this.createRow('Daily Volumne', volume)}
                        {this.createRow('Volumne 10D Average', volume10DAverage_string)}
                        {this.createRow('Dividend amount',dividendsAmount)}
                        {this.createRow('Dividend yield',dividend)}
                        {isCrypto? this.createRow('Coins outstanding',sharesOutstanding_string): this.createRow('Shares outstanding',sharesOutstanding_string)}
                        {this.createRow('Short interest rate',shortInterest)}
                        {this.createRow('Revenue',revenue)}
                        {this.createRow('Operating cash flow',cashFlow)}
                        {this.createRow('Ex dividend date',nextDividendsDate)}
                        {this.createRow('Next earning date',nextEarningsDate)}
                    </div>
                </div>
            )
        }
    }
}