import React, { Component } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import SearchAndAddDialog from '../../../SearchAndAddDialog'
import '../../../../../scss/pages/account-activation/aistock/AIstockActivation.scss'
import DepositDialog from '../../../DepositDialog'
import Endpoint from '../../../../Endpoint'
import ConfirmationDialog from '../../../ConfirmationDialog'
import Axios from 'axios'
import SearchEndpoint from '../../../../SearchEndpoint'
import ProductActivationBackIcon from '../../../../../assets/product-activation-back-icon.svg'
export default class AIstockActivation extends Component {
  state = {
    initialAmount: Math.max(
      500,
      this.props.selectedAccount?.balances[0]?.available
    ),
    stocks: [],
    addStockDialog: false,
    errorMessage: '',
    showDepositForm: false,
    showConfirmationDialog: false,
    canTrade: {
      status: true,
    },
  }

  componentDidMount() {
    this.getMarketStatus()
  }
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.accounts !== this.props.accounts) {
  //     this.props.toggleActivation();
  //   }
  // };

  startAIstockTrader = async () => {
    this.setState({ isLoading: true })
    let body = {
      groupName: 'AI Trader',
      dw_account_id: this.props.selectedAccount.accountId,
      stocks: this.state.stocks.map((obj) => obj.symbol),
      amount: this.state.initialAmount,
    }
    return await Axios.post(
      `${Endpoint}/mobile/trade-groups/create-new`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then(async (response) => {
        if (response?.data?.Status && response?.data?.Status === 'Success') {
          await this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log('ai stock trader activation error', error)
        return false
      })
  }
  getMarketStatus = () => {
    fetch(`${SearchEndpoint}/istradinghour`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('can trade resp: ', responseJson)
        this.setState({ canTrade: responseJson })
      })
      .catch((err) => {
        console.log('get market status error', err)
      })
  }

  handleInitialAmountChange = (value) => {
    this.setState({ initialAmount: value })
  }

  toggleAddStockDialog = () => {
    this.setState({ addStockDialog: !this.state.addStockDialog })
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 5000)
  }

  header() {
    return (
      <div className='header-container'>
        <div className='left-col'>
          <p className='label'>{this.props.t('accountvalue')}</p>
          <p className='value'>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].virtualAccountValue
            )}
          </p>
        </div>
        <div className='right-col'>
          <p className='label'>{this.props.t('accountbuyingpower')}</p>
          <p className='value'>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].buyingPower
            )}
          </p>
        </div>
      </div>
    )
  }

  initialInvestmentForm() {
    return (
      <div className='initial-investment-form-container'>
        <p className='label'>{this.props.t('initialinvestment')}</p>
        <InputNumber
          value={this.state.initialAmount}
          min={Math.max(
            500,
            this.props.selectedAccount.balances[0].virtualAccountValue
          )}
          onValueChange={(e) => this.handleInitialAmountChange(e.value)}
          mode='currency'
          currency='USD'
          minFractionDigits={0}
          locale='en-US'
        />
      </div>
    )
  }

  requirementMessage() {
    let value =
      this.state.initialAmount -
      this.props.selectedAccount?.balances[0]?.available
    if (value > 0) {
      return (
        <div className='requirement-message-container'>
          <p className='message'>
            {this.props.t('additional')} {this.props.formatCurrency(value)} is
            {this.props.t('isrequiredtomeetyourinitialinvestment')}
          </p>
        </div>
      )
    }
  }

  stocksList() {
    return (
      <div className='stocks-list-container'>
        <p className='header'>
          {this.props.t('stocks')} ({this.state.stocks.length})
        </p>
        {this.state.stocks.map((stock, index) => {
          return this.getStockRow(stock, index)
        })}
        <div className='btn-container'>
          <Button
            label={this.props.t('addstocks')}
            className='add-stocks-btn'
            onClick={() => {
              this.toggleAddStockDialog()
            }}
          />
        </div>
      </div>
    )
  }

  getStockRow(stock, index) {
    return (
      <div className='stock-row'>
        <div className='image-and-info'>
          <div className='img-container'>
            {stock.imageURL === 'not-found' ? (
              <div className='not-found'>{stock.symbol.charAt(0)}</div>
            ) : (
              <img src={stock.imageURL} className='image' />
            )}
          </div>
          <div className='info'>
            <p className='symbol'>{stock.symbol}</p>
            <p className='company'>{stock.company}</p>
          </div>
        </div>
        <Button
          className='delete-btn'
          icon='pi pi-times'
          onClick={() => {
            this.removeStock(stock)
          }}
        />
      </div>
    )
  }

  addStock = (stock) => {
    let stockList = this.state.stocks
    if (stockList.length < 4) {
      if (stock.isDwSupported) {
        stockList.push(stock)
        this.setState({ stocks: stockList })
      } else {
        this.toggleErrorMessage(this.props.t('pleaseselectavalidstock'))
      }
    } else {
      this.toggleErrorMessage(this.props.t('cannotselectmorethan4stocks'))
    }
  }

  removeStock = (stock, index) => {
    let stockList = this.state.stocks
    stockList.splice(index, 1)
    this.setState({ stocks: stockList })
  }

  toggleShowDepositForm = () => {
    this.setState({ showDepositForm: !this.state.showDepositForm })
  }

  submitButton() {
    return (
      <div className='button-container'>
        {this.props.selectedAccount &&
        this.props.selectedAccount?.balances[0]?.available >=
          this.state.initialAmount ? (
          <Button
            className='submit-button'
            label={this.props.t('startaitrader')}
            onClick={() => {
              this.validateForm()
            }}
          />
        ) : (
          <Button
            className='submit-button'
            label={this.props.t('addcash')}
            onClick={() => {
              this.toggleShowDepositForm()
            }}
          />
        )}
      </div>
    )
  }

  validateForm = () => {
    if (this.state.stocks.length >= 2) {
      if (this.state.canTrade.status) {
        this.toggleShowConfirmationDialog()
      } else {
        this.toggleErrorMessage(this.props.t('marketsareonlyopenfrom'))
      }
    } else {
      this.toggleErrorMessage(this.props.t('pleaseselectatleast2stocks'))
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div className='ai-stock-activation-container'>
          <img
            src={ProductActivationBackIcon}
            height={25}
            width={25}
            onClick={() => {
              this.props.toggleActivation()
            }}
            style={{ cursor: 'pointer', marginTop: '25px' }}
          />
          <p className='ai-stock-header'>{this.props.t('ai stock trader')}</p>
          {this.header()}
          {this.initialInvestmentForm()}
          {this.requirementMessage()}
          {this.stocksList()}
          <p className='error'>{this.state.errorMessage}</p>
          {this.submitButton()}
          <DepositDialog
            toggleShowDepositForm={this.toggleShowDepositForm}
            selectedAccount={this.props.selectedAccount}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            showDepositForm={this.state.showDepositForm}
            isSmallScreen={this.props.isSmallScreen}
            accounts={this.props.accounts}
            t={this.props.t}
          />
          <SearchAndAddDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleAddDialog={this.toggleAddStockDialog}
            add={this.addStock}
            addDialog={this.state.addStockDialog}
            type='stock'
            t={this.props.t}
          />
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            name={this.props.t('startaitrader')}
            action={this.startAIstockTrader}
            successMessage={this.props.t(
              'aistocktraderhasbeensuccessfullyactivated'
            )}
            btnClassName='group'
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
