//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import { Dialog } from 'primereact/dialog'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'
import CreditCardIcon from '../../assets/credit-card-icon.png'

//STYLES
import '../../scss/components/budgeting/CreditDetails.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'
import { getTrend } from '../../utility-functions/getTrend'

export default function CreditDetails() {
  const { t, budgetingData, setPage, isSmallScreen } =
    useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const creditDetails = [...details?.credit_score_data]
  creditDetails.sort((a, b) => a.order - b.order)
  console.log(creditDetails)

  const [showDialog, setShowDialog] = useState(false)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')

  const tooltip = (desc, name) => {
    return `${name}: ${desc}`
  }

  const getMessage = () => {
    const creditScore = budgetingData?.payload?.credit_score
    if (creditScore < 629) return t('below_averge')
    else if (creditScore < 689) return t('average')
    else if (creditScore < 719) return t('good')
    else if (creditScore < 850) return t('excellent')
    else return ''
  }

  return (
    <div className='credit-details'>
      <div className='credit-details-content'>
        <div className='credit-details-row space-bottom'>
          <p className='credit-details-header no-margin'>
            {t('credit_analysis')}
          </p>
          <div
            className='blue-dot'
            onClick={(e) => {
              setShowDialog(true)
            }}
          >
            <p className='blue-dot-i'>i</p>
          </div>
        </div>
        <div className='credit-details-row'>
          <img src={CreditCardIcon} height={50} width={50} />
          <p className='credit-score'>{budgetingData?.payload?.credit_score}</p>
        </div>
        <div className='space-bottom-big' style={{ textAlign: 'center' }}>
          <p className='credit-text'>{getMessage()}</p>
          <p className='credit-message'>
            {t('based_on_investall_credit_rating')}
          </p>
        </div>
        <p className='credit-details-msg'>{t('credit_details_message')}</p>
        {creditDetails?.map((item, index) => {
          return (
            <Button
              className='budgeting-row underline'
              key={index}
              tooltip={tooltip(item.descr, item.name)}
              tooltipOptions={{ mouseTrack: true }}
              style={{ cursor: 'pointer', background: 'transparent' }}
            >
              <p className='budgeting-label big-text no-margin'>
                {t(item.name)}
              </p>
              <div className='row-value-and-trend'>
                <p className='budgeting-value big-text no-margin shift'>
                  {item.value}
                </p>
                {item.trend !== null ? (
                  <img src={getTrend(item.trend)} className='trend-icon' />
                ) : (
                  <div style={{ width: 30 }}></div>
                )}
              </div>
            </Button>
          )
        })}
      </div>

      <Dialog
        visible={showDialog}
        header={t('credit_score_disclaimer')}
        onHide={() => setShowDialog(false)}
        style={{ width: isSmallScreen ? '90vw' : '50vw' }}
      >
        <p>{t('info_credit_score')}</p>
      </Dialog>
    </div>
  )
}
