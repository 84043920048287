import React, { Component } from 'react'
import moment from 'moment'
import '../../scss/pages/cp3/Stats.scss'

export default class Stats extends Component {
  changeToPercent = (x) => {
    // return (Math.round(x*100) / 100) + '%'
    return (x * 100).toFixed(2) + '%'
  }

  separator(numb) {
    var str = numb.toString().split('.')
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return str.join('.')
  }

  render() {
    if (this.props.t) {
      return (
        <div className='stats-container'>
          <div className='key-stats'>
            <p className='stats-sub-header'>{this.props.t('keystats')}</p>
            <div className='info-container'>
              {this.props.selectedStock.marketCap_string && (
                <div className='stats-info'>
                  <p className='stats-label'>{this.props.t('marketcap')}</p>
                  <p className='stats-value'>
                    {this.props.selectedStock.marketCap_string}
                  </p>
                </div>
              )}

              {this.props.selectedStock.PERatio && (
                <div className='stats-info'>
                  <p className='stats-label'>{this.props.t('peratio')}</p>
                  <p className='stats-value'>
                    {this.props.selectedStock.PERatio}
                  </p>
                </div>
              )}

              {this.props.selectedStock.volume && (
                <div className='stats-info'>
                  <p className='stats-label'>{this.props.t('dailyvolume')}</p>
                  <p className='stats-value'>
                    {this.separator(this.props.selectedStock.volume_string)}
                  </p>
                </div>
              )}

              {this.props.selectedStock.volume10DAverage_string &&
                this.props.selectedStock.volume10DAverage_string !== 'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('volume10davg')}
                    </p>
                    <p className='stats-value'>
                      {this.props.selectedStock.volume10DAverage_string}
                    </p>
                  </div>
                )}

              {this.props.selectedStock.dividendsAmount && (
                <div className='stats-info'>
                  <p className='stats-label'>
                    {this.props.t('dividendamount')}
                  </p>
                  <p className='stats-value'>
                    {this.props.selectedStock.dividendsAmount}
                  </p>
                </div>
              )}

              {this.props.selectedStock.dividendsYield && (
                <div className='stats-info'>
                  <p className='stats-label'>{this.props.t('dividendyield')}</p>
                  <p className='stats-value'>
                    {this.changeToPercent(
                      this.props.selectedStock.dividendsYield
                    )}
                  </p>
                </div>
              )}

              {this.props.selectedStock.sharesOutstanding_string && (
                <div className='stats-info'>
                  <p className='stats-label'>
                    {this.props.t('sharesoutstanding')}
                  </p>
                  <p className='stats-value'>
                    {this.props.selectedStock.sharesOutstanding_string}
                  </p>
                </div>
              )}

              {this.props.selectedStock.shortInterest && (
                <div className='stats-info'>
                  <p className='stats-label'>{this.props.t('shortinterest')}</p>
                  <p className='stats-value'>
                    {this.changeToPercent(
                      this.props.selectedStock.shortInterest / 100
                    )}
                  </p>
                </div>
              )}

              {this.props.selectedStock.fully_diluted_valuation_string &&
                this.props.selectedStock.fully_diluted_valuation_string !==
                  'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('fullydilutedvaluation')}
                    </p>
                    <p className='stats-value'>
                      {this.props.selectedStock.fully_diluted_valuation_string}
                    </p>
                  </div>
                )}

              {this.props.selectedStock.circulating_supply_string &&
                this.props.selectedStock.circulating_supply_string !==
                  'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('circulatingsupply')}
                    </p>
                    <p className='stats-value'>
                      {this.props.selectedStock.circulating_supply_string}
                    </p>
                  </div>
                )}

              {this.props.selectedStock.total_supply_string &&
                this.props.selectedStock.total_supply_string !== 'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>{this.props.t('totalsupply')}</p>
                    <p className='stats-value'>
                      {this.props.selectedStock.total_supply_string}
                    </p>
                  </div>
                )}

              {/* {this.props.selectedStock.nextEarningsDate &&
                this.props.selectedStock.nextEarningsDate !== 'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('nextearningsdate')}
                    </p>
                    <p className='stats-value'>
                      {moment
                        .unix(this.props.selectedStock.nextEarningsDate)
                        .format('MMM D, YYYY')}
                    </p>
                  </div>
                )}

              {this.props.selectedStock.nextDividendsDate &&
                this.props.selectedStock.nextDividendsDate !== 'N/A' && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('nextdividenddate')}
                    </p>
                    <p className='stats-value'>
                      {moment
                        .unix(this.props.selectedStock.nextDividendsDate)
                        .format('MMM D, YYYY')}
                    </p>
                  </div>
                )} */}
            </div>
          </div>

          {!this.props.selectedStock?.isCrypto && (
            <div className='key-stats'>
              <p className='stats-sub-header'>
                {this.props.t('ratiosprofitability')}
              </p>
              <div className='info-container'>
                {this.props.stockData?.ttmData?.peRatioTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>{this.props.t('peratiottm')}</p>
                    <p className='stats-value'>
                      {this.props.stockData?.ttmData?.peRatioTTM.toFixed(2)}
                    </p>
                  </div>
                )}

                {this.props.stockData?.ttmData?.netProfitMarginTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('netmarginttm')}
                    </p>
                    <p className='stats-value'>
                      {(
                        this.props.stockData?.ttmData?.netProfitMarginTTM * 100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}

                {this.props.stockData?.ttmData?.priceToSalesRatioTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('pricesalesttm')}
                    </p>
                    <p className='stats-value'>
                      {this.props.stockData?.ttmData?.priceToSalesRatioTTM.toFixed(
                        2
                      )}
                    </p>
                  </div>
                )}

                {this.props.stockData?.ttmData?.enterpriseValueMultipleTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('enterprisevaluettm')}
                    </p>
                    <p className='stats-value'>
                      {this.props.stockData?.ttmData?.enterpriseValueMultipleTTM.toFixed(
                        2
                      )}
                    </p>
                  </div>
                )}

                {this.props.stockData?.ttmData?.operatingProfitMarginTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('operatingmarginttm')}
                    </p>
                    <p className='stats-value'>
                      {(
                        this.props.stockData?.ttmData
                          ?.operatingProfitMarginTTM * 100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}

                {this.props.stockData?.ttmData?.returnOnEquityTTM && (
                  <div className='stats-info'>
                    <p className='stats-label'>
                      {this.props.t('returnonequityttm')}
                    </p>
                    <p className='stats-value'>
                      {(
                        this.props.stockData?.ttmData?.returnOnEquityTTM * 100
                      ).toFixed(2)}
                      %
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {!this.props.selectedStock?.isCrypto && (
            <div className='key-stats'>
              <p className='stats-sub-header'>{this.props.t('events')}</p>
              <div className='info-container'>
                {this.props.selectedStock.nextEarningsDate &&
                  this.props.selectedStock.nextEarningsDate !== 'N/A' && (
                    <div className='stats-info'>
                      <p className='stats-label'>
                        {this.props.t('nextearningsdate')}
                      </p>
                      <p className='stats-value'>
                        {moment
                          .unix(this.props.selectedStock.nextEarningsDate)
                          .format('MMM D, YYYY')}
                      </p>
                    </div>
                  )}

                {this.props.selectedStock.nextDividendsDate &&
                  this.props.selectedStock.nextDividendsDate !== 'N/A' && (
                    <div className='stats-info'>
                      <p className='stats-label'>
                        {this.props.t('nextdividenddate')}
                      </p>
                      <p className='stats-value'>
                        {moment
                          .unix(this.props.selectedStock.nextDividendsDate)
                          .format('MMM D, YYYY')}
                      </p>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>

        // <div className='stats-container'>
        //   {this.props.selectedStock.marketCap_string ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('marketcap')}: </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.marketCap_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.PERatio ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('peratio')}: </p>
        //       <p className='stats-value'>{this.props.selectedStock.PERatio}</p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.volume ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('dailyvolume')}: </p>
        //       <p className='stats-value'>
        //         {this.separator(this.props.selectedStock.volume_string)}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.volume10DAverage_string &&
        //   this.props.selectedStock.volume10DAverage_string !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('volume10davg')}: </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.volume10DAverage_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.dividendsAmount ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('dividendamount')}: </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.dividendsAmount}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.dividendsYield ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('dividendyield')}: </p>
        //       <p className='stats-value'>
        //         {this.changeToPercent(this.props.selectedStock.dividendsYield)}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.sharesOutstanding_string ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>
        //         {this.props.t('sharesoutstanding')}:{' '}
        //       </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.sharesOutstanding_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.shortInterest ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('shortinterest')}: </p>
        //       <p className='stats-value'>
        //         {this.changeToPercent(
        //           this.props.selectedStock.shortInterest / 100
        //         )}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.fully_diluted_valuation_string &&
        //   this.props.selectedStock.fully_diluted_valuation_string !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>
        //         {this.props.t('fullydilutedvaluation')}:{' '}
        //       </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.fully_diluted_valuation_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.circulating_supply_string &&
        //   this.props.selectedStock.circulating_supply_string !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>
        //         {this.props.t('circulatingsupply')}:{' '}
        //       </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.circulating_supply_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.total_supply_string &&
        //   this.props.selectedStock.total_supply_string !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>{this.props.t('totalsupply')}: </p>
        //       <p className='stats-value'>
        //         {this.props.selectedStock.total_supply_string}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.nextEarningsDate &&
        //   this.props.selectedStock.nextEarningsDate !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>
        //         {this.props.t('nextearningsdate')}:{' '}
        //       </p>
        //       <p className='stats-value'>
        //         {moment
        //           .unix(this.props.selectedStock.nextEarningsDate)
        //           .format('MMM D, YYYY')}
        //       </p>
        //     </div>
        //   ) : null}
        //   {this.props.selectedStock.nextDividendsDate &&
        //   this.props.selectedStock.nextDividendsDate !== 'N/A' ? (
        //     <div className='stats-row'>
        //       <p className='stats-label'>
        //         {this.props.t('nextdividenddate')}:{' '}
        //       </p>
        //       <p className='stats-value'>
        //         {moment
        //           .unix(this.props.selectedStock.nextDividendsDate)
        //           .format('MMM D, YYYY')}
        //       </p>
        //     </div>
        //   ) : null}
        // </div>
      )
    } else return null
  }
}
