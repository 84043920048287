import React from 'react'
import Lottie from 'react-lottie'
import animation from '../../../myloader.json'
import { TailSpin } from 'react-loader-spinner'

export default function Loader({ logoLoader = false }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  if (logoLoader)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    )
  else {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin
          heigth='50'
          width='50'
          color='#3525e4'
          background='white'
          ariaLabel='loading'
        />
      </div>
    )
  }
}
