import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/goals/GoalSummary.scss'
import Axios from 'axios'
import { Dropdown } from 'primereact/dropdown'
import Endpoint from '../../../../Endpoint'
import { Button } from 'primereact/button'
import ConfirmationDialog from '../../../ConfirmationDialog'
import { TailSpin } from 'react-loader-spinner'
import ReactHighcharts from 'react-highcharts'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import Loader from '../../Loader'
import LinkPlaidBtn from '../../../LinkPlaidBtn'
export default class GoalSummary extends Component {
  state = {
    bankAccounts: [],
    goalDataFetched: false,
    selectedBankAccount: null,
    errorMessage: '',
    recurringAmount: 0,
    graphData: [],
    showConfirmationDialog: false,
  }

  componentDidMount() {
    this.getGoalProjection()
    this.getBankAccounts()
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 2500)
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  activateGoal = async () => {
    //API TO START GOAL
    let body = {
      goalName: this.props.selectedGoal,
      dw_account_id: this.props.selectedAccount.accountId,
      periodicContribution: this.state.recurringAmount,
      initialContribution: this.props.initialAmount,
      // strategy: this.props.selectedStrategy,
      strategy: this.props.strategyInfo[this.props.selectedStrategy]?.name,
      targetedAmount: this.props.goalAmount,
      duration: this.props.yearsAccumulate,
      frequency: 'Monthly',
      currentBuyingPower: this.props.selectedAccount.balances[0]?.buyingPower,
    }
    if (this.state.recurringAmount > 0)
      body.plaid_account_id = this.state.selectedBankAccount.accountId
    else body.plaid_account_id = ''
    return await Axios.post(
      `${Endpoint}/rebalance-mobile/goal-planning/execute`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        if (
          response?.data &&
          response?.data['Goals Execution Save'] === 'Completed'
        ) {
          this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  getBankAccounts = () => {
    let bankAccounts = []
    if (this.props.accounts) {
      this.props.accounts.forEach((account) => {
        if (account.type === 'depository')
          bankAccounts.push({ label: account.name, value: account })
      })
    }
    this.setState({ bankAccounts: bankAccounts })
  }

  handleDepositBankAccountChange = (value) => {
    this.setState({ selectedBankAccount: value })
  }

  getGoalProjection = async () => {
    await Axios.post(
      `${Endpoint}/mobile/goal-planning/projection`,
      {
        goalData: {
          compoundInterest: 'Compound Annual',
          contributionAmount: 0,
          contributionFrequency: 'Monthly',
          roi: this.props.strategyInfo[this.props.selectedStrategy]?.return,
          startingAmount: this.props.initialAmount,
          targetedAmount: this.props.goalAmount,
          yearsToAccumulate: this.props.yearsAccumulate,
        },
        option: {
          action: 'calculate',
        },
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        this.setState({
          recurringAmount:
            response?.data?.recurringAmounts?.totalContriAmt_notax,
          graphData: response?.data?.balanceData?.balance,
          goalDataFetched: true,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  summary() {
    return (
      <div className='summary-container'>
        <div className='row'>
          <p className='label'>{this.props.t('availablebalance')}</p>
          <p className='value'>
            {this.props.formatCurrency(
              this.props.selectedAccount?.balances[0]?.virtualAccountValue
            )}
          </p>
        </div>
        <div className='row'>
          <p className='label'>{this.props.t('initialinvestment')}</p>
          <p className='value'>
            {this.props.formatCurrency(this.props.initialAmount)}
          </p>
        </div>
        <div className='row'>
          <p className='label'>{this.props.t('investmentgoal')}</p>
          <p className='value'>
            {this.props.formatCurrency(this.props.goalAmount)}
          </p>
        </div>
        <div className='row'>
          <p className='label'>{this.props.t('periodiccontribution')}</p>
          <p className='value'>
            {this.props.formatCurrency(this.state.recurringAmount)}
          </p>
        </div>
        <div className='row'>
          <p className='label'>{this.props.t('rateofreturnofinvestment')}</p>
          <p className='value'>
            {(
              this.props.strategyInfo[this.props.selectedStrategy]?.return * 100
            ).toFixed(2)}
            %
          </p>
        </div>
      </div>
    )
  }

  barGraph() {
    return <div className='graph-container'></div>
  }

  bankAccountForm() {
    if (this.state.recurringAmount > 0) {
      return (
        <div className='bank-account-container'>
          <p className='bank-account-label'>
            {this.props.t('chooseabankaccountforperiodiccontribution')}
          </p>
          <Dropdown
            value={this.state.selectedBankAccount}
            options={this.state.bankAccounts}
            onChange={(e) => this.handleDepositBankAccountChange(e.value)}
            placeholder={this.props.t('selectanaccount')}
          />
          {this.state.bankAccounts?.length == 0 && (
            <div className='link-plaid-container'>
              <LinkPlaidBtn
                user={this.props.user}
                getAllAccountInfo={this.props.getAllAccountInfo}
                isBank={true}
                showButton={true}
                reLink={false}
                t={this.props.t}
              />
            </div>
          )}
        </div>
      )
    } else return null
  }

  validateForm() {
    if (this.state.recurringAmount > 0 && !this.state.selectedBankAccount) {
      this.toggleErrorMessage('Please select a bank account')
    } else {
      this.toggleShowConfirmationDialog()
    }
  }

  getConfig() {
    let totalDeposited = []
    let mergedData = []
    let balance = Object.values(this.state.graphData)
    for (let i = 1; i < balance.length; i++) {
      mergedData.push({
        name: i,
        Balance: balance[i].toFixed(2),
        'Total Deposited': (
          balance[0] +
          this.state.recurringAmount * 12 * i
        ).toFixed(2),
      })
    }
    return mergedData
  }

  formatX = (tickItem) => {
    return `$${tickItem}`
  }

  formatY = (tickItem) => {
    if (tickItem > 1) {
      return `${tickItem} Years`
    } else return `${tickItem} Year`
  }

  render() {
    if (this.props.t) {
      if (this.state.goalDataFetched) {
        return (
          <div className='goal-summary-container'>
            <p className='header'>{this.props.t('goalpreview')}</p>
            {this.summary()}
            {this.barGraph()}
            {/* <ReactHighcharts config={this.getConfig()} /> */}
            <div className='bar-chart'>
              <ResponsiveContainer>
                <BarChart data={this.getConfig()}>
                  <XAxis dataKey='name' tickFormatter={this.formatY} />
                  <YAxis tickFormatter={this.formatX} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey='Balance' fill='#3D32E7' />
                  <Bar dataKey='Total Deposited' fill='#7B61FF' />
                </BarChart>
              </ResponsiveContainer>
            </div>
            {this.bankAccountForm()}
            <p className='error'>{this.state.errorMessage}</p>
            <Button
              label={this.props.t('startgoal')}
              onClick={() => {
                this.validateForm()
              }}
              className='submit-btn'
            />
            <ConfirmationDialog
              name={this.props.t('activategoals')}
              action={this.activateGoal}
              isSmallScreen={this.props.isSmallScreen}
              toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
              showConfirmationDialog={this.state.showConfirmationDialog}
              successMessage={this.props.t('goalshasbeensuccessfullyactivated')}
              btnClassName='goals'
              t={this.props.t}
            />
          </div>
        )
      } else {
        return (
          <div className='loader'>
            <Loader logoLoader={false} />
          </div>
        )
      }
    } else return null
  }
}
