import React, { Component } from "react";
import PressBar from "../components/PressBar";

class PressRelease extends Component {
  render() {
    return (
      <div>
        {this.props.value.map(press => (
          <PressBar key={press.id} value={press} />
        ))}
      </div>
    );
  }
}

export default PressRelease;
