import React, { Component } from 'react'
import Loader from './Loader'
import GoalsBasicInfo from './goals/GoalsBasicInfo'
import GoalsInvestments from './goals/GoalsInvestments'
import GoalsRecurBuys from './goals/GoalsRecurBuys'
import GoalsPendingTransfers from './goals/GoalsPendingTransfers'
import GoalsSpecificInfo from './goals/GoalsSpecificInfo'
import OrderHistory from './OrderHistory'
import TransferHistory from './TransferHistory'
import TransferFundsDW from './TransferFundsDW'
import Statements from '../Statements'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import AccountActivity from '../AccountActivity'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
export default class GoalsAccountInfo extends Component {
  state = {
    activeTab: 'General Info',
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  tabs() {
    const customSelectedTab = { ...selectedTab, backgroundColor: '#3D32E7' }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          onClick={() => this.setState({ activeTab: 'General Info' })}
          style={
            this.state.activeTab === 'General Info'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t(this.props.selectedName)}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      return (
        <TransferFundsDW
          accounts={this.props.accounts}
          isSmallScreen={this.props.isSmallScreen}
          formatCurrency={this.props.formatCurrency}
          user={this.props.user}
          getAllAccountInfo={this.props.getAllAccountInfo}
          t={this.props.t}
          selectedAccount={this.props.selectedAccount}
        />
      )
    } else if (this.state.activeTab === 'Account Activity')
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  generalInfo() {
    return (
      <div>
        {this.props.productInstanceData &&
        this.props.accountTransactionsData ? (
          <>
            <GoalsBasicInfo {...this.props} />
            <GoalsSpecificInfo {...this.props} />
            <GoalsInvestments {...this.props} />
            <GoalsRecurBuys {...this.props} />
            <GoalsPendingTransfers {...this.props} />
          </>
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
        )}

        {/* {this.props.productInstanceData ? (
          <GoalsBasicInfo {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.productInstanceData ? (
          <GoalsSpecificInfo {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        <GoalsInvestments {...this.props} />
        {this.props.accountTransactionsData ? (
          <GoalsRecurBuys {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.accountTransactionsData ? (
          <GoalsPendingTransfers {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )} */}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '60%',
          }}
        >
          <img
            src={ProductActivationBackIcon}
            height={25}
            width={25}
            onClick={() => {
              this.props.resetSelectedAccount()
            }}
            style={{ cursor: 'pointer', marginBottom: '25px' }}
          />
          {this.tabs()}
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '80%',
              margin: 'auto',
              height: '100%',
            }}
          >
            {this.showSelectedTab()}
          </div>
        </div>
      )
    } else return null
  }
}
