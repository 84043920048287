import React, { Component } from 'react'
import ENDPOINT from '../Endpoint'
import { Card, Row, } from "react-bootstrap"
import MobileWl from './mobileWl'
import HeaderDropdown from '../HeaderDropdownESG'
import HeaderEnvironment from '../../assets/ESG/Header/esg_environment.png'
import HeaderSocial from '../../assets/ESG/Header/esg_social.png'
import HeaderCorporate from '../../assets/ESG/Header/esg_corporate.png'
import DiversityIcon from '../../assets/ESG/Icons/diversity_outer.png'
import LGBTQIcon from '../../assets/ESG/Icons/lgbtq_outer.jpg'
import SocialIcon from '../../assets/ESG/Icons/socialresponsible_outer.png'
import Top30Icon from '../../assets/ESG/Icons/top30_outer.png'
import WomenIcon from '../../assets/ESG/Icons/women_outer.png'
import DiversityBackground from '../../assets/ESG/Backgrounds/esg_diversity.png'
import LGBTQBackground from '../../assets/ESG/Backgrounds/esg_lgbtq2.png'
import SocialBackound from '../../assets/ESG/Backgrounds/esg_socialresponsible2.png'
import Top30Background from '../../assets/ESG/Backgrounds/esg_top302.png'
import WomenBackgound from '../../assets/ESG/Backgrounds/esg_women2.png'

const themeImg = {
    'Diversity': DiversityBackground,
    'LGBTQ': LGBTQBackground,
    'Socially Responsible': SocialBackound,
    'Top 30': Top30Background,
    'Women': WomenBackgound,
}


export default class ESGDashboard extends Component {

    state = {
        currentTheme: 'Top 30',
        ESG: '',
        descriptions: '',
    }
    topOfTickers = React.createRef();

    componentDidMount(){
        this.fetchESGInfo()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.currentTheme !== ''){
            if (prevState['currentTheme'] !== this.state.currentTheme){
                this.scrollTo()
            }
        }
    }

    fetchESGInfo = () => {
        // let url = `${ENDPOINT}/search/ticker-list`
        let url = 'https://api.allocaterite.com/search/esg-themes'
        fetch(url)
            .then((res) => res.json())
            .then((responseJson)=>{
                console.log(responseJson);
                this.setState({
                    ESG: responseJson.lists,
                    descriptions: responseJson.descriptions
                })
            })
    }

    scrollTo = () => {
        this.topOfTickers && this.topOfTickers.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    handleThemeChange = (theme) => {
        if(theme === this.state.currentTheme) {
            this.setState({currentTheme: ''})
        } else {
            this.setState({currentTheme: theme})
        }
    }

    showDescrption = () => {
        let data = this.state.descriptions
        let theme = this.state.currentTheme
        if(this.state.currentTheme === 'Socially Responsible')
            theme = 'Responsible'
        if(this.state.currentTheme === 'Top 30')
            theme = 'Top ESG'
        return (
            <p style={{fontSize: 18, textAlign: 'center', width: '67%'}}>{this.state.descriptions[theme]}</p>
        )
    }

    showTickers = () => {
        let data =  this.state.ESG
        let theme = this.state.currentTheme
        let mappedData = null
        if(this.state.currentTheme === 'Socially Responsible')
            theme = 'Responsible'
        if(this.state.currentTheme === 'Top 30')
            theme = 'Top ESG'
        if(data) {
            mappedData = [{ columnTitle:true}, ...data[theme]].map((ele, index)=>{
                return (<HeaderDropdown id={'ESGTicker_' + index} size={'large'} isCrypto={ele.industry === 'Cryptocurrency'} subheading={ele.subheading} img={ele.imageURL} remove={this.props.removeFromWl} editMode={this.state.editMode} companyName={ele.company} needOptimization={ele.needOptimization_updated} rf={ele.returnForecastValue} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>)
              })
        }
        return (
            <Card
                style={{
                    width: "100%",
                    // marginTop: 20,
                    border:'none'
                }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width:'100%' }}>
                </div>
                <div style={{width:'100%' , display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', padding:'2%',margin:this.props.isSmallScreen?'':'auto' }}>
                    {this.props.isSmallScreen ? 
                        data ?
                            <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data[theme]}/> :
                            null
                        : 
                        <div className="mapped" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
                            {
                              mappedData
                            }
                        </div>
                    }
                </div>
            </Card>
        );
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'flex-start', alignItems: 'center'}}>
                {
                    this.props.isSmallScreen ?
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <img style={{height: 85, width: 85}} src={HeaderEnvironment}/>
                        <img style={{height: 85, width: 85}} src={HeaderSocial}/>
                        <img style={{height: 85, width: 85}} src={HeaderCorporate}/>
                    </div> :
                    <div style={{width: '100%', maxWidth: 1000, display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <img style={{height: 150, width: 150}} src={HeaderEnvironment}/>
                        <img style={{height: 150, width: 150}} src={HeaderSocial}/>
                        <img style={{height: 150, width: 150}} src={HeaderCorporate}/>
                    </div>
                }
                <p style={{marginTop: '2%', fontSize: 30, fontWeight: 'bold'}}>ESG Dashboard</p>
                <p style={{fontSize: 18, textAlign: 'center', width: '67%'}}>The ForeSCITE ESG Reading is designed to measure a company’s resilience to long-term, industry material environmental, social and governance (ESG) risks. Our ESG Ratings range from leader, average to laggard. We also rate equities, loans, mutual funds, ETFs and countries. *Esg Score does not include cash, options and shorts</p>
                <div style={{marginTop: '2.5%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '80%', borderRadius: 20}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100',}}>
                        <p style={{fontSize: 30, fontWeight: 'bold'}}>Themes</p>
                        {
                            this.props.isSmallScreen ?
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: '80%'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '80%'}}>
                                        <div onClick={() => this.handleThemeChange('Top 30')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Top 30'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                            <img style={{height: 35, marginRight: 10}} src={Top30Icon}/>
                                            <p style={{margin: 0, textAlign: 'center', width: 55}}>Top 30</p>
                                        </div>                            
                                        <div onClick={() => this.handleThemeChange('Diversity')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Diversity'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                            <img style={{height: 35, marginRight: 10}} src={DiversityIcon}/>
                                            <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Diversity</p>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '80%'}}>
                                        <div onClick={() => this.handleThemeChange('LGBTQ')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'LGBTQ'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                            <img style={{height: 35, marginRight: 10}} src={LGBTQIcon}/>
                                            <p style={{margin: 0, textAlign: 'center', width: '80%'}}>LGBTQ</p>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '80%'}}>
                                        <div onClick={() => this.handleThemeChange('Socially Responsible')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Socially Responsible'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                            <img style={{height: 35, marginRight: 10}} src={SocialIcon}/>
                                            <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Socially Responsible</p>
                                        </div>
                                        <div onClick={() => this.handleThemeChange('Women')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Women'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                            <img style={{height: 35, marginRight: 10}} src={WomenIcon}/>
                                            <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Women</p>
                                        </div>
                                    </div>
                                </div> :
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '80%'}}>
                                    <div onClick={() => this.handleThemeChange('Top 30')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Top 30'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                        <img style={{height: 35, marginRight: 10}} src={Top30Icon}/>
                                        <p style={{margin: 0, textAlign: 'center', width: 55}}>Top 30</p>
                                    </div>                            
                                    <div onClick={() => this.handleThemeChange('Diversity')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Diversity'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                        <img style={{height: 35, marginRight: 10}} src={DiversityIcon}/>
                                        <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Diversity</p>
                                    </div>
                                    <div onClick={() => this.handleThemeChange('LGBTQ')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'LGBTQ'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                        <img style={{height: 35, marginRight: 10}} src={LGBTQIcon}/>
                                        <p style={{margin: 0, textAlign: 'center', width: '80%'}}>LGBTQ</p>
                                    </div>
                                    <div onClick={() => this.handleThemeChange('Socially Responsible')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Socially Responsible'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                        <img style={{height: 35, marginRight: 10}} src={SocialIcon}/>
                                        <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Socially Responsible</p>
                                    </div>
                                    <div onClick={() => this.handleThemeChange('Women')} style={{height: 50, display: 'flex',cursor: 'pointer',borderRadius:5,border:'solid',borderColor: this.state.currentTheme === 'Women'? 'rgb(29,32,42)' : '#dddddd', justifyContent:'center',alignItems:'center', paddingLeft: 10, paddingRight: 10, marginLeft:4,marginRight:4, marginTop: 2, objectFit: 'contain'}}>
                                        <img style={{height: 35, marginRight: 10}} src={WomenIcon}/>
                                        <p style={{margin: 0, textAlign: 'center', width: '80%'}}>Women</p>
                                    </div>
                                </div>
                        }
                    </div>                    
                    
                    {
                            this.state.currentTheme ?
                                <div style={{marginTop: '2%',display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
                                    <p style={{fontSize: 25, fontWeight: 'bold'}}>{this.state.currentTheme}</p>
                                    {
                                        this.showDescrption()
                                    }
                                    <img src={themeImg[this.state.currentTheme]} style={{height: 250}}/>
                                    <div style={{position:'absolute', top:this.props.isSmallScreen ? 1300 : 1250, zIndex:-100000}} ref={(ele) => (this.topOfTickers = ele)}></div>
                                    {
                                        this.showTickers()
                                    }
                                </div> :
                                null
                    }
                </div>
            </div>
        )
    }
}
