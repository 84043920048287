import React, { createContext, useEffect, useRef, useState } from 'react'
import MainScreen from './MainScreen'
import './LandingPage.scss'
import ProductScreen from './ProductScreen'
import AutomatedPrecisionScreen from './AutomatedPrecisionScreen'
import AutomateCrypto from './AutomateCrypto'
import PublicFooter from '../../PublicFooter'
import SeeFuture from './SeeFuture'
import { getSingleStockData } from '../../../services/stockService'
import MainNavBar from '../../components/MainNavBar'
import AggregateAccounts from './AggregateAccounts'
import { Carousel } from 'primereact/carousel'
import { getApkData } from '../../../services/generalService'
import DownloadBanner from '../../DownloadBanner'
import { useSelector } from 'react-redux'
import ZeroEmotions from './ZeroEmotions'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import DownloadPage from './DownloadPage'

export const LandingPageContext = createContext()

export default function Index(props) {
  const [data, setData] = useState({})
  const [apkData, setApkData] = useState({})
  const [showBanner, setShowBanner] = useState(true)
  const [carouselColor, setCarouselColor] = useState('transparent')
  const [page, setPage] = useState(0)
  const { general } = useSelector((state) => state)
  const [blueHeader, setBlueHeader] = useState(false)
  const { isSmallScreen } = general
  const bluepageRef = useRef(null)

  useEffect(() => {
    fetchData()
    fetchApkData()
  }, [])

  useEffect(() => {
    if (!isSmallScreen) window.zE('webWidget', 'show')
    return () => {
      window.zE('webWidget', 'hide')
    }
  }, [isSmallScreen])

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)

    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  })

  const listenToScroll = () => {
    const offSetTop = bluepageRef.current.offsetTop
    const height = bluepageRef.current.clientHeight
    const scrollPos = window.scrollY
    if (scrollPos >= offSetTop && scrollPos <= offSetTop + height) {
      if (!blueHeader) setBlueHeader(true)
    } else {
      if (blueHeader) setBlueHeader(false)
    }
  }

  const fetchData = async () => {
    const res = await getSingleStockData('AMZN')
    console.log(res)
    if (res.content) {
      setData(res.content[0]['AMZN'])
    }
  }

  const fetchApkData = async () => {
    const res = await getApkData()
    setApkData(res)
  }

  const handlePageChange = (slide) => {
    setPage(slide)
    if (slide === 1) setCarouselColor('#3728E4')
    else setCarouselColor('transparent')
  }

  const timeOutMessage = () => {
    return (
      <div className='timeout-popup'>
        <div className='timeout-content'>
          <p className='timeout-message'>You have been timed out</p>
          <p className='close-timeout-btn'>X</p>
        </div>
      </div>
    )
  }

  if (props.t) {
    return (
      <LandingPageContext.Provider
        value={{ t: props.t, data: data, apkData: apkData }}
      >
        <div className='landing-page'>
          <MainNavBar {...props} blue={blueHeader} />
          <MainScreen />
          {isSmallScreen && <DownloadPage />}
          <div ref={bluepageRef}>
            <ProductScreen />
          </div>
          <AutomatedPrecisionScreen />
          <SeeFuture />
          <ZeroEmotions />
          <AggregateAccounts />
          <PublicFooter isSmallScreen={false} t={props.t} />
          {isSmallScreen && showBanner && (
            <DownloadBanner setShowBanner={setShowBanner} />
          )}
        </div>
      </LandingPageContext.Provider>
    )
  } else return null
}
