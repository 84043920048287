import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class TotalInvestments extends Component {
  state = {
    showAllInvestmentList: false,
  }

  displayMyInvestments() {
    const holdings = this.props.holdings
    if (holdings && holdings.length > 0) {
      return (
        <div style={{ overflow: 'auto', height: '100%' }}>
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '100%',
              margin: 'auto',
              boxShadow:
                '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
              borderRadius: '19px',
              height: 'auto',
              maxHeight: '100%',
              minWidth: '500px',
              overflow: 'auto',
              // height: "100%",
            }}
          >
            <div style={{ padding: '10px', height: '100%' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '15px',
                  padding: '15px 0 0 0',
                }}
              >
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#272748',
                      fontSize: '15px',
                      verticalAlign: 'middle',
                      marginBottom: '0',
                      textAlign: 'left',
                      fontFamily: 'Akkurat-Light',
                    }}
                  >
                    {this.props.t('symbol')}
                  </p>
                </div>
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#272748',
                      fontSize: '15px',
                      verticalAlign: 'middle',
                      marginBottom: '0',
                      textAlign: 'left',
                      fontFamily: 'Akkurat-Light',
                    }}
                  >
                    {this.props.t('shares')}
                  </p>
                </div>
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#272748',
                      fontSize: '15px',
                      verticalAlign: 'middle',
                      marginBottom: '0',
                      textAlign: 'left',
                      fontFamily: 'Akkurat-Light',
                    }}
                  >
                    {this.props.t('costbasis')}
                  </p>
                </div>
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#272748',
                      fontSize: '15px',
                      verticalAlign: 'middle',
                      marginBottom: '0',
                      textAlign: 'left',
                      fontFamily: 'Akkurat-Light',
                      textAlign: 'center',
                    }}
                  >
                    {this.props.t('marketvalue')}
                  </p>
                </div>
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <p
                    style={{
                      color: '#272748',
                      fontSize: '15px',
                      verticalAlign: 'middle',
                      marginBottom: '0',
                      textAlign: 'left',
                      fontFamily: 'Akkurat-Light',
                      textAlign: 'right',
                    }}
                  >
                    {this.props.t('pl')}
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: 'auto',
                  maxHeight: '80%',
                  overflow: 'auto',
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                }}
              >
                {holdings.map((stock, index) => {
                  return this.renderInvestmentRow(stock, index)
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  renderInvestmentRow(stock, index) {
    return (
      <NavLink
        style={{ textDecoration: 'none' }}
        key={index}
        to={`/Dashboard/Trade/${stock.symbol}`}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            borderBottom:
              index !== this.props.holdings?.length - 1
                ? '1px solid rgba(128,128,128,.1)'
                : '',
            alignItems: 'center',
            padding: '10px 0',
          }}
        >
          <div style={{ width: '20%' }}>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '17px',
                marginBottom: '0',
                color: '#272748',
                textAlign: 'left',
              }}
            >
              {stock.symbol}
            </p>
            {/* <p
              style={{
                fontSize: "10px",
                color: "rgba(128,128,128,.8)",
                textAlign: "left",
              }}
            >
              {this.props.formatCurrency(stock.instPrice)}
            </p> */}
          </div>
          <div style={{ width: '20%' }}>
            <p
              style={{
                color: '#272748',
                textAlign: 'center',
                fontSize: '17px',
                textAlign: 'left',
                margin: 0,
              }}
            >
              {stock.quantity < 1
                ? stock.quantity.toFixed(4)
                : stock.quantity.toFixed(2)}
            </p>
          </div>
          <div style={{ width: '20%' }}>
            <p
              style={{
                color: '#272748',
                textAlign: 'center',
                fontSize: '17px',
                textAlign: 'left',
                margin: 0,
              }}
            >
              {this.props.formatCurrency(stock.averagePrice)}
            </p>
          </div>
          <div style={{ width: '20%' }}>
            {stock.type === 'Crypto' ? (
              <p
                style={{
                  color: '#272748',
                  textAlign: 'center',
                  fontSize: '17px',
                  textAlign: 'center',
                  margin: 0,
                }}
              >
                {this.props.formatCurrency(stock.instValue)}
              </p>
            ) : (
              <p
                style={{
                  color: '#272748',
                  textAlign: 'center',
                  fontSize: '17px',
                  textAlign: 'center',
                  margin: 0,
                }}
              >
                {this.props.formatCurrency(stock.instValue)}
              </p>
            )}
          </div>
          <div style={{ width: '20%' }}>
            {stock.unrealizedPL > 0 ? (
              <p
                style={{
                  color: '#3525e4',
                  textAlign: 'right',
                  fontSize: '17px',
                  margin: 0,
                }}
              >
                +{this.props.formatCurrency(stock.unrealizedPL)}
              </p>
            ) : (
              <>
                {stock.unrealizedPL < 0 ? (
                  <p
                    style={{
                      color: '#3D5062',
                      textAlign: 'right',
                      fontSize: '17px',
                      margin: 0,
                    }}
                  >
                    {this.props.formatCurrency(stock.unrealizedPL)}
                  </p>
                ) : (
                  <p
                    style={{
                      color: 'black',
                      textAlign: 'right',
                      fontSize: '17px',
                      margin: 0,
                    }}
                  >
                    {this.props.formatCurrency(stock.unrealizedPL)}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </NavLink>
    )
  }

  render() {
    if (this.props.t) {
      return <div style={{ height: '100%' }}>{this.displayMyInvestments()}</div>
    } else return null
  }
}

// const mapStateToProps = (state) => {
//   return {
//     accounts: state.account.accounts
//   };
// };

// export default connect(mapStateToProps, actions)(TotalInvestments);
