import React, { useContext } from 'react'
import PlayStoreIcon from '../../../assets/play-store-icon-black-background.png'
import AppStoreIcon from '../../../assets/app-store-icon-black-background.png'
import ApkIcon from '../../../assets/apk-download-black-background.png'
import { LandingPageContext } from '.'
import { sendApkIncrement } from '../../../services/generalService'
import DownArrow from '../../../assets/DownArrowBlack.svg'

export default function DownloadPage() {
  const { t, apkData } = useContext(LandingPageContext)

  const incrementApk = async () => {
    const response = await sendApkIncrement(apkData?.version)
    console.log('res', response)
  }

  return (
    <div className='download-page'>
      <div className='download-icons-container'>
        <div className='download-header-container'>
          <p className='download-header'>{t('gettheapp')}</p>
          <img src={DownArrow} className='down-arrow' />
        </div>
        <p className='download-text'>{t('download_app_text')}</p>
        <div className='icons-container'>
          <div>
            <a
              href='https://itunes.apple.com/us/app/forescite/id1567589436'
              target='_blank'
            >
              <img src={AppStoreIcon} className='download-icon' />
            </a>
            {/* <p className='notice-text-small'>*{t('notavailableinchina')}</p> */}
          </div>
          <div>
            <a
              href='https://play.google.com/store/apps/details?id=com.forescite'
              target='_blank'
            >
              <img src={PlayStoreIcon} className='download-icon' />
            </a>
            {/* <p className='notice-text-small'>*{t('notavailableinchina')}</p> */}
          </div>
          <div>
            <a
              href={apkData?.url}
              target='_blank'
              onClick={() => {
                incrementApk()
              }}
            >
              <img src={ApkIcon} className='download-icon' />
            </a>
            <p className='notice-text-small'>{t('downloadapkmessage')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
