//REACT
import React, { useState } from 'react'
import { useEffect } from 'react'

//REACT LIBRARIES
import { TabView, TabPanel } from 'primereact/tabview'
import { InputText } from 'primereact/inputtext'

//STYLES
import '../../scss/components/Promotions.scss'

//ICONS
import WarningIcon from '../../assets/warning.svg'

//APIS
import { applyPromoCode, fetchRewards } from '../../services/userServices'
import Loader from '../components/accountInfo/Loader'
import formatCurrency from '../../utility-functions/formatCurrency'
export default function Promotions(props) {
  const [activeTab, setActiveTab] = useState(0)
  const [promoCode, setPromoCode] = useState('')
  const [rewards, setRewards] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSucces] = useState('')
  const [showRequirements, setShowRequirements] = useState(false)

  useEffect(() => {
    fetchPromoData()
  }, [])

  useEffect(() => {
    setError('')
    setSucces('')
  }, [activeTab])

  const fetchPromoData = async () => {
    const res = await fetchRewards(props.user?.idToken?.jwtToken)
    setRewards(res?.output)
  }

  const submitPromoCode = async () => {
    setLoading(true)
    const res = await applyPromoCode(props?.user?.idToken?.jwtToken, promoCode)
    if (res?.status) {
      setSucces(props.t('promo_applied_successfully'))
      await fetchPromoData()
    } else {
      switch (res?.reason) {
        case 'User is an already existing user or has already applied this promotion':
          setError(props.t('user_is_an_already_existing_user'))
          break
        case 'Invalid Promo Code':
          setError(props.t('invalid_promo_code'))
          break
        default:
          setError(props.t('somethingwentwrong'))
          break
      }
    }
    setLoading(false)
  }

  const renderPromoCode = () => {
    if (loading) {
      return (
        <div className='promo'>
          <Loader size='large' />
        </div>
      )
    } else {
      if (rewards?.length > 0) {
        const reward = rewards?.[0]
        return (
          <div className='promo'>
            <p className='promo-big-text'>{props.t('success')}!</p>
            <p className='promo-medium-text'>{reward?.code}</p>
            <p className='promo-small-text'>
              {props.t('you_have_been_subscribed_to_a_new_reward')}
            </p>
            <p className='promo-medium-text space-top-big'>
              {props.t('eligibility')}
            </p>
            <p className='promo-small-text'>{props.t(reward?.reason)}</p>
          </div>
        )
      } else {
        return (
          <div className='promo'>
            <div className='promo-row'>
              <InputText
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                placeholder={props.t('enter_promo_code')}
                className='promo-input'
              />
              <button
                className='promo-btn'
                onClick={() => {
                  if (!promoCode) setError(props.t('please_enter_promo_code'))
                  else submitPromoCode()
                }}
              >
                {props.t('submit')}
              </button>
            </div>
            <div className='message-block'>
              {error && <p className='error'>{error}</p>}
              {success && <p className='success'>{success}</p>}
            </div>
          </div>
        )
      }
    }
  }

  const renderRewards = () => {
    if (rewards?.length > 0) {
      const reward = rewards?.[0]
      return (
        <div className='rewards'>
          <div className='account-card reward-card'>
            <p className='promo-small-text promo-light-text right-text no-margin'>
              {props.t('reward_amount')}:
            </p>
            <div className='promo-row'>
              <p className='promo-medium-text'>{reward?.code}</p>
              <p className='promo-medium-text'>
                {formatCurrency(reward?.rewardAmount)}
              </p>
            </div>
            {!reward?.status && (
              <div
                className='promo-row-left clickable'
                onClick={() => {
                  setShowRequirements((prevState) => !prevState)
                }}
              >
                <img src={WarningIcon} className='warning-icon' />
                <p className='promo-alert-text no-margin'>
                  {props.t('requirements_not_met')}
                </p>
                {/* <p className='arrow-btn'>{'>'}</p> */}
              </div>
            )}
            {showRequirements && promoRequirements()}
          </div>
        </div>
      )
    } else {
      return (
        <div className='rewards'>
          <p className='reward-text'>{props.t('no_rewards_available')}</p>
        </div>
      )
    }
  }

  const promoRequirements = () => {
    return (
      <div className='requirements'>
        <div className='promo-row-left success'>
          <p className='requirement'>1. {props.t('enter_promo_code')}</p>
        </div>
        <div className='promo-row-left success'>
          <p className='requirement'>2. {props.t('must_be_a_new_user')}</p>
        </div>
        <div className='promo-row-left error'>
          <p className='requirement'>
            3. {props.t('deposit_minimum_100_dollars')}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className='promotions'>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}
        className='categorized-investments-tab'
      >
        {/* <TabPanel className='investments-tab' header={props.t('my_code')}>
          <p>hi</p>
        </TabPanel> */}
        <TabPanel className='investments-tab' header={props.t('promo_code')}>
          {renderPromoCode()}
        </TabPanel>
        <TabPanel className='investments-tab' header={props.t('rewards')}>
          {renderRewards()}
        </TabPanel>
      </TabView>
    </div>
  )
}
