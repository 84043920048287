import React, { useContext } from 'react'
import Budgeting from '../../../assets/budgeting-img.png'
import CryptoTrader from '../../../assets/crypto-trader-img.png'
import { LandingPageContext } from '.'
export default function AutomatedPrecisionScreen() {
  const { t } = useContext(LandingPageContext)
  return (
    <div className='automated-precision'>
      <p className='automated-header'>{t('automatedprecision')}</p>
      <div className='automated-content'>
        <div className='left'>
          <img src={Budgeting} className='budgeting-img' />
          <div className='bottom-content'>
            <p className='header'>{t('Budgeting')}</p>
            <p className='description'>
              {t(
                'aggregateallyouraccountsseeinsifhtsandtrendsandtrackyourexpenses'
              )}
            </p>
            {/* <button className='learn-more-btn'>{t('learnmore')}</button> */}
          </div>
        </div>
        <div className='right'>
          <img src={CryptoTrader} className='crypto-trader-img' />
          <div className='bottom-content'>
            <p className='header'>{t('cryptotrader')}</p>
            <p className='description'>{t('exploretheworlds')}</p>
            {/* <button className='learn-more-btn'>{t('learnmore')}</button> */}
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}
