import React, { Component } from 'react'
import {
  // BrowserRouter as Router,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import ReactGA from 'react-ga'
import { connect } from 'react-redux'

/* Layout */
import Header from './components/layout/Header'

/* Page */
import Home from './components/pages/Home'
import AiHome from './components/pages/AiHome'
import Home2 from './components/pages/Home2'
import Platform from './components/pages/Platform2'
import Strategies from './components/pages/Strategies'
import Insights from './components/pages/Insights'
// import Login from "./components/pages/Login";
import Login from './components/pages/Login2'
// import SignUp from "./components/pages/SignUp";
import WatchList from './components/pages/UserWatchList'
// import UserDashboard from "./components/pages/UserDashboard";
import Dashboard from './components/pages/Dashboardv2'
import About from './components/pages/About2'
import VideoBay from './components/pages/VideoBay'
import Team from './components/pages/Team'
import FreeTrial from './components/pages/FreeTrial'
import Contact from './components/pages/Contact2'
import PrivacyPolicy from './components/pages/PrivacyPolicy'
import EULA from './components/pages/EULA'
import TermsOfUse from './components/pages/TermsOfUse'
import GeneralDisclaimer from './components/pages/GeneralDisclaimer'
import CryptoAgreement from './components/pages/CryptoAgreement'
import ApexNewAccountAggrements from './components/pages/ApexNewAccountAgreements'
import PubLowPrice from './components/pages/PubLowPrice'
import Disclosures from './components/pages/Disclosures'
import InvestingTerms from './components/pages/InvestingTerms'
import AllocateRiteForecastDisclaimer from './components/pages/AllocateRiteForecastDisclaimer'
import USDomestic from './components/pages/USDomestic'
import Careers from './components/pages/Careers2'
import DiversifiedInternational from './components/pages/DiversifiedInternational'
import DynamicGlobalBlend from './components/pages/DynamicGlobalBlend'
import DisclaimersDisclosures from './components/pages/DisclaimersDisclosures'
import ProvideList from './components/pages/ProvideList'
import UnderConstruction from './components/pages/UnderConstruction'
import ChineseHome from './components/pages/ChineseHome'
import ChinesePrivacyPolicy from './components/pages/ChinesePrivacyPolicy'
import ChineseTermsOfUse from './components/pages/ChineseTermsOfUse'
import ChineseGeneralDisclaimer from './components/pages/ChineseGeneralDisclaimer'
import ChinesePlatform from './components/pages/ChinesePlatform'
import ChineseStrategies from './components/pages/ChineseStrategies'
import ChineseTeam from './components/pages/ChineseTeam'
import ChineseInsights from './components/pages/ChineseInsights'
import AccountRegistrationForm from './components/pages/AccountRegistrationForm'
import CompanyPage from './components/pages/Cp3'
import LandingPage from './components/pages/LandingPage2'
import GoogleHome from './components/pages/GoogleHome'
import Search2 from './components/pages/Search2'
import NoMatch from './components/pages/NoMatch'
import CreateDwAcc from './components/pages/DwCreate'
import Transfer from './components/pages/Transfers'
import Questionnaire from './components/pages/Questionnaire'
import UpdateDw from './components/pages/UpdateDw'
import Links from './components/pages/Links'
import ProductsInfo from './components/pages/landingPageInformationalPages/Products'
import StocksInfo from './components/pages/landingPageInformationalPages/Stocks'
import CryptoInfo from './components/pages/landingPageInformationalPages/Crypto'
import ToolsInfo from './components/pages/landingPageInformationalPages/Tools'

//Landing Page
import NewLandingPage from './components/pages/landing-page'

/* Style sheet */
import './App.scss'
import './components/layout/Style/style.scss'

/** AWS AUTH  */
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from './aws-config'
import { withAuthenticator } from 'aws-amplify-react'
import DWDisclosures from './components/pages/disclosures/DWDisclosures'
import ApexDisclosures from './components/pages/disclosures/ApexDisclosures'
import ForesciteDisclosures from './components/pages/disclosures/ForesciteDisclosures'

//PRIME REACT
import 'primereact/resources/themes/md-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getUserLocation } from './services/generalService'

import IdleTimer from 'react-idle-timer'

import hookWrapper from '../src/hookWrapper'
import Timeout from './components/pages/Timeout'
import { setIsSmallScreen } from './features/general'
import { setTimeOut } from './features/user'
import ScrollToTop from './components/ScrollToTop'
import SignIn from './components/pages/SignIn'
import SignUp from './components/pages/SignUp'
import ForgotPassword from './components/pages/ForgotPassword'
import i18n from 'i18next'
import Support from './components/pages/Support'
import AppRedirect from './components/pages/AppRedirect'
import AsaLogin from './components/pages/AsaLogin'
import ProductPage from './components/pages/product-page/ProductPage'
import PromoPage from './components/pages/PromoPage'
import PromoIntroPage from './components/pages/PromoIntroPage'
import AsaEmail from './components/pages/AsaEmail'
import AsaSignup from './components/pages/AsaSignup'
import SignUpASA from './components/pages/SignInASA'
import SignInASA from './components/pages/SignInASA'
import SignInMoneyNet from './components/pages/SignInMoneyNet'
const history = createBrowserHistory()

ReactGA.initialize('UA-101523183-1')
ReactGA.pageview('/')
require('typeface-ubuntu')

//--------WHICH USER POOL? -------------------------
Amplify.configure(awsconfig.prod) //PRODUCTION  |
// Amplify.configure(awsconfig.dev) //DEV          |
//--------------------------------------------------

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAZr6mCB6Ykfe042cblJbR_6MGyPKDtvfE',
  authDomain: 'allocaterite-native-app.firebaseapp.com',
  databaseURL: 'https://allocaterite-native-app.firebaseio.com',
  projectId: 'allocaterite-native-app',
  storageBucket: 'allocaterite-native-app.appspot.com',
  messagingSenderId: '58514042943',
  appId: '1:58514042943:web:41300a5d8f3e5a9a23488a',
  measurementId: 'G-66R90HVHZ8',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      errors: false,
      isSmallScreen: true,
      retryUser: '',
    }
    this.topOfPage = React.createRef()
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.timer = null
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
    i18n.changeLanguage('en')
    this.updateWindowDimensions()
    // this.updateWindowDimensions()
    ReactGA.pageview(window.location.pathname + window.location.search)
    if (this.state.user === null) {
      Auth.currentSession().then((resp) => {
        this.setState({ user: resp })
      })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions)
    clearTimeout(this.timer)
  }

  handleOnIdle(event) {
    if (this.state.user) {
      try {
        const response = Auth.signOut()
        this.setState({ user: null })
        history.push('/signin')
        this.props.setTimeOut(true)
      } catch (error) {
        console.log('error signing out: ', error)
      }
    }
  }

  setUser = (user, customRoute) => {
    if (customRoute) {
      this.setState({ user: user }, () => {
        history.push(customRoute)
      })
    } else {
      this.setState({ user: user }, () => {
        history.push('/Dashboard')
      })
    }
  }

  signIn = (username, password) => {
    let user = false
    let error = ''
    Auth.signIn(username, password)
      .catch((error) => {
        console.log(error)
        switch (error.message) {
          case 'User does not exist.':
            error = 'Username not found'
            break
          case 'Incorrect username or password.':
            error = 'Incorrect Password'
            break
          case 'User is not confirmed.':
            error =
              'User is not confirmed. Please enter the confirmation code sent previously or request a new one.'
            this.setState({ retryUser: username })
            break
          default:
            // code block
            console.log('Not Caught Error: ' + error.message)
            break
        }
        this.setState({ errors: error })
      })
      .then(Auth.currentSession())
      .then((resp) => {
        console.log('login resp', resp)
        user = resp
        if (user) {
          this.setState({ user })
        }
      })
  }

  signOut = () => {
    try {
      const response = Auth.signOut()
      this.setState({ user: null })
      history.push('/')
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }
  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      //this.setState({ isSmallScreen: true })
      this.props.setIsSmallScreen(true)
    } else {
      this.props.setIsSmallScreen(false)
      //this.setState({ isSmallScreen: false })
    }
  }
  scrollToTop = () => {
    this.topOfPage &&
      this.topOfPage.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
  }

  render() {
    if (this.props.t) {
      const { t } = this.props.t
      return (
        <>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref
            }}
            timeout={1000 * 60 * 15}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
          />
          <Router
            onUpdate={() => {
              window.scrollTo(0, 0)
            }}
            {...this.props}
            history={history}
          >
            <div className='App'>
              <ScrollToTop />
              <Switch>
                <Route
                  exact
                  path='/'
                  component={(props) => (
                    <NewLandingPage
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/timeout'
                  component={(props) => (
                    <Timeout signOut={this.signOut} t={this.props.t} />
                  )}
                />
                <Route
                  exact
                  path='/signin'
                  component={() => (
                    <SignIn
                      {...this.props}
                      setUser={this.setUser}
                      history={history}
                    />
                  )}
                />
                <Route
                  exact
                  path='/signin-ASA'
                  component={() => (
                    <SignInASA {...this.props} setUser={this.setUser} />
                  )}
                />
                <Route
                  path='/signin-money-net'
                  component={() => (
                    <SignInMoneyNet {...this.props} setUser={this.setUser} />
                  )}
                />
                <Route
                  exact
                  path='/AsaSignup'
                  component={() => (
                    <AsaSignup
                      {...this.props}
                      setUser={this.setUser}
                      history={history}
                    />
                  )}
                />
                <Route
                  exact
                  path='/signup'
                  component={() => (
                    <SignUp {...this.props} setUser={this.setUser} />
                  )}
                />
                <Route
                  exact
                  path='/signup/:promoCode'
                  component={() => (
                    <SignUp {...this.props} setUser={this.setUser} />
                  )}
                />
                <Route
                  exact
                  path='/forgotpassword'
                  component={() => <ForgotPassword {...this.props} />}
                />
                <Route
                  exact
                  path='/About'
                  component={() => (
                    <About
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/Careers'
                  component={() => (
                    <Careers
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/stock-info'
                  component={(props) => (
                    <LandingPage
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/crypto-info'
                  component={(props) => (
                    <CryptoInfo
                      setUser={this.setUser}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/products-info'
                  component={(props) => (
                    // <ProductsInfo
                    //   setUser={this.setUser}
                    //   signIn={this.signIn}
                    //   signOut={this.signOut}
                    //   user={this.state.user}
                    //   isSmallScreen={this.state.isSmallScreen}
                    //   {...this.props}
                    // />
                    <ProductPage
                      setUser={this.setUser}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/Contact'
                  component={() => (
                    <Contact
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  exact
                  path='/CryptoAgreement'
                  component={() => (
                    <CryptoAgreement
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                    />
                  )}
                />
                <Route
                  exact
                  path='/ApexAccountAgreement'
                  component={() => (
                    <ApexNewAccountAggrements
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                    />
                  )}
                />
                <Route
                  exact
                  path='/Disclosures'
                  component={() => (
                    <Disclosures
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
                <Route
                  path='/Dashboard'
                  component={() => (
                    <Dashboard
                      scrollToTop={this.scrollToTop}
                      t={this.props.t}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      history={history}
                    />
                  )}
                />
                <Route
                  path='/Dashboard/:tab'
                  component={() => (
                    <Dashboard
                      scrollToTop={this.scrollToTop}
                      t={this.props.t}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      history={history}
                    />
                  )}
                />
                <Route
                  path='/Dashboard/:tab:/:ticker'
                  component={() => (
                    <Dashboard
                      scrollToTop={this.scrollToTop}
                      t={this.props.t}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      history={history}
                    />
                  )}
                />
                <Route
                  exact
                  path='/mobile-disclosures-dw'
                  component={() => <DWDisclosures />}
                />
                <Route
                  exact
                  path='/mobile-disclosures-apex'
                  component={() => <ApexDisclosures />}
                />
                <Route
                  exact
                  path='/mobile-disclosures-forescite'
                  component={() => <ForesciteDisclosures />}
                />
                <Route
                  path='/zendesk'
                  component={() => {
                    window.location.href =
                      'https://allocaterite.zendesk.com/hc/en-us'
                    return null
                  }}
                />
                <Route
                  exact
                  path='/Support'
                  component={() => (
                    <Support
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                      user={this.state.user}
                    />
                  )}
                />
                <Route
                  exact
                  path='/Redirect'
                  component={() => <AppRedirect history={history} />}
                />
                <Route
                  exact
                  path='/login'
                  component={() => (
                    <AsaLogin
                      history={history}
                      setUser={this.setUser}
                      t={this.props.t}
                    />
                  )}
                />
                <Route
                  exact
                  path='/asasignup'
                  component={() => (
                    <AsaEmail
                      history={history}
                      setUser={this.setUser}
                      t={this.props.t}
                    />
                  )}
                />
                <Route
                  exact
                  path='/promo'
                  component={() => (
                    <PromoPage user={this.state.user} {...this.props} />
                  )}
                />

                {/* <Route
                  path='/promo?code=:'
                  component={() => (
                    <PromoIntroPage user={this.state.user} {...this.props} />
                  )}
                /> */}

                <Route
                  component={(props) => (
                    <NewLandingPage
                      setUser={this.setUser}
                      errors={this.state.errors}
                      signIn={this.signIn}
                      signOut={this.signOut}
                      user={this.state.user}
                      isSmallScreen={this.state.isSmallScreen}
                      {...this.props}
                    />
                  )}
                />
              </Switch>
            </div>
          </Router>
        </>
      )
    } else return null
  }
}

const mapDispatchToProps = { setIsSmallScreen, setTimeOut }

export default connect(null, mapDispatchToProps)(hookWrapper(App))
