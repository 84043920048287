import React, { useContext } from 'react'
import { LandingPageContext } from '.'
import AgrregateImg from '../../../assets/aggregate-img.png'
import PieChartImg from '../../../assets/budgeting-pie-chart.png'
import TickerCard from './TickerCard'
import { handleAppRedirect } from '../../../utility-functions/handleAppRedirect'
import { useSelector } from 'react-redux'

export default function AggregateAccounts() {
  const { t } = useContext(LandingPageContext)
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general

  return (
    <div className='see-future'>
      <div className='left'>
        <p className='header'>{t('aggregateallyouraccounts')}</p>
        <p className='description'>{t('aggregateallyouraccountsinfo')}</p>
        <div className='see-future-img-container'>
          <img src={AgrregateImg} className='see-future-img' />
        </div>
        {!isSmallScreen && (
          <div className='get-the-app-btn-container'>
            <button
              className='get-the-app-btn'
              onClick={() => {
                handleAppRedirect()
              }}
            >
              {t('gettheapp')}
            </button>
          </div>
        )}
      </div>
      <div className='right'>
        <p className='header'>{t('Budgeting')}</p>
        <div className='pie-chart-img-container'>
          <img src={PieChartImg} className='pie-chart-img' />
        </div>
        {isSmallScreen && (
          <div className='get-the-app-btn-container'>
            <button
              className='get-the-app-btn'
              onClick={() => {
                handleAppRedirect()
              }}
            >
              {t('gettheapp')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
