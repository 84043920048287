//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/PieChartsCard.scss'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'
import BudgetingPieChart from './BudgetingPieChart'
import { generateColors } from '../../utility-functions/generateColors'

export default function PieChartsCard() {
  const { t, budgetingData, isSmallScreen } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting

  const [tab, setTab] = useState(0)
  const [subTab, setSubTab] = useState(0)
  const [graphData, setGraphData] = useState(null)
  const [colors, setColors] = useState([])
  const fixedColors = [
    ['#3627e4', '#7147e9', '#5c21d8'],
    ['#3627e4'],
    ['#7147e9'],
    ['#5c21d8'],
  ]

  useEffect(() => {
    setSubTab(0)
  }, [tab])

  useEffect(() => {
    if (tab === 0) {
      getAssetsGraphData()
    } else {
      getLiabilitiesGraphData()
    }
  }, [subTab, tab])

  useEffect(() => {
    if (graphData) {
      const colors = generateColors(graphData?.length)
      setColors(colors)
    }
  }, [graphData])

  const tabs = () => {
    return (
      <div className='piecharts-tabs'>
        <div
          style={tab === 0 ? selectedTab : unSelectedTab}
          onClick={() => {
            setTab(0)
          }}
        >
          <p style={tab === 0 ? selectedText : unSelectedText}>{t('assets')}</p>
        </div>
        <div
          style={tab === 1 ? selectedTab : unSelectedTab}
          onClick={() => {
            setTab(1)
          }}
        >
          <p style={tab === 1 ? selectedText : unSelectedText}>
            {t('liabilities')}
          </p>
        </div>
      </div>
    )
  }

  const subTabs = () => {
    if (tab === 0) {
      return (
        <div className='piecharts-sub-tabs'>
          <p
            className={
              subTab === 0 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(0)
            }}
          >
            {t('all')}
          </p>
          <p
            className={
              subTab === 1 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(1)
            }}
          >
            {t('physical_assets')}
          </p>
          <p
            className={
              subTab === 2 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(2)
            }}
          >
            {t('investments')}
          </p>
          <p
            className={
              subTab === 3 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(3)
            }}
          >
            {t('cash')}
          </p>
        </div>
      )
    } else {
      return (
        <div className='piecharts-sub-tabs'>
          <p
            className={
              subTab === 0 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(0)
            }}
          >
            {t('all')}
          </p>
          <p
            className={
              subTab === 1 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(1)
            }}
          >
            {t('credit_cards')}
          </p>
          <p
            className={
              subTab === 2 ? 'piecharts-sub-tab-selected' : 'piecharts-sub-tab'
            }
            onClick={() => {
              setSubTab(2)
            }}
          >
            {t('loans')}
          </p>
        </div>
      )
    }
  }

  const getAssetsGraphData = () => {
    let graphData = []
    switch (subTab) {
      case 0:
        graphData = [
          {
            name: t('physical_assets'),
            value: details?.finStat?.asset?.sumPhysicalAssets,
          },
          {
            name: t('investments'),
            value: details?.finStat?.asset?.sumInvestment,
          },
          { name: t('cash'), value: details?.finStat?.asset?.sumDepository },
        ]
        break
      case 1:
        details?.properties?.forEach((item) => {
          graphData.push({ name: item.nickname, value: item.estimated_value })
        })
        break
      case 2:
        let investallValue = 0
        details?.finStat?.asset?.investment?.forEach((item) => {
          if (
            item.accountDetails?.source === 'DriveWealth' ||
            item.accountDetails?.source === 'Apex'
          ) {
            investallValue += item.amount
          } else {
            graphData.push({
              name: `${item.accountDetails?.source} ${item.accountDetails?.mask}`,
              value: item.amount,
            })
          }
        })
        graphData.push({
          name: 'Investall',
          value: investallValue,
        })
        break
      case 3:
        details?.finStat?.asset?.depository.forEach((item) => {
          graphData.push({
            name: `${item.accountDetails?.source} ${item.accountDetails?.mask}`,
            value: item.amount,
          })
        })
        break
      default:
        break
    }

    setGraphData(Object.assign([], graphData))
  }

  const getLiabilitiesGraphData = () => {
    let graphData = []
    switch (subTab) {
      case 0:
        graphData = [
          {
            name: t('credit_cards'),
            value: details?.finStat?.liability?.sumCredit,
          },
          {
            name: t('loans'),
            value: details?.loan_extra,
          },
        ]
        break
      case 1:
        details?.finStat?.liability?.credit?.forEach((item) => {
          graphData.push({
            name: `${item.accountDetails?.source} ${item.accountDetails?.mask}`,
            value: item.amount,
          })
        })
        break
      case 2:
        details?.properties?.forEach((item) => {
          if (item.mortgage_balance) {
            graphData.push({
              name: item?.nickname,
              value: parseFloat(item?.mortgage_balance),
            })
          }
        })
        break
      default:
        break
    }

    setGraphData(Object.assign([], graphData))
  }

  const chartLegend = () => {
    return (
      <div className='chart-legend'>
        {graphData?.map((item, index) => {
          return (
            <div
              className='chart-legend-row'
              key={index}
              style={{ color: 'black' }}
            >
              <p className='chart-legend-label'>{item.name}</p>
              <p className='chart-legend-value'>{formatCurrency(item.value)}</p>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='shadow-card'>
      <div className='piecharts-card'>
        {tabs()}
        {subTabs()}
        <div className='piecharts-container'>
          <BudgetingPieChart
            seriesData={graphData}
            t={t}
            COLORS={fixedColors[subTab]}
            isSmallScreen={isSmallScreen}
            verticalAlign={false}
          />
        </div>
        {chartLegend()}
      </div>
    </div>
  )
}
