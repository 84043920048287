import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../scss/pages/PromoIntroPage.scss'

import BudgetingSub from '../../assets/submodal/budgeting-sub-white.png'
import AiTraderSub from '../../assets/submodal/ai-trade-stock-sub-white.png'
import GoalsSub from '../../assets/submodal/goals-sub-white.png'
import InvestallLogo from '../../assets/investall-logo-white.svg'
import InvestallBubble from '../../assets/investall-cool-bubble.svg'

export default function PromoIntroPage(props) {
  const history = useHistory()
  const t = props.t
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general
  if (t) {
    return (
      <div className='promo-intro-page'>
        <img
          src={InvestallLogo}
          className='investall-logo-img space-bottom'
          onClick={() => {
            history.push('/')
          }}
        />
        <div className='promo-intro-center'>
          <p className='promo-intro-small-text promo-intro-light space-bottom-big space-top-big'>
            {t('faares_has_invited_you_to')}
          </p>
          <p className='promo-intro-big-text'>
            Get <span className='green-text'>$20 free</span> when you deposit
            $100
          </p>
          {isSmallScreen ? (
            <div className='mobile-btn-background'>
              <button
                className='mobile-btn capitalize'
                onClick={() => {
                  history.push(`/signup/${props.promoCode}`)
                }}
              >
                {t('signup')}
              </button>
            </div>
          ) : (
            <button
              className='desktop-btn space-top-large space-bottom-big capitalize'
              onClick={() => {
                history.push(`/signup/${props.promoCode}`)
              }}
            >
              {t('signup')}
            </button>
          )}
          <img
            src={InvestallBubble}
            className='cool-bubble space-top-big space-bottom-large'
          />
          <p className='promo-intro-medium-text promo-intro-bold black-text'>
            {t('why_investall')}
          </p>
          <ul className='promo-intro-ul black-text space-top space-bottom'>
            <li className='space-bottom'>
              {t('trade_stocks_etfs_and_crypto_msg')}
            </li>
            <li className='space-bottom'>{t('use_expert_strategyies_msg')}</li>
            <li className='space-bottom'>
              {t('accessible_on_multi_platform_msg')}
            </li>
          </ul>
          <div className='purple-container space-top space-bottom'>
            <p className='promo-intro-medium-text promo-intro-bold space-bottom-small promo-intro-center-item'>
              {t('complimentary_upgrade_to')}
            </p>
            <p className='promo-intro-medium-text promo-intro-black promo-intro-center-item space-bottom-big'>
              {t('investall_pro')}
            </p>
            <p className='promo-intro-small-text promo-intro-light space-bottom-big promo-intro-center-item'>
              {t('receive_one_month_subscription_msg')}
            </p>
            <div className='promo-intro-row'>
              <img src={BudgetingSub} className='sub-icon' />
              <div className='promo-intro-left'>
                <p className='promo-intro-medium-text promo-intro-bold'>
                  {t('Budgeting')}
                </p>
                <p className='promo-intro-small-text'>
                  {t('budgeting_subs_info')}
                </p>
              </div>
            </div>
            <div className='promo-intro-row'>
              <img src={AiTraderSub} className='sub-icon' />
              <div className='promo-intro-left'>
                <p className='promo-intro-medium-text promo-intro-bold'>
                  {t('ai_trader_stock_n_crypto')}
                </p>
                <p className='promo-intro-small-text'>
                  {t('ai_trader_stock_n_crypto_sub_info')}
                </p>
              </div>
            </div>
            <div className='promo-intro-row'>
              <img src={GoalsSub} className='sub-icon' />
              <div className='promo-intro-left'>
                <p className='promo-intro-medium-text promo-intro-bold'>
                  {t('goals')}
                </p>
                <p className='promo-intro-small-text'>{t('goals_sub_info')}</p>
              </div>
            </div>
          </div>
          <p className='subtle-text space-top space-bottom-large'>
            {t('free_trial_msg')}
          </p>
          {!isSmallScreen && (
            <button
              className='desktop-btn capitalize'
              onClick={() => {
                history.push('/signup/FAARES')
              }}
            >
              {t('signup')}
            </button>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}
