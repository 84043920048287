import React from 'react'
import Switch from "react-switch"

import ENDPOINT from '../Endpoint'
import SearchEndpoint from '../SearchEndpoint';
export default class TickersTable extends React.Component{

    state={
        shares:false,
        returnForecast:[],
        once: 1
        
    }
    searchMomentum = (data) => {
        // console.log("Ticker",data);
        
        data.map(ele=>{
            // console.log("s",ele[0])
            let url = `${SearchEndpoint}/ticker?search=${ele[0].toUpperCase()}`;
            // console.log(url)
            fetch(url)
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                // console.log("ss",data[0])
                // var out=[data[0]["priceUSD"],data[0]["returnForecast"]]
                // console.log("ss2",out)
                this.setState({returnForecast: this.state.returnForecast.concat({"symbol":ele[0],"price":data[0]["priceUSD"],"forecast":data[0]["returnForecast"]})})
                // console.log("Final",this.state.returnForecast);
                // this.setState({
                //     isMomDataLoading: false,
                //     momDataSource: data,
                // });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
        })
        // const { searchMomentumText } = this.state;
        // this.setState({ isMomDataLoading: true });
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
        
    };

    

    createCells = ()=>{
        let full = this.props.data;
        // console.log("once",this.state.once)
        if (this.state.once === 1){
            this.searchMomentum(this.props.data);
            this.setState({once: 2})
        }
        var returnForecast = this.state.returnForecast;
        // console.log("returnForecast",returnForecast);
        // console.log("Full",full)
        // 
        var cash = 0;
        var symbol= []; 
        var forecast = '';
        var z = {};
        let arr = this.props.data
        arr.push(arr.shift())
        return arr.map(ele=>{
            if(ele[0]=== "$$"){
                // console.log("Cash1",ele[3])
                cash = ele[3]
                
            }
            returnForecast.map(val=>{
                // console.log('val',val)
                z[val["symbol"]] =  val["price"]*val["forecast"]+val["price"];
                // z[val[0]] = val[1]
            })
            // console.log('finalZ',z)
            // if(ele[0] !== "$$")
            //      this.searchMomentum(ele[0]);
            //     symbol[ele[0]] = this.state.returnForecast;
            //     console.log("DataF",symbol)
            if(ele[0] === "BIL" && ele[1] === "0" && ele[2] === "0"){
                // console.log('skipping BIL')
                return(null)
            }else{
                // console.log('ele', ele)
                // console.log('ele[0] equality', ele[0] === "BIL")
                // console.log('ele[1] equality', ele[1] === "0")
                // console.log('ele[2] equality', ele[2] === "0")
                return(
                    <div style={{width:'100%', margin:0,padding:0,display:'flex', justifyContent:'space-between', alignItems:'flex-start',borderBottom:'solid', borderColor:'rgb(244,244,248)'}}>
                        <div style={{width:'10%',paddingLeft: '5%', backgroundColor:'rgb(240,240,245)', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[0]==='$$'? 'cash':ele[0]}
                            </p>
                        </div>
                        {/* <div style={{width:'15%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {isNaN(ele[1])?0:ele[1]}%
                            </p>
                        </div>
                        <div style={{width:'15%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[0]==='$$'? `$${ele[3].toFixed(2)}`:ele[3]}
                            </p>
                        </div> */}
                        <div style={{width:'25%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[2]}%
                            </p>
                        </div>
                        <div style={{width:'25%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {/* {ele[0]==='$$'? `$${ele[4].toFixed(2)}`:ele[4].toString()} */}
                                {ele[0]==='$$'? `-`:ele[4].toString()}
                            </p>
                        </div>
                        <div style={{width:'25%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[0]==='$$'? '-':`$${Number(z[ele[0]]).toFixed(0)}`}
                            </p>
                        </div>
                        <div style={{width:'25%',paddingLeft: '5%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[0]==='$$'? `-`:`${Math.abs(ele[6]*100).toFixed(2)}%`}
                            </p>
                        </div>
                        {/* <div style={{width:'21%',paddingLeft: '5%',padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                // {ele[4] - ele[3] > 0 ? '+':''}{ele[0]==='$$'? `$${(ele[4]-ele[3]).toFixed(2)}`: (ele[4] - ele[3]).toFixed(2)} //
                                // {ele[4] - ele[3] > 0 ? '':''}{ele[0]==='$$'? ``: (ele[4] - ele[3]).toFixed(2)} //
                                {ele[0]==='$$'? `$${ele[4].toFixed(2)}`:ele[4]}
                            </p>
                        </div> */}
                    </div>
                )
            }
        })
    }
    createCells2 = ()=>{
        let full = this.props;
        // console.log("Full",full)
        return this.props.data.map(ele=>{
            if(ele[0] === "BIL" && ele[1] === "0" && ele[2] === "0"){
                // console.log('skipping BIL')
                return(null)
            }else{
                // console.log('ele', ele)
                // console.log('ele[0] equality', ele[0] === "BIL")
                // console.log('ele[1] equality', ele[1] === "0")
                // console.log('ele[2] equality', ele[2] === "0")
                return(
                    <div style={{width:'100%', margin:0,padding:0,display:'flex', alignItems:'flex-start',borderBottom:'solid', borderColor:'rgb(244,244,248)'}}>
                        <div style={{width:'25%', backgroundColor:'rgb(240,240,245)', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {ele[0]}
                            </p>
                        </div>
                        <div style={{width:'25%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {this.state.shares? `${ele[1]}%` : ele[0]==='$$'? `$${ele[3].toFixed(2)}`:ele[3]}
                            </p>
                        </div>
                        <div style={{width:'25%', padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {this.state.shares? `${ele[2]}%` : ele[0]==='$$'? `$${ele[4].toFixed(2)}`:ele[4]}
                            </p>
                        </div>
                        <div style={{width:'25%',padding:8}}>
                            <p style={{margin:0, textAlign:'right'}}>
                                {/* {ele[4] - ele[3] > 0 ? '+':''}{ele[0]==='$$'? `$${(ele[4]-ele[3]).toFixed(2)}`: ele[4] - ele[3]} */}
                                {ele[0]==='$$'? `$${ele[4].toFixed(2)}`:ele[4]}
                            </p>
                        </div>
                    </div>
                )
            }
        })
    }

    render(){
        if(this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex',justifyContent:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start', width:'100%',margin:'auto'}}>
                            <p style={{ fontSize:24,fontWeight:'bold'}}>Portfolio Allocations</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', width:'100%',margin:'auto'}}>
                            <p style={{ fontSize:18,fontWeight:'bold'}}>By: {this.state.shares ?'Percentage':'Shares'}</p>
                            <Switch 
                                checked={this.state.shares} 
                                onChange={()=>this.setState({shares:!this.state.shares})}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                offColor = '#3b8e8c'
                                onColor = '#8682cb'
                            />
                        </div>
                        <div style={{width:'100%', margin:0,padding:0, }}>
                            <div style={{width:'100%', margin:0,padding:0, display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                <div style={{width:'25%',padding:8,  borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(128,130,136)' }}>
                                    <p style={{margin:0, fontSize:14, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Stock
                                    </p>
                                    <p style={{margin:0, fontSize:14, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Symbol
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8, borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(34,37,48)'}}>
                                    <p style={{margin:0, fontSize:14, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Actual
                                    </p>
                                    <p style={{margin:0, fontSize:14,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                       {this.state.shares?  'Percent':'Shares'}
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontSize:14, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Optimized
                                    </p>
                                    <p style={{margin:0, fontSize:14,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        {this.state.shares?  'Percent':'Shares'}
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(128,130,136)'}}>
                                    <p style={{margin:0, fontSize:14, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Trades
                                    </p>
                                    <p style={{margin:0, fontSize:14,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        Needed
                                    </p>
                                </div>
                            
                            </div>
                            <div style={{width:'100%', height:this.props.isSmallScreen ? '':250, overflowY:'scroll'}}>
                                {this.createCells2()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'flex-start', maxHeight:548.73}}>
                    <div style={{width: '100%', height: this.props.isSmallScreen ? '' :'', display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start', width:'100%'}}>
                            <p style={{ fontSize:24,fontWeight:'bold'}}>Portfolio Allocations</p>
                        </div>
                        <div style={{width:'100%', margin:0,padding:0, }}>
                            <div style={{width:'100%', margin:0,padding:0, display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
                                <div style={{width:'10%',padding:8,  borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(128,130,136)' }}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Stock
                                    </p>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Symbol
                                    </p>
                                </div>
                                {/* <div style={{width:'15%',padding:8, borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(34,37,48)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Actual
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        Percentage
                                    </p>
                                </div>
                                
                                <div style={{width:'15%',padding:8, borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(34,37,48)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Actual
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        # Shares
                                    </p>
                                </div> */}
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Allocation
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        %
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                    &nbsp;
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        # Shares
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        30 D Price
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        Forecast
                                    </p>
                                </div>
                                <div style={{width:'25%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        Risk in
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        Portfolio
                                    </p>
                                </div>
                                {/* <div style={{width:'21%',padding:8,borderBottom:'solid', borderWidth:.25, borderColor: '#ddd',backgroundColor:'rgb(74,129,128)'}}>
                                    <p style={{margin:0, fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)' }}>
                                        # Shares
                                    </p>
                                    <p style={{margin:0,fontWeight:'bold', textAlign:'right', color:'rgb(255,255,255)'}}>
                                        Required
                                    </p>
                                </div> */}
                            
                            </div>
                            <div style={{width:'100%', height:this.props.isSmallScreen ? '':250, overflowY:'scroll'}}>
                                {this.createCells()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}