//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//IMAGE
import InvestallCompany from '../../../assets/investall-company.png'

export default function BuyFractionalShares() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='default-container'>
      <div className='block'>
        <p className='big-text light-text'>{t('buy_fractional_shares_msg')}</p>
        {/* TRANSLATE LINE BELOW */}
        <p className='chart-disclaimer-text'>
          Chart is not indicative of future performance, it is for simulation
          purposes only.
        </p>
      </div>
      <div className='block'>
        <div className='phone-img-container-right'>
          <div className='center-content'>
            <img src={InvestallCompany} className='phone-img' />
          </div>
        </div>
      </div>
    </div>
  )
}
