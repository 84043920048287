import React from 'react'
import { Card, Row } from 'react-bootstrap'
import Header from '../layout/Header'
import SingleCompanyCard from '../singleCompanyCard'
import Footer from '../Footer'
import { FaChevronDown, FaArrowRight, FaArrowLeft } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import MobileWl from './mobileWl'
import HeaderDropdown from '../HeaderDropdown'
import TickerTape from './TickerTape'
import { FaSearch } from 'react-icons/fa'
import ENDPOINT from '../Endpoint'
import UADisplay from '../UADisplay'
import SearchBar from '../components/SearchBar'
import SearchEndpoint from '../SearchEndpoint'
import Disclaimer from '../Disclaimer'
import { TabPanel, TabView } from 'primereact/tabview'
import '../../scss/pages/Watchlist2.scss'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import CreateWatchlist from '../components/CreateWatchlist'
import PlusCircle from '../../assets/plus-circle-icon.svg'
const brandColor = '#00A99D'

export default class Watchlist extends React.Component {
  state = {
    shownList: 'My Watchlist',
    showListOptions: false,
    editMode: false,
    start: 0,
    showAddModal: false,
    searchMomentumText: '',
    momDataSource: [],
    isSearching: false,
    refresh: true,
    displayList: 'Watchlist',
    activeTab: 0,
    selectedWatchlistGroup: '',
    createWatchlistModal: false,
  }

  componentDidMount() {
    // if (this.props.user) {
    //     this.fetchWatchList()
    // }
    this.interval = setInterval(() => this.refreshWl(), 5000)
    this.getFirstGroup()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.myWatchlistGroup?.names?.length === 0 ||
      !this.props.myWatchlistGroup?.names?.includes(
        this.state.selectedWatchlistGroup
      )
    ) {
      this.getFirstGroup()
    }
  }

  getFirstGroup() {
    const keys = this.props.myWatchlistGroup?.names
    console.log(keys)
    if (keys?.length > 0) {
      this.setState({ selectedWatchlistGroup: keys[0] })
    }
  }

  handleTabChange = (val) => {
    if (val == 0) {
      this.setState({ activeTab: val, displayList: 'UAStocks' })
    } else {
      this.setState({ activeTab: val, displayList: 'UAOptions' })
    }
  }

  refreshWl = () => {
    console.log('refreshing wl')
    this.props.refreshWl()
    this.props.refreshUA()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  arrayToObject = (arr) => {
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].listTitle] = arr[i].tickers
    }
    return result
  }

  // fetchWatchList = () => {
  //     fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
  //         method: "GET",
  //         headers: {
  //             Authorization: "Bearer " + this.props.user.idToken.jwtToken
  //         },
  //     }).then(response => response.json())
  //         .then(responseJson => {
  //             let watchListData = responseJson.watchlist;
  //             let predefinedLists = responseJson.predefinedLists
  //             let watchlistLastUpdated = (new Date(responseJson.lastUpdated * 1000)).toString()
  //             let sortedPredefinedLists = this.arrayToObject(predefinedLists)
  //             this.setState({ myWatchlist: watchListData, presetTickers: sortedPredefinedLists, watchlistLastUpdated })
  //         })
  //         .catch(error => console.log(error))
  // }

  // fetchPresetTickers = () => {
  //     let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
  //     fetch(url, {
  //         "Access-Control-Allow-Headers": {
  //             "Content-Type": "application/json",
  //             "Cache-Control": "no-cache",
  //             "Access-Control-Request-Headers": "*",
  //             "Access-Control-Allow-Origin": "*",
  //             "Access-Control-Allow-Methods": "*",
  //         },
  //     })
  //         .then((res) => res.json())
  //         .then((responseJson) => {
  //             this.setState({ presetTickers: responseJson.lists })
  //         })
  // }

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state
    this.setState({ isMomDataLoading: true })
    // console.log(searchMomentumText);
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    let url = `${SearchEndpoint}/ticker?search=${searchMomentumText.toUpperCase()}`
    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        this.setState({
          isMomDataLoading: false,
          momDataSource: data,
        })
      })
      .catch((err) => {
        this.setState({ isMomDataLoading: false })
        console.log(err)
      })
  }

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  addModal = () => {
    if (this.state.showAddModal) {
      clearInterval(this.interval)
      return (
        <div
          style={{
            backgroundColor: 'transparent',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'transparent',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '90%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {this.props.t('stocksearch')}
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showAddModal: false })}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div
              style={{
                width: '90%',
                margin: 'auto',
                marginTop: 25,
                marginBottom: 50,
              }}
            >
              <p style={{ fontSize: 22 }}>
                {this.props.t('searchforanystock')}
              </p>
              <p style={{ fontSize: 22 }}>{this.props.t('clickonanyresult')}</p>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'transparent',
                  width: '75%',
                  marginBottom: 20,
                  borderRadius: 20,
                  paddingLeft: 10,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  boxShadow: this.state.hover
                    ? '0 0 10px rgb(223,225,229)'
                    : 'none',
                  borderColor: 'rgb(237,238,240)',
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                  minWidth: 250,
                }}
                onMouseEnter={() => this.setState({ hover: 'input' })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                <div
                  style={{
                    minWidth: 20,
                    width: '5%',
                    /*backgroundColor: '#00A99D',*/ display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FaSearch size={'50%'} style={{ color: '#dddddd' }} />
                </div>
                <input
                  id='AddToWatchList_Search'
                  ref={this.input}
                  style={{
                    width: '90%',
                    border: 'none',
                    outline: 'none',
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  placeholder={this.props.t('enterstocksymbol')}
                  type='text'
                  value={this.state.searchMomentumText}
                  onChange={(event) => {
                    let searchMomentumText = event.target.value.toUpperCase()
                    this.setState(
                      {
                        searchMomentumText,
                        selectedPreset: null,
                        errors: false,
                      },
                      () => {
                        const { isSearching } = this.state
                        if (isSearching) {
                          return
                        } else {
                          this.setState({ isSearching: true })
                          setTimeout(() => {
                            this.setState(
                              { isSearching: false },
                              this.onSubmitSearchMomentum
                            )
                          }, 2000)
                        }
                      }
                    )
                  }}
                />
              </div>
            </div>
            <div
              style={{
                maxHeight: 300,
                overflowY: 'scroll',
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 20,
              }}
            >
              {this.state.searchMomentumText.length > 0 ? (
                this.state.isSearching ? (
                  <div style={{ width: '100%', marginTop: 75 }}>
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: 22,
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      Loading...
                    </p>
                  </div>
                ) : this.state.momDataSource.length === 0 ? (
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: 22,
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    {this.props.t('noresultsfound')}...
                  </p>
                ) : (
                  <div
                    style={{
                      width: '95%',
                      margin: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      marginLeft: 15,
                    }}
                  >
                    {this.state.momDataSource.map((ele, index) =>
                      this.createBox(ele, index)
                    )}
                  </div>
                )
              ) : (
                // this.state.momDataSource.map(ele =>
                //     <div onClick={()=>{
                //             let list = []
                //             this.props.myWatchlist.forEach(ele=>list.push(ele.symbol))
                //             if(!list.includes(ele.symbol)){
                //                 this.props.addWl(ele)
                //                 this.setState({showAddModal:false})
                //             }
                //         }}
                //         onMouseEnter={()=>this.setState({hover:ele})}
                //         style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
                //     >
                //         <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                //             <p style={{ margin: 0, float: 'left', fontWeight:'bold' }}>{ele.symbol}</p>
                //             <p style={{ margin: 0, float: 'left' }}>{ele.company}</p>
                //         </div>
                //     </div>
                // )
                <div style={{ width: '100%', marginTop: 75 }}>
                  <p style={{ textAlign: 'center' }}>
                    {' '}
                    {this.props.t('searchabove')}
                  </p>
                </div>
              )}
            </div>
            <div>{this.showError()}</div>
          </div>
        </div>
      )
    }
  }

  individuleCompanyCard = (data) => {
    return (
      <Row
        style={{
          // padding: "2%",
          textDecoration: 'none',
        }}
      >
        {data.map((item, index) => {
          return (
            <SingleCompanyCard
              user={this.props.user}
              remove={this.props.removeFromWl}
              editMode={this.state.editMode}
              item={item}
              index={index}
            />
          )
        })}
      </Row>
    )
  }

  countTickers = () => {
    let data
    if (this.state.shownList === 'My Watchlist') {
      data = this.props.myWatchlist
    } else {
      data = this.props.presetTickers[this.state.shownList]
    }
    if (data) {
      return data.length
    }

    // return this.props.myWatchlist.length
  }

  showSuccess = () => {
    if (this.state.success) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p style={{ margin: 0, textAlign: 'center' }}>
              {this.state.success}
            </p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  removeSuccess = () => {
    setTimeout(() => this.setState({ success: false }), 5000)
  }

  createBox = (ele, index) => {
    // console.log('inside create box', ele)
    return (
      <div
        // onClick={()=>{
        //     this.owned(this.state.acc,ele.symbol)
        //     this.getLivePrice(ele.symbol)
        //     this.setState({searchMomentumText:'', selected: ele, limitPrice:ele.priceUSD})
        // }}
        id={'WatchlistTicker_' + index}
        onClick={() => {
          let list = []
          this.props.myWatchlist.forEach((ele) => list.push(ele.symbol))
          if (!list.includes(ele.symbol)) {
            this.props.addWl(ele)
            this.setState({
              showAddModal: false,
              success: `${ele.symbol} has been added to your watchlist`,
              searchMomentumText: '',
            })
            this.props.turnOnWlUpdates()
            this.interval = setInterval(() => this.refreshWl(), 5000)
            this.removeSuccess()
          } else {
            this.setState({
              errors: `${ele.symbol} is already on your watchlist`,
            })
          }
        }}
        onMouseEnter={() => this.setState({ hover: ele })}
        onMouseLeave={() => this.setState({ hover: '' })}
        style={{ width: 100, height: 175, marginBottom: 10, marginRight: 15 }}
        // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
      >
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              this.state.hover === ele ? 'transparent' : 'transparent',
            borderRadius: 7,
            overflow: 'hidden',
            width: 100,
            height: 100,
            justifySelf: 'flex-end',
            border: 'solid',
            borderWidth: '0.25px',
          }}
        >
          {ele.imageURL === 'not-found' ? (
            <div
              style={{
                objectFit: 'contain',
                width: 50,
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                }}
              >
                {ele.symbol[0]}
              </p>
            </div>
          ) : (
            <div>
              <img
                // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                src={ele.imageURL}
                style={{
                  objectFit: 'contain',
                  width: 80,
                  height: 80,
                  padding: 10,
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
          }}
        >
          <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold' }}>
            {ele.symbol}
          </p>
          <p
            style={{
              margin: 0,
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {ele.company}
          </p>
        </div>
      </div>
    )
  }

  showError = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            width: '60%',
            backgroundColor: 'transparent',
            padding: 12,
            marginBottom: 10,
            borderRadius: 8,
          }}
        >
          <p style={{ margin: 0, textAlign: 'center' }}>{this.state.errors}</p>
        </div>
      )
    }
  }

  showTickers = () => {
    // let data = this.props.myWatchlist
    let data =
      this.state.selectedWatchlistGroup &&
      this.props.myWatchlistGroup?.watchlist[this.state.selectedWatchlistGroup]
        ? this.props.myWatchlistGroup?.watchlist[
            this.state.selectedWatchlistGroup
          ]
        : []
    let start = this.state.start
    let end
    if (data?.length < start + 15) {
      end = data?.length
    } else {
      end = start + 15
    }
    // let mappedData = [{ columnTitle:true, priceUSD:0, direction:0, directionPrev:0, symbol:'' }, ...data.slice(start,end)].map(ele=>(<HeaderDropdown size={'large'} remove={this.props.removeFromWl} editMode={this.state.editMode} companyName={ele.company} rf={ele.returnForecast} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>))
    // let mappedData = [{ columnTitle:true},{subheading:true}, ...data.slice(start,end)].map(ele=>{
    let mappedData = [
      { subheading: true },
      { columnTitle: true },
      ...data?.slice(start, end),
    ].map((ele, index) => {
      // let ele1 = Object.values(ele)[0]
      return (
        <HeaderDropdown
          size={'large'}
          isCrypto={ele.industry === 'Cryptocurrency'}
          subheading={ele.subheading}
          img={ele.imageURL}
          remove={this.props.removeFromWl}
          editMode={this.state.editMode}
          companyName={ele.company}
          needOptimization={ele.needOptimization}
          rf={ele.returnForecastValue}
          columnTitle={ele.columnTitle}
          priceUSD={ele.priceUSD}
          directionPrev={ele.directionPrev}
          direction={ele.direction}
          symbol={ele.symbol}
          t={this.props.t}
          key={index}
          showEdit={this.showEdit}
          yearHigh={ele['52weekHigh']}
          yearLow={ele['52weekLow']}
          daysLow={ele.low}
          daysHigh={ele.high}
          selectedWatchlistGroup={this.state.selectedWatchlistGroup}
          user={this.props.user}
          refreshWl={this.props.refreshWl}
        />
      )
    })
    return (
      <Card
        style={{
          width: '100%',
          // marginTop: 20,
          border: 'none',
          backgroundColor: '#f6f6f6',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* {this.state.shownList === 'My Watchlist' ?<div style={{width: '20%'}}/> : null} */}
          {/* <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        {this.props.isSmallScreen? 'click a symbol for more info' :'select a ticker for more information'}
                    </p> */}
          {/* {this.state.shownList === 'My Watchlist' ?
                        <div style={{ width: '30%',marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                            <p style={{ backgroundColor: 'rgb(248,248,247)', padding: 10, borderRadius: 8, fontSize: 16, margin:0}} onClick={()=>this.setState({editMode: !this.state.editMode})}>{this.props.isSmallScreen? 'Edit' :'Edit Watchlist'}</p>
                        </div>:<div style={{width:'30%'}}/>
                    } */}
        </div>
        {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '2%',
            margin: this.props.isSmallScreen ? '' : 'auto',
            backgroundColor: 'transparent',
          }}
        >
          {/* {this.props.isSmallScreen ? <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data}/> :this.individuleCompanyCard(data)} */}
          {this.props.isSmallScreen ? (
            <MobileWl
              remove={this.props.removeFromWl}
              editMode={this.state.editMode}
              data={data}
              t={this.props.t}
            />
          ) : (
            <div
              className='mapped'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {mappedData}
            </div>
          )}
        </div>

        {/*        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '80%',
            margin: 'auto',
            marginTop: 0,
            marginBottom: 5,
          }}
        >
          {this.props.isSmallScreen ||
          this.props.myWatchlist.length < 16 ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 8,
                padding: 5,
                backgroundColor: '#f6f6f6',
                cursor: 'pointer',
              }}
              onClick={() => {
                let start = this.state.start
                if (this.state.start - 15 >= 0) {
                  window.scrollTo(0, 400)
                  start -= 15
                } else {
                  console.log('out of bounds')
                }
                this.setState({ start })
              }}
            >
              <FaArrowLeft />
            </div>
          )}
          {this.props.isSmallScreen ||
          this.props.myWatchlist.length < 16 ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 8,
                padding: 5,
                backgroundColor: '#f6f6f6',
                cursor: 'pointer',
              }}
              onClick={() => {
                let start = this.state.start
                console.log('in clickEvent')
                if (this.state.start + 15 <= data?.length) {
                  window.scrollTo(0, 400)
                  start += 15
                } else {
                  console.log('out of bounds')
                }
                this.setState({ start })
              }}
            >
              <FaArrowRight />
            </div>
          )}
        </div> */}

        {/* <div style={{margin:'auto',marginTop:10, display: 'flex', marginRight: '5%', marginLeft: '5%',  flexDirection: 'column'}}>
                        <p style={{fontSize:18}}>Disclaimer:</p>
                        <p style={{fontSize:14, margin: 0}}>All 30 day forecasts (medium term) and trends (short term, 2 hours - 2 days) are generated by our AI forecasting model. They are not intended to be advice or recomendation to buy or sell any stocks.</p>
                        <p style={{fontSize:14, margin: 0}}>The results do not represent actual trading and actual results may significantly differ from the theoretical results presented. Past performance is not indicative of future performance.</p>
                    </div> */}
      </Card>
    )
  }

  showUnusualActivity = () => {
    let stocks = this.props.unusualActivity.payload
    let options = this.props.unusualActivity.payload_options
    let mappedStocks = null
    let mappedOptions = null
    if (stocks) {
      mappedStocks = stocks.map((ele) => {
        return <UADisplay data={ele} type={this.state.displayList} />
      })
    }
    if (options) {
      mappedOptions = options.map((ele) => {
        return <UADisplay data={ele} type={this.state.displayList} />
      })
    }
    return (
      <Card
        style={{
          width: '100%',
          // marginTop: 20,
          border: 'none',
          backgroundColor: '#f6f6f6',
        }}
      >
        <div className='ua-content'>
          {' '}
          <TabView
            activeIndex={this.state.activeTab}
            onTabChange={(e) => this.handleTabChange(e.index)}
          >
            <TabPanel header={this.props.t('stocks')}>
              {' '}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '2%',
                  margin: this.props.isSmallScreen ? '' : 'auto',
                }}
              >
                {this.props.isSmallScreen ? (
                  stocks ? (
                    <MobileWl
                      remove={this.props.removeFromWl}
                      editMode={this.state.editMode}
                      data={stocks}
                      t={this.props.t}
                    />
                  ) : null
                ) : (
                  <div
                    className='mapped'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    {/* <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                  >
                    <div
                      onClick={() => this.setState({ displayList: "UAStocks" })}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "solid .25px #3F46F6",
                        borderRadius: "5px",
                        marginRight: 20,
                        width: "100px",
                        height: "35px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.5em",
                          marginRight: 10,
                          marginLeft: 10,
                          color:
                            this.state.displayList === "UAStocks" ||
                            this.state.displayList === "UAOptions"
                              ? "#3F46F6"
                              : "",
                        }}
                      >
                        {this.props.t("stocks")}
                      </p>
                    </div>
                    <div
                      onClick={() =>
                        this.setState({ displayList: "UAOptions" })
                      }
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "solid .25px grey",
                        borderRadius: "5px",
                        width: "100px",
                        height: "35px",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: "1.5em",
                          marginRight: 10,
                          marginLeft: 10,
                          color: "",
                        }}
                      >
                        {this.props.t("options")}
                      </p>
                    </div>
                  </div> */}
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        color: '#272748',
                      }}
                    >
                      {/* <div style={{ flex: 0.65 }}></div> */}
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'left',
                          fontSize: 15,
                          fontFamily: 'Akkurat-Light',
                          color: '#272748',
                        }}
                      >
                        {this.props.t('symbol')}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'center',
                          fontSize: 15,
                          fontFamily: 'Akkurat-Light',
                          color: '#272748',
                        }}
                      >
                        {this.props.t('company')}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'center',
                          fontSize: 15,
                          fontFamily: 'Akkurat-Light',
                          color: '#272748',
                        }}
                      >
                        {this.props.t('changeinprice')}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'center',
                          fontSize: 15,
                          fontFamily: 'Akkurat-Light',
                          color: '#272748',
                        }}
                      >
                        {this.props.t('volumechange')}
                      </p>
                    </div>
                    {mappedStocks}
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel header={this.props.t('options')}>
              {' '}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '2%',
                  margin: this.props.isSmallScreen ? '' : 'auto',
                }}
              >
                {this.props.isSmallScreen ? (
                  options ? (
                    <MobileWl
                      remove={this.props.removeFromWl}
                      editMode={this.state.editMode}
                      data={options}
                      t={this.props.t}
                    />
                  ) : null
                ) : (
                  <div
                    className='mapped'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{ width: '100%', display: 'flex', maxWidth: 1280 }}
                    >
                      <p
                        style={{
                          flex: 2,
                          textAlign: 'left',
                          fontSize: 15,
                          color: 'rgb(39, 39, 72)',
                          fontFamily: 'Akkurat-Light',
                        }}
                      >
                        {/* {this.props.t("symbol")} */}
                        {this.props.t('unusual')} {this.props.t('options')}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'center',
                          fontSize: 15,
                          color: 'rgb(39, 39, 72)',
                          fontFamily: 'Akkurat-Light',
                        }}
                      >
                        {this.props.t('volume')}
                      </p>
                      <p
                        style={{
                          flex: 1,
                          textAlign: 'center',
                          fontSize: 15,
                          color: 'rgb(39, 39, 72)',
                          fontFamily: 'Akkurat-Light',
                        }}
                      >
                        {this.props.t('volumechange')}
                      </p>
                    </div>
                    {mappedOptions}
                  </div>
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Card>
    )
  }

  showLists = () => {
    let categories = ['My Watchlist']
    Object.keys(this.props.presetTickers).forEach((ele) => categories.push(ele))
    categories = categories.filter((ele) => ele !== this.state.shownList)
    // let mapped = categories.map(ele => <li onClick={()=>this.setState({shownList: ele, showListOptions: false, editMode: false })} style={{cursor: 'pointer', fontWeight: 'bold', width: '100%', margin: 'auto', marginLeft: '15%'}}>{ele}</li>)
    let mapped = categories.map((ele) => (
      <li
        style={{
          marginBottom: 5,
          cursor: 'pointer',
          backgroundColor:
            this.state.shownList !== ele ? 'transparent' : brandColor,
          padding: 7,
          borderRadius: 5,
          width: '100%',
        }}
        onClick={() =>
          this.setState({ shownList: ele, showListOptions: false })
        }
      >
        {ele}
      </li>
    ))

    return (
      <ul
        style={{
          backgroundColor: 'transparent',
          listStyleType: 'none',
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          borderRadius: 12,
          marginTop: -15,
          zIndex: 100,
        }}
      >
        {mapped}
      </ul>
    )
  }

  remove = (symbol) => {
    let filtered = this.props.myWatchlist.filter((ele) => ele.symbol !== symbol)
    this.setState({ myWatchlist: filtered })
  }

  showEdit = () => {
    if (this.state.editMode) {
      this.props.turnOnWlUpdates()
      this.interval = setInterval(() => this.refreshWl(), 5000)
      this.setState({ editMode: false, refresh: true })
    } else {
      this.props.turnOffWlUpdates()
      clearInterval(this.interval)
      this.setState({ editMode: true, refresh: false })
    }
  }

  tabs() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          margin: this.props.isSmallScreen ? '25px 0' : '10px 0 0 0 ',
        }}
      >
        <div
          style={
            this.state.displayList === 'Watchlist' ? selectedTab : unSelectedTab
          }
          onClick={() =>
            this.setState({
              displayList: 'Watchlist',
              activeTab: 0,
            })
          }
        >
          <p
            style={
              this.state.displayList === 'Watchlist'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('watchlist')}
          </p>
        </div>
        <div
          style={
            this.state.displayList === 'UAStocks' ||
            this.state.displayList === 'UAOptions'
              ? selectedTab
              : unSelectedTab
          }
          onClick={() => this.setState({ displayList: 'UAStocks' })}
        >
          <p
            style={
              this.state.displayList === 'UAStocks' ||
              this.state.displayList === 'UAOptions'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('unusualactivity')}
          </p>
        </div>
      </div>
    )
  }

  getWatchlistGroups = () => {
    if (this.props.myWatchlistGroup?.names) {
      const itemOptions = this.props.myWatchlistGroup?.names.map((item) => {
        return { label: item, value: item }
      })
      return itemOptions
    }
    return []
  }

  render() {
    if (this.props.t) {
      // console.log('wl props', this.props)
      if (this.props.user == null) {
        return (
          <div>
            <Header user={this.props.user} signOut={this.props.signOut} />
            <div>
              <div
                style={{
                  marginTop: 100,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NavLink
                  to={'/Login'}
                  style={{
                    fontWeight: 'bold',
                    fontSize: 20,
                    color: '#00A99D',
                    backgroundColor: 'transparent',
                    borderRadius: 12,
                    padding: '10px 20px',
                  }}
                >
                  {this.props.t('pleaselogintoseeyourwatchlist')}
                </NavLink>
              </div>
            </div>
          </div>
        )
      } else {
        // let data
        // if (this.state.shownList === 'My Watchlist') {
        //     data = this.props.myWatchlist
        // } else {
        //     data = this.props.presetTickers[this.state.shownList]
        // }
        return (
          <div
            style={{
              paddingBottom: '2%',
              borderRadius: '10px',
              backgroundColor: '#f6f6f6',
              overflow: 'hidden',
            }}
          >
            <div style={{}}>
              <div
                style={{
                  width: '100%',
                  marginTop: 25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    margin: 'auto',
                    paddingTop: '10px',
                  }}
                >
                  <div
                    style={{
                      width: this.props.isSmallScreen ? '80%' : '40%',
                      borderRadius: '19px',
                      margin: this.props.isSmallScreen ? 'auto' : '',
                      marginBottom: this.props.isSmallScreen ? '25px' : '25px',
                    }}
                  >
                    <SearchBar
                      user={this.props.user}
                      isSmallScreen={this.props.isSmallScreen}
                      t={this.props.t}
                      refreshW1={this.props.refreshWl}
                      myWatchlist={this.props.myWatchlist}
                      refreshWl={this.props.refreshWl}
                      history={this.props.history}
                      myWatchlistGroup={this.props.myWatchlistGroup}
                    />
                  </div>
                  <div
                    style={{
                      width: this.props.isSmallScreen ? '80%' : '40%',
                      margin: this.props.isSmallScreen ? 'auto' : 0,
                    }}
                  >
                    {this.tabs()}
                  </div>
                  {/* DROPDOWN FOR WATCHLIST GROUPS*/}
                  <div style={{ display: 'flex', marginTop: 25 }}>
                    <div style={{ width: 200, marginRight: 25 }}>
                      <Dropdown
                        value={this.state.selectedWatchlistGroup}
                        onChange={(e) => {
                          console.log(e)
                          this.setState({
                            selectedWatchlistGroup: e.target.value,
                          })
                        }}
                        options={this.getWatchlistGroups()}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        borderRadius: 15,
                        // backgroundColor: 'rgba(128,128,128,.5)',
                        backgroundColor: '#3627e4',
                        padding: 10,
                        cursor: 'pointer',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        this.setState({ createWatchlistModal: true })
                      }}
                    >
                      <img src={PlusCircle} width={20} height={20} />
                      <p
                        style={{
                          margin: 0,
                          color: 'white',
                          fontSize: 15,
                          marginLeft: 5,
                        }}
                      >
                        {this.props.t('createwatchlist')}
                      </p>
                    </div>
                  </div>
                  <Dialog
                    visible={this.state.createWatchlistModal}
                    header={this.props.t('createwatchlist')}
                    onHide={() =>
                      this.setState({ createWatchlistModal: false })
                    }
                    style={{
                      width: this.props.isSmallScreen ? '90vw' : '30vw',
                      height: this.props.isSmallScreen ? '90vh' : '50vh',
                    }}
                  >
                    <CreateWatchlist
                      isSmallScreen={this.props.isSmallScreen}
                      names={this.props.myWatchlistGroup.names}
                      refreshWl={this.props.refreshWl}
                      user={this.props.user}
                      t={this.props.t}
                    />
                  </Dialog>
                </div>
              </div>
              {this.state.displayList === 'Watchlist' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {this.props.myWatchlist
                    ? this.props.myWatchlist.length === 0
                      ? null
                      : this.showTickers()
                    : null}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {this.state.displayList === 'UAStocks' ? (
                    <div style={{ width: '100%' }}>
                      {this.showUnusualActivity()}
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      {this.showUnusualActivity()}
                    </div>
                  )}
                </div>
              )}
            </div>
            {this.addModal()}
            <Disclaimer t={this.props.t} />
          </div>
        )
      }
    } else return null
  }
}
