import Axios from 'axios'
import React, { Component } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { IoIosClose } from 'react-icons/io'
import Modal from 'react-modal'
import Endpoint from '../Endpoint'
import '../../scss/components/ChangePasswordModal.scss'
import { validatePassword } from '../../utility-functions/validatePassword'
import PasswordInput from '../PasswordInput'
import PasswordMessage from '../PasswordMessage'

export default class ChangePasswordModal extends Component {
  state = {
    previousPassword: '',
    password: '',
    confirmPassword: '',
    errors: '',
    successMessage: '',
    isLoading: false,
    status: false,
    showPasswordMessage: false,
  }

  changePassword = async () => {
    this.setState({ isLoading: true })
    await Axios.get(
      `${Endpoint}/mobile/change-temp-password?email=${this.props.email}&password=${this.state.password}`
    )
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({ isLoading: false })
          this.toggleSuccessMessage(this.props.t('passwordupdatedsuccessfully'))
        } else {
          this.setState({ isLoading: false })
          this.toggleErrorMessage(this.props.t('somethingwentwrong'))
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ isLoading: false })
      })
    this.setState({ isLoading: false })
  }

  validateForm = () => {
    if (
      this.state.previousPassword !== '' &&
      this.state.password !== '' &&
      this.state.confirmPassword !== ''
    ) {
      if (this.state.previousPassword === this.props.previousPassword) {
        if (this.state.password === this.state.confirmPassword) {
          if (validatePassword(this.state.password)) {
            this.changePassword()
          } else {
            this.setState({ showPasswordMessage: true })
            //this.toggleErrorMessage(this.props.t('pleaseentervalidpassword'))
          }
        } else {
          this.toggleErrorMessage(
            this.props.t('newpasswordandconfirmdoesnotmatch')
          )
        }
      } else {
        this.toggleErrorMessage('youroldpasswordisincorrect')
      }
    } else {
      this.toggleErrorMessage(this.props.t('pleasecompleteallfields'))
    }
  }

  setPreviousPassword = (value) => {
    this.setState({ previousPassword: value })
  }

  setPassword = (value) => {
    this.setState({ password: value })
  }

  setConfirmPassword = (value) => {
    this.setState({ confirmPassword: value })
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errors: msg, successMessage: '' })
    // setTimeout(() => {
    //   this.setState({ errors: '' })
    // }, 5000)
  }

  toggleSuccessMessage = (msg) => {
    this.setState({ successMessage: msg, status: true, errors: '' })
    // setTimeout(() => {
    //   this.setState({ successMessage: '' })
    // }, 5000)
  }

  render() {
    if (this.props.t) {
      return (
        <div className='change-password-modal-container'>
          <Modal
            style={this.props.customStyles}
            isOpen={this.props.showChangePasswordModal}
            ariaHideApp={false}
            onRequestClose={() =>
              this.props.toggleShowChangePasswordModal(false)
            }
          >
            <div
              className='modal__container'
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <div className='top__container'>
                {this.props.t('changepassword')}
                <IoIosClose
                  className='icon'
                  onClick={() =>
                    this.props.toggleShowChangePasswordModal(false)
                  }
                />
              </div>
              <div className='form__container'>
                {/* <div className='success-message'>
                  {this.state.successMessage}
                </div>
                {this.state.status ? (
                  <div
                    className='login-button'
                    onClick={() => {
                      this.props.toggleShowChangePasswordModal(false)
                    }}
                  >
                    {this.props.t('clickheretologin')}
                  </div>
                ) : null} */}
                {this.state.status && this.state.successMessage ? (
                  <>
                    <div className='success-message'>
                      {this.state.successMessage}
                    </div>
                    <div
                      className='login-button'
                      onClick={() => {
                        this.props.toggleShowChangePasswordModal(false)
                      }}
                    >
                      {this.props.t('clickheretologin')}
                    </div>
                  </>
                ) : (
                  <>
                    {' '}
                    <p> {this.props.t('temporarypassword')}:</p>
                    <PasswordInput
                      password={this.state.previousPassword}
                      setPassword={this.setPreviousPassword}
                      placeHolder=''
                      className={'input__line'}
                    />
                    <p> {this.props.t('newpassword')}:</p>
                    <PasswordInput
                      password={this.state.password}
                      setPassword={this.setPassword}
                      placeHolder=''
                      className={'input__line'}
                    />
                    <p>{this.props.t('confirmnewpassword')}:</p>
                    <PasswordInput
                      password={this.state.confirmPassword}
                      setPassword={this.setConfirmPassword}
                      placeHolder=''
                      className={'input__line'}
                    />
                    {this.state.showPasswordMessage && (
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <PasswordMessage
                          t={this.props.t}
                          password={this.state.password}
                        />
                      </div>
                    )}
                    {this.state.isLoading ? (
                      <Spinner
                        animation='border'
                        id='spinner'
                        variant='dark'
                        role='status'
                        style={{ marginTop: '20px' }}
                      >
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    ) : (
                      <div
                        onClick={() => this.validateForm()}
                        className='login__button__2'
                      >
                        {this.props.t('submit')}
                      </div>
                    )}
                    <div className='errors__container'>{this.state.errors}</div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </div>
      )
    } else return null
  }
}
