import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import React, { Component } from 'react'
import Axios from 'axios'
import '../../scss/components/AlertsForm.scss'
import Endpoint from '../Endpoint'
import { ConfirmDialog } from 'primereact/confirmdialog'

export default class AlertsForm extends Component {
  state = {
    value: this.props.selectedStock.s1,
    selected: 's1',
    showConfirmDialog: false,
    showDeleteDialog: false,
    selectedCrossover: null,
    deleteId: null,
  }

  submitAlert = async (indicator) => {
    let body = {
      symbol: this.props.selectedStock.symbol,
      price: this.props.selectedStock.price,
    }
    if (indicator) body.indicator = indicator
    else body.thresholdPrice = this.state.value
    await Axios.post(`${Endpoint}/mobile/fibonacci-alerts/setup`, body, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => {
        if (response.status) this.props.getStockAlerts()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteAlert = async () => {
    await Axios.get(
      `${Endpoint}/mobile/fibonacci-alerts/cancel?alert_id=${this.state.deleteId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => {
        if (response.status) this.props.getStockAlerts()
      })
      .catch((error) => {
        console.log(error)
      })
    this.toggleShowDeleteDialog()
  }

  handleValueChange = (value) => {
    this.setState({ value: value })
  }

  handleButtonChange = (value, amount) => {
    this.setState({ selected: value, value: amount })
  }

  toggleShowConfirmDialog = (value) => {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      selectedCrossover: value,
    })
  }

  toggleShowDeleteDialog = (id) => {
    this.setState({
      showDeleteDialog: !this.state.showDeleteDialog,
      deleteId: id,
    })
  }

  indicators() {
    return (
      <div className='indicators-container'>
        <p className='sub-header'>{this.props.t('selectfromindicators')}: </p>
        <div className='indicators'>
          <div className='indicators-row'>
            <Button
              className={
                this.state.selected === 's1' ? 'button-active' : 'button'
              }
              label={this.props.t('oneweeksupport')}
              onClick={() => {
                this.handleButtonChange('s1', this.props.selectedStock.s1)
              }}
            />
            <Button
              label={this.props.t('oneweekresistance')}
              className={
                this.state.selected === 'r1' ? 'button-active' : 'button'
              }
              onClick={() => {
                this.handleButtonChange('r1', this.props.selectedStock.r1)
              }}
            />
          </div>
          <div className='indicators-row'>
            <Button
              label={this.props.t('onemonthsupport')}
              className={
                this.state.selected === 's2' ? 'button-active' : 'button'
              }
              onClick={() => {
                this.handleButtonChange('s2', this.props.selectedStock.s2)
              }}
            />
            <Button
              label={this.props.t('onemonthresistance')}
              className={
                this.state.selected === 'r2' ? 'button-active' : 'button'
              }
              onClick={() => {
                this.handleButtonChange('r2', this.props.selectedStock.r2)
              }}
            />
          </div>
          <div className='indicators-row'>
            <Button
              label={this.props.t('bollingersupport')}
              className={
                this.state.selected === 'bollinger_down'
                  ? 'button-active'
                  : 'button'
              }
              onClick={() => {
                this.handleButtonChange(
                  'bollinger_down',
                  this.props.selectedStock.bollinger_down
                )
              }}
            />
            <Button
              label={this.props.t('bollingerresistance')}
              className={
                this.state.selected === 'bollinger_up'
                  ? 'button-active'
                  : 'button'
              }
              onClick={() => {
                this.handleButtonChange(
                  'bollinger_up',
                  this.props.selectedStock.bollinger_up
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  crossovers() {
    return (
      <div className='crossovers-container'>
        <p className='sub-header'>{this.props.t('selectfromcrossovers')}: </p>
        <div className='crossovers'>
          <Button
            label={this.props.t('bullishcrossover')}
            className='button'
            onClick={() => {
              this.toggleShowConfirmDialog('BULLISH_EMA')
            }}
          />
          <Button
            label={this.props.t('bearishcrossover')}
            className='button'
            onClick={() => {
              this.toggleShowConfirmDialog('BEARISH_EMA')
            }}
          />
        </div>
      </div>
    )
  }

  getCrossoverLabel() {
    if (this.state.selectedCrossover) {
      if (this.state.selectedCrossover === 'BULLISH_EMA') return 'Bullish'
      else if (this.state.selectedCrossover === 'BEARISH_EMA') return 'Bearish'
    }
    return null
  }

  submitAlertWithIndicator = () => {
    this.submitAlert(this.state.selectedCrossover)
    this.toggleShowConfirmDialog()
  }

  alertsCard() {
    if (this.props.alertsData && this.props.alertsData.length > 0) {
      return (
        <div className='alerts-container'>
          <p className='heading'>{this.props.t('alerts')}</p>
          {this.props.alertsData.map((alert) => {
            return (
              <div className='alerts-row'>
                {alert.indicator ? (
                  <p className='alerts-price'>
                    {alert.indicator.replace('_', ' ')}
                  </p>
                ) : (
                  <p className='alerts-price'>
                    {this.props.formatCurrency(alert.thresholdPrice)}
                  </p>
                )}
                <p
                  className='cancel-button'
                  onClick={() => {
                    this.toggleShowDeleteDialog(alert.id)
                  }}
                >
                  {this.props.t('cancelalert')}
                </p>
              </div>
            )
          })}
        </div>
      )
    } else return null
  }

  render() {
    if (this.props.t) {
      return (
        <div className='alerts-form-container'>
          <p className='heading'>{this.props.t('setanalert')}</p>
          <div className='input-container'>
            <InputNumber
              value={this.state.value}
              onValueChange={(e) => this.handleValueChange(e.value)}
              mode='currency'
              currency='USD'
              locale='en-US'
              minFractionDigits={0}
              maxFractionDigits={2}
            />
            <Button
              label={this.props.t('set')}
              className='set-button'
              onClick={() => {
                this.submitAlert(null)
              }}
            />
          </div>
          {this.indicators()}
          {this.crossovers()}
          {this.alertsCard()}
          <ConfirmDialog
            visible={this.state.showConfirmDialog}
            onHide={() => this.toggleShowConfirmDialog}
            message={`Set ${this.getCrossoverLabel()} Crossover Alert?`}
            header={this.props.t('confirmation')}
            icon='pi pi-exclamation-triangle'
            accept={this.submitAlertWithIndicator}
            reject={this.toggleShowConfirmDialog}
            style={{ maxWidth: this.props.isSmallScreen ? '80vw' : '' }}
          />
          <ConfirmDialog
            visible={this.state.showDeleteDialog}
            onHide={() => this.toggleShowDeleteDialog}
            message={this.props.t('areyousureyouwanttocancelthisalert')}
            header='Confirmation'
            icon='pi pi-exclamation-triangle'
            accept={this.deleteAlert}
            reject={this.toggleShowDeleteDialog}
            style={{ maxWidth: this.props.isSmallScreen ? '80vw' : '' }}
          />
        </div>
      )
    } else return null
  }
}
