import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";

class StrategiesPolicy extends Component {
  render() {
    return (
      <Container fluid className="strategies-policy-container">
        <Container>
          <Row>
            <p className="strategies-policy-text">
              *{" "}
              <span style={{ fontWeight: "bold" }}>
                Performance data shown represents hypothetical performance,
                which is no guarantee of future results.
              </span>{" "}
              The investment return and principal value of an investment in
              AllocateRite LLC or the “Fund” will fluctuate so that, when
              redeemed, it may be worth more or less than the original cost.
              Current performance may be higher or lower than the data quoted.
              Returns less than one year are cumulative. The performance of an
              index is not an exact representation of any particular investment,
              as you cannot invest directly in an index. Investments are not
              FDIC insured and they may lose value. Returns include changes in
              share prices and reinvested dividends, but do not consider
              transaction costs to buy and sell securities, management fee, capital gains or
              income taxes on an individual’s investment. The performance data
              presented represents backtested performance based on simulated
              index data of fund results from January 1, 2005 to the current
              period end date shown, using the strategy of buy and hold and at
              the beginning of each monthly or intra-monthly rebalancing of the
              fund. Backtested performance is hypothetical (it does not reflect
              trading in actual accounts) and is provided for informational
              purposes only to indicate historical performance had the index
              portfolios been available over the relevant time period.
              AllocateRite refers to this hypothetical data as a Simulated
              Passive Returns (“SPR”). Monthly SPR was calculated from January
              1, 2005 through the most current date. These simulated results,
              prepared by AllocateRite, are in the process of being verified by
              a third-party source. All performance returns, other than where
              noted as actual, should be assumed as simulated hypothetical
              returns.
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              Note: Past performance is not indicative of future results.
              Composite returns include the reinvestment of income.
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              The 60:40 return uses 60% of the S&P 500 return and 40% of the
              Barclays US Aggregate Total Return (Bloomberg Symbol: LBUSTRUU)
              which is an index holding intermediate term U.S. traded investment
              grade bonds.
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              See{" "}
              <a
                className="terms-link"
                href="http://localhost:3000/DisclaimersDisclosures"
              >
                Disclaimers and Disclosures
              </a>{" "}
              for additional disclosures.
            </p>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default StrategiesPolicy;
