//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//ICONS
import PortfolioAllocations from '../../../assets/portfolio-allocations.png'
import PortfolioAmount from '../../../assets/portfolio-amount.png'
import ExpertStrategies from '../../../assets/expert-strategies.png'

//CONTEXT
import { ProductPageContext } from './ProductPage'

export default function UpgradeTools() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='column-container'>
      <p className='big-text light-text'>{t('upgraded_tools')}</p>
      <div className='product-page-row left-border space-top-big'>
        <div className='item'>
          <p className='big-text light-text space-bottom'>{t('aitrader')}</p>
          <p className='small-text space-bottom'>
            {t('trades_are_automatically_msg')}
          </p>
          <p className='medium-text'>{t('automate_your_trading_msg')}</p>
        </div>
        <div className='item-right'>
          <img src={PortfolioAllocations} className='tools-img' />
        </div>
      </div>
      <div className='product-page-row left-border space-top-big'>
        <div className='item'>
          <p className='big-text light-text space-bottom'>{t('goals')}</p>
          <p className='small-text'>{t('its_important_to_set_goals_msg')}</p>
        </div>
        <div className='item-right'>
          <img src={ExpertStrategies} className='tools-img' />
        </div>
      </div>
      <div className='product-page-row left-border space-top-big'>
        <div className='item'>
          <p className='big-text light-text capitalize space-bottom'>
            {t('budgeting')}
          </p>
          <p className='small-text'>{t('aggregateallyouraccountsinfo')}</p>
        </div>
        <div className='item-right'>
          <img src={PortfolioAmount} className='tools-img' />
          <p className='tools-disclaimer-text' style={{ marginTop: 10 }}>
            Chart is not indicative of future performance, it is for simulation
            purposes only
          </p>
        </div>
      </div>
    </div>
  )
}
