import React, { Component } from 'react'
import PL from '../PL'
import { InputSwitch } from 'primereact/inputswitch'
import Axios from 'axios'
import Endpoint from '../../../Endpoint'
import ConfirmationDialog from '../../ConfirmationDialog'
import { Dialog } from 'primereact/dialog'

export default class GoalsSpecificInfo extends Component {
  state = {
    showConfirmationDialog: false,
    nonTradingHoursDialog: false,
  }

  deactivateGoal = async () => {
    return await Axios.post(
      `${Endpoint}/mobile/liquidate`,
      {
        dw_account_id: this.props.selectedAccount.accountId,
        product: 'goal',
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((response) => {
        if (
          response?.data['Liquidate Account'] &&
          response?.data['Liquidate Account'] === 'Complete'
        ) {
          this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  toggleShowConfirmationDialog = () => {
    if (this.props.canTrade?.status) {
      this.setState({
        showConfirmationDialog: !this.state.showConfirmationDialog,
      })
    } else {
      this.toggleNonTradingHoursDialog()
    }
  }

  toggleNonTradingHoursDialog = () => {
    this.setState({ nonTradingHoursDialog: !this.state.nonTradingHoursDialog })
  }

  intro() {
    let productName
    if (this.props.productInstanceData.accountInfo?.productTitle === 'Short')
      productName = this.props.t('SHORT TERM TARGET')
    else if (
      this.props.productInstanceData.accountInfo?.productTitle === 'Medium'
    )
      productName = this.props.t('MEDIUM TERM TARGET')
    else productName = this.props.t('LONG TERM TARGET')
    return (
      <div>
        <p
          style={{
            color: '#3D32E7',
            fontWeight: 'bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {productName} :{' '}
          {this.props.formatCurrency(this.props.productInstanceData.target)}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('totalaccountvalue')}:</p>
          <p>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].virtualAccountValue
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('equityvalue')}:</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData?.cashData?.equityValue
            )}
          </p>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>Net P/L:</p>
          <PL
            formatCurrency={this.props.formatCurrency}
            value={this.props.selectedAccount.balances[0]['profit-loss']}
          />
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {this.props.productInstanceData.ontrack ? (
            <p>
              {this.props.t('currently')}:{' '}
              <span
                style={{
                  color: 'white',
                  marginLeft: '5px',
                  padding: '5px 15px',
                  borderRadius: '10px',
                  background: '#3D32E7',
                }}
              >
                {this.props.t('ontrack')}
              </span>
            </p>
          ) : (
            <p>
              {this.props.t('currently')}:{' '}
              <span
                style={{
                  color: 'white',
                  marginLeft: '5px',
                  padding: '5px 15px',
                  borderRadius: '10px',
                  background: 'grey',
                }}
              >
                {this.props.t('offtrack')}
              </span>
            </p>
          )}
        </div>
      </div>
    )
  }

  overview() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('overview')}
        </p>
        {this.props.canTrade ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>{this.props.t('active')}</p>
            </div>
            <InputSwitch
              checked={true}
              onChange={(e) => this.toggleShowConfirmationDialog()}
            />
          </div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('startdate')}</p>
          <p>{this.props.productInstanceData.start_date}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('investmentamount')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.total_deposit
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('target')}</p>
          <p>
            {this.props.formatCurrency(this.props.productInstanceData.target)}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('currentvalue')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].virtualAccountValue
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('periodiccontribution')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.periodicContribution
            )}
          </p>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Return-to-date</p>
          <PL formatCurrency={this.props.formatCurrency} value={this.props.productInstanceData.profit} percentage={this.props.productInstanceData.profit_percentage}/>
        </div> */}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            padding: '25px 0',
          }}
        >
          {this.intro()}
          {this.overview()}
          <ConfirmationDialog
            name={this.props.t('deactivategoals')}
            action={this.deactivateGoal}
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            successMessage={this.props.t('goalshasbeensuccessfullydeactivated')}
            t={this.props.t}
          />
          <Dialog
            visible={this.state.nonTradingHoursDialog}
            onHide={this.toggleNonTradingHoursDialog}
            header={this.props.t('cannotdeactivateroboduringnontradinghours')}
          ></Dialog>
        </div>
      )
    } else return null
  }
}
