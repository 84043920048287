import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    isSmallScreen: false,
    errorMessage: '',
  },
  reducers: {
    setIsSmallScreen: (state, action) => {
      return {
        ...state,
        isSmallScreen: action.payload,
      }
    },
    setErrorMessage: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload,
      }
    },
  },
})

export const { setIsSmallScreen, setErrorMessage } = generalSlice.actions

export default generalSlice.reducer
