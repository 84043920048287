import { Nav } from 'aws-amplify-react';
import React from 'react'
import { FaPlayCircle, FaTimesCircle, FaBars }  from "react-icons/fa";
import { NavLink } from 'react-router-dom'
import BackgroundImg from "../assets/about/bg-wavy@3x.png"
import ForeSCITEDarkText from "../assets/landingPage/ForeSCITE_Dark_Text_cropped.png"
import ForeSCITELightText from "../assets/landingPage/ForeSCITE_Light_Text_cropped.png"
export default class PublicHeader3 extends React.Component{
    
    state={
        dropdown:false,
        scrolled:false,
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
      }

    listenScrollEvent = e => {
    if (window.scrollY > 20) {
        this.setState({scrolled: true})
    } else {
        this.setState({scrolled: false})
    }
    }

    showDropdown = ()=>{
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'100%' :'40%', height: '100%', paddingBottom:25}}>
                    <ul style={{ flexDirection:'column',listStyleType: 'none',margin:0, padding:5, backgroundColor:'rgb(255,255,255)', }}>
                        <li style={{ display:'flex', justifyContent:'center',position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                           <NavLink to="/">
                                <img
                                        src={ForeSCITEDarkText}
                                        style={{width:'70%', }}
                                />
                           </NavLink>
                        </li>
                        <li style={{ display:'flex', justifyContent:'space-evenly',position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <div>
                                <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:18, fontWeight:'bold'}}>Personal</p>
                            </div> 
                            <div >
                                <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:18, fontWeight:'', marginBottom:3}}>|</p>
                            </div> 
                            <div>
                                <a href='https://www.allocaterite.com/home.jsp' style={{margin:0, textAlign:'center', color:'#080808', fontSize:18}}>Business</a>
                            </div> 
                        </li>
                        <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <NavLink style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} to="/products-info">
                            <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                Platform
                            </p>
                            </NavLink>
                        </li>
                        <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <NavLink style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} to="/About">
                            <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                About
                            </p>
                            </NavLink>
                        </li>
                        {/* <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <NavLink style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} to="/Strategies">
                            <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                Strategies
                            </p>
                            </NavLink>
                        </li> */}
                        <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <NavLink style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} to="/Login">
                                <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                    {this.props.user? 'Dashboard':'Sign In'}
                                </p>
                            </NavLink>
                        </li>
                        
                        {this.props.user?
                            <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                                <div style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} onClick={()=>this.props.signOut()}>
                                    <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                        Sign Out
                                    </p>
                                </div>
                            </li>
                        :
                        <li style={{ position: 'relative', cursor:"pointer", borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.25px', padding:15}}>
                            <NavLink style={{color:'rgb(17,17,17)', textAlign:'left', textDecoration:'none'}} to="/Login">
                                <p style={{textAlign:"left", color:'rgb(17,17,17)', margin:0}}>
                                    Sign Up
                                </p>
                            </NavLink>
                        </li>
                        }
                        
                    </ul>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'baseline', marginTop:50}}>
                        <FaTimesCircle size={50} color='rgb(108,190,179)' onClick={()=>this.setState({dropdown:false})}/>
                    </div>
                </div>
            </div>
        )
    }
    
    renderSmall = ()=>{
        return(
            <div style={{ display:'flex', alignItems: 'center',position:'fixed',top:0,backgroundColor:this.state.scrolled?'rgb(255,255,255)':'transparent', boxShadow:this.state.scrolled?'0px 0px 5px 1px #cccccc':'', zIndex:199, justifyContent:'space-between',}}>
                <div style={{width:'95%', margin:'auto', height:60,padding:20}}>
                    <div style={{width:'50%',display:'flex',justifyContent:'flex-start', alignItems:'center',float:'left' }}>
                        <img
                            // src={this.state.scrolled? ForeSCITEDarkText : ForeSCITELightText}
                            src={ForeSCITEDarkText }
                            style={{width:'80%', objectFit:'contain', }}
                        />
                    </div>
                    <div style={{display:'flex', alignItems:'center',float:'right'}} onClick={()=>this.setState({dropdown:!this.state.dropdown})}>
                        <FaBars />
                        {this.state.dropdown? <div style={{ position:'absolute', top:45, marginRight:80}}>{this.showDropdown()}</div> : null}
                    </div>
                </div>
            </div>
        )
    }

    renderBig = ()=>{
        return(
            <div style={{width:'100%',}}>
                
            <div style={{ display:'flex', alignItems: 'center', width: '100%', clear: 'both',position:'fixed',top:0,/*backgroundColor:this.state.scrolled?'rgb(255,255,255)':'transparent',*/ boxShadow:this.state.scrolled?'0px 0px 5px 1px #cccccc':'', zIndex:199, justifyContent:'space-between',}}>
                <div style={{ width: '100%', zIndex:-100,position:'absolute',}}>
                    <img
                        src={BackgroundImg}
                        style={{
                            // marginTop: 5,
                            width: '100%',
                            height: 75,
                            justifySelf:'flex-end'
                        }}
                    />
                </div>
            <div style={{width:'98%', margin:'auto', display:'flex', justifyContent:'space-between',alignItems:'center', height:75,}}>
                <div style={{width:'40%', height:'100%',display:'flex',justifyContent:'space-between', alignItems:'center', }}>
                    <NavLink to="/">
                        <img
                            // src={this.state.scrolled? ForeSCITEDarkText : ForeSCITELightText}
                            src={ForeSCITEDarkText}

                            style={{width:'50%',height:'50%', marginLeft:50, objectFit:'contain' }}
                        />
                    </NavLink>
                    <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center', width:'30%'}}>
                        <div >
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:18, fontWeight:'bold', paddingRight: 15}}>Personal</p>
                        </div> 
                        <div >
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:18, fontWeight:'', marginBottom:3}}>|</p>
                        </div> 
                        <div>
                            <a href='https://www.allocaterite.com/home.jsp' style={{margin:0, textAlign:'center', color:'#080808', fontSize:18, paddingLeft: 15}}>Business</a>
                        </div> 
                    </div>
                </div>
                <div style={{width:'50%', display:'flex',justifyContent:'space-evenly', alignItems:'center',maxWidth: 640}}>
                    <NavLink style={{display:"flex",alignItems:'center',justifyContent:"center"}} to='/products-info'>
                        <div>
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:16}}>Platform</p>
                        </div>
                    </NavLink>
                    <NavLink style={{display:"flex",alignItems:'center',justifyContent:"center"}} to='/About'>
                        <div>
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:16}}>About</p>
                        </div>
                    </NavLink>
                    {/* <NavLink style={{display:"flex",alignItems:'center',justifyContent:"center"}} to='/Strategies'>
                        <div>
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:16}}>Strategies</p>
                        </div>
                    </NavLink> */}
                    {this.props.user?
                    <NavLink style={{display:"flex",alignItems:'center',justifyContent:"center"}} to='/Dashboard'>
                        <div>
                            <p style={{margin:0, textAlign:'center', color:'#080808', fontSize:16}}>Dashboard</p>
                        </div>
                    </NavLink>
                    :null
                    }
                     {this.props.user?
                        <div style={{
                            padding: '9px 29px 8px 28px',
                            boxShadow: '2.4px 4.4px 2.6px 0.5px rgba(20, 20, 20, 0.33)',
                            borderRadius:20,
                            backgroundColor:'rgb(53,55,105)',
                            display:"flex",
                            alignItems:'center',
                            justifyContent:"center"
                        }}
                            onClick={()=>this.props.signOut()}
                        >
                            <p style={{margin:0, textAlign:'center', color:'#fcfcfc', fontSize:16}}>Log out</p>
                        </div>
                    :
                        <NavLink style={{display:"flex",alignItems:'center',justifyContent:"center"}} to='/Login'>
                            <div style={{
                                padding: '9px 29px 8px 28px',
                                boxShadow: '2.4px 4.4px 2.6px 0.5px rgba(20, 20, 20, 0.33)',
                                borderRadius:20,
                                backgroundColor:'rgb(53,55,105)',
                                display:"flex",
                                alignItems:'center',
                                justifyContent:"center"
                            }}>
                                <p style={{margin:0, textAlign:'center', color:'#fcfcfc', fontSize:16}}>Log In</p>
                            </div>
                        </NavLink>
                    }
                </div>
            </div>
        </div>

        </div>  
        )
    }

    render(){
        return(
            this.props.isSmallScreen? this.renderSmall() : this.renderBig()
        )
    }
}