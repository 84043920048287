import React from 'react'
import CompanyInfo from '../companyPage3/CompanyInfo'
import CurrPrevTrend from '../companyPage3/CurrPrevTrend'
import SingleForecastChart from './SingleForecastChart'

export default class Ptp extends React.Component{
    state={

    }

    render(){      
        if (this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                    <div style={{marginBottom:20,marginTop:20}}>
                        <CompanyInfo maxDrawdownVal={this.props.maxDrawdownVal}  priceUSD={this.props.priceUSD} volume={this.props.volume} isSmallScreen={this.props.isSmallScreen} cvar={this.props.cvar} isCrypto={this.props.isCrypto} companyName={this.props.companyName} symbol={this.props.symbol} industry={this.props.industry} tickerCurrency={this.props.tickerCurrency} currentPrice={this.props.currentPrice} isSmallScreen={true} priceChange={this.props.priceChange} priceChangeValue={this.props.priceChangeValue}/>
                    </div>
                    {/* <div style={{marginBottom:20}}>
                        <CurrPrevTrend isCrypto={this.props.isCrypto} priceForecast={this.props.priceForecast} currentPrice={this.props.currentPrice} generated={this.props.directionLastUpdate} signalPrice={this.props.signalPrice} direction={this.props.direction} isSmallScreen={true}/>
                    </div>
                    {this.props.isCrypto?
                    null : 
                    <div style={{marginBottom:20}}>
                        <CurrPrevTrend generated={this.props.directionPrevLastUpdate} direction={this.props.directionPrev} prev={true} isSmallScreen={true}/>
                    </div>
                    } */}
                    {this.props.symbol ? <SingleForecastChart {...this.props}/> : null}
                </div>
            )
        } else{
            return(
                <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center'}}>
                    <div style={{height:'61%', width:'29%', marginRight: '1.5%'}}>
                        {
                            this.props.cvar? 
                            <CompanyInfo maxDrawdownVal={this.props.maxDrawdownVal} priceUSD={this.props.priceUSD} volume={this.props.volume} isSmallScreen={this.props.isSmallScreen} cvar={this.props.cvar} isCrypto={this.props.isCrypto} companyName={this.props.companyName} symbol={this.props.symbol} industry={this.props.industry} tickerCurrency={this.props.tickerCurrency} currentPrice={this.props.currentPrice} priceChange={this.props.priceChange} priceChangeValue={this.props.priceChangeValue}/>
                            :
                            null
                        }
                    </div>
                    <div style={{height:'61%', width:'41%'}}>
                        {this.props.symbol ? <SingleForecastChart {...this.props}/> : null}
                        {/* <div style={{height:'45%', width:'100%', marginBottom:20}}>
                            <CurrPrevTrend isCrypto={this.props.isCrypto} priceForecast={this.props.priceForecast} currentPrice={this.props.currentPrice} isCrypto={this.props.isCrypto} generated={this.props.directionLastUpdate} signalPrice={this.props.signalPrice} direction={this.props.direction}/>
                        </div>
                        {this.props.isCrypto?
                            null : 
                        <div style={{height:'45%', width:'100%',marginTop:20}}>
                            <CurrPrevTrend generated={this.props.directionPrevLastUpdate} direction={this.props.directionPrev} prev={true}/>
                        </div>
                        } */}
                    </div>
                </div>
            )
        }
    }
}