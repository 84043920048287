//REACT
import React from 'react'
import { formatPrice } from '../utility-functions/formatPrice'

//COMPONENTS
import CustomImage from './CustomImage'
import EthIcon from './EthIcon'

const NftCard = ({ children }) => {
  return <div style={styles.container}>{children}</div>
}

const CardImage = ({ url, label }) => {
  return (
    <div style={styles.imageContainer}>
      {url ? (
        <img style={styles.image} src={url} />
      ) : (
        <CustomImage
          label={label}
          containerStyle={styles.customImageStyle}
          labelStyle={styles.customImageLabelStyle}
        />
      )}
    </div>
  )
}

const CardTitle = ({ title }) => {
  return (
    <div style={styles.titleContainer}>
      <p style={styles.title}>{title}</p>
    </div>
  )
}

const CardInfo = ({ name, price }) => {
  return (
    <div style={styles.infoContainer}>
      <p style={styles.light}>{name}</p>
      <div style={styles.centerRow}>
        <EthIcon width={10} height={10} />
        <p style={styles.regular}>{formatPrice(price)}</p>
      </div>
    </div>
  )
}

const CardFooter = ({ children }) => {
  return <div style={styles.infoContainer}>{children}</div>
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(128,128,128,.12)',
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },

  imageContainer: {
    width: '100%',
    flex: 5,
    overflow: 'hidden',
  },

  titleContainer: {
    width: '100%',
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  infoContainer: {
    width: '100%',
    flex: 1.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },

  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    flex: 1,
  },

  customImageStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: '#3627e4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  customImageLabelStyle: {
    fontSize: 50,
    color: 'white',
    margin: 0,
  },

  bold: {
    fontWeight: 'bold',
    fontSize: 10,
    color: 'black',
    marginBottom: 0,
  },

  light: {
    fontWeight: 'bold',
    fontSize: 10,
    color: 'grey',
    marginBottom: 0,
    //color: "rgba(128,128,128,.6)",
  },

  regular: {
    fontSize: 10,
    color: 'black',
    marginBottom: 0,
  },

  centerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    color: 'black',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 0,
  },
}

NftCard.Image = CardImage
NftCard.Title = CardTitle
NftCard.Info = CardInfo
NftCard.Footer = CardFooter
export default NftCard
