import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

export default class AreYouSure extends React.Component{

    state = {
        yn:'No',
        submitClicked:false,
    }

    stockList = ()=>{
        let str = ''
        this.props.selected.map(ele=>str+=`${ele.symbol}, `)
        return str.slice(0, -2)
    }

    group = ()=>{
        const acc = this.props.info.acc

        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p style={{fontSize:20}}>
                           Are you sure you want to start a trade group in {acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}?
                        </p>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%',flexDirection:'column' }}>
                        <p style={{fontSize:20}}>
                            In the event that you have holdings this will liquidate all of your existing positions for this account and then trade on your behalf going forward. 
                        </p>
                        <p style={{fontSize:20}}>
                        To access this account or the money in it you must unlink it from this trade group. 
                        </p>
                        <p style={{fontSize:20}}>
                            Due to day trading laws trade groups function best in accounts with more than $30,000 in the account. 
                        </p>
                    </div>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p style={{fontSize:20}}>
                            {this.props.selected.length > 1 ? `The ${this.props.selected.length} stocks you have selected are: ${this.stockList()}.` : `The only stock you have selected is ${this.props.selected[0].symbol}.`}
                            
                        </p>
                    </div>
            </div>
        )
    }

    robo = ()=>{
        const acc = this.props.info.acc
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p style={{fontSize:20}}>
                           Are you sure you want to start use robo trading for {acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}?
                        </p>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%',flexDirection:'column' }}>
                        <p style={{fontSize:20}}>
                            In the event that you have holdings this will liquidate any of your existing positions for this account and then automatically invest using ForeSCITE Robo. 
                        </p>
                        <p style={{fontSize:20}}>
                            To access this account or the money in it you must unlink it from the robo. 
                        </p>
                    </div>
            </div>
        )
    }

    openLiq = ()=>{
        const acc = this.props.info.acc
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p style={{fontSize:20}}>
                          You cannot start using {this.props.product === 'group' ? 'group ':'robo '} trading for {acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`} because your account is in ''
                        </p>
                    </div>
                    
            </div>
        )
    }

    

    render(){
        // console.log('AYS.props', this.props)
        if (this.props.product === 'normal'){
            this.props.confirm()
        }
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Confirm Account</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    {this.props.info.acc.status === 'OPEN_NO_NEW_TRADE'? this.openLiq() : this.props.product === 'group' ? this.group():this.robo()}
                    <div style={{width: '70%', margin:'auto', marginBottom:10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', }}>
                            <input type="radio" checked={this.state.yn==='Yes'} onChange={()=>{this.setState({yn:'Yes'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>Yes, I am sure.</p>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                            <input type="radio" checked={this.state.yn==='No'} onChange={()=>{this.setState({yn:'No'})}}/> 
                            <p style={{margin:0, marginLeft:10}}>No, do not proceed</p>
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%'}}>
                        <div 
                        onClick={this.state.yn === 'Yes'? ()=>{
                            this.setState({submitClicked:true})
                            this.props.confirm(this.props.info.id,this.props.info.total)
                        }
                        :null} 
                        style={{display:'flex', cursor:'pointer',justifyContent:'center',alignItems:'center', width:'50%', paddingLeft:12, paddingRight:12, paddingTop:10, paddingBottom:10, border:'solid', borderWeight: '0.5px',borderColor:'#3b8e8c', borderRadius:10}}>
                            {this.state.submitClicked? 
                                  <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                 :
                                    <p style={{color:'#3b8e8c', margin:0, fontWeight:'bold'}}>{this.props.product === 'group'?'Begin Trade Group':'Begin Robo Trading'} </p>
                                 }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}