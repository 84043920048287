import React from 'react'
import { Card, Row, } from "react-bootstrap";
import Header from "../layout/Header";
import SingleCompanyCard from "../singleCompanyCard"
import Footer from "../layout/Footer";
import {FaChevronDown, FaArrowRight, FaArrowLeft}  from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MobileWl from './mobileWl'
import HeaderDropdown from '../HeaderDropdown'
import ENDPOINT from '../Endpoint'


const brandColor = "#00A99D"

export default class Watchlist extends React.Component{
    state={
        shownList: 'My Watchlist',
        showListOptions: false,
        editMode: false,
        start: 0
    }

    componentDidMount(){
        // if (this.props.user) {
        //     this.fetchWatchList()
        // }
    }

    arrayToObject = (arr) => {
        let result = {};
        for (let i = 0; i < arr.length; i++) {
            result[arr[i].listTitle] = arr[i].tickers;
        }
        return result
    }

    fetchWatchList = () => {
        fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + this.props.user.idToken.jwtToken
            },
        }).then(response => response.json())
            .then(responseJson => {
                let watchListData = responseJson.watchlist;
                let predefinedLists = responseJson.predefinedLists
                let watchlistLastUpdated = (new Date(responseJson.lastUpdated * 1000)).toString()
                let sortedPredefinedLists = this.arrayToObject(predefinedLists)
                this.setState({ myWatchlist: watchListData, presetTickers: sortedPredefinedLists, watchlistLastUpdated })
            })
            .catch(error => console.log(error))
    }

    fetchPresetTickers = () => {
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                this.setState({ presetTickers: responseJson.lists })
            })
    }

    


    individuleCompanyCard = (data) => {
        
        return (
            <Row
                style={{
                    padding: "2%",
                    textDecoration: "none",
                }}
            >
                {data.map((item, index) => {
                    return (
                        <SingleCompanyCard user={this.props.user} remove={this.props.removeFromWl} editMode={this.state.editMode} item={item} index={index} />
                    );
                })}
            </Row>
        );
    };

    showTickers = () => {
        let data
        if (this.state.shownList === 'My Watchlist') {
            data = this.props.myWatchlist
        } else {
            data = this.props.presetTickers[this.state.shownList]
        }
        let start = this.state.start
        let end
        if(data.length < start + 15){
            end = data.length 
        }else{
            end = start + 15
        }
        let mappedData = [{ columnTitle:true, priceUSD:0, direction:0, directionPrev:0, symbol:'' }, ...data.slice(start,end)].map(ele=>(<HeaderDropdown size={'large'} remove={this.props.removeFromWl} editMode={this.state.editMode} companyName={ele.company} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>))
        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 20,
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', }}>
                    {/* {this.state.shownList === 'My Watchlist' ?<div style={{width: '20%'}}/> : null} */}
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        {this.props.isSmallScreen? 'click a symbol for more info' :'select a ticker for more information'}
                    </p>
                    {this.state.shownList === 'My Watchlist' ?
                        <div style={{ width: '30%',marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                            <p style={{ backgroundColor: 'rgb(248,248,247)', padding: 10, borderRadius: 8, fontSize: 16, margin:0}} onClick={()=>this.setState({editMode: !this.state.editMode})}>{this.props.isSmallScreen? 'Edit' :'Edit Watchlist'}</p>
                        </div>:<div style={{width:'30%'}}/>
                    }
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
                <div style={{width:this.props.isSmallScreen?'100%' :'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', padding:'2%',margin:this.props.isSmallScreen?'':'auto' }}>
                    {/* {this.props.isSmallScreen ? <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data}/> :this.individuleCompanyCard(data)} */}
                    {this.props.isSmallScreen ? 
                        <MobileWl remove={this.props.removeFromWl} editMode={this.state.editMode} data={data}/> 
                        : 
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
                            {
                                mappedData
                            }
                        </div>
                }
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-around', width:'80%', margin:'auto', marginTop:0, marginBottom: 5}}>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center', borderRadius: 8, padding:5, backgroundColor:'rgb(200,200,200)', cursor:'pointer', }} 
                            onClick={()=>{
                                let start = this.state.start
                                if(this.state.start-15>=0){
                                    window.scrollTo(0, 400)
                                    start-=15
                                }else{
                                    console.log('outta bounds')
                                }
                                this.setState({start})
                             }}
                        >
                            {/* <p style={{margin:0}}>Back</p> */}
                            <FaArrowLeft />
                        </div>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'center', borderRadius: 8, padding:5, backgroundColor:'rgb(200,200,200)', cursor:'pointer', }} 
                            onClick={()=>{
                                let start = this.state.start
                                console.log('in clickEvent')
                                if(this.state.start+15<=data.length){
                                    window.scrollTo(0, 400)
                                    start+=15
                                }else{
                                    console.log('outta bounds')
                                }
                                this.setState({start})
                             }}
                        >
                            {/* <p style={{margin:0}}>Next</p> */}
                            <FaArrowRight/>
                        </div>
                    </div>
            </Card>
        );
    }

    showLists = () =>{
        let categories = ['My Watchlist']
        Object.keys(this.props.presetTickers).forEach(ele=>categories.push(ele))
        categories = categories.filter(ele => ele !== this.state.shownList)
        // let mapped = categories.map(ele => <li onClick={()=>this.setState({shownList: ele, showListOptions: false, editMode: false })} style={{cursor: 'pointer', fontWeight: 'bold', width: '100%', margin: 'auto', marginLeft: '15%'}}>{ele}</li>)
        let mapped = categories.map(ele=><li style={{marginBottom: 5, cursor:'pointer', backgroundColor: this.state.shownList !== ele ? 'rgb(146,146,146)':brandColor , padding: 7, borderRadius:5, width:'100%'}} onClick={()=>this.setState({shownList:ele})}>{ele}</li>)

        return(
            <ul style={{ backgroundColor: 'rgb(248,248,250)', listStyleType: 'none', padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', borderRadius: 12, marginTop: -15, zIndex: 100}}>
                {mapped}
            </ul>
        )
    }

    remove = (symbol) =>{
        let filtered = this.props.myWatchlist.filter(ele=> ele.symbol !== symbol)
        this.setState({myWatchlist: filtered})
    }

    render(){
        if (this.props.user == null){
            return(
                <div>
                    <Header user={this.props.user} signOut={this.props.signOut} />
                    <div>
                        <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <NavLink to={"/Login"} style={{ fontWeight: "bold", fontSize: 20, color:'#00A99D', backgroundColor: 'rgb(247,247,247)', borderRadius: 12, padding: '10px 20px'}}>
                                Please Login to see your watchlist
                            </NavLink>
                        </div>
                    </div>
                </div>
            )
        }else{
            // let data
            // if (this.state.shownList === 'My Watchlist') {
            //     data = this.props.myWatchlist
            // } else {
            //     data = this.props.presetTickers[this.state.shownList]
            // }
        return(
            <div>
                <div>
                    <div style={{ width:'80%', marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto' }}>
                        <div onClick={() => this.setState({ showListOptions: !this.state.showListOptions })} style={{ backgroundColor: 'rgb(248,248,250)', display: 'flex', justifyContent: 'center', alignItems: 'center',padding: '10px 25px', borderRadius: 12, width: this.props.isSmallScreen? '90%' : '40%',cursor:'pointer',}}>
                            <p style={{margin:0, fontSize: '1.5em'}}>
                                {this.state.shownList}
                            </p>
                            <div  style={{marginLeft: 5, marginTop: 3}}>
                                <FaChevronDown size={20} />
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 100, position: 'absolute' , display:'block', width: this.props.isSmallScreen? '50%' : '20%', left: this.props.isSmallScreen?'25%':'40%'   }}>
                        {this.state.showListOptions? this.showLists() : null}
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                        {this.showTickers()}
                    </div>
                    
                </div>
                <div>
                    <Footer/>
                </div>
            </div>
        )}
    }
}