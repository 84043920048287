//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import { InputText } from 'primereact/inputtext'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//APIS
import {
  addAsset,
  getBudgetingData,
  linkWalletAddress,
} from '../../services/userServices'
import {
  fetchPropertyData,
  fetchPropertySuggestions,
} from '../../services/generalService'

//STYLES
import '../../scss/components/budgeting/AddExternalAccount.scss'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'
import PlusIcon from '../../assets/plus.svg'

//COMPONENTS
import Loader from '../components/accountInfo/Loader'
import LinkPlaidCustom from '../components/LinkPlaidCustom'
import { InputNumber } from 'primereact/inputnumber'

export default function AddExternalAccount() {
  const {
    t,
    budgetingData,
    showExternalAccounts,
    isSmallScreenm,
    user,
    getAllAccountInfo,
    refreshBudgetingInfo,
    fetchBudgetingInfo,
  } = useContext(BudgetingContext)

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [successMsg, setSuccessMsg] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState(['home'])
  const [suggestions, setSuggestions] = useState([])

  const [homeData, setHomeData] = useState({
    address: '',
    estimated: '',
    loanBalance: 0,
    nickname: '',
    type: 'home',
  })

  const [vehicleData, setVehicleData] = useState({
    model: '',
    make: '',
    year: '',
    estimated: '',
    loanBalance: 0,
    nickname: '',
    type: 'auto',
  })

  const [valuableData, setValuableData] = useState({
    model: '',
    make: '',
    year: '',
    estimated: '',
    loanBalance: 0,
    nickname: '',
    type: 'valuable',
  })

  const [walletData, setWalletData] = useState({
    walletAddress: '',
    nickname: '',
  })

  useEffect(() => {
    setError('')
  }, [pages])

  useEffect(() => {
    if (homeData?.address) {
      getPropertySuggestions()
    } else {
      setSuggestions([])
    }
  }, [homeData.address])

  const getPropertySuggestions = async () => {
    // setTimeout(async () => {
    //   const res = await fetchPropertySuggestions(
    //     user?.idToken?.jwtToken,
    //     query,
    //     '10'
    //   )
    //   setSuggestions(res)
    // }, 200)
    const res = await fetchPropertySuggestions(
      user?.idToken?.jwtToken,
      homeData.address,
      '10'
    )
    setSuggestions(res)
  }

  const saveHomeData = (label, value) => {
    setHomeData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveVehicleData = (label, value) => {
    setVehicleData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveValuableData = (label, value) => {
    setValuableData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveWalletData = (label, value) => {
    setWalletData((prevState) => ({ ...prevState, [label]: value }))
  }

  const addPage = (page) => {
    setPages((prevState) => [...prevState, page])
  }

  const onSuccess = async () => {
    setLoading(true)
    setTimeout(async () => {
      await getAllAccountInfo()
      await fetchBudgetingInfo()
      setSuccessMsg(t('successfully_linked_account'))
      setTimeout(() => {
        setSuccessMsg('')
      }, 10000)
      setLoading(false)
      setSuccessMsg('')
    }, 10000)
  }

  const onError = async () => {
    setErrorMsg(t('somethingwentwrong'))
    setTimeout(() => {
      setErrorMsg('')
    }, 10000)
  }

  const Home = () => {
    return (
      <div className='add-account-forms'>
        {successMsg && <p className='success'>{successMsg}</p>}
        {errorMsg && <p className='error'>{errorMsg}</p>}
        <LinkPlaidCustom
          user={user}
          getAllAccountInfo={getAllAccountInfo}
          t={t}
          isBank={true}
          reLink={false}
          onSuccess={onSuccess}
          onError={onError}
        >
          <div className='shadow-card add-account-card'>
            <div className='budgeting-row'>
              <p className='budgeting-value big-text no-margin'>
                {t('link_bank_account')}
              </p>
              <img src={PlusIcon} width={35} height={35} />
            </div>
          </div>
        </LinkPlaidCustom>
        <LinkPlaidCustom
          user={user}
          getAllAccountInfo={getAllAccountInfo}
          t={t}
          isBank={false}
          reLink={false}
          onSuccess={onSuccess}
          onError={onError}
        >
          <div className='shadow-card add-account-card'>
            <div className='budgeting-row'>
              <p className='budgeting-value big-text no-margin'>
                {t('link_brokerage_account')}
              </p>
              <img src={PlusIcon} width={35} height={35} />
            </div>
          </div>
        </LinkPlaidCustom>
        <div
          className='shadow-card add-account-card'
          onClick={() => {
            addPage('asset')
          }}
        >
          <div className='budgeting-row'>
            <p className='budgeting-value big-text no-margin'>
              {t('link_asset_or_property')}
            </p>
            <img src={PlusIcon} width={35} height={35} />
          </div>
        </div>
        <div
          className='shadow-card add-account-card'
          onClick={() => {
            addPage('wallet')
          }}
        >
          <div className='budgeting-row'>
            <p className='budgeting-value big-text no-margin'>
              {t('link_digital_wallet')}
            </p>
            <img src={PlusIcon} width={35} height={35} />
          </div>
        </div>
      </div>
    )
  }

  const AssetForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('add_an_asset')}</p>
        <div
          className='shadow-card add-account-card'
          onClick={() => {
            addPage('property')
          }}
        >
          <div className='budgeting-row'>
            <p className='budgeting-value big-text no-margin'>
              {t('add_a_property')}
            </p>
            <img src={PlusIcon} width={35} height={35} />
          </div>
        </div>
        <div
          className='shadow-card add-account-card'
          onClick={() => {
            addPage('vehicle')
          }}
        >
          <div className='budgeting-row'>
            <p className='budgeting-value big-text no-margin'>
              {t('add_a_vehicle')}
            </p>
            <img src={PlusIcon} width={35} height={35} />
          </div>
        </div>
        <div
          className='shadow-card add-account-card'
          onClick={() => {
            addPage('valuable')
          }}
        >
          <div className='budgeting-row'>
            <p className='budgeting-value big-text no-margin'>
              {t('add_other_valuable')}
            </p>
            <img src={PlusIcon} width={35} height={35} />
          </div>
        </div>
      </div>
    )
  }

  const propertyForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>
          {t('find_your_homes_estimated_value')}
        </p>
        <div className='property-input-container'>
          <p className='input-label small-height'>{t('enter_full_address')}</p>
          <InputText
            value={homeData.address}
            onChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='address'
            placeholder={t('enter_full_address')}
            className='add-account-input-fixed big-height'
          />
          {suggestions?.length > 0 && suggestionsList()}
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('enter_home_estimated_value')}</p>
          <InputNumber
            value={homeData?.estimated}
            onValueChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='estimated'
            placeholder={t('enter_home_estimate')}
            className='add-account-input-fixed'
            format={true}
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('enter_your_mortgage_balance')}</p>
          <InputNumber
            value={homeData?.loanBalance}
            onValueChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='loanBalance'
            placeholder={t('enter_mortgage_balance')}
            className='add-account-input-fixed'
            format={true}
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('give_your_property_a_nickname')}</p>
          <InputText
            value={homeData.nickname}
            onChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_nickname')}
            className='add-account-input-fixed'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateHomeDetails()
          }}
        >
          {t('add_property')}
        </button>
      </div>
    )
  }

  const suggestionsList = () => {
    return (
      <div className='suggestions'>
        <p
          onClick={() => {
            setSuggestions([])
          }}
          className='suggestions-close'
        >
          x
        </p>
        {suggestions.map((item, index) => {
          return (
            <div
              className='suggestions-row'
              key={index}
              onClick={() => {
                saveHomeData('address', item)
                getPropertyEstimate()
                setSuggestions([])
              }}
            >
              <p className='suggestion-item'>{item}</p>
            </div>
          )
        })}
      </div>
    )
  }

  const getPropertyEstimate = async () => {
    const res = await fetchPropertyData(
      user?.idToken?.jwtToken,
      homeData?.address
    )
    console.log(res)
    saveHomeData('estimated', res?.property_forecast?.appraisal)
  }

  const vehicleForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_your_vehicle_details')}</p>
        <div className='input-container'>
          <p className='input-label'>{t('vehicle_model')}</p>
          <InputText
            value={vehicleData?.model}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='model'
            placeholder={t('enter_vehicle_model')}
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('vehicle_make')}</p>
          <InputText
            value={vehicleData?.make}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='make'
            placeholder={t('enter_vehicle_make')}
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('vehicle_year')}</p>
          <InputNumber
            value={vehicleData?.year}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='year'
            placeholder={t('enter_vehicle_year')}
            className='add-account-input'
            format={false}
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('estimated_value')}</p>
          <InputNumber
            value={vehicleData?.estimated}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='estimated'
            placeholder={t('enter_vehicle_estimate')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('loan_balance')}</p>
          <InputNumber
            value={vehicleData?.loanBalance}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='loanBalance'
            placeholder={t('enter_vehicle_loan')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('vehicle_nickname')}</p>
          <InputText
            value={vehicleData?.nickname}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_vehicle_nickname')}
            className='add-account-input'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateVehicleDetails()
          }}
        >
          {t('add_vehicle')}
        </button>
      </div>
    )
  }

  const valuableForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_your_valuable_details')}</p>
        <div className='input-container'>
          <p className='input-label'>{t('name')}</p>
          <InputText
            value={valuableData?.nickname}
            onChange={(e) => saveValuableData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('ex_art_jewelery')}
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('estimated_value')}</p>
          <InputNumber
            value={valuableData?.estimated}
            onValueChange={(e) =>
              saveValuableData(e.target.name, e.target.value)
            }
            name='estimated'
            placeholder={t('')}
            prefix='$'
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <p className='input-label'>{t('loan_balance')}</p>
          <InputNumber
            value={valuableData?.loanBalance}
            onValueChange={(e) =>
              saveValuableData(e.target.name, e.target.value)
            }
            name='loanBalance'
            placeholder={t('')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateValuableDetails()
          }}
        >
          {t('add_valuable')}
        </button>
      </div>
    )
  }

  const walletForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_wallet_address')}</p>
        <div className='input-container'>
          <InputText
            value={walletData?.walletAddress}
            onChange={(e) => saveWalletData(e.target.name, e.target.value)}
            name='walletAddress'
            placeholder={t('enter_wallet_address')}
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <InputText
            value={walletData?.nickname}
            onChange={(e) => saveWalletData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_nickname')}
            className='add-account-input'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateWalletForm()
          }}
        >
          {t('add_wallet')}
        </button>
      </div>
    )
  }

  const validateHomeDetails = () => {
    if (homeData?.address) {
      if (homeData?.estimated) {
        if (homeData?.nickname) {
          submitApi()
        } else {
          setError(t('pleasecompleteallfields'))
        }
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateVehicleDetails = () => {
    if (vehicleData?.model) {
      if (vehicleData?.make) {
        if (vehicleData?.year) {
          if (vehicleData?.estimated) {
            if (vehicleData?.nickname) {
              submitApi()
            } else {
              setError(t('pleasecompleteallfields'))
            }
          } else {
            setError(t('pleasecompleteallfields'))
          }
        } else {
          setError(t('pleasecompleteallfields'))
        }
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateValuableDetails = () => {
    if (valuableData?.nickname) {
      if (valuableData?.estimated) {
        submitApi()
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateWalletForm = () => {
    if (walletData?.walletAddress && walletData?.nickname) {
      linkWalletAddressApi()
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const linkWalletAddressApi = async () => {
    setLoading(true)
    const res = await linkWalletAddress(
      user?.idToken?.jwtToken,
      walletData?.walletAddress,
      walletData?.nickname
    )
    await fetchBudgetingInfo()
    console.log(res)
    if (res) {
      setSuccess(t('successfully_added_wallet'))
    } else {
      setError(t('somethingwentwrong'))
    }

    setLoading(false)
  }

  const renderPage = () => {
    const currentPage = pages?.[pages?.length - 1]

    switch (currentPage) {
      case 'home':
        return <Home />
      case 'asset':
        return <AssetForm />
      case 'property':
        return propertyForm()
      case 'vehicle':
        return vehicleForm()
      case 'valuable':
        return valuableForm()
      case 'wallet':
        return walletForm()
      default:
        return null
    }
  }

  /*
  nickname,
  mortgage_balance,
  mortgage_account ??,
  address ??,
  estimated_value,
  type
  */

  const submitApi = async () => {
    setLoading(true)
    const currentPage = pages?.[pages?.length - 1]
    const data =
      currentPage === 'property'
        ? homeData
        : currentPage === 'vehicle'
        ? vehicleData
        : valuableData
    const address =
      currentPage === 'vehicle'
        ? `${data.make},${data.model},${data.year}`
        : currentPage === 'property'
        ? homeData?.address
        : null
    const res = await addAsset(
      user?.idToken?.jwtToken,
      data.nickname,
      data.loanBalance,
      null,
      address,
      data.estimated,
      data.type
    )
    if (res) {
      const budgetingData = await getBudgetingData(user?.idToken?.jwtToken)
      refreshBudgetingInfo(budgetingData)
      setSuccess(t('successfully_added_account'))
    } else {
      setError(t('somethingwentwrong'))
    }
    setLoading(false)
  }

  if (success) {
    return <p className='success'>{success}</p>
  } else if (loading) {
    return <Loader logoLoader={false} />
  } else {
    return (
      <div>
        {pages.length > 1 && (
          <img
            src={BackIcon}
            className='back-icon'
            onClick={() => {
              setPages((prevState) => {
                const pages = [...prevState]
                pages.pop()
                return pages
              })
            }}
          />
        )}
        {renderPage()}
        <p className='error space-top' style={{ marginTop: 15 }}>
          {error}
        </p>
      </div>
    )
  }
}
