//REACT
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//PACKAGES
import { Button } from 'primereact/button'
import VerificationInput from 'react-verification-input'
import Amplify, { Auth } from 'aws-amplify'
import 'react-phone-input-2/lib/style.css'

//COMPONENTS
import { RadioButton } from 'primereact/radiobutton'
import '../../scss/pages/CodeVerification.scss'
import Loader from '../components/accountInfo/Loader'
import { applyPromoCode } from '../../services/userServices'

export default function CodeVerification(props) {
  const { t, email, password, phone, defaultPage } = props
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general
  const [isEmail, setIsEmail] = useState(true)
  const [authCode, setAuthCode] = useState('')

  //UTILS
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(defaultPage ? defaultPage : 'options') //options -> verify

  useEffect(() => {
    resetMessages()
  }, [page])

  useEffect(() => {
    if (authCode.length === 6) {
      resetMessages()
      confirmSignUp()
    }
  }, [authCode])

  const resetMessages = () => {
    setErrorMsg('')
    setSuccessMsg('')
  }

  const showOptions = () => {
    return (
      <div className='options-container'>
        <p className='options-sub-header'>{t('chooseaverificationmethod')}</p>
        <div className='options-row'>
          <div className='options-container'>
            <RadioButton
              value={isEmail}
              onChange={(e) => setIsEmail((prevState) => !prevState)}
              checked={isEmail}
            />
            <p className='option-label'>{t('email')}</p>
          </div>
          <div className='options-container'>
            <RadioButton
              value={isEmail}
              onChange={(e) => setIsEmail((prevState) => !prevState)}
              checked={!isEmail}
            />
            <p className='option-label'>{t('phone')}</p>
          </div>
        </div>
        <div className='options-submit-btn-container'>
          <Button
            label={t('submit')}
            className='options-submit-btn'
            onClick={() => {
              resetMessages()
              if (isEmail) {
                sendVerifCode(true, email)
              } else {
                sendVerifCode(false, email)
              }
            }}
            loading={loading}
          />
        </div>
        <div
          className='have-code-container'
          onClick={() => {
            setPage('verify')
          }}
        >
          {t('alreadyreceivedcode')}
        </div>
      </div>
    )
  }

  const showVerify = () => {
    return loading ? (
      <>
        <Loader isSmallScreen={isSmallScreen} logoLoader={false} />
      </>
    ) : (
      <div className='codeConfirm__container'>
        <p>{t('verificationcodesentmessage')}</p>
        <VerificationInput
          autoFocus
          removeDefaultStyles
          classNames={{
            container: 'verificationInput__container',
            character: 'verificationInputCharacter__container',
            characterInactive:
              'verificationInputCharacter__container__inactive',
            characterSelected:
              'verificationInputCharacter__container__selected',
          }}
          validChars='0-9'
          placeholder=''
          onChange={(e) => setAuthCode(e)}
          value={authCode}
        />
        <p className='resend__button' style={{ cursor: 'pointer' }}>
          {t('didntgetthecode')}
          <span onClick={() => resendCode()} className='resend__button'>
            {t('resendcode')}
          </span>
        </p>
      </div>
    )
  }

  const sendVerifCode = (isEmail, username) => {
    setLoading(true)
    let attributes = {
      email,
    }
    if (!isEmail) attributes.phone_number = `+${phone}`
    Auth.signUp({
      username: email,
      password,
      attributes,
    })
      .then((resp) => {
        console.log('signup response', resp)
        setLoading(false)
        // setVerificationDestination(resp?.codeDeliveryDetails?.Destination)
        setPage('verify')
      })
      .catch((error) => {
        props.goBack(error.message)
        setErrorMsg(error.message)
        setLoading(false)
      })
  }

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then((res) => {
        console.log('resend code response: ' + JSON.stringify(res))
        setSuccessMsg(t('codehasbeensent'))
        setErrorMsg('')
      })
      .catch((error) => {
        console.log('resend code error: ' + JSON.stringify(error))
        setErrorMsg(t('failedtoresendcode'))
        setSuccessMsg('')
      })
  }

  const confirmSignUp = () => {
    console.log('sign up')
    setLoading(true)
    Auth.confirmSignUp(email, authCode)
      .then(() => {
        let loginVar = Auth.signIn(email, password)
          .catch((error) => {
            switch (error.message) {
              case 'User does not exist.':
                error = t('usernamenotfound')
                break
              case 'Incorrect username or password.':
                error = t('incorrectpassword')
              // props.incorrectPassword()
              case 'User is not confirmed.':
                error = t('userisnotconfirmed')
                break
              default:
                console.log('Not Caught Error: ' + error.message)
                error = error.message
                break
            }
            setErrorMsg(error)
            setLoading(false)
            return
          })
          .then((res) => {
            if (res) {
              setSuccessMsg(props.t('signupsuccessfulyouwillberedirectshortly'))
              Auth.currentSession()
                .then(async (resp) => {
                  let user = resp
                  if (user) {
                    if (props.promoCode) {
                      const result = await applyPromoCode(
                        user?.idToken?.jwtToken,
                        props.promoCode
                      )
                      if (result?.status) {
                        props.setUser(user, '/promo')
                      } else {
                        props.setUser(user)
                      }
                    } else {
                      props.setUser(user)
                    }
                  }
                })
                .catch((err) => {
                  console.log('login', err)
                  setLoading(false)
                })
              // .then(() => {
              //   window.location.replace('/Dashboard')
              // })
            }
          })
        setLoading(false)
      })
      .catch((err) => {
        console.log('login err', err)
        setLoading(false)
        setErrorMsg(props.t('invalidcode'))
      })
  }

  return (
    <div>
      {page === 'options' && showOptions()}
      {page === 'verify' && showVerify()}
      {errorMsg && <p className='error'>{errorMsg}</p>}
      {successMsg && <p className='success'>{successMsg}</p>}
    </div>
  )
}
