import React, { Component } from "react";

class FeatureHiddenText extends Component {
  state = {
    isChinese: this.props.isChinese
  };

  render() {
    return (
      <div>
        {this.state.isChinese ? (
          <p className="diversified-text-hide">
            现金管理已嵌入其中，我们通过将现金投入固定收益类资产来赚取利息回报，客户可以在任何时候从中提现。相比于其他投资选择，比如个股或者共同基金，ETF的优势在于有更大的流动性，风险分散化，税负优势以及更低的费用。AllocateRite的策略都仅限于配置多头仓位。浏览我们上月的投资组合或者现在就加入我们。
          </p>
        ) : (
          <p className="diversified-text-hide">
            Cash management is built in. Compared to alternatives like
            individual stocks or mutual funds, ETFs enjoy greater liquidity,
            diversification, tax advantage, and lower fees. AllocateRite’s cash
            allocation provides dual benefits- interest income and effective
            portfolio hedges. AllocateRite’s products are long only. Explore
            prior months’ portfolios or join now for the latest.
          </p>
        )}
      </div>
    );
  }
}

export default FeatureHiddenText;
