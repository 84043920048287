import React from 'react'
import { FaSearch, FaShareSquare, FaCheckCircle } from 'react-icons/fa'

import Spinner from 'react-bootstrap/Spinner'

import ENDPOINT from '../Endpoint'
import { PlaidLink } from 'react-plaid-link'
import SearchEndpoint from '../SearchEndpoint'

const brandColor = 'rgb(70,182,179)'

export default class RecurringTradesModal extends React.Component {
  state = {
    step: 0,
    rts: [],
    searchMomentumText: '',
    momDataSource: [],
    errors: '',
    selected: false,
    dollarVal: 0,
    sharesVal: 0,
    theme: 'most_active',
    sureCheck: false,
    editMode: false,
    recurringMonthly: true,
    recurringAmount: null,
    recurringBuyStep: 0,
    checkingAccount: null,
  }

  componentDidMount() {
    this.getRecStats()
  }

  deleteRec = (sym) => {
    let body = { dw_account_id: this.props.acc.accountId, stock: sym }
    fetch(`${ENDPOINT}/mobile/recurring/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        console.log('delete rec resp:', responseJson)
        if (responseJson.Status === 'Success') {
          let rts = this.state.rts.filter((r) => r.ticker !== sym)
          this.setState({ rts })
        }
      })
      .catch((err) => {
        // console.log('delete Rec errors: ', err)
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
      })
  }

  getRecStats = () => {
    fetch(
      `${ENDPOINT}/mobile/recurring/stats?account_id=${this.props.acc.accountId}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('get rts resp:', responseJson)
        this.setState({ rts: responseJson.recurring })
      })
  }

  setRecBuy = () => {
    let body = {
      dw_account_id: this.props.acc.accountId,
      amount: this.state.dollarVal,
      stock: this.state.selected.symbol,
    }

    fetch(`${ENDPOINT}/mobile/recurring/buy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        console.log('rec buy resp:', responseJson)
        if (responseJson.status === 'SUCCESS') {
          this.setState({ step: 3 })
        } else {
          this.setState({ errors: responseJson.status })
        }
      })
      .catch((err) => {
        console.log('trade submission errors: ', err)
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
      })
  }

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state
    this.setState({ isMomDataLoading: true })
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    let url = `${SearchEndpoint}/ticker?search=${searchMomentumText}`
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        this.setState({
          isMomDataLoading: false,
          momDataSource: data,
        })
      })
      .catch((err) => {
        this.setState({ isMomDataLoading: false })
        console.log(err)
      })
  }

  getHeight = () => {
    let totalHeight = window.innerHeight
    return totalHeight * 0.5
  }

  displayErrors = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 25,
          }}
        >
          <div
            style={{
              padding: 20,
              backgroundColor: 'rgb(238,139,132)',
              borderRadius: 15,
            }}
          >
            <p style={{ margin: 0 }}>{this.state.errors}</p>
          </div>
        </div>
      )
    }
  }
  sureCheck = () => {
    let title = this.state.sureCheck
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          zIndex: 99999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 10,
            width: this.props.isSmallScreen ? '90%' : '40%',
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(37,42,55)',
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
              }}
            >
              Delete {title}
            </p>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ sureCheck: false })}
            >
              {' '}
              X{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 30,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <p style={{ fontSize: '22px' }}>
              Are you sure you want to delete the recurring investment into:{' '}
              {title}?
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgb(221,103,108',
                  borderRadius: 12,
                  padding: 20,
                  marginTop: 10,
                  marginBottom: 20,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.deleteRec(title)
                  this.getRecStats()
                  this.setState({ sureCheck: false })
                }}
              >
                <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>
                  Delete Recurring Investment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleSetupRecurringBuy = () => {
    this.setState({ recurringLoading: true })
    if (this.state.recurringMonthly) {
      let num = parseFloat(this.state.recurringAmount).toFixed(2)
      let body = {
        dw_account_id: this.props.acc.accountId,
        plaid_account_id: this.state.checkingAccount,
        periodic: num,
        stock: this.state.stock.ticker,
        roundup: false,
      }
      console.log('SENDING')
      fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res)
        this.setState({ recurringBuyStep: 1, recurringLoading: false })
      })
    } else {
      let body = {
        dw_account_id: this.props.acc.accountId,
        plaid_account_id: this.state.checkingAccount,
        // "periodic": 0.0,
        stock: this.state.stock.ticker,
        roundup: true,
      }
      console.log('SENDING')
      fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res.json())
        // if(res.json().Status === 'false') {
        //     console.log('Error')
        // } else {
        this.setState({ recurringBuyStep: 1, recurringLoading: false })
        // }
      })
    }
  }
  renderRecurringBuy = () => {
    switch (this.state.recurringBuyStep) {
      case 0:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '5%',
                paddingRight: '5%',
                paddingTop: '2%',
                paddingBottom: '2%',
                width: '100%',
              }}
            >
              <p style={{ fontSize: 24 }}>Recurring Buy</p>
              <p>Select Method</p>
              <div style={{ width: '100%', margin: 'auto', marginBottom: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <input
                    type='radio'
                    checked={this.state.recurringMonthly === true}
                    onChange={() => {
                      this.setState({ recurringMonthly: true })
                    }}
                  />
                  <p style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                    Monthly
                  </p>
                  <input
                    type='number'
                    onChange={(e) => {
                      if (this.numberOnlyInput(e.target.value)) {
                        this.setState({ recurringAmount: e.target.value })
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <input
                    type='radio'
                    checked={this.state.recurringMonthly === false}
                    onChange={() => {
                      this.setState({ recurringMonthly: false })
                    }}
                  />
                  <p style={{ margin: 0, marginLeft: 10 }}>Round Up</p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {this.props.everyAccount.filter((account) => {
                  if (account.type === 'depository') return account
                }).length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <p>
                      Please select the checking account you would like to use
                    </p>
                    {this.createDropdown()}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <p>
                      You do not have any checking accounts linked to ForeSCITE.
                      Please click the link below to link a checking account
                      with Plaid
                    </p>
                    <PlaidLink
                      id='LinkBankAccount_Button'
                      clientName='ForeSCITE'
                      // env="sandbox"
                      env='production'
                      product={['transactions']}
                      publicKey='3e65a03171afbab180fa7198f87cef'
                      onSuccess={this.plaidHandleOnSuccess}
                      linkCustomizationName={'bank'}
                      // className="test"
                      style={{
                        marginRight: '5%',
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                    >
                      <div
                        style={{
                          padding: 20,
                          border: '1px solid black',
                          borderRadius: 10,
                        }}
                      >
                        <p style={{ textDecoration: 'underline' }}>
                          Link a Checking Account
                        </p>
                      </div>
                    </PlaidLink>
                  </div>
                )}
              </div>
              {this.state.recurringLoading ? (
                <div>
                  <p>Loading</p>
                </div>
              ) : // ((this.state.recurringMonthly && this.state.recurringAmount) && (this.state.checkingAccount !== null || this.state.checkingAccount !== 'Please Select an Account')) || (!this.state.recurringMonthly && (this.state.checkingAccount !== null || this.state.checkingAccount !== 'Please Select an Account')) && (this.state.checkingAccount !== null || this.state.checkingAccount !== 'Please Select an Account')  ?
              this.state.recurringMonthly ? (
                this.state.recurringAmount !== null &&
                this.state.recurringAmount !== '' &&
                this.state.checkingAccount !== null &&
                this.state.checkingAccount !== 'Please Select an Account' ? (
                  <div
                    style={{
                      width: '40%',
                      margin: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: 'solid',
                      borderWidth: '0.5px',
                      borderColor: 'rgb(30,30,30)',
                      paddingTop: 7,
                      paddingBottom: 7,
                      borderRadius: 8,
                      cursor: 'pointer',
                    }}
                    onClick={() => this.handleSetupRecurringBuy()}
                  >
                    <p>Continue</p>
                  </div>
                ) : (
                  <div>
                    <p>Please Select all options</p>
                  </div>
                )
              ) : this.state.checkingAccount !== null &&
                this.state.checkingAccount !== 'Please Select an Account' ? (
                <div
                  style={{
                    width: '40%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'solid',
                    borderWidth: '0.5px',
                    borderColor: 'rgb(30,30,30)',
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handleSetupRecurringBuy()}
                >
                  <p>Continue</p>
                </div>
              ) : (
                <div>
                  <p>Please Select all options</p>
                </div>
              )}
            </div>
          </>
        )
      case 1:
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FaCheckCircle color={brandColor} size={50} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>
                Success! You have set up a monthly recurring investment for{' '}
                {this.state.stock.ticker}
              </p>
            </div>
            <div
              id='TradeModal_BackButton'
              onClick={() => this.props.closeModal()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Close</p>
            </div>
          </div>
        )
    }
  }

  createDropdown = () => {
    let accounts = this.props.everyAccount.filter((account) => {
      if (account.type === 'depository') return account
    })
    accounts.unshift({ name: 'Please Select an Account' })
    return (
      <select
        onChange={(e) => {
          console.log(e.target.value)
          this.setState({ checkingAccount: e.target.value })
        }}
        style={{
          border: 'none',
          textAlign: 'left',
          margin: 0,
          color: 'rgb(52,131,129)',
          outline: 'none',
          background: 'transparent',
          width: '100%',
        }}
      >
        {accounts.map((account, index) => (
          <option key={index} value={account.accountId}>
            {account.name}
          </option>
        ))}
      </select>
    )
  }

  existingRts = () => {
    let mapped = []
    if (this.state.rts.length > 0) {
      mapped = this.state.rts.map((rt) => this.row(rt))
    }
    if (this.state.editMode === true) {
      return this.renderRecurringBuy()
    } else {
      return (
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <div style={{ overflowY: 'scroll' }}>
            {mapped.length === 0 ? null : (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ width: '18%', margin: 0, textAlign: 'center' }}>
                    Symbol
                  </p>
                  <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
                    Amount
                  </p>
                  <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
                    Next
                  </p>
                  <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
                    Edit
                  </p>
                  <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
                    Delete
                  </p>
                  <div style={{ width: '2%' }}></div>
                </div>
                <hr></hr>
              </div>
            )}
            {mapped}
          </div>
          <div>
            {/* <div onClick={ ()=>this.setState({step:1 ,selected:false, quantity:'0',price:'0.00',limitPrice:'',pcnt:'',})} style={{width:'60%', margin:'auto' ,display:'flex', justifyContent:'center', alignItems:'center', border:'solid', borderWidth:'0.5px' ,borderColor:'rgb(30,30,30)', paddingTop: 7, paddingBottom:7, borderRadius: 8, cursor:'pointer'}}>
                            <p style={{margin:0, color:'rgb(30,30,30)', }}>
                            Create a New Reccuring Investment
                            </p>
                        </div> */}
          </div>
        </div>
      )
    }
  }
  selectStock = () => {
    let data = []
    if (this.props.presetTickers.most_active) {
      // console.log('props.preset',this.props.presetTickers)
      data = this.props.presetTickers[this.state.theme]
    }
    // console.log('data', data)
    let mapped = data.map((ele) => this.createBoxSearch(ele))
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgb(255,255,255)',
          padding: 20,
          borderRadius: 12,
          marginBottom: 25,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgb(255,255,255)',
              width: '75%',
              marginBottom: 20,
              borderRadius: 20,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-start',
              boxShadow: this.state.hover
                ? '0 0 10px rgb(223,225,229)'
                : 'none',
              borderColor: 'rgb(237,238,240)',
              borderStyle: 'solid',
              borderWidth: 'thin',
              minWidth: 250,
            }}
            onMouseEnter={() => this.setState({ hover: 'input' })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <div
              style={{
                minWidth: 20,
                width: '5%',
                /*backgroundColor: '#00A99D',*/ display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FaSearch size={'50%'} style={{ color: '#dddddd' }} />
            </div>
            <input
              ref={this.input}
              style={{
                width: '90%',
                border: 'none',
                outline: 'none',
                paddingTop: 5,
                paddingBottom: 5,
              }}
              placeholder='Enter stock symbol'
              type='text'
              value={this.state.searchMomentumText}
              onChange={(event) => {
                let searchMomentumText = event.target.value.toUpperCase()
                this.setState(
                  { searchMomentumText, selectedPreset: null, errors: false },
                  () => {
                    const { isSearching } = this.state
                    if (isSearching) {
                      return
                    } else {
                      this.setState({ isSearching: true })
                      setTimeout(() => {
                        this.setState(
                          { isSearching: false },
                          this.onSubmitSearchMomentum
                        )
                      }, 2000)
                    }
                  }
                )
              }}
            />
          </div>
        </div>
        <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'most_active'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'most_active' })
              }
            >
              <p style={{ margin: 0 }}>Top 30</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Energy'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Energy' })
              }
            >
              <p style={{ margin: 0 }}>Energy</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Communications'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({
                  searchMomentumText: '',
                  theme: 'Communications',
                })
              }
            >
              <p style={{ margin: 0 }}>Comms</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'ETFs'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'ETFs' })
              }
            >
              <p style={{ margin: 0 }}>ETFs</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Finance'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Finance' })
              }
            >
              <p style={{ margin: 0 }}>Finance</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'SaaS'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'SaaS' })
              }
            >
              <p style={{ margin: 0 }}>Saas</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Cannabis'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Cannabis' })
              }
            >
              <p style={{ margin: 0 }}>Cannabis</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'EV'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'EV' })
              }
            >
              <p style={{ margin: 0 }}>EV</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Recovery'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Recovery' })
              }
            >
              <p style={{ margin: 0 }}>Recovery</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Semis'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Semis' })
              }
            >
              <p style={{ margin: 0 }}>Semis</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Healthcare'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Healthcare' })
              }
            >
              <p style={{ margin: 0 }}>Healthcare</p>
            </div>
          </div>
        </div>
        <div
          style={{
            maxHeight: 350,
            overflowY: 'scroll',
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
            minHeight: this.getHeight(),
          }}
        >
          {this.state.searchMomentumText.length > 0 ? (
            this.state.isSearching ? (
              <div style={{ width: '100%', marginTop: 75 }}>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: 22,
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  Loading...
                </p>
              </div>
            ) : (
              <div
                style={{
                  width: '95%',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  marginLeft: 15,
                }}
              >
                {this.state.momDataSource.map((ele) =>
                  this.createBoxSearch(ele)
                )}
              </div>
            )
          ) : (
            <div
              style={{
                width: '95%',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginLeft: 15,
              }}
            >
              {mapped.length === 0 ? (
                <Spinner
                  animation='border'
                  id='spinner'
                  variant='dark'
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                mapped
              )}
            </div>
          )}
          {this.displayErrors()}
        </div>
      </div>
    )
  }
  status = () => {
    this.getRecStats()
    return (
      <div style={{ padding: 40 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FaCheckCircle color={brandColor} size={70} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>
            Success! You have set up a recurring buy for{' '}
            {this.state.selected.symbol}
          </p>
        </div>
        <div
          onClick={() =>
            this.setState({
              step: 0,
              selected: false,
              quantity: '',
              price: '',
              limitPrice: '',
              pcnt: '',
            })
          }
          style={{
            width: '60%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'solid',
            borderWidth: '0.5px',
            borderColor: 'rgb(30,30,30)',
            paddingTop: 7,
            paddingBottom: 7,
            borderRadius: 8,
            cursor: 'pointer',
          }}
        >
          <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>
            See All Recurring Investments
          </p>
        </div>
      </div>
    )
  }

  swapDate(dt) {
    let months = [
      null,
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    let arr = dt.split('-')
    if (arr.length === 3) {
      return months[arr[1] * 1] + ' ' + arr[2] * 1 + ', ' + arr[0]
    } else {
      return 'none prior'
    }
  }

  row = (rt) => {
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {/* <div style={{width:'8%'}}>
                        {this.createBoxRow()}
                    </div> */}
          <div style={{ width: '2%' }}></div>
          <a
            style={{ width: '16%' }}
            /*onClick={()=>this.props.tradeStock()} href='#'*/ href={`/Dashboard/Trade/${rt.ticker}`}
          >
            <p
              style={{
                width: '100%',
                margin: 0,
                textAlign: 'left',
                color: brandColor,
                marginLeft: 7,
              }}
            >
              {rt.ticker}
            </p>
          </a>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            {rt.amount.toFixed(2) == '0.00'
              ? 'Round Up'
              : '$' + this.addCommas(rt.amount.toFixed(2))}
          </p>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            {this.swapDate(rt.next_purchase_date)}
          </p>
          <p
            onClick={() => this.setState({ editMode: true, stock: rt })}
            style={{
              width: '20%',
              margin: 0,
              textAlign: 'right',
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
          >
            Edit
          </p>
          <div
            style={{
              width: '20%',
              margin: 0,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                borderRadius: 20,
                backgroundColor: 'rgb(238, 94, 104)',
                width: 40,
                cursor: 'pointer',
              }}
              /*onClick={()=>}*/ onClick={() =>
                this.setState({ sureCheck: rt.ticker })
              }
            >
              <p
                style={{
                  margin: 0,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                X
              </p>
            </div>
          </div>
          <div style={{ width: '2%' }}></div>
        </div>
        <hr></hr>
      </div>
    )
  }

  numberOnlyInput(val) {
    let goodNum = true
    let arr = val.split('')
    let acceptable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    let i = 0
    let dots = 0
    while (i < arr.length) {
      if (arr[i] === '.' && dots === 0) {
        dots = 1
        i++
      } else if (arr[i] === '.' && dots === 1) {
        goodNum = false
        i += arr.length
      } else if (acceptable.includes(arr[i])) {
        i++
      } else {
        goodNum = false
        i += arr.length
      }
    }
    return goodNum
  }

  getLivePrice = (sym) => {
    let url = `${ENDPOINT}/mobile/risk-monkey/ticker/`
    if (this.state.selected) {
      url = url + `${this.state.selected.symbol.toUpperCase()}/live`
    } else {
      url = url + `${sym.toUpperCase()}/live`
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('getLivePrice:', responseJson)
        this.setState({
          selected: { ...this.state.selected, live: responseJson },
        })
        // this.startPriceTimer()
      })
  }

  createBoxSearch = (ele) => {
    // console.log('inside create box', ele)
    return (
      <div
        onClick={() => {
          if (ele.isDwSupported) {
            this.getLivePrice(ele.symbol)
            this.owned(ele.symbol)
            this.setState({
              searchMomentumText: '',
              step: 2,
              selected: { ...this.state.selected, ...ele },
            })
          } else {
            this.setState({
              errors: `DriveWealth does not currently support ${ele.symbol}`,
            })
          }
        }}
        onMouseEnter={() => this.setState({ hover: ele })}
        onMouseLeave={() => this.setState({ hover: '' })}
        style={{ width: 100, height: 175, marginBottom: 10, marginRight: 15 }}
        // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
      >
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              this.state.hover === ele
                ? 'rgb(225,225,225)'
                : 'rgb(255,255,255)',
            borderRadius: 7,
            overflow: 'hidden',
            width: 100,
            height: 100,
            justifySelf: 'flex-end',
            border: 'solid',
            borderWidth: '0.25px',
          }}
        >
          {ele.imageURL === 'not-found' ? (
            <div
              style={{
                objectFit: 'contain',
                width: 50,
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(17,17,17)',
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                }}
              >
                {ele.symbol[0]}
              </p>
            </div>
          ) : (
            <img
              src={ele.imageURL}
              style={{
                objectFit: 'contain',
                width: 80,
                height: 80,
                padding: 10,
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
          }}
        >
          <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold' }}>
            {ele.symbol}
          </p>
          {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
        </div>
      </div>
    )
  }

  createBoxRow = (rt) => {
    if (rt.imageURL === 'not-found') {
      return (
        <div
          style={{
            objectFit: 'contain',
            width: 50,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(17,17,17)',
            borderRadius: 10,
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 'bold',
              color: 'rgb(255,255,255)',
            }}
          >
            {rt.symbol[0]}
          </p>
        </div>
      )
    } else {
      return (
        <img
          // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
          src={rt.imageURL}
          style={{ objectFit: 'contain', width: 70, height: 70, marginLeft: 5 }}
        />
      )
    }
  }

  addCommas(x) {
    if (x === '') {
      return ''
    } else {
      let arr = x.toString().split('.')
      if (arr[1]) {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + arr[1]
      } else {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    }
  }

  owned = (symbol) => {
    let total = 0
    this.props.acc.holdings.forEach((stock) => {
      if (stock.symbol === symbol) {
        total = stock.quantity
      }
    })
    this.setState({ sharesOwned: total })
  }

  canTrade = () => {
    let availableFunds = this.props.acc.balances[0].buyingPower
    let cost = this.state.dollarVal
    // console.log('cost',cost)
    // console.log('price',this.state.price)
    let errors = ''
    if (cost <= 0 || this.state.dollarVal === '0.00') {
      errors = 'You can make a trade for less than $0.00'
    } else if (this.state.buySell === 'buy' && cost > availableFunds) {
      errors = 'You do not have enough funds for this trade'
    } else if (this.state.quantity > 10000) {
      errors =
        'Maximum number of shares per order is 10,000. Please send multiple orders if necessary.'
    }

    if (errors.length > 0) {
      this.setState({ errors })
    } else {
      // GO TO SUMMARY
      if (this.props.acc.inst === 'DriveWealth') {
        this.setRecBuy()
      }
    }
  }

  setPurchases = () => {
    // console.log('this.availableFunds()', this.availableFunds())
    // this.startTradeStatusTimer()
    if (this.state.titleText !== 'Make a Trade') {
      this.setState({ titleText: 'Make a Trade' })
    }

    let price =
      this.state.selected.live && this.state.selected.live.price
        ? this.addCommas(this.state.selected.live.price.toFixed(2))
        : this.state.selected.priceUSD
        ? this.addCommas(this.state.selected.priceUSD.toFixed(2))
        : 0
    let so = this.state.sharesOwned.toFixed(8).split('.')
    let sharesOwned = this.addCommas(so[0]) + '.' + so[1]

    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '2%',
            paddingBottom: '2%',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 7,
                overflow: 'hidden',
                width: 100,
                height: 100,
                justifySelf: 'flex-end',
              }}
            >
              <img
                // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
                src={this.state.selected.imageURL}
                style={{ objectFit: 'contain', width: 100, height: 100 }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 28 }}>
              {this.state.selected.symbol}
            </p>
            <p style={{ margin: 0, fontSize: 24 }}>
              {this.state.acc
                ? `${this.state.acc.inst} ${this.state.acc.mask}`
                : null}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.state.selected.company}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Shares Owned:{' '}
              </strong>
              {parseFloat(sharesOwned) === 0 ? 0 : sharesOwned}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.state.selected.industry}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Position Value:{' '}
              </strong>
              $
              {this.state.sharesOwned === 0
                ? 0
                : this.addCommas((this.state.sharesOwned * price).toFixed(2))}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Price:{' '}
              </strong>
              ${price}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Buying Power:{' '}
              </strong>
              ${this.addCommas(this.props.acc.balances[0].buyingPower)}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Medium Term Forecast*:{' '}
              </strong>
              {this.state.selected.needOptimization
                ? `$${this.addCommas(
                    (
                      this.state.selected.returnForecast *
                        this.state.selected.priceUSD +
                      this.state.selected.priceUSD
                    ).toFixed(2)
                  )}`
                : 'HIGH RISK'}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Account Value:{' '}
              </strong>
              ${this.addCommas(this.props.acc.balances[0].current.toFixed(2))}
            </p>
          </div>
          <hr></hr>
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>Price Type:</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 7,
                paddingBottom: 7,
                background: 'rgb(225,238,238)',
                borderRadius: 5 /*width:'40%'*/,
              }}
            >
              <p
                style={{
                  border: 'none',
                  textAlign: 'right',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                  outline: 'none',
                  background: 'transparent',
                }}
              >
                MARKET
              </p>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>
              {this.state.lastChanged === 'quantity' ? '' : 'Approx '} Number of
              Shares:
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 10,
                background: 'rgb(225,238,238)',
                borderRadius: 5,
                width: '40%',
              }}
            >
              <input
                style={{
                  border: 'none',
                  textAlign: 'right',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                  outline: 'none',
                  background: 'transparent',
                }}
                type='text'
                value={this.state.sharesVal}
                onChange={(e) => {
                  this.setBuyNumShares(e)
                }}
              />
            </div>
          </div>
          {/* <div style={{paddingTop:10, display:'flex', alignItems:'center',justifyContent:'space-between', width:'100%', }}>
                    <p style={{margin:0, marginRight:25}}>
                    {this.state.lastChanged !== 'quantity' ? '':'Approx '} {this.state.buySell === 'sell'?'% Position':'% Funds'}: 
                    </p>
                        <input style={{}}type="range" 
                            min="1"
                            max="100" 
                            step={1}
                            value={this.state.pcnt}
                            onChange={(e)=>{
                                if(this.state.buySell === 'sell'){
                                    this.setSellPercent(e)
                                }else{
                                    this.setBuyPercent(e)
                                }
                            }
                        }/>
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'20%'}}>
                            <input 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                type="text" 
                                value={this.state.pcnt} 
                                onChange={(e)=>{
                                    if(this.state.buySell === 'sell'){
                                        this.setSellPercent(e)
                                    }else{
                                        this.setBuyPercent(e)
                                    }
                                }
                            }/>
                            <p style={{margin:0,color:'rgb(52,131,129)'}}>%</p>
                    </div>
                </div> */}
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>
              {this.state.lastChanged !== 'quantity' ? '' : ''}Total Value:
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 10,
                background: 'rgb(225,238,238)',
                borderRadius: 5,
                width: '40%',
              }}
            >
              <input
                style={{
                  border: 'none',
                  textAlign: 'right',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                  outline: 'none',
                  background: 'transparent',
                }}
                type='text'
                value={this.state.dollarVal}
                onChange={(e) => {
                  this.setBuyCost(e)
                }}
              />
              <p style={{ margin: 0, color: 'rgb(52,131,129)' }}>(USD)</p>
            </div>
          </div>

          <div style={{ paddingTop: 10, width: '100%' }}>
            {this.state.errors.length > 0 ? this.displayErrors() : null}
          </div>
          <div
            style={{
              width: '100%',
              paddingTop: 25,
              paddingBottom: 10,
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <div
              onClick={() =>
                this.setState({
                  step: 1,
                  selected: false,
                  quantity: '',
                  price: '',
                  limitPrice: '',
                  pcnt: '',
                })
              }
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Go Back</p>
            </div>
            <div
              onClick={() => this.canTrade()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(52,131,129)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(52,131,129)' }}>Preview</p>
            </div>
          </div>
          <div>
            <p style={{ fontSize: 12 }}>
              *Price forecast is based on ForeSCITE's AI powered forecasting
              model. It is neither advice nor recommendation to Buy or Sell
              stocks.
            </p>
          </div>
        </div>
      </div>
    )
  }

  setBuyCost = (e) => {
    let shareprice = this.state.selected.live.price
    if (e.target.value === '.') {
      this.setState({ dollarVal: '0.', sharesVal: 0, errors: '' })
    } else if (this.numberOnlyInput(e.target.value)) {
      let sharesVal = (e.target.value / shareprice).toFixed(8)
      // if(quantity <= 10000){
      this.setState({ dollarVal: e.target.value, sharesVal, errors: '' })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  setBuyNumShares = (e) => {
    let shareprice = this.state.selected.live.price
    if (e.target.value === '.') {
      this.setState({ dollarVal: 0, sharesVal: '0.', errors: '' })
    } else if (this.numberOnlyInput(e.target.value)) {
      let dollarVal = (e.target.value * shareprice).toFixed(2)
      // if(e.target.value <= 10000){
      this.setState({ dollarVal, sharesVal: e.target.value, errors: '' })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  whatStep = () => {
    switch (this.state.step) {
      case 0:
        return this.existingRts()
      case 1:
        return this.selectStock()
      case 2:
        return this.setPurchases()
      case 3:
        return this.status()
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          zIndex: 99999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 10,
            width: this.props.isSmallScreen ? '90%' : '40%',
            height: '90%',
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(37,42,55)',
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
              }}
            >
              {' '}
              Recurring Investments
            </p>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
                cursor: 'pointer',
              }}
              onClick={() => this.props.closeModal()}
            >
              {' '}
              X{' '}
            </p>
          </div>
          <div style={{ width: '100%' }}>{this.whatStep()}</div>
        </div>
        {this.state.sureCheck ? this.sureCheck() : null}
      </div>
    )
  }
}
