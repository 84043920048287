import React, { useState, useEffect } from 'react'
import '../scss/components/FinraQuestionnaire.scss'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import Axios from 'axios'
import Endpoint from './Endpoint'
import Loader from './components/accountInfo/Loader'

export default function FinraQuestionnaire(props) {
  const [finraForm, setFinraForm] = useState({
    subPackage: 'basic',
    birthdayDay: 0,
    birthdayMonth: 0,
    birthdayYear: 0,
  })
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')

  const handleInputChanges = (name, value) => {
    setFinraForm({
      ...finraForm,
      [name]: value,
    })
  }

  const validateForm = () => {
    if (
      finraForm &&
      finraForm.investmentMainReason &&
      finraForm.investmentPeriod &&
      finraForm.riskTolerance &&
      finraForm.investmentExperience &&
      finraForm.investmentObjectives
    ) {
      submitFinra()
    } else {
      setErrorMsg(props.t('pleasecompleteallfields'))
    }
  }

  const submitFinra = async () => {
    setLoading(true)
    await Axios.post(`${Endpoint}/mobile/dw/quiz`, finraForm, {
      headers: {
        Authorization: 'Bearer ' + props.user.idToken.jwtToken,
      },
    })
      .then(async (res) => {
        if (res?.data?.success) {
          let status = await props.action()
          if (status) {
            setSuccessMsg(props.t('questionnaireupdatedsuccessfully'))
            setErrorMsg('')
            setLoading(false)
            setStatus(true)
          }
        } else {
          setErrorMsg(props.t('somethingwentwrong'))
        }
      })
      .catch((err) => {
        console.log(err)
        setErrorMsg(props.t('somethingwentwrong'))
      })
  }

  const yearOptions = [
    {
      label: `1 ${props.t('year')}`,
      value: '1',
    },
    {
      label: `2 ${props.t('years')}`,
      value: '2',
    },
    {
      label: `3 ${props.t('years')}`,
      value: '3',
    },
    {
      label: `4 ${props.t('years')}`,
      value: '4',
    },
    {
      label: `5 ${props.t('years')}`,
      value: '5',
    },
    {
      label: `6 ${props.t('years')}`,
      value: '6',
    },
    {
      label: `7 ${props.t('years')}`,
      value: '7',
    },
    {
      label: `8 ${props.t('years')}`,
      value: '8',
    },
    {
      label: `9 ${props.t('years')}`,
      value: '9',
    },
    {
      label: `10+ ${props.t('years')}`,
      value: '10',
    },
  ]

  if (props.t) {
    return (
      <div className='finra-container'>
        {loading ? (
          <div className='loading-container'>
            <Loader logoLoader={false} />
          </div>
        ) : (
          <>
            <div className='question-container'>
              <p className='question-header'>
                {props.t('whatisthemainreasonforyourinvestment')}
              </p>
              <div className='question-input-container'>
                <div className='option-container'>
                  <RadioButton
                    value='SHORT_TERM'
                    name='investmentMainReason'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.investmentMainReason === 'SHORT_TERM'}
                  />
                  <p className='option-label'>{props.t('shortterm')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='MEDIUM_TERM'
                    name='investmentMainReason'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentMainReason === 'MEDIUM_TERM'}
                  />
                  <p className='option-label'>{props.t('mediumterm')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='LONG_TERM'
                    name='investmentMainReason'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentMainReason === 'LONG_TERM'}
                  />
                  <p className='option-label'>{props.t('longterm')}</p>
                </div>
              </div>
            </div>
            <div className='question-container'>
              <p className='question-header'>
                {props.t('howdoyouwishtoinvest')}
              </p>
              <div className='question-input-container'>
                <div className='option-container'>
                  <Dropdown
                    value={finraForm?.investmentPeriod}
                    onChange={(e) => {
                      handleInputChanges(e.target.name, e.value)
                    }}
                    name='investmentPeriod'
                    placeholder={props.t('investmentperiod')}
                    options={yearOptions}
                  />
                </div>
              </div>
            </div>
            <div className='question-container'>
              <p className='question-header'>
                {props.t('whatisyourrisktolerance')}
              </p>
              <div className='question-input-container'>
                <div className='option-container'>
                  <RadioButton
                    value='LOW'
                    name='riskTolerance'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.riskTolerance === 'LOW'}
                  />
                  <p className='option-label'>{props.t('minimizelosses')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='MEDIUM'
                    name='riskTolerance'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.riskTolerance === 'MEDIUM'}
                  />
                  <p className='option-label'>
                    {props.t('gainswhilelimitinglosses')}
                  </p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='GAINS'
                    name='riskTolerance'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.riskTolerance === 'GAINS'}
                  />
                  <p className='option-label'>{props.t('maximizegains')}</p>
                </div>
              </div>
            </div>
            <div className='question-container'>
              <p className='question-header'>
                {props.t('whatisyourinvestmentexperience')}
              </p>
              <div className='question-input-container'>
                <div className='option-container'>
                  <RadioButton
                    value='NONE'
                    name='investmentExperience'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.investmentExperience === 'NONE'}
                  />
                  <p className='option-label'>{props.t('noexperience')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='SOME'
                    name='investmentExperience'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentExperience === 'SOME'}
                  />
                  <p className='option-label'>{props.t('someexperience')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='CONSIDERABLE'
                    name='investmentExperience'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentExperience === 'CONSIDERABLE'}
                  />
                  <p className='option-label'>
                    {props.t('considerableexperience')}
                  </p>
                </div>
              </div>
            </div>
            <div className='question-container'>
              <p className='question-header'>
                {props.t('whatisyourinvestmentobjective')}
              </p>
              <div className='question-input-container'>
                <div className='option-container'>
                  <RadioButton
                    value='LONG_TERM'
                    name='investmentObjectives'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.investmentObjectives === 'LONG_TERM'}
                  />
                  <p className='option-label'>{props.t('longtermbuyhold')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='INFREQUENT'
                    name='investmentObjectives'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentObjectives === 'INFREQUENT'}
                  />
                  <p className='option-label'>
                    {props.t('tradinginfrequently')}
                  </p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='FREQUENT'
                    name='investmentObjectives'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm.investmentObjectives === 'FREQUENT'}
                  />
                  <p className='option-label'>{props.t('frequenttrader')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='ACTIVE_DAILY'
                    name='investmentObjectives'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.investmentObjectives === 'ACTIVE_DAILY'}
                  />
                  <p className='option-label'>{props.t('activedailytrader')}</p>
                </div>
                <div className='option-container'>
                  <RadioButton
                    value='NEW'
                    name='investmentObjectives'
                    onChange={(e) => handleInputChanges(e.target.name, e.value)}
                    checked={finraForm?.investmentObjectives === 'NEW'}
                  />
                  <p className='option-label'>{props.t('newtoinvesting')}</p>
                </div>
              </div>
            </div>
            <div className='finra-submit-container'>
              <Button
                label={props.t('submit')}
                onClick={() => {
                  validateForm()
                }}
                className='finra-submit-btn'
                loading={loading}
              />
            </div>
            <p className='error-msg'>{errorMsg}</p>
            <p className='success-msg'>{successMsg}</p>
          </>
        )}
      </div>
    )
  } else return null
}
