import { controllers } from 'chart.js'
import React from 'react'
import GaugeChart from 'react-gauge-chart'

const uncolored = [
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
  '#a3a5a8',
]
// const colored = [
//     "#7bc0c3",
//     "#7bc0c3",
//     "#7bc0c3",
//     "#f2ab25",
//     "#f2ab25",
//     "#f2ab25",
//     "#f2ab25",
//     "#f28425",
//     "#f28425",
//     "#f28425",
//     "#f26425",
//     "#f26425",
//     "#f26425",
//     "#f24c25",
//     "#f24c25",
//     "#f24c25",
//     "#f24c25",
//     "#f22525",
//     "#f22525",
//     "#f22525",
//     ];

const colored = [
  '#7bc0c3',
  '#7bc0c3',
  '#7bc0c3',
  '#7bc0c3',
  '#7bc0c3',
  '#7bc0c3',
  '#F5CD19',
  '#F5CD19',
  '#F5CD19',
  '#F5CD19',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
  '#f22525',
]
export default class RiskForecast extends React.Component {
  textColor(input) {
    let num = ((Math.abs(input) * 2) / 5) * 100
    if (colored[num.toFixed(0)]) {
      return colored[num.toFixed(0)]
    } else {
      return colored[19]
    }
  }

  // riskNum(cvar,maxDrawdownVal){
  //     console.log("riskNum",Math.pow(Math.abs(cvar),0.75) * Math.pow(Math.abs(maxDrawdownVal/100),0.25))
  //     let riskNumber = Math.pow(Math.abs(cvar),0.75) * Math.pow(Math.abs(maxDrawdownVal/100),0.25)
  //     console.log("riskNumber",riskNumber.toFixed(0)*10)
  //     console.log("cvar",cvar,maxDrawdownVal)
  //     if(!cvar || !riskNumber){
  //         return '-'
  //     }
  //     else if (riskNumber > 1){
  //         return '10+'
  //     }
  //     else if (riskNumber < 0.1){
  //         return '1'
  //     }
  //     else{
  //         return (riskNumber*10).toFixed(0)
  //     }
  // }

  setColoredGauges(input) {
    // let pivotPoint = ((Math.abs(input) * 2) / 5) * 100;
    // let answer = [...uncolored];
    // return answer.map((ele, i) => {
    //     if (i <= pivotPoint) {
    //     return colored[i];
    //     } else {
    //     return ele;
    //     }
    // });
    let pivotPoint = Math.ceil(36 * input)
    let final = []
    for (let i = 0; i < 36; i++) {
      if (i < pivotPoint) {
        final.push(colored[i])
      } else {
        final.push(uncolored[i])
      }
    }
    return final
  }
  //   riskText = ()=>{
  //       switch (this.props.cvar){
  //           case:
  //       }
  //   }

  numText = (riskNum, ticker) => {
    let num = this.num(riskNum, ticker)
    if (riskNum < 0.16) {
      return 'Low'
    } else if (riskNum >= 0.16 && riskNum < 0.26) {
      return 'Moderate'
    } else {
      return 'High'
    }
    // else{
    //     return 'Extreme'
    // }
  }
  num(cvar, ticker) {
    if (ticker === 'BIL' || ticker === 'TLT') {
      return '0'
    } else {
      if (Math.abs((cvar * 200) / 10) > 10) {
        return '10+'
      } else if (Math.abs((cvar * 200) / 10) < 1) {
        return '1'
      } else {
        return Math.abs((cvar * 200) / 10).toFixed(0)
      }
    }
  }
  numColor = (riskNum, ticker) => {
    // let num = this.num(cvar,ticker)
    if (riskNum < 0.16) {
      return '#7bc0c3'
    }
    if (riskNum >= 0.16 && riskNum < 0.26) {
      return '#F5CD19'
    } else {
      return '#f22525'
    }
    // else if (num < 8){
    //     return 'rgb(240,121,33)'
    // }
    // else{
    //     return 'rgb(240,33,33)'
    // }
  }

  render() {
    let pcnt
    if (this.props.cvar <= 0.79) {
      pcnt = parseFloat(this.props.cvar.toFixed(2)) * 2
    } else {
      pcnt = 1
    }
    let riskNum = (
      Math.pow(Math.abs(this.props.maxDrawdownVal) / 100, 0.25) *
      Math.pow(Math.abs(this.props.cvar), 0.75)
    ).toFixed(2)

    if (this.props.isSmallScreen) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <GaugeChart
              style={{ backgroundColor: 'hsla(0, 100%, 90%, 0.0)' }}
              id='gauge-chart3'
              nrOfLevels={36}
              colors={this.setColoredGauges(riskNum)}
              percent={riskNum}
              textColor='hsla(0, 100%, 90%, 0.0)'
              // needleColor="hsla(0, 100%, 90%, 0.0)"
              needleColor='#242a38'
              needleBaseColor='#242a38'
              // needleBaseColor="hsla(0, 100%, 90%, 0.0)"
              animate={false}
              cornerRadius={8}
              formatTextValue={(value) => value + '%'}
              hideText={true}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                fontSize: 48,
                margin: 0,
                fontWeight: 'bolder',
                color: this.textColor(riskNum),
              }}
            >
              {(riskNum * 100).toFixed(0)}%
            </p>
            <p
              style={{
                fontWeight: 'bold',
                color: '#a3a5a8',
                fontSize: 36,
                marginTop: -5,
                textAlign: 'center',
                color: this.textColor(riskNum),
              }}
            >
              {this.numText(riskNum, this.props.symbol)} Risk
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
        >
          <div>
            <GaugeChart
              style={{ backgroundColor: 'hsla(0, 100%, 90%, 0.0)' }}
              id='gauge-chart3'
              nrOfLevels={36}
              colors={this.setColoredGauges(riskNum)}
              percent={riskNum}
              textColor='hsla(0, 100%, 90%, 0.0)'
              // needleColor="hsla(0, 100%, 90%, 0.0)"
              needleColor='#242a38'
              needleBaseColor='#242a38'
              // needleBaseColor="hsla(0, 100%, 90%, 0.0)"
              animate={false}
              cornerRadius={8}
              formatTextValue={(value) => value + '%'}
              hideText={true}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: this.props.myStrats ? -25 : 0,
            }}
          >
            {/* <div style={{display:'flex', alignItems:'center'}}>
                            
                            <p style={{fontSize: 28, margin:0, fontWeight:'bold',color:"#a3a5a8", marginRight:8, textAlign: 'center'}}>
                                ForeSCITE Risk: 
                            </p>
                        </div> */}
            <p
              style={{
                fontSize: 40,
                margin: 0,
                color: this.textColor(riskNum),
              }}
            >
              {(riskNum * 100).toFixed(0)}%{/* ${(100* riskNum).toFixed(0)}% */}
            </p>
            <p
              style={{
                color: this.textColor(riskNum),
                fontSize: 36,
                margin: 0,
                textAlign: 'center',
              }}
            >
              {this.numText(riskNum, this.props.symbol)} Risk
            </p>
            <p
              style={{
                alignSelf: 'flex-start',
                fontSize: 18,
                marginTop: 20,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                fontWeight: 'bolder',
                color: 'a3a5a8',
              }}
            >
              {/* ForeSCITE Risk ranges risk from 1-10  */}
              Stock risk forecasted by ForeSCITE
            </p>
            <p
              style={{
                alignSelf: 'flex-start',
                fontSize: 18,
                margin: 0,
                fontWeight: 'bolder',
                color: 'a3a5a8',
              }}
            >
              0% - 15% being low risk and 100% being extreme risk
            </p>
            <p
              style={{
                alignSelf: 'flex-start',
                fontSize: 18,
                margin: 0,
                fontWeight: 'bolder',
                color: 'a3a5a8',
              }}
            >
              16% - 25% being moderate risk
            </p>
            <p
              style={{
                alignSelf: 'flex-start',
                fontSize: 18,
                margin: 0,
                fontWeight: 'bolder',
                color: 'a3a5a8',
              }}
            >
              25% - 100% being high risk
            </p>
          </div>
        </div>
      )
    }
  }
}
