import React from 'react'
// import { Container, Row, Col } from 'react-bootstrap'
// import investmentRisk from '../investmentRisk'
// import GaugeChart from "react-gauge-chart"
import DoubleLine from "./DoubleLine"
import PieChart from "./PieChart"
import RiskGuage from './RiskGuage'
import TickersTable from './TickersTable'
import { FaArrowLeft, /*FaSave*/ } from 'react-icons/fa'
import SubModal from "../SubModal"
import ENDPOINT from '../Endpoint'

const uncolored = [
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
];
const colored = [
    "rgb(41,253,47)",
    "rgb(51,253,47)",
    "rgb(69,253,47)",
    "rgb(69,253,47)",
    "rgb(90,253,47)",
    "rgb(90,253,47)",
    "rgb(254,187,44)",
    "rgb(254,187,44)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,160,40)",
    "rgb(253,134,36)",
    "rgb(253,134,36)",
    "rgb(253,100,33)",
    "rgb(253,82,31)",
    "rgb(252,57,29)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
    "rgb(252,32,28)",
];
const brandColor = "#00A99D"

export default class Portfolio extends React.Component {

    state={
        portName: '',
        portNameError: false,
        // actual: false,
        showSaveModal:false,
        saved: false,
        saveWhich: 'actual',
        subModal: false
    }

    saveOne = (body)=>{
        let URL = `${ENDPOINT}/risk-monkey/portfolios/save`
            fetch(URL,{
                method: "POST",
                headers:{
                  Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
              })
            .then(response=>response.json())
            .then(responseJson=>{
                console.log('responseJson from save: ', responseJson)
                if(responseJson.status === 'duplicate'){
                    console.log('hit duplicate')
                    this.setState({portNameError:'duplicate'})
                }
                else if (responseJson.status === 'error'){
                    this.setState({portNameError:'error'})
                }
                else{
                    console.log(body)
                    console.log('successful save')
                }
        }).then(()=>this.props.fetchCustomStrategies())
        .catch(err=>console.log('save err: ', err))
    }

    savePortfolio = () => {
        if(this.props.wm){
            if(this.state.portName.length > 0 && !(this.state.portName.indexOf(' ') >= 0)){
                let body = {
                    "holdings" : this.state.saveWhich === 'actual' ? this.bodyForSaveActual() :this.bodyForSaveOpt(),
                    "portfolioId" : `${this.state.portName}${this.state.saveWhich === 'both' ?'--Optimized':null}`
                } 
                this.saveOne(body)
                if(this.state.saveWhich === 'both'){
                    let bodyTwo = {
                        "holdings" : this.bodyForSaveActual(),
                        "portfolioId" : `${this.state.portName}${this.state.saveWhich === 'both' ?'--Actual':null}`
                    }
                    this.saveOne(bodyTwo)
                }
            }else{
                this.setState({portNameError:'no name'})
            }
        }else{
            this.setState({subModal:true})
        }
    }

    bodyForSaveActual = ()=>{
        let data = this.props.userInputs
        let holdings = []
        Object.keys(data).forEach(ele => {
            holdings.push({
                "symbol": ele,
                "quantity": data[ele].shares
            })
        })
        return holdings
    }

    bodyForSaveOpt = () =>{
        let data = this.props.data.optimizedPortfolioShare
        let holdings = []
        Object.keys(data).forEach(ele => {
            holdings.push({
                "symbol": ele,
                "quantity": data[ele]
            })
        })
        return holdings
    }

    textColor(input) {
        let num = ((Math.abs(input) * 2) / 5) * 100
        if (colored[num.toFixed(0)]) {
            return colored[num.toFixed(0)];
        } else {
            return colored[19]
        }
    }

    setColoredGauges(input) {
        let pivotPoint = ((Math.abs(input) * 2) / 5) * 100;
        let answer = [...uncolored];
        return answer.map((ele, i) => {
            if (i <= pivotPoint) {
                return colored[i];
            } else {
                return ele;
            }
        });
    }

    setGaugeText(cvar) {
        if (cvar <= 0.15) {
            return 'low risk'
        }
        if (cvar > 0.15 && cvar < 0.3) {
            return 'medium risk'
        }
        if (cvar >= 0.3) {
            return 'high risk'
        }
    }

    riskNum(cvar,ticker){
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }

    createRow(symbol, yours, ours, index){
        return(
            <div style={{display:'flex',justifyContent:'space-around', alignItems:'flex-start', width:'100%', borderBottom:'solid',}}>
                <div style={{display: 'flex', justifyContent:'center', alignItems:'center', width: '33%',}}>
                    <p style={{margin:0, marginLeft: 'auto', marginRight:'auto', display:'flex', alignItems:'flex-end' }}>
                        {symbol}
                    </p>
                </div>
                <div style={{display: 'flex', justifyContent:'center',alignItems:'center',width: '33%', borderLeft:'solid', borderRight:'solid', padding: 3}}>
                    <p style={{margin:0, margin: 'auto'}}>
                        {yours}{index !== 0 ? "%" : null }
                    </p>
                </div>
                <div style={{display: 'flex', justifyContent:'center',alignItems:'center',width: '33%'}}>
                    <p style={{margin:0, margin: 'auto'}}>
                        {ours}{index !== 0 ? "%" : null }
                    </p>
                </div>
            </div>
        )
    }

    tickers2 = ()=>{
        const{optimizedPortfolio} = this.props.data
        const {userInputs} = this.props
        let  data = []
        Object.keys(optimizedPortfolio).map(ele=>{
            data.push([ele, Math.abs(userInputs[ele].allocation).toFixed(), Math.abs(100 * optimizedPortfolio[ele]).toFixed()])
        })
        // return (
        //     <div style={{display:'flex', flexDirection:'column', width: '100%'}}>
        //         {data.map((ele,i)=>this.createRow(ele[0],ele[1],ele[2],i))}
        //     </div>
        // )
        return data
    }

    tickers = (location)=>{
        let tickers
        const{optimizedPortfolio} = this.props.data
        if(location === 'tickers'){
        tickers = Object.keys(optimizedPortfolio).map(ele=>{
            return(
                <p>{ele}</p>
            )
        })}
        else if(location === 'optimized'){
            tickers = Object.values(optimizedPortfolio).map(ele=>{
                return(
                    <p style={{color: ele < 0 ? 'rgb(233,89,74)':'rgb(17,17,17)', fontWeight: 'bold'}}>{Math.abs(100*ele).toFixed()}%</p>
                )
            })
        }
        else if(location === 'userInputs'){
            tickers = this.props.userInputs.map(ele=>{
                return(
                    <p style={{color: 'rgb(17,17,17)', fontWeight: 'bold'}}>{ele.allocation}%</p>
                )
            })
        }
        return tickers
    }

    saveModal = ()=>{
        if(this.state.showSaveModal){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, padding:20}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                            <div style={{width:'20%',}}/>
                                <h2>Save Portfolio</h2>
                            <div style={{width:'20%',}}>
                                <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({showSaveModal:false})}>
                                    <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                        X
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'60%', justifyContent:'center', alignItems:'center'}}>
                           <p>
                               Name your Portfolio (names may not include spaces)
                            </p>
                            <input style={{width:'80%', backgroundColor:this.state.portNameError?'rgb(253,180,181)':'', paddingLeft:'1%'}} placeholder="Portfolio-Name" type="text" value={this.state.portName} onChange={(e)=>this.setState({portName:e.target.value, portNameError:false})}/>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                            <p>Which Portfolio would you like to save?</p>
                            <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'flex-start'}}>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}>
                                    <input style={{cursor:'pointer',}}type="radio" checked={this.state.saveWhich === 'optimized'} onChange={this.state.saveWhich === 'optimized'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'optimized'})}/>
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">Optimized Portfolio</label>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                    <input style={{cursor:'pointer'}}type="radio" checked={this.state.saveWhich === 'actual'} onChange={this.state.saveWhich === 'actual'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'actual'})} />
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="risk">Actual Portfolio</label>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                    <input style={{cursor:'pointer'}}type="radio" checked={this.state.saveWhich === 'both'} onChange={this.state.saveWhich === 'both'? ()=>this.setState({saveWhich:''}) :()=>this.setState({saveWhich:'both'})} />
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="risk">Both Portfolios</label>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(226,238,227)', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} onClick={()=>{
                                
                                    this.setState({showSaveModal:false})
                                    this.savePortfolio()
                                    this.setState({showSaveModal:false, saved:true})
                                
                                }}>
                                <p style={{margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
                                    {this.state.saved? 'Saved':'Save'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    statTable = ()=>{
        let {CVaR, dispersion, divYield, sharpeRatio,drawDownChart, maxDrawdown, returns, correlation, assetBreakDown} = this.props.data
        return(
            <div style={{width:this.props.isSmallScreen? '100%': '33%'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width: '100%', }}>
                            <div 
                                style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:brandColor, cursor:'pointer'}}
                                onClick={()=>this.props.toPc()}
                            >
                                <FaArrowLeft color={'rgb(255,255,255)'}/>
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>Back</p>
                            </div>
                            <div 
                                style={{width:'20%', display:'flex', justifyContent:'space-around', alignItems:'center', padding:10, borderRadius:8, backgroundColor:this.state.saved?'rgb(100,100,100)' :brandColor, cursor:'pointer', marginRight:5}}
                                onClick={this.state.saved? null :()=>this.setState({showSaveModal:true})}
                            >
                                <p style={{margin:0, color:'rgb(255,255,255)'}}>{this.state.saved? 'Saved':'Save'}</p>
                                {/* <FaSave color={'rgb(255,255,255)'}/> */}
                            </div>
                            {/* <div style={{width:'60%', display:'flex', flexDirection:'column'}}>
                                <div style={{width:'100%'}}>
                                    <p style={{margin:0}}>Name this portfolio</p>
                                </div>
                                <div style={{width:'100%'}}>
                                    <input type="text" placeholder="Portfolio Name" value={this.state.portName} onChange={(e)=>this.setState({portName:e.target.value})}/>
                                </div>
                            </div> */}
                        </div>
                        <div style={{paddingLeft: 5, paddingRight: 5, }}>
                            <div style={{display: 'flex', justifyContent: 'center',borderBottom:'solid'}}>
                            <p style={{margin:0}}>Stats</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div style={{width:'33%', display:'flex', justifyContent:'flex-start'}}>
                                    <p>Category</p>
                                </div>
                                <div style={{width:'33%', display:'flex', justifyContent:'flex-end',}}>
                                    <p>Actual</p>
                                </div>
                                <div style={{width:'33%', display:'flex', justifyContent:'flex-end',}}>
                                    <p>Optimized</p>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10}}>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <p style={{margin: 0, fontWeight: 'bold'}}>AR Risk Number</p>
                                    <p style={{margin: 0, }}>the lower the better</p>
                                </div>
                                <div style={{backgroundColor:this.textColor(CVaR.old), borderRadius: 8, padding: 10, width: 80, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <h2 style={{margin:0}}> {this.riskNum(CVaR.old)}</h2>
                                </div>
                                <div style={{backgroundColor:this.textColor(CVaR.new), borderRadius: 8, padding: 10, width: 80, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <h2 style={{margin:0}}> {this.riskNum(CVaR.new)}</h2>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Return</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{returns.old.toFixed(2)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{returns.new.toFixed(2)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Cvar</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(CVaR.old *100).toFixed(0)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(CVaR.new *100).toFixed(0)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Correlation to SPX</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(correlation.old).toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(correlation.new).toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Max Drawdown</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(maxDrawdown.old*100).toFixed(0)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{(maxDrawdown.new*100).toFixed(0)}%</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Sharpe Ratio</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{sharpeRatio.old.toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{sharpeRatio.new.toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Dispersion</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{dispersion.old.toFixed(2)}</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{dispersion.new.toFixed(2)}</p></div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between',marginBottom: 10, marginRight: 5}}>
                                <div style={{width:'50%'}}><p style={{margin:0,padding:0}}>Dividend Yield</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}><p style={{margin:0,padding:0}}>{divYield.old.toFixed(2)}%</p></div>
                                <div style={{width:'25%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><p style={{margin:0,padding:0}}>{divYield.new.toFixed(2)}%</p></div>
                            </div>
                        </div>
                    </div>
        )
    }

    subModal = ()=>{
        if(this.state.subModal){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{width:'20%',display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({subModal:false})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                        <div>
                            <SubModal userEmail={this.props.userEmai} updatePaymentStatus={this.props.updatePaymentStatus} subStatus={this.props.subStatus} activeTab={this.props.activeTab} user={this.props.user} subLevel={this.props.subLevel}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if(Object.keys(this.props.data).length < 2){
            console.log('something didnt work, lets check out the data:',this.props.data)
            return(
                <div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h2>Loading</h2>
                    </div>
                </div>
            )
        }
        let {CVaR, dispersion, divYield, sharpeRatio,drawDownChart, maxDrawdown, returns, correlation, assetBreakDown} = this.props.data
        if(!this.props.isSmallScreen){
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', border: 'solid' }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', margin: 0, }}>
                    {this.statTable()}
                    <div style={{ display: 'flex', justifyContent:'center', alignItems:'flex-start', borderLeft:'solid',borderRight:'solid', height: 460, padding: 0, width: '33%'  }}>
                        <div style={{width: '100%', display:'flex', alignItems:'flex-start', marginTop: 5 }}>
                            <TickersTable data={this.tickers2()} isSmallScreen={this.props.isSmallScreen}/>
                        </div>
                    </div>
                        <div style={{height: 420, display:'flex', flexDirection: 'column', width:'33%', marginTop: 20, alignItems:'center', justifyContent:'center'}}>
                            <div style={{height: 210}}>
                                <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                    <RiskGuage cvar={CVaR.new} before={false}/>
                                </div>
                            </div>
                            <div style={{height: 210}}>
                                <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                    <RiskGuage cvar={CVaR.old} before={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems:'center',height: '100%', borderTop:'solid' }}>
                    <div style={{ width:'50%',minWidth: 400}}>
                        <div style={{width:'100%', }}>
                            <PieChart beforeData={assetBreakDown.old} optData={assetBreakDown.new} />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column',width:'100%' }}>
                            <p style={{margin:0, zIndex: 1}}>Stress Test</p>
                            <div style={{zIndex:0, width:'100%'}}>
                                <DoubleLine data={drawDownChart} title={'stress'}/>
                            </div>
                        </div>
                    </div>
                </div>
                {this.saveModal()}
                {this.subModal()}
            </div>
            
        )}
        else{
            return(
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <div style={{width:'100%'}}>
                        {this.statTable()}
                    </div>
                    <hr></hr>
                    <div style={{width: '100%', display:'flex', flexDirection:'column', marginTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <p>Asset Breakdown</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent:'center', alignItems:'center',width:'100%'}}>
                            <TickersTable data={this.tickers2()} isSmallScreen={this.props.isSmallScreen}/>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', flexDirection: 'column', width:'100%', marginTop: 20}}>
                        <div style={{ display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <p style={{fontSize:'larger'}}>Risk Level</p>
                        </div>
                        <div style={{height: 210}}>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                <RiskGuage cvar={CVaR.new} before={false}/>
                            </div>
                        </div>
                        <div style={{height:20}}/>
                        <div style={{height: 210}}>
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', }}>
                                <RiskGuage cvar={CVaR.old} before={true}/>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column', width:'50%'}}>
                        <PieChart beforeData={assetBreakDown.old} optData={assetBreakDown.new} isSmallScreen={this.props.isSmallScreen} />
                    </div>
                    <hr></hr>
                    <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column',width:'50%' }}>
                        <p style={{margin:0, zIndex: 1}}>Stress Test</p>
                        <div style={{zIndex:0, marginRight: '50%', }}>
                            <DoubleLine data={drawDownChart} title={'stress'} isSmallScreen={this.props.isSmallScreen}/>
                        </div>
                    </div>
                    {this.saveModal()}
                    {this.subModal()}
                </div>
            )
        }
    }
}