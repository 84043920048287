//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import { Dropdown } from 'primereact/dropdown'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'

//STYLES
import '../../scss/components/budgeting/ActivityDetails.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'
import moment from 'moment'

export default function ActivityExpenses() {
  const { t, budgetingData, setPage, isSmallScreen } =
    useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting

  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('ALL')
  const [graphTab, setGraphTab] = useState('6M')
  const [graphData, setGraphData] = useState(null)

  useEffect(() => {
    changeGraphData()
  }, [graphTab, selected])

  const graphContent = () => {
    return (
      <div className='graph-content'>
        {renderGraph()}
        {graphTabs()}
      </div>
    )
  }

  const changeGraphData = () => {
    const data = {
      graph: [],
    }
    let total = 0
    let totalIncome = 0
    let totalExpense = 0
    switch (graphTab) {
      case '1W':
        const { startDate, endDate } = getPreviousFullWeek()
        console.log(startDate, endDate)
        const array1w = details?.expense?.expense_daily
        for (let i = 0; i < array1w?.length; i++) {
          if (array1w[i]?.start >= startDate && array1w[i]?.start <= endDate) {
            if (selected === 'ALL') {
              totalIncome += array1w?.[i]?.income * -1
              totalExpense += array1w?.[i]?.Expense
              data.graph.push({
                name: moment
                  .unix(parseInt(array1w?.[i]?.start / 1000))
                  .format('ddd'),
                [t('income')]: array1w?.[i]?.income * -1,
                [t('expenses')]: array1w?.[i]?.Expense,
              })
            } else {
              let amount = array1w?.[i]?.[selected]
              if (
                selected === 'TRANSFER_IN' ||
                selected === 'BANK_FEES' ||
                selected === 'LOAN_PAYMENTS'
              ) {
                amount = amount * -1
              }
              total += amount
              data.graph.push({
                name: moment
                  .unix(parseInt(array1w?.[i]?.start / 1000))
                  .format('ddd'),
                [t('total')]: amount,
              })
            }
          }
        }
        break
      case '1M':
        const { startDayMonth, endDayMonth } = getPreviousMonth()
        console.log(startDayMonth, endDayMonth)
        const array1m = details?.expense?.expense_daily
        for (let i = 0; i < array1m?.length - 1; i++) {
          if (
            array1m[i]?.start >= startDayMonth &&
            array1m[i]?.start <= endDayMonth
          ) {
            if (selected === 'ALL') {
              totalIncome += array1m?.[i]?.income * -1
              totalExpense += array1m?.[i]?.Expense
              data.graph.push({
                name: moment
                  .unix(parseInt(array1m?.[i]?.start / 1000))
                  .format('DD'),
                [t('income')]: array1m?.[i]?.income * -1,
                [t('expenses')]: array1m?.[i]?.Expense,
              })
            } else {
              let amount = array1m?.[i]?.[selected]
              if (
                selected === 'TRANSFER_IN' ||
                selected === 'BANK_FEES' ||
                selected === 'LOAN_PAYMENTS'
              ) {
                amount = amount * -1
              }
              total += amount
              data.graph.push({
                name: moment
                  .unix(parseInt(array1m?.[i]?.start / 1000))
                  .format('DD'),
                [t('total')]: amount,
              })
            }
          }
        }
        break
      case '3M':
        // const threemonthAgo = getThreeMonthsAgo()
        // console.log(threemonthAgo)
        // const array3m = details?.expense?.expense_weekly
        // for (let i = 0; i < array3m?.length - 1; i++) {
        //   if (array3m[i]?.start >= threemonthAgo) {
        //     if (selected === 'ALL') {
        //       totalIncome += array3m?.[i]?.income * -1
        //       totalExpense += array3m?.[i]?.Expense
        //       data.graph.push({
        //         name: moment
        //           .unix(parseInt(array3m?.[i]?.start / 1000))
        //           .format('MMM'),
        //         Income: array3m?.[i]?.income * -1,
        //         Expense: array3m?.[i]?.Expense,
        //       })
        //     } else {
        //       let amount = array3m?.[i]?.[selected]
        //       if (
        //         selected === 'TRANSFER_IN' ||
        //         selected === 'BANK_FEES' ||
        //         selected === 'LOAN_PAYMENTS'
        //       ) {
        //         amount = amount * -1
        //       }
        //       total += amount
        //       data.graph.push({
        //         name: moment
        //           .unix(parseInt(array3m?.[i]?.start / 1000))
        //           .format('MMM'),
        //         Total: amount,
        //       })
        //     }
        //   }
        // }
        const array3m = details?.expense?.expense_monthly
        const size3m = array3m?.length
        for (let i = Math.max(0, size3m - 3 - 1); i < size3m - 1; i++) {
          if (selected === 'ALL') {
            totalIncome += array3m?.[i]?.income * -1
            totalExpense += array3m?.[i]?.Expense
            data.graph.push({
              name: moment
                .unix(parseInt(array3m?.[i]?.start / 1000))
                .format('MMM'),
              [t('income')]: array3m?.[i]?.income * -1,
              [t('expenses')]: array3m?.[i]?.Expense,
            })
          } else {
            let amount = array3m?.[i]?.[selected]
            if (
              selected === 'TRANSFER_IN' ||
              selected === 'BANK_FEES' ||
              selected === 'LOAN_PAYMENTS'
            ) {
              amount = amount * -1
            }
            total += amount
            data.graph.push({
              name: moment
                .unix(parseInt(array3m?.[i]?.start / 1000))
                .format('MMM'),
              [t('total')]: amount,
            })
          }
        }
        break
      case '6M':
        const array6m = details?.expense?.expense_monthly
        const size = array6m?.length
        for (let i = Math.max(0, size - 6 - 1); i < size - 1; i++) {
          if (selected === 'ALL') {
            totalIncome += array6m?.[i]?.income * -1
            totalExpense += array6m?.[i]?.Expense
            data.graph.push({
              name: moment
                .unix(parseInt(array6m?.[i]?.start / 1000))
                .format('MMM'),
              [t('income')]: array6m?.[i]?.income * -1,
              [t('expenses')]: array6m?.[i]?.Expense,
            })
          } else {
            let amount = array6m?.[i]?.[selected]
            if (
              selected === 'TRANSFER_IN' ||
              selected === 'BANK_FEES' ||
              selected === 'LOAN_PAYMENTS'
            ) {
              amount = amount * -1
            }
            total += amount
            data.graph.push({
              name: moment
                .unix(parseInt(array6m?.[i]?.start / 1000))
                .format('MMM'),
              [t('total')]: amount,
            })
          }
        }
        break
      default:
        break
    }
    data.total = total
    data.totalIncome = totalIncome
    data.totalExpense = totalExpense
    setGraphData(data)
  }

  const getPreviousFullWeek = () => {
    const currDate = new Date()
    currDate.setUTCHours(0, 0, 0, 0)
    const daysFromSunday = currDate.getDay()
    const beginnigOfThisWeek = new Date(
      currDate.setDate(currDate.getDate() - daysFromSunday - 1)
    )
    const startDate = new Date(
      beginnigOfThisWeek.setDate(beginnigOfThisWeek.getDate() - 7)
    ).getTime()
    const tempDate = new Date(startDate)
    const endDate = new Date(tempDate.setDate(tempDate.getDate() + 7)).getTime()
    return { startDate, endDate }
  }

  const getPreviousMonth = () => {
    const currDate = new Date()
    const previousMonth = new Date(currDate.setMonth(currDate.getMonth() - 1))
    const startDayMonth = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth(),
      1
    )
    startDayMonth.setUTCHours(0, 0, 0, 0)
    const endDayMonth = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth() + 1,
      0
    )
    endDayMonth.setUTCHours(23, 59, 59, 999)
    return {
      startDayMonth: startDayMonth.getTime(),
      endDayMonth: endDayMonth.getTime(),
    }
  }

  const getThreeMonthsAgo = () => {
    const currDate = new Date()
    return new Date(currDate.setMonth(currDate.getMonth() - 3)).getTime()
    // const currDate = new Date()
    // const previousMonth = new Date(currDate.setMonth(currDate.getMonth() - 1))
    // const startDayMonth = new Date(
    //   previousMonth.getFullYear(),
    //   previousMonth.getMonth(),
    //   1
    // )
    // return new Date(
    //   startDayMonth.setMonth(startDayMonth.getMonth() - 2)
    // ).getTime()
  }

  const interval = () => {
    switch (graphTab) {
      case '1W':
        if (isSmallScreen) {
          return 1
        } else {
          return 0
        }
      case '1M':
        return 5
      case '3M':
        return 0
      case '6M':
        if (isSmallScreen) {
          return 1
        } else {
          return 0
        }
      default:
        return 0
    }
  }

  const renderGraph = () => {
    return (
      <div className='activity-expenses-graph-container'>
        <ResponsiveContainer width='100%' height={350}>
          <BarChart
            width={500}
            height={300}
            data={graphData?.graph}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='2' />
            <XAxis dataKey='name' axisLine={false} interval={interval()} />
            <YAxis
              axisLine={false}
              tickFormatter={(value) => formatCurrency(value)}
            />
            <Tooltip
              formatter={(value) => formatCurrency(value)}
              cursor={{ fill: 'rgba(128,128,128,.3)' }}
            />
            <Legend />
            {selected === 'ALL' ? (
              <>
                <Bar
                  dataKey={t('income')}
                  fill='#7B61FF'
                  radius={[10, 10, 10, 10]}
                />
                <Bar
                  dataKey={t('expenses')}
                  fill='#3627E4'
                  radius={[10, 10, 10, 10]}
                />
              </>
            ) : (
              <Bar
                dataKey={t('total')}
                fill='#3627E4'
                radius={[10, 10, 10, 10]}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const graphTabs = () => {
    return (
      <div className='graph-tabs'>
        <p
          className={`graph-tab ${graphTab === '1W' ? 'selected' : ''}`}
          onClick={() => {
            setGraphTab('1W')
          }}
        >
          {t('one_week')}
        </p>
        <p
          className={`graph-tab ${graphTab === '1M' ? 'selected' : ''}`}
          onClick={() => {
            setGraphTab('1M')
          }}
        >
          {t('one_month')}
        </p>
        <p
          className={`graph-tab ${graphTab === '3M' ? 'selected' : ''}`}
          onClick={() => {
            setGraphTab('3M')
          }}
        >
          {t('three_month')}
        </p>
        <p
          className={`graph-tab ${graphTab === '6M' ? 'selected' : ''}`}
          onClick={() => {
            setGraphTab('6M')
          }}
        >
          {t('six_month')}
        </p>
      </div>
    )
  }

  const graphLegend = () => {
    return (
      <div className='graph-legend'>
        {selected === 'ALL' ? (
          <>
            <div className='activity-expenses-row'>
              <p className='activity-expenses-label'>{t('total_income')}:</p>
              <p className='activity-expenses-value'>
                {formatCurrency(graphData?.totalIncome)}
              </p>
            </div>
            <div className='activity-expenses-row'>
              <p className='activity-expenses-label'>{t('total_expense')}:</p>
              <p className='activity-expenses-value'>
                {formatCurrency(graphData?.totalExpense)}
              </p>
            </div>
          </>
        ) : (
          <div className='activity-expenses-row'>
            <div className='activity-expenses-row'>
              <p className='activity-expenses-label'>{t('total')}:</p>
              <p className='activity-expenses-value'>
                {formatCurrency(graphData?.total)}
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='activity-expenses'>
      <p className='activity-details-section-header'>{t('expenses')}</p>
      <div className='activity-details-dropdown-container'>
        <Dropdown
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value)
          }}
          placeholder={t('select_category')}
          options={[
            { label: t('income_n_expense'), value: 'ALL' },
            { label: t('bank_fees'), value: 'BANK_FEES' },
            { label: t('entertainment'), value: 'ENTERTAINMENT' },
            { label: t('food_and_drink'), value: 'FOOD_AND_DRINK' },
            { label: t('general_merchandise'), value: 'GENERAL_MERCHANDISE' },
            { label: t('general_services'), value: 'GENERAL_SERVICES' },
            {
              label: t('government_and_non_profit'),
              value: 'GOVERNMENT_AND_NON_PROFIT',
            },
            { label: t('home_improvement'), value: 'HOME_IMPROVEMENT' },
            { label: t('loan_payments'), value: 'LOAN_PAYMENTS' },
            { label: t('medical'), value: 'MEDICAL' },
            { label: t('personal_care'), value: 'PERSONAL_CARE' },
            { label: t('rent_and_utilities'), value: 'RENT_AND_UTILITIES' },
            { label: t('transportation'), value: 'TRANSPORTATION' },
            { label: t('travel'), value: 'TRAVEL' },
            { label: t('transfer_in'), value: 'TRANSFER_IN' },
            { label: t('transfer_out'), value: 'TRANSFER_OUT' },
          ]}
        />
      </div>
      {graphLegend()}
      {graphContent()}
    </div>
  )
}
