import React, { Component } from 'react'
import SearchGlass from '../../assets/newUI/searchGlass.png'
import { NavLink } from 'react-router-dom'
import ENDPOINT from '../Endpoint'
import upArrow from '../../assets/single_up.svg'
import downArrow from '../../assets/single_down.svg'
import strongUpArrow from '../../assets/double_up.svg'
import strongDownArrow from '../../assets/double_down.svg'
import flatArrow from '../../assets/flat.svg'
import SearchIcon from '../../assets/search-icon.png'
import AddIcon from '../../assets/add-icon.png'
import CheckIcon from '../../assets/check-icon.png'
import {
  FaArrowLeft,
  FaSearch,
  FaTimes,
  FaQuestionCircle,
} from 'react-icons/fa'
import SearchEndpoint from '../SearchEndpoint'
import { addToWatchlist } from '../../services/userServices'
import XIcon from '../../assets/x_icon.svg'
import { Dialog } from 'primereact/dialog'
import WatchlistSelection from '../WatchlistSelection'

export default class SearchBar extends Component {
  state = {
    searchActive: true,
    searchResults: [],
    searchText: '',
    watchlistModal: false,
    type: '',
    symbol: '',
  }

  onSubmitSearchMomentum = () => {
    const { searchText } = this.state
    this.setState({ dataLoading: true })
    let url = `${SearchEndpoint}/ticker?search=${searchText.toUpperCase()}`

    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        for (var i = 0; i < data.length; i++) {
          var item = data[i]
        }
        this.setState({
          dataLoading: false,
          searchResults: data,
        })
      })
      .catch((err) => {
        this.setState({ searchResults: false })
        console.log(err)
      })
  }

  search = (event) => {
    let searchText = event.target.value.toUpperCase()
    this.setState({ searchText }, () => {
      const { isSearching } = this.state
      if (isSearching) {
        return
      } else {
        this.setState({ isSearching: true })
        setTimeout(() => {
          this.setState({ isSearching: false }, this.onSubmitSearchMomentum)
        }, 200)
      }
    })
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return downArrow
      case 1:
        return upArrow
      case 2:
        return strongUpArrow
      case -2:
        return strongDownArrow
      default:
        return flatArrow
    }
  }

  addWatchList(symbol) {
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({ ['add']: [symbol] }),
    })
      .catch((err) => {
        console.log(err)
      })
      .then((res) => {})
  }

  watchlisted = (symbol) => {
    for (let i = 0; i < this.props.myWatchlist?.length; i++) {
      if (this.props.myWatchlist[i]?.symbol === symbol) {
        return true
      }
    }
    return false
  }

  showResults = () => {
    let arr = []
    // this.state.searchResults.forEach(ele=>{
    for (let i = 0; i < Math.min(6, this.state.searchResults.length); i++) {
      arr.push(
        <>
          <NavLink /* onClick={()=>this.setState({searchActive:false})} */
            onMouseLeave={() => this.setState({ hover: false })}
            onMouseEnter={() =>
              this.setState({ hover: this.state.searchResults[i] })
            }
            onClick={() => {
              this.setState({
                searchText: '',
                searchResults: [],
                sourceData: false,
                noTicker: 'loading',
              })
            }}
            to={`/Dashboard/Trade/${this.state.searchResults[i].symbol}`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor:
                this.state.hover === this.state.searchResults[i]
                  ? '#f1f1f1'
                  : '#fafafa',
              cursor: 'pointer',
              textDecoration: 'none',
              color: 'rgb(17,17,17)',
              borderBottom: 'solid',
              borderWidth: 0.25,
              borderColor: '#cccccc',
              padding: 8,
              borderRadius:
                i == Math.min(5, this.state.searchResults.length - 1)
                  ? '0 0 15px 15px'
                  : '',
            }}
          >
            <div
              style={{
                width: '90%',
                margin: 'auto',
                display: 'flex',
                justifyContent: '',
              }}
            >
              <div
                style={{ width: '30%', display: 'flex', alignItems: 'center' }}
              >
                <p style={{ margin: 0 }}>
                  {this.state.searchResults[i].symbol}
                </p>
              </div>
              <div
                style={{
                  width: '70%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ margin: 0, textAlign: 'left' }}>
                  {this.state.searchResults[i].company}
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 5,
                  }}
                >
                  {/* <img
                    src={AddIcon}
                    width={25}
                    height={25}
                    onClick={async (e) => {
                      e.preventDefault()
                      await addToWatchlist(
                        'add',
                        this.state.searchResults[i].symbol,
                        this.props.user.idToken.jwtToken
                      )
                      this.props.refreshW1()
                    }}
                  /> */}

                  {/*{this.watchlisted(this.state.searchResults[i].symbol) ? (
                    <img
                      src={CheckIcon}
                      width={25}
                      height={25}
                      onClick={async (e) => {
                        e.preventDefault()
                        if (this.props.history)
                          this.props.history.push('/Dashboard/Watchlist')
                        // this.setState({
                        //   watchlistModal: true,
                        //   type: 'remove',
                        //   symbol: this.state.searchResults[i].symbol,
                        // })
                      }}
                    />
                  ) : (
                    <img
                      src={AddIcon}
                      width={25}
                      height={25}
                      onClick={async (e) => {
                        e.preventDefault()
                        this.setState({
                          watchlistModal: true,
                          type: 'add',
                          symbol: this.state.searchResults[i].symbol,
                        })
                        // await addToWatchlist(
                        //   'add',
                        //   this.state.searchResults[i].symbol,
                        //   this.props.user.idToken.jwtToken
                        // )
                        // this.props.refreshWl()
                        // if (this.props.history)
                        //   this.props.history.push('/Dashboard/Watchlist')
                      }}
                    />
                  )} */}

                  <img
                    src={AddIcon}
                    width={25}
                    height={25}
                    onClick={async (e) => {
                      e.preventDefault()
                      this.setState({
                        watchlistModal: true,
                        type: 'add',
                        symbol: this.state.searchResults[i].symbol,
                      })
                      // await addToWatchlist(
                      //   'add',
                      //   this.state.searchResults[i].symbol,
                      //   this.props.user.idToken.jwtToken
                      // )
                      // this.props.refreshWl()
                      // if (this.props.history)
                      //   this.props.history.push('/Dashboard/Watchlist')
                    }}
                  />
                </div>
              </div>
            </div>
          </NavLink>
        </>
      )
      // if (i + 1 >= this.state.searchResults.length) {
      //   i += 5
      // }
    }
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          zIndex: 9,
          boxShadow: '0px 15px 15px 0px #c4c4c4',
          borderRadius: '0 0 15px 15px',
        }}
      >
        {this.state.searchText.length > 0 ? arr : null}
      </div>
    )
  }

  displaySearchBar = () => {
    if (this.state.searchActive) {
      if (this.props.isSmallScreen) {
        return (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: 40,
                width: '',
                borderRadius:
                  this.state.searchResults.length > 0 &&
                  this.state.searchText !== ''
                    ? '20px 20px 0 0 '
                    : 20,
                backgroundColor: 'rgb(231,232,233)',
                paddingLeft: this.state.isSmallScreen ? 0 : 20,
                padding: this.state.isSmallScreen ? 8 : '',
                paddingRight: 15,
              }}
            >
              {/* <img
                src={SearchGlass}
                style={{ height: 15, marginRight: 0 }}
                onClick={() =>
                  this.setState({ searchActive: !this.state.searchActive })
                }
              /> */}
              {/* <img
                src={SearchGlass}
                style={{ height: 20, marginRight: 13, marginLeft: 20 }}
                onClick={() =>
                  this.setState({ searchActive: !this.state.searchActive })
                }
              /> */}
              <FaSearch
                style={{
                  color: '#ddd',
                  width: '25px',
                  height: '25px',
                  margin: '0 10px',
                }}
              />
              <input
                autoFocus
                value={this.state.searchText}
                id='headerSearch'
                onChange={(e) => this.search(e)}
                type='text'
                placeholder={this.props.t('search')}
                style={{
                  border: 'none',
                  outline: 'none',
                  backgroundColor: 'rgb(231,232,233)',
                  width: '90%',
                }}
                autoComplete='off'
              />
              {/* <p
                onClick={() => {
                  this.setState({ searchText: '' })
                }}
                style={{
                  color: 'grey',
                  fontsize: 5,
                  margin: 0,
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
              >
                X
              </p> */}
              <img
                src={XIcon}
                width={20}
                height={20}
                onClick={() => {
                  this.setState({ searchText: '' })
                }}
              />
            </div>
            {this.state.searchResults.length > 0 ? (
              <div
                style={{
                  position: 'relative',
                  boxShadow: '0px 15px 15px 0px #c4c4c4',
                  borderRadius: '0 0 15px 15px',
                }}
              >
                {this.showResults()}
              </div>
            ) : null}
          </div>
        )
        // return (
        //   <div
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //     }}
        //   >
        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         width: "",
        //         borderRadius:
        //           this.state.searchResults.length > 0 ? "20px 20px 0 0 " : 20,
        //         backgroundColor: "rgb(231,232,233)",
        //         paddingLeft: this.state.isSmallScreen ? 0 : 20,
        //         padding: this.state.isSmallScreen ? 8 : "",
        //       }}
        //     >
        //       {/* <img
        //         src={SearchGlass}
        //         style={{ height: 15, marginRight: 5 }}
        //         onClick={() =>
        //           this.setState({ searchActive: !this.state.searchActive })
        //         }
        //       /> */}
        //       <img
        //         src={SearchIcon}
        //         style={{ height: 15, marginRight: 5 }}
        //         onClick={() =>
        //           this.setState({ searchActive: !this.state.searchActive })
        //         }
        //       />
        //       <input
        //         autoFocus
        //         value={this.state.searchText}
        //         id="headerSearch"
        //         onChange={(e) => this.search(e)}
        //         type="text"
        //         placeholder="Enter stock symbol"
        //         style={{
        //           border: "none",
        //           outline: "none",
        //           backgroundColor: "rgb(231,232,233)",
        //         }}
        //         autocomplete="off"
        //       />
        //     </div>
        //     {this.state.searchResults.length > 0 ? (
        //       <div
        //         style={{
        //           position: "absolute",
        //           zIndex: 999,
        //           width: "60%",
        //           marginTop: 20,
        //           borderRadius: "0 0 15px 15px",
        //         }}
        //       >
        //         {this.showResults()}
        //       </div>
        //     ) : null}
        //   </div>
        // );
      }
      return (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 40,
              width: '',
              borderRadius:
                this.state.searchResults.length > 0 &&
                this.state.searchText !== ''
                  ? '20px 20px 0 0 '
                  : 20,
              backgroundColor: 'rgb(231,232,233)',
              paddingLeft: this.state.isSmallScreen ? 0 : 20,
              padding: this.state.isSmallScreen ? 8 : '',
              paddingRight: 15,
            }}
          >
            <FaSearch
              style={{
                color: '#ddd',
                width: '25px',
                height: '25px',
                margin: '0 10px',
              }}
            />
            <input
              autoFocus
              value={this.state.searchText}
              id='headerSearch'
              onChange={(e) => this.search(e)}
              type='text'
              placeholder={this.props.t('search')}
              style={{
                border: 'none',
                outline: 'none',
                backgroundColor: 'rgb(231,232,233)',
                width: '90%',
              }}
              autoComplete='off'
            />
            {/* <p
              onClick={() => {
                this.setState({ searchText: '' })
              }}
              style={{
                color: 'grey',
                fontsize: 9,
                margin: 0,
                marginLeft: 5,
                cursor: 'pointer',
              }}
            >
              X
            </p> */}
            <img
              src={XIcon}
              width={20}
              height={20}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ searchText: '' })
              }}
            />
          </div>
          {this.state.searchResults.length > 0 ? (
            <div
              style={{
                position: 'relative',
                boxShadow: '0px 15px 15px 0px #c4c4c4',
                borderRadius: '0 0 15px 15px',
              }}
            >
              {this.showResults()}
            </div>
          ) : null}
        </div>
      )
    } else {
      if (this.state.isSmallScreen) {
        return (
          <div style={{ position: 'relative' }}>
            <img
              src={SearchGlass}
              style={{ height: 20 }}
              onClick={() => {
                this.setState({ searchActive: !this.state.searchActive })
              }}
            />
          </div>
        )
      }
      return (
        <div style={{ marginRight: '1.5rem', position: 'relative' }}>
          <img
            src={SearchGlass}
            style={{ height: 20, cursor: 'pointer' }}
            onClick={() => {
              this.setState({ searchActive: !this.state.searchActive })
            }}
          />
        </div>
      )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ width: '100%' }}>
          {this.displaySearchBar()}{' '}
          <Dialog
            visible={this.state.watchlistModal}
            header={
              this.state.type === 'add'
                ? `${this.props.t('add')} ${this.state.symbol} ${this.props.t(
                    'towatchlist'
                  )}`
                : `${this.props.t('remove')} ${
                    this.state.symbol
                  } ${this.props.t('fromwatchlist')}`
            }
            onHide={() => this.setState({ watchlistModal: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '20vw' }}
          >
            <WatchlistSelection
              names={this.props.myWatchlistGroup?.names}
              symbol={this.state.symbol}
              isSmallScreen={this.props.isSmallScreen}
              user={this.props.user}
              refreshWl={this.props.refreshWl}
              t={this.props.t}
              type={this.state.type}
              myWatchlistGroup={this.props.myWatchlistGroup?.watchlist}
            />
          </Dialog>
        </div>
      )
    } else return null
  }
}
