import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class StrategiesChart extends Component {
    constructor(props){
        super(props);
    }
    
    render() {
        return (
            <div style={{width:'100%', height:600}}>
                <iframe src={this.props.value.frameSrc} title={this.props.value.id} frameBorder="0" height="450" width="100%" scrolling="no"></iframe>
                <p className="chart-title">{this.props.value.title}</p>
                <p className="chart-subtitle" dangerouslySetInnerHTML={{ __html: this.props.value.subtitle}}></p>
            </div>
        )
    }
}

export default StrategiesChart
