import React, { Component } from 'react'
import '../../scss/components/ConfirmACH.scss'
import '../../scss/components/PrimeReact.scss'
import { InputNumber } from 'primereact/inputnumber'
import { Dialog } from 'primereact/dialog'
import Endpoint from '../Endpoint'
import { Button } from 'primereact/button'
import Axios from 'axios'

export default class ConfirmACH extends Component {
  state = {
    //Confirmation Form Fields
    confirmationAmount: null,
    confirmationAmount2: null,

    errorMessage: '',
    successMessage: '',

    //isLoading
    isLoading: false,
  }

  //Confirm Micro-deposit API
  confirmMicroDepositsAPI = async () => {
    this.setState({ isLoading: true })
    await Axios.post(
      `${Endpoint}/apex-crypto/ach-relationships/approve?account_id=${this.props.cryptoAccount.accountId}`,
      {
        relationshipID: this.props.achPending?.id,
        microDeposits: [
          this.state.confirmationAmount,
          this.state.confirmationAmount2,
        ],
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        if (response.data?.status?.approval) {
          this.toggleSuccessMessage('Account successfully linked')
          this.props.getACHaccount()
        } else if (response.data?.status?.errorCode) {
          this.toggleErrorMessage(response.data?.status?.errorDescription)
        }
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        this.toggleErrorMessage('Something went wrong.')
        this.setState({ isLoading: false })
      })
  }

  //CONFIRMATION INPUT CHANGES
  handleConfirmationAmountChange = (value) => {
    this.setState({ confirmationAmount: value })
  }

  handleConfirmationAmount2Change = (value) => {
    this.setState({ confirmationAmount2: value })
  }

  //Toggle Messages
  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 2500)
  }

  toggleSuccessMessage = (msg) => {
    this.setState({ successMessage: msg })
    setTimeout(() => {
      this.setState({ successMessage: '' })
    }, 2500)
  }

  //Form Validation
  validateForm = () => {
    if (this.state.confirmationAmount && this.state.confirmationAmount2) {
      this.confirmMicroDepositsAPI()
    } else {
      this.toggleErrorMessage('Please complete all fields')
    }
  }

  //Submit Button
  submitButtons = () => {
    if (!this.state.isLoading) {
      return (
        <Button
          label='Submit'
          style={{ color: 'white', background: '#3525e4' }}
          onClick={() => {
            this.validateForm()
          }}
        ></Button>
      )
    } else {
      return <Button loading></Button>
    }
  }

  confirmationForm() {
    return (
      <div>
        <p className='confirmACH-subheader'>
          The micro-deposits may take up to two business days to show up in your
          bank account
        </p>
        <div className='confirmACH-inputs-container'>
          <InputNumber
            value={this.state.confirmationAmount}
            onValueChange={(e) => this.handleConfirmationAmountChange(e.value)}
            placeholder='Amount 1'
            mode='currency'
            currency='USD'
            locale='en-US'
            minFractionDigits={0}
            maxFractionDigits={2}
          />
          <InputNumber
            value={this.state.confirmationAmount2}
            onValueChange={(e) => this.handleConfirmationAmount2Change(e.value)}
            placeholder='Amount 2'
            mode='currency'
            currency='USD'
            locale='en-US'
            style={{ marginTop: '15px' }}
            minFractionDigits={0}
            maxFractionDigits={2}
          />
        </div>
        <div className='confirmACH-message-container'>
          <p className='confirmACH-error'>{this.state.errorMessage}</p>
          <p className='confirmACH-success'>{this.state.successMessage}</p>
        </div>
        <div className='confirmACH-button-container'>
          {this.submitButtons()}
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className='confirmACH-dialog-container'>
        <Dialog
          header='Please enter the two Micro-deposit amounts sent to your bank account'
          visible={this.props.showDialog}
          style={{ width: this.props.isSmallScreen ? '90vw' : '35vw' }}
          onHide={() => this.props.toggleShowConfirmACHdialog()}
        >
          {this.confirmationForm()}
        </Dialog>
      </div>
    )
  }
}
