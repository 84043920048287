import React from 'react'
import ReactHighcharts from 'react-highcharts'
// const ReactHighstock = require("react-highcharts/ReactHighstock");
const brandColor = "#00A99D"
const options = {
  global: {
    useUTC: false
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
    rangeSelectorZoom: ""
  }
}

export default class PieChart extends React.Component{
    state={
        optPc:true
    }
    config = ()=>{
        return(
            {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height:400,
                    width:500,
                    backgroundColor:null,
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        animation:false,
                        allowPointSelect: true,
                        cursor: 'pointer',
                        innerSize:'40%',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                credits:{
                    enabled:false
                },
                series: [{
                    name: 'Holdings Analysis',
                    colorByPoint: true,
                    // innerSize: '80%',
                    data: [
                        {
                            name: 'Stock',
                            y: Math.round(this.props.data.Equity*100)/100,
                            color: '#242a38'
                        }, {
                            name: 'Fixed Income',
                            y: Math.round(this.props.data.FixedIncome*100)/100,
                            color: '#8682cb'
                        }, {
                            name: 'Cash',
                            y: Math.round(this.props.data.Cash*100)/100,
                            color: '#3b8e8c'
                        },
                    ]
                }]
            }
        )
    }
    render(){
        // return(
        //     <div style={{width: '400', marginRight: 100, marginTop: -20}}>
        //         <ReactHighcharts config={this.config()} options={options}/>
        //     </div>
        // )
        return(
            <div style={{display: 'flex', flexDirection: 'column',padding:0,width: this.props.isSmallScreen ? '100%':'100%'}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems: 'center', zIndex:10, height: 76}}>
                            <div style={{width:'60%', borderRadius:8,float:'right',padding:10, margin:4}}>
                                <p style={{margin:0, fontWeight:'bold'}}>Asset Allocation</p>
                                <p style={{margin:0, fontWeight:'bold'}}>{this.props.optPc?'Optimized Allocation':'Actual Allocation'}</p>
                            </div>
                            
                        </div>
                        <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection: 'column'}}>
                            <div style={{marginLeft:'22%', width:'100%',display:'flex', justifyContent:'center', alignItems: 'center'}}>
                                <div style={{width: '100%', marginRight: 100,display:'flex', justifyContent:'center', alignItems: 'center'}}>
                                    <ReactHighcharts config={this.config()} options={options}/>
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}