import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPageTitle from "../layout/FooterPageTitle";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"


class ChineseGeneralDisclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
      <div>
        <FooterPageTitle title="免责声明" />
        <Container>
          <Row />
          <Row className="terms-top-paragraph">
            <Col className="terms-col" sm={12}>
              <h3 className="terms-title">交易和投资的一般风险</h3>
            </Col>
            <p className="terms-text">
              阅读并充分理解以下交易和投资风险对您至关重要：
            </p>
            <p className="terms-text">
              所有证券交易，无论是股票交易还是其他投资工具，都具有投机性质，并且存在重大损失风险。
              我们鼓励我们的用户进行认真投资，并利用
              <a className="terms-link" href="http://www.sec.gov">
                美国证券交易委员会网站
              </a>
              和
              <a className="terms-link" href="http://www.nasd.com">
                美国证券交易商协会网站
              </a>
              提供的信息。 NASD已经发布了有关如何在其网站上进行认真投资的信息。
              我们还鼓励您从您的专业投资顾问那里获得个人建议，并在采取行动之前进行独立调查。
              我们不以任何方式保证或保证您依赖我们的信息采取任何行动是否获得成功。
            </p>
            <p className="terms-text">
              过往表现并不一定表示未来结果。
              所有投资都有风险，个人的所有交易决策仍由该个人负责。
              无法保证系统，指标或交易信号将导致利润或不会导致损失。
              建议所有投资者充分了解与他们选择进行的任何交易或投资相关的所有风险。
            </p>
            <p className="terms-text">
              假设或模拟的表现并不代表未来的结果。除非另有特别说明，
              我们网站和出版物中提供的所有利润示例均基于假设或模拟交易，
              这意味着它们是在纸质上或电子方式基于实际市场价格在推荐发布给此服务的订阅者时完成的，
              但没有实际的资金投入。此外，这些示例不包括订阅，佣金和其他费用的成本，或者其他建议的示例，
              其中存在利用推荐时的定时的损失。由于这些例子的交易实际上并未执行，
              结果可能低估或夸大某些市场因素的影响，例如缺乏流动性（下文讨论）。
              一般而言，模拟交易程序的设计也具有后见之明的优点，这可能与实际交易无关。
              我们不作任何陈述或保证任何账户将或可能获得与所示利润相似的利润，
              因为假设或模拟的表现不一定表示未来的结果。
            </p>
            <p className="terms-text">
              AllocateRite根据我们的建议发布投资组合。由于多种原因，您的实际结果可能与报告的构建投资组合的结果不同，包括但不限于：
              （i）构建的投资组合的业绩结果不反映您可能产生的实际交易佣金;
              （ii）已构建投资组合的表现结果并未考虑可能影响结果的若干市场因素（例如缺乏流动资金）的影响;
              （iii）为构建的投资组合选择的交易所交易基金可能是不稳定的，尽管在确认重新平衡警报已发送之前，构建的投资组合中的证券的“购买”或“出售”将不会在构建的投资组合中生效对于订阅者，轻微的传递延迟和其他因素可能导致您获得的价格可能与重新平衡警报发出时的价格产生重大差异。过去的结果并不代表未来的表现/结果。
            </p>
            <p className="terms-text">
              投资涉及重大风险。
              对于使用投资信息可能获得的任何结果，我们不作任何保证或其他承诺。
              虽然可以分析过去的表现，但过去的表现不应被视为未来表现的指示。
              未经咨询自己的个人财务顾问并进行自己的研究和尽职调查，包括仔细审阅招股说明书和其他公开文件，任何订户都不应作出任何投资决定。
              在法律允许的最大范围内，如果网站中包含的任何信息，评论，分析，意见，建议和/或建议被证明不准确，不完整或不可靠，或者导致，则AllocateRite及其关联公司不承担任何和所有责任。
              任何投资或其他损失。
            </p>
          </Row>
          <Row>
            <p className="terms-bottom-paragraph">
              订阅者可以直接通过网站向AllocateRite反映问题。
            </p>
          </Row>
        </Container>
        <ChineseFooter />
      </div>
      </div>
    );
  }
}

export default ChineseGeneralDisclaimer;
