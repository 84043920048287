import React, { Component } from 'react'
import ENDPOINT from '../Endpoint'
import '../../scss/pages/Dashboard.scss'
import BalanceHistoryGraph from '../components/BalanceHistoryGraph'
import AllocationPieChart from '../components/AllocationPieChart'
import TotalInvestments from './TotalInvestments'
import i18n from 'i18next'
import { Checkbox } from 'primereact/checkbox'
import CategorizedInvestments from './CategorizedInvestments'
import { FaBars, FaSignOutAlt, FaUserCircle, FaBell } from 'react-icons/fa'
import { Dropdown } from 'primereact/dropdown'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import Notifications from '../Notifications'
import NotificationIcon from '../../assets/notifications_icon.svg'
import NotificationIconActive from '../../assets/notifications_icon_active.svg'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import ApexKYC from '../ApexKYC'
import DwKYC from '../DwKYC'
import { NavLink } from 'react-router-dom'

export default class Dashboard extends Component {
  state = {
    filteredAccounts: null,
    portfolioAmount: 0,
    totalInvestments: 0,
    totalNetChange: 0,
    allocations: null,
    selectedTab: 'STOCKS',
    stockHoldings: null,
    chinese: i18n.language === 'zh',
    monthData: {},
    ytdData: {},
    yearData: {},
    totalData: {},
    selectedGraphData: {},
    graphTab: '',
    showNotif: false,
    language: i18n.language === 'zh' ? 'zh' : 'eng',
    showNotif: false,
    notifs: [],
    unresolved: 0,
    showApexKYC:
      this.props.dwKYCstatus === 'approved' &&
      this.props.apexKYCunapprovedIds &&
      this.props.apexKYCunapprovedIds.length > 0,
    showDwKYC: this.props.dwKYCstatus === 'not approved',
  }

  componentDidMount() {
    this.combineAccounts()
    this.getBalanceHistory()
    this.filterAllocations()
    this.getStockHoldings()
    // this.filterHoldings();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.dwKYCapproved != this.props.dwKYCapproved ||
      prevProps.apexKYCunapprovedIds != this.props.apexKYCunapprovedIds
    ) {
      this.setState({
        showApexKYC:
          this.props.dwKYCapproved == null &&
          this.props.apexKYCunapprovedIds &&
          this.props.apexKYCunapprovedIds.length > 0,
        showDwKYC: this.props.dwKYCapproved != null,
      })
    }
  }

  //NOTIFICATIONS
  toggleShowNotif = () => {
    if (!this.state.showNotif) {
      this.resolveAllNotif()
    }
    this.setState({ showNotif: !this.state.showNotif })
  }

  getNotifcations = () => {
    // console.log('----------getting notifs----------')
    let url = `${ENDPOINT}/users/notification`
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        // console.log('notif resp',responseJson)
        let notifs = responseJson.payload.notifications
        let unresolved = 0
        notifs.forEach((n) => {
          if (!n.resolved) {
            // console.log('unresolved', n)
            unresolved++
          }
        })
        this.setState({ notifs, unresolved })
        setTimeout(() => this.getNotifcations(), 150000)
        // this.startTimer()
      })
      .catch((err) => console.log('notif catch', err))
  }

  resolveAllNotif = () => {
    // if (this.state.resolved) {
    //   this.setState({ showNotif: true, resolved: true });
    // } else {
    //   this.setState({ showNotif: true, resolved: true });

    //   fetch(`${ENDPOINT}/users/notification/all/resolve`, {
    //     headers: {
    //       Authorization: "Bearer " + this.props.user.idToken.jwtToken,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then((resp) => resp.json())
    //     .then((responseJson) => {
    //       console.log("resolve resp", responseJson);
    //     });
    // }
    fetch(`${ENDPOINT}/users/notification/all/resolve`, {
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.log(err)
      })
      .then((resp) => resp.json())
      .then((responseJson) => {
        console.log('resolve resp', responseJson)
      })
    this.setState({ unresolved: 0 })
  }

  getBalanceHistory = () => {
    fetch(`${ENDPOINT}/mobile/balance/history`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        let monthData = []
        let yearData = []
        let ytdData = []
        let totalData = []
        let monthMin = Number.MAX_VALUE,
          ytdMin = Number.MAX_VALUE,
          yearMin = Number.MAX_VALUE,
          totalMin = Number.MAX_VALUE
        let monthMax = Number.MIN_VALUE,
          ytdMax = Number.MIN_VALUE,
          yearMax = Number.MIN_VALUE,
          totalMax = Number.MIN_VALUE
        let currentDate = new Date()
        let monthDate = currentDate - 1000 * 60 * 60 * 24 * 30
        let yearDate = currentDate - 1000 * 60 * 60 * 24 * 30 * 12
        let ytdDate = new Date(currentDate.getFullYear(), 0, 1).getTime()
        if (responseJson.data) {
          let startDate = JSON.parse(responseJson.data)[0].date
          if (yearDate > startDate) {
            //DO ALL
            console.log('all')
            JSON.parse(responseJson.data).forEach((holding) => {
              if (holding.date > yearDate) {
                //YEAR

                yearData.push({
                  x: moment.unix(holding.date / 1000).format('MMM DD'),
                  'Portfolio Amount': holding.current,
                })
                yearMin = Math.min(yearMin, holding.current)
                yearMax = Math.max(yearMax, holding.current)
              }
              if (holding.date > ytdDate) {
                //YTD

                ytdData.push({
                  x: moment.unix(holding.date / 1000).format('MMM DD'),
                  'Portfolio Amount': holding.current,
                })
                ytdMin = Math.min(ytdMin, holding.current)
                ytdMax = Math.max(ytdMax, holding.current)
              }
              if (holding.date > monthDate) {
                // MONTH

                monthData.push({
                  x: moment.unix(holding.date / 1000).format('MMM DD'),
                  'Portfolio Amount': holding.current,
                })
                monthMin = Math.min(monthMin, holding.current)
                monthMax = Math.max(monthMax, holding.current)
              }
              //ALL

              totalData.push({
                x: moment.unix(holding.date / 1000).format('MMM DD'),
                'Portfolio Amount': holding.current,
              })
              totalMin = Math.min(totalMin, holding.current)
              totalMax = Math.max(totalMax, holding.current)
            })
          } else if (monthDate > startDate) {
            //EXCLUDE YEAR
            JSON.parse(responseJson.data).forEach((holding) => {
              if (holding.date > monthDate) {
                //MONTH
                monthData.push({
                  x: moment.unix(holding.date / 1000).format('MMM DD'),
                  'Portfolio Amount': holding.current,
                })
                monthMin = Math.min(monthMin, holding.current)
                monthMax = Math.max(monthMax, holding.current)
              }

              //YTD
              ytdData.push({
                x: moment.unix(holding.date / 1000).format('MMM DD'),
                'Portfolio Amount': holding.current,
              })
              ytdMin = Math.min(ytdMin, holding.current)
              ytdMax = Math.max(ytdMax, holding.current)

              //ALL
              totalData.push({
                x: moment.unix(holding.date / 1000).format('MMM DD'),
                'Portfolio Amount': holding.current,
              })
              totalMin = Math.min(totalMin, holding.current)
              totalMax = Math.max(totalMax, holding.current)
            })
          } else {
            console.log('only all')
            JSON.parse(responseJson.data).forEach((holding) => {
              //EXCLUDE YEAR AND MONTH

              //YTD
              ytdData.push({
                x: moment.unix(holding.date / 1000).format('MMM DD'),
                'Portfolio Amount': holding.current,
              })
              ytdMin = Math.min(ytdMin, holding.current)
              ytdMax = Math.max(ytdMax, holding.current)
              //ALL
              totalData.push({
                x: moment.unix(holding.date / 1000).format('MMM DD'),
                'Portfolio Amount': holding.current,
              })
              totalMin = Math.min(totalMin, holding.current)
              totalMax = Math.max(totalMax, holding.current)
            })
          }
          let selectedGraphData = []
          let graphTab = ''
          if (monthData?.length === 1) monthData.push(monthData[0])
          if (ytdData?.length === 1) ytdData.push(ytdData[0])
          if (yearData?.length === 1) yearData.push(yearData[0])
          if (totalData?.length === 1) totalData.push(totalData[0])
          selectedGraphData = {
            data: [...totalData],
            min: totalMin,
            max: totalMax,
          }
          graphTab = 'ALL'
          this.setState({
            monthData: {
              data: monthData,
              min: monthMin,
              max: monthMax,
            },
            ytdData: {
              data: ytdData,
              min: ytdMin,
              max: ytdMax,
            },
            yearData: {
              data: yearData,
              min: yearMin,
              max: yearMax,
            },
            totalData: {
              data: totalData,
              min: totalMin,
              max: totalMax,
            },
            selectedGraphData: selectedGraphData,
            graphTab: graphTab,
          })
        } else {
          this.setEmptyGraphData()
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setEmptyGraphData()
      })
  }

  getStockHoldings() {
    let stockHoldings = []
    this.props.accounts.forEach((account) => {
      if (account.product_assigned === 'equity') {
        this.setState({ stockHoldings: account.holdings })
        return
      }
    })
  }

  filterAllocations() {
    if (this.props.allocations) {
      let allocations = []
      const cash = this.props.allocations.Cash
      const crypto = this.props.allocations.Crypto
      const equity = this.props.allocations.Equity
      const fixedIncome = this.props.allocations['Fixed Income']
      const other = this.props.allocations.Other
      if (cash && cash.ratio > 0)
        allocations.push({
          value: parseFloat((cash.ratio * 100).toFixed(2)),
          name: this.props.t('cash'),
        })
      if (crypto && crypto.ratio > 0)
        allocations.push({
          value: parseFloat((crypto.ratio * 100).toFixed(2)),
          name: this.props.t('crypto'),
        })
      if (equity && equity.ratio > 0)
        allocations.push({
          value: parseFloat((equity.ratio * 100).toFixed(2)),
          name: this.props.t('equity'),
        })
      if (fixedIncome && fixedIncome.ratio > 0)
        allocations.push({
          value: parseFloat((fixedIncome.ratio * 100).toFixed(2)),
          name: this.props.t('fixedincome'),
        })
      if (other && other.ratio > 0)
        allocations.push({
          value: parseFloat((other.ratio * 100).toFixed(2)),
          name: this.props.t('other'),
        })
      this.setState({
        allocations: allocations,
      })
    }
  }

  setEmptyGraphData = () => {
    this.setState({
      graphTab: 'ALL',
      selectedGraphData: {
        data: [
          {
            x: moment.unix(new Date() / 1000).format('MMM DD'),
            'Portfolio Amount': 0,
          },
          {
            x: moment.unix(new Date() / 1000).format('MMM DD'),
            'Portfolio Amount': 0,
          },
        ],
        min: 0,
        max: 0,
      },
      totalData: {
        data: [
          {
            x: moment.unix(new Date() / 1000).format('MMM DD'),
            'Portfolio Amount': 0,
          },
          {
            x: moment.unix(new Date() / 1000).format('MMM DD'),
            'Portfolio Amount': 0,
          },
        ],
      },
      min: 0,
      max: 0,
    })
  }

  // filterHoldings() {
  //   if (this.props.holdingsMerged) {
  //     let stockHoldings = [];
  //     let cryptoHoldings = [];
  //     this.props.holdingsMerged.forEach((holding) => {
  //       if (holding.type === "Equity") stockHoldings.push(holding);
  //       else if (holding.type === "Crypto") cryptoHoldings.push(holding);
  //     });
  //     this.setState({
  //       stockHoldings: stockHoldings,
  //       cryptoHoldings: cryptoHoldings,
  //     });
  //   }
  // }

  combineAccounts() {
    let filteredAccounts = []
    let portfolioAmount = 0
    let totalInvestments = 0
    let totalNetChange = 0
    this.props.accounts.forEach((account) => {
      if (
        (account && account.inst === 'Apex') ||
        account.inst === 'DriveWealth'
      ) {
        filteredAccounts.push(account)
        portfolioAmount += account.balances[0].virtualAccountValue
        totalInvestments += account.balances[0].equityValue
        totalNetChange += account.balances[0]['profit-loss']
          ? account.balances[0]['profit-loss']
          : 0
      }
    })
    this.setState({
      filteredAccounts: filteredAccounts,
      portfolioAmount: portfolioAmount,
      totalInvestments: totalInvestments,
      totalNetChange: totalNetChange,
    })
  }

  displayHeading() {
    return (
      <div className='heading-container'>
        <p className='header'>{this.props.t('portfolioamount')}</p>
        <p className='portfolio-amount'>
          {this.props.formatCurrency(this.state.portfolioAmount)}
        </p>
      </div>
    )
  }

  changePortfolioAmount = (value) => {
    this.setState({ portfolioAmount: value })
  }

  displayInvestmentAndNetChange() {
    return (
      <div className='investment-net-container'>
        <div className='investment-net-row'>
          <p className='label'>{this.props.t('investments')}</p>
          <p className='value'>
            {this.props.formatCurrency(this.props.totalInvestment)}
          </p>
        </div>
        <div className='investment-net-row'>
          <p className='label'>{this.props.t('gainloss')}</p>
          <p
            className={`value ${
              this.state.totalNetChange < 0 ? 'negative' : ''
            }`}
          >
            {this.props.formatCurrency(this.state.totalNetChange)}
          </p>
        </div>
      </div>
    )
  }

  displayPortfolioAllocations() {
    return (
      <div className='portfolio-allocations-container'>
        <p className='header'>{this.props.t('portfolioallocations')}</p>
        <div className='portfolio-allocations-chart-container'>
          <AllocationPieChart
            seriesData={this.state.allocations}
            t={this.props.t}
            COLORS={[
              '#3627E4',
              'rgba(123, 97, 255, .61)',
              '#7B61FF',
              '#474280',
            ]}
            isSmallScreen={this.props.isSmallScreen}
            verticalAlign={true}
          />
        </div>
      </div>
    )
  }

  displayMyInvestments() {
    return (
      <div className='my-investments-container'>
        <p className='header'>{this.props.t('myinvestments')}</p>
        <div className='holdings-data'>
          <CategorizedInvestments
            accounts={this.props.accounts}
            formatCurrency={this.props.formatCurrency}
            isSmallScreen={this.props.isSmallScreen}
            t={this.props.t}
          />
        </div>
        {/* {this.props.userType === "normal" ? (
          <div className="holdings-data">
            <CategorizedInvestments
              accounts={this.props.accounts}
              formatCurrency={this.props.formatCurrency}
              isSmallScreen={this.props.isSmallScreen}
              t={this.props.t}
            />
          </div>
        ) : this.state.stockHoldings ? (
          <div className="holdings-data">
            <TotalInvestments
              holdings={this.state.stockHoldings}
              formatCurrency={this.props.formatCurrency}
              isSmallScreen={this.props.isSmallScreen}
              t={this.props.t}
            />
          </div>
        ) : null} */}
      </div>
    )
  }

  tabs() {
    let selectedTab = {
      cursor: 'pointer',
      alignItems: 'center',
      marginRight: '15px',
      width: '180px',
      height: '37px',
      background: '#F6F6F6',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), -3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
      borderRadius: '19px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    let selectedText = {
      fontFamily: 'Akkurat-Bold',
      fontSize: '16px',
      textAlign: 'center',
      color: '#272748',
      margin: '0',
    }
    let unSelectedTab = {
      cursor: 'pointer',
      color: 'grey',
      marginRight: '15px',
    }
    let unSelectedText = {
      fontWeight: 'bold',
      fontSize: '25px',
      textAlign: 'center',
      color: '#272748',
    }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10%',
        }}
      >
        <div
          onClick={() => this.setState({ selectedTab: 'STOCKS' })}
          style={
            this.state.selectedTab === 'STOCKS' ? selectedTab : unSelectedTab
          }
        >
          <p
            style={
              this.state.selectedTab === 'STOCKS'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('stocks')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ selectedTab: 'CRYPTO' })}
          style={
            this.state.selectedTab === 'CRYPTO' ? selectedTab : unSelectedTab
          }
        >
          <p
            style={
              this.state.selectedTab === 'CRYPTO'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('crypto')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab() {
    if (this.state.selectedTab === 'STOCKS') {
      return (
        <div className='holdings-data'>
          <TotalInvestments
            holdings={this.state.stockHoldings}
            formatCurrency={this.props.formatCurrency}
            isSmallScreen={this.props.isSmallScreen}
            t={this.props.t}
          />
        </div>
      )
    } else {
      return (
        <div className='holdings-data'>
          <TotalInvestments
            holdings={this.state.cryptoHoldings}
            formatCurrency={this.props.formatCurrency}
            isSmallScreen={this.props.isSmallScreen}
            t={this.props.t}
          />
        </div>
      )
    }
  }

  changeLanguage = (value) => {
    // this.setState({ chinese: value });
    this.setState({ language: value })

    if (i18n.language === 'zh') {
      i18n.changeLanguage('en')
      this.changeLanguageAPI('en')
    } else {
      i18n.changeLanguage('zh')
      this.changeLanguageAPI('zh')
    }
  }

  changeLanguageAPI = async (language) => {
    Axios.get(`${Endpoint}/mobile/get-email?lang=${language}`, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res) {
          console.log('apiapi', res)
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handleGraphChange = (tab, data) => {
    this.setState({ selectedGraphData: data, graphTab: tab })
  }

  graphTabs() {
    return (
      <div className='graph-tabs'>
        {this.state.monthData?.data?.length > 0 ? (
          <p
            className={`graph-tab ${
              this.state.graphTab === '1M' ? 'selected' : ''
            }`}
            onClick={() => {
              this.handleGraphChange('1M', this.state.monthData)
            }}
          >
            {this.props.t('onemonth')}
          </p>
        ) : null}
        {this.state.ytdData?.data?.length > 0 ? (
          <p
            className={`graph-tab ${
              this.state.graphTab === 'YTD' ? 'selected' : ''
            }`}
            onClick={() => {
              this.handleGraphChange('YTD', this.state.ytdData)
            }}
          >
            {this.props.t('ytd')}
          </p>
        ) : null}
        {this.state.yearData?.data?.length > 0 ? (
          <p
            className={`graph-tab ${
              this.state.graphTab === 'YEAR' ? 'selected' : ''
            }`}
            onClick={() => {
              this.handleGraphChange('1Y', this.state.yearData)
            }}
          >
            {this.props.t('oneyear')}
          </p>
        ) : null}
        <p
          className={`graph-tab ${
            this.state.graphTab === 'ALL' ? 'selected' : ''
          }`}
          onClick={() => {
            this.handleGraphChange('ALL', this.state.totalData)
          }}
        >
          {this.props.t('all')}
        </p>
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div className='dashboard'>
          <div className='notification-toggle-container'>
            {this.state.unresolved === 0 ? (
              <div
                className='active-notif-icon-container'
                onClick={() => {
                  this.toggleShowNotif()
                }}
              >
                {' '}
                <img src={NotificationIcon} height={20} width={20} />
              </div>
            ) : (
              <div
                className='active-notif-icon-container'
                onClick={() => {
                  this.toggleShowNotif()
                }}
              >
                <div className='count-container'>{this.state.unresolved}</div>
                <img src={NotificationIconActive} height={20} width={20} />
              </div>
            )}
          </div>
          {this.state.showNotif ? (
            <div
              className='notification-container'
              onClick={() => this.setState({ showNotif: false })}
            >
              <div className='notifications'>
                <Notifications notifs={this.state.notifs} t={this.props.t} />
              </div>
            </div>
          ) : null}
          {/* {this.props.dwKYCpending ? (
            <p
              className='kyc-msg'
            >
              Account is under pending status.
            </p>
          ) : (
            <>
              {this.props.dwKYCapproved ? (
                <p
                  className='kyc-msg'
                  onClick={() => {
                    this.setState({ showDwKYC: true })
                  }}
                >
                  Account is pending approval. Please click here to proceed
                </p>
              ) : this.props.apexKYCunapprovedIds &&
                this.props.apexKYCunapprovedIds.length > 0 ? (
                <p
                  className='kyc-msg'
                  onClick={() => {
                    this.setState({ showApexKYC: true })
                  }}
                >
                  {
                    this.props.apexKYCunapprovedIds[
                      this.props.apexKYCunapprovedIds.length - 1
                    ]?.message
                  }
                </p>
              ) : null}
            </>
          )} */}

          {this.props.dwKYCstatus === 'pending' ||
          this.props.dwKYCstatus === 'not approved' ? (
            <p
              className='kyc-msg'
              onClick={() => {
                this.setState({ showDwKYC: true })
              }}
            >
              {this.props.t('pleaseclickheretoverifykyc')}
            </p>
          ) : this.props.apexKYCunapprovedIds?.length > 0 ? (
            <p
              className='kyc-msg'
              onClick={() => {
                this.setState({ showApexKYC: true })
              }}
            >
              {this.props.apexKYCunapprovedIds[0]?.message}
            </p>
          ) : null}

          {this.props.unlinkedAccounts > 0 && (
            <NavLink
              exact={true}
              to='/Dashboard/Account'
              style={{ color: 'black' }}
              onClick={() => {
                this.props.changeDefaultAccountTab('link')
              }}
            >
              <p className='kyc-msg'>
                {this.props.unlinkedAccounts === 1
                  ? this.props.t('oneofyouraccounthasbeenunlinked')
                  : this.props.t('someofyouraccountshavebeenunlinked')}
              </p>
            </NavLink>
          )}
          <p className='dashboard-header'>{this.props.t('portfoliodetails')}</p>
          <div className='top-row'>
            <div className='left-col'>
              {' '}
              <div className='portfolio-details'>
                {this.state.filteredAccounts ? this.displayHeading() : null}
                {this.state.filteredAccounts
                  ? this.displayInvestmentAndNetChange()
                  : null}
              </div>
              <div className='balance-history-graph-container'>
                <div className='graph-container'>
                  <BalanceHistoryGraph
                    seriesData={this.state.selectedGraphData.data}
                    formatCurrency={this.props.formatCurrency}
                    xLabel={'x'}
                    yLabel={'Portfolio Amount'}
                    t={this.props.t}
                  />
                </div>
                {this.graphTabs()}
              </div>
            </div>
            <div className='right-col'>
              {this.state.allocations
                ? this.displayPortfolioAllocations()
                : null}
            </div>
          </div>
          <div className='bottom-row'>{this.displayMyInvestments()}</div>
          <Dialog
            visible={this.state.showApexKYC}
            header={
              <p style={{ marginLeft: '40px' }}>
                {this.props.t('kycverification')}
              </p>
            }
            onHide={() => this.setState({ showApexKYC: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          >
            <ApexKYC
              user={this.props.user}
              apexKYCunapprovedIds={this.props.apexKYCunapprovedIds}
              getAllAccountInfoV2={this.props.getAllAccountInfoV2}
              t={this.props.t}
            />
          </Dialog>
          <Dialog
            visible={this.state.showDwKYC}
            header={
              <p style={{ marginLeft: '40px' }}>
                {this.props.t('kycverification')}
              </p>
            }
            onHide={() => this.setState({ showDwKYC: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          >
            <DwKYC
              user={this.props.user}
              getAllAccountInfoV2={this.props.getAllAccountInfoV2}
              dwKYCstatus={this.props.dwKYCstatus}
              dwKYCid={this.props.dwKYCid}
              t={this.props.t}
            />
          </Dialog>
        </div>
      )
    } else return null
  }
}

// const mapStateToProps = (state) => {
//   return {
//     accounts: state.account.accounts
//   };
// };

// export default connect(mapStateToProps, actions)(Dashboard);
