import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/goals/GoalResults.scss'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import NextArrow from '../../../../../assets/next-arrow.svg'
import { InputText } from 'primereact/inputtext'
export default class GoalResults extends Component {
  state = {
    strategies: [],
  }

  componentDidMount() {
    this.getStrategies()
  }
  getStrategies = () => {
    let strategies = []
    if (this.props.strategyInfo) {
      Object.keys(this.props.strategyInfo).forEach((key) => {
        if (
          this.props.strategyInfo[key]?.type?.includes(
            this.props.selectedGoal.toLowerCase()
          )
        ) {
          strategies.push({
            label: this.props.strategyInfo[key]?.fullname,
            value: key,
          })
        }
      })
    }
    if (strategies.length > 0)
      this.props.handleStrategyChange(strategies[0].value)
    this.setState({ strategies: strategies })
  }

  returnInfo() {
    return (
      <div className='return-info-container'>
        {/* <Dropdown
          value={this.props.selectedStrategy}
          options={this.state.strategies}
          onChange={(e) => this.props.handleStrategyChange(e.value)}
          placeholder="Choose a strategy"
        /> */}
        <InputText
          value={this.props.strategyInfo[this.props.selectedStrategy]?.fullname}
          disabled
        />
        <div className='return-info'>
          <p className='return-label'>{this.props.t('return')}: </p>
          <p className='return-value'>
            {(
              this.props.strategyInfo[this.props.selectedStrategy]?.return * 100
            ).toFixed(2)}
            %
          </p>
        </div>
      </div>
    )
  }
  strategyDescription() {
    return (
      <div className='strategy-description-container'>
        <p className='description'>
          {/* {this.props.selectedStrategy?.description} */}
          {this.props.strategyInfo[this.props.selectedStrategy]?.description}
        </p>
      </div>
    )
  }
  render() {
    if (this.props.strategyInfo && this.props.t) {
      return (
        <div className='goal-results-container'>
          <p className='header'>{this.props.t('investmentforyourgoal')}</p>
          {this.returnInfo()}
          {this.strategyDescription()}
          <div className='button-container'>
            <Button
              className='submit-button'
              label={
                <div className='next-button-container'>
                  <p className='next-text'>{this.props.t('submit')}</p>
                  <img src={NextArrow} width={15} height={15} />
                </div>
              }
              onClick={() => {
                this.props.addHistory('Goal Summary')
              }}
            />
          </div>
        </div>
      )
    } else return null
  }
}
