//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import RightArrow from '../../assets/right-arrow.png'
import DownArrow from '../../assets/down-arrow.png'
import PlusIcon from '../../assets/plus.svg'

//STYLES
import '../../scss/components/budgeting/ExternalAccountsCard.scss'

export default function ExternalAccountsCard() {
  const {
    t,
    budgetingData,
    showExternalAccounts,
    isSmallScreen,
    setAddAccountDialog,
  } = useContext(BudgetingContext)

  return (
    <div className='shadow-card'>
      <div className='external-accounts-card'>
        <div className='external-accounts-row'>
          <div className='external-accounts-group'>
            <p className='external-accounts-title'>{t('external_accounts')}</p>
            <img
              onClick={(e) => {
                e.stopPropagation()
                setAddAccountDialog(true)
              }}
              src={PlusIcon}
              className='plus-icon'
            />
          </div>
          {showExternalAccounts ? (
            <img src={DownArrow} className='external-accounts-down-arrow' />
          ) : (
            <img src={RightArrow} className='external-accounts-right-arrow' />
          )}
        </div>
      </div>
    </div>
  )
}
