import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FooterPageTitle from '../layout/FooterPageTitle'
import { FaPrint } from 'react-icons/fa'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'
import MainNavbar from '../components/MainNavBar'

export class Disclosures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSmallScreen: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  apexLinks() {
    return (
      <div>
        {/* <div style={{ paddingBottom: 10 }}>
          <a href='/CryptoAgreement' style={{ color: '#3F46F6', fontSize: 20 }}>
            Crypto Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/ApexAccountAgreement'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Account Agreement
          </a>
        </div> */}
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/apex-crypto-risk-disclosures.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Risk Disclosure Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/apex-crypto-user-agreement.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Crypto User Agreement
          </a>
        </div>
      </div>
    )
  }

  dwLinks() {
    return (
      <div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/disclosures-disclaimers'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Risk Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/bcp'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Business Continuity Plan
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/form-client-relationship-summary'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Customer Relationship Summary
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://static1.squarespace.com/static/5d84d0802787ab07bdc5bc7e/t/5ef5ff44e5a2240b8bcae99d/1593179972919/Form+CRD+6-2020++DriveWealth.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Potential Conflicts of Interest
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/customer-account-agreement'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Account Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/terms-of-use'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Terms of Use
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/funding-withdrawal-policies'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Funding and Withdrawal Policies
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/extended-hours-trading-disclosures'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Extended Hours Trading Disclosures
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/sec-rule-606'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            SEC Rule 606
          </a>
        </div>
        {/* <div style={{paddingBottom: 10}}>
                    <a href='' style={{color:'#3F46F6', fontSize:20}}>DriveWealth Clearing Brochure</a>
                </div> */}
      </div>
    )
  }

  govtLinks() {
    return (
      <div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='http://www.finra.org/industry/customer-identification-program-notice'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            FINRA Customer ID Program Notice
          </a>
        </div>
      </div>
    )
  }

  fsLinks() {
    return (
      <div style={{ paddingBottom: 20 }}>
        <div style={{ paddingBottom: 10 }}>
          {/* <a href='/PrivacyPolicy' style={{ color: '#3F46F6', fontSize: 20 }}>
            ForeSCITE Privacy Policy
          </a> */}
          <a
            href='/Legal/ForeSCITE-Privacy-Policy.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE {this.props.t('privacypolicy')}
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Terms-of-use.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            FORESCITE {this.props.t('termsofuse')}
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          {/* <a
            href='/GeneralDisclaimer'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE General Disclaimer
          </a> */}
          <a
            href='/Legal/General-Disclaimer.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE General Disclaimer
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          {/* <a href='/EULA' style={{ color: '#3F46F6', fontSize: 20 }}>
            ForeSCITE EULA
          </a> */}
          <a href='/Legal/EULA.pdf' style={{ color: '#3F46F6', fontSize: 20 }}>
            {this.props.t('eula')}
          </a>
        </div>
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Low-Priced-Securities-Disclosure'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Public Low Price Securities Disclosure
          </a>
        </div> */}
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-2021-ADV-Part2A.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE ADV Part 2
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-2021-CRS.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE CRS
          </a>
        </div>
        {/* <div style={{paddingBottom: 10}}>
                    <a href='/Legal/OpenToPublicBrokerageAgreement.pdf' style={{color:'#3F46F6', fontSize:20}}>Open to the Public Brokerage Agreement</a>
                </div> */}
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Annual-Disclosure-2021.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            2021 ForeSCITE Annual Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Customer-Relationship-Summary.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE Customer Information Brochure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Investment-Advisory-Agreement.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE Investment Advisory Agreement
          </a>
        </div>
      </div>
    )
  }

  faq() {
    return (
      <div style={{ paddingBottom: 20 }}>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/PrivacyPolicy'
            style={{ color: '#3F46F6' }}
          >
            What is ForeSCITE?
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/TermsofUse'
            style={{ color: '#3F46F6' }}
          >
            Who is DriveWealth
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/GeneralDisclaimer'
            style={{ color: '#3F46F6' }}
          >
            My accounts and subscription{' '}
          </a>
        </div>
      </div>
    )
  }

  render() {
    //   console.log('small screen?', this.state.isSmallScreen)
    if (this.props.t) {
      return (
        <div style={{ width: '100%' }}>
          {/* <Header
          isSmallScreen={this.state.isSmallScreen}
          user={this.props.user}
          signOut={this.props.signOut}
        /> */}
          <MainNavbar {...this.props} colorChange={true} />
          <div style={{ width: '100%' }}>
            {/* <FooterPageTitle title="ForeSCITE Legal" /> */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                backgroundColor: 'rgb(237,237,237)',
                marginTop: this.state.isSmallScreen ? '' : '75px',
                justifyContent: 'space-between',
                paddingLeft: '20%',
                paddingRight: '20%',
                paddingTop: 40,
                paddingBottom: 40,
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 40,
                  fontFamily: 'Akkurat-Bold',
                }}
              >
                Disclosures
              </p>
              {/* <FaPrint size={45}  style={{cursor:'pointer'}} onClick={()=>window.print()}/> */}
              <div />
            </div>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '50%', marginBottom: 50, marginTop: 30 }}>
                {/* <h2 style={{ textAlign: 'center', color: '#3f4174' }}>
                {' '}
                Disclosures
              </h2> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 20,
                  paddingLeft: '20%',
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    width: this.state.isSmallScreen ? '90%' : '40%',
                  }}
                >
                  <h4 style={{ fontFamily: 'Akkurat-Bold' }}>ForeSCITE</h4>
                  {this.fsLinks()}
                  <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Regulatory</h4>
                  {this.govtLinks()}
                  {this.state.isSmallScreen ? (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>DriveWealth</h4>
                  ) : null}
                  {this.state.isSmallScreen ? this.dwLinks() : null}
                  {this.state.isSmallScreen ? (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Apex</h4>
                  ) : null}
                  {this.state.isSmallScreen ? this.apexLinks() : null}
                </div>
                {this.state.isSmallScreen ? null : (
                  <div style={{ width: '40%' }}>
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>DriveWealth</h4>
                    {this.dwLinks()}
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Apex</h4>
                    {this.apexLinks()}
                  </div>
                )}
                {/* {this.state.isSmallScreen ? null : (
                <div style={{ width: '30%' }}>
                </div>
              )} */}
              </div>
            </div>
            <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
          </div>
        </div>
      )
    } else return null
  }
}

export default Disclosures
