import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import '../../scss/components/DepositAchDialog.scss'
import { Button } from 'primereact/button'

export default function DepositAchDialog(props) {
  const [amount, setAmount] = useState(0)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const deposit = async () => {
    setLoading(true)

    await Axios.post(
      `${Endpoint}/apex-crypto/ach-relationships/deposit?account_id=${props.selectedAccount.accountId}`,
      {
        memo: 'achdeposit',
        relationshipId: props.achAccount.id,
        amount: amount,
      },
      {
        headers: {
          Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then(async (response) => {
        if (response?.data?.status) {
          await props.getAllAccountInfo()
          setSuccessMsg(props.t('successfullydeposited'))
        } else {
          setErrorMsg(props.t('somethingwentwrong'))
        }
      })
      .catch((err) => {
        setErrorMsg(props.t('somethingwentwrong'))
      })
    setLoading(false)
  }

  const validateForm = () => {
    if (amount && amount > 0 && props.achAccount && props.selectedAccount) {
      deposit()
    } else {
      setErrorMsg(props.t('pleasecompleteallfields'))
    }
  }

  if (props.t) {
    return (
      <div className='deposit-ach-form'>
        <div className='ach-container'>
          <p className='ach-label'>{props.t('from')}:</p>
          <InputText
            className='ach-input'
            value={props.achAccount?.nickname}
            disabled
          />
        </div>
        {/* <div className='bank-btn-container'>
          {props.achAccount ? (
            <Button
              label='Edit Bank'
              onClick={() => {
                props.hideDepositAndShowForm()
              }}
              className='bank-btn'
            />
          ) : (
            <>
              {props.achPending ? (
                <Button
                  label='Verify Bank Account'
                  onClick={() => {
                    props.hideDepositAndShowForm()
                  }}
                  className='bank-btn'
                />
              ) : (
                <Button
                  label='Add Bank'
                  onClick={() => {
                    props.hideDepositAndShowForm()
                  }}
                  className='bank-btn'
                />
              )}
            </>
          )}
        </div> */}
        <div className='ach-container'>
          <p className='ach-label'>{props.t('to')}:</p>
          <InputText
            className='ach-input'
            value={props.selectedAccount?.itemId}
            disabled
          />
        </div>
        <div className='ach-container'>
          <p className='ach-label'>{props.t('amount')}:</p>
          <InputNumber
            className='ach-input-number'
            value={amount}
            onValueChange={(e) => setAmount(e.value)}
            mode='currency'
            currency='USD'
            locale='en-US'
            minFractionDigits={0}
            maxFractionDigits={2}
          />
        </div>
        <p className='succes-msg'>{successMsg}</p>
        <p className='error-msg'>{errorMsg}</p>
        <div className='btn-container'>
          <Button
            label={props.t('submit')}
            onClick={() => {
              validateForm()
            }}
            className='submit-btn'
            loading={loading}
          />
        </div>
      </div>
    )
  } else return null
}
