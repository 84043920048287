import React from 'react'
import '../../scss/pages/Timeout.scss'

export default function Timeout(props) {
  if (props.t) {
    return (
      <div className='timeout-page'>
        <p
          className='message'
          onClick={() => {
            window.location.replace('/')
          }}
        >
          {props.t('sessiontimeout')}
        </p>
      </div>
    )
  } else return null
}
