import React from 'react'
import ReactHighcharts from 'react-highcharts'
import { Card, Row, Button, } from "react-bootstrap";

// const ReactHighstock = require("react-highcharts/ReactHighstock");
const brandColor = "#00A99D"
const options = {
  global: {
    useUTC: false
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
    rangeSelectorZoom: ""
  }
}
export default class DoubleLine extends React.Component{

    config = ()=>{
    let {data, title} = this.props
    let optimized = [...data.new.map(ele => [(ele[0] * 1000), Math.abs(ele[1]*100)])]
    let old = [...data.old.map(ele => [(ele[0] * 1000), Math.abs(ele[1]*100)])]
    let pcntsOld = old.map(ele => ele[1])
    let pcntsNew = optimized.map(ele => ele[1])
    let pcnts = [...pcntsNew, ...pcntsOld]
    let maxVal = Math.max(...pcnts)
    let minVal = Math.min(...pcnts)
    let maxRange = maxVal + ((maxVal - minVal) * 0.02)
    let minRange = 0
        return {
          scrollbar: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          navigator: {
            enabled: false,
          },
          credits: {
              enabled: false,
            },
          chart: {
              zoomType: "x",
              height: 350,
              width: this.props.isSmallScreen ? 300:null
            },
          title: {
              text: "",
            },
          xAxis: {
              type: "datetime",
            },
          tooltip:{
              shared: true,
              split: false,
              valueSuffix: '%',
            },
          plotOptions:{
            line:{
              animation:false
            }
          },
          yAxis: {
            opposite:false,
              title: {
                text: title === 'stress' ?"Max Drawdown":'Returns',
              },
              maxZoom: 150,
              max: maxRange,
              min: minRange,
              labels: {
                format: "{value}%"
              }
            },
            legend: {
              enabled: false,
            },
            rangeSelector: {
              selected: 4,
              inputEnabled: false,
              buttonTheme: {
                visibility: "hidden",
              },
              labelStyle: {
                visibility: "hidden",
              },
            },
            series: [
              {
                name: "Before Optimatization",
                type: "line",
                yAxis: 0,
                data: old,
                enableMouseTracking: true,
                marker: {
                  radius: 5,
                },
                marker: {
                  radius: 5,
                },
                tooltip: {
                  valueDecimals: 2
                },
                threshold: null,
                color: "#242a38",
              },
              {
                name: "After Optimization",
                type: "line",
                yAxis: 0,
                data: optimized,
                enableMouseTracking: true,
                marker: {
                  radius: 5,
                },
                marker: {
                  radius: 5,
                },
                tooltip: {
                  valueDecimals: 2
                },
                threshold: null,
                color: '#3b8e8c',
              },
               
              ],
          }
    }
    render(){
        return(
            <div style={{width:'100%',}}>
                <ReactHighcharts config={this.config()} options={options}/>
            </div>
        )
    }


}