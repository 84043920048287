import React from 'react'
import '../../scss/pages/cp3/Events.scss'

export default function Events({ t, stockData }) {
  return (
    <div className='events-container'>
      {stockData?.newsData?.map((news, index) => {
        const date = news?.publishedDate?.split(' ')[0]
        const time = news?.publishedDate?.split(' ')[1].split(':')
        return (
          <a href={news?.url} target='_blank' key={index}>
            {/* <div className='news-link-container' key={index}>
              <p className='news-link'>{news?.title}</p>
              <p className='news-date'>{news?.publishedDate}</p>
            </div> */}
            <div className='news-link-row'>
              <p className='news-title'>{news?.title}</p>
              <p className='news-date'>{news?.publishedDate}</p>
            </div>
          </a>
        )
      })}
    </div>
  )
}
