import React from 'react'
import { Card, Row } from 'react-bootstrap'
import Header from '../layout/Header'
import SingleCompanyCard from '../singleCompanyCard'
import Footer from '../layout/Footer'
import { FaChevronDown } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import ENDPOINT from '../Endpoint'

export default class UserWatchList extends React.Component {
  state = {
    shownList: 'My Watchlist',
    myWatchlist: [],
    presetTickers: null,
    showListOptions: false,
    editMode: false,
  }

  componentDidMount() {
    if (this.props.user) {
      this.fetchWatchList()
    }
    document.title = `AllocateRite - Watchlist`
  }

  arrayToObject = (arr) => {
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].listTitle] = arr[i].tickers
    }
    return result
  }

  fetchWatchList = () => {
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let watchListData = responseJson.watchlist
        let predefinedLists = responseJson.predefinedLists
        let watchlistLastUpdated = new Date(
          responseJson.lastUpdated * 1000
        ).toString()
        let sortedPredefinedLists = this.arrayToObject(predefinedLists)
        this.setState({
          myWatchlist: watchListData,
          presetTickers: sortedPredefinedLists,
          watchlistLastUpdated,
        })
      })
      .catch((error) => console.log(error))
  }

  fetchPresetTickers = () => {
    let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ presetTickers: responseJson.lists })
      })
  }

  individuleCompanyCard = (data) => {
    return (
      <Row
        style={{
          padding: '2%',
          textDecoration: 'none',
        }}
      >
        {data.map((item, index) => {
          return (
            <SingleCompanyCard
              user={this.props.user}
              remove={this.remove}
              editMode={this.state.editMode}
              item={item}
              index={index}
            />
          )
        })}
      </Row>
    )
  }

  showTickers = () => {
    let data
    if (this.state.shownList === 'My Watchlist') {
      data = this.state.myWatchlist
    } else {
      data = this.state.presetTickers[this.state.shownList]
    }
    return (
      <Card
        style={{
          width: '90%',
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {this.state.shownList === 'My Watchlist' ? (
            <div style={{ width: '20%' }} />
          ) : null}
          <p
            style={{
              color: 'rgb(120,120,120)',
              margin: 0,
              marginTop: 5,
              fontWeight: 'bold',
            }}
          >
            select a ticker for more information
          </p>
          {this.state.shownList === 'My Watchlist' ? (
            <div
              style={{
                width: '20%',
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  backgroundColor: 'rgb(248,248,247)',
                  padding: 10,
                  borderRadius: 8,
                }}
                onClick={() =>
                  this.setState({ editMode: !this.state.editMode })
                }
              >
                Edit Watchlist
              </p>
            </div>
          ) : null}
        </div>
        {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
        <div>{this.individuleCompanyCard(data)}</div>
      </Card>
    )
  }

  showLists = () => {
    let categories = ['My Watchlist']
    Object.keys(this.state.presetTickers).forEach((ele) => categories.push(ele))
    categories = categories.filter((ele) => ele !== this.state.shownList)
    let mapped = categories.map((ele) => (
      <li
        onClick={() =>
          this.setState({
            shownList: ele,
            showListOptions: false,
            editMode: false,
          })
        }
        style={{
          cursor: 'pointer',
          fontWeight: 'bold',
          width: '100%',
          margin: 'auto',
          marginLeft: '15%',
        }}
      >
        {ele}
      </li>
    ))
    return (
      <ul
        style={{
          backgroundColor: 'rgb(248,248,250)',
          listStyleType: 'none',
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '20%',
          borderRadius: 12,
          marginTop: -15,
          zIndex: 100,
        }}
      >
        {mapped}
      </ul>
    )
  }
  remove = (symbol) => {
    let filtered = this.state.myWatchlist.filter((ele) => ele.symbol !== symbol)
    this.setState({ myWatchlist: filtered })
  }

  render() {
    if (this.props.user == null) {
      return (
        <div>
          <Header user={this.props.user} signOut={this.props.signOut} />
          <div>
            <div
              style={{
                marginTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NavLink
                to={'/Login'}
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  color: '#00A99D',
                  backgroundColor: 'rgb(247,247,247)',
                  borderRadius: 12,
                  padding: '10px 20px',
                }}
              >
                Please Login to see your watchlist
              </NavLink>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Header user={this.props.user} signOut={this.props.signOut} />
          <div>
            <div
              style={{
                marginTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                onClick={() =>
                  this.setState({
                    showListOptions: !this.state.showListOptions,
                  })
                }
                style={{
                  backgroundColor: 'rgb(248,248,250)',
                  display: 'flex',
                  justifyContent: 'space-around',
                  padding: '10px 25px',
                  borderRadius: 12,
                  width: '20%',
                  cursor: 'pointer',
                }}
              >
                <h4>{this.state.shownList}</h4>
                <div style={{ marginLeft: 5, marginTop: 3 }}>
                  <FaChevronDown size={25} />
                </div>
              </div>
            </div>
            <div
              style={{
                zIndex: 100,
                position: 'absolute',
                left: '40%',
                display: 'block',
                width: '100%',
              }}
            >
              {this.state.showListOptions ? this.showLists() : null}
            </div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.showTickers()}
            </div>
          </div>
          <div>
            <Footer t={this.props.t} />
          </div>
        </div>
      )
    }
  }
}
