//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//IMAGE
import BudgetingImage from '../../../assets/investall-budgeting.png'

export default function KnowWorth() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='grey-background'>
      <div className='default-container'>
        <div className='block'>
          <div className='phone-img-container-left'>
            <img src={BudgetingImage} className='phone-img' />
          </div>
        </div>
        <div className='block'>
          <p className='big-text light-text'>{t('know_your_net_worth')}</p>
          <p className='small-text'>
            {t('our_ai_aggregates_the_current_value_msg')}
          </p>
        </div>
      </div>
    </div>
  )
}
