import React, { Component } from "react";
import GaugeChart from "react-gauge-chart";
import { graphColor } from "../../../companyPage3/RiskForecastColor";

export default class NonProdRiskGraph extends Component {

  displayRiskGraph() {
    let riskNum = (
      Math.pow(Math.abs(this.props.showStats.data.maxDrawdownVal) / 100, 0.25) *
      Math.pow(Math.abs(this.props.showStats.data.CVar), 0.75)
    ).toFixed(2);
    let correlationWithSP = this.props.showStats.data.rSquared.toFixed(2);
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? "100%" : "30%",
          boxShadow: "0px 5px 15px 0px #c4c4c4",
          borderRadius: "25px",
          padding: "10px",
          display: "block",
        }}
      >
        <p style={{ fontWeight: "500", fontSize: "20px", textAlign: "center" }}>
          Account Risk
        </p>
        <div
          style={{
            width: "100%",
            display: "block",
            borderBottom: "1px solid grey",
          }}
        >
          <GaugeChart
            style={{ backgroundColor: "hsla(0, 100%, 90%, 0.0)" }}
            id="gauge-chart3"
            nrOfLevels={36}
            colors={graphColor}
            percent={riskNum}
            textColor="hsla(0, 100%, 90%, 0.0)"
            needleColor="#242a38"
            needleBaseColor="#242a38"
            animate={false}
            cornerRadius={8}
            formatTextValue={(value) => value + "%"}
            hideText={true}
          />
        </div>
        <div style={{ padding: "15px", borderBottom: "1px solid grey" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "0",
            }}
          >
            {(riskNum * 100).toFixed(0)}% (
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].virtualAccountValue *
                riskNum
            )}
            )
          </p>
          {(riskNum * 100).toFixed(0) <= 15 ? (
            <p
              style={{
                fontSize: "20px",
                color: "#0BDA51",
                marginBottom: "0",
              }}
            >
              Low Risk
            </p>
          ) : (
            <>
              {(riskNum * 100).toFixed(0) >= 25 ? (
                <p
                  style={{
                    fontSize: "20px",
                    color: "red",
                    marginBottom: "0",
                  }}
                >
                  High Risk
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "20px",
                    color: "yellow",
                    marginBottom: "0",
                  }}
                >
                  Medium Risk
                </p>
              )}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "15px 15px 5px 15px",
          }}
        >
          <div
            style={{
              background: "black",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "10px",
              padding: "10px",
              width: "40%",
            }}
          >
            <p style={{ fontSize: "10px", marginBottom: "0", width: "60%" }}>
              Correlation with S&amp;P
            </p>
            <p style={{ fontSize: "10px", marginBottom: "0" }}>
              {correlationWithSP}
            </p>
          </div>
          <div
            style={{
              background: "black",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "10px",
              padding: "10px",
              width: "40%",
            }}
          >
            <p style={{ fontSize: "10px", marginBottom: "0" }}>Holdings</p>
            <p style={{ fontSize: "10px", marginBottom: "0" }}>
              {this.props.selectedAccount.holdings.length}
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.displayRiskGraph()}</div>;
  }
}
