import React from 'react'

import EthIconPath from '../assets/eth-icon.svg'

export default function EthIcon({ width, height }) {
  return (
    <img
      src={EthIconPath}
      width={width}
      height={height}
      style={{ marginRight: 3 }}
    />
  )
}
