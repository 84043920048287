import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

class UniqueSellingProps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChinese: this.props.isChinese
    };
  }

  render() {
    return (
      <Container>
      <Container>
        <Row>
          <p className="usm-top-text">Choose which one is suitable for you</p>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={4} className="usm-col">
            <Container className="usm-col-container">
              <Container className="usm-holder">
                <Row>
                  <p className="usm-title">RiskMonkey Analytics</p>
                </Row>
                <Row className = "usm-benefits">
                <ul>
                  <li>Link multiple brokerage accounts</li>
                  <li className="usm-li-level2">
                    <ul>
                      <li>Get real-time portfolio risks</li>
                    </ul>
                  </li>
                  <li>Qualitative and quantitative analysis</li>
                  <li className="usm-li-level2">
                    <ul>
                      <li>Price momentum alerts</li>
                      <li>Underlying stock risk</li>
                    </ul>
                  </li>
                  <li>For <strong>ALL</strong></li>
                </ul>
                </Row>
                <Row className = "usp-button-holder">
                  <a className = "ar-button-a getAppUSP" href ="https://apps.apple.com/us/app/forescite/id1567589436"><button className="ar-button">Get the app</button></a>
                </Row>
              </Container>
            </Container>
          </Col>
          <Col md={4} className="usm-col mt-3 mt-md-0">
            <Container className="usm-col-container">
              <Container className="usm-holder">
                <Row>
                  <p className="usm-title">Wealth Management</p>
                </Row>
                <Row className = "usm-benefits">
                <ul>
                  <li>Strategies</li>
                  <li className="usm-li-level2">
                    <ul>
                      <li>Diversified and liquid</li>
                      <li>Risk-adjusted</li>
                      <li>Tax-efficient</li>
                      <li>Tax loss harvesting</li>
                    </ul>
                  </li>
                  <li>Automatic periodic rebalancing</li>
                  <li>Automated execution</li>
                  <li>Cash management built in</li>
                  <li>Individual <strong>401k</strong>, <strong>Retirement</strong>, <strong>Investment</strong></li>
                </ul>
                </Row>
                <Row className = "usp-button-holder">
                  <a className = "ar-button-a getAppUSP" href="https://apps.apple.com/us/app/forescite/id1567589436"><button className="ar-button">Get the app</button></a>
                </Row>
              </Container>
            </Container>
          </Col>
          <Col md={4} className="usm-col mt-3 mt-md-0">
            <Container className="usm-col-container">
              <Container className="usm-holder">
                <Row>
                  <p className="usm-title">Asset Management</p>
                </Row>
                <Row className = "usm-benefits">
                <ul>
                  <li>Alpha overlay over existing strategies</li>
                  <li className="usm-li-level2">
                    <ul>
                      <li>Diversified and liquid</li>
                      <li>Risk-adjusted</li>
                      <li>Tax-efficient</li>
                      <li>Tax loss harvesting</li>
                    </ul>
                  </li>
                  <li>Risk-adjust strategies</li>
                  <li>Automatic rebalancing and execution</li>
                  <li><strong>Pension Funds</strong></li>
                  <li><strong>College Endowments</strong></li>
                  <li><strong>Hedge Funds</strong></li>
                </ul>
                </Row>
                <Row className = "usp-button-holder">
                  <a className = "ar-button-a" href="https://docs.google.com/forms/d/e/1FAIpQLSf3rUsC53CITyFl7UpVF_Z7KaQt2lpOHMEQCNSPCjRk30ey6g/viewform?usp=sf_link"><button className="ar-button">Sign up for free</button></a>
                </Row>
              </Container>
            </Container>
          </Col>
        </Row>
        <Row>
          <p className = "ar-disclaimer-non-broker">AllocateRite is not a broker. It does not custody funds and does not exercise discretion. Users have absolute control of their accounts and the funds in them</p>
        </Row>
        <Row className="page-blank-row-md d-md-block d-none" />
        <Row className="page-blank-row-xs d-md-none d-block" />
      </Container>
      </Container>
    );
  }
}

export default UniqueSellingProps;
