import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class LineCharts extends Component {
  state = {
    isChinese: this.props.isChinese,
    lineCharts: [
      {
        id: 1,
        title: `2008 Market Crash *`,
        cnTitle: `2008年股灾 *`,
        imgUrl: `../images/tab-1-chartA.png`
      },
      {
        id: 2,
        title: `Brexit`,
        cnTitle: `英国脱欧`,
        imgUrl: `../images/tab-2-chartA.png`
      },
      {
        id: 3,
        title: `2016 Election`,
        cnTitle: `2016年选举`,
        imgUrl: `../images/tab-3-chartA.png`
      }
    ]
  };

  render() {
    return (
      <Container fluid className="linecharts-fluid-container">
        <Container>
          <Row>
            {this.state.lineCharts.map(lineChart => (
              <Col
                xs={12}
                sm={4}
                md={4}
                className="linecharts-single-chart"
                key={lineChart.id}
              >
                {this.props.isChinese ? (
                  <p className="linechart-title">{lineChart.cnTitle}</p>
                ) : (
                  <p className="linechart-title">{lineChart.title}</p>
                )}

                <img
                  src={lineChart.imgUrl}
                  alt="strategies-linechart"
                  width="90%"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    );
  }
}

export default LineCharts;
