import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../../aws-config'
import VerificationInput from 'react-verification-input'
import Spinner from 'react-bootstrap/Spinner'
import PhoneInput from 'react-phone-input-2'

import { IoIosClose } from 'react-icons/io'

import HeroPhone from '../../../assets/newLandingPage/hero_phone.svg'
import ChooseYourGoal from '../../../assets/newLandingPage/chooseYourGoal.svg'
import YearlyPerformance from '../../../assets/newLandingPage/TenYearPerformance.svg'
import BlackBlob from '../../../assets/newLandingPage/BlackBlob.svg'
import Modal from 'react-modal'

import Footer from '../../PublicFooter'
import MainNavbar from '../../components/MainNavBar'

Amplify.configure(awsExports)

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

export default class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      isSmallScreen: false,
      functionDropdown: false,
      tradeDropdown: false,
      cryptoDropdown: false,
      financialDropdown: false,
    }
    this.topOfPage = React.createRef()
    this.mainNavbarRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = () => {
    if (window.scrollY > window.screen.height - 100) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  handleNavigation = (link) => {
    document.body.style.overflow = 'unset'
    window.location.replace(link)
  }

  render() {
    if (this.props.t) {
      return (
        <>
          <MainNavbar
            ref={this.mainNavbarRef}
            {...this.props}
            colorChange={true}
          />
          {/* PAGE CONTENT START */}

          {this.state.isSmallScreen ? (
            <>
              <div
                className='info__container__mobile'
                style={{ paddingTop: '100px' }}
              >
                <p className='logo-text'>{this.props.t('investing')}</p>
                <p className='large-text'>
                  {this.props.t('institutionallevelroboinvesting')}
                </p>
                <ul>
                  <li>{this.props.t('roboinvestinginfo1')}</li>
                  <li>{this.props.t('roboinvestinginfo2')}</li>
                  <li>{this.props.t('roboinvestinginfo3')}</li>
                </ul>
                <div
                  className='default__button'
                  onClick={() =>
                    this.mainNavbarRef.current.setState({
                      showSignUpPopup: true,
                    })
                  }
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  {this.props.t('signup')}
                </div>
                <div className='image__container'>
                  <img src={HeroPhone} alt='' />
                </div>
              </div>
              <div className='info__container__mobile greenBackground'>
                <p className='logo-text'>{this.props.t('investing')}</p>
                <p className='large-text'>
                  {this.props.t('investingmadeeasy')}
                </p>
                <ul>
                  <li>{this.props.t('investingmadeeasyinfo1')}</li>
                  <li>{this.props.t('investingmadeeasyinfo2')}</li>
                  <li>{this.props.t('investingmadeeasyinfo3')}</li>
                </ul>
                <div
                  className='default__button'
                  onClick={() =>
                    this.mainNavbarRef.current({ showSignUpPopup: true })
                  }
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  {this.props.t('signup')}
                </div>
                <div className='image__container'>
                  <img src={YearlyPerformance} alt='' />
                </div>
              </div>
              <div className='info__container__mobile blackBackground'>
                <div className='image__container'>
                  <img src={BlackBlob} alt='' />
                </div>
                <p className='logo-text'>{this.props.t('aitrader')}</p>
                <p className='large-text'>{this.props.t('aitraderheader')}</p>
                <ul>
                  {/* <li>Pick up to four stocks in each trade group</li> */}
                  <li>{this.props.t('aitraderinfo1')}</li>
                  <li>{this.props.t('aitraderinfo2')}</li>
                  <li>{this.props.t('aitraderinfo3')}</li>
                  <li>{this.props.t('aitraderinfo4')}</li>
                </ul>
              </div>
              <div className='info__container__mobile blackBackground'>
                <div className='image__container smaller-img'>
                  <img src={ChooseYourGoal} alt='' />
                </div>
                <p className='logo-text'>{this.props.t('goals')}</p>
                <p className='large-text'>{this.props.t('goalsheader')}</p>
                <ul>
                  <li>{this.props.t('goalsinfo1')}</li>
                  <li>{this.props.t('goalsinfo2')}</li>
                  <li>{this.props.t('goalsinfo3')}</li>
                  <li>{this.props.t('goalsinfo4')}</li>
                </ul>
              </div>
              <div className='info__container__mobile greenBackground'>
                <p className='logo-text'>{this.props.t('Budgeting')}</p>
                <p className='large-text'>{this.props.t('budgetingheader')}</p>
                <ul>
                  <li>{this.props.t('budgetinginfo1')}</li>
                  <li>{this.props.t('budgetinginfo2')}</li>
                  <li>{this.props.t('budgetinginfo3')}</li>
                </ul>
                <div className='image__container'>
                  <img src={YearlyPerformance} alt='' />
                </div>
              </div>
              <div className='info__container__mobile'>
                <div className='text__container textOnly'>
                  <div className='wrapper__container'>
                    <p className='large-text bold-text'>
                      {this.props.t('freeversion')}
                    </p>
                    <p className='subtitle__text'>
                      {this.props.t('freeversioninfo')}
                    </p>
                  </div>
                </div>
                <div
                  className='text__container textOnly'
                  style={{ marginTop: 50 }}
                >
                  <div className='wrapper__container'>
                    <p className='large-text bold-text'>
                      {this.props.t('upgradetools')}
                    </p>
                    <p className='subtitle__text'>
                      {this.props.t('upgradetoolsinfo')}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='infoSection__reg green'>
                <div className='largeScreenWrapper__container' id='invest-robo'>
                  <div
                    className={
                      this.state.isSmallScreen
                        ? 'fullRow__container even'
                        : 'fullRow__container smallLeft'
                    }
                  >
                    <div className='image__container'>
                      <img src={HeroPhone} alt='' />
                    </div>
                    <div className='text__container'>
                      <div className='wrapper__container white'>
                        <p className='logo-text'>{this.props.t('investing')}</p>
                        <p className='large-text'>
                          {this.props.t('institutionallevelroboinvesting')}
                        </p>
                        <p>{this.props.t('producttabinfo')}</p>
                        <p>{this.props.t('dynamicassetmanagement')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='infoSection__reg'>
                <div className='largeScreenWrapper__container'>
                  <div className='title-container'>
                    <p className='logo-text'>{this.props.t('investrobo')}</p>
                    <p className='large-text'>
                      {this.props.t(
                        'whypickstockswhenyoucanletouraiinvestforyou'
                      )}
                    </p>
                  </div>
                  <div className='fullRow__container even'>
                    <div className='text__container textOnly'>
                      <div className='wrapper__container'>
                        <p className='subtitle__text'>
                          {this.props.t('trustedbythebigbanks')}
                        </p>
                        <p className='subtitle__text'>
                          {this.props.t('bigbanksinfo')}
                        </p>
                      </div>
                    </div>
                    <div className='text__container textOnly'>
                      <div className='wrapper__container'>
                        <p className='subtitle__text'>
                          {this.props.t('tailoredtoyourchoices')}
                        </p>
                        <p className='subtitle__text'>
                          {this.props.t('tailoredinfo')}
                        </p>
                      </div>
                    </div>
                    <div className='text__container textOnly'>
                      <div className='wrapper__container'>
                        <p className='subtitle__text'>
                          {this.props.t('highlyliquid')}
                        </p>
                        <p className='subtitle__text'>
                          {this.props.t('highlyinfo')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="infoSection__reg green" id="ai-trader">
              <div className="largeScreenWrapper__container">
                <div
                  className={
                    this.state.isSmallScreen
                      ? "fullRow__container even"
                      : "fullRow__container smallLeft"
                  }
                >
                  <div className="image__container">
                    <img src={YearlyPerformance} alt="" />
                  </div>
                  <div className="text__container">
                    <div className="wrapper__container white">
                      <p className="title__text">
                        Investing made easy, buy fractional shares with as
                        little as $1
                      </p>
                      <ul>
                        <li>
                          Invest commission-free in individual stocks or bundles
                          of investments (ETFs)
                        </li>
                        <li>
                          Use our price trends and 30-day price forecasts to
                          guide your investments
                        </li>
                        <li>
                          Buy fractional shares of high-cost stocks or any stock
                          you’d like to invest in
                        </li>
                      </ul>
                      {this.state.isSmallScreen ? (
                        <div
                          className="default__button"
                          onClick={() =>
                            this.setState({ showSignUpPopup: true })
                          }
                        >
                          SIGN UP
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            this.setState({ signUpModalOpen: true })
                          }
                          className="default__button"
                        >
                          SIGN UP
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              <div className='infoSection__reg black' id='ai-trader'>
                <div className='largeScreenWrapper__container'>
                  <div className='fullRow__container even'>
                    <div className='text__container'>
                      <div className='wrapper__container white'>
                        <p className='logo-text'>{this.props.t('aitrader')}</p>
                        <p className='large-text'>
                          {this.props.t('aitraderheader')}
                        </p>
                        <ul>
                          <li>{this.props.t('aitraderinfo1')}</li>
                          <li>{this.props.t('aitraderinfo2')}</li>
                          {/* <li>Pick up to four stocks in each trade group</li> */}
                          <li>{this.props.t('aitraderinfo3')}</li>
                          <li>{this.props.t('aitraderinfo4')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className='image__container'>
                      <img src={BlackBlob} alt='' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='infoSection__reg black' id='goals'>
                <div className='largeScreenWrapper__container'>
                  <div className='fullRow__container even'>
                    <div className='text__container'>
                      <div className='wrapper__container white'>
                        <p className='logo-text'>{this.props.t('goals')}</p>
                        <p className='large-text'>
                          {this.props.t('goalsheader')}
                        </p>
                        <ul>
                          <li>{this.props.t('goalsinfo1')}</li>
                          <li>{this.props.t('goalsinfo2')}</li>
                          <li>{this.props.t('goalsinfo3')}</li>
                          <li>{this.props.t('goalsinfo4')}</li>
                        </ul>
                      </div>
                    </div>
                    <div className='image__container'>
                      <img src={ChooseYourGoal} alt='' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='infoSection__reg green' id='budgeting'>
                <div className='largeScreenWrapper__container'>
                  <div
                    className={
                      this.state.isSmallScreen
                        ? 'fullRow__container even'
                        : 'fullRow__container smallLeft'
                    }
                  >
                    <div className='image__container'>
                      <img src={YearlyPerformance} alt='' />
                    </div>
                    <div className='text__container'>
                      <div className='wrapper__container white'>
                        <p className='logo-text'>{this.props.t('Budgeting')}</p>
                        <p className='large-text'>
                          {this.props.t('budgetingheader')}
                        </p>
                        <ul>
                          <li>{this.props.t('budgetinginfo1')}</li>
                          <li>{this.props.t('budgetinginfo2')}</li>
                          <li>{this.props.t('budgetinginfo3')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='infoSection__reg'>
                <div className='largeScreenWrapper__container'>
                  <div className='fullRow__container even'>
                    <div className='text__container textOnly'>
                      <div className='wrapper__container'>
                        <p className='large-text bold-text'>
                          {this.props.t('freeversion')}
                        </p>
                        <p className='subtitle__text'>
                          {this.props.t('freeversioninfo')}
                        </p>
                      </div>
                    </div>
                    <div className='text__container textOnly'>
                      <div className='wrapper__container'>
                        <p className='large-text bold-text'>
                          {this.props.t('upgradetools')}
                        </p>
                        <p className='subtitle__text'>
                          {this.props.t('upgradetoolsinfo')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
        </>
      )
    } else return null
  }
}
