export default function getPercentage(price, priceChange) {
  if (price == 0) return '-'

  let val = (
    ((parseFloat(priceChange) - parseFloat(price)) / parseFloat(price)) *
    100
  ).toFixed(2)

  if (val < 0) {
    return {
      value: val,
      formattedValue: `${val}%`,
    }
  } else {
    return {
      value: val,
      formattedValue: `+${val}%`,
    }
  }
}
