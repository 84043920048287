import React, {useState} from "react";
import { NavLink, /*Redirect*/ } from "react-router-dom";
import { Navbar, Nav, Container, Col, Row, Button,NavDropdown, /*Form,*/ FormControl, Dropdown} from "react-bootstrap";
import HeaderDropdown from "../HeaderDropdown"
import { /*FaArrowUp, FaArrowDown, FaMinus, FaRegTimesCircle, */FaQuestionCircle } from 'react-icons/fa'
// import { SegmentedControl } from 'segmented-control'
import ENDPOINT from '../Endpoint'


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled" style={{width:'100%'}}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.notToggleNavbar = this.notToggleNavbar.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleChinese = this.toggleChinese.bind(this);
    this.notToggleChinese = this.notToggleChinese.bind(this);

    this.state = {
      isSmallScreen: false,
      collapsed: false,
      isChinese: this.props.isChinese,
      searchMomentumText: '',
      momDataSource: [],
      isMomDataLoading: false,
      isMomCurSelected: true,
      showTrendPredictor: false,
      screenWidth: window.innerWidth,
      isSearching: false,
      isCompanySearchShown: false
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  notToggleNavbar() {
    this.setState({
      collapsed: this.state.collapsed
    });
  }

  toggleChinese() {
    this.setState({
      collapsed: !this.state.collapsed,
      isChinese: !this.state.isChinese
    });
  }

  notToggleChinese() {
    this.setState({
      collapsed: this.state.collapsed,
      isChinese: !this.state.isChinese
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
    }else{
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state;
    this.setState({ isMomDataLoading: true });
    // console.log(searchMomentumText);
    let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;

    fetch(url, {
        "Access-Control-Allow-Headers": {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
        },
    })
        .then((res) => res.json())
        .then((responseJson) => {
            var data = responseJson.content;
            // console.log("SEARCHING RESULT: " , data);

            for (var i = 0; i < data.length; i++) {
                var item = data[i];
                var color = "";
                if (item.rar) {
                    switch (item.rar) {
                        case "H":
                            color = "#dc143c";
                            break;
                        case "M":
                            color = "#fcac0c";
                            break;
                        default:
                            color = "#00A99D";
                    }
                } else {
                    color = "rgb(0,0,0)";
                }
                item.symbolColor = color;
                data[i] = item;
            }
            this.setState({
                isMomDataLoading: false,
                momDataSource: data,
            });
        })
        .catch((err) => {
            this.setState({ isMomDataLoading: false });
            console.log(err);
        });
};

  input = ()=>{
    return(
      <div style={{
        display: 'flex',
        flex: '1 1 0%',
        borderWidth: '1',
        borderColor: 'rgba(0, 0, 0, 0.2)',
        width:'100%',
        alignItems:'center',
        justifyContent:'center',
        textDecoration:'none',
      }}>
        <input 
          style={{
          width:'90%', 
          display:'flex', 
          padding:5,
          borderWidth: .5,
          borderRadius:5,
          borderColor: 'rgba(0, 0, 0, 0.2)',
          textDecoration:'none',
          cursor:'text'
        }}
          type="text" 
          placeholder="Enter a symbol or company name" 
          value={this.state.searchMomentumText}
          onChange={(event) => {
            let searchMomentumText = event.target.value;
            this.setState({ searchMomentumText, selectedPreset: null }, () => {
                const { isSearching } = this.state;
                if (isSearching) {
                    return;
                } else {
                    this.setState({ isSearching: true });
                    setTimeout(() => {
                        this.setState(
                            { isSearching: false },
                            this.onSubmitSearchMomentum
                        );
                    }, 2000);
                }
            });
        }}
        />
      </div>
    )
  }

  dropDownItems = (ele, size)=>{
      return(
        // <div style={{display:"flex"}}>
          <Dropdown.Item as="button" style={{padding: 10, width: '100%', padding:'.5rem 1.5rem'}}>
            <HeaderDropdown trendKey={ele.trendKey} size={size} columnTitle={ele.columnTitle} priceUSD={ele.priceUSD} directionPrev={ele.directionPrev} direction={ele.direction} symbol={ele.symbol}/>
          </Dropdown.Item>
        // </div> 
      )
  }

  

  render() {
    const{showTrendPredictor, isSmallScreen, collapsed, momDataSource} = this.state
    let searchTable = [...momDataSource]
    return (
      <Container fluid >
        <Navbar
          className="navbar-bg-allocate"
          fixed="top"
          collapseOnSelect
          expand="0"
          expanded={this.state.collapsed}
          onToggle={this.toggleNavbar}
        >
          <Col xs sm={1}>
            <NavLink
              to={this.state.isChinese ? "/cn/Home" : "/"}
              onClick={
                this.state.isSmallScreen
                  ? this.toggleNavbar
                  : this.notToggleNavbar
              }
            >
              <img
                className="header-logo"
                src="/images/logo/ARLogoWhite.png"
                alt={"logo"}
                style={{
                  width: 150
                }}
              />
            </NavLink>
          </Col>
          {this.props.hiddenSearch?null:<div>
          {!isSmallScreen ? <Col 
          style={{
            marginLeft: 100
          }}>
              <Row>
                {/* <NavLink
                  style={{display: 'flex', flex:1, marginRight: 10, marginLeft: 10, height: 44, borderWidth: 1, borderColor: 'rgba(0,0,0,0.2)'}}
                  to={'/Dashboard/Search'}
                >
                  <Button
                    variant='light'
                    style={{display: 'flex', flex:1, marginRight: 10, height: 44, textAlign: 'middle'}}
                  >
                    Enter a symbol or company name to see price trend and stock info
                  </Button>
                  <Button style={{
                    position: 'absolute',
                    right: 20,
                    backgroundColor: '#00000000',
                    borderColor: '#00000000'
                  }}>
                  </Button>
                </NavLink> */}
                {/* <div style={{width:600}}>
                  {this.input()}
                </div>
              </Row>
              <Dropdown>
                {this.state.momDataSource.map(ele=>this.dropDownItems(ele))}
              </Dropdown> */}
              <Dropdown style={{width: 600, marginRight:'5%'}}>
                <Dropdown.Toggle as={CustomToggle} alignItems={true} id="dropdown-custom-components" style={{textDecoration:'none'}}>
                  {this.input()}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu} alignItems={true} style={{width:'90%', marginLeft:'5% !important'}}>
                  <Dropdown.Header style={{width:'100%'}}>
                    <HeaderDropdown trendKey={false} size={'big'} columnTitle={true} priceUSD={0} directionPrev={0} direction={0} symbol={''}/>
                  </Dropdown.Header>
                  {searchTable.slice(0,8).map(ele=>this.dropDownItems(ele,'big'))}
                  <Dropdown.Header>
                    <HeaderDropdown trendKey={true} size={'big'} columnTitle={false} priceUSD={0} directionPrev={0} direction={0} symbol={''}/>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
              </Row>
              {/* <div style={{display:'block', backgroundColor:'rgb(255,255,255)', width:540}}>
              </div> */}
          </Col>: !collapsed ?
          <Col style={{
            position: 'absolute',
            left: 0,
            top: 50,
            right: 0
          }}>
            {/* <Row>
              <NavLink
                style={{display: 'flex', flex:1, marginRight: 10, marginLeft: 10, height: 44, borderWidth: 1, borderColor: 'rgba(0,0,0,0.2)',}}
                to={'/Dashboard/Search'}
              >
                <Button
                  variant='light'
                  style={{display: 'flex', flex:1,  borderWidth: 1, borderColor: 'rgba(0,0,0,0.2)'}}
                >
                  Enter a symbol or company name 
                </Button>
              </NavLink>
              {this.input()}
            </Row> */}
            <Dropdown style={{width: '100%', marginRight:'5%'}}>
                <Dropdown.Toggle as={CustomToggle} alignItems={true} id="dropdown-custom-components">
                  {this.input()}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu} alignItems={true} style={{width:'90%', marginLeft:'5% !important'}}>
                  <Dropdown.Header style={{width:'100%'}}>
                      <HeaderDropdown trendKey={false} size={'small'} columnTitle={true} priceUSD={0} directionPrev={0} direction={0} symbol={''}/>
                  </Dropdown.Header>
                  {searchTable.slice(0,8).map(ele=>this.dropDownItems(ele, 'small'))}
                  <Dropdown.Header>
                    <HeaderDropdown trendKey={true} size={'small'} columnTitle={false} priceUSD={0} directionPrev={0} direction={0} symbol={''}/>
                  </Dropdown.Header>
                </Dropdown.Menu>
              </Dropdown>
            {this.state.momDataSource.forEach((ele,i)=>this.dropDownItems(ele,i))}
          </Col> : null}
          </div>}
          <Col className="header-rightpart">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              id="nav-toggle-button"
            />
            <Navbar.Collapse
              className="justify-content-end"
              id="basic-navbar-nav"
            >
              {/* <Nav>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div
                  className="div"
                  style={{
                    backgroundColor: 'rgb(255,255,255)',
                    color: '#00A99D',
                    borderRadius: 6,
                    padding: 10,
                    maxWidth: 54,
                  }}
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? (
                    <span>倍塔</span>
                  ) : (
                      <span>Beta</span>
                    )}
                </div>
                </div>
              </Nav> */}
              <Nav>
                <NavLink
                  to={"/"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                    <span>Home</span>
                </NavLink>
                <NavLink
                  to={this.state.isChinese ? "/cn/Platform" : "/Platform"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? (
                    <span>平台</span>
                  ) : (
                    <span>Platform</span>
                  )}
                </NavLink>
                <NavLink
                  to={this.state.isChinese ? "/cn/About" : "/About"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                    <span>About</span>
                </NavLink>
                <NavLink
                  to={this.state.isChinese ? "/cn/Strategies" : "/Strategies"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? (
                    <span>策略</span>
                  ) : (
                    <span>Strategies</span>
                  )}
                </NavLink>
                <NavLink
                  to={this.state.isChinese ? "/cn/Insights" : "/Insights"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? (
                    <span>新闻</span>
                  ) : (
                    <span>Insights</span>
                  )}
                </NavLink>
                {/* <NavLink
                  to={this.state.isChinese ? "/cn/Team" : "/Team"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? <span>团队</span> : <span>Team</span>}
                </NavLink> */}

                {/*<NavLink to="/FreeTrial" className="NavLink">Free Trial</NavLink>*/}

                <NavLink
                  to={this.state.isChinese ? "/About" : "/cn/About"}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleChinese
                      : this.notToggleChinese
                  }
                >
                  {this.state.isChinese ? (
                    <span>English</span>
                  ) : (
                    <span>中文</span>
                  )}
                </NavLink>
                {this.props.user ? 
                  <NavLink
                    to={'/Dashboard'}
                    className="NavLink"
                    onClick={
                      this.state.isSmallScreen
                        ? this.toggleNavbar
                        : this.notToggleNavbar
                    }
                  >
                      <span>Dashboard</span> 
                  </NavLink>
                
                :
                <NavLink
                  to={'/SignUp'}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {!this.state.isChinese ? (
                    <span>Sign Up</span>
                  ) : (
                    <span>注册</span>
                  )}
                </NavLink>
                }

                <a
                  className="span-in-header"
                  href="https://app.allocaterite.com/login"
                >
                  {this.state.isChinese ? (
                    <span>登录</span>
                  ) : (
                    <span>Advisor Login</span>
                  )}
                </a>
                {this.props.user ? <div style={{cursor:'pointer'}} onClick= {() => this.props.signOut()}><span  className="span-in-header">Log Out</span></div>:
                <NavLink
                  to={'/Login'}
                  className="NavLink"
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  <span>Login</span>
                </NavLink> }
                
              </Nav>
              {/* <Nav>
                <div
                  className="div"
                  style={{
                    backgroundColor: 'rgb(255,255,255)',
                    color: '#00A99D',
                    borderRadius: 6,
                    padding: 10
                  }}
                  onClick={
                    this.state.isSmallScreen
                      ? this.toggleNavbar
                      : this.notToggleNavbar
                  }
                >
                  {this.state.isChinese ? (
                    <span>倍塔</span>
                  ) : (
                      <span>Beta</span>
                    )}
                </div>
              </Nav> */}
            </Navbar.Collapse>
          </Col>
        </Navbar>
      </Container>
    );
  }
}

export default Header;
