import React, { Component } from 'react'
import RoboActivation from '../product-activation/robo-activation/RoboActivation'
import AIstockActivation from '../product-activation/ai-stock-activation/AIstockActivation'
import AIcryptoActivation from '../product-activation/ai-stock-activation/AIcryptoActivation'
import GoalsActivation from '../product-activation/goals-activation/GoalsActivation'
import { Dialog } from 'primereact/dialog'

export default class ActiveProduct extends Component {
  state = {
    showActivation: false,
    showMessageDialog: false,
    showSubMessageDialog: false,
  }

  toggleActivation = () => {
    if (
      this.props.selectedName === 'AI CRYPTO TRADER' ||
      this.props.canTrade?.status
    ) {
      this.props.toggleShowInfo()
      this.setState({ showActivation: !this.state.showActivation })
    } else {
      this.toggleMessageDialog()
    }
  }

  toggleMessageDialog = () => {
    this.setState({ showMessageDialog: !this.state.showMessageDialog })
  }
  activateProduct() {
    let color
    if (this.props.selectedName === 'ROBO') {
      color = '#7147E9'
    } else if (this.props.selectedName === 'GOALS') {
      color = '#3D32E7'
    } else {
      color = '#5C21D8'
    }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          boxShadow:
            '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
          borderRadius: '19px',
          padding: '25px',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            fontFamily: 'Akkurat-Bold',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          {/* Activate {this.props.selectedName} */}
          {this.props.t(`activate${this.props.selectedName.toLowerCase()}`)}
        </p>
        <p
          style={{
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          {/* Continue automated trading by activating your{' '}
          {this.props.selectedName} account. */}
          {this.props.t('continueautomatedtradingby')}
        </p>
        <button
          style={{
            background: 'transparent',
            margin: '0 auto',
            display: 'block',
            border: 'none',
          }}
          onClick={() => {
            if (this.props.subStatus) {
              this.toggleActivation()
            } else {
              this.setState({ showSubMessageDialog: true })
            }
          }}
        >
          <p
            style={{
              textDecoration: 'underline',
              color: color,
              margin: 0,
            }}
          >
            {this.props.t('activate')}
          </p>
        </button>
      </div>
    )
  }

  getProductActivation() {
    switch (this.props.selectedName) {
      case 'ROBO':
        return (
          <RoboActivation
            {...this.props}
            toggleActivation={this.toggleActivation}
          />
        )
      case 'AI STOCK TRADER':
        return (
          <AIstockActivation
            {...this.props}
            toggleActivation={this.toggleActivation}
          />
        )
      case 'AI CRYPTO TRADER':
        return (
          <AIcryptoActivation
            {...this.props}
            toggleActivation={this.toggleActivation}
          />
        )
      case 'GOALS':
        return (
          <GoalsActivation
            {...this.props}
            toggleActivation={this.toggleActivation}
          />
        )
      default:
        break
    }
    return null
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          {this.state.showActivation
            ? this.getProductActivation()
            : this.activateProduct()}
          <Dialog
            visible={this.state.showMessageDialog}
            onHide={this.toggleMessageDialog}
            header={this.props.t('cannotactivateaccountduringnontradinghours')}
          ></Dialog>
          <Dialog
            visible={this.state.showSubMessageDialog}
            header={this.props.t(
              'pleaseupgradetoinvestallprotoaccessthisproduct'
            )}
            onHide={() => this.setState({ showSubMessageDialog: false })}
          ></Dialog>
        </div>
      )
    } else return null
  }
}
