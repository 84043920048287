import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';


class PieChartOfFeature extends Component {

    constructor(props) {

        super(props);

        this.state = {
            legend: {
                display: true,
                position: 'bottom',
                pointStyle: 'string'
            }
        }
    }


    render() {
        return (
            <div>
                <Doughnut data={this.props.value} width={30} height={30}
                    options={{title: {
                        display: true,
                        text: this.props.titleText,
                        fontSize: 14
                    }}} 
                    legend={this.state.legend}
                />
            </div>
        )
    }
}

export default PieChartOfFeature
