import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

export class Footer extends Component {
  render() {
    return (
      <div id='footer'>
        <Container className='footerContainer' bg='light'>
          <Row className='foot-blank-row' />
          <Row className='terms'>
            <Col sm={1} />
            <Col sm={10}>
              <NavLink to='/PrivacyPolicy' className='footer-navlink'>
                Privacy Policy
              </NavLink>
              <NavLink to='/TermsOfUse' className='footer-navlink'>
                Terms of Use
              </NavLink>
              <NavLink to='/About' className='footer-navlink'>
                About
              </NavLink>
              <NavLink to='/Team' className='footer-navlink'>
                Team
              </NavLink>
              <NavLink to='/Careers' className='footer-navlink'>
                Careers
              </NavLink>
              <NavLink to='/Contact' className='footer-navlink'>
                Contact Us
              </NavLink>
              <a href='/Legal/EULA.pdf' className='link'>
                {this.props.t('eula')}
              </a>
              <NavLink to='/GeneralDisclaimer' className='footer-navlink'>
                General Disclaimer
              </NavLink>
            </Col>
            <Col sm={1} />
          </Row>

          <Row className='footer-policy-text'>
            <p className='p1'>
              AllocateRite is an SEC registered investment advisor. Past
              performance is no guarantee of future results or returns.
              Investments are not FDIC insured, and securities and investments
              may lose value. Any historical returns, expected returns or
              probability projections may not reflect actual future performance.
              All securities involve risk and may result in loss. All
              information herein as well as any communications on social media
              is not an offer, solicitation of an offer, or advice to buy or
              sell securities or services.
            </p>
          </Row>

          <Row className='footer-policy-text'>
            <p className='p2'>
              Past performance is no guarantee of future results or returns.
              Investments are not FDIC insured, and securities and investments
              may lose value. Any historical returns, expected returns or
              probability projections may not reflect actual future performance.
              All securities involve risk and may result in loss. All
              information herein as well as any communications on social media
              is not an offer, solicitation of an offer, or advice to buy or
              sell securities or services.
            </p>
          </Row>

          <Row className='copy'>
            <Col sm={6} id='copyleft'>
              <span id='copyLeftText'>
                Copyright © 2019 — AllocateRite LLC, NYC
              </span>
            </Col>
            <Col sm={6} id='copyright'>
              <span id='copyRightText'>All rights reserved. </span>
            </Col>
          </Row>
          <Row className='foot-blank-row' />
        </Container>
      </div>
    )
  }
}

export default Footer
