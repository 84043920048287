import React, { useContext } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { LandingPageContext } from '.'
import Robo from '../../../assets/newLandingPage/Robo.png'
import AAPL from '../../../assets/newLandingPage/AAPL.png'
import Stocks from '../../../assets/newLandingPage/Stocks.png'
import Goals from '../../../assets/newLandingPage/Goals.png'
import AI_trader from '../../../assets/newLandingPage/AI_trader.png'
import Products from './Products'
import { useEffect } from 'react'

export default function ProductScreen() {
  const { t } = useContext(LandingPageContext)
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general
  const [tab, setTab] = useState(0)
  const [productImage, setProductImage] = useState(Stocks)

  useEffect(() => {
    switch (tab) {
      case 0:
        setProductImage(Stocks)
        break
      case 1:
        setProductImage(Robo)
        break
      case 2:
        setProductImage(Goals)
        break
      case 3:
        setProductImage(AI_trader)
        break
      default:
        break
    }
  }, [tab])

  const getProductTitle = () => {
    switch (tab) {
      case 0:
        return (
          <p className='product-title fade1'>{t('thefutureofinvesting')}</p>
        )
      case 1:
        return <p className='product-title fade2'>{t('getexpertstrategies')}</p>
      case 2:
        return <p className='product-title fade3'>{t('meetallgoals')}</p>
      case 3:
        return <p className='product-title fade4'>{t('automateyourtrades')}</p>
      default:
        return null
    }
  }

  return (
    <div className='product-screen'>
      <div className='product-info'>
        {!isSmallScreen && (
          <div className='product-left'>
            <div className='phone-img-container'>
              <img src={productImage} className='phone-img' />
              {/* <img src={PhoneImg} className='phone-img' /> */}
            </div>
          </div>
        )}
        <div className='product-right'>
          {getProductTitle()}
          <Products tab={tab} setTab={setTab} productImage={productImage} />
        </div>
      </div>
    </div>
  )
}
