import React, { Component } from 'react'
import TransferFundsACH from './TransferFundsACH'
import GroupCryptoBasicInfo from './groupCrpyto/GroupCryptoBasicInfo'
import GroupCryptoInvestments from './groupCrpyto/GroupCryptoInvestments'
import GroupCryptoRecurBuys from './groupCrpyto/GroupCryptoRecurBuys'
import GroupCryptoPendingTransfers from './groupCrpyto/GroupCryptoPendingTransfers'
import GroupCryptoSpecificInfo from './groupCrpyto/GroupCryptoSpecificInfo'
import OrderHistory from './OrderHistory'
import TransferHistory from './TransferHistory'
import Statements from '../Statements'
import Loader from './Loader'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import AccountActivity from '../AccountActivity'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
export default class GroupCryptoAccountInfo extends Component {
  state = {
    activeTab: 'General Info',
  }
  tabs() {
    const customSelectedTab = { ...selectedTab, backgroundColor: '#5C21D8' }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          onClick={() => this.setState({ activeTab: 'General Info' })}
          style={
            this.state.activeTab === 'General Info'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('ai crypto trader')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      return (
        <TransferFundsACH
          accounts={this.props.accounts}
          isSmallScreen={this.props.isSmallScreen}
          formatCurrency={this.props.formatCurrency}
          user={this.props.user}
          getAllAccountInfo={this.props.getAllAccountInfo}
          cryptoAccount={this.props.selectedAccount}
          t={this.props.t}
        />
      )
    } else if (this.state.activeTab === 'Account Activity')
      // return (
      //   <div>
      //     {this.props.accountTransactionsData ? (
      //       <OrderHistory {...this.props} />
      //     ) : (
      //       <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
      //     )}
      //     {this.props.accountTransactionsData ? (
      //       <TransferHistory {...this.props} />
      //     ) : (
      //       <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
      //     )}
      //   </div>
      // )
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  generalInfo() {
    return (
      <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
        {this.props.productInstanceData &&
        this.props.accountTransactionsData ? (
          <>
            <GroupCryptoBasicInfo {...this.props} />
            <GroupCryptoSpecificInfo {...this.props} />
            <GroupCryptoInvestments {...this.props} />
            <GroupCryptoRecurBuys {...this.props} />
            <GroupCryptoPendingTransfers {...this.props} />
          </>
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
        )}

        {/* {this.props.productInstanceData ? (
          <GroupCryptoBasicInfo {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.productInstanceData ? (
          <GroupCryptoSpecificInfo {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.productInstanceData ? (
          <GroupCryptoInvestments {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.accountTransactionsData ? (
          <GroupCryptoRecurBuys {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )}
        {this.props.accountTransactionsData ? (
          <GroupCryptoPendingTransfers {...this.props} />
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false}/>
        )} */}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '60%',
            }}
          >
            <img
              src={ProductActivationBackIcon}
              height={25}
              width={25}
              onClick={() => {
                this.props.resetSelectedAccount()
              }}
              style={{ cursor: 'pointer', marginBottom: '25px' }}
            />
            {this.tabs()}
            <div
              style={{
                width: this.props.isSmallScreen ? '100%' : '80%',
                margin: 'auto',
                height: '100%',
              }}
            >
              {this.showSelectedTab()}
            </div>
          </div>
        </div>
      )
    } else return null
  }
}
