import React from 'react'
import ENDPOINT from '../Endpoint'

const brandColor = "#00A99D"

export default class WealthManagement extends React.Component {
    
    state={
        stratData:false,
        selectedStrat:'',
        customPorts: this.props.customPorts
    }

    componentDidMount(){
        this.fetchARstrategies()
    }

    
    fetchOneStrategy(name){
        // fetch(`${ENDPOINT}/risk-monkey/portfolios/${name}`,{
        fetch(`${ENDPOINT}/risk-monkey/portfolios/test2`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('one custom saved result: ', responseJson))
    }

    deleteOneStrategy = (id)=>{
        fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('responseJson:', responseJson))
          .then(()=>{
              let strats = {...this.state.customPorts}
              delete strats[id]
              this.setState({customPorts:strats})
          })
    }

    fetchARstrategies = ()=>{
        fetch('${ENDPOINT}/strategies',
        {
            'Access-Control-Allow-Headers': {
              'Content-Type': 'application/json',
            }
          }
        )
        .then(res => res.json())
        .then(responseJson => {
            this.setState({stratData:responseJson })
        })
    }

    showFactSheet = (displayName)=>{
        switch(displayName) {
            case 'US Domestic':
              //code 
            case 'US Domestic AI':
              //code 
            case 'Alpha Overlay':
              //code 
            
          }
        
    }

    setHref = (displayName)=>{
        if(displayName === 'US Domestic'){
            return '../../assets/factsheets/US-Domestic-Fact-Sheet.pdf'
        }
        else if(displayName === 'US Domestic AI'){
            return '../../assets/factsheets/US-Dynamic-Leverage-Fact-Sheet.pdf'
        }else{
            return null
        }
    }

    showOneCustom =(title,data)=>{
        // console.log('got to custom')
        // console.log('title', title)
        // console.log('data', data)
        let arr = []
        let stockRow = data.forEach(ele=>{
            arr.push(
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-evenly', border: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
            <div style={{width:'50%', borderRight: 'solid', borderWidth: .25, borderColor: '#ddd',display:'flex', justifyContent:'center', alignItems:'center'}}>
                <p>{ele.symbol}</p>
            </div>
            <div style={{width:'50%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                <p>{ele.symbol==='$$' ? parseFloat(ele.quantity).toFixed(2) : ele.quantity}</p>
            </div>
        </div>
            )
        })
        return(
            <div style={{display:'flex', flexDirection:'column'}} >
                <div style={{width:'100%', display:'flex' , justifyContent:'center', alignItems:'center', border: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                    <div style={{width:'80%', marginLeft:'10%', cursor:'pointer'}} onClick={()=>this.setState({selectedStrat:this.state.selectedStrat === title? '':title})}>
                        <p>
                        {title}
                        </p>
                    </div>
                    <div style={{width:'20%', borderLeft:'solid', borderWidth:.25, borderColor:'#ddd', display:'flex',alignItems:'center', justifyContent:'center', cursor:'pointer'}}
                    onClick={()=>this.deleteOneStrategy(title)}
                    >
                        <p >
                            Delete {title}
                        </p>
                    </div>
                
                </div>
                {this.state.selectedStrat===title?
                <div>
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around',  border: 'solid', borderWidth: .25, borderColor: '#ddd'}}>
                    <div style={{width:'50%', borderRight: 'solid', borderWidth: .25, borderColor: '#ddd', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <p>Symbol</p>
                    </div>
                    <div style={{width:'50%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <p>Number of Shares</p>
                    </div>
                </div>
                <div style={{width:'100%'}}>
                    <div>
                        {arr}
                    </div>
                </div>
                </div>
                :null}
            </div>
        )
    }

    showStrategy = (ob) => {
        return(
            <div style={{height: 300,}}>
                    <div style={{cursor:'pointer'}} 
                    // onClick={() => window.open(this.setHref(ob.displayName))}
                    >
                        <h4 style={{color:''}}>{ob.displayName}</h4>
                        {/* <a style={{color:brandColor, fontSize:24}} href={this.setHref(ob.displayName)} target={ob.displayName === 'US Domestic' ||ob.displayName === 'US Domestic AI' ?'_blank':null}>{ob.displayName}</a> */}
                    </div>
                    <div>
                        <p>{ob.description}</p>
                    </div>
                    <div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <p style={{fontWeight:'bold'}}>Performance</p>
                        </div>
                        <div>
                            {this.state.stratData?
                            <div style={{display: 'flex', justifyContent:'space-around'}}>
                                <div>
                                    <p>MTD</p>
                                    <p style={{color:brandColor}}>{(ob.mtd*100).toFixed(2)}</p>
                                </div>
                                <div>
                                    <p>YTD</p>
                                    <p style={{color:brandColor}}>{(ob.ytd*100).toFixed(2)}</p>
                                </div>
                                <div>
                                    <p>LTD</p>
                                    <p style={{color:brandColor}}>{(ob.ltd*100).toFixed(2)}</p>
                                </div>
                            </div> 
                            :null}
                        </div>
                    </div>
                </div>
        )
    }
    render() {
        if(!this.state.stratData){
            return (
                <div>
                   <div style={{height: 200,marginTop:20}}>
                        <div>
                            <h4>Your Custom Optimized Portfolio</h4>
                        </div>
                        <p>Use our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}}
                        onClick={()=>this.props.toPc()}
                        >Portfolio Constructor</strong> create your own strategy new portfolio or optimize an existing portfolio</p>
                        <p>See your previously created <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}}
                        onClick={()=>this.props.toMs()}
                        >Strategies</strong> or create a new strategy using our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}}
                        onClick={()=>this.props.toPc()}
                        >Portfolio Constructor</strong></p>
                    </div>
                </div>
            )
        }
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:12}}>
                    {this.showStrategy(this.state.stratData[0])}
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:12}}>
                    {this.showStrategy(this.state.stratData[1])}
                </div>
                <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:12}}>
                    {this.showStrategy(this.state.stratData[2])}
                </div>
                <div style={{height: 200,marginTop:20,backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:12}}>
                    <div style={{}}>
                        <h4>Your Custom Optimized Portfolio</h4>
                    </div>
                    <p>Use our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}} onClick={()=>this.props.toPc()}> Portfolio Constructor
                    </strong> 
                        create your own strategy new portfolio or optimize an existing portfolio</p>
                    <p>See your previously created <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}}
                    onClick={()=>this.props.toMs()}
                    >Strategies</strong> or create a new strategy using our <strong style={{fontWeight:'bold', color:brandColor, cursor:'pointer'}}
                    onClick={()=>this.props.toPc()}
                    >Portfolio Constructor</strong></p>
                </div>
            </div>
        )
    }
}