import React from "react";

export default function ApexDisclosures() {
  return (
    <div className="mobile-disclosure-main-conatiner">
      <p className="title">Apex Disclosures</p>
      <ul>
        <li>
          <a href="#account-agreement" rel="noopener noreferrer">
            Apex Account Agreement
          </a>
        </li>
        <li>
          <a
            href="https://apexcrypto.com/privacy-statement/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apex Crypto Privacy Policy
          </a>
        </li>
        <li>
          <a
            href="https://www.foreSCITE.ai/Legal/apex-crypto-risk-disclosures.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apex Crypto Risk Disclosure
          </a>
        </li>
        <li>
          <a href="#user-agreement" rel="noopener noreferrer">
            Apex Crypto User Agreement
          </a>
        </li>
      </ul>
      <div style={{ width: "100%" }} id="account-agreement">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingBottom: 40,
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0, fontSize: 40, fontWeight: "bold" }}>
              Apex Account Agreement
            </p>
          </div>
          <div>
            <div>
              <p>
                {" "}
                New Account Application &amp; Agreement I (We) would like to
                open a brokerage account with Apex Clearing Corporation.
              </p>
              <h1 id="customer-account-agreement-and-advisor-authorization">
                Customer Account Agreement and Advisor Authorization
              </h1>
              <p>
                {" "}
                This Customer Account Agreement (the Agreement) sets forth the
                respective rights and obligations of Apex Clearing Corporation
                (Apex) and the customer identified on the New Account
                Application (the Customer) in connection with the Customer&#39;s
                brokerage account with Apex (the Account). Customer hereby
                agrees as follows with respect to the Account, which Customer
                has established with Apex for the purchase, sale, and/or
                carrying of securities or contracts relating thereto and/or the
                borrowing of funds, at the instruction of Customer&#39;s
                registered investment advisor as authorized by Customer in the
                New Account Application (Advisor), which transactions will be
                cleared through Apex. To help the government fight the funding
                of terrorism and money laundering, Federal law requires all
                financial institutions to obtain, verify, and record information
                that identifies each person who opens an account. In order to
                open the Account, Customer will provide information that will
                allow Apex to identify Customer, including, but not limited to,
                Customer&#39;s name, address, date of birth, and copies of
                Customer&#39;s driver license and/or other identifying
                documents.
              </p>
              <ol>
                <li>
                  <strong>Applicable Rules and Regulations.</strong> All
                  transactions for the Account shall be subject to the
                  constitution, rules, regulations, customs, and usages of the
                  exchange or market and its clearing house, if any, upon which
                  such transactions are executed, except as otherwise
                  specifically provided in this Agreement.
                </li>
                <li>
                  <strong>Definitions.</strong> Obligations means all
                  indebtedness, debit balances, liabilities, or other
                  obligations of any kind of the Customer to Apex, whether now
                  existing or hereafter arising. Assets shall include, but shall
                  not be limited to, money, securities, commodities, or other
                  property of every kind and nature and all contracts and
                  options relating to them in any way, whether for present of
                  future delivery.
                </li>
                <li>
                  <strong>Advisor Agreement.</strong> Customer represents that
                  Customer has entered into a separate agreement directly with
                  Advisor (Advisor Agreement) authorizing Advisor to manage the
                  Account, including executing trades, appointing sub-advisors,
                  depositing or withdrawing funds to and from Account,
                  requesting information, receiving Account statements and
                  confirmations, changing certain of my information, and
                  generally transacting in and managing the Account. Customer
                  understands that Advisor has entered into an agreement with
                  Apex pursuant to which Apex is willing to grant access to
                  Advisor for Advisor to manage accounts for Advisor&#39;s
                  customers whom open brokerage accounts at Apex for Advisor to
                  manage. Customer authorizes Advisor to establish relationships
                  with Apex and to appoint and use other sub-agents on the
                  Account at Advisor&#39;s discretion. Should Advisor appoint a
                  sub-agent to the Account, Apex shall be entitled to rely on
                  and execute any instructions from that sub-agent as if they
                  were given directly by Advisor.
                </li>
                <li>
                  <strong>Apex&#39;s Role and Appointment.</strong> Customer
                  appoints Apex as agent to carry the Account and to execute
                  Advisor&#39;s instructions relating to the Account. Customer
                  agrees Apex may execute, clear, and settle any transaction in
                  the Account at the instruction of Advisor and may accept and
                  execute any instruction relating to the Account without
                  inquiry or investigation. Customer assumes all investment risk
                  with the Account and with authorizing Advisor to transact for
                  the Account. Customer understands all transactions in the
                  Account will be executed by Apex only at the instruction of
                  Advisor acting pursuant to the Advisor Agreement, and that
                  Apex&#39;s role is only to hold or custody Account Assets,
                  distribute or collect funds on behalf of the Account, execute
                  and clear trades at the instruction of Advisor, generate
                  account statements, and provide other custodial and clearing
                  services as may be mandated by various regulatory standards
                  and requirements. Customer understands Apex does not and will
                  not offer investment advice, review the Account or
                  transactions therein, make any determination of Customer&#39;s
                  suitability for any transaction or investment, will have no
                  responsibility for trades made or activity in the Account, and
                  has no discretion over transactions in or decisions made on
                  behalf of Customer or the Account. Customer acknowledges that
                  Advisor provides and is solely responsible for all investment
                  advice and investment advisory services given in connection
                  with the Account. Additionally, Apex will not verify the
                  accuracy of any management fees Customer pays to Advisor
                  pursuant to the terms of the Advisor Agreement.
                  Notwithstanding the foregoing, in the event Customer initiates
                  a claim against Apex, in Apex&#39;s capacity as custodial
                  broker or otherwise, and does not prevail, Customer shall be
                  responsible for the costs and expenses associated with
                  Apex&#39;s defense of such claim. Apex may, in its sole and
                  absolute discretion, and without prior notice to Customer or
                  Advisor, transact, restrict, or refuse any orders placed in
                  the Account.
                </li>
                <li>
                  <strong>Advisor Fee and Charges.</strong> Customer will pay
                  fees to Advisor for its services in accordance with the
                  Advisor Agreement (each such payment an Advisor Fee). Customer
                  authorizes and instructs Apex to deduct the Advisor Fee
                  directly from the Account and pay it to Advisor. Customer
                  agrees and acknowledges that Advisor will instruct Apex of the
                  amount to deduct and pay to Advisor, and Customer agrees to
                  hold harmless Apex in any dispute related to the amount of any
                  Advisor Fee. Apex may also charge, and Customer authorizes
                  Apex to deduct from the Account, fees for services performed
                  by Apex at Customer or Advisor&#39;s instruction, including,
                  but not limited to, transactions, preparation and delivery of
                  paper statements and confirmations, rejected payments, and
                  wire transfers. Customer acknowledges per the agreement
                  between Advisor and Apex that Advisor may pay Apex fees
                  directly for Apex&#39;s services provided. Regardless of the
                  method used to calculate fees charged by Apex to Advisor,
                  Customer understands that Apex is not an advisor and will not
                  act as an advisor on the Account. Additionally, the Advisor
                  Fee may not include underlying ETF or mutual fund expenses,
                  which are standard expenses that shareholders pay in
                  accordance with such funds&#39; prospectus, in which case Apex
                  shall be entitled to deduct such fees from the Account.
                </li>
                <li>
                  <strong>Breach; Security Interest.</strong> Whenever in
                  Apex&#39;s discretion Apex considers it necessary for
                  Apex&#39;s, Advisor&#39;s, or Customer&#39;s protection or in
                  the event of, but not limited to: (i) any breach by Customer
                  of this or any agreement with Apex or Advisor or (ii)
                  Customer&#39;s failure to pay for Assets purchased or deliver
                  Assets sold, Apex may sell any and all Assets held in the
                  Account or any other account in Customer&#39;s name (either
                  individually or jointly with others), cancel or complete any
                  open orders for the purchase or sale of any Assets and/or
                  borrow or buy-in any Assets required to make delivery against
                  any sale, including a short sale, effected for the Customer,
                  all without notice or demand for deposit of collateral, other
                  notice of sale or purchase, or other notice or advertisement,
                  each of which is expressly waiver by Customer, and/or Apex may
                  require Customer to deposit cash or adequate collateral to
                  Account prior to any settlement date in order to assure the
                  performance or payment of any open contractual commitments
                  and/or unsettled transactions. Apex has the right to refuse to
                  execute transactions for Customer or Advisor at any time and
                  for any reason. Any and all Assets belonging to Customer or in
                  which Customer may have an interest held by Apex or carried in
                  any of Customer&#39;s accounts with Apex (either individually
                  or jointly with others) shall be subject to a continuing and
                  perfected first priority lien and security interest and right
                  of set-off held by Apex for the discharge of and all
                  indebtedness or obligation Customer may have to Apex, and will
                  be held as security for payment of any liability or
                  indebtedness in the Account or any of Customer&#39;s accounts
                  with Apex, wherever or however arising and without regard to
                  whether or not Apex has made advances with respect to such
                  Assets, and Apex is hereby authorized to sell and/or purchase
                  any and all Assets in any of Customer&#39;s accounts, and/or
                  to transfer any such Assets among any of the Customer&#39;s
                  accounts to the fullest extent of the law and without notice
                  where allowed. The losses, costs and expenses, including but
                  not limited to reasonable attorneys&#39; fees and expenses,
                  incurred and payable or paid by Apex in the (i) collection of
                  a debit balance and/or any unpaid deficiency in the Account or
                  any accounts of the Customer with Apex or (ii) defense of any
                  matter arising out of the Customer&#39;s or Advisor&#39;s
                  transactions, shall be payable to Apex by Customer. Customer
                  understands that because of circumstances beyond Apex&#39;s
                  control, Customer&#39;s voting rights may be impaired. For
                  example, if the stock of a company that one Apex customer has
                  purchased has not yet been received from the seller(s), then a
                  different Apex customers&#39; abilities to vote that
                  company&#39;s stock could be impaired until those shares are
                  received by Apex. In addition, if the stock of a company that
                  Customer has purchased has not yet been received from the
                  seller(s), then payments received by the Customer, in lieu of
                  the dividends on that stock not yet received, may receive tax
                  treatment less favorable than that accorded to dividends.
                </li>
                <li>
                  <strong>Cancellation.</strong> Apex is authorized, in its sole
                  discretion, should it for any reason whatsoever deem it
                  necessary for its protection, without any notice, to cancel
                  any outstanding order, to close out the Account or any
                  accounts of Customer at Apex, in whole or in part, or to close
                  out any commitment made on behalf of Customer. Customer also
                  authorizes Apex to terminate the account with or without
                  Advisor&#39;s instruction without notice to Customer.
                </li>
                <li>
                  <strong>Payment of Indebtedness on Demand.</strong> Customer
                  shall at all times be liable for payment upon demand of any
                  obligations owing from Customer to Apex and Customer shall be
                  liable to Apex for any deficiency remaining in Account or in
                  any other accounts of Customer at Apex in the event of the
                  liquidation thereof (as contemplated by this Agreement or
                  otherwise), in whole or in part, by Apex, Advisor, or
                  Customer. Customer shall make payment of such obligations
                  immediately upon demand.
                </li>
                <li>
                  <strong>Communications.</strong> Apex may send communications
                  to Customer at Customer&#39;s address on the New Account
                  Application or at such other address as Advisor may hereafter
                  give to Apex on behalf of Customer. All communications so
                  sent, whether by mail, electronically, or otherwise, shall be
                  deemed given to Customer personally, whether actually received
                  or not. Customer shall be responsible for reviewing all
                  statements and confirmations of Account as well as
                  communications sent by Apex. Statements of Customer shall be
                  conclusive if not objected to in writing by Customer within
                  ten (10) days and confirmations shall be conclusive if not
                  objected to in writing by Customer within five (5) days, after
                  forwarding by Apex by mail or otherwise. In consideration of
                  Apex sending any mail to Customer in care of a post office box
                  or third party address, Customer hereby agrees that all
                  correspondence of any nature whatsoever sent to Customer at
                  such address will have the same force and effect as if it had
                  been delivered to Customer personally.
                </li>
                <li>
                  <strong>
                    ARBITRATION AGREEMENT. THIS AGREEMENT CONTAINS A PREDISPUTE
                    ARBITRATION CLAUSE. BY SIGNING AN ARBITRATION AGREEMENT THE
                    PARTIES AGREE AS FOLLOWS:
                  </strong>
                </li>
                <li>
                  <strong>
                    ALL PARTIES TO THIS AGREEMENT ARE GIVING UP THE RIGHT TO SUE
                    EACH OTHER IN COURT, INCLUDING THE RIGHT TO A TRIAL BY JURY
                    EXCEPT AS PROVIDED BY THE RULES OF THE ARBITRATION FORUM IN
                    WHICH A CLAIM IS FILED;
                  </strong>
                </li>
                <li>
                  <strong>
                    ARBITRATION AWARDS ARE GENERALLY FINAL AND BINDING; A
                    PARTY&#39;S ABILITY TO HAVE A COURT REVERSE OR MODIFY AN
                    ARBITRATION AWARD IS VERY LIMITED.
                  </strong>
                </li>
                <li>
                  <strong>
                    THE ABILITY OF THE PARTIES TO OBTAIN DOCUMENTS, WITNESS
                    STATEMENTS AND OTHER DISCOVERY IS GENERALLY MORE LIMITED IN
                    ARBITRATION THAN IN COURT PROCEEDINGS;
                  </strong>
                </li>
                <li>
                  <strong>
                    THE ARBITRATORS DO NOT HAVE TO EXPLAIN THE REASON(S) FOR
                    THEIR AWARD UNLESS, IN AN ELIGIBLE CASE, A JOINT REQUEST FOR
                    AN EXPLAINED DECISION HAS BEEN SUBMITTED BY ALL PARTIES TO
                    THE PANEL AT LEAST 20 DAYS PRIOR TO THE FIRST SCHEDULED
                    HEARING DATE.
                  </strong>
                </li>
                <li>
                  <strong>
                    THE PANEL OF ARBITRATORS WILL TYPICALLY INCLUDE A MINORITY
                    OF ARBITRATORS WHO WERE OR ARE AFFILIATED WITH THE
                    SECURITIES INDUSTRY.
                  </strong>
                </li>
                <li>
                  <strong>
                    THE RULES OF SOME ARBITRATION FORUMS MAY IMPOSE TIME LIMITS
                    FOR BRINGING A CLAIM IN ARBITRATION. IN SOME CASES, A CLAIM
                    THAT IS INELIGIBLE FOR ARBITRATION MAY BE BROUGHT IN COURT.
                  </strong>
                </li>
                <li>
                  <p>
                    <strong>
                      THE RULES OF THE ARBITRATION FORUM IN WHICH THE CLAIM IS
                      FILED, AND ANY AMENDMENTS THERETO, SHALL BE INCORPORATED
                      INTO THIS AGREEMENT.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      THIS ARBITRATION AGREEMENT SHOULD BE READ IN CONJUNCTION
                      WITH THE DISCLOSURES ABOVE. ANY AND ALL CONTROVERSIES,
                      DISPUTES OR CLAIMS BETWEEN OR AMONG APEX, ADVISOR, AND/OR
                      CUSTOMER OR THEIR REPRESENTATIVES, EMPLOYEES, DIRECTORS,
                      OFFICERS, OR CONTROL PERSONS, ARISING OUT OF, IN
                      CONNECTION WITH, FROM, OR WITH RESPECT TO (a) ANY
                      PROVISIONS OF OR THE VALIDITY OF THIS AGREEMENT OR ANY
                      RELATED AGREEMENTS, (b) THE RELATIONSHIP OF THE PARTIES
                      HERETO, OR (c) ANY CONTROVERSY ARISING OUT OF APEX&#39;S
                      BUSINESS, ADVISOR&#39;S BUSINESS OR THE CUSTOMER&#39;S
                      ACCOUNTS, SHALL BE CONDUCTED PURSUANT TO THE CODE OF
                      ARBITRATION PROCEDURE OF THE FINANCIAL INDUSTRY REGULATORY
                      AUTHORITY (FINRA). ARBITRATION MUST BE COMMENCED BY
                      SERVICE OF A WRITTEN DEMAND FOR ARBITRATION OR A WRITTEN
                      NOTICE OF INTENTION TO ARBITRATE. THE DECISION AND AWARD
                      OF THE ARBITRATOR(S) SHALL BE CONCLUSIVE AND BINDING UPON
                      ALL PARTIES, AND ANY JUDGMENT UPON ANY AWARD RENDERED MAY
                      BE ENTERED IN A COURT HAVING JURISDICTION THEREOF, AND
                      NEITHER PARTY SHALL OPPOSE SUCH ENTRY.
                    </strong>
                  </p>
                  <p>
                    No person shall bring a putative or certified class action
                    to arbitration, nor seek to enforce any pre-dispute
                    arbitration agreement against any person who has initiated
                    in court a putative class action; or who is a member of a
                    putative class who has not opted out of the class with
                    respect to any claims encompassed by the putative class
                    action until: (i) the class certification is denied; (ii)
                    the class is de-certified; or (iii) Customer is excluded
                    from the class by the court. Such forbearance to enforce an
                    agreement to arbitrate shall not constitute a waiver of any
                    rights under this Agreement except to extent stated herein.
                  </p>
                </li>
                <li>
                  <strong>Representations.</strong> Customer represents and
                  warrants that Customer is of majority age, is not an employee
                  of any exchange, corporation of which any exchange owns a
                  majority of stock, or member of any exchange, member firm, or
                  member corporation registered on any exchange, or of a bank,
                  trust company, insurance company, or of any corporation, firm,
                  or individual engaged in the business dealing either as broker
                  or as principal in securities, bills of exchange, acceptances,
                  or other forms of commercial paper. If Customer is a
                  corporation, partnership, trust, or other entity, Customer
                  represents that its governing instruments permit this
                  Agreement, that this Agreement has been authorized by all
                  require persons, and that the signatory on the New Account
                  Application is authorized to sign on behalf of and bind
                  Customer. Customer represents that it shall comply with all
                  applicable laws, rules, and regulations in connection with the
                  Account. Customer further represents that no one except
                  Customer has an interest in the Account or other accounts of
                  Customer with Apex.
                </li>
                <li>
                  <strong>Tax Treaty Eligibility.</strong> This Agreement shall
                  serve as the Customer&#39;s certification that Customer is
                  eligible to receive tax treaty benefits between the country of
                  residence indicated on the new account form and the country or
                  countries of origin holding jurisdiction over the instruments
                  held within Customer&#39;s account.
                </li>
                <li>
                  <strong>Joint Accounts.</strong> If the New Account
                  Application indicates that the Account shall consist of more
                  than one person, Customer&#39;s obligations under this
                  Agreement shall be joint and several among them all.
                  References to Customer shall include each of the customers
                  identified on the New Account Application. Apex may rely on
                  transfer or other instructions from Advisor or any one of the
                  Customers in a joint account, and such instructions shall be
                  binding on each Customer. Apex may deliver Assets, and send
                  confirmations, notices, statements and communications of every
                  kind to any one of the Customers, and such action shall be
                  binding on each Customer. Notwithstanding the foregoing, Apex
                  is authorized in its sole discretion to require joint action
                  by some or all Customers with respect to any matter concerning
                  the joint account, including but not limited to the giving or
                  cancellation of orders and the withdrawal of money or
                  securities. In the case of Tenants by the Entirety accounts,
                  joint action will be required for all matters concerning the
                  joint account. Tenants by Entirety accounts are not recognized
                  in certain jurisdictions, and, where not expressly allowed,
                  will not be a permitted designation of the account.
                </li>
                <li>
                  <strong>Custodial Accounts for Minors.</strong> If a custodial
                  account was selected, such as UTMA/UGMA, as the Customer Type
                  in the New Account Application, the custodian opening the
                  account (Custodian) understands Custodian has opened a
                  custodial account for a minor and acts as the account
                  custodian on behalf of the minor owner. It is Custodian&#39;s
                  obligation to adhere to all applicable laws and regulations
                  governing such accounts. Custodian should consult with an
                  attorney and/or tax advisor before opening a custodial
                  account. The age of majority varies by state. Custodian agrees
                  to promptly inform its advisor and broker in writing upon the
                  account owner reaching the age of majority and to facilitate
                  the transfer of the account assets to its beneficial owner.
                  Apex reserves the right, but shall not be obligated, to
                  terminate the custodianship and/or transfer the account assets
                  to the beneficial owner upon the minor reaching the age of
                  majority as determined by the state code Custodian has
                  included, without notice to or consent of Custodian.
                </li>
                <li>
                  <strong>Other Agreements.</strong> If the Customer trades any
                  options, the Customer agrees to be bound by the terms of your
                  Customer Option Agreement. The Customer understands that
                  copies of these agreements are available from you and, to the
                  extent applicable, are incorporated by reference herein. The
                  terms of these other agreements are in addition to the
                  provisions of this Agreement and any other written agreements
                  between you and the Customer.
                </li>
                <li>
                  <strong>Data Not Guaranteed.</strong> Customer expressly
                  agrees that any data or online reports is provided to the
                  Customer without warranties of any kind, express or implied,
                  including but not limited to, the implied warranties of
                  merchantability, fitness of a particular purpose or
                  non-infringement. Customer acknowledges that the information
                  contained in any reports provided by Apex is obtained from
                  sources believed to be reliable but is not guaranteed as to
                  its accuracy of completeness. Such information could include
                  technical or other inaccuracies, errors or omissions. In no
                  event shall Apex or any of its affiliates be liable to
                  Customer or any third party for the accuracy, timeliness, or
                  completeness of any information made available to Customer or
                  for any decision made or taken by Customer in reliance upon
                  such information. In no event shall Apex or its affiliated
                  entities be liable for any special incidental, indirect or
                  consequential damages whatsoever, including, without
                  limitation, those resulting from loss of use, data or profits,
                  whether or not advised of the possibility of damages, and on
                  any theory of liability, arising out of or in connection with
                  the use of any reports provided by you or with the delay or
                  inability to use such reports.
                </li>
                <li>
                  <strong>Payment for Order Flow Disclosure.</strong> Depending
                  on the security traded and absent specific direction from
                  Customer, equity and option orders are routed to market
                  centers (i.e., broker-dealers, primary exchanges or electronic
                  communication networks) for execution. Routing decisions are
                  based on a number of factors including the size of the order,
                  the opportunity for price improvement and the quality of order
                  executions, and decisions are regularly reviewed to ensure the
                  duty of best execution is met. Apex may receive compensation
                  or other consideration for the placing of orders with market
                  centers for execution. The amount of the compensation depends
                  on the agreement reached with each venue. The source and
                  nature of compensation relating to Customer&#39;s transactions
                  will be furnished upon written request.
                </li>
                <li>
                  <strong>Credit Check.</strong> Apex is authorized, in its
                  discretion, should Apex for any reason deem it necessary for
                  its protection, to request and obtain a consumer credit report
                  for Customer.
                </li>
                <li>
                  <strong>Miscellaneous.</strong> If any provision of this
                  Agreement is held to be invalid or unenforceable, it shall not
                  affect any other provision of this Agreement. The headings of
                  each section of this Agreement are descriptive only and do not
                  modify or qualify any provision of this Agreement. This
                  Agreement and its enforcement shall be governed by the laws of
                  the state of Texas and shall cover individually and
                  collectively all accounts which the Customer has previously
                  opened, now has open or may open or reopen with you, or any
                  introducing broker, and any and all previous, current and
                  future transactions in such accounts. Except as provided in
                  this Agreement, no provision of this Agreement may be altered,
                  modified or amended unless in writing signed by Apex&#39;s
                  authorized representative. This Agreement and all provisions
                  shall inure to the benefit of Apex and its successors, whether
                  by merger, consolidation or otherwise, Apex&#39;s assigns, and
                  all other persons specified in Paragraph 10. Apex shall not be
                  liable for losses caused directly or indirectly by any events
                  beyond Apex&#39;s reasonable control, including without
                  limitation, government restrictions, exchange or market
                  rulings, suspension of trading or unusually heavy trading in
                  securities, a general change in economic, political or
                  financial conditions, war or strikes. Apex may transfer the
                  accounts of Customer to Apex&#39;s successors and assigns.
                  This Agreement shall be binding upon Customer and the heirs,
                  executors, administrators, successors and assigns of Customer.
                  Failure to insist on strict compliance with this Agreement is
                  not considered a waiver of Apex&#39;s rights under this
                  Agreement. At Apex&#39;s discretion, it may terminate this
                  Agreement at any time on notice to the Customer and the
                  Customer will continue to be responsible for any obligation
                  incurred by the Customer prior to termination. Customer may
                  not assign Customer&#39;s rights or delegate Customer&#39;s
                  obligations under this Agreement, in whole or in part, without
                  Apex&#39;s prior consent.
                </li>
                <li>
                  <strong>SIPC Protection.</strong> As a member of the
                  Securities Investor Protection Corporation (SIPC), funds are
                  available to meet customer claims up to a ceiling of $500,000,
                  including a maximum of $250,000 for cash claims. For
                  additional information regarding SIPC coverage, including a
                  brochure, please contact SIPC at (202) 371-8300 or visit
                  www.sipc.org. Apex has purchased an additional insurance
                  policy through a group of London Underwriters (with
                  Lloyd&#39;s of London Syndicates as the Lead Underwriter) to
                  supplement SIPC protection. This additional insurance policy
                  becomes available to customers in the event that SIPC limits
                  are exhausted and provides protection for securities and cash
                  up to certain limits. Similar to SIPC protection, this
                  additional insurance does not protect against a loss in the
                  market value of securities.
                </li>
                <li>
                  <strong>Sweep Program.</strong> If the Customer elects to
                  participate in one of your FDIC or money market sweep
                  programs, the Customer acknowledges and agrees that: (a) the
                  Customer has read and understands the sweep program terms and
                  conditions and/or prospectuses available at{" "}
                  <a href="http://www.apexclearing.com/disclosures/">
                    http://www.apexclearing.com/disclosures/
                  </a>{" "}
                  and is aware of the products available in such sweep programs;
                  (b) you may make changes to your FDIC and/or money market
                  sweep programs and products at any time, in your sole
                  discretion and with or without notice to Customer; (c) the
                  free credit balances in the Customer&#39;s Account may begin
                  being included in the sweep program upon Account opening; and
                  (d) you have no obligation to monitor the applicable sweep
                  program elected for the Customer&#39;s Account or to make
                  recommendations about, or changes to, the sweep program that
                  might be beneficial to the Customer.
                </li>
                <li>
                  <strong>Trusted Contacts.</strong> Under FINRA Rule 4512 your
                  broker is required to disclose to you (the customer) that your
                  broker, Apex Clearing Corporation or an associated person of
                  your broker or Apex Clearing Corporation is authorized to
                  contact the trusted contact person and disclose information
                  about the customer&#39;s account to address possible financial
                  exploitation, to confirm the specifics of the customer&#39;s
                  current contact information, health status, or the identity of
                  any legal guardian, executor, trustee or holder of a power of
                  attorney, or as otherwise permitted by FINRA Rule 2165.
                </li>
                <li>
                  <strong>ACH Agreement.</strong> If Customer requests Automated
                  Clearinghouse (ACH) transactions from Customer&#39;s Account
                  at Apex, Customer authorizes Apex to originate or facilitate
                  transfer credits/debits to/from Customer&#39;s eligible bank
                  account. Transactions sent through the NACHA network will be
                  subject to all applicable rules of NACHA and all rules set
                  forth in Federal Reserve Operating circulars or other
                  applicable laws and regulations. ACH deposits to
                  Customer&#39;s brokerage account are provisional. If the
                  beneficiary bank does not receive final and complete payment
                  for a payment order transferred through ACH, the beneficiary
                  bank is entitled to recover from the beneficiary any
                  provisional credit and Apex may charge Customer&#39;s account
                  for the transaction amount. Customer understands Apex or
                  Customer&#39;s Advisor may not notify Customer of any returned
                  or rejected ACH transfers. Customer agrees to hold Apex and
                  Apex&#39;s agents free of liability for compliance with these
                  instructions. Customer hereby agrees to hold harmless Apex and
                  each of its affiliates, offices, directors, employees, and
                  agents against, any claims, judgments, expenses, liabilities
                  or costs of defense or settlement relating to: (a) any refusal
                  or failure to initiate or honor any credit or debit request,
                  by Apex or Advisor, whether (i) due to a lack of funds
                  necessary to credit Customer&#39;s account; (ii) due to
                  inadvertence, error caused by similarity of account holder
                  names or (iii) otherwise provided Apex has not acted in bad
                  faith; (b) if the routing number is incorrect or the routing
                  number or other information changes at another U.S. financial
                  institution or (c) any loss, damage, liability or claim
                  arising, directly or indirectly, from any error, delay or
                  failure which is caused by circumstances beyond Apex&#39;s
                  direct control. To the extent permitted by applicable law or
                  regulation, Apex hereby disclaims all warranties, express or
                  implied, and in no event shall Apex be liable for any special
                  indirect, incidental, or consequential damages whatsoever
                  resulting from the ACH electronic service or any ACH
                  transactions. Nothing in this herein shall constitute a
                  commitment or undertaking by Apex or Advisor to effect any ACH
                  transaction or otherwise act upon the instructions of Customer
                  or Advisor with respect to any account at Apex. This
                  authorization shall remain in full force and effect until
                  Customer revokes authorization by written notification to
                  Advisor that is forwarded to Apex. Customer understands that
                  Apex has the right to terminate or suspend the ACH agreement
                  at any time and without notice.
                </li>
              </ol>

              <h1 id="privacy-policy">Privacy Policy</h1>
              <p>
                {" "}
                Apex Clearing Corporation (Apex) carries your account as a
                clearing broker by arrangement with your broker-dealer or
                registered investment advisor as Apex&#39;s introducing client.
                At Apex, we understand that privacy is an important issue for
                customers of our introducing firms. It is our policy to respect
                the privacy of all accounts that we maintain as clearing broker
                and to protect the security and confidentiality of non-public
                personal information relating to those accounts. Please note
                that this policy generally applies to former customers of Apex
                as well as current customers.
              </p>
              <h2 id="personal-information-collected">
                Personal Information Collected
              </h2>
              <p>
                {" "}
                In order to service your account as a clearing broker,
                information is provided to Apex by your introducing firm who
                collects information from you in order to provide the financial
                services that you have requested. The information collected by
                your introducing firm and provided to Apex or otherwise obtained
                by Apex may come from the following sources and is not limited
                to:
              </p>
              <ul>
                <li>
                  Information included in your applications or forms, such as
                  your name, address, telephone number, social security number,
                  occupation, and income;
                </li>
                <li>
                  Information relating to your transactions, including account
                  balances, positions, and activity;
                </li>
                <li>
                  Information which may be received from consumer reporting
                  agencies, such as credit bureau reports;
                </li>
                <li>Information relating to your creditworthiness;</li>
                <li>
                  <p>
                    Information which may be received from other sources with
                    your consent or with the consent of your introducing firm.
                  </p>
                  <p>
                    In addition to servicing your account, Apex may make use of
                    your personal information for analysis purposes, for
                    example, to draw conclusions, detect patterns or determine
                    preferences.
                  </p>
                  <h2 id="sharing-of-non-public-personal-information">
                    Sharing of Non-public Personal Information
                  </h2>
                  <p>
                    Apex does not disclose non-public personal information
                    relating to current or former customers of introducing firms
                    to any third parties, except as required or permitted by
                    law, including but not limited to any obligations of Apex
                    under the USA PATRIOT Act, and in order to facilitate the
                    clearing of customer transactions in the ordinary course of
                    business.
                  </p>
                  <p>
                    Apex has multiple affiliates and relationships with third
                    party companies. Examples of these companies include
                    financial and non-financial companies that perform services
                    such as data processing and companies that perform
                    securities executions on your behalf. We may share
                    information among our affiliates and third parties, as
                    permitted by law, in order to better service your financial
                    needs and to pursue legitimate business interests, including
                    to carry out, monitor and analyze our business, systems and
                    operations.
                  </p>
                  <h2 id="security">Security</h2>
                  <p>
                    Apex strives to ensure that our systems are secure and that
                    they meet industry standards. We seek to protect non-public
                    personal information that is provided to Apex by your
                    introducing firm or otherwise obtained by Apex by
                    implementing physical and electronic safeguards. Where we
                    believe appropriate, we employ firewalls, encryption
                    technology, user authentication systems (i.e. passwords and
                    personal identification numbers) and access control
                    mechanisms to control access to systems and data. Apex
                    endeavors to ensure that third party service providers who
                    may have access to non-public personal information are
                    following appropriate standards of security and
                    confidentiality. Further, we instruct our employees to use
                    strict standards of care in handling the personal financial
                    information of customers. As a general policy, our staff
                    will not discuss or disclose information regarding an
                    account except; 1) with authorized personnel of your
                    introducing firm, 2) as required by law or pursuant to
                    regulatory request, or 3) as authorized by Apex to a third
                    party or affiliate providing services to your account or
                    pursuing Apex&#39;s legitimate business interests.
                  </p>
                  <h2 id="access-to-your-information">
                    Access to Your Information
                  </h2>
                  <p>
                    You may access your account information through a variety of
                    media offered by your introducing firm and Apex (i.e.
                    statements or online services). Please contact your
                    introducing firm if you require any additional information.
                    Apex may use cookies in order to provide better service, to
                    facilitate its customers&#39; use of the website, to track
                    usage of the website, and to address security hazards. A
                    cookie is a small piece of information that a website stores
                    on a personal computer, and which it can later retrieve.
                  </p>
                  <h2 id="changes-to-apex-s-privacy-policy">
                    Changes to Apex&#39;s Privacy Policy
                  </h2>
                  <p>Apex reserves the right to make changes to this policy.</p>
                  <h2 id="how-to-get-in-touch-with-apex-about-this-privacy-policy">
                    How to Get in Touch with Apex about this Privacy Policy
                  </h2>
                  <p>
                    For reference, this Privacy Policy is available on our
                    website at www.apexclearing.com. For more information
                    relating to Apex&#39;s Privacy Policy or to limit our
                    sharing of your personal information, please contact:
                  </p>
                  <p>
                    Apex Clearing Corporation Attn: Compliance Department 350 N.
                    St. Paul St., Suite 1300 Dallas, Texas 75201
                    214-765-1055&quot;,
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* USER AGREEMENT */}
      <div style={{ width: "100%" }} id="user-agreement">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: 40,
            alignItems: "center",
          }}
        >
          <p style={{ margin: 0, fontSize: 40, fontWeight: "bold" }}>
            Apex Crypto User Agreement
          </p>
        </div>
        <p className="terms-text">Last Updated: June 25, 2019</p>
        <p className="terms-text">
          This user account agreement ("User Agreement") sets forth the
          respective rights and obligations between you, Apex Clearing
          Corporation ("Apex Clearing"), and Apex Crypto LLC, a limited
          liability company formed under the laws of Delaware ("Apex Crypto,"
          "we," or "us"). Your brokerage firm shall be referred to as the
          "Correspondent" hereunder but shall not be considered a party to this
          User Agreement. Please read through this User Agreement carefully
          before registering an account and accessing or using the Services (as
          defined herein). BY REGISTERING AN ACCOUNT WITH APEX CRYPTO
          ("Account"), OR BY ACCESSING OR USING OUR SERVICES, YOU AGREE TO BE
          LEGALLY BOUND TO THE TERMS AND CONDITIONS CONTAINED IN THIS USER
          AGREEMENT IN THEIR ENTIRETY, INCLUDING ALL TERMS INCORPORATED BY
          REFERENCE. IF YOU DO NOT AGREE TO ANY OF THESE TERMS AND CONDITIONS,
          INCLUDING THE MANDATORY ARBITRATION PROVISION AND CLASS ACTION WAIVER
          IN SECTION 17, AND THE MANDATORY INFORMATION SHARING PROVISION IN
          SECTION 5.1, DO NOT REGISTER AN ACCOUNT OR ACCESS OR USE THE SERVICES.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          1. Scope of Services
        </h3>
        <p className="terms-text">
          This User Agreement applies to your access to and use of our Services,
          as described herein. By registering an Account with Apex Crypto, in
          accordance with the terms and conditions contained in this User
          Agreement, you will be able to: (i) enter into transactions with Apex
          Crypto through your Correspondent's mobile application or website(s)
          (the "Platform") to purchase supported cryptocurrencies for personal,
          non-commercial purposes; (ii) enter into transactions with Apex Crypto
          through the Platform to sell cryptocurrencies that you previously
          purchased from Apex Crypto (purchase and sell transactions are
          referred to herein, collectively, as "Cryptocurrency Transactions");
          (iii) store cryptocurrency that you acquired through Cryptocurrency
          Transactions in one or more hosted cryptocurrency wallets ("Hosted
          Wallets"); and (iv) track your Cryptocurrency Transactions and Hosted
          Wallet balance(s) through the Platform, (collectively, the
          "Services"). You agree, represent and warrant that the Services
          constitute financial and investment transactions and that no consumer
          transactions are contemplated by or permitted under this User
          Agreement.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          2. Modifications to this User Agreement
        </h3>
        <p className="terms-text">
          We reserve the right to make changes to this User Agreement at any
          time and at our sole discretion. If we make changes, we will post the
          amended User Agreement to apexcrypto.com and update the "Last Updated"
          date above. We may also attempt to notify you by sending an email
          notice to the address associated with your Account or by providing
          notice through the Platform. Using a particular form of notice in some
          instances does not obligate us to use the same form in other
          instances. Unless we say otherwise in our notice, the amended User
          Agreement will be effective immediately and will apply to any then
          current and subsequent uses of the Services, including any pending
          Cryptocurrency Transactions. You are responsible for reviewing this
          User Agreement each time you access or use our Services. Your
          continued access to and use of your Account and/or the Services after
          we provide notice will constitute your acceptance of the changes. If
          you do not agree to any of the amended terms and conditions, you must
          stop accessing and using the Services and close your Account
          immediately.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            3. Eligibility
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            3.1 General Requirements
          </h3>
        </div>
        <p className="terms-text">
          To register for an Account or access or use the Services you must be
          an individual at least 18 years of age and have the legal capacity to
          enter into this User Agreement. In addition, you must have a brokerage
          account, in good standing, held at Apex Clearing ("Brokerage
          Account").
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          3.2 General Requirements
        </h3>
        <p className="terms-text">
          You may not register an Account or use the Services if you are a
          resident of any state in which Apex Crypto is not authorized to
          provide the Services ("Restricted States"). A current list of
          Restricted States, which is incorporated by reference, is available at
          apexcrypto.com and is subject to change at any time.
        </p>
        <p className="terms-text">
          You hereby represent and warrant that you are not a resident of any
          Restricted State and that you will not register an Account or use the
          Services even if our methods to prevent you from registering an
          account or using the Services are not effective or can be bypassed.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          3.3 Restricted States
        </h3>
        <p className="terms-text">
          You may not register an Account or access or use the Services if you
          are located in, under the control of, or a citizen or resident of any
          state, country, territory or other jurisdiction to which the United
          States has embargoed goods or services, or where your use of the
          Services would be illegal or otherwise violate any applicable law of
          such jurisdiction or of the United States ("Restricted Jurisdiction").
          We may implement controls to restrict access to the Services from any
          Restricted Jurisdiction.{" "}
        </p>
        <p className="terms-text">
          You hereby represent and warrant that you are not under the control
          of, or a citizen or resident of any Restricted Jurisdiction and that
          you will not access or use any Services while located in any
          Restricted Jurisdiction, even if our methods to prevent access to and
          use of the Services from these jurisdictions are not effective or can
          be bypassed.{" "}
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          3.4 Restricted Persons
        </h3>
        <p className="terms-text">
          You hereby represent and warrant that you have not been identified as
          a Specially Designated National or placed on any sanctions list by the
          U.S. Treasury Department's Office of Foreign Assets Control, the U.S.
          Commerce Department, or the U.S. Department of State; and you will not
          use our Services to conduct any illegal or illicit activity.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            4. Account Registration
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            4.1 Personal Information
          </h3>
        </div>
        <p className="terms-text">
          During the Account registration process you must provide us with
          information and documentation that we request for the purpose of
          establishing and verifying your identity ("Personal Information"). In
          addition, as a condition to accessing and using the Services, you must
          authorize Apex Clearing to share your Personal Information with us
          pursuant to Section 5.1. Personal Information may include your name,
          email address, residential address, phone number, date of birth, and
          taxpayer identification number. Personal Information may also include
          documentation, such as copies of your government-issued photo
          identification (for example, your passport, driver's license, or
          military identification card), account statements, and other documents
          as we may require. Personal Information will be retained by us at our
          discretion and in accordance with applicable laws. You agree to
          provide us accurate, current and complete Personal Information.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          4.2 Verification
        </h3>
        <p className="terms-text">
          You hereby authorize us, or a third-party service provider that we
          designate, to take any measures that we consider necessary to confirm
          the Personal Information you provide, verify and authenticate your
          Personal Information, and take any action we deem necessary based on
          the results. You acknowledge that this process may result in a delay
          in registering your Account, and that you will not be authorized to
          access or use the Services until your Account registration has been
          successfully completed.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            5. Account Authorizations
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            5.1 Mandatory Information Sharing
          </h3>
        </div>
        <p className="terms-text">
          You hereby authorize your Correspondent and Apex Clearing to provide
          Apex Crypto any of your personal information maintained in connection
          with your Brokerage Account, and to continue sharing such information,
          and any revisions or additions thereto, with Apex Crypto on an ongoing
          basis until your Account is either closed or terminated in accordance
          with this User Agreement. You hereby authorize Apex Crypto to provide
          your Correspondent and Apex Clearing any of your Personal Information,
          and to continue sharing such information, and any revisions or
          additions thereto, with them on an ongoing basis until your account(s)
          with your Correspondent or Apex Clearing is closed pursuant to the
          terms and conditions governing such account(s).
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          5.2 Authorization to Apex Crypto
        </h3>
        <p className="terms-text">
          You understand and acknowledge that your Account is self-directed, and
          you hereby appoint Apex Crypto as your agent for the purpose of
          carrying out your instructions in accordance with this User Agreement.
          You hereby authorize Apex Crypto to open and close your Account, place
          and cancel orders to purchase and sell cryptocurrency ("Orders"),
          collect and offset any fees or other amounts due to Apex Crypto, and
          take such other steps as are reasonable to carry out your
          instructions.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          5.3 Authorization to Apex Clearing
        </h3>
        <p className="terms-text">
          You understand and acknowledge that when you submit an Order through
          the Platform to purchase cryptocurrency, you are authorizing and
          instructing Apex Crypto to instruct Apex Clearing to accept such
          instruction to transfer the funds required for the Cryptocurrency
          Transaction from your Brokerage Account to either (i) an account held
          at Apex Clearing in the name of Apex Crypto; or (ii) an external bank
          account in the name of Apex Crypto, (each such account, an "Entity
          Account"). You understand and acknowledge that your Account is
          provided by Apex Crypto and is separate from your Brokerage Account.
          You further understand and acknowledge that Apex Crypto is not under
          the control of Apex Clearing, that any Entity Account is outside of
          Apex Clearing's possession and control, and that Apex Clearing does
          not have the ability to monitor or recall the funds after such funds
          have been wired or transferred to an Entity Account. You further
          understand and acknowledge that, upon transfer from your Brokerage
          Account, the funds will no longer be provided protection under the
          Federal Deposit Insurance Corporation ("FDIC") or the Securities
          Investor Protector Corporation ("SIPC").
        </p>
        <p className="terms-text">
          You agree to hold Apex Clearing harmless with respect to Apex Clearing
          accepting and following your instructions to transfer funds from your
          Brokerage Account to an Entity Account. You hereby authorize and
          instruct Apex Clearing to accept all instructions to deposit funds
          into your Brokerage Account from an Entity Account at the sole
          instruction of Apex Crypto. You understand that you may revoke this
          authorization at any time by terminating your Apex Crypto Account.
        </p>
        <p className="terms-text">
          You understand and acknowledge that cryptocurrencies in your Apex
          Crypto Account are not held at Apex Clearing and that Apex Clearing is
          not involved in the purchase, sale, execution, custody, or trading of
          cryptocurrencies, and only takes instructions regarding transferring
          funds as described in this User Agreement.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          6. Use of Margin is Strictly Prohibited
        </h3>
        <div>
          <p className="terms-text">
            Apex Crypto does not provide margin trading, and margin trading is
            not including as part of the Services.
          </p>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            7. Account Management
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            7.1 Account Access
          </h3>
        </div>
        <p className="terms-text">
          Once you have successfully registered an Account, you will be able to
          access your Account by logging into the Platform using the username
          and password associated with the Platform (collectively, "Login
          Credentials"). You may only access your Account using your Login
          Credentials, or as may be authorized or required by us and your
          Correspondent. From time to time, Apex Crypto or your Correspondent
          may require you to change your Login Credentials and re-verify your
          Personal Information at their discretion and as a condition to your
          continued access to and use of your Account and the Services.
        </p>
        <p className="terms-text">
          You hereby represent and warrant that you will not share your Login
          Credentials with any third-party or permit any third-party to gain
          access to your Account, and you hereby assume responsibility for any
          instructions, orders, or actions provided or taken by anyone who has
          accessed your Account regardless of whether the access was authorized
          or unauthorized.
        </p>
        <p className="terms-text">
          Only individuals that have successfully registered an Account
          ("Users") are authorized to access and use the Services.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          7.2 Account Maintenance
        </h3>
        <p className="terms-text">
          You are required to maintain the information associated with your
          Account ("Account Profile") updated at all times, and you agree to
          update your account profile immediately of any changes to Personal
          Information that you previously provided. YOU UNDERSTAND THAT A CHANGE
          IN YOUR STATE OF RESIDENCE MAY IMPACT YOUR ELIGIBILITY TO ACCESS AND
          USE THE SERVICES, AND HEREBY AGREE TO NOTIFY US IN ADVANCE OF ANY
          CHANGE IN YOUR STATE OF RESIDENCE. PLEASE REFER TO OUR LIST OF
          RESTRICTED STATES, WHICH IS AVAILABLE at apexcrypto.com.
        </p>
        <p className="terms-text">
          As part of our legal compliance program ("AML Program"), we will
          monitor your Account and your use of the Services, and review your
          Personal Information on an ongoing basis, as may be required by law
          and/or pursuant to our internal policies and procedures. At any time,
          we may require you to provide us with additional Personal Information
          as a condition to your continued access to and use of your Account and
          the Services. During such time, your access to and use of your Account
          and the Services may be temporarily restricted.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          7.3 Account Security
        </h3>
        <p className="terms-text">
          You are solely responsible for managing and maintaining the security
          of your Login Credentials and any other forms of authentication, and
          you understand and acknowledge that we are not responsible (and you
          will not hold us responsible) for any unauthorized access to and or
          use of your Login Credentials and/or Account ("Unauthorized Access").
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          7.4 Unauthorized Account Activity
        </h3>
        <p className="terms-text">
          You are solely responsible for monitoring your Account for
          unauthorized or suspicious instructions, orders, actions, or
          transactions ("Unauthorized Activity"), and agree that we are not
          responsible (and you will not hold us responsible) for any
          Unauthorized Activity.
        </p>
        <p className="terms-text">
          You agree to protect your Account from Unauthorized Activity by (i)
          reviewing, on an ongoing basis, your transaction history and your
          Account Profile; (ii) reviewing, immediately upon receipt, any
          Confirmations, confirmations and notices we provide through the
          Platform, post to your Account or send to the email address associated
          with your Account; and (iii) verifying that you receive a confirmation
          from us that an Order or instruction you provide was received,
          executed or cancelled, as applicable, and (iv) in the event of not
          having received any such confirmation, notifying us within twenty-four
          (24) hours of such Order or instruction.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          7.5 Potentially Fraudulent Activity
        </h3>
        <p className="terms-text">
          Any actual or suspected Unauthorized Access and/or Unauthorized
          Activity will be treated by Apex Crypto as potentially fraudulent
          ("Potentially Fraudulent Activity"). You agree to notify us within
          twenty-four (24) hours if you become aware of or suspect any
          Potentially Fraudulent Activity, by emailing
          AccountSecurity@apexcrypto.com. For avoidance of doubt, you are deemed
          to be aware of Potentially Fraudulent Activity upon receipt of any
          notice of the occurrence of such activity. Upon receipt of written
          notice from you any Potentially Fraudulent Activity, we will take
          reasonable steps to protect your Account, including, for example, by
          temporarily restricting access to your Account, suspending any pending
          Cryptocurrency Transactions, and/or requiring you to change your Login
          Credentials.
        </p>
        <p className="terms-text">
          You agree to promptly report any Potentially Fraudulent Activity to
          legal authorities and provide us a copy of any report prepared by such
          legal authorities. In the event of an investigation of any Potentially
          Fraudulent Activity, you further agree to (i) cooperate fully with the
          legal authorities and Apex Crypto in such investigation; (ii) complete
          any required affidavits promptly, accurately and thoroughly; and (iii)
          allow Apex Crypto, or any third-party designated by us, access to your
          mobile device, computer, and network as may be relevant to such
          investigation. You understand and acknowledge that any failure to
          cooperate in any such investigation may cause delays in regaining
          access to your Account and any cryptocurrency held in your Hosted
          Wallet(s).
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            8. Account Closure
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            8.1 Account Closure
          </h3>
        </div>
        <p className="terms-text">
          You may close your Account at any time and for any reason unless Apex
          Crypto believes, at our sole discretion, that such closure is being
          performed in an effort to evade an investigation. Closing an Account
          will not affect any rights or obligations incurred prior to the date
          of closure in accordance with this User Agreement. Prior to closing
          your Account, you must liquidate any cryptocurrency balance(s)
          maintained in your Hosted Wallet(s), the proceeds of which shall be
          transferred to your Brokerage Account.{" "}
        </p>
        <p className="terms-text">
          You understand and acknowledge that you are solely responsible for any
          fees, costs, expenses, charges or obligations (collectively, "Costs")
          associated with the closing of your Account. In the event any incurred
          Costs exceed the value of your Account, you understand and acknowledge
          that you are responsible for reimbursing Apex Crypto the value of such
          Costs and that you will remain liable to Apex Crypto for all
          obligations incurred in your Account, pursuant to this User Agreement,
          or otherwise, whether arising before or after the closure of your
          Account or the termination of this User Agreement.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          8.2 Account Suspension or Termination
        </h3>
        <p className="terms-text">
          By using the Services, you understand and acknowledge that we have the
          right, at our sole discretion, to immediately suspend or terminate
          your Account and/or freeze any cryptocurrency balance(s) in your
          Hosted Wallet(s), without prior notice if (i) we suspect, at our sole
          discretion, you to be in violation of any provision of this User
          Agreement, our AML Program, or any applicable laws or regulations;
          (ii) we are required to do so by law or by any regulatory authority,
          court order, facially valid subpoena, or binding order of a government
          authority; (iii) we suspect any suspicious or Unauthorized Activity or
          any actual or attempted unauthorized access to your Account or Login
          Credentials; (iv) your account with the correspondent has been
          suspended or terminated or you no longer have access to the Platform;
          or (v) the Account has not been accessed in two years or more. You
          must notify Apex Crypto if you intend to, or actually do, close your
          account with Correspondent.
        </p>
        <p className="terms-text">
          If your Account has been suspended or terminated, you will be notified
          when you attempt to access the Services. We may, at our sole
          discretion and as permitted by law, provide written notice that your
          Account has been suspended or terminated.
        </p>
        <p className="terms-text">
          Upon termination of your Account, unless prohibited by law, any
          remaining cryptocurrency balance(s) in your Hosted Wallet(s) may be
          liquidated and the proceeds, less any Costs, will be returned to your
          Brokerage Account, at the discretion of Apex Crypto. In the event your
          Account is terminated, you hereby authorize Apex Crypto to sell any
          such remaining cryptocurrency balance(s) in your Hosted Wallet(s) at
          the prevailing market price(s), and transfer the proceeds to your
          Brokerage Account, less any Costs associated with such sale(s), the
          Account, or its termination.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            9. Cryptocurrencies
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            9.1 Definition
          </h3>
        </div>
        <p className="terms-text">
          As used herein, "cryptocurrency" (also referred to as "virtual
          currency," "digital currency," "digital asset," and "digital
          commodity") means a digital representation of value which is based on
          a cryptographic protocol that can be digitally traded and functions
          as: (1) a medium of exchange; and/or (2) a unit of account; and/or (3)
          a store of value, but does not have legal tender status and is not
          issued or guaranteed by any government. Cryptocurrency is
          distinguished from "fiat currency" (such as U.S. dollars), which is
          the coin and paper money of a country that is designated as its legal
          tender. For more information, please refer to the FATF Report, Virtual
          Currencies, Key Definitions and Potential AML/CFT Risks, FINANCIAL
          ACTION TASK FORCE (June 2014), available here.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          9.2 Supported Cryptocurrencies
        </h3>
        <p className="terms-text">
          A list of cryptocurrencies that Apex Crypto currently supports is
          available via the Platform. Apex Crypto may delist a cryptocurrency,
          at any time and for any reason at our sole discretion, including due
          to changes in a given cryptocurrency's characteristics after Apex
          Crypto has listed the cryptocurrency or due to a change in the
          cryptocurrency's regulatory classification.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          9.3 Network Control
        </h3>
        <p className="terms-text">
          Apex Crypto does not own or control any of the underlying software
          through which blockchain networks are formed and cryptocurrencies are
          created and transacted. In general, the underlying software for
          blockchain networks tends to be open source such that anyone can use,
          copy, modify, and distribute it. By using the Services, you understand
          and acknowledge that Apex Crypto is not responsible for operation of
          the underlying software and networks that support cryptocurrencies and
          that Apex Crypto makes no guarantee of functionality, security, or
          availability of such software and networks.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          9.4 Forks
        </h3>
        <p className="terms-text">
          The underlying protocols are subject to sudden changes in operating
          rules, and third parties may from time to time create a copy of a
          cryptocurrency network and implement changes in operating rules or
          other features ("Forks") that may result in more than one version of a
          network (each, a "Forked Network") and more than one version of a
          cryptocurrency ("Forked Assets").
        </p>
        <p className="terms-text">
          You understand and acknowledge that Forked Networks and the available
          supply of any Forked Assets are wholly outside of the control of Apex
          Crypto, and you further acknowledge that our ability to deliver Forked
          Assets resulting from a Forked Network may depend on third-parties
          that are outside of our control. You understand and acknowledge that
          Forks may materially affect the value, function, and/or even the name
          of the cryptocurrencies you store in your Hosted Wallet(s). In the
          event of a Fork, you understand and acknowledge that Apex Crypto may
          temporarily suspend the Services (with or without advance notice to
          you) while we determine, at our sole discretion, which Forked
          Network(s) to support.
        </p>
        <p className="terms-text">
          YOU ACKNOWLEDGE AND HEREBY AGREE THAT APEX CRYPTO MAY DETERMINE, IN
          OUR SOLE DISCRETION, NOT TO SUPPORT A FORKED NETWORK AND THAT YOU HAVE
          NO RIGHT, CLAIM, OR OTHER PRIVILEGE TO FORKED ASSETS ON SUCH
          UNSUPPORTED FORKED NETWORK. YOU HEREBY FURTHER AGREE THAT APEX CRYPTO
          MAY, IN OUR SOLE DISCRETION, DETERMINE THE APPROACH TO SUCH FORKED
          ASSETS, WHICH MAY INCLUDE ABANDONING OR OTHERWISE ELECTING NOT TO
          SUPPORT SUCH FORKED ASSETS AS PART OF THE SERVICES. YOU UNDERSTAND AND
          HEREBY ACKNOWLEDGE THAT APEX CRYPTO IS UNLIKELY TO SUPPORT MOST FORKED
          NETWORKS AND THAT MOST FORKED ASSETS WILL LIKELY NOT BE MADE AVAILABLE
          TO YOU.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          9.5 Risks of Cryptocurrency Trading
        </h3>
        <p className="terms-text">
          There are several risks associated with cryptocurrencies and
          cryptocurrency trading. By accessing and using the Services, you
          hereby represent and warrant that you have read our Cryptocurrency
          Risk Disclosure available at apexcrypto.com\\legal, which is
          incorporated by reference.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            10. Cryptocurrency Transactions
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            10.1 Market Orders
          </h3>
        </div>
        <p className="terms-text">
          Orders to purchase or sell cryptocurrency at the prevailing market
          price ("Market Orders") are based on the prevailing market price at
          the time the Market Order is filled. You understand and acknowledge
          that the price at which your Market Order is filled may vary from the
          price displayed at the time your Market Order is placed, and you
          further understand and acknowledge that Apex Crypto may, at its sole
          discretion, cancel any Market Order if the prevailing market price
          changes significantly from the time the Market Order was placed. You
          further understand and acknowledge that Apex Crypto may, at its sole
          discretion, cancel any Market Order. Apex Crypto, or Apex Crypto
          through your Correspondent, will send you a notification upon the
          occurrence of a cancellation of any Market Order.{" "}
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.2 Order Placement
        </h3>
        <p className="terms-text">
          Orders are placed on the Platform through your Account using your
          Login Credentials. You understand that all Orders to purchase or sell
          cryptocurrency will be executed in reliance on your promise that an
          actual purchase or sale is intended, and that Apex Crypto requires
          full payment in cleared, non-margined funds at the time an Order is
          filled. You further understand and acknowledge that you are not
          permitted to place an Order to purchase cryptocurrency unless you have
          enough non-margined funds available in your Brokerage Account, and
          that you are not permitted to place an Order to sell cryptocurrency
          unless you have enough cryptocurrency available in your Apex Crypto
          Account.
        </p>
        <p className="terms-text">
          You understand and acknowledge that Apex Crypto does not guarantee
          that an Order you place will be filled, and you further understand and
          acknowledge that Apex Crypto reserves the right to cancel any Order or
          part of an Order, if such Order (i) was placed during a scheduled or
          unscheduled downtime of Apex Crypto, Apex Clearing, or your
          Correspondent, (ii) violates the terms of this User Agreement, or
          (iii) is non-marketable.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.3 Order Routing
        </h3>
        <p className="terms-text">
          All Orders will be routed to us by or through your Correspondent. By
          using the Services, you understand and acknowledge that all Orders
          routed to us by or through your Correspondent which are placed through
          your Account using your Login Credentials are your Orders, and you
          agree to be bound by all terms and conditions pertaining to such
          Orders in accordance with this User Agreement.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.4 Source of Funds; Destination of Proceeds
        </h3>
        <p className="terms-text">
          Apex Crypto only authorizes the purchase of cryptocurrency using
          non-margined funds maintained in your Brokerage Account. You
          understand and acknowledge that you are not allowed to use funds that
          are not owned by you to enter into Cryptocurrency Transactions, and
          that you are not authorized to enter into Cryptocurrency Transactions
          on behalf of any third party. You hereby represent and warrant that
          all funds used by you to enter into Cryptocurrency Transactions
          (presently and in the future) are owned by you and are not the direct
          or indirect proceeds of any criminal or fraudulent activity.
        </p>
        <p className="terms-text">
          You understand and acknowledge that any proceeds from the sale of
          cryptocurrency shall be returned to your Brokerage Account, and that
          you will not have the option to transfer proceeds to any other
          account, including an account that you own.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.5 Transaction Limits
        </h3>
        <p className="terms-text">
          You understand and acknowledge that your use of the Services is
          subject to a limit on volume (in USD terms) that you may transact in a
          given time period. Such limits may vary, at our sole discretion,
          depending on a variety of factors, and we may apply higher limits to
          certain Users at our discretion.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.6 Transaction Record
        </h3>
        <p className="terms-text">
          When your Order is filled, Apex Crypto, or your Correspondent on
          behalf of Apex Crypto, will send your confirmation (a "Confirmation")
          through the Platform or to the email address associated with your
          Account. We may choose, at our sole discretion and without prior
          notice to you, to periodically consolidate Orders into a single
          Confirmation. Confirmations contain the following information: (i)
          Cryptocurrency Transaction date; (ii) Order type; (iii) trading pair;
          (iv) amount and U.S. dollar value of cryptocurrency purchased or sold;
          (v) price information; (vi) fees; (vii) a unique Order identification
          number; and (viii) any other information we may elect to include at
          our sole discretion or as required by law.
        </p>
        <p className="terms-text">
          A record of your Cryptocurrency Transactions ("Transaction History")
          conducted during the preceding twelve (12) months is available through
          your Account on the Platform. Transaction History that is older than
          twelve (12) months may be requested by emailing apexcrypto.com or by
          accessing your monthly statements on the Platform.
        </p>
        <div>
          <p className="terms-text">
            Apex Crypto maintains records of all Cryptocurrency Transactions for
            a period as required by law.
          </p>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            10.7 Right to Offset
          </h3>
        </div>
        <p className="terms-text">
          In the event that you fail to provide sufficient funds at the time an
          Order is filled, Apex Crypto reserves the right, in our sole
          discretion, to cancel, reverse or fill such Order in whole or in part.
          You agree to be responsible for the costs of any such action taken by
          Apex Crypto. You understand and acknowledge that if a failure to
          provide sufficient funds results in an amount due and owing by you to
          Apex Crypto (a "Negative Balance"), Apex Crypto shall have the right
          to (i) charge a reasonable rate of interest on any such Negative
          Balance; (ii) offset any such Negative Balance, including any accrued
          interest and consequential losses, by transferring funds that are or
          become available in your Brokerage Account to Apex Crypto.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            10.8 Cancellations
          </h3>
          <p className="terms-text">
            Orders may not be cancelled after they are filled.
          </p>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            10.9 Errors
          </h3>
        </div>
        <p className="terms-text">
          You are solely responsible for reviewing your Confirmations and for
          monitoring your Transaction History for any errors (or any Potentially
          Fraudulent Activity, as outlined in Section 7.5). You understand and
          acknowledge that if you have not received a Confirmation or an Order
          cancellation notice, you are responsible for notifying us via email at
          support@apexcrypto.com within twenty-four (24) hours of placing the
          Order. You further understand and acknowledge that you are solely
          responsible for reviewing your Confirmations for accuracy and for
          notifying us of any suspected errors via email at
          support@apexcrypto.com within twenty-four (24) hours of the time any
          such Confirmation was sent. You hereby waive your right to dispute any
          Cryptocurrency Transaction unless you notify us of any objections
          within twenty-four (24) hours of the time in which the applicable
          Confirmation was sent.
        </p>
        <p className="terms-text">
          You understand and acknowledge that Apex Crypto reserves the right,
          but has no obligation, to declare null and void a Cryptocurrency
          Transaction that Apex Crypto considers to be erroneous. A
          Cryptocurrency Transaction may be deemed erroneous when its price is
          substantially inconsistent with the prevailing price at the time the
          Order is filled (each, an "Erroneous Transaction"). You understand and
          acknowledge that you are responsible for ensuring that the appropriate
          price and Order type is submitted when you place an Order to purchase
          or sell cryptocurrency with Apex Crypto, and that a simple assertion
          by you that a mistake was made in entering an Order, or that you
          failed to pay attention to or update an Order, shall not be sufficient
          to establish it as an Erroneous Transaction. You further understand
          and acknowledge that if Apex Crypto determines that a given
          Cryptocurrency Transaction is an Erroneous Transaction, Apex Crypto
          may, at our sole discretion, declare it null and void, in whole and in
          part, even if you do not agree to cancel or modify it, in which case
          you shall return the cryptocurrency or funds received in the Erroneous
          Transaction.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          10.10 Downtime
        </h3>
        <p className="terms-text">
          Apex Crypto, Apex Clearing, and your Correspondent use commercially
          reasonable efforts to provide the Services in a reliable and secure
          manner. From time to time, interruptions, errors, delays, or other
          deficiencies in providing the Services may occur due to a variety of
          factors, some of which are outside of Apex Crypto's control, and some
          which may require or result in scheduled maintenance or unscheduled
          downtime of the Platform or the Services (collectively, "Downtime").
        </p>
        <p className="terms-text">
          You understand and acknowledge that part or all of the Platform or
          Services may be unavailable during any such period of Downtime, and
          you acknowledge that Apex Crypto is not liable or responsible to you
          for any inconvenience or losses to you as a result of Downtime.
          Following Downtime, you further understand and acknowledge that the
          prevailing market prices of cryptocurrency may differ significantly
          from the prices prior to such downtime.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          11. Hosted Wallets
        </h3>
        <p className="terms-text">
          Cryptocurrencies that you purchase from Apex Crypto shall be stored as
          part of the Services in one or more omnibus Hosted Wallets. Apex
          Crypto shall track the balance and ownership of cryptocurrencies
          purchased through the Services and stored in Hosted Wallets, and you
          understand that you can view the balance of cryptocurrencies in your
          Account through the Platform.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            12. General Terms of Service{" "}
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            12.1 Fees
          </h3>
        </div>
        <p className="terms-text">
          A schedule of our fees is accessible by logging into the Platform.
          Fees are subject to change, at our sole discretion, and you understand
          and acknowledge that you are solely responsible for accessing the fee
          schedule prior to placing an Order. You hereby acknowledge that your
          placement of an Order will constitute your acceptance of the fee
          published at the time the Order is placed.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          12.2 Self-Directed Account
        </h3>
        <p className="terms-text">
          You understand and acknowledge that your Account is self-directed, you
          are solely responsible for any and all Orders placed through your
          Account, and all Orders entered by you are unsolicited and based on
          your own investment decisions. You understand and acknowledge that you
          have not received and do not expect to receive any investment advice
          from Apex Crypto or any of its Affiliates (including Apex Clearing) in
          connection with your Orders. Notwithstanding anything in this User
          Agreement, you understand and acknowledge that Apex Crypto accepts no
          responsibility whatsoever for, and shall in no circumstances be liable
          to you in connection with, your decisions. You further understand and
          acknowledge that under no circumstances will your use of the Services
          be deemed to create a relationship that includes the provision or
          tendering of investment advice. You acknowledge that neither Apex
          Crypto, nor any of our Affiliates (including Apex Clearing), nor any
          of our or our Affiliates' employees, agents, principals, or
          representatives (i) provide investment advice in connection with this
          Account, (ii) recommend any cryptocurrencies, Cryptocurrency
          Transactions, or Orders, or (iii) solicit Orders. To the extent
          research materials or similar information are available through the
          Platform, I understand that these materials are intended for
          informational and educational purposes only and they do not constitute
          a recommendation to enter into any Cryptocurrency Transactions or to
          engage in any investment strategies.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          12.3 Communications
        </h3>
        <p className="terms-text">
          You understand and acknowledge that all notices and communication with
          you will be through the Platform, your Account, or via an email sent
          by us, or by your Correspondent on behalf of us, to the email address
          associated with your Account. To ensure that you receive all of our
          communications, you agree to keep your email address up-to-date and
          immediately notify us if there are any changes. Delivery of any
          communication to the email address associated with your Account is
          considered valid. If any email communication is returned as
          undeliverable, we retain the right to block your access to your
          Account until you provide and confirm a new and valid email address.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          12.4 Taxes
        </h3>
        <p className="terms-text">
          You understand and acknowledge that Apex Crypto does not provide tax
          or legal advice. You further understand and acknowledge that Apex
          Crypto will report Cryptocurrency Transactions and the proceeds from
          cryptocurrency sales to the Internal Revenue Service to the extent and
          manner in which so required by law.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          12.4.1 U.S. Persons
        </h3>
        <p className="terms-text">
          This subsection is applicable if you are a U.S. person (including a
          U.S. resident alien) as such term is defined in section 7701(a) of the
          Internal Revenue Code of 1986, as amended ("U.S. Person"). Under
          penalties of perjury, you hereby certify that the taxpayer
          identification number that you have provided or will provide to Apex
          Crypto in connection with your Account (including any taxpayer
          identification number on any Form W-9 that you have provided or will
          provide) is your correct taxpayer identification number.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          12.4.2 Non-U.S. Persons
        </h3>
        <p className="terms-text">
          This subsection is applicable if you are not a U.S. Person. You hereby
          certify that you fully understand all the information on any Form
          W-8BEN that you have submitted or will submit to Apex Crypto in
          connection with your Account. Under penalties of perjury, you declare
          that (i) you have examined all the information on any Form W-8BEN that
          you have submitted or will submit to Apex Crypto and (ii) to the best
          of your knowledge and belief all such information is true, correct,
          and complete.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          13. Privacy Policy
        </h3>
        <p className="terms-text">
          Please refer to your Correspondent's Privacy Policy for information
          about how your Correspondent collects, uses and discloses information
          about you when you engage with the Platform.
        </p>
        <p className="terms-text">
          Please refer to Apex Crypto's Privacy Policy at apexcrypto.com for
          information about how we collect, use and disclose information about
          you when you visit our website.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          14. Remedies for Breach; Remedies in General
        </h3>
        <p className="terms-text">
          In the event that you or your officers, managers, partners, directors,
          Affiliates, employees, independent agents or contractors breach any of
          your representations, warranties, agreements or covenants set forth in
          this Agreement or otherwise fail to comply with the requirements of
          this Agreement, any policies of Apex Crypto, Apex Clearing or the
          terms of service set forth on our website, including without
          limitation, giving Apex Crypto inaccurate or incomplete information
          for any reason, Apex Crypto will have the right to terminate this
          Agreement and to close your Account. Apex Crypto will also have the
          right to restrict your transactions by refusing to either accept or
          execute any transactions for your Account. You are liable for all
          damages suffered by Apex Crypto and its Affiliates resulting from any
          such breach by you or your officers, managers, partners, directors,
          Affiliates, employees, independent agents or contractors. Further, in
          the event of such breach, Apex Crypto will have the rights of a
          secured creditor under all applicable laws with respect to your
          Account and can (i) recover all damages incurred by Apex Crypto and
          its Affiliates by selling any or all of the cryptocurrencies held in
          your hosted wallet. Apex Crypto has sole discretion over what actions,
          if any, it takes in the event of such breach and may take action
          without prior notice to you and/or (ii) recover against your accounts
          with your Correspondent. If a breach by you or your officers,
          managers, partners, directors, Affiliates, employees, independent
          agents or contractors involves participation by other parties with
          accounts at Apex Crypto, you and such parties will be jointly and
          severally liable for all resulting damages to Apex Crypto and its
          Affiliates.
        </p>
        <p className="terms-text">
          The enumeration in this Agreement of specific remedies shall not be
          exclusive of any other remedies that may be available at law or in
          equity. Any delay or failure by any party to this Agreement to
          exercise any right, power, remedy or privilege herein contained, or
          now or hereafter existing under any applicable statute or law, shall
          not be construed to be a waiver of such right, power, remedy or
          privilege, nor to limit the exercise of such right, power, remedy or
          privilege, nor shall it preclude the further exercise thereof or the
          exercise of any other right, power, remedy or privilege.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            15. Indemnification
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            15.1 Indemnification by You
          </h3>
        </div>
        <p className="terms-text">
          In addition to any other obligations you may have under other
          provisions of this Agreement, you agree to indemnify, defend and hold
          harmless Correspondent, Apex Crypto, Apex Clearing, each of their
          Affiliates and all of their officers, directors, managers, partners,
          employees, independent contractors, or agents, (collectively, the
          "Apex Crypto Indemnified Persons") from and against all claims,
          demands, proceedings, suits and actions and all liabilities, losses,
          expenses, costs (including reasonable legal and accounting fees and
          expenses), damages, penalties, fines, taxes or amounts due of any kind
          (collectively, "Losses") arising out of, in connection with or
          relating to (A) you or your officers, managers, partners, directors,
          Affiliates, employees, independent agents or contractors, for any
          reason, fraudulent or otherwise, failing to perform or comply with any
          obligation, agreement or covenant under this Agreement, the Services
          or your use of the Services or any other services provided in
          connection with your Account, the Platform or your use of the
          Platform, (B) a breach by you or your officers, managers, partners,
          directors, Affiliates, employees, independent agents or contractors,
          of any representation or warranty made by you under this Agreement or
          your provision of any inaccurate or incomplete information to any of
          the Apex Crypto Indemnified Parties for any reason in connection with
          this Agreement, the Services, any other services provided in
          connection with your Account or the Platform, (C) any negligent,
          dishonest, fraudulent, or criminal act or omission by your or any of
          your officers, managers, partners, directors, Affiliates, employees,
          or independent agents or contractors, (D) any claim or dispute between
          you and your Correspondent and (E) any failure by you or your
          officers, managers, partners, directors, Affiliates, employees,
          independent agents or contractors to comply with any laws, rules or
          regulations applicable to you or them. Notwithstanding the foregoing,
          an Apex Crypto Indemnified Person shall not be entitled under this
          paragraph to receive indemnification for that portion, if any, of any
          liabilities and costs which is solely caused by its own individual
          gross negligence, willful misconduct or fraud, as determined by
          arbitration in accordance with Section 17 below.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          15.2 Indemnification Procedures
        </h3>
        <p className="terms-text">
          Upon receipt by any Apex Crypto Indemnified Person(s) under this
          Section 15 of notice of the commencement of any action, and if a claim
          is to be made against you under this Section 15, the Apex Crypto
          Indemnified Person(s) will promptly notify you; provided, however,
          that no delay on the part of the Apex Crypto Indemnified Person(s) in
          notifying you shall relieve you from any obligation hereunder unless
          (and then solely to the extent) you thereby are prejudiced. Further,
          the omission to promptly notify you will not relieve you from any
          liability that you may have to any Apex Crypto Indemnified Person(s)
          otherwise than under this Section 15. In any such action brought
          against any Apex Crypto Indemnified Person(s), you will be entitled to
          participate in and, to the extent that you may wish, to assume the
          defense thereof, subject to the provisions herein stated, with counsel
          reasonably satisfactory to such Apex Crypto Indemnified Person(s).
          After notice from you to such Apex Crypto Indemnified Person(s) of
          your election to assume the defense thereof, you will not be liable to
          such Apex Crypto Indemnified Person(s) under this Section 15 for any
          legal or other expense subsequently incurred by such Apex Crypto
          Indemnified Person(s) in connection with the defense thereof other
          than reasonable costs of investigation or in the case of an actual or
          potential conflict of interest between you and any Apex Crypto
          Indemnified Person(s), identified in writing by counsel to the Apex
          Crypto Indemnified Person(s). You shall keep the Apex Crypto
          Indemnified Person(s) informed of the status of the defense of such
          claims, and you will not agree to any settlement without consent of
          the Apex Crypto Indemnified Person(s), which consent will not be
          unreasonably withheld. The Apex Crypto Indemnified Person(s) shall
          have the right to employ separate counsel in any such action and to
          participate in the defense thereof, but the fees and expenses of such
          counsel shall not, other than in the case of an actual or potential
          conflict of interest between you and Apex Crypto Indemnified
          Person(s), be at your expense if you have assumed the defense of the
          action with counsel satisfactory to the Apex Crypto Indemnified
          Person(s). In the event that the Apex Crypto Indemnified Person(s)
          reasonably believes that you are not adequately defending a claim, the
          Apex Crypto Indemnified Person(s) will have the right to assume the
          defense of such claims at your sole expense. You will not settle any
          action unless such settlement completely and finally releases the Apex
          Crypto Indemnified Person(s) from any and all liability and otherwise
          is acceptable to the Apex Crypto Indemnified Person(s). Except with
          your prior written consent, the Apex Crypto Indemnified Person(s), you
          may not confess any claim or make any compromise in any case in which
          you may be required to provide indemnification.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          16. Limitation of Liability
        </h3>
        <p className="terms-text">
          YOUR ACCOUNT AT APEX CRYPTO, THE SERVICES, YOUR USE OF THE ACCOUNT,
          THE PLATFORM, YOUR USE OF THE PLATFORM AND ANY OTHER SERVICES PROVIDED
          TO YOU IN CONNECTION THEREWITH ARE PROVIDED BY CORRESPONDENT, APEX
          CRYPTO AND THEIR AFFILIATES AND ANY OTHER SERVICE PROVIDERS ON AN "AS
          IS" AND "AS AVAILABLE" BASIS AND WITHOUT WARRANTY OF ANY KIND, TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. CORRESPONDENT AND APEX
          CRYPTO DISCLAIM ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
          PURPOSE. THERE IS NO WARRANTY THAT ANY OF THE SERVICES OR THE
          PLATFORM, OR ANY DATA OR OTHER INFORMATION PROVIDED TO YOU BY
          CORRESPONDENT, APEX CRYPTO OR THEIR AFFILIATES OR THROUGH THE PLATFORM
          WILL FULFILL ANY PARTICULAR PURPOSES OR NEEDS. THERE IS NO WARRANTY
          THAT THE SERVICES PROVIDED HEREUNDER OR THE PLATFORM WILL BE ERROR
          FREE, UNINTERRUPTED, TIMELY, RELIABLE, COMPLETE OR ACCURATE.
        </p>
        <p className="terms-text">
          None of Correspondent, Apex Crypto, Apex Clearing, any of their
          Affiliates nor any of their officers, directors, managers, partners,
          employees or independent agents or contractors will be liable to you
          or any of your Affiliates for any losses, expenses (including legal
          fees and costs), liabilities, damages, costs, demands, obligations,
          penalties, charges, causes of action, claims, fines, taxes or amounts
          due of any kind (both known and unknown, absolute or contingent,
          liquidated or unliquidated, direct or indirect, payable now or payable
          in the future, accrued or not accrued) (collectively, "Losses")
          arising out of, related to or resulting from your Account, the
          Services or your use of the Services, including without limitation any
          trading or other activities conducted in the Account, the Platform or
          your use of the Platform, including without limitation, your sending
          orders for the purchase and sale of cryptocurrencies to Apex Crypto
          and directing the transfer of funds to Apex Crypto, and the storage of
          your cryptocurrencies, except to the extent and only to the extent
          that your Losses are actual and have been finally determined by a
          court of competent jurisdiction or arbitration panel to have resulted
          solely from the gross negligence, intentional misconduct or fraud of
          Correspondent, Apex Crypto, Apex Clearing, any of their Affiliates or
          any of their officers, directors, managers, partners, employees or
          independent agents or contractors. You will not be held liable for
          such actual Losses that have been finally determined to have resulted
          solely from the gross negligence, intentional misconduct or fraud of
          Correspondent, Apex Crypto, Apex Clearing, any of their Affiliates or
          any of their officers, directors, managers, partners, employees or
          independent agents or contractors. Further, none of Correspondent,
          Apex Crypto, Apex Clearing, any of their Affiliates or any of their
          officers, directors, managers, partners, employees or agents will have
          responsibility for Losses or have any other liability to you (i)
          arising out of or resulting from any actions or inactions or
          performance of services by any third party or actions of other
          participants in the markets with respect to your Account or the
          activities conducted through your Account or (ii) arising out of or
          resulting from system failures, outages, unauthorized access to the
          Account, conversion of property, errors of any kind, government
          actions, force majeure events, trading suspensions, or any other
          causes over which Apex Crypto does not have direct control. Further,
          none of Correspondent, Apex Crypto, Apex Clearing, any of their
          Affiliates or any of their officers, directors, managers, partners,
          employees or agents shall be liable for any Losses that are not direct
          damages, including without limitation, indirect, special, incidental,
          punitive, consequential or exemplary damages, which includes trading
          losses, lost profits and other lost business opportunities relating to
          the Account, the Services and your use of the Services, the Platform
          and your use of the Platform, including without limitation for sending
          orders for the purchase and sale of cryptocurrencies to Apex Crypto,
          directing the transfer of funds to Apex Crypto and the storage of your
          cryptocurrencies.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          17. Third Party Beneficiaries.
        </h3>
        <p className="terms-text">
          Except for Correspondent, who shall be considered a third-party
          beneficiary under Sections 15 and 16 of this User Agreement, but for
          the avoidance of doubt not under Section 15.1(D), this User Agreement
          is intended to be solely for the benefit of you, Apex Clearing and
          Apex Crypto and is not intended to confer any benefits upon, or create
          any rights in favor of, any person other than you, Apex Clearing and
          Apex Crypto.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          18. Dispute Resolution
        </h3>
        <div style={{ paddingLeft: "5%" }}>
          <p className="terms-text">
            (a) In the event of a dispute between the parties regarding the
            subject matter of this User Agreement, such dispute shall be settled
            by arbitration before one arbitrator sitting in Chicago, Illinois,
            unless the amount in dispute exceeds $1,000,000, in which case the
            arbitration shall be before three independent arbitrators sitting in
            Chicago, Illinois.
          </p>
          <p className="terms-text">(b) Arbitration Disclosure.</p>
          <div style={{ paddingLeft: "5%" }}>
            <p className="terms-text">
              (i) ARBITRATION IS FINAL AND BINDING ON THE PARTIES.
            </p>
            <p className="terms-text">
              (ii) THE PARTIES ARE WAIVING THEIR RIGHT TO SEEK REMEDIES IN
              COURT, INCLUDING THE RIGHT TO JURY TRIAL; PROVIDED, HOWEVER, THAT
              THE PARTIES RESERVE THE RIGHT TO SEEK PURELY INTERIM OR EQUITABLE
              RELIEF IN A COURT OF COMPETENT JURISDICTION AS SET FORTH BELOW.
            </p>
            <p className="terms-text">
              (iii) PRE-ARBITRATION DISCOVERY IS GENERALLY MORE LIMITED THAN AND
              DIFFERENT FROM COURT PROCEEDINGS.
            </p>
            <p className="terms-text">
              (iv) THE ARBITRATOR OR ARBITRATION PANEL SHALL ISSUE A REASONED
              AWARD.
            </p>
          </div>
          <p className="terms-text">(c) ARBITRATION AGREEMENT.</p>
          <div style={{ paddingLeft: "5%" }}>
            <p className="terms-text">
              EACH PARTY AGREES TO ADVISE THE OTHER PARTY PROMPTLY IN THE EVENT
              A DISPUTE ARISES BETWEEN THEM (OR ANY OF THEIR AFFILIATES, IT
              BEING UNDERSTOOD THAT AFFILIATES ARE BOUND BY THIS USER
              AGREEMENT). THE PARTIES AGREE TO ATTEMPT TO RESOLVE ANY SUCH
              DISPUTE INFORMALLY BEFORE FILING A FORMAL ACTION. IF A DISPUTE
              CANNOT BE RESOLVED INFORMALLY WITHIN THIRTY (30) DAYS, AND
              MEDIATION (DESCRIBED BELOW) FAILS TO RESOLVE THE DISPUTE, EITHER
              PARTY MAY FILE AN ARBITRATION DEMAND. NOTWITHSTANDING THE
              FOREGOING, EITHER PARTY MAY FILE AN ARBITRATION DEMAND PRIOR TO
              SUCH TIME TO COMPLY WITH ANY STATUTE OF LIMITATION OR TIMING
              REQUIREMENTS TO PRESERVE ITS CLAIMS.
            </p>
            <p className="terms-text">
              ANY CONTROVERSY, DEMAND, CAUSE OF ACTION, WHETHER IN TORT,
              CONTRACT, BY STATUTE OR OTHERWISE, BETWEEN YOU AND APEX CRYPTO (OR
              ITS AFFILIATES) INCLUDING THOSE ARISING OUT OF OR RELATING TO THIS
              AGREEMENT, THE ACCOUNT, ANY ACTIVITIES IN THE ACCOUNT, INCLUDING
              WITHOUT LIMITATION, ANY USE OF THE SERVICES, USE OF THE PLATFORM,
              THIRD PARTY SERVICES, SENDING ORDERS FOR THE PURCHASE OR SALE OF
              CRYPTOCURRENCIES, AND THE STORAGE OF YOUR CRYPTOCURRENCIES, SHALL
              BE EXCLUSIVELY, FULLY AND FINALLY RESOLVED BY BINDING ARBITRATION
              PURSUANT TO THE JAMS COMPREHENSIVE ARBITRATION RULES AND
              PROCEDURES (INCLUDING THE EXPEDITED PROCEDURES FOR SUCH RULES IF
              THE MATTER IS ARBITRATED BY A SINGLE ARBITRATOR). THE ARBITRATION
              WILL BE HELD IN CHICAGO, ILLINOIS, UNLESS SUCH LOCATION IS NOT
              CONVENIENT, THE ARBITRATION WILL BE HELD IN THE COUNTY OF YOUR
              RESIDENCE. ARBITRATION MUST BE COMMENCED BY SERVICE UPON THE OTHER
              PARTY OF A WRITTEN DEMAND FOR ARBITRATION OR A WRITTEN NOTICE OF
              INTENTION TO ARBITRATE. THE MATTER SHALL BE DECIDED BY ONE
              INDEPENDENT ARBITRATOR, UNLESS THE AMOUNT IN DISPUTE IS IN EXCESS
              OF $1,000,000, THEN THE ARBITRATION SHALL BE DECIDED BY A PANEL OF
              THREE INDEPENDENT ARBITRATORS, ONE CHOSEN BY APEX CRYPTO, ONE
              CHOSEN BY YOU AND THE THIRD CHOSEN BY THE FIRST TWO ARBITRATORS.
              THE ARBITRATOR(S) SHALL HAVE INDUSTRY RELATED EXPERIENCE. THE
              ARBITRATOR(S) SHALL APPLY THE SUBSTANTIVE LAW OF THE STATE OF
              DELAWARE WITHOUT GIVING EFFECT TO ANY CONFLICT OF LAWS PRINCIPLES
              THAT MAY APPLY. THE ARBITRATION ITSELF SHALL BE GOVERNED BY
              FEDERAL ARBITRATION ACT 9 USC ß1 ET. SEQ. AND JUDGMENT UPON THE
              AWARD MAY BE ENTERED BY ANY COURT HAVING COMPETENT JURISDICTION.
              THE ARBITRATOR OR PANEL SHALL ISSUE A REASONED AWARD. APEX CRYPTO
              SHALL BE RESPONSIBLE FOR THE COSTS OF THE ARBITRATION AND THE
              PARTIES OWN LEGAL FEES AND COSTS, UNLESS APEX CRYPTO IS THE
              PREVAILING PARTY, IN WHICH CASE YOU WILL PAY ONE-HALF OF THE COSTS
              OF THE ARBITRATION AND YOUR LEGAL FEES AND COSTS. THE ARBITRATORS
              SHALL BE SPECIFICALLY AUTHORIZED TO AWARD LEGAL FEES AND COSTS OF
              ARBITRATION TO THE PREVAILING PARTY. THE DUTY TO ARBITRATE
              DESCRIBED HEREIN SHALL SURVIVE THE TERMINATION OF THIS AGREEMENT.
              THE PARTIES HEREBY WAIVE TRIAL IN A COURT OF LAW OR BY JURY.
            </p>
            <p className="terms-text">
              ALL ARBITRATION CLAIMS WILL BE BROUGHT ON AN INDIVIDUAL BASIS AND
              YOU WAIVE ANY RIGHTS YOU HAVE TO PARTICIPATE IN A CLASS ACTION
              RELATING TO ANY MATTERS DESCRIBED IN THIS DISPUTE RESOLUTION
              SECTION.
            </p>
            <p className="terms-text">
              ANY DISPUTE REQUIRING INJUNCTIVE OR OTHER EMERGENCY EQUITABLE
              RELIEF MAY BE BROUGHT DIRECTLY TO A COURT OF COMPETENT
              JURISDICTION. THE PARTIES SUBMIT TO THE EXCLUSIVE JURISDICTION AND
              VENUE OF THE STATE OR FEDERAL COURT LOCATED IN CHICAGO, ILLINOIS
              FOR ANY SUIT SEEKING INTERIM OR INJUNCTIVE RELIEF OR TO CONFIRM
              AND EXECUTE UPON THE AWARD. YOU HEREBY AGREE TO SUCH EXCLUSIVE
              JURISDICTION AND VENUE AND WAIVE ANY RIGHTS YOU MAY HAVE TO CLAIM
              THAT THE FORUM IS NOT CONVENIENT AND OTHER CLAIMS TO INVALIDATE
              SUCH JURISDICTION.
            </p>
            <p className="terms-text">
              BEFORE COMMENCING AN ACTION IN ARBITRATION, THE PARTIES SHALL
              PROMPTLY SUBMIT ANY DISPUTE THAT THEY HAVE FAILED TO PROMPTLY
              RESOLVE TO MEDIATION BEFORE JAMS IN CHICAGO, ILLINOIS OR AT A
              LOCATION AGREED TO BY THE PARTIES, THE PARTIES SHALL JOINTLY
              SELECT THE MEDIATOR, OR IF THE PARTIES ARE UNABLE TO AGREE UPON A
              MEDIATOR, THEN THE DISPUTE SHALL BE SUBMITTED TO NON-BINDING
              MEDIATION BEFORE JAMS. THE COST OF THE MEDIATION SHALL BE PAID BY
              APEX CRYPTO. IF THE PARTIES ARE UNABLE TO ACHIEVE A MUTUALLY
              AGREEABLE RESOLUTION OF THE DISPUTE THROUGH MEDIATION WITHIN 60
              DAYS AFTER COMMENCEMENT OF MEDIATION PROCEEDINGS, THE PARTIES
              HEREBY AGREE TO SUBMIT THEIR DISPUTE TO BINDING ARBITRATION
              DESCRIBED ABOVE. NOTWITHSTANDING THE FOREGOING, EITHER PARTY MAY
              FILE AN ARBITRATION DEMAND AT ANY TIME TO COMPLY WITH ANY STATUTE
              OF LIMITATION OR TIMING REQUIREMENTS TO PRESERVE ITS CLAIMS.
            </p>
          </div>
        </div>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          19. Governing Law and Venue
        </h3>
        <p className="terms-text">
          This Agreement shall be governed by the internal laws of the State of
          Delaware applicable to agreements made and to be performed entirely
          within the State of Delaware, without regard to the conflicts of law
          principles that would apply the laws of any other jurisdiction.
        </p>
        <div>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            20. Legal
          </h3>
          <h3 style={{ color: "#3f4174" }} className="terms-title">
            20.1 Regulation
          </h3>
        </div>
        <p className="terms-text">
          Apex Crypto is registered with the U.S. Department of Treasury
          Financial Crimes Enforcement Network ("FinCEN") as a money services
          business ("MSB"). As a registered MSB, Apex Crypto is subject to the
          Bank Secrecy Act and its implementing regulations (collectively, the
          "BSA") which set out the requirements imposed upon financial
          institutions to implement policies and procedures reasonably designed
          to detect and prevent money laundering and terrorist financing. You
          understand and acknowledge that your access to and use of the Services
          is subject to compliance with Apex Crypto's AML Program.
        </p>
        <p className="terms-text">
          You understand and acknowledge that Apex Crypto is not a registered
          broker-dealer and is not a member of the Financial Industry Regulatory
          Authority or the SIPC. You further understand and acknowledge that
          your cryptocurrency holdings are not protected by the FDIC or SIPC, or
          any other insurance.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          20.2 Compliance with Applicable Laws
        </h3>
        <p className="terms-text">
          Cryptocurrency Transactions are subject to applicable laws,
          regulations, and rules of federal and state governmental and
          regulatory authorities (collectively, "Applicable Laws"). You
          understand that compliance with Applicable Laws may include compliance
          with any guidance or direction of any regulatory authority or
          government agency, any writ of attachment, lien, levy, subpoena,
          warrant, or other legal order (collectively, "Legal Orders").
        </p>
        <p className="terms-text">
          You understand and acknowledge that in no event will Apex Crypto be
          obligated to affect any Cryptocurrency Transaction it believes would
          violate any Applicable Law. You further understand and acknowledge
          that Apex Crypto is not responsible for any losses, whether direct or
          indirect, that you may incur as a result of Apex Crypto's good faith
          efforts to comply with any Applicable Law, including any Legal Order.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          20.3 State Licenses and Disclosures
        </h3>
        <p className="terms-text">
          Apex Crypto maintains licenses to engage in money transmission
          activities in certain states, and these licenses may impact our
          provision and your use of certain Services depending on where you
          live. Apex Crypto's licenses and corresponding required disclosures
          can be found at apexcrypto.com\\legal, which is incorporated by
          reference.
        </p>
        <h3 style={{ color: "#3f4174" }} className="terms-title">
          20.4 Authorizing Apex Clearing to Share Your Information
        </h3>
        <p className="terms-text">
          `acceptedCrypto` By checking this box, I hereby authorize Apex
          Clearing Corporation to provide all information provided to Apex
          Clearing Corporation in my new account application to Apex Crypto LLC
          for purposes of opening and maintaining an Apex Crypto LLC account.
        </p>
        <p className="terms-text">
          By clicking the "Agree and Submit" button below, I acknowledge and
          agree that: 1) I am opening an account to trade digital assets (such
          as cryptocurrencies); 2) I have read the Agreement and am legally
          bound by its terms and conditions; and 3) I have reviewed the Risk
          Disclosures.
        </p>
        <p className="terms-text">
          Disclosures: Apex Crypto LLC is not a broker-dealer and is a separate
          company from Apex Clearing Corporation. Assets held at Apex Crypto LLC
          are held away from Apex Clearing Corporation and are not protected by
          SIPC or the FDIC. Purchasing cryptocurrencies comes with a number of
          risks, including volatile market price swings or flash crashes, fraud,
          market manipulation, and cybersecurity risks. Please review your
          account agreement with Apex Crypto LLC for further elaboration on
          these risks.
        </p>
        <p className="terms-text">Signature: `signature`</p>
      </div>
    </div>
  );
}
