import React from 'react'
import Warning from '../../assets/newUI/warning.svg'


export default class RiskNum extends React.Component{
    state={}

    num(cvar,ticker){
        if(ticker === 'BIL' || ticker === "TLT"){
            return '0'
        }
        else{
           if( Math.abs(cvar*200/10) > 10){
                return '10+'
           }else if (Math.abs(cvar*200/10) < 1){
               return '1'
           }
           else{
               return Math.abs(cvar*200/10).toFixed(0)
           }
        }
    }
    numText = (riskNum,ticker)=>{
        // let num = this.num(cvar,ticker)
        // if (num < 3){
        //     return 'Low'
        // }
        // else if (num < 6){
        //     return 'Medium'
        // }
        // else if (num < 8){
        //     return 'High'
        // }
        // else{
        //     return 'Extreme'
        // }
        if (riskNum < 0.16){
            return 'Low'
        }
        else if (riskNum >= 0.16 && riskNum < 0.26){
            return 'Moderate'
        }
        else {
            return 'High'
        }
    }
    numColor = (riskNum,ticker)=>{
        let num = this.num(riskNum,ticker)
        if(riskNum < 0.16) {
            return "#7bc0c3"
        }
        if (riskNum >= 0.16 && riskNum < 0.26){
            return '#F5CD19'
        }
        else{
            return '#f22525'
        }
    }

    render(){
        return(
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width:'100%', height: '100%',borderRadius: '15px',backgroundColor: this.numColor(this.props.riskNum, this.props.symbol), paddingLeft: '5%',paddingRight: '5%',paddingTop: '5%', paddingBottom:'5%', }}>
                <div style={{display: 'flex', width: '100%'}}>
                    <div style={{width:'50%', display: 'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column', }}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <p style={{fontSize: 20}}>
                                Risk Forecast
                            </p>
                        </div>
                        <img
                            src={Warning}
                            style={{width:'50%'}}
                        />
                    </div>
                    <div style={{width:'75%', display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection: 'column', height: '100%'}}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
                                <p 
                                style={{display:'flex', justifyContent:'center', alignItems:'center',margin:0, fontSize: '25px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.22, letterSpacing: 'normal', textAlign: 'left', color: 'rgb(36, 42, 56)'}}
                                >
                                {/* {this.num(this.props.cvar, this.props.symbol)} */}
                                {(this.props.riskNum * 100).toFixed(0)}%
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center',margin:0,}}>
                                <p style={{margin: 0, marginLeft:5, fontSize: 25}}>{this.numText(this.props.riskNum)} Risk  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}