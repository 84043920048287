import Axios from 'axios'
import Endpoint from '../components/Endpoint'

//GET USER DRIVE WEALTH INFORMATION FOR DEPOSIT
export const getSelectedAccountsInformation = async (jwtToken, accountId) => {
  try {
    const response = await Axios.get(
      `${Endpoint}/mobile/wire/vaccount?account_id=${accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    )
    return response?.data
  } catch (err) {
    console.log(err)
  }
}

export const fetchExistingInfo = async (jwtToken) => {
  try {
    const response = await Axios.get(`${Endpoint}/mobile/fetch-existing-info`, {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    })
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

// export const addToWatchlist = async (type, symbol, jwtToken) => {
//   try {
//     const url = `${Endpoint}/risk-monkey/watchlist`
//     const body = { [type]: [symbol] }
//     const headers = {
//       Authorization: 'Bearer ' + jwtToken,
//     }
//     const res = await Axios.post(url, body, { headers })
//     console.log(res)
//   } catch (error) {
//     console.log(error)
//   }
// }

export const addToWatchlist = async (names, symbols, jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/watchlist-new/edit`
    const body = {
      names: names,
      add: symbols,
      remove: [],
    }
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.post(url, body, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const removeFromWatchlist = async (names, symbols, jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/watchlist-new/edit`
    const body = {
      names: names,
      remove: symbols,
      add: [],
    }
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.post(url, body, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

// export const fetchWatchList = async (jwtToken) => {
//   try {
//     const url = `${Endpoint}/mobile/watchlist`
// const headers = {
//   Authorization: 'Bearer ' + jwtToken,
// }
//     const res = await Axios.get(url, { headers })
//     console.log(res)
//     return res?.data?.watchlist
//   } catch (error) {
//     console.log(error)
//   }
// }

export const fetchWatchList = async (jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/watchlist-new`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchAccountInfoLimited = async (jwtToken, accountId) => {
  try {
    const url = `${Endpoint}/mobile/user/account-info?account_id=${accountId}&onlyinfo=1`
    // const url = `${Endpoint}/mobile/user/account-info?account_id=${accountId}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const getTradeGroupSuggestions = async (jwtToken, accountId) => {
  try {
    const url = `${Endpoint}/search/trade-group/suggestion?account_id=${accountId}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data?.suggestions
  } catch (error) {
    console.log(error)
  }
}

export const swapTradeGroupStock = async (jwtToken, dwAccountId, stocks) => {
  try {
    const url = `${Endpoint}/mobile/trade-groups/switch`
    const body = {
      dw_account_id: dwAccountId,
      stocks,
    }
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.post(url, body, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const getBudgetingData = async (jwtToken) => {
  try {
    const url = `${Endpoint}/budgeting/get_budgeting`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.post(url, {}, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const addCreditScore = async (
  jwtToken,
  degree,
  credit_date,
  current_employment_years,
  employment_years,
  home_status
) => {
  try {
    const url = `${Endpoint}/mobile-plaid/plaid/credit-score/insert`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const body = {
      degree,
      credit_date,
      current_employment_years,
      employment_years,
      home_status,
    }
    const res = await Axios.post(url, body, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const addAsset = async (
  jwtToken,
  nickname,
  mortgage_balance,
  mortgage_account,
  address,
  estimated_value,
  type
) => {
  try {
    const url = `${Endpoint}/mobile/mortgage/update`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const body = {
      nickname,
      mortgage_balance,
      mortgage_account,
      address,
      estimated_value,
      type,
    }
    console.log(url, header, body)
    const res = await Axios.post(url, body, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const unlinkAccount = async (jwtToken, accountToRemove) => {
  try {
    const url = `${Endpoint}/mobile-plaid/plaid/unlink?item_id=${accountToRemove}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    console.log(res)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const unsubscribe = async (jwtToken) => {
  try {
    const url = `${Endpoint}/stripe/cards`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.delete(url, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const unlinkAsset = async (jwtToken, assetId) => {
  try {
    const url = `${Endpoint}/mobile/mortgage/remove?id=${assetId}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const editAsset = async (
  jwtToken,
  nickname,
  mortgage_balance,
  mortgage_account,
  address,
  estimated_value,
  id,
  type
) => {
  try {
    const url = `${Endpoint}/mobile/mortgage/edit`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const body = {
      nickname,
      mortgage_balance,
      mortgage_account,
      address,
      estimated_value,
      id,
      type,
    }
    const res = await Axios.post(url, body, header)
    console.log(res)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const linkWalletAddress = async (jwtToken, walletAddress, nickname) => {
  try {
    const url = `${Endpoint}/mobile/wallet/address/add?address=${walletAddress}&name=${nickname}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data?.status
  } catch (error) {
    console.log(error)
  }
}

export const fetchWalletAddress = async (jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/wallet/address/get`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchWalletData = async (walletAddress) => {
  try {
    const url = 'https://api.swopx.com/nft_data/wallet-bulk'
    const body = { wallets: [walletAddress] }
    // const body = {
    //   wallets: ['0xc71e8d9c352512540a94a0b85582a96f82ac7b4e'],
    // }
    const header = {
      withCredentials: false,
      headers: {
        Authorization: 'a0b51652-86e5-11ec-a8a3-0242ac120002',
      },
    }
    const res = await Axios.post(url, body, header)
    return res?.data?.content[0]
  } catch (error) {
    console.log(error)
  }
}

export const applyPromoCode = async (jwtToken, code) => {
  try {
    const url = `${Endpoint}/mobile/promo/apply?code=${code?.toUpperCase()}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const fetchRewards = async (jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/promo/status`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}
