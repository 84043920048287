import React from 'react'
import ENDPOINT from '../Endpoint'

export default class OrdersModal extends React.Component{

    state = {
        ordersResp:false
    }

    componentDidMount(){
        this.getOrders()
    }

    getOrders = ()=>{
        // console.log('acc id:', this.props.acc.accountId)
       fetch(`${ENDPOINT}/mobile/order-history?account_id=${this.props.acc.accountId}`,{
        headers:{
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
          },
       })
       .then(response => response.json())
       .then(resp=> this.setState({ordersResp:resp}))
    }

    addCommas = (x)=>{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    history = ()=>{
        let mapped = [
            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
            <div style={{width:'70%'}}>
                    <p style={{margin:0, textAlign:'left', fontWeight:'bold'}}> 
                        Message
                    </p>
                </div>
                <div style={{width:'10%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Amount
                    </p>
                </div>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Date
                    </p>
                </div>
            </div>
        ]
        this.state.ordersResp.orderHistory.slice().forEach(ele=>{
            mapped.push(
                <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
                    <div style={{width:'60%'}}>
                        <p style={{margin:0, textAlign:'left'}}> 
                            {ele.name}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.addCommas(Math.abs(ele.transactionAmount).toFixed(2))}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.utcToLocal(ele.createdOn)}
                        </p>
                    </div>
                </div>
            )
        })
        return(
            <div>
                {mapped.length === 1 ?<div style={{display:'flex', alignItems:'center',justifyContent:'center',width:'100%'}}><p style={{fontSize:22, fontWeight:'bold'}}>You have no previous orders</p></div> :mapped}
            </div>
        )
    }
    pending = ()=>{
        let mapped = [
            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
            <div style={{width:'60%'}}>
                {this.props.orders? 
                    <p style={{margin:0, textAlign:'left', fontWeight:'bold'}}> 
                        Message
                    </p>:
                    <div style={{display:'flex'}}>
                    <div style={{width:'30%'}}>
                        <p style={{margin:0, textAlign:'left',fontWeight:'bold'}}> 
                            Message
                        </p>
                    </div>
                    <div style={{width:'30%'}}>
                        <p style={{margin:0, textAlign:'right',fontWeight:'bold'}}> 
                            Submitted
                        </p>
                    </div>
                </div>}

            </div>
                {/* <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Submitted
                    </p>
                </div> */}
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                    {this.props.orders?'Expires':'Expected Completion'}
                    </p>
                </div>
                <div style={{width:'10%'}}/>
                {this.props.orders?
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Cancel
                    </p>
                </div>
                :<div style={{width:'20%'}}>
                <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                    Amount
                </p>
            </div>}
                
            </div>
        ]
        let data = this.props.orders? this.state.ordersResp.pendingOrders :this.state.ordersResp.pendingTransfers
        data.forEach(ele=>{
            mapped.push(
                <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
                    <div style={{width:'60%'}}>
                        <p style={{margin:0, textAlign:'left'}}> 
                            {this.props.orders?ele.name.split('|')[0]:
                        <div style={{display:'flex'}}>
                            <div style={{width:'30%'}}>
                                <p style={{margin:0, textAlign:'left'}}> 
                                    {ele.name.split('|')[0]}
                                </p>
                            </div>
                            <div style={{width:'30%'}}>
                                <p style={{margin:0, textAlign:'left'}}> 
                                {this.utcToLocal(ele.transactionDate)}
                                </p>
                            </div>
                        </div>
                    }
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.props.orders? this.utcToLocal(ele.expiryDate):this.utcToLocal(ele.updateDate)}
                        </p>
                    </div>
                    {this.props.orders?
                    <div style={{width:'20%', display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        <div 
                            style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:7,paddingRight:7, }}
                            onClick={()=>this.cancelOrder(ele.orderID)}
                            >
                                <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Cancel</p>
                            </div>
                    </div>
                    :
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            ${ele.transactionAmount}
                        </p>
                    </div>
                    }
                </div>
            )
        })
        return(
            <div>
                {mapped.length === 1 ?<div style={{display:'flex', alignItems:'center',justifyContent:'center',width:'100%'}}><p style={{fontSize:22, fontWeight:'bold'}}>You have no pending {this.props.orders? 'orders': 'transactions'}</p></div> :mapped}
            </div>
        )
    }

    cancelOrder =(id)=>{
        let url = `${ENDPOINT}/mobile/order-cancel?order_id=${id}`
        fetch(url,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
            },
        })
            .then((res) => res.json())
            .then((responseJson)=>{
                if(responseJson.status === 'Success'){
                    let filtered = this.state.ordersResp.pendingOrders.filter(ele=>ele.orderID !== id)
                    let ordersResp = {...this.state.ordersResp,pendingOrders:filtered }
                    this.setState({ordersResp})
                }
            })
            .catch(err=>console.log('cancel error: ', err))
        
    }

    expired = ()=>{
        let mapped = [
            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
            <div style={{width:'60%'}}>
                    <p style={{margin:0, textAlign:'left', fontWeight:'bold'}}> 
                        Message
                    </p>
                </div>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Submitted
                    </p>
                </div>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Expires
                    </p>
                </div>
            </div>
        ]
        this.state.ordersResp.pendingOrders.forEach(ele=>{
            mapped.push(
                <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
                    <div style={{width:'60%'}}>
                        <p style={{margin:0, textAlign:'left'}}> 
                            {ele.name}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.utcToLocal(ele.createdOn)}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.utcToLocal(ele.expiryDate)}
                        </p>
                    </div>
                </div>
            )
        })
        return(
            <div>
                {mapped.length === 1 ?<div style={{display:'flex', alignItems:'center',justifyContent:'center',width:'100%'}}><p style={{fontSize:22, fontWeight:'bold'}}>You have no pending orders</p></div> :mapped}
            </div>
        )
    }
    transfers = ()=>{
        let mapped = [
            <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'left', fontWeight:'bold'}}> 
                        Transaction
                    </p>
                </div>
                <div style={{width:'40%'}}>
                    <p style={{margin:0, textAlign:'left', fontWeight:'bold'}}> 
                        Message
                    </p>
                </div>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Amount
                    </p>
                </div>
                <div style={{width:'20%'}}>
                    <p style={{margin:0, textAlign:'right', fontWeight:'bold'}}> 
                        Date
                    </p>
                </div>
            </div>
        ]
        this.state.ordersResp.transfers.forEach(ele=>{
            mapped.push(
                <div style={{width:'95%', margin:'auto', display:'flex', alignItems:'center', padding:6}}>
                   <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'left'}}> 
                            {ele.type}
                        </p>
                    </div>
                   <div style={{width:'40%'}}>
                        <p style={{margin:0, textAlign:'left'}}> 
                            {ele.name.split("|")[0]}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.addCommas(ele.transactionAmount.toFixed(2))}
                        </p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0, textAlign:'right'}}> 
                            {this.utcToLocal(ele.transactionDate)}
                        </p>
                    </div>
                </div>
            )
        })
        return(
            <div>
                {mapped.length === 1 ?<div style={{display:'flex', alignItems:'center',justifyContent:'center',width:'100%'}}><p style={{fontSize:22,fontWeight:'bold'}}>You have no prior transactions</p></div>:mapped}
            </div>
        )
    }

    parseDate(dt){
        let all = dt.split('T')
        let day = all[0].split("-")
        let time = all[1].slice(0,5)
        return time + " " + day[1] +"/"+ day[2] +"/"+ day[0]
    }

    utcToLocal(timeAndDate){
        // console.log('time n date', timeAndDate)
        let date = new Date(timeAndDate).toString().split(" ")
        let hour = date[4].substring(0,2) 
        let min = date[4].substring(3,5) 
        let day = date[1]+ ' ' + date[2]+ ', ' + date[3]
        let amPm = 'AM'
        if (hour > 12){
            hour = hour - 12
            amPm = 'PM'
        }
        else if(hour === '00'){
            hour = '12'
        }
        let time = `${hour}:${min} ${amPm}`
        return (
            <div style={{width:'100%'}}>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {time.replace(/^0(?:0:0?)?/, '')} 
                </p>
                <p style={{textAlign:"right",margin:0, fontSize:14}}>
                    {day}
                </p>
            </div>
        )
    }

    render(){
        // console.log('ordersResp:',this.state.ordersResp)
        const {acc} = this.props
        // console.log( 'orders resp',this.state.ordersResp)
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', minWidth:this.props.isSmallScreen? '':725, height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>{this.props.orders?'Order':'Transaction'} History</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%', overflowY:'scroll'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:28}}>
                                {acc.mask_full} 
                            </p>
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:20}}>
                                
                                {this.props.orders? 'Pending Orders:':'Pending Transactions'}
                            </p>
                        </div>
                        {this.state.ordersResp?
                        <div style={{width:'95%', margin:'auto',}}>
                           {this.pending()}
                        </div>:null}
                        
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:20}}>
                            {this.props.orders? 'Completed Orders:':'Transfer History:'}
                            </p>
                        </div>
                        {this.props.orders?
                            <div style={{width:'95%', margin:'auto',}}>
                                {this.state.ordersResp?this.history():null}
                            </div>
                        :
                            <div style={{width:'95%', margin:'auto',marginBottom:25}}>
                                {this.state.ordersResp?this.transfers():null}
                            </div>
                        }
                        {/* <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:20}}>
                                Expired Orders:
                            </p>
                        </div>
                        <div style={{width:'95%', margin:'auto',marginBottom:25}}>
                            {this.state.ordersResp?this.expired():null}
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}