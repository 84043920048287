//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

export default function DownloadUpgrade() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='default-container-big border-top'>
      <div className='block squeeze'>
        <div>
          <p className='big-text light-text space-bottom-big'>
            {t('free')} <span className='small-top-text'>*</span>
          </p>
          <p className='small-text grey-color'>
            {t('get_robo_for_automated_trading_msg')}
          </p>
        </div>
        <div className='features-container'>
          <div className='feature'>
            <p className='feature-label'>{t('robo')}</p>
          </div>
          <div className='feature'>
            <p className='feature-label'>{t('forecasting')}</p>
          </div>
          <div className='feature'>
            <p className='feature-label'>{t('forecast_screener')}</p>
          </div>
          <div className='feature'>
            <p className='feature-label'>{t('fractional_trading')}</p>
          </div>
        </div>
      </div>
      <div className='block blue-box squeeze'>
        <div>
          <p className='big-text light-text space-bottom-big'>
            $3.99
            <span className='small-text light-text'>{t('per_month')}</span>
          </p>
          <p className='small-text light-text'>
            {t('everything_from_free_version_msg')}
          </p>
        </div>
        <div className='features-container'>
          <div className='feature white'>
            <p className='feature-label white'>{t('aitrader')}</p>
          </div>
          <div className='feature white'>
            <p className='feature-label white'>{t('goals')}</p>
          </div>
          <div className='feature white'>
            <p className='feature-label white capitalize'>{t('budgeting')}</p>
          </div>
          <div className='feature white'>
            <p className='feature-label white'>+ {t('all_free_features')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
