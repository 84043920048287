import React from 'react'
import ENDPOINT from '../Endpoint'
import { FaUniversity } from 'react-icons/fa'
import BrokerageRow from '../BrokerageRow'
import AreYouSure from '../areYouSureModal'
import SubModal from '../SubModal'
import SearchEndpoint from '../SearchEndpoint'

const brandColor = 'rgb(70,182,179)'

export default class BrokerageAcc extends React.Component {
  state = {
    deposit: false,
    showAllStock: false,
    addRobo: [],
    showSub: false,
  }

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  utcToLocal(timeAndDate) {
    let date = new Date(timeAndDate).toString().split(' ')
    let hour = date[4].substring(0, 2)
    let min = date[4].substring(3, 5)
    let day = date[1] + ' ' + date[2] + ', ' + date[3]
    let amPm = 'AM'
    if (hour > 12) {
      hour = hour - 12
      amPm = 'PM'
    } else if (hour === '00') {
      hour = '12'
    }
    let time = `${hour}:${min} ${amPm}`
    return (
      <div style={{ width: '100%' }}>
        <p style={{ textAlign: 'right', margin: 0, fontSize: 14 }}>
          {time.replace(/^0(?:0:0?)?/, '')}
        </p>
        <p style={{ textAlign: 'right', margin: 0, fontSize: 14 }}>{day}</p>
      </div>
    )
  }

  checkLogo = (symbol) => {
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      return (
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 7,
            overflow: 'hidden',
            width: 70,
            height: 70,
            justifySelf: 'flex-end',
          }}
        >
          <img
            // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
            src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
            onError='this.onerror=null; this.remove();'
            style={{ objectFit: 'contain', width: 70, height: 70 }}
          />
        </div>
      )
    }
  }

  row(stock, total) {
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 5,
            alignItems: 'center',
          }}
        >
          <div style={{ width: '8%' }}>
            {stock.symbol === 'CASH' ? null : this.checkLogo(stock.symbol)}
          </div>
          <a
            style={{ width: '10%' }}
            target='_blank'
            href={
              stock.symbol === 'CASH' || stock.symbol === 'Us dollar'
                ? null
                : `/Dashboard/Trade/${stock.symbol}`
            }
          >
            <p
              style={{
                width: '100%',
                margin: 0,
                textAlign: 'left',
                color: brandColor,
              }}
            >
              {stock.symbol}
            </p>
          </a>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            ${this.addCommas(stock.instPrice.toFixed(2))}
          </p>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            {this.addCommas(stock.quantity.toFixed(2))}
          </p>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            ${this.addCommas(stock.instValue.toFixed(2))}
          </p>
          <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
            {isNaN(((stock.instValue / total) * 100).toFixed(2))
              ? 0
              : ((stock.instValue / total) * 100).toFixed(2)}
            %
          </p>
          <div style={{ width: '2%' }}></div>
        </div>
        <hr></hr>
      </div>
    )
  }

  buttonDropdown = (acc) => {
    if (this.state.activeDropdown) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: 5,
              border: 'solid',
              borderRadius: 2.5,
              borderWidth: '1.25px',
              borderColor: 'rgb(17,17,17)',
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: '',
                color: 'rgb(17,17,17)',
                fontSize: 18,
              }}
            >
              Menu
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              right: '5%',
              backgroundColor: '#f9f9f9',
              minWidth: '10%',
              overflow: 'auto',
              boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
              zIndex: 100,
              marginRight: 25,
            }}
          >
            {/* <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'deposit'?'rgb(200,200,200)':'',}}onClick={()=>this.props.deposit()} onMouseEnter={()=>this.setState({hover:'deposit'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>  
                        </div>
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'withdraw'?'rgb(200,200,200)':'',}}onClick={()=>this.props.withdraw()} onMouseEnter={()=>this.setState({hover:'withdraw'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Withdraw</p>  
                        </div> */}
            {acc.inst === 'DriveWealth' &&
            !acc.ria &&
            this.props.isSmallScreen ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'trade' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.trade()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'trade' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Trade
                </p>
              </div>
            ) : null}
            {acc.inst === 'DriveWealth' &&
            !acc.ria &&
            this.props.isSmallScreen ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'transfer' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.deposit()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'transfer' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Transfer
                </p>
              </div>
            ) : null}
            {acc.inst === 'DriveWealth' && !acc.ria ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'orders' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.orders()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'orders' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Orders
                </p>
              </div>
            ) : null}
            {acc.inst === 'DriveWealth' ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'transactions'
                      ? 'rgb(200,200,200)'
                      : '',
                }}
                onClick={() => {
                  this.props.transactions()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'transactions' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Transactions
                </p>
              </div>
            ) : null}
            {acc.inst === 'DriveWealth' ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'statements' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.statements()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'statements' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Statements
                </p>
              </div>
            ) : null}
            {acc.inst === 'DriveWealth' && !acc.ria ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'rt' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.recurring()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'rt' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Recurring Investments
                </p>
              </div>
            ) : null}
            <div
              style={{
                color: 'black',
                padding: '12px 16px',
                textDecoration: 'none',
                display: 'block',
                cursor: 'pointer',
                backgroundColor:
                  this.state.hover === 'analyze' ? 'rgb(200,200,200)' : '',
              }}
              onClick={() => {
                this.props.analyze()
                this.setState({ hover: false })
              }}
              onMouseEnter={() => this.setState({ hover: 'analyze' })}
            >
              <p style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}>
                Analyze
              </p>
            </div>
            {acc.inst !== 'DriveWealth' || acc.product ? (
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'unlink' ? 'rgb(200,200,200)' : '',
                }}
                onClick={() => {
                  this.props.unlink()
                  this.setState({ hover: false })
                }}
                onMouseEnter={() => this.setState({ hover: 'unlink' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Unlink
                </p>
              </div>
            ) : null}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: 5,
              border: 'solid',
              borderRadius: 2.5,
              borderWidth: '1.25px',
              borderColor: 'rgb(17,17,17)',
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: '',
                color: 'rgb(17,17,17)',
                fontSize: 18,
              }}
            >
              Menu
            </p>
          </div>
        </div>
      )
    }
  }

  accButtons = () => {
    let acc = this.props.acc
    return (
      <div
        style={{
          display: 'flex',
          width: '60%',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 2.5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:true,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 2.5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({orders:false,showOrders:acc})}>
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Transactions</p>
                                    </div>
                                :null
                                }
                                {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 2.5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                } */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: 5,
            border: 'solid',
            borderRadius: 2.5,
            borderWidth: '1.25px',
            borderColor: '#ee5e68',
            marginLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => this.setState({ showUnlink: acc })}
        >
          <p style={{ margin: 0, fontWeight: '', color: '#ee5e68' }}>Unlink</p>
        </div>
      </div>
    )
  }

  tradeStock = (acc, ele) => {
    let stock
    fetch(
      `${SearchEndpoint}/ticker-bulk-live?search=${ele.symbol.toUpperCase()}`
    )
      .then((resp) => resp.json())
      .then((responseJson) => {
        stock = responseJson.content_list[0]
        this.props.tradeStock(acc, stock)
      })
  }

  formatPnL(val) {
    let x = '0.00'
    if (!val) {
      x = '0.00'
    } else {
      x = this.addCommas(val.toFixed(2))
      if (val < 0) {
        x = '-' + x.substring(1)
      } else {
        x = '' + x
      }
    }
    return x
  }

  showAccBody = () => {
    let total = 0
    let acc = this.props.acc
    // console.log('acc', acc)

    acc.holdings.forEach((stock) => {
      if (!stock.cashEq) {
        total += stock.instValue
      }
    })
    if (acc.status === 'OPEN_NO_NEW_TRADES') {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <div style={{ width: '50%' }}>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Cash Available:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].fundsInvestable.toFixed(2))
                  : 0}
              </strong>{' '}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Total Account Value:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].current.toFixed(2))
                  : 0}
              </strong>{' '}
              {acc.inst === 'DriveWealth'
                ? this.formatPnL(acc.balances[0]['profit-loss'])
                : null}{' '}
            </p>
          </div>
          <p
            style={{
              width: '50%',
              margin: 0,
              textAlign: 'center',
              fontSize: 22,
            }}
          >
            Your account status is "liquidate only" so you cannot start using
            the robo now. Please contact ForeSCITE support to rectify this
            issue.
          </p>
        </div>
      )
    } else if (
      acc.balances[0].available === 0 &&
      acc.balances[0].totalUnsettledCash === 0 &&
      acc.balances[0].fundsInvestable === 0 &&
      acc.balances[0].cashAvailableForWithdrawal === 0 &&
      acc.balances[0].current === 0 &&
      acc.holdings.length === 0
    ) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <a onClick={() => this.props.deposit()} href='#'>
            <p
              style={{
                width: '100%',
                margin: 0,
                textAlign: 'left',
                color: brandColor,
                fontSize: 22,
              }}
            >
              To Get Started Click Here and Deposit Funds{' '}
            </p>
          </a>
          {acc.ria ? (
            <p
              style={{
                width: '50%',
                margin: 0,
                textAlign: 'center',
                fontSize: 20,
              }}
            >
              You must deposit at least $500 to get started with the robo
            </p>
          ) : null}
        </div>
      )
    } else if (acc.ria && this.props.hasRobo && !acc.product) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <div style={{ width: '50%' }}>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Cash Available:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].fundsInvestable.toFixed(2))
                  : 0}
              </strong>{' '}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Total Account Value:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(
                      (
                        acc.balances[0].fundsInvestable +
                        acc.balances[0].totalUnsettledCash
                      ).toFixed(2)
                    )
                  : 0}
              </strong>{' '}
              {acc.inst === 'DriveWealth'
                ? this.formatPnL(acc.balances[0]['profit-loss'])
                : null}{' '}
            </p>
          </div>
          <p
            style={{
              width: '50%',
              margin: 0,
              textAlign: 'center',
              color: brandColor,
              fontSize: 22,
            }}
          >
            You can only have one robo at account at a time.
          </p>
        </div>
      )
    } else if (
      acc.ria &&
      acc.balances[0].totalUnsettledCash + acc.balances[0].fundsInvestable <
        500 &&
      !this.props.hasRobo
    ) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: this.props.isSmallScreen ? null : 18,
            }}
          >
            Account Value:{' '}
            <strong style={{ color: brandColor, marginLeft: 5 }}>
              $
              {acc.balances && acc.balances[0] && acc.balances[0].current
                ? this.addCommas(acc.balances[0].current.toFixed(2))
                : 0}
            </strong>
          </p>
          <p
            style={{
              margin: 0,
              fontSize: this.props.isSmallScreen ? null : 18,
            }}
          >
            Account P/L:{' '}
            <strong
              style={{
                color:
                  acc.balances[0]['profit-loss'] < 0
                    ? 'rgb(238,94,104)'
                    : brandColor,
                marginLeft: 5,
              }}
            >
              $
              {acc.inst === 'DriveWealth'
                ? this.formatPnL(acc.balances[0]['profit-loss'])
                : 0.0}
            </strong>{' '}
          </p>
          <a onClick={() => this.props.deposit()} href='#'>
            <p
              style={{
                width: '100%',
                margin: 0,
                textAlign: 'center',
                color: brandColor,
                fontSize: 22,
              }}
            >
              You need at least $500 to start the robo.<br></br> Click here to
              deposit more funds{' '}
            </p>
          </a>
        </div>
      )
    } else if (
      acc.ria &&
      acc.balances[0].totalUnsettledCash + acc.balances[0].fundsInvestable >=
        500 &&
      acc.balances[0].fundsInvestable < 500 &&
      !this.props.hasRobo
    ) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <div style={{ width: '45%' }}>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Cash Available:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].fundsInvestable.toFixed(2))
                  : 0}
              </strong>{' '}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Total Account Value:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].current.toFixed(2))
                  : 0}
              </strong>{' '}
              {acc.inst === 'DriveWealth'
                ? this.formatPnL(acc.balances[0]['profit-loss'])
                : null}{' '}
            </p>
          </div>
          <div style={{ width: '50%' }}>
            <p
              style={{
                margin: 0,
                textAlign: 'justify',
                color: brandColor,
                fontSize: 22,
              }}
            >
              Your funds are not ready yet! This can take up to 3 business days
              please try again later.
            </p>
          </div>
        </div>
      )
    } else if (
      acc.ria &&
      acc.balances[0].fundsInvestable >= 500 &&
      !this.props.hasRobo
    ) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
        >
          <div style={{ width: '50%' }}>
            {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>Cash Available: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ?this.addCommas(acc.balances[0].fundsInvestable.toFixed(2)):0}</strong>  </p> */}
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Total Account Value:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].current.toFixed(2))
                  : 0}
              </strong>
            </p>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Account PnL:{' '}
              <strong
                style={{
                  color:
                    acc.balances[0]['profit-loss'] < 0
                      ? 'rgb(238,94,104)'
                      : brandColor,
                }}
              >
                $
                {acc.inst === 'DriveWealth'
                  ? this.formatPnL(acc.balances[0]['profit-loss'])
                  : null}
              </strong>
            </p>
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Equity Value:{' '}
              <strong
                style={{
                  color:
                    acc.balances[0].equityValue < 0
                      ? 'rgb(238,94,104)'
                      : brandColor,
                }}
              >
                $
                {acc.inst === 'DriveWealth'
                  ? this.formatPnL(acc.balances[0].equityValue)
                  : null}
              </strong>
            </p>
          </div>
          {/* <a href='#'>
                        <p 
                        onClick={()=>{
                            if(this.props.subStatus){
                                if(this.props.canTrade){
                                    this.setState({
                                        addRobo:{
                                            id:acc.accountId,
                                            total: acc.balances[0].available ,
                                            acc:acc,
                                            instName: acc.inst
                                        }
                                    })
                                }else{
                                    console.log('cannot complete')
                                }
                            }
                            else{
                                this.setState({showSub:true})
                            }
                    } 
                        }
                            
                        style={{width:'100%', margin:0 ,textAlign:'left', color:brandColor, fontSize:22}}>{this.props.canTrade?'You are ready to get started! Click Here to Link to Robo':'You are ready to get started! Please come back during market hours to activate your robo'}</p>
                    </a> */}
        </div>
      )
    }
    // else if(acc.ria && acc.balances[0].available >= 100 ){
    else {
      return (
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              width: '95%',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              margin: 'auto',
              marginBottom: 25,
              marginTop: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              Account Value:{' '}
              <strong style={{ color: brandColor, marginLeft: 5 }}>
                $
                {acc.balances && acc.balances[0] && acc.balances[0].current
                  ? this.addCommas(acc.balances[0].current.toFixed(2))
                  : 0}
              </strong>
            </p>
            {acc.inst === 'DriveWealth' && !acc.ria ? (
              <p
                style={{
                  margin: 0,
                  fontSize: this.props.isSmallScreen ? null : 18,
                }}
              >
                {' '}
                Buying Power:{' '}
                <strong style={{ color: brandColor, marginLeft: 5 }}>
                  $
                  {acc.balances &&
                  acc.balances[0] &&
                  acc.balances[0].buyingPower
                    ? this.addCommas(acc.balances[0].buyingPower.toFixed(2))
                    : 0.0}
                </strong>
              </p>
            ) : null}
            {acc.inst === 'DriveWealth' && !acc.ria ? (
              <p
                style={{
                  margin: 0,
                  fontSize: this.props.isSmallScreen ? null : 18,
                }}
              >
                {' '}
                Equity Value:{' '}
                <strong style={{ color: brandColor, marginLeft: 5 }}>
                  $
                  {acc.balances &&
                  acc.balances[0] &&
                  acc.balances[0].equityValue
                    ? this.addCommas(acc.balances[0].equityValue.toFixed(2))
                    : 0.0}
                </strong>
              </p>
            ) : null}
            {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}> Cash: <strong style={{color:brandColor, marginLeft:5}}>${acc.balances&& acc.balances[0]&& acc.balances[0].current ? this.addCommas((acc.balances[0].current-total).toFixed(2)):0}</strong></p> */}
            <p
              style={{
                margin: 0,
                fontSize: this.props.isSmallScreen ? null : 18,
              }}
            >
              {' '}
              Account P/L:{' '}
              <strong
                style={{
                  color:
                    acc.balances[0]['profit-loss'] < 0
                      ? 'rgb(238,94,104)'
                      : brandColor,
                  marginLeft: 5,
                }}
              >
                $
                {acc.inst === 'DriveWealth'
                  ? this.formatPnL(acc.balances[0]['profit-loss'])
                  : 0.0}
              </strong>
            </p>
          </div>
          {/* {this.renderButtons(acc)} */}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: 5,
                marginTop: 10,
              }}
            >
              <p
                style={{ width: '18%', textAlign: 'center', fontSize: 'large' }}
              >
                Symbol
              </p>
              <p
                style={{ width: '20%', textAlign: 'right', fontSize: 'large' }}
              >
                Cost Basis
              </p>
              <p
                style={{ width: '20%', textAlign: 'right', fontSize: 'large' }}
              >
                # Shares
              </p>
              <p
                style={{ width: '20%', textAlign: 'right', fontSize: 'large' }}
              >
                Mkt Val
              </p>
              <p
                style={{ width: '20%', textAlign: 'right', fontSize: 'large' }}
              >
                P/L
              </p>
              <div style={{ width: '2%' }}></div>
            </div>
          </div>
          <div
            style={{
              maxHeight: this.state.showAllStock ? '' : 300,
              overflowY: 'scroll',
            }}
          >
            {acc.holdings.map((ele) => (
              <BrokerageRow
                tradeStock={() => this.tradeStock(acc, ele)}
                key={ele.symbol}
                stock={ele}
                total={acc.balances[0].current}
              />
            ))}
          </div>
          {acc.holdings.length > 3 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  color: brandColor,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  this.setState({ showAllStock: !this.state.showAllStock })
                }
              >
                {this.state.showAllStock ? 'Show Less' : 'Show More'}
              </p>
            </div>
          ) : null}
        </div>
      )
    }
  }

  linkToRobo = () => {
    // console.log('accountId:',accountId)
    let accountId = this.props.acc.accountId
    fetch(`${ENDPOINT}/trackers/live`, {
      method: 'POST',
      headers: {
        // "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({
        accountId: accountId,
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => console.log('link2robo resp', respJSON))
      .then(() => this.props.updateAccs())
      .catch((err) => console.log('err', err))
  }

  subModal = () => {
    if (this.state.showSub) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '95%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {' '}
                Subscribe
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showSub: false })}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <SubModal
                updatePaymentStatus={this.props.updatePaymentStatus}
                modal={true}
                user={this.props.user}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  closeSure = () => {
    this.setState({ addRobo: [] })
  }

  getAccountInfo = (acc) => {
    if (acc.product_assigned) {
      if (acc.product_assigned === 'crypto') {
        return (
          <div style={{ margin: 0, fontSize: 22, textAlign: 'left' }}>
            <p style={{ color: 'blue', marginBottom: '0' }}>CRYPTO</p>
            <p style={{ fontSize: '15px', color: 'grey' }}>
              {acc.inst === 'DriveWealth'
                ? 'DriveWealth ' + acc.mask_full
                : `${acc.inst} ${acc.mask}`}
            </p>
            <p>Self Trading</p>
          </div>
        )
      } else if (acc.product_assigned === 'equity') {
        return (
          <div style={{ margin: 0, fontSize: 22, textAlign: 'left' }}>
            <p style={{ color: 'green', marginBottom: '0' }}>STOCK</p>
            <p style={{ fontSize: '15px', color: 'grey' }}>
              {acc.inst === 'DriveWealth'
                ? 'DriveWealth ' + acc.mask_full
                : `${acc.inst} ${acc.mask}`}
            </p>
            <p>Self Trading</p>
          </div>
        )
      } else if (acc.product_assigned === 'group') {
        return (
          <div style={{ margin: 0, fontSize: 22, textAlign: 'left' }}>
            <p style={{ color: 'black', marginBottom: '0' }}>AI STOCK TRADER</p>
            <p style={{ fontSize: '15px', color: 'grey' }}>
              {acc.inst === 'DriveWealth'
                ? 'DriveWealth ' + acc.mask_full
                : `${acc.inst} ${acc.mask}`}
            </p>
          </div>
        )
      } else if (acc.product_assigned === 'robo') {
        return (
          <div style={{ margin: 0, fontSize: 22, textAlign: 'left' }}>
            <p style={{ color: 'black', marginBottom: '0' }}>ROBO</p>
            <p style={{ fontSize: '15px', color: 'grey' }}>
              {acc.inst === 'DriveWealth'
                ? 'DriveWealth ' + acc.mask_full
                : `${acc.inst} ${acc.mask}`}
            </p>
          </div>
        )
      }
    } else {
      return (
        <div style={{ margin: 0, fontSize: 22, textAlign: 'left' }}>
          <p>
            {acc.inst === 'DriveWealth'
              ? 'DriveWealth ' + acc.mask_full
              : `${acc.inst} ${acc.mask}`}
          </p>
        </div>
      )
    }
  }

  render() {
    let total = 0
    let acc = this.props.acc
    // console.log('acc', acc)

    acc.holdings.forEach((stock) => {
      if (!stock.cashEq) {
        total += stock.instValue
      }
    })
    if (this.props.isSmallScreen) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingTop: 40,
              paddingBottom: 10,
              borderRadius: '50px',
              backgroundImage: 'linear-gradient(97deg, #ffffff 1%, #f2f2f2)',
              width: '100%',
              marginTop: 25,
            }}
          >
            <div style={{ width: '100%', margin: 'auto', marginBottom: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>{this.getAccountInfo(acc)}</strong>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div
                    onClick={() =>
                      this.setState({
                        activeDropdown: !this.state.activeDropdown,
                      })
                    }
                  >
                    {this.buttonDropdown(acc)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginLeft: 10 }}>
                  {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.inst}</p>
                                    <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.mask_full}</p> */}
                  {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.name}</p> */}
                  <p
                    style={{
                      margin: 0,
                      fontSize: this.props.isSmallScreen ? null : 18,
                    }}
                  >
                    <strong style={{ color: brandColor }}>
                      $
                      {acc.balances &&
                      acc.balances[0] &&
                      acc.balances[0].current
                        ? this.addCommas(acc.balances[0].current.toFixed(2))
                        : 0}
                    </strong>
                  </p>
                </div>
                <div style={{ marginRight: 10 }}>
                  <div
                    style={{
                      height: 48,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: 9,
                      marginBottom: 15,
                    }}
                  >
                    <FaUniversity style={{ height: '100%', width: 'auto' }} />
                  </div>
                  {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.ria ? 'RIA-Directed':'Self-Directed'}</p> */}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingTop: 40,
              paddingBottom: 10,
              borderRadius: '10px',
              backgroundImage: 'linear-gradient(97deg, #ffffff 1%, #f2f2f2)',
              width: '100%',
              marginTop: 25,
            }}
          >
            <div style={{ width: '100%', margin: 'auto', marginBottom: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong>{this.getAccountInfo(acc)}</strong>
                <small
                  style={{ fontSize: '20px', color: 'grey', textAlign: 'left' }}
                ></small>
                {/* <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.product === 'robo'? 'Robo': acc.product==='group'?'Trade Group': acc.ria? 'Directed Account':'Self-Directed Account'}: </strong>{acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}</p> */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '50%',
                  }}
                >
                  <div style={{}}>
                    {acc.inst === 'DriveWealth' && !acc.ria ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginBottom: 5,
                          border: 'solid',
                          borderRadius: 2.5,
                          borderWidth: '1.25px',
                          borderColor: '#3b8e8c',
                          marginLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                        // onClick={()=>this.setState({showTrade:acc})}>
                        onClick={() => this.props.trade()}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: '',
                            color: '#3b8e8c',
                            fontSize: 18,
                          }}
                        >
                          Trade
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div style={{}}>
                    {acc.inst === 'DriveWealth' &&
                    acc.ria &&
                    acc.balances[0].fundsInvestable >= 500 &&
                    !this.props.hasRobo ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginBottom: 5,
                          border: 'solid',
                          borderRadius: 2.5,
                          borderWidth: '1.25px',
                          borderColor: '#3b8e8c',
                          marginLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                        id='Activate_Robo_Button'
                        onClick={() => {
                          if (this.props.canTrade === true) {
                            this.setState({
                              addRobo: {
                                id: acc.accountId,
                                total: acc.balances[0].available,
                                acc: acc,
                                instName: acc.inst,
                              },
                            })
                          } else {
                            this.props.openMarketClosed()
                          }
                        }}
                        // onClick={()=>this.getStatements(acc.accountId)}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: '',
                            color: '#3b8e8c',
                            fontSize: 18,
                          }}
                        >
                          Activate Robo
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {acc.inst === 'DriveWealth' && acc.status === 'OPEN' ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginBottom: 5,
                          border: 'solid',
                          borderRadius: 2.5,
                          borderWidth: '1.25px',
                          borderColor: 'rgb(17,17,17)',
                          marginLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                        onClick={() => this.props.deposit()}
                        id='Transfer_Modal_Toggle'
                        // onClick={()=>this.getStatements(acc.accountId)}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: '',
                            color: 'rgb(17,17,17)',
                            fontSize: 18,
                          }}
                        >
                          Transfer
                        </p>
                      </div>
                    ) : null}
                  </div>
                  {acc.status === 'OPEN_NO_NEW_TRADES' &&
                  acc.balances[0].fundsInvestable +
                    acc.balances[0].totalUnsettledCash <
                    0 ? (
                    <div style={{}}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          marginBottom: 5,
                          border: 'solid',
                          borderRadius: 2.5,
                          borderWidth: '1.25px',
                          borderColor: 'rgb(17,17,17)',
                          marginLeft: 10,
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                        onClick={() => this.props.reopen()}
                        // onClick={()=>this.getStatements(acc.accountId)}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: '',
                            color: 'rgb(17,17,17)',
                            fontSize: 18,
                          }}
                        >
                          Re-Open
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {/* {
                                    acc.inst  === 'DriveWealth'? 
                                        <div 
                                        style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 2.5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                        onClick={()=>this.props.withdraw()}
                                        // onClick={()=>this.getStatements(acc.accountId)}
                                        >
                                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)',fontSize:18}}>Withdraw</p>
                                        </div>
                                    :null
                                    } */}
                  <div
                    id='Menu_Dropdown_Hover'
                    onMouseEnter={() => this.setState({ activeDropdown: true })}
                    onMouseLeave={() =>
                      this.setState({ activeDropdown: false, hover: '' })
                    }
                  >
                    {this.buttonDropdown(acc)}
                  </div>
                </div>
              </div>
              {this.showAccBody()}
            </div>
          </div>
          {Object.keys(this.state.addRobo).length === 4 ? (
            <AreYouSure
              product={'robo'}
              info={this.state.addRobo}
              confirm={this.linkToRobo}
              closeModal={this.closeSure}
            />
          ) : null}
          {this.state.showSub ? this.subModal() : null}
        </div>
      )
    }
  }
}
