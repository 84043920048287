import React from 'react'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'

import BarGraph from '../../assets/platform/bars-graphic@2x.png'
import RoboIcon from '../../assets/platform/robo-art@2x.png'
import BudgetIcon from '../../assets/platform/bullseye@2x.png'
import SelfDirectedIcon from '../../assets/platform/self-dir-trade.png'
import GroupIcon from '../../assets/platform/tg.png'

export default class Platform extends React.Component {
  state = {
    isSmallScreen: false,
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  renderBig() {
    return (
      <div>
        <Header
          user={this.props.user}
          signOut={this.props.signOut}
          isSmallScreen={this.state.isSmallScreen}
        />
        <div style={{ marginTop: 75 }}>
          <div
            style={{
              backgroundColor: 'rgb(230,230,230)',
              paddingTop: '5%',
              paddingLeft: '0%',
            }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <div
                style={{
                  width: '70%',
                  paddingBottom: '10%',
                  paddingLeft: '5%',
                }}
              >
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 'bolder',
                    color: 'rgb(255,255,255)',
                    textShadow: '1px 1.7px 2.8px rgba(20, 20, 20, 0.13)',
                  }}
                >
                  Platform
                </p>
                <p style={{ fontSize: 30, fontWeight: 'bold' }}>
                  An investment super app that treats you like a professional
                </p>
                <p style={{ fontSize: 26 }}>
                  ForeSCITE allows retail investors to trade smarter by
                  leveraging the tech used by hedge funds and large financial
                  institutions.
                </p>
                <p style={{ fontSize: 24 }}>
                  ForeSCITE not only supercharges your investing, but aggregates
                  all of your financial accounts. The more accounts you link,
                  the more powerful our tool is to help you assess your
                  risk,spending habits, and more.{' '}
                </p>
              </div>
              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <img
                  src={BarGraph}
                  style={{ width: '100%', marginRight: 20 }}
                />
              </div>
            </div>
          </div>
          <div>
            {/* GROUP SECTION */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <div
                style={{ width: '50%', paddingBottom: '5%', paddingTop: '5%' }}
              >
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 'bold',
                    color: '#3f4174',
                    marginBottom: 20,
                  }}
                >
                  Our{' '}
                  <strong style={{ color: '#86c03c', fontWeight: 'bold' }}>
                    AI-Driven
                  </strong>{' '}
                  Strategies
                </p>
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  ForeSCITE Group Trader
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Quant trading for the average investor
                </p>
                <ul>
                  <li>
                    Leverage AI-powered automated trading, just like the hedge
                    funds use
                  </li>
                  <li>You pick your stocks, up to four in each trade group</li>
                  <li>
                    Trades are automatically placed based on momentum shifts - a
                    combination of over a hundred thousand factors
                  </li>
                  <li>
                    Our AI optimizes trade orders to best lower risk and reap
                    the reward{' '}
                  </li>
                </ul>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={GroupIcon}
                  style={{ width: '50%', marginRight: 20 }}
                />
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* ROBO SECTION */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={RoboIcon} style={{ width: '30%' }} alt='' />
              </div>
              <div
                style={{ width: '50%', paddingBottom: '5%', paddingTop: '5%' }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  ForeSCITE Invest Robo
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Trusted by the big banks, now available to the masses
                </p>
                <ul>
                  <li>
                    Complete an investment questionnaire and our AI picks a
                    strategy tailored to your goals and risk tolerance
                  </li>
                  <li>
                    Investment strategies are rebalanced monthly based on
                    current market conditions
                  </li>
                  <li>
                    Highly liquid so you can always get your money when you need
                    it
                  </li>
                  <li>
                    Proven track record outperforming other top robo advisors
                  </li>
                </ul>
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* SELF DIRECTED SECTION */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <div
                style={{ width: '50%', paddingBottom: '5%', paddingTop: '5%' }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  Self Directed Investing
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  The only trading super app that helps you see into the future
                </p>
                <ul>
                  <li>
                    Invest commission-free in individual stocks or bundles of
                    investments (ETFs)
                  </li>
                  <li>
                    Use our price trends and 30 day price forecasts to guide
                    your investments{' '}
                  </li>
                  <li>
                    Invest in over 5,000 stocks such as Apple, Amazon, Tesla,
                    etc.
                  </li>
                  <li>
                    Buy fractional shares of high-cost stocks or any stock you’d
                    like to invest in{' '}
                  </li>
                </ul>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={SelfDirectedIcon} style={{ width: '50%' }} />
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* Budgetting SECTION */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={BudgetIcon} style={{ width: '30%' }} />
              </div>
              <div
                style={{ width: '50%', paddingBottom: '5%', paddingTop: '5%' }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  Budgeting & Goals Planning
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Secure your future today with AI-powered financial planning
                </p>
                <ul>
                  <li>
                    Suitable for any long term savings goal - whether you’re
                    preparing for retirement, a college fund, or finally saving
                    up for that big purchase
                  </li>
                  <li>
                    Simply set a savings goal and investment strategy for your
                    portfolio and ForeSCITE guides your investing and spending
                    habits.
                  </li>
                  <li>
                    Set up automated monthly contributions to help keep you on
                    track
                  </li>
                  <li>
                    You pick your own investment strategy - safe or moderately
                    aggressive depending on your cash goal and timeline
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </div>
    )
  }
  renderSmall() {
    return (
      <div>
        <Header
          user={this.props.user}
          signOut={this.props.signOut}
          isSmallScreen={this.state.isSmallScreen}
        />
        <div style={{ marginTop: 75 }}>
          <div
            style={{
              backgroundColor: 'rgb(230,230,230)',
              paddingTop: '5%',
              paddingLeft: '7%',
            }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <div
                style={{
                  width: '90%',
                  paddingBottom: '10%',
                  paddingLeft: '5%',
                  margin: 'auto',
                }}
              >
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 'bolder',
                    color: 'rgb(255,255,255)',
                    textShadow: '1px 1.7px 2.8px rgba(20, 20, 20, 0.13)',
                  }}
                >
                  Platform
                </p>
                <p style={{ fontSize: 30, fontWeight: 'bold' }}>
                  An investment super app that treats you like a professional
                </p>
                <p style={{ fontSize: 26 }}>
                  ForeSCITE allows retail investors to trade smarter by
                  leveraging the tech used by hedge funds and large financial
                  institutions.
                </p>
                <p style={{ fontSize: 24 }}>
                  ForeSCITE not only supercharges your investing, but aggregates
                  all of your financial accounts. The more accounts you link,
                  the more powerful our tool is to help you assess your
                  risk,spending habits, and more.{' '}
                </p>
              </div>
            </div>
          </div>
          <div>
            {/* GROUP SECTION */}
            <div
              style={{ width: '100%', paddingLeft: '5%', paddingRight: '5%' }}
            >
              <p
                style={{
                  fontSize: 36,
                  fontWeight: 'bold',
                  color: '#3f4174',
                  marginBottom: 20,
                  textAlign: 'center',
                }}
              >
                Our{' '}
                <strong style={{ color: '#86c03c', fontWeight: 'bold' }}>
                  AI-Driven
                </strong>{' '}
                Strategies
              </p>
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={GroupIcon} style={{ width: '100%' }} />
              </div>
              <div
                style={{
                  width: '90%',
                  paddingBottom: '5%',
                  paddingTop: '5%',
                  margin: 'auto',
                }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  ForeSCITE <br />
                  Group Trader
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Quant trading for the average investor
                </p>
                <ul>
                  <li>
                    Leverage AI-powered automated trading, just like the hedge
                    funds use
                  </li>
                  <li>You pick your stocks, up to four in each trade group</li>
                  <li>
                    Trades are automatically placed based on momentum shifts - a
                    combination of over a hundred thousand factors
                  </li>
                  <li>
                    Our AI optimizes trade orders to best lower risk and reap
                    the reward{' '}
                  </li>
                </ul>
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* ROBO SECTION */}
            <div
              style={{ width: '100%', paddingLeft: '5%', paddingRight: '5%' }}
            >
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }}
              >
                <img src={RoboIcon} style={{ width: '70%' }} />
              </div>
              <div
                style={{
                  width: '90%',
                  paddingBottom: '5%',
                  paddingTop: '5%',
                  margin: 'auto',
                }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  ForeSCITE Invest Robo
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Trusted by the big banks, now available to the masses
                </p>
                <ul>
                  <li>
                    Complete an investment questionnaire and our AI picks a
                    strategy tailored to your goals and risk tolerance
                  </li>
                  <li>
                    Investment strategies are rebalanced monthly based on
                    current market conditions
                  </li>
                  <li>
                    Highly liquid so you can always get your money when you need
                    it
                  </li>
                  <li>
                    Proven track record outperforming other top robo advisors
                  </li>
                </ul>
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* SELF DIRECTED SECTION */}
            <div
              style={{
                width: '100%',
                display: '',
                paddingLeft: '5%',
                paddingRight: '5%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={SelfDirectedIcon} style={{ width: '80%' }} />
              </div>
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                  paddingBottom: '5%',
                  paddingTop: '5%',
                }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  Self Directed Investing
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  The only trading super app that helps you see into the future
                </p>
                <ul>
                  <li>
                    Invest commission-free in individual stocks or bundles of
                    investments (ETFs)
                  </li>
                  <li>
                    Use our price trends and 30 day price forecasts to guide
                    your investments{' '}
                  </li>
                  <li>
                    Invest in over 5,000 stocks such as Apple, Amazon, Tesla,
                    etc.
                  </li>
                  <li>
                    Buy fractional shares of high-cost stocks or any stock you’d
                    like to invest in{' '}
                  </li>
                </ul>
              </div>
            </div>
            {/* GREEN LINE */}
            <div
              style={{
                width: '90%',
                backgroundColor: '#a6d46d',
                height: 2,
                margin: 'auto',
                marginTop: 50,
                marginBottom: 10,
              }}
            />
            {/* Budgetting SECTION */}
            <div
              style={{ width: '100%', paddingLeft: '5%', paddingRight: '5%' }}
            >
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={BudgetIcon} style={{ width: '70%' }} />
              </div>
              <div
                style={{ width: '100%', paddingBottom: '5%', paddingTop: '5%' }}
              >
                <p style={{ fontSize: 32, fontWeight: 'bold' }}>
                  Budgeting & Goals Planning
                </p>
                <p style={{ fontSize: 22, margin: 0 }}>
                  Secure your future today with AI-powered financial planning
                </p>
                <ul>
                  <li>
                    Suitable for any long term savings goal - whether you’re
                    preparing for retirement, a college fund, or finally saving
                    up for that big purchase
                  </li>
                  <li>
                    Simply set a savings goal and investment strategy for your
                    portfolio and ForeSCITE guides your investing and spending
                    habits.
                  </li>
                  <li>
                    Set up automated monthly contributions to help keep you on
                    track
                  </li>
                  <li>
                    You pick your own investment strategy - safe or moderately
                    aggressive depending on your cash goal and timeline
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </div>
    )
  }
  render() {
    return this.state.isSmallScreen ? this.renderSmall() : this.renderBig()
  }
}
