import React from 'react'
import GaugeChart from "react-gauge-chart"

const uncolored = [
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
    "#a3a5a8",
];
const colored = [
    "#7bc0c3",
    "#7bc0c3",
    "#7bc0c3",
    "#f2ab25",
    "#f2ab25",
    "#f2ab25",
    "#f2ab25",
    "#f28425",
    "#f28425",
    "#f28425",
    "#f26425",
    "#f26425",
    "#f26425",
    "#f24c25",
    "#f24c25",
    "#f24c25",
    "#f24c25",
    "#f22525",
    "#f22525",
    "#f22525",
];
export default class RiskGuage extends React.Component{
    

    textColor(input) {
        let num = ((Math.abs(input) * 2) / 5) * 100
        if (colored[num.toFixed(0)]) {
            return colored[num.toFixed(0)];
        } else {
            return colored[19]
        }
    }

    setColoredGauges(input) {
        let pivotPoint = ((Math.abs(input) * 2) / 5) * 100;
        let answer = [...uncolored];
        return answer.map((ele, i) => {
            if (i <= pivotPoint) {
                return colored[i];
            } else {
                return ele;
            }
        });
    }

    setGaugeText(cvar) {
        if (cvar <= 0.15) {
            return 'low risk'
        }
        if (cvar > 0.15 && cvar < 0.3) {
            return 'medium risk'
        }
        if (cvar >= 0.3) {
            return 'high risk'
        }
    }

    render(){
        return(
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', flexDirection:'column'}}>
                {this.props.companyPage?null:
                <div style={{display: 'flex', justifyContent: 'flex-start', width:'100%', padding:8}}>
                    <p style={{ fontSize:24,fontWeight:'bold'}}>{this.props.before? 'Actual Risk' : 'Optimized Risk'}</p>
                </div>
                }
                <div style={{marginTop:-20 }}>
                    <GaugeChart
                        style={{ backgroundColor: "hsla(0, 100%, 90%, 0.0)" }}
                        id= {this.props.before ?"gauge-chart3": "gauge-chart4"}
                        nrOfLevels={20}
                        colors={this.setColoredGauges(this.props.cvar)}
                        percent={parseFloat(this.props.cvar.toFixed(2))}
                        textColor={this.textColor(this.props.cvar)}
                        needleColor="hsla(0, 100%, 90%, 0.0)"
                        needleBaseColor="hsla(0, 100%, 90%, 0.0)"
                        animate={false}
                        cornerRadius={8}
                        formatTextValue={(value) => value + "%"}
                    />
                </div>
                <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h6
                    style={{
                    fontWeight: "bold",
                    color: this.textColor(this.props.cvar),
                    marginTop: -40,
                    }}
                >
                    {this.setGaugeText(this.props.cvar)}
                </h6>
                </div>
            </div>
        )
    }
}