import React from 'react'
import '../../../../scss/pages/account-page/ai-stock-account-info/GroupSwapStock.scss'
import DoubleNegative from '../../../../assets/double-negative.png'
import SingleNegative from '../../../../assets/single-negative.png'
import Neutral from '../../../../assets/neutral.png'
import SinglePositive from '../../../../assets/single-positive.png'
import DoublePositive from '../../../../assets/double-positive.png'
import { RadioButton } from 'primereact/radiobutton'
import { Button } from 'primereact/button'
import { useState } from 'react'
import Loader from '../Loader'
import { swapTradeGroupStock } from '../../../../services/userServices'

export default function GroupSwapStock({
  t,
  selectedStock,
  user,
  selectedAccount,
  oldStock,
  getAllAccountInfo,
}) {
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  const getArrow = (direction) => {
    switch (direction) {
      case -1:
        return SingleNegative
      case 1:
        return SinglePositive
      case 2:
        return DoublePositive
      case -2:
        return DoubleNegative
      default:
        return Neutral
    }
  }

  const swapStock = async () => {
    setLoading(true)
    if (selected !== null) {
      console.log(user.idToken.jwtToken, selectedAccount?.accountId, {
        [selectedStock.name]: selectedStock.list[selected].ticker,
      })
      const res = await swapTradeGroupStock(
        user.idToken.jwtToken,
        selectedAccount?.accountId,
        {
          [selectedStock.name]: selectedStock.list[selected].ticker,
        }
      )
      if (res) {
        await getAllAccountInfo()
        setSuccessMsg(t('stockreplacedsuccessfully'))
      } else {
        setErrorMsg(t('somethingwentwrong'))
      }
    }

    setLoading(false)
  }

  if (loading) {
    return <Loader logoLoader={false} />
  } else {
    if (successMsg) {
      return (
        <div className='suggested-container'>
          <p className='success'>{successMsg}</p>
        </div>
      )
    } else {
      return (
        <div className='suggested-container'>
          {/* <p className='suggested-title'>{t('suggestedcomparables')}</p> */}
          <div style={{ width: '100%' }}>
            <div className='suggested-header-row'>
              <p className='suggested-header'>{t('symbol')}</p>
              <p className='suggested-header right'>{t('trend')}</p>
            </div>

            {selectedStock?.list.map((item, index) => {
              return (
                <div
                  key={index}
                  className='suggested-row'
                  onClick={() => {
                    if (selected === index) {
                      setSelected(null)
                    } else {
                      setSelected(index)
                    }
                  }}
                >
                  <RadioButton
                    value={index}
                    // onChange={(e) => {
                    //   if(selected === index){
                    //     setSelected(null)
                    //   }else{
                    //     setSelected(index)
                    //   }
                    // }}
                    checked={selected === index}
                    className='suggested-radio'
                  />
                  <p className='suggested-value'>{item.ticker}</p>
                  <img src={getArrow(item.trend)} className='suggested-arrow' />
                </div>
              )
            })}
          </div>
          {errorMsg && <p className='error'>{errorMsg}</p>}
          <div className='suggested-btn-container'>
            <Button
              className='suggested-btn'
              label={t('submit')}
              disabled={selected === null}
              onClick={() => {
                swapStock()
              }}
            />
          </div>
        </div>
      )
    }
  }
}
