import React, { useState, useEffect } from 'react'
import '../scss/components/DwApexQuestionnaire.scss'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import Axios from 'axios'
import Endpoint from './Endpoint'
import { InputText } from 'primereact/inputtext'
import { Sidebar } from 'primereact/sidebar'
import { Calendar } from 'primereact/calendar'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'aws-amplify-react'
import { FileUpload } from 'primereact/fileupload'
import ApexDisclosures from './pages/disclosures/ApexDisclosures'
import DWDisclosures from './pages/disclosures/DWDisclosures'
import ForesciteDisclosures from './pages/disclosures/ForesciteDisclosures'
import states from '../states'
import { validatePhoneNumber } from '../utility-functions/validatePhoneNumber'
import Loader from './components/accountInfo/Loader'
import { validateUsername } from '../utility-functions/validateUsername'
import { validateSpecialCharacter } from '../utility-functions/validatePassword'

export default function DwApexQuestionnaire(props) {
  //PROPS
  // apexStatus : boolean, true if apex account exists
  // driveWealthStatus: boolean, true if drive wealth account exists
  // user: user
  // action: action to call when api succeeds
  const [dwApexData, setDwApexData] = useState({
    isPoliticallyExposed: { status: 'NO' },
    isControlPerson: { status: 'NO' },
    isAffiliatedExchangeOrFINRA: { status: 'NO' },
    // address2: '',
    // country: 'USA',
    // province: 'gawda',
    // phoneNumber: '703-477-6377',
    // usTaxPayer: true,
    // taxNo: '666-00-0000',
    // countryCitizenship: 'USA',
    // postalCode: '07029',
    // city: 'Harrison',
    // address1: '739 Harrison Ave',
    // employmentStatus: 'RETIRED',
    // employmentType: 'CONSTRUCTION',
    // employmentPosition: 'ACTUARY',
    // employmentCompany: 'e232323',
    // annualIncome: 232323,
    // netWorth: 232323,
    // investmentObjectives: 'LONG_TERM',
    // investmentObjective: 'GROWTH_INCOME',
    // contactGivenName: 'john',
    // contactFamilyName: 'doe',
    // contactEmail: 'mytest20@mailinator.com',
    // contactPhoneNumber: '232-323-2323',
    // firstName: 'Daniel',
    // lastName: 'kim',
  })
  const [birthday, setBirthday] = useState('')
  const [agreement, setAgreement] = useState(false)
  const [agreement3, setAgreement3] = useState(false)
  const [agreement4, setAgreement4] = useState(false)
  const [countries, setCountries] = useState([])
  const [countries_all, setAllCountries] = useState([])
  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([])
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([])
  const [employmentPositionOptions, setEmploymentPositionOptions] = useState([])
  const [investmentObjectivesOptions, setInvestmentObjectivesOptions] =
    useState([])
  const [dwAccount, setDwAccount] = useState(true)
  const [apexAccount, setApexAccount] = useState(true)

  useEffect(() => {
    if (props.selectedAccount) {
      switch (props.selectedAccount) {
        case 'STOCK':
          setApexAccount(false)
          setAgreement(true)
          break
        case 'CRYPTO':
          setDwAccount(false)
          setAgreement3(true)
          break
        case 'ROBO':
          setApexAccount(false)
          setAgreement(true)
          break
        case 'AI STOCK TRADER':
          setApexAccount(false)
          setAgreement(true)
          break
        case 'AI CRYPTO TRADER':
          setDwAccount(false)
          setAgreement3(true)
          break
        case 'GOALS':
          setApexAccount(false)
          setAgreement(true)
          break
        default:
          break
      }
    }
  }, [])
  const investmentGoalOptions = [
    {
      value: 'CAPITAL_PRESERVATION',
      label: 'Capital Preservation',
    },
    {
      value: 'INCOME',
      label: 'Income',
    },
    {
      value: 'GROWTH_INCOME',
      label: 'Growth Income',
    },
    {
      value: 'GROWTH',
      label: 'Growth',
    },
    {
      value: 'SPECULATION',
      label: 'Speculation',
    },
    {
      value: 'MAXIMUM_GROWTH',
      label: 'Maximum Growth',
    },
    {
      value: 'BALANCED',
      label: 'Balanced',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ]
  const [accountHolderTypeOptions, setAccountHolderTypeOptions] = useState([])
  const [document, setDocument] = useState({})
  const [showAgreement, setShowAgreement] = useState(false)
  const [showAgreement2, setShowAgreement2] = useState(false)
  const [showAgreement3, setShowAgreement3] = useState(false)
  const [showAgreement4, setShowAgreement4] = useState(false)

  const [readAgreement, setReadAgreement] = useState(false)
  const [readAgreement2, setReadAgreement2] = useState(false)
  const [readAgreement3, setReadAgreement3] = useState(false)
  const [readAgreement4, setReadAgreement4] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')

  const FormData = require('form-data')

  useEffect(() => {
    fetchUserInfo()
    fetchPresets()
  }, [])

  const fetchUserInfo = async () => {
    await Axios.get(`${Endpoint}/mobile/fetch-existing-info`, {
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
      },
    })
      .then((res) => {
        if (res?.data?.dwData) {
          parseDwData(res.data.dwData)
        }
        if (res?.data?.apexData) {
          parseApexData(res.data.apexData)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const fetchPresets = async () => {
    await Axios.get(`${Endpoint}/drivewealth/preset`)
      .then((res) => {
        if (res?.data) {
          let countries = []
          res.data.countries.map((country) => {
            countries.push({
              label: country.name,
              value: country.id,
            })
          })
          setCountries(countries)

          let allCountries = []
          res.data.countries_all.map((country) => {
            allCountries.push({
              label: country.name,
              value: country.id,
            })
          })
          setAllCountries(allCountries)

          let employmentStatusOptions = []
          res.data.emp_status.map((status) => {
            employmentStatusOptions.push({
              label: Object.values(status)[0],
              value: Object.keys(status)[0],
            })
          })
          setEmploymentStatusOptions(employmentStatusOptions)

          let employmentTypeOptions = []
          res.data.emp_types.map((type) => {
            employmentTypeOptions.push({
              label: Object.values(type)[0],
              value: Object.keys(type)[0],
            })
          })
          setEmploymentTypeOptions(employmentTypeOptions)

          let employmentPositionOptions = []
          res.data.emp_positions.map((option) => {
            employmentPositionOptions.push({
              label: Object.values(option)[0],
              value: Object.keys(option)[0],
            })
          })
          setEmploymentPositionOptions(employmentPositionOptions)

          let investmentObjectives = []
          res.data.inv_objectives.map((option) => {
            investmentObjectives.push({
              label: Object.values(option)[0],
              value: Object.keys(option)[0],
            })
          })
          setInvestmentObjectivesOptions(investmentObjectives)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const parseDwData = (dwData) => {
    if (dwData.annual_income)
      handleDwApexInputChanges('annualIncome', dwData.annual_income)
    if (dwData.net_worth) handleDwApexInputChanges('netWorth', dwData.net_worth)
    if (dwData.birth_day)
      handleDwApexInputChanges('birthdayDay', dwData.birth_day)
    if (dwData.birth_month)
      handleDwApexInputChanges('birthdayMonth', dwData.birth_month)
    if (dwData.birth_year)
      handleDwApexInputChanges('birthdayYear', dwData.birth_year)
    if (dwData.city) handleDwApexInputChanges('city', dwData.city)
    if (dwData.country) handleDwApexInputChanges('country', dwData.country)
    if (dwData.country_citizenship)
      handleDwApexInputChanges('countryCitizenship', dwData.country_citizenship)
    if (dwData.email) handleDwApexInputChanges('contactEmail', dwData.email)
    if (dwData.emp_company)
      handleDwApexInputChanges('employmentCompany', dwData.emp_company)
    if (dwData.emp_position)
      handleDwApexInputChanges('employmentPosition', dwData.emp_position)
    if (dwData.emp_status)
      handleDwApexInputChanges('employmentStatus', dwData.emp_status)
    if (dwData.emp_type)
      handleDwApexInputChanges('employmentType', dwData.emp_type)
    if (dwData.first_name)
      handleDwApexInputChanges('firstName', dwData.first_name)
    if (dwData.last_name) handleDwApexInputChanges('lastName', dwData.last_name)
    if (dwData.investment_objectives)
      handleDwApexInputChanges(
        'investmentObjectives',
        dwData.investment_objectives
      )
    if (dwData.phone) handleDwApexInputChanges('phoneNumber', dwData.phone)
    if (dwData.province) handleDwApexInputChanges('province', dwData.province)
    if (dwData.street) handleDwApexInputChanges('address1', dwData.street)
    if (dwData.street2) handleDwApexInputChanges('address2', dwData.street2)
    if (dwData.tax_no) handleDwApexInputChanges('taxNo', dwData.tax_no)
    if (dwData.tax_id_type)
      handleDwApexInputChanges('taxIdType', dwData.tax_id_type)
    if (dwData.us_tax_payer)
      handleDwApexInputChanges('usTaxPayer', dwData.us_tax_payer)
    if (dwData.zipCode) handleDwApexInputChanges('postalCode', dwData.zipCode)
  }

  const parseApexData = (apexData) => {
    if (apexData.address1)
      handleDwApexInputChanges('address1', apexData.address1)
    if (apexData.address2)
      handleDwApexInputChanges('address2', apexData.address2)
    if (apexData.city) handleDwApexInputChanges('city', apexData.city)
    if (apexData.country) handleDwApexInputChanges('country', apexData.country)
    if (apexData.email) handleDwApexInputChanges('contactEmail', apexData.email)
    if (apexData.net_worth)
      handleDwApexInputChanges('netWorth', apexData.net_worth)
    if (apexData.first_name)
      handleDwApexInputChanges('firstName', apexData.first_name)
    if (apexData.last_name)
      handleDwApexInputChanges('lastName', apexData.last_name)
    if (apexData.nationality)
      handleDwApexInputChanges('countryCitizenship', apexData.nationality)
    if (apexData.phone_number)
      handleDwApexInputChanges('phoneNumber', apexData.phone_number)
    if (apexData.province)
      handleDwApexInputChanges('province', apexData.province)
    if (apexData.zipCode)
      handleDwApexInputChanges('postalCode', apexData.zipCode)
  }

  const parseBirthday = (birthday) => {
    const date = new Date(birthday)
    setBirthday(birthday)
    handleDwApexInputChanges('birthdayDay', date.getDate())
    handleDwApexInputChanges('birthdayMonth', date.getMonth() + 1)
    handleDwApexInputChanges('birthdayYear', date.getFullYear())
  }

  const handleDwApexInputChanges = (name, value) => {
    setDwApexData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handlePoliticallyExposedChanges = (name, value) => {
    setDwApexData({
      ...dwApexData,
      isPoliticallyExposed: {
        ...dwApexData.isPoliticallyExposed,
        [name]: value,
      },
    })
  }

  const handleControlPersonChanges = (name, value) => {
    setDwApexData({
      ...dwApexData,
      isControlPerson: {
        ...dwApexData.isControlPerson,
        [name]: value,
      },
    })
  }

  const handleisAffiliatedExchangeOrFINRAChanges = (name, value) => {
    setDwApexData({
      ...dwApexData,
      isAffiliatedExchangeOrFINRA: {
        ...dwApexData.isAffiliatedExchangeOrFINRA,
        [name]: value,
      },
    })
  }

  const uploadFile = async ({ files }) => {
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    let metaData = JSON.stringify({
      correspondent: 'FCTE',
      documentType: 'AFFILIATED_APPROVAL',
    })
    formData.append('metadata', metaData)

    await Axios.post(`${Endpoint}/apex-crypto/upload/documents`, formData, {
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        // Accept: "application/json;charset=utf-8",
        // "Content-Type": "multipart/form-data",
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res?.data?.documentId) {
          handleisAffiliatedExchangeOrFINRAChanges(
            'affiliatedApproval',
            res.data.documentId
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const validateAge = () => {
    var today = new Date()
    var birthDate = new Date(birthday)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age >= 18
  }

  //   const uploadFile = async () => {
  //     console.log(document)
  //   const formData = new FormData();
  //   formData.append("file", document[0]);
  //   let metaData = JSON.stringify({
  //     correspondent: "FCTE",
  //     documentType: "AFFILIATED_APPROVAL",
  //   });
  //   formData.append("metadata", metaData);

  //   await Axios.post(`${Endpoint}/apex-crypto/upload/documents`, formData, {
  //     headers: {
  //       Authorization: "Bearer " + props.user?.idToken?.jwtToken,
  //       // Accept: "application/json;charset=utf-8",
  //       // "Content-Type": "multipart/form-data",
  //       Accept: "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       console.log("file", res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const validateForm = () => {
    if (
      dwApexData?.firstName &&
      dwApexData?.lastName &&
      dwApexData?.birthdayDay &&
      dwApexData?.birthdayMonth &&
      dwApexData?.birthdayYear &&
      dwApexData?.phoneNumber &&
      dwApexData?.country &&
      dwApexData?.taxNo &&
      dwApexData?.countryCitizenship &&
      dwApexData?.postalCode &&
      dwApexData?.postalCode?.length === 5 &&
      dwApexData?.city &&
      dwApexData?.address1 &&
      dwApexData?.investmentObjective &&
      dwApexData?.province &&
      dwApexData?.employmentStatus &&
      dwApexData?.employmentType &&
      dwApexData?.employmentPosition &&
      dwApexData?.employmentCompany &&
      dwApexData?.annualIncome &&
      dwApexData?.netWorth &&
      dwApexData?.investmentObjectives &&
      dwApexData?.contactGivenName &&
      dwApexData?.contactFamilyName &&
      dwApexData?.contactEmail &&
      dwApexData?.contactPhoneNumber &&
      (dwApexData?.isPoliticallyExposed.status === 'NO' ||
        (dwApexData?.isPoliticallyExposed.status === 'YES' &&
          dwApexData?.isPoliticallyExposed.politicalOrganization &&
          dwApexData?.isPoliticallyExposed.immediateFamily)) &&
      (dwApexData?.isAffiliatedExchangeOrFINRA.status === 'NO' ||
        (dwApexData?.isAffiliatedExchangeOrFINRA.status === 'YES' &&
          dwApexData.isAffiliatedExchangeOrFINRA.firmName &&
          dwApexData.isAffiliatedExchangeOrFINRA.affiliatedApproval)) && // add uuid for image
      (dwApexData?.isControlPerson.status === 'NO' ||
        (dwApexData?.isControlPerson.status === 'YES' &&
          dwApexData?.isControlPerson.companySymbols))
    ) {
      if (agreement && agreement3 && agreement4) {
        if (birthday && validateAge()) {
          if (
            validatePhoneNumber(dwApexData?.phoneNumber) &&
            validatePhoneNumber(dwApexData?.contactPhoneNumber)
          ) {
            if (validateUsername(dwApexData?.contactEmail)) {
              if (!validateSpecialCharacter(dwApexData?.contactGivenName)) {
                if (!validateSpecialCharacter(dwApexData?.contactFamilyName)) {
                  if (props.selectedAccount) {
                    switch (props.selectedAccount) {
                      case 'STOCK':
                        callDwAPI('equity')
                        break
                      case 'CRYPTO':
                        callApexAPI('crypto')
                        break
                      case 'ROBO':
                        callDwAPI('robo')
                        break
                      case 'AI STOCK TRADER':
                        callDwAPI('group')
                        break
                      case 'AI CRYPTO TRADER':
                        callApexAPI('group-crypto')
                        break
                      case 'GOALS':
                        callDwAPI('goal')
                        break
                      default:
                        break
                    }
                  } else {
                    createAllAccounts()
                  }
                } else {
                  setErrorMsg('Please enter a valid trusted contact last name')
                }
              } else {
                setErrorMsg('Please enter a valid trusted contact first name')
              }
            } else {
              setErrorMsg('Please enter a valid trusted contact email')
            }
          } else {
            setErrorMsg('Please enter a valid phone number')
          }
        } else {
          setErrorMsg(
            'You must be at least 18 years old to open an investment account.'
          )
        }
      } else {
        setErrorMsg('Please accept the agreements to continue')
      }
    } else {
      setErrorMsg('Please complete all fields')
    }
  }

  const callDwAPI = async (name) => {
    setLoading(true)
    setErrorMsg('')
    const dwBody = getDwBody()
    await Axios.post(
      // `${Endpoint}/dw/users/create/account?ria=${false}&product=${name}`,
      `${Endpoint}/mobile/dw/create-user-and-dw?ria=${false}&product=${name}`,
      dwBody,
      {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((res) => {
        if (res?.data?.success) {
          handleSuccess()
        } else {
          setErrorMsg('Something went wrong. Please try again later')
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setErrorMsg('Something went wrong. Please try again later')
        setLoading(false)
      })
  }

  const callApexAPI = async (name) => {
    setLoading(true)
    setErrorMsg('')
    const apexBody = getApexBody()
    await Axios.post(
      `${Endpoint}/apex-crypto/user/create?product=${name}`,
      apexBody,
      {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((res) => {
        if (res?.data?.status?.summary_status === 'SUCCESS') {
          handleSuccess()
        } else {
          setErrorMsg('Something went wrong. Please try again later')
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const createAllAccounts = async () => {
    setLoading(true)
    setErrorMsg('')
    const dwBody = getDwBody()
    const apexBody = getApexBody()
    console.log(dwBody, apexBody)
    let createdCount = 0
    await Axios.post(
      // `${Endpoint}/dw/users/create/account?ria=${false}&product=${'equity'}`,
      `${Endpoint}/mobile/dw/create-user-and-dw?ria=${false}&product=${'equity'}`,
      dwBody,
      {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      }
    )
      .then(async (res) => {
        if (res?.data?.success) {
          createdCount++
          await Axios.get(
            // `${Endpoint}/dw/accounts/create?ria=${true}&product=${'robo'}`,
            `${Endpoint}/mobile/dw/create-dw-account?ria=${true}&product=${'robo'}`,
            {
              headers: {
                Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
              },
            }
          )
            .then((res) => {})
            .catch((err) => {
              setErrorMsg('Something went wrong. Please try again later')
              setLoading(false)
              return
            })
        } else {
          if (res?.data?.payload) {
            setErrorMsg(res?.data?.payload)
          } else {
            setErrorMsg('Something went wrong. Please try again later')
          }
          setLoading(false)
          return
        }
        // if (dwApexData.country === 'USA' && dwApexData.province !== 'NY') {
        if (dwApexData.country === 'USA') {
          await Axios.post(
            `${Endpoint}/apex-crypto/user/create?product=crypto`,
            apexBody,
            {
              headers: {
                Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
              },
            }
          )
            .then((res) => {
              if (res?.data?.status?.summary_status === 'SUCCESS') {
                handleSuccess()
              } else {
                if (createdCount > 0) {
                  handleSuccess()
                } else {
                  setErrorMsg('Something went wrong. Please try again later')
                  setLoading(false)
                }
              }
            })
            .catch((err) => {
              console.log(err)
              setErrorMsg('Something went wrong. Please try again later')
              setLoading(false)
            })
        } else {
          if (createdCount > 0) {
            handleSuccess()
          } else {
            setErrorMsg('Something went wrong. Please try again later')
            setLoading(false)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        setErrorMsg('Something went wrong. Please try again later')
        setLoading(false)
      })
  }

  const handleSuccess = async () => {
    let status = await props.action()
    await props.getUserFullName()
    if (status) {
      setSuccessMsg('Account created successfully')
      setLoading(false)
      setErrorMsg('')
      setStatus(true)
    }
  }

  const getDwBody = () => {
    let dwBody = {}
    dwBody.firstName = dwApexData.firstName
    dwBody.lastName = dwApexData.lastName
    dwBody.birthdayDay = dwApexData.birthdayDay
    dwBody.birthdayMonth = dwApexData.birthdayMonth
    dwBody.birthdayYear = dwApexData.birthdayYear
    dwBody.phoneNumber = dwApexData.phoneNumber?.replace(/-/g, '')
    dwBody.country = dwApexData.country
    dwBody.taxNo = dwApexData.taxNo
    dwBody.street = dwApexData.address1
    dwBody.city = dwApexData.city
    dwBody.province = dwApexData.province
    dwBody.postalCode = dwApexData.postalCode
    dwBody.employmentStatus = dwApexData.employmentStatus
    dwBody.employmentCompany = dwApexData.employmentCompany
    dwBody.employmentType = dwApexData.employmentType
    dwBody.employmentPosition = dwApexData.employmentPosition
    dwBody.investmentObjectives = dwApexData.investmentObjectives
    dwBody.annualIncome = dwApexData.annualIncome
    dwBody.netWorth = dwApexData.netWorth
    dwBody.contactEmail = dwApexData.contactEmail
    dwBody.contactFamilyName = dwApexData.contactFamilyName
    dwBody.contactGivenName = dwApexData.contactGivenName
    dwBody.contactPhoneNumber = dwApexData.contactPhoneNumber?.replace(/-/g, '')
    dwBody.taxIdType =
      dwApexData.usTaxPayer || dwApexData.country === 'USA' ? 'SSN' : 'TIN'
    dwBody.countryCitizenship = dwApexData.countryCitizenship
    dwBody.usTaxPayer = dwApexData.usTaxPayer || dwApexData.country === 'USA'
    dwBody.isBroker = dwApexData.isAffiliatedExchangeOrFINRA.status === 'YES'
    dwBody.DirectorOf =
      dwApexData.isControlPerson.status === 'YES'
        ? dwApexData.isControlPerson.companySymbols
        : null
    return dwBody
  }

  const getApexBody = () => {
    let apexBody = {}
    apexBody.fname = dwApexData.firstName
    apexBody.lname = dwApexData.lastName
    apexBody.birthdayDay = dwApexData.birthdayDay
    apexBody.birthdayMonth = dwApexData.birthdayMonth
    apexBody.birthdayYear = dwApexData.birthdayYear
    // apexBody.date_of_birth = "1988-04-06";
    apexBody.phone_number = dwApexData.phoneNumber.replaceAll('-', '')
    apexBody.country = dwApexData.country
    apexBody.address1 = dwApexData.address1
    apexBody.address2 = dwApexData.address2
    apexBody.city = dwApexData.city
    apexBody.cip_id_type = 'SSN'
    apexBody.cip_id = dwApexData.taxNo.replaceAll('-', '')
    apexBody.cip_id_country = 'USA'
    apexBody.province = dwApexData.province
    apexBody.zip_code = dwApexData.postalCode
    apexBody.employmentStatus = dwApexData.employmentStatus
    apexBody.employer = dwApexData.employmentCompany
    apexBody.employmentType = dwApexData.employmentType
    apexBody.positionEmployed = dwApexData.employmentPosition
    apexBody.investmentObjective = dwApexData.investmentObjective
    apexBody.annualIncome = dwApexData.annualIncome
    apexBody.netWorth = dwApexData.netWorth
    apexBody.taxIdType =
      dwApexData.usTaxPayer || dwApexData.country === 'USA' ? 'SSN' : 'TIN'
    apexBody.usTaxPayer = dwApexData.usTaxPayer || dwApexData.country === 'USA'
    apexBody.isAffiliatedExchangeOrFINRA =
      dwApexData.isAffiliatedExchangeOrFINRA
    apexBody.isControlPerson = dwApexData.isControlPerson
    apexBody.isPoliticallyExposed = dwApexData.isPoliticallyExposed
    apexBody.contactEmail = dwApexData.contactEmail
    apexBody.nationality = dwApexData.countryCitizenship
    apexBody.contactFamilyName = dwApexData.contactFamilyName
    apexBody.contactGivenName = dwApexData.contactGivenName
    apexBody.contactPhoneNumber = dwApexData.contactPhoneNumber.replaceAll(
      '-',
      ''
    )
    // apexBody.isBroker = dwApexData.isAffiliatedExchangeOrFINRA.status === "YES";
    // apexBody.DirectorOf =
    //   dwApexData.isControlPerson.status === "YES"
    //     ? dwApexData.isControlPerson.companySymbols
    //     : null;
    apexBody.crypto = true
    return apexBody
  }

  return (
    <div className='dw-apex-questionnaire'>
      {loading ? (
        <div className='loading-container'>
          <Loader logoLoader={false} />
        </div>
      ) : (
        <div className='dw-apex-columns-container'>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Name</p>
            <div className='input-row'>
              <div className='half-input'>
                <InputText
                  value={dwApexData?.firstName}
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  name='firstName'
                  placeholder='First Name'
                />
              </div>
              <div className='half-input'>
                <InputText
                  value={dwApexData?.lastName}
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  placeholder='Last Name'
                  name='lastName'
                />
              </div>
            </div>
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>When is your Birthday?</p>
            <Calendar
              value={birthday}
              onChange={(e) => parseBirthday(e.target.value)}
              dateFormat='mm/dd/yy'
              name='birthDay'
              showIcon
              icon='pi pi-calendar'
              monthNavigator={true}
              yearNavigator={true}
              placeholder='Birthday'
              yearRange={`1900:${new Date().getFullYear()}`}
            ></Calendar>
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Phone Number</p>
            <InputMask
              mask='999-999-9999'
              value={dwApexData.phoneNumber}
              name='phoneNumber'
              placeholder='Phone'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Country of Residence</p>
            <Dropdown
              value={dwApexData.country}
              name='country'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Country'
              options={countries}
            />
          </div>
          {dwApexData?.country === 'USA' ? (
            <>
              {' '}
              <div className='dw-apex-question-container'>
                <p className='dw-apex-header'>Social Security Number</p>
                <InputMask
                  mask='999-99-9999'
                  value={dwApexData?.taxNo}
                  name='taxNo'
                  placeholder='Social Security Number'
                  onChange={(e) => {
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className='dw-apex-question-container'>
                <p className='dw-apex-header'>Tax ID</p>
                <p className='dw-apex-subheader'>
                  Do you pay taxes in United States of America?
                </p>
                <div className='radio-input-row'>
                  <div className='radio-input-container'>
                    <RadioButton
                      value={true}
                      name='usTaxPayer'
                      onChange={(e) =>
                        handleDwApexInputChanges(e.target.name, e.target.value)
                      }
                      checked={dwApexData?.usTaxPayer}
                    />
                    <p className='radio-input-label'>Yes</p>
                  </div>
                  <div className='radio-input-container'>
                    <RadioButton
                      value={false}
                      name='usTaxPayer'
                      onChange={(e) =>
                        handleDwApexInputChanges(e.target.name, e.target.value)
                      }
                      checked={!dwApexData?.usTaxPayer}
                    />
                    <p className='radio-input-label'>No</p>
                  </div>
                </div>
                {dwApexData?.usTaxPayer ? (
                  <>
                    {' '}
                    <InputMask
                      mask='999-99-9999'
                      value={dwApexData?.taxNo}
                      name='taxNo'
                      placeholder='Social Security Number'
                      onChange={(e) => {
                        handleDwApexInputChanges(e.target.name, e.target.value)
                      }}
                    />
                  </>
                ) : (
                  <>
                    {' '}
                    <InputText
                      value={dwApexData?.taxNo}
                      onChange={(e) =>
                        handleDwApexInputChanges(e.target.name, e.target.value)
                      }
                      name='taxNo'
                      placeholder='Tax Number'
                    />
                  </>
                )}
              </div>
            </>
          )}
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Citizenship</p>
            <Dropdown
              value={dwApexData.countryCitizenship}
              name='countryCitizenship'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Citizenship'
              options={countries_all}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Address</p>
            <div className='input-row spaced-container'>
              {' '}
              <div className='half-input'>
                {/* <InputText
                value={dwApexData?.postalCode}
                onChange={(e) =>
                  handleDwApexInputChanges(e.target.name, e.target.value)
                }
                name="postalCode"
                placeholder="Zip"
              /> */}

                {dwApexData.country === 'USA' ? (
                  <InputMask
                    mask='99999'
                    value={dwApexData?.postalCode}
                    name='postalCode'
                    placeholder='Zip'
                    onChange={(e) =>
                      handleDwApexInputChanges(e.target.name, e.target.value)
                    }
                  />
                ) : (
                  <InputMask
                    mask='*****'
                    value={dwApexData?.postalCode}
                    name='postalCode'
                    placeholder='Zip'
                    onChange={(e) =>
                      handleDwApexInputChanges(e.target.name, e.target.value)
                    }
                  />
                )}
              </div>
              <div className='half-input'>
                <InputText
                  value={dwApexData?.city}
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  placeholder='City'
                  name='city'
                />
              </div>
            </div>
            <div className='spaced-container'>
              {' '}
              <InputText
                value={dwApexData?.address1}
                onChange={(e) =>
                  handleDwApexInputChanges(e.target.name, e.target.value)
                }
                name='address1'
                placeholder='Address 1'
              />
            </div>
            <div className='spaced-container'>
              {' '}
              <InputText
                value={dwApexData?.address2}
                onChange={(e) =>
                  handleDwApexInputChanges(e.target.name, e.target.value)
                }
                name='address2'
                placeholder='Address 2'
              />
            </div>
            <div className='spaced-container'>
              {' '}
              {dwApexData?.country === 'USA' ? (
                <Dropdown
                  value={dwApexData?.province}
                  name='province'
                  onChange={(e) => {
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }}
                  placeholder='State'
                  options={states}
                />
              ) : (
                <InputText
                  value={dwApexData?.province}
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  name='province'
                  placeholder='Province'
                />
              )}
            </div>
          </div>
          <div className='dw-apex-question-container'>
            {' '}
            <div className='disclosure'>
              <p className='disclosure-header'>Disclosure</p>
              <p className='disclosure-info'>
                Is this account maintained for a current or former Politically
                Exposed Person or Public Official(includes U.S. and Foreign)? A
                politically exposed person is someone who has been entrusted
                with a prominent public function, or who is closely related to
                such a person.
              </p>
              <div className='radio-input-row'>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'YES'}
                    name='status'
                    onChange={(e) =>
                      handlePoliticallyExposedChanges(
                        e.target.name,
                        e.target.value
                      )
                    }
                    checked={dwApexData?.isPoliticallyExposed?.status === 'YES'}
                  />
                  <p className='radio-input-label'>Yes</p>
                </div>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'NO'}
                    name='status'
                    onChange={(e) =>
                      handlePoliticallyExposedChanges(
                        e.target.name,
                        e.target.value
                      )
                    }
                    checked={dwApexData?.isPoliticallyExposed?.status === 'NO'}
                  />
                  <p className='radio-input-label'>No</p>
                </div>
              </div>
              {dwApexData?.isPoliticallyExposed?.status === 'YES' ? (
                <>
                  {' '}
                  <p className='disclosure-info'>
                    Please enter the name of the Political Organization
                  </p>
                  <div className='spaced-container'>
                    <InputText
                      value={
                        dwApexData?.isPoliticallyExposed?.politicalOrganization
                      }
                      onChange={(e) =>
                        handlePoliticallyExposedChanges(
                          e.target.name,
                          e.target.value
                        )
                      }
                      name='politicalOrganization'
                      placeholder='Political Organization'
                    />
                  </div>
                  <p className='disclosure-info'>
                    Please enter the name of your immediate family members,
                    including former spouses, separated by commas
                  </p>
                  <div className='spaced-container'>
                    <InputText
                      value={dwApexData?.isPoliticallyExposed?.immediateFamily}
                      onChange={(e) =>
                        handlePoliticallyExposedChanges(
                          e.target.name,
                          e.target.value
                        )
                      }
                      name='immediateFamily'
                      placeholder='Immediate Family'
                    />
                  </div>
                </>
              ) : null}
              <p className='disclosure-info'>
                Are you affiliated with or employed by a stock exchange, member
                firm of an exchange or FINRA, or a municipal securities
                broker-dealer?
              </p>
              <div className='radio-input-row'>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'YES'}
                    onChange={(e) =>
                      handleisAffiliatedExchangeOrFINRAChanges(
                        e.target.name,
                        e.target.value
                      )
                    }
                    checked={
                      dwApexData?.isAffiliatedExchangeOrFINRA?.status === 'YES'
                    }
                    name='status'
                  />
                  <p className='radio-input-label'>Yes</p>
                </div>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'NO'}
                    onChange={(e) =>
                      handleisAffiliatedExchangeOrFINRAChanges(
                        e.target.name,
                        e.target.value
                      )
                    }
                    checked={
                      dwApexData?.isAffiliatedExchangeOrFINRA?.status === 'NO'
                    }
                    name='status'
                  />
                  <p className='radio-input-label'>No</p>
                </div>
              </div>
              {dwApexData?.isAffiliatedExchangeOrFINRA?.status === 'YES' ? (
                <>
                  <p className='disclosure-info'>Upload Documents</p>
                  <div className='spaced-container'>
                    <FileUpload
                      name='file'
                      customUpload={true}
                      uploadHandler={uploadFile}
                      multiple={false}
                      mode='advanced'
                    />
                  </div>
                  {dwApexData?.isAffiliatedExchangeOrFINRA
                    ?.affiliatedApproval ? (
                    <p className='upload-success'>
                      Document uploaded successfully
                    </p>
                  ) : (
                    <p className='upload-error'>Please upload document.</p>
                  )}
                  <div className='spaced-container'>
                    <InputText
                      value={dwApexData?.isAffiliatedExchangeOrFINRA?.firmName}
                      onChange={(e) =>
                        handleisAffiliatedExchangeOrFINRAChanges(
                          e.target.name,
                          e.target.value
                        )
                      }
                      name='firmName'
                      placeholder='Firm Name'
                    />
                  </div>
                  <p className='disclosure-info'>Firm Name</p>
                </>
              ) : null}
              <p className='disclosure-info'>
                Is the account holder(s) a control person of a publicly traded
                company? A Director, Officer or 10% stock owner?
              </p>
              <div className='radio-input-row'>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'YES'}
                    onChange={(e) =>
                      handleControlPersonChanges(e.target.name, e.target.value)
                    }
                    checked={dwApexData?.isControlPerson?.status === 'YES'}
                    name='status'
                  />
                  <p className='radio-input-label'>Yes</p>
                </div>
                <div className='radio-input-container'>
                  <RadioButton
                    value={'NO'}
                    onChange={(e) =>
                      handleControlPersonChanges(e.target.name, e.target.value)
                    }
                    checked={dwApexData?.isControlPerson?.status === 'NO'}
                    name='status'
                  />
                  <p className='radio-input-label'>No</p>
                </div>
              </div>
              {dwApexData?.isControlPerson?.status === 'YES' ? (
                <>
                  <p className='disclosure-info'>
                    Please list the ticker symbols, separated by commas
                  </p>
                  <InputText
                    value={dwApexData?.isControlPerson?.companySymbols}
                    onChange={(e) =>
                      handleControlPersonChanges(e.target.name, e.target.value)
                    }
                    name='companySymbols'
                    placeholder='Company Symbols'
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Employment Status</p>
            <Dropdown
              value={dwApexData.employmentStatus}
              name='employmentStatus'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Employment Status'
              options={employmentStatusOptions}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Employment Type</p>
            <Dropdown
              value={dwApexData.employmentType}
              name='employmentType'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Employment Type'
              options={employmentTypeOptions}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Employment Position</p>
            <Dropdown
              value={dwApexData.employmentPosition}
              name='employmentPosition'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Employment Position'
              options={employmentPositionOptions}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Company Name</p>
            <InputText
              value={dwApexData?.employmentCompany}
              onChange={(e) =>
                handleDwApexInputChanges(e.target.name, e.target.value)
              }
              name='employmentCompany'
              placeholder='Company Name'
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Annual Income (in $)</p>
            <InputNumber
              value={dwApexData?.annualIncome}
              name='annualIncome'
              onValueChange={(e) =>
                handleDwApexInputChanges(e.target.name, e.target.value)
              }
              mode='currency'
              currency='USD'
              minFractionDigits={0}
              maxFractionDigits={2}
              placeholder='Annual Income'
              locale='en-US'
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Net Worth (in $)</p>
            <InputNumber
              value={dwApexData?.netWorth}
              name='netWorth'
              onValueChange={(e) =>
                handleDwApexInputChanges(e.target.name, e.target.value)
              }
              mode='currency'
              currency='USD'
              placeholder='Net Worth'
              maxFractionDigits={2}
              locale='en-US'
              minFractionDigits={0}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Investment Objective</p>
            <Dropdown
              value={dwApexData.investmentObjectives}
              name='investmentObjectives'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Investment Objective'
              options={investmentObjectivesOptions}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Investment Goal</p>
            <Dropdown
              value={dwApexData.investmentObjective}
              name='investmentObjective'
              onChange={(e) => {
                handleDwApexInputChanges(e.target.name, e.target.value)
              }}
              placeholder='Investment Goal'
              options={investmentGoalOptions}
            />
          </div>
          <div className='dw-apex-question-container'>
            <p className='dw-apex-header'>Trusted Contact</p>
            <p className='dw-apex-subheader'>
              Enter the name and at least one contact information of your
              trusted contact.
            </p>
            <div className='input-row spaced-container'>
              <div className='half-input'>
                {' '}
                <InputText
                  value={dwApexData?.contactGivenName}
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  name='contactGivenName'
                  placeholder='Given Name'
                />
              </div>
              <div className='half-input'>
                <InputText
                  value={dwApexData?.contactFamilyName}
                  name='contactFamilyName'
                  onChange={(e) =>
                    handleDwApexInputChanges(e.target.name, e.target.value)
                  }
                  placeholder='Family Name'
                />
              </div>
            </div>
            <div className='spaced-container'>
              {' '}
              <InputText
                value={dwApexData?.contactEmail}
                name='contactEmail'
                onChange={(e) =>
                  handleDwApexInputChanges(e.target.name, e.target.value)
                }
                placeholder='Trusted Email'
              />
            </div>
            <div className='spaced-container'>
              {' '}
              <InputMask
                mask='999-999-9999'
                value={dwApexData?.contactPhoneNumber}
                name='contactPhoneNumber'
                placeholder='Trusted Phone'
                onChange={(e) => {
                  handleDwApexInputChanges(e.target.name, e.target.value)
                }}
              />
            </div>
          </div>
          {apexAccount && (
            <div className='dw-apex-question-container'>
              <div className='agreement-row'>
                {/* <Checkbox onChange={(e)=>{console.log(e)}} checked={agreement}></Checkbox> */}
                <Checkbox
                  onChange={(e) => {
                    // readAgreement
                    //   ? setAgreement(!agreement)
                    //   : setErrorMsg('Please open and read APEX User Agreement')
                    setAgreement(!agreement)
                  }}
                  checked={agreement}
                ></Checkbox>
                <p className='agreement-label'>
                  I Agree to the{' '}
                  <span
                    onClick={() => {
                      setShowAgreement(true)
                      setReadAgreement(true)
                    }}
                  >
                    APEX Account Agreement
                  </span>
                </p>
              </div>
            </div>
          )}
          {/* <div className="dw-apex-question-container">
          {" "}
          <div className="agreement-row">
            <Checkbox
              onChange={(e) => {
                readAgreement2
                  ? setAgreement2(e.checked)
                  : setErrorMsg("Please open and read APEX Account Agreement");
              }}
              checked={agreement2}
            ></Checkbox>
            <p className="agreement-label">
              I Agree to the{" "}
              <span
                onClick={() => {
                  setShowAgreement2(true);
                  setReadAgreement2(true);
                }}
              >
                APEX Account Agreement
              </span>
            </p>
          </div>
        </div> */}
          {dwAccount && (
            <div className='dw-apex-question-container'>
              <div className='agreement-row'>
                <Checkbox
                  onChange={(e) => {
                    // readAgreement3
                    //   ? setAgreement3(!agreement3)
                    //   : setErrorMsg(
                    //       'Please open and read DriveWealth Account Agreement'
                    //     )
                    setAgreement3(!agreement3)
                  }}
                  checked={agreement3}
                ></Checkbox>
                <p className='agreement-label'>
                  I Agree to the{' '}
                  <span
                    onClick={() => {
                      setShowAgreement3(true)
                      setReadAgreement3(true)
                    }}
                  >
                    DriveWealth Account Agreement
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className='dw-apex-question-container'>
            {' '}
            <div className='agreement-row'>
              <Checkbox
                onChange={(e) => {
                  // readAgreement4
                  //   ? setAgreement4(!agreement4)
                  //   : setErrorMsg(
                  //       'Please open and read ForeSCITE Advisory Agreement'
                  //     )
                  setAgreement4(!agreement4)
                }}
                checked={agreement4}
              ></Checkbox>
              <p className='agreement-label'>
                I Agree to the{' '}
                <span
                  onClick={() => {
                    setShowAgreement4(true)
                    setReadAgreement4(true)
                  }}
                >
                  ForeSCITE Advisory Agreement
                </span>
              </p>
            </div>
          </div>
          <p className='error-msg'>{errorMsg}</p>
          <p className='success-msg'>{successMsg}</p>
          <div className='dw-apex-submit-btn-container'>
            <Button
              label='Submit'
              className='dw-apex-submit-btn'
              onClick={() => {
                validateForm()
              }}
              loading={loading}
            />
          </div>
        </div>
      )}
      <Sidebar
        visible={showAgreement}
        width={'100vw'}
        fullScreen={true}
        onHide={() => setShowAgreement(false)}
        className='disclosures-sidebar'
      >
        <ApexDisclosures />
      </Sidebar>
      {/* <Sidebar
        visible={showAgreement2}
        width={"100vw"}
        fullScreen={true}
        onHide={() => setShowAgreement2(false)}
      >
        Agreement 2
      </Sidebar> */}
      <Sidebar
        visible={showAgreement3}
        width={'100vw'}
        fullScreen={true}
        onHide={() => setShowAgreement3(false)}
        className='disclosures-sidebar'
      >
        <DWDisclosures />
      </Sidebar>
      <Sidebar
        visible={showAgreement4}
        width={'100vw'}
        fullScreen={true}
        onHide={() => setShowAgreement4(false)}
        className='disclosures-sidebar'
      >
        <ForesciteDisclosures />
      </Sidebar>
    </div>
  )
}
