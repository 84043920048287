import React, { useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import Loader from './components/accountInfo/Loader'
import '../scss/components/WatchlistSelection.scss'
import { addToWatchlist, removeFromWatchlist } from '../services/userServices'
import { Dialog } from 'primereact/dialog'
import CreateWatchlist from './components/CreateWatchlist'

export default function WatchlistSelection({
  symbol,
  names,
  myWatchlistGroup,
  isSmallScreen,
  user,
  refreshWl,
  t,
  type, //add or remove
}) {
  const [watchlists, setWatchlists] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [createWatchlistModal, setCreateWatchlistModal] = useState(false)

  useEffect(() => {
    filterWatchlists()
  }, [])
  useEffect(() => {}, [watchlists])

  const filterWatchlists = () => {
    Object.keys(myWatchlistGroup).forEach((key) => {
      myWatchlistGroup[key].forEach((item) => {
        if (item.symbol === symbol) {
          setWatchlists((prevState) => [...prevState, key])
        }
      })
    })
  }

  const callAddToWatchlist = async () => {
    setLoading(true)
    const res = await addToWatchlist(
      watchlists,
      [symbol],
      user.idToken.jwtToken
    )
    if (res?.status) {
      await refreshWl()
      setSuccessMessage(t('addedsuccessfully'))
    } else {
      setErrorMessage(t('somethingwentwrong'))
    }

    setLoading(false)
  }

  const callDeleteFromWatchlist = async () => {
    setLoading(true)
    const res = await removeFromWatchlist(
      watchlists,
      [symbol],
      user.idToken.jwtToken
    )
    if (res?.status) {
      await refreshWl()
      setSuccessMessage(t('removedsuccessfully'))
    } else {
      setErrorMessage(t('somethingwentwrong'))
    }

    setLoading(false)
  }

  if (loading) {
    return <Loader isSmallScreen={isSmallScreen} logoLoader={false} />
  } else {
    return (
      <div className='watchlist-selection'>
        {names.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                width: '100%',
                padding: 15,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ margin: 0, fontSize: 15 }}>{item}</p>
              <Checkbox
                onChange={(e) => {
                  if (e.checked) {
                    setWatchlists((prevState) =>
                      prevState.filter((val) => val !== item)
                    )
                    setWatchlists((prevState) => [...prevState, item])
                  } else {
                    setWatchlists((prevState) =>
                      prevState.filter((val) => val !== item)
                    )
                  }
                }}
                checked={watchlists.includes(item)}
                value={item}
              ></Checkbox>
            </div>
          )
        })}
        <p
          style={{ margin: '15px 0 0 0', cursor: 'pointer' }}
          onClick={() => {
            setCreateWatchlistModal(true)
          }}
        >
          + {t('createnewwatchlist')}
        </p>
        <button
          onClick={() => {
            if (watchlists.length === 0) {
              setErrorMessage(t('pleaseselectatleastonegroup'))
            } else {
              if (type === 'add') {
                callAddToWatchlist()
              } else if (type === 'remove') {
                callDeleteFromWatchlist()
              }
            }
          }}
          className='selection-button'
        >
          {t('submit')}
        </button>
        {successMessage && <p className='success'>{successMessage}</p>}
        {errorMessage && <p className='error'>{errorMessage}</p>}

        <Dialog
          visible={createWatchlistModal}
          header={t('createwatchlist')}
          onHide={() => setCreateWatchlistModal(false)}
          style={{
            width: isSmallScreen ? '90vw' : '30vw',
            height: isSmallScreen ? '90vh' : '50vh',
          }}
        >
          <CreateWatchlist
            isSmallScreen={isSmallScreen}
            names={names}
            refreshWl={refreshWl}
            user={user}
            t={t}
          />
        </Dialog>
      </div>
    )
  }
}
