//https://api.allocaterite.com/search/ticker-bulk-live?search=AAPL

import Axios from 'axios'
import Endpoint from '../components/Endpoint'
import SearchEndpoint from '../components/SearchEndpoint'

//GET USER DRIVE WEALTH INFORMATION FOR DEPOSIT
export const getSingleStockData = async (ticker) => {
  try {
    const response = await Axios.get(
      `${SearchEndpoint}/ticker-bulk-live?search=${ticker}`
    )
    return response?.data
  } catch (err) {
    console.log(err)
  }
}

export const getSingleStockData2 = async (ticker) => {
  try {
    const res = await Axios.get(
      `https://api.allocaterite.com/search/ticker-details?ticker=${ticker}`
    )
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getForecastChartData = async (symbol, jwtToken) => {
  try {
    const url = `${Endpoint}/mobile/forecast/stock?stock=${symbol}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}

export const getPrices = async (jwtToken, symbol) => {
  try {
    const url = `${Endpoint}/search/ticker-price?search=${symbol}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
      },
    }
    const res = await Axios.get(url, header)
    return res?.data?.[0]
  } catch (error) {
    console.log(error)
  }
}
