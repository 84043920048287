//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//IMAGE
import AssetsIncomeImg from '../../../assets/assets-income.png'

export default function AssetsIncome() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='center-container'>
      <div className='center-block'>
        <img src={AssetsIncomeImg} className='assets-income-img' />
        <div className='product-page-row space-top'>
          <p className='medium-text item grey-color'>
            {t('my_assets_and_income_managed_regularly')}
          </p>
          <p className='tiny-text item dark-grey-color'>
            {t('check_the_current_msg')}
          </p>
        </div>
      </div>
    </div>
  )
}
