import React, { Component } from 'react'
import '../../../scss/components/TransferFundsDW.scss'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { TabView, TabPanel } from 'primereact/tabview'
import ENDPOINT from '../../Endpoint'
import axios from 'axios'
import WireDepositDialog from '../WireDepositDialog'
import { ConfirmDialog } from 'primereact/confirmdialog'
import RelinkPlaid from './RelinkPlaid'
import {
  FaEye,
  FaEyeSlash,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPlusCircle,
} from 'react-icons/fa'
import WireWithdrawDialog from '../WireWithdrawDialog'
import { PlaidLink } from 'react-plaid-link'
import { Dialog } from 'primereact/dialog'
import LinkPlaidBtn from '../LinkPlaidBtn'
import SearchEndpoint from '../../SearchEndpoint'
import ConfirmationDialog from '../ConfirmationDialog'
import Loader from './Loader'
import WhiteArrow from '../../../assets/white-arrow.svg'
import BackIcon from '../../../assets/product-activation-back-icon.svg'

export default class TransferFundsDW extends Component {
  state = {
    activeTab: 0,
    selectedFromAccount: null,
    selectedToAccount: null,
    productAccounts: [],
    inactiveProductAccounts: [],
    bankingAccounts: [],
    amountValue: 0,
    noOptions: [{ label: 'No available options', value: null }],
    isLoading: false,
    status: null,
    formErrorMessage: '',
    canTrade: null,
    showWireDepositDialog: false,
    showWireWithdrawDialog: false,
    showRelinkDialog: false,
    selectedRelinkAcc: null,
    showConfirmationDialog: false,
    depositOptions: 0,
    confirmMessage: '',
    withdrawOptions: 0,
  }

  componentDidMount = () => {
    this.filterAccounts()
    this.getMarketStatus()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.accounts !== this.props.accounts) {
      this.filterAccounts()
    }
  }

  toggleWireDepositDialog = () => {
    this.setState({ showWireDepositDialog: !this.state.showWireDepositDialog })
  }

  toggleWireWithdrawDialog = () => {
    this.setState({
      showWireWithdrawDialog: !this.state.showWireWithdrawDialog,
    })
  }

  toggleRelinkDialog = () => {
    this.setState({ showRelinkDialog: !this.state.showRelinkDialog })
  }

  getMarketStatus = () => {
    // console.log('inside can trade')
    fetch(`${SearchEndpoint}/istradinghour`, {
      method: 'GET',
    })
      .catch((err) => {
        console.log(err)
      })
      .then((res) => res?.json())
      .then((responseJson) => {
        // console.log('can trade resp: ', responseJson)
        this.setState({ canTrade: responseJson })
      })
  }

  deposit = () => {
    this.setState({ isLoading: true })
    let body = {
      dwAccountID: this.state.selectedToAccount.accountId,
      plaidAccountID: this.state.selectedFromAccount.accountId,
      sourceType: 'plaid',
      amount: this.state.amountValue,
    }
    return fetch(`${ENDPOINT}/dw/deposits/onetime?sandbox=${false}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(async (resp) => {
        if (resp.success) {
          await this.props.getAllAccountInfo()
          // this.props.getAccounts(this.props.user.idToken.jwtToken)
          this.setState({ isLoading: false, status: true })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return true
        } else {
          this.setState({ isLoading: false, status: false })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return false
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, status: false })
        setTimeout(() => {
          this.setState({ status: null })
        }, 5000)
        return false
      })
  }

  transfer = async () => {
    this.setState({ isLoading: true })
    return await axios
      .post(
        `${ENDPOINT}/mobile/internal/transfer`,
        {
          accountFrom: this.state.selectedFromAccount.accountId,
          accountTo: this.state.selectedToAccount.accountId,
          amount: this.state.amountValue,
          batch: true,
          comment: 'Transfer',
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.status === 'Success') {
          await this.props.getAllAccountInfo()
          this.setState({ isLoading: false, status: true })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return true
        } else {
          this.setState({ isLoading: false, status: false })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return false
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setState({ isLoading: false, status: false })
        setTimeout(() => {
          this.setState({ status: null })
        }, 5000)
        return false
      })
  }

  withdraw = async () => {
    let body = {
      plaidAccountID: this.state.selectedToAccount.accountId,
      dwAccountID: this.state.selectedFromAccount.accountId,
      amount: this.state.amountValue,
      note: 'Transfer',
    }
    this.setState({ isLoading: true })
    return await axios
      .post(
        `${ENDPOINT}/mobile/withdraw-plaid`,
        {
          plaidAccountID: this.state.selectedToAccount.accountId,
          dwAccountID: this.state.selectedFromAccount.accountId,
          amount: this.state.amountValue,
          note: 'Transfer',
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        if (response?.data?.Withdraw?.batch) {
          await this.props.getAllAccountInfo()
          this.setState({ isLoading: false, status: true })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return true
        } else {
          this.setState({ isLoading: false, status: false })
          setTimeout(() => {
            this.setState({ status: null })
          }, 5000)
          return false
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setState({ isLoading: false, status: false })
        setTimeout(() => {
          this.setState({ status: null })
        }, 5000)
        return false
      })
  }

  filterAccounts = () => {
    let productAccounts = []
    let bankingAccounts = []
    let inactiveProductAccounts = []

    this.props.accounts.forEach((account) => {
      if (account.inst && account.inst === 'DriveWealth') {
        if (!account.product && account.product_assigned !== 'empty') {
          inactiveProductAccounts.push({
            label: `${this.getProductName(account.product_assigned)} ACCOUNT`,
            value: account,
          })
        }
        if (account.product_assigned !== 'empty')
          productAccounts.push({
            label: `${this.getProductName(account.product_assigned)} ACCOUNT`,
            value: account,
          })
      } else if (account.type && account.type === 'depository') {
        bankingAccounts.push({
          label: `${account.inst} ${account.mask}`,
          value: account,
        })
      }
    })
    this.setState({
      productAccounts: productAccounts,
      inactiveProductAccounts: inactiveProductAccounts,
      bankingAccounts: bankingAccounts,
      selectedFromAccount: null,
      selectedToAccount: null,
    })
  }

  getProductName = (product_assigned) => {
    switch (product_assigned) {
      case 'equity':
        return 'STOCKS'
      case 'goal':
        return 'GOAL'
      case 'group':
        return 'AI STOCK TRADER'
      case 'robo':
        return 'ROBO'
      default:
        return ''
    }
  }

  handleFromAccountChange = (value) => {
    if (this.state.activeTab === 0 && value.reconnect_plaid === 1) {
      this.setState({ showRelinkDialog: true, selectedRelinkAcc: value })
    } else {
      this.setState({
        selectedFromAccount: value,
      })
    }
  }

  handleToAccountChange = (value) => {
    this.setState({
      selectedToAccount: value,
    })
  }

  handleAmountChange = (value) => {
    this.setState({ amountValue: value })
  }

  tabs() {
    let selectedTab = {
      cursor: 'pointer',
      background: '#3F46F6',
      color: 'white',
      alignItems: 'center',
      marginRight: '15px',
      borderRadius: '15px',
      fontWeight: 'bold',
    }
    let unSelectedTab = {
      cursor: 'pointer',
      background: 'transparent',
      color: 'grey',
      marginRight: '15px',
      borderRadius: '15px',
      fontWeight: 'bold',
    }
    return (
      <div className='tabs-container'>
        <Button
          label='Add Cash'
          onClick={() => this.setState({ activeTab: 'Add Cash' })}
          style={
            this.state.activeTab === 'Add Cash' ? selectedTab : unSelectedTab
          }
        />
        {this.props.userType === 'normal' ? (
          <Button
            label='Transfer'
            onClick={() => this.setState({ activeTab: 'Transfer' })}
            style={
              this.state.activeTab === 'Transfer' ? selectedTab : unSelectedTab
            }
          />
        ) : null}
        <Button
          label='Cash Out'
          onClick={() => this.setState({ activeTab: 'Cash Out' })}
          style={
            this.state.activeTab === 'Cash Out' ? selectedTab : unSelectedTab
          }
        />
      </div>
    )
  }

  fromAccountForm(accounts, showBalance) {
    return (
      <div className='from-account-form-container'>
        <p className='label'>{this.props.t('from')}:</p>
        <Dropdown
          value={
            this.state.selectedFromAccount?.length === 0
              ? this.state.noOptions
              : this.state.selectedFromAccount
          }
          options={accounts}
          onChange={(e) => this.handleFromAccountChange(e.value)}
          placeholder={this.props.t('selectanaccount')}
        />
        {showBalance && this.state.selectedFromAccount ? (
          <p className='balance'>
            {this.props.t('available')}:{' '}
            <span className='dollar-amount'>
              {this.props.formatCurrency(this.displayFromBalance())}
            </span>
          </p>
        ) : null}
        {this.state.activeTab === 0 ? (
          <div className='link-plaid-btn'>
            <LinkPlaidBtn
              user={this.props.user}
              getAllAccountInfo={this.props.getAllAccountInfo}
              isBank={true}
              showButton={true}
              reLink={false}
              t={this.props.t}
            />
          </div>
        ) : null}
      </div>
    )
  }

  toAccountForm(accounts, showBalance) {
    return (
      <div className='to-account-form-container'>
        <p className='label'>{this.props.t('to')}:</p>
        <Dropdown
          value={
            this.state.selectedToAccount?.length === 0
              ? this.state.noOptions
              : this.state.selectedToAccount
          }
          options={accounts}
          onChange={(e) => this.handleToAccountChange(e.value)}
          placeholder={this.props.t('selectanaccount')}
        />
        {showBalance && this.state.selectedToAccount ? (
          <p className='balance'>
            {this.props.t('balance')}:{' '}
            <span className='dollar-amount'>
              {this.props.formatCurrency(this.displayToBalance())}
            </span>
          </p>
        ) : null}
        {this.state.activeTab === 2 ? (
          <div className='link-plaid-btn'>
            <LinkPlaidBtn
              user={this.props.user}
              getAllAccountInfo={this.props.getAllAccountInfo}
              isBank={true}
              showButton={true}
              reLink={false}
              t={this.props.t}
            />
          </div>
        ) : null}
      </div>
    )
  }

  displayFromBalance() {
    if (this.state.activeTab === 1) {
      return this.state.selectedFromAccount.balances[0].fundsInvestable
    } else if (this.state.activeTab === 2) {
      return this.state.selectedFromAccount.balances[0]
        .cashAvailableForWithdrawal
    } else return null
  }

  displayToBalance() {
    return this.state.selectedToAccount.balances[0].virtualAccountValue
  }

  amountForm(type) {
    return (
      <div className='amount-form-container'>
        <p className='label'>{type}</p>
        <InputNumber
          value={this.state.amountValue}
          onValueChange={(e) => this.handleAmountChange(e.value)}
          mode='currency'
          currency='USD'
          locale='en-US'
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
    )
  }

  submitButton() {
    return (
      <div className='submit-button-container'>
        {!this.state.isLoading ? (
          <Button
            label={this.props.t('submit')}
            onClick={() => {
              this.validateForm()
              // this.props.getAccounts(this.props.user.idToken.jwtToken);
            }}
          />
        ) : (
          <Button loading />
        )}
      </div>
    )
  }

  validateForm() {
    if (
      this.state.selectedFromAccount &&
      this.state.selectedToAccount &&
      this.state.amountValue
    ) {
      if (this.state.activeTab === 0) {
        if (this.state.amountValue <= 5000) {
          this.setState({
            showConfirmationDialog: true,
            confirmMessage: `Are you sure you want to make a deposit of ${this.props.formatCurrency(
              this.state.amountValue
            )}?`,
          })
          // this.submitAPI()
        } else {
          this.toggleError(this.props.t('amountcannotbegreaterthan5000'))
        }
      } else if (this.state.activeTab === 1) {
        if (this.state.selectedFromAccount !== this.state.selectedToAccount) {
          if (
            this.state.amountValue <=
            this.state.selectedFromAccount.balances[0].fundsInvestable
          ) {
            if (this.state.canTrade?.transfer) {
              this.setState({
                showConfirmationDialog: true,
                confirmMessage: `Are you sure you want to make a transfer of ${this.props.formatCurrency(
                  this.state.amountValue
                )}?`,
              })
              // this.submitAPI()
            } else {
              this.toggleError(
                this.props.t('transfersarenotavailableatthistime')
              )
            }
          } else {
            this.toggleError(this.props.t('cannottransfermorethanyouown'))
          }
        } else {
          this.toggleError('Cannot transfer between same accounts')
        }
      } else {
        if (this.state.amountValue >= 1) {
          if (
            this.state.amountValue <=
            this.state.selectedFromAccount.balances[0]
              .cashAvailableForWithdrawal
          ) {
            this.setState({
              showConfirmationDialog: true,
              confirmMessage: `Are you sure you want to make a withdrawal of ${this.props.formatCurrency(
                this.state.amountValue
              )}?`,
            })
            // this.submitAPI()
          } else {
            this.toggleError(this.props.t('cannotwithdrawmorethanyouown'))
          }
        } else {
          this.toggleError(this.props.t('amountmustbeatleast1dollar'))
        }
      }
    } else {
      this.toggleError(this.props.t('allfieldsarerequired'))
    }
  }

  toggleError(msg) {
    this.setState({ formErrorMessage: msg })
    setTimeout(() => {
      this.setState({ formErrorMessage: '' })
    }, 3000)
  }

  showFormError() {
    return <p className='failure'>{this.state.formErrorMessage}</p>
  }

  submitAPI = () => {
    switch (this.state.activeTab) {
      case 0:
        return this.deposit()
      case 1:
        return this.transfer()
      case 2:
        return this.withdraw()
      default:
        return false
    }
  }

  limitMessage() {
    return (
      <div className='message-container'>
        <p className='message'>{this.props.t('youcanonlydeposit5000perday')}</p>
      </div>
    )
  }

  timeMessage() {
    if (!this.state.canTrade?.transfer) {
      return (
        <div className='time-message-container'>
          <p className='message'>
            {this.props.t('transfersareavailabefrom430to130')}
          </p>
        </div>
      )
    } else return null
  }

  setActiveIndex = (index) => {
    this.setState({
      activeTab: index,
      selectedFromAccount: null,
      selectedToAccount: null,
      amountValue: 0,
      isLoading: false,
      status: null,
      formError: false,
      formErrorMessage: '',
    })
  }

  showMessage() {
    if (this.state.status) {
      return (
        <p className='success'>
          {this.props.t('transactionhasbeensuccessfullycompleted')}
        </p>
      )
    } else if (this.state.status === false) {
      return <p className='failure'>{this.props.t('somethingwentwrong')}</p>
    } else return null
  }

  wireDeposit = () => {
    return (
      <div className='wire-deposit-btn-container'>
        <p
          className='wire-deposit-btn'
          onClick={() => {
            this.toggleWireDepositDialog()
          }}
        >
          {this.props.t('wiredeposit')}
        </p>
      </div>
    )
  }

  wireWithdraw = () => {
    return (
      <div className='wire-withdraw-btn-container'>
        <p
          className='wire-withdraw-btn'
          onClick={() => {
            this.toggleWireWithdrawDialog()
          }}
        >
          {this.props.t('wirewithdraw')}
        </p>
      </div>
    )
  }

  linkPlaidButton() {
    return (
      <div
        style={{
          width: '100%',
          marginTop: 20,
          marginBottom: 20,
          height: 50,
          marginLeft: '2%',
          display: this.props.isSmallScreen ? '' : 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {this.props.isSmallScreen ? null : (
          <div style={{ marginRight: '10%' }}>
            <FaPlusCircle
              id='ManageAccounts_Button'
              size={75}
              color={'#3b8e8c'}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                this.setState({
                  plusDropdown: !this.state.plusDropdown,
                  errorsSucceses: false,
                })
              }
            />
            {this.plusDropdown()}
          </div>
        )}
      </div>
    )
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  render() {
    if (this.props.t) {
      return (
        <div className='transfer-funds-dw-container'>
          <div className='tabs-container'>
            {this.state.productAccounts && this.state.bankingAccounts ? (
              <TabView
                activeIndex={this.state.activeTab}
                onTabChange={(e) => this.setActiveIndex(e.index)}
              >
                <TabPanel header={this.props.t('deposit')}>
                  {this.state.depositOptions === 0 && (
                    <div className='option-buttons'>
                      <button
                        className='option-btn'
                        onClick={() => {
                          this.setState({ depositOptions: 1 })
                        }}
                      >
                        {this.props.t('achlinking')} <br />
                        {this.props.t('realtimeverification')}
                        <br />({this.props.t('usbankaccountsonly')})
                        <img src={WhiteArrow} className='white-arrow' />
                      </button>
                      <button
                        className='option-btn'
                        onClick={() => {
                          this.setState({ depositOptions: 2 })
                        }}
                      >
                        {this.props.t('wiredeposit')}
                        <img src={WhiteArrow} className='white-arrow' />
                      </button>
                    </div>
                  )}
                  {this.state.depositOptions === 1 && (
                    <div className='tab-container'>
                      <div
                        style={{
                          filter: this.state.isLoading ? 'blur(5px)' : '',
                          pointerEvents: this.state.isLoading ? 'none' : '',
                        }}
                      >
                        <img
                          src={BackIcon}
                          height={25}
                          width={25}
                          onClick={() => {
                            this.setState({ depositOptions: 0 })
                          }}
                          style={{ cursor: 'pointer' }}
                        />{' '}
                        {this.fromAccountForm(
                          this.state.bankingAccounts,
                          false
                        )}
                        {/* {this.wireDeposit()} */}
                        {this.toAccountForm(this.state.productAccounts, true)}
                        {this.amountForm(this.props.t('depositamount'))}
                        {this.showMessage()}
                        {this.showFormError()}
                        {this.submitButton()}
                        {this.limitMessage()}
                      </div>
                    </div>
                  )}
                  {this.state.depositOptions === 2 && (
                    <>
                      {' '}
                      <img
                        src={BackIcon}
                        height={25}
                        width={25}
                        onClick={() => {
                          this.setState({ depositOptions: 0 })
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <div className='wire-deposit-container'>
                        <WireDepositDialog
                          isSmallScreen={this.props.isSmallScreen}
                          accounts={this.props.accounts}
                          t={this.props.t}
                          selectedAccount={this.props.selectedAccount}
                          user={this.props.user}
                          yPadding={30}
                          xPadding={10}
                        />
                      </div>
                    </>
                  )}
                </TabPanel>
                <TabPanel header={this.props.t('transfer')}>
                  <div className='tab-container'>
                    <div
                      style={{
                        filter: this.state.isLoading ? 'blur(5px)' : '',
                        pointerEvents: this.state.isLoading ? 'none' : '',
                      }}
                    >
                      {this.timeMessage()}
                      {this.fromAccountForm(
                        this.state.inactiveProductAccounts,
                        true
                      )}
                      {this.toAccountForm(this.state.productAccounts, true)}
                      {this.amountForm(this.props.t('transferamount'))}
                      {this.showMessage()}
                      {this.showFormError()}
                      {this.submitButton()}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header={this.props.t('withdraw')}>
                  {this.state.withdrawOptions === 0 && (
                    <div className='option-buttons'>
                      <button
                        className='option-btn'
                        onClick={() => {
                          this.setState({ withdrawOptions: 1 })
                        }}
                      >
                        {this.props.t('achlinking')} <br />
                        {this.props.t('realtimeverification')}
                        <br />({this.props.t('usbankaccountsonly')})
                        <img src={WhiteArrow} className='white-arrow' />
                      </button>
                      <button
                        className='option-btn'
                        onClick={() => {
                          this.setState({ withdrawOptions: 2 })
                        }}
                      >
                        {this.props.t('wirewithdraw')}
                        <img src={WhiteArrow} className='white-arrow' />
                      </button>
                    </div>
                  )}
                  {this.state.withdrawOptions === 1 && (
                    <>
                      <img
                        src={BackIcon}
                        height={25}
                        width={25}
                        onClick={() => {
                          this.setState({ withdrawOptions: 0 })
                        }}
                        style={{ cursor: 'pointer' }}
                      />{' '}
                      <div className='tab-container'>
                        <div
                          style={{
                            filter: this.state.isLoading ? 'blur(5px)' : '',
                            pointerEvents: this.state.isLoading ? 'none' : '',
                          }}
                        >
                          {' '}
                          {this.fromAccountForm(
                            this.state.inactiveProductAccounts,
                            true
                          )}
                          {this.toAccountForm(this.state.bankingAccounts)}
                          {this.amountForm(this.props.t('withdrawalamount'))}
                          {this.showMessage()}
                          {this.showFormError()}
                          {this.submitButton()}
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.withdrawOptions === 2 && (
                    <>
                      {' '}
                      <img
                        src={BackIcon}
                        height={25}
                        width={25}
                        onClick={() => {
                          this.setState({ withdrawOptions: 0 })
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <div className='wire-withdraw-container'>
                        <WireWithdrawDialog
                          showWireWithdrawDialog={
                            this.state.showWireWithdrawDialog
                          }
                          toggleWireWithdrawDialog={
                            this.toggleWireWithdrawDialog
                          }
                          isSmallScreen={this.props.isSmallScreen}
                          accounts={this.props.accounts}
                          formatCurrency={this.props.formatCurrency}
                          t={this.props.t}
                          user={this.props.user}
                        />
                      </div>
                    </>
                  )}
                  {/* {this.props.userType === 'normal' ? (
                    <div className='tab-container'>
                      {this.state.isLoading && (
                        <div className='logo-loader'>
                          <Loader logoLoader={true} />
                        </div>
                      )}
                      <div
                        style={{
                          filter: this.state.isLoading ? 'blur(5px)' : '',
                          pointerEvents: this.state.isLoading ? 'none' : '',
                        }}
                      >
                        {' '}
                        {this.fromAccountForm(
                          this.state.inactiveProductAccounts,
                          true
                        )}
                        {this.wireWithdraw()}
                        {this.toAccountForm(this.state.bankingAccounts)}
                        {this.amountForm(this.props.t('withdrawalamount'))}
                        {this.showMessage()}
                        {this.showFormError()}
                        {this.submitButton()}
                      </div>
                    </div>
                  ) : (
                    <div className='wire-withdraw-container'>
                      <WireWithdrawDialog
                        showWireWithdrawDialog={
                          this.state.showWireWithdrawDialog
                        }
                        toggleWireWithdrawDialog={this.toggleWireWithdrawDialog}
                        isSmallScreen={this.props.isSmallScreen}
                        accounts={this.props.accounts}
                        formatCurrency={this.props.formatCurrency}
                        t={this.props.t}
                        user={this.props.user}
                      />
                    </div>
                  )} */}
                </TabPanel>
              </TabView>
            ) : null}
          </div>
          <Dialog
            id='print-dialog'
            visible={this.state.showWireDepositDialog}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
            header={
              <p className='dialog-title'>
                {this.props.t('wiredepositinstructions')}
              </p>
            }
            onHide={() => this.toggleWireDepositDialog()}
          >
            <WireDepositDialog
              isSmallScreen={this.props.isSmallScreen}
              accounts={this.props.accounts}
              t={this.props.t}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              yPadding={30}
              xPadding={10}
            />
          </Dialog>
          <Dialog
            visible={this.state.showWireWithdrawDialog}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
            header={
              <p className='dialog-title'>{this.props.t('wirewithdrawal')}</p>
            }
            onHide={() => this.toggleWireWithdrawDialog()}
          >
            <WireWithdrawDialog
              showWireWithdrawDialog={this.state.showWireWithdrawDialog}
              toggleWireWithdrawDialog={this.toggleWireWithdrawDialog}
              isSmallScreen={this.props.isSmallScreen}
              accounts={this.props.accounts}
              formatCurrency={this.props.formatCurrency}
              t={this.props.t}
              user={this.props.user}
            />
          </Dialog>
          <RelinkPlaid
            showRelinkDialog={this.state.showRelinkDialog}
            toggleRelinkDialog={this.toggleRelinkDialog}
            user={this.props.user}
            isSmallScreen={this.props.isSmallScreen}
            getAllAccountInfo={this.props.getAllAccountInfo}
            t={this.props.t}
            selectedRelinkAcc={this.state.selectedRelinkAcc}
          />
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            action={this.submitAPI}
            name={this.props.t('submit')}
            successMessage={
              this.state.activeTab === 0
                ? this.props.t('depositcompletedsuccessfully')
                : this.state.activeTab === 2
                ? this.props.t('withdrawcompletedsuccessfully')
                : this.props.t('transfercompletedsuccessfully')
            }
            customHeader={this.props.t('confirmation')}
            customMessage={this.state.confirmMessage}
            t={this.props.t}
          />
        </div>
      )
    }
  }
}

// const mapStateToProps = (state) => {
//   return {
//     accounts: state.account.accounts
//   };
// };

// export default connect(mapStateToProps, actions)(TransferFundsDW);
