import '../scss/components/DwKYC.scss'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { Dropdown } from 'primereact/dropdown'
import Axios from 'axios'
import Endpoint from './Endpoint'
import React, { useState, useEffect } from 'react'
export default function DwKYC(props) {
  const [frontDocument, setFrontDocument] = useState(null)
  const [backDocument, setBackDocument] = useState(null)
  const [documentType, setDocumentType] = useState('')
  const [loading, setLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [fileTypeOptions, setFileTypeOptions] = useState([])

  // const fileTypeOptions = [
  //   {
  //     label: 'Drivers License',
  //     value: {
  //       documentType: 'DRIVERS_LICENSE',
  //       both_sides: true,
  //     },
  //   },
  //   {
  //     label: 'Passport',
  //     value: {
  //       documentType: 'PASSPORT',
  //       both_sides: false,
  //     },
  //   },
  //   {
  //     label: 'National ID: Birth Certificate, State ID, SSN Card, etc',
  //     value: {
  //       documentType: 'NATIONAL_ID_CARD',
  //       both_sides: true,
  //     },
  //   },
  //   {
  //     label: 'Voter ID',
  //     value: {
  //       documentType: 'VOTER_ID',
  //       both_sides: true,
  //     },
  //   },
  //   {
  //     label: 'Work Permit: EAD Card, H1B Authorization, etc',
  //     value: {
  //       documentType: 'WORK_PERMIT',
  //       both_sides: true,
  //     },
  //   },
  //   {
  //     label: 'Visa',
  //     value: {
  //       documentType: 'VISA',
  //       both_sides: false,
  //     },
  //   },
  //   {
  //     label: 'Residence Permit: Green Card, Permanent Residency, etc',
  //     value: {
  //       documentType: 'RESIDENCE_PERMIT',
  //       both_sides: true,
  //     },
  //   },
  // ]

  useEffect(() => {
    fetchPresetData()
  }, [])

  const fetchPresetData = async () => {
    try {
      const resp = await Axios.get(`${Endpoint}/drivewealth/preset`)
      if (resp?.data?.document_types_v2) {
        const documentData = resp.data.document_types_v2.map((document) => {
          return {
            label: document.description,
            value: {
              both_sides: document.both_sides,
              documentType: document.name,
            },
          }
        })
        setFileTypeOptions(documentData)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const uploadFrontFile = async ({ files }) => {
    setErrorMsg('')
    if (files.length > 1) {
      files.pop()
      setErrorMsg(props.t('pleaseremovepreviousimagetouploadanotherone'))
    } else {
      setFrontDocument(files[0])
    }
  }
  const uploadBackFile = async ({ files }) => {
    setErrorMsg('')
    if (files.length > 1) {
      files.pop()
      setErrorMsg(props.t('pleaseremovepreviousimagetouploadanotherone'))
    } else {
      setBackDocument(files[0])
    }
  }
  const submitDocuments = async () => {
    setLoading(true)
    setErrorMsg('')
    setSuccessMsg('')
    const formData = new FormData()
    formData.append('userID', props.dwKYCid)
    formData.append('documentType', documentType.documentType)
    formData.append('side', 'FRONT')
    formData.append('documentImage', frontDocument)
    await Axios.post(`${Endpoint}/drivewealth/user/kyc-doc`, formData, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then(async (res) => {
        if (res?.data?.status?.description) {
          if (documentType.both_sides) {
            formData.set('side', 'BACK')
            formData.set('documentImage', backDocument)
            Axios.post(`${Endpoint}/drivewealth/user/kyc-doc`, formData, {
              headers: {
                Accept: 'application/json',
              },
            })
              .then(async (res) => {
                let status = await props.getAllAccountInfoV2()
                if (status) {
                  setLoading(false)
                  setSuccessMsg(props.t('documenthasbeenuploadedforapproval'))
                }
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
                setErrorMsg(props.t('failedtouploaddocument'))
              })
          } else {
            let status = await props.getAllAccountInfoV2()
            if (status) {
              setLoading(false)
              setSuccessMsg(props.t('documenthasbeenuploadedforapproval'))
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setErrorMsg(props.t('failedtouploaddocument'))
      })
  }

  if (props.t) {
    return (
      <div className='dw-kyc-container'>
        {props.dwKYCstatus === 'pending' && (
          <p className='kyc-status-message'>
            {props.t('pleasewaituntilverificationiscomplete')}
          </p>
        )}
        {props.dwKYCstatus === 'approved' && (
          <p className='kyc-status-message'>{props.t('kycapproved')}</p>
        )}
        <p className='dw-kyc-header'>{props.t('selectdocumenttype')}</p>
        <div className='file-options-container'>
          <Dropdown
            value={documentType}
            onChange={(e) => {
              setDocumentType(e.target.value)
            }}
            placeholder={props.t('documenttype')}
            options={fileTypeOptions}
          />
        </div>
        <div className='file-upload-container'>
          <FileUpload
            customUpload={true}
            uploadHandler={uploadFrontFile}
            auto
            mode='advanced'
            chooseLabel={props.t('uploadfrontside')}
            accept='.jpg,.png'
            id='frontSide'
          />
        </div>
        {documentType?.both_sides ? (
          <>
            {' '}
            <div className='file-upload-container'>
              <FileUpload
                name='file'
                customUpload={true}
                uploadHandler={uploadBackFile}
                auto
                mode='advanced'
                chooseLabel={props.t('uploadbackside')}
                accept='.jpg,.png'
              />
            </div>
          </>
        ) : null}
        <div className='dw-kyc-submit-container'>
          <Button
            label={props.t('submit')}
            className='dw-kyc-submit-btn'
            onClick={() => {
              submitDocuments()
            }}
            loading={loading}
            disabled={
              props.dwKYCstatus === 'pending' ||
              props.dwKYCstatus === 'approved'
            }
          />
        </div>
        <p className='error-msg'>{errorMsg}</p>
        <p className='success-msg'>{successMsg}</p>
      </div>
    )
  } else return null
}
