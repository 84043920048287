import React, { Component } from "react";
import PressRelease from "../PressRelease";
import Articles from "../Articles";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class PressAndArticles extends Component {
  state = {
    isChinese: this.props.isChinese,
    presses: [
      {
        id: 1,
        title:
          "AllocateRite Announces a New Version of its Mobile App, with Significant Updates",
        date: "January 31, 2020",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-announces-a-new-version-of-its-mobile-app-with-significant-updates-300997179.html",
        content:
          "NEW YORK, Jan. 31, 2020 /PRNewswire/ -- AllocateRite Digital, the New York FinTech and data science company that provides wealth managers and individual investors with ETF-based dynamic asset allocation strategies and risk analytics, is excited to announce the release of a newly updated version of our mobile app. The update includes stock market momentum indicators which use AI […]"
      },
      {
        id: 2,
        title:
          "AllocateRite's AI-Powered Autonomous Wealth Management Strategies Are Now Available Via iOS and Android Mobile Apps",
        date: "January 20, 2020",
        url:
          "https://www.prnewswire.com/news-releases/allocaterites-ai-powered-autonomous-wealth-management-strategies-are-now-available-via-ios-and-android-mobile-apps-300989890.html",
        content:
          "NEW YORK, Jan. 20, 2020 /PRNewswire/ -- AllocateRite Digital, the New York FinTech and data science company that provides wealth managers with ETF-based dynamic asset allocation strategies and risk analytics, is excited to announce that its proprietary portfolio management and asset allocation strategies are now available to iOS and Android mobile devices via our mobile app. […]"
      },
      {
        id: 3,
        title:
          "AllocateRite Introduces Consolidated Risk Assessment Across Multiple Accounts - Because You Never Know When The Next Bubble Will Burst",
        date: "January 13, 2020",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-introduces-consolidated-risk-assessment-across-multiple-accounts--because-you-never-know-when-the-next-bubble-will-burst-300985844.html",
        content:
          "NEW YORK, Jan. 13, 2020 /PRNewswire/ -- AllocateRite Digital, the New York FinTech and data science company that provides investment managers with ETF-based dynamic asset allocation strategies, is excited to announce support for multiple accounts at multiple brokerages. Get the complete picture of your financial health by linking multiple brokerage accounts. Use RiskMonkey […]"
      },
      {
        id: 4,
        title:
          "AllocateRite Introduces RiskMonkey™ AI: A new product that uses sophisticated risk analytics and AI-based forecasting together in an easy-to-use app",
        date: "November 7, 2019",
        url:
          "http://www.prnewswire.com/news-releases/allocaterite-introduces-riskmonkey-ai-a-new-product-that-uses-sophisticated-risk-analytics-and-ai-based-forecasting-together-in-an-easy-to-use-app-300953938.html?tc=eml_cleartime",
        content:
          "NEW YORK, Nov. 7, 2019 /PRNewswire/ -- AllocateRite Digital, the New York FinTech and data science company that provides investment managers with ETF-based dynamic asset allocation strategies, is excited to announce the launch of a portfolio analysis app whose sophistication makes it appropriate for wealth managers but whose ease of use and flexibility can be utilized by anyone[…]"
      },
      {
        id: 5,
        title:
          "AllocateRite Democratizes Wealth Management with its New Mobile App, Available on Apple App Store and Google Play Store",
        date: "October 29, 2019",
        url:
          "http://www.prnewswire.com/news-releases/allocaterite-democratizes-wealth-management-with-its-new-mobile-app-available-on-apple-app-store-and-google-play-store-300947648.html?tc=eml_cleartime",
        content:
          "NEW YORK, Oct. 29, 2019 /PRNewswire/ -- AllocateRite Digital, the New York FinTech and data science company that provides investment managers with ETF-based dynamic asset allocation strategies, is excited to announce the launch of its mobile app, available for both iOS and Android mobile devices […]"
      },
      {
        id: 6,
        title:
          "AllocateRite Unveils Derivative of its U.S. Domestic Composite Investment Strategy",
        date: "June 9, 2019",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-unveils-derivative-of-its-us-domestic-composite-investment-strategy-300864043.html",
        content:
          "NEW YORK, June 7, 2019 /PRNewswire/ — AllocateRite, the New York FinTech and data science company that provides wealth managers with dynamic asset allocations employed through ETF-based smart investment strategies, is excited to announce the addition of a new deep learning strategy to its portfolio, based on stacking two strategic hidden layers and a protective risk-managing outer layer […]"
      },
      {
        id: 7,
        title:
          "AllocateRite Announces Hiring of New Chief of AI and Data Science",
        date: "April 11, 2019",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-announces-hiring-of-new-chief-of-ai-and-data-science-300830315.html",
        content:
          "NEW YORK, April 11, 2019 /PRNewswire/ — AllocateRite, the New York FinTech and data science company that provides asset managers with dynamic asset allocations employed through ETF-based smart investment strategies, is excited to announce world class AI and data science expert Michael Spece has joined AllocateRite as its Chief of AI and Data Science. Mr. Spece will work directly with […]"
      },
      {
        id: 8,
        title: "AllocateRite Reveals New Tax Optimization Capability",
        date: "August 9, 2018",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-reveals-new-tax-optimization-capability-300695006.html?tc=eml_cleartime",
        content:
          "NEW YORK, Aug. 9, 2018 /PRNewswire/ — AllocateRite, a New York FinTech company that provides asset managers with dynamic asset allocations employed through ETF-based investment strategies, today announced a new, proprietary solution to optimizing tax efficiency on an individual-portfolio basis. The new tool allows investment advisers the option to elect AllocateRite’s tax optimization model and select an appropriate […]"
      },
      {
        id: 9,
        title:
          "AllocateRite Announces Launch of AI-powered Mandarin Chinese Digital Asset Management Platform and Website",
        date: "August 3, 2018",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-announces-launch-of-ai-powered-mandarin-chinese-digital-asset-management-platform-and-website-300691591.html?tc=eml_cleartime",
        content:
          "NEW YORK, Aug. 3, 2018 /PRNewswire/ — AllocateRite, a New York FinTech company that provides asset managers with dynamic asset allocations employed through ETF-based investment strategies, today announced that it has launched a Mandarin version of its fully automated digital asset management platform and marketing website designed for the Chinese market."
      },
      {
        id: 10,
        title:
          "AllocateRite Launches Fresh New Look for Its Digital Asset Management Platform Technology with Enhanced Dashboard Functionality",
        date: "July 17, 2018",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-launches-fresh-new-look-for-its-digital-asset-management-platform-technology-with-enhanced-dashboard-functionality-300682082.html",
        content:
          "NEW YORK, July 17, 2018 /PRNewswire/ — AllocateRite, the innovative New York FinTech company that provides asset managers with ETF-based investment strategies, today announced the rollout of its newly branded and enhanced digital platform. Created with the user experience in mind, the platform now features smart tax optimization and integration of blockchain technology to ensure integrity and immutability of its data and content. Enhanced interactive"
      },
      {
        id: 11,
        title:
          "AllocateRite Unveils Two New Internationally Focused Market Strategies",
        date: "June 27, 2017",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-unveils-two-new-internationally-focused-market-strategies-300480165.html",
        content:
          "NEW YORK, June 27, 2017 /PRNewswire/ — AllocateRite today announces the addition of two new products to its portfolio: Diversified International Composite and Global Dynamic Blend Composite, which extends the breadth of AllocateRite’s investment strategies to include international markets. These new strategies leverage our same proprietary algorithmic model- focusing on low volatility and long-term outperformance."
      }
    ],
    cnPresses: [
      {
        id: 1,
        title: "AllocateRite推出其美国国内综合投资策略的衍生产品",
        date: "2019年6月9日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-unveils-derivative-of-its-us-domestic-composite-investment-strategy-300864043.html",
        content:
          "AllocateRite，纽约金融科技和数据科学公司，通过基于ETF的智能投资策略为财富管理机构提供动态资产配置，我们很高兴地宣布增加一项新的深度学习策略 基于堆叠两个战略隐藏层和保护风险管理外层[...]"
      },
      {
        id: 2,
        title: "AllocateRite宣布聘用人工智能和数据科学新任主管",
        date: "2019年4月11日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-announces-hiring-of-new-chief-of-ai-and-data-science-300830315.html",
        content:
          "AllocateRite，纽约金融科技和数据科学公司，通过基于ETF的智能投资策略为资产管理公司提供动态资产配置，很高兴宣布世界级人工智能和数据科学专家迈克尔 Spece已加入AllocateRite，担任AI和数据科学主管。 Spece先生将直接与[...]"
      },
      {
        id: 3,
        title: "AllocateRite展示新的税务优化能力",
        date: "2018年8月9日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-reveals-new-tax-optimization-capability-300695006.html?tc=eml_cleartime",
        content:
          "AllocateRite是一家纽约金融科技公司，通过基于ETF的投资策略为资产管理公司提供动态资产配置，今天宣布推出一项新的专有解决方案，以优化个人的税收效率 -  组合基础。 新工具允许投资顾问选择AllocateRite的税收优化模型并选择合适的[...]"
      },
      {
        id: 4,
        title: "AllocateRite宣布推出基于AI的中文数字资产管理平台和网站",
        date: "2018年8月3日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-announces-launch-of-ai-powered-mandarin-chinese-digital-asset-management-platform-and-website-300691591.html?tc=eml_cleartime",
        content:
          "AllocateRite是一家纽约金融科技公司，通过基于ETF的投资策略为资产管理公司提供动态资产配置，今天宣布推出其全自动数字资产的普通话版本 为中国市场设计的管理平台和营销网站。"
      },
      {
        id: 5,
        title:
          "AllocateRite通过增强的仪表板功能为其数字资产管理平台技术带来全新面貌",
        date: "2018年7月17日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-launches-fresh-new-look-for-its-digital-asset-management-platform-technology-with-enhanced-dashboard-functionality-300682082.html",
        content:
          "AllocateRite是一家创新的纽约金融科技公司，为资产管理公司提供基于ETF的投资策略，今天宣布推出其新品牌和增强型数字平台。 该平台以用户体验为基础，现在具有智能税收优化和区块链技术集成，以确保其数据和内容的完整性和不变性。 增强互动性"
      },
      {
        id: 6,
        title: "AllocateRite推出两项新的国际市场战略",
        date: "2017年6月27日",
        url:
          "https://www.prnewswire.com/news-releases/allocaterite-unveils-two-new-internationally-focused-market-strategies-300480165.html",
        content:
          "AllocateRite今天宣布在其产品组合中增加两种新产品：Diversified International Composite和Global Dynamic Blend Composite，它将AllocateRite投资策略的广度扩展到国际市场。 这些新策略利用我们相同的专有算法模型 - 专注于低波动性和长期优异性能。"
      }
    ],
    articles: [
      {
        id: 1,
        title:
          "AllocateRite Launches AI-Powered Mandarin Chinese Digital Asset Management Platform",
        date: "August 6, 2018",
        url:
          "http://nyctechnews.org/allocaterite-launches-ai-powered-mandarin-chinese-digital-asset/",
        content:
          "Designed for the Chinese market AllocateRite a New York FinTech company provides asset managers with dynamic asset allocations. Read more at NYC Tech News."
      },
      {
        id: 2,
        title:
          "AllocateRite Launches Its Blockchain Digital Asset Management Platform",
        date: "July 18, 2018",
        url:
          "https://bitcoinexchangeguide.com/allocaterite-launches-its-blockchain-digital-asset-management-platform/",
        content:
          "The innovative New York FinTech company that provides asset managers with ETF-based investment strategies, said its FinTech platform was designed with ease and simplicity for the asset management vertical."
      },
      {
        id: 3,
        title:
          "You Too Can Now Invest Wisely, Using Artificial Intelligence – Linkedin",
        date: "June 20, 2017",
        url:
          "https://www.linkedin.com/pulse/you-too-can-now-invest-wisely-using-artificial-don-peppers",
        content:
          "By Don Peppers Best-Selling Author. Keynote Speaker. Customer Experience, Marketing and Sales Thought Leader. Fun Guy. If you’ve ever interacted with any of the growing number of robo advisors, such as Betterment, Charles Schwab, Wealthfront, or Vanguard, then you’re familiar with how the automated question-and-answer onboarding session goes. How old are you? How much in savings and investments do you […]"
      }
    ],
    cnArticles: [
      {
        id: 1,
        title: "AllocateRite推出支持AI的普通话中文数字资产管理平台",
        date: "2018年8月6日",
        url:
          "http://nyctechnews.org/allocaterite-launches-ai-powered-mandarin-chinese-digital-asset/",
        content:
          "专为中国市场设计的AllocateRite纽约金融科技公司为资产经理提供动态资产配置。 阅读更多纽约科技新闻。"
      },
      {
        id: 2,
        title: "AllocateRite推出其区块链数字资产管理平台",
        date: "July 18, 2018",
        url:
          "https://bitcoinexchangeguide.com/allocaterite-launches-its-blockchain-digital-asset-management-platform/",
        content:
          "创新的纽约金融科技公司为资产管理公司提供基于ETF的投资策略，该公司表示其FinTech平台的设计简单，简单，适用于垂直资产管理。"
      },
      {
        id: 3,
        title: "您现在可以使用人工智能进行明智的投资 -  Linkedin",
        date: "June 20, 2017",
        url:
          "https://www.linkedin.com/pulse/you-too-can-now-invest-wisely-using-artificial-don-peppers",
        content:
          "如果您曾与任何越来越多的机器人顾问（例如Betterment，Charles Schwab，Wealthfront或Vanguard）进行过互动，那么您就会熟悉自动问答式入职培训会议的进展情况。 你几岁？ 你有多少储蓄和投资[...]"
      }
    ]
  };

  render() {
    return (
      <div>
        <Tabs>
          <TabList>
            <Tab>{this.state.isChinese ? "新闻中心" : "PRESS RELEASES"}</Tab>
            <span> | </span>
            <Tab>{this.state.isChinese ? "文章" : "ARTICLES"}</Tab>
          </TabList>

          <TabPanel>
            {this.state.isChinese ? (
              <PressRelease value={this.state.cnPresses} />
            ) : (
              <PressRelease value={this.state.presses} />
            )}
          </TabPanel>
          <TabPanel>
            {this.state.isChinese ? (
              <Articles value={this.state.cnArticles} />
            ) : (
              <Articles value={this.state.articles} />
            )}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default PressAndArticles;
