import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Amplify, { Auth } from 'aws-amplify'
import VerificationInput from 'react-verification-input'

//COMPONENTS
import '../../scss/pages/ForgotPassword.scss'
import { Button } from 'primereact/button'
import { validateUsername } from '../../utility-functions/validateUsername'
import { validatePassword } from '../../utility-functions/validatePassword'
import MainNavBar from '../components/MainNavBar'
import Loader from '../components/accountInfo/Loader'
import PasswordInput from '../PasswordInput'
import { NavLink } from 'react-router-dom'
import PasswordMessage from '../PasswordMessage'

export default function ForgotPassword(props) {
  const { t } = props
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general

  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [page, setPage] = useState('emailform') // emailform -> verifycode -> passwordform ->
  const [authCode, setAuthCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [status, setStatus] = useState(false)
  const [showPasswordMessage, setShowPasswordMessage] = useState(false)

  useEffect(() => {
    if (authCode.length === 6) {
      resetMessages()
      setPage('passwordform')
    }
  }, [authCode])

  const resetMessages = () => {
    setErrorMsg('')
    setSuccessMsg('')
  }

  const validateForm = () => {
    if (validateUsername(email)) {
      sendForgotPasswordCode()
    } else {
      setErrorMsg(t('pleaseentervalidemail'))
    }
  }

  const validatePasswordForm = () => {
    if (validatePassword(newPassword)) {
      if (newPassword === confirmPassword) {
        updatePassword()
      } else {
        setErrorMsg(t('newpasswordandconfirmdoesnotmatch'))
      }
    } else {
      showPasswordMessage(true)
      //setErrorMsg(t('pleaseentervalidpassword'))
    }
  }

  const sendForgotPasswordCode = () => {
    setLoading(true)
    Auth.forgotPassword(email)
      .then((resp) => {
        setPage('verifycode')
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setErrorMsg(error.message)
        setLoading(false)
      })
  }

  const resendCode = () => {
    resetMessages()
    setLoading(true)
    Auth.forgotPassword(email)
      .then((resp) => {
        setSuccessMsg(t('codehasbeensent'))
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setErrorMsg(error.message)
        setLoading(false)
      })
  }

  const updatePassword = () => {
    setLoading(true)
    Auth.forgotPasswordSubmit(email, authCode, newPassword)
      .then((res) => {
        console.log('res', res)
        setSuccessMsg(t('yourpasswordhasbeenreset'))
        setStatus(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log('err', err)

        if (
          err.message ===
          'Invalid verification code provided, please try again.'
        )
          setPage('verifycode')
        setErrorMsg(err.message)
        setLoading(false)
      })
  }

  const showEmailForm = () => {
    return (
      <>
        {' '}
        <input
          placeholder={t('email')}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          name='username'
          autoComplete='username'
          className='forgot-password-input'
        />
        <div className='forgot-password-btn-container'>
          <Button
            className='forgot-password-button'
            label={t('submit')}
            onClick={() => {
              resetMessages()
              validateForm()
            }}
            loading={loading}
          />
        </div>
      </>
    )
  }

  const showVerifyCode = () => {
    return loading ? (
      <>
        <Loader isSmallScreen={isSmallScreen} logoLoader={false} />
      </>
    ) : (
      <div className='codeConfirm__container'>
        <p>{t('verificationcodesentmessage')}</p>
        <VerificationInput
          autoFocus
          removeDefaultStyles
          classNames={{
            container: 'verificationInput__container',
            character: 'verificationInputCharacter__container',
            characterInactive:
              'verificationInputCharacter__container__inactive',
            characterSelected:
              'verificationInputCharacter__container__selected',
          }}
          validChars='0-9'
          placeholder=''
          onChange={(e) => setAuthCode(e)}
          value={authCode}
        />
        <p className='resend__button' style={{ cursor: 'pointer' }}>
          {t('didntgetthecode')}
          <span onClick={() => resendCode()} className='resend__button'>
            {t('resendcode')}
          </span>
        </p>
      </div>
    )
  }

  const showPasswordForm = () => {
    return (
      <>
        <PasswordInput
          password={newPassword}
          setPassword={setNewPassword}
          placeHolder={t('newpassword')}
          className={'forgot-password-input space-bottom'}
        />
        <PasswordInput
          password={confirmPassword}
          setPassword={setConfirmPassword}
          placeHolder={t('confirmnewpassword')}
          className={'forgot-password-input space-bottom'}
        />
        {showPasswordMessage && (
          <PasswordMessage t={t} password={newPassword} />
        )}
        <div className='forgot-password-btn-container'>
          <Button
            className='forgot-password-button'
            label={t('submit')}
            onClick={() => {
              resetMessages()
              validatePasswordForm()
            }}
            loading={loading}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <MainNavBar
        isSmallScreen={isSmallScreen}
        t={props.t}
        whiteBorder={true}
      />
      <div className='forgot-password-container'>
        <div className='forgot-password-form-container'>
          <p className='forgot-password-header'>{t('changepassword')}</p>
          <div className='forgot-password-form'>
            {status && successMsg ? (
              <>
                <p className='success'>{successMsg}</p>
                <NavLink to={'/signin'} exact={true}>
                  <p className='signin-btn'>{t('clickheretologin')}</p>
                </NavLink>
              </>
            ) : (
              <>
                {page === 'emailform' && showEmailForm()}
                {page === 'verifycode' && showVerifyCode()}
                {page === 'passwordform' && showPasswordForm()}
                {errorMsg && <p className='error'>{errorMsg}</p>}
              </>
            )}
            {/* {page === 'emailform' && showEmailForm()}
            {page === 'verifycode' && showVerifyCode()}
            {page === 'passwordform' && showPasswordForm()}
            {errorMsg && <p className='error'>{errorMsg}</p>}
            {successMsg && <p className='success'>{successMsg}</p>}
            {status && (
              <NavLink to={'/signin'} exact={true}>
                <p className='signin-btn'>{t('clickheretologin')}</p>
              </NavLink>
            )} */}
          </div>
        </div>
      </div>
    </>
  )
}
