import React from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'

export default class DdChart extends React.Component {
  state = {}
  render() {
    let drawdown = this.props.drawdownChart
    if (!drawdown) {
      return null
    }
    const drawdownData = drawdown.map((ele) => [
      ele[0],
      Math.abs(ele[1].toFixed(2)),
    ])
    drawdownData.sort((a, b) => a[0] - b[0])
    let pcnts = drawdownData.map((ele) => ele[1])
    let maxVal = Math.max(...pcnts)
    let minVal = Math.min(...pcnts)
    let maxRange = maxVal + (maxVal - minVal) * 0.02
    let minRange = minVal - (maxVal - minVal) * 0.02
    let lastDrawdown = drawdown[drawdown.length - 1]
    const config = {
      credits: {
        enabled: false,
      },
      chart: {
        zoomType: 'x',
        height: 200,
        backgroundColor: 'transparent',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'datetime',
        tickLength: 0,
        formatter: function () {
          return moment.unix(this.value).format('mm/yyyy')
        },
      },
      tooltip: {
        valueSuffix: '%',
      },
      yAxis: {
        title: {
          text: this.props.t('risk'),
        },
        gridLineColor: 'transparent',
        maxZoom: 150,
        max: maxRange,
        min: minRange,
        labels: {
          format: '{value}%',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, '#8682cb'],
              [1, 'rgba(120, 116, 182, 0)'],
            ],
          },
          marker: {
            radius: 5,
          },
          lineColor: '#8682cb',
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 3,
            },
          },
          threshold: null,
        },
      },
      series: [
        {
          animation: false,
          type: 'area',
          name: 'Max Drawdown',
          data: drawdownData,
        },
      ],
    }
    if (this.props.t) {
      return (
        <div>
          <ReactHighcharts config={config} />
        </div>
      )
    } else return null
  }
}
