import React, { useContext } from 'react'
import { LandingPageContext } from '.'
import getArrow from '../../../utility-functions/getArrow'
import getPercentage from '../../../utility-functions/getPercentage'
import formatCurrency from '../../../utility-functions/formatCurrency'
export default function TickerCard() {
  const { t, data } = useContext(LandingPageContext)

  const forecastChange = (price, forecast) => {
    return (
      ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
      100
    ).toFixed(2)
  }

  const symbol = data?.symbol
  const price = data?.price

  const oneMonthPrice = data?.returnForecastValue
  const threeMonthPrice = data?.forecast_3m
  const sixMonthPrice = data?.forecast_6m
  const oneYearPrice = data?.forecast_1y
  const twoYearPrice = data?.forecast_2y

  const oneMonthChange = getPercentage(price, oneMonthPrice)
  const threeMonthChange = getPercentage(price, threeMonthPrice)
  const sixMonthChange = getPercentage(price, sixMonthPrice)
  const oneYearChange = getPercentage(price, oneYearPrice)
  const twoYearChange = getPercentage(price, twoYearPrice)

  return (
    <div className='ticker-card'>
      <div className='header'>
        <p className='ticker'>{symbol}</p>
        <p className='price'>{formatCurrency(price)}</p>
      </div>
      <div className='ticker-content'>
        <div className='ticker-col'>
          <p className='heading'>{t('forecast')}</p>
          <p>1-2 {t('month')}</p>
          <p>3 {t('month')}</p>
          <p>6 {t('month')}</p>
          <p>1 {t('year')}</p>
          <p>2 {t('year')}</p>
        </div>
        <div className='ticker-col'>
          <p className='heading'>{t('price')}</p>
          <p>
            {parseFloat(oneMonthPrice).toFixed(2)}{' '}
            {getArrow(oneMonthChange.value, 25)}
          </p>
          <p>
            {parseFloat(threeMonthPrice).toFixed(2)}{' '}
            {getArrow(threeMonthChange.value)}
          </p>
          <p>
            {parseFloat(sixMonthPrice).toFixed(2)}{' '}
            {getArrow(sixMonthChange.value, 25)}
          </p>
          <p>
            {parseFloat(oneYearPrice).toFixed(2)}{' '}
            {getArrow(oneYearChange.value, 25)}
          </p>
          <p>
            {parseFloat(twoYearPrice).toFixed(2)}{' '}
            {getArrow(twoYearChange.value, 25)}
          </p>
        </div>
        <div className='ticker-col'>
          <p className='heading'>%{t('change')}</p>
          <p>{oneMonthChange.formattedValue}</p>
          <p>{threeMonthChange.formattedValue}</p>
          <p>{sixMonthChange.formattedValue}</p>
          <p>{oneYearChange.formattedValue}</p>
          <p>{twoYearChange.formattedValue}</p>
        </div>
      </div>
    </div>
  )
}
