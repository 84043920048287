import React, { Component } from 'react'
import { Card, Row, Button, Form } from 'react-bootstrap'
import {
  FaArrowLeft,
  FaSearch,
  FaTimes,
  FaQuestionCircle,
} from 'react-icons/fa'
import SingleCompanyCard from '../singleCompanyCard'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Youtube from 'react-youtube'
// import Comms_list from "../../assets/homeIcons/comms_list.svg"
// import Crypto  from "../../assets/homeIcons/crypto.svg"
// import Recovery  from "../../assets/homeIcons/recovery.svg"
// import Semiconductor  from "../../assets/homeIcons/semiconductor.svg"
import Japan from '../../assets/homeIcons/japan.svg'
import India from '../../assets/homeIcons/india.svg'
import Germany from '../../assets/homeIcons/germany.svg'
import China from '../../assets/homeIcons/china.svg'
import Brazil from '../../assets/homeIcons/brazil.svg'
import ARlogo from '../../assets/ar_logo.png'
import ForeSciteNoText from '../../assets/newUI/ForesciteNoText.png'
import Explore from '../../assets/newUI/explore.png'
import Crypto from '../../assets/newUI/crypto.png'
import Recovery from '../../assets/newUI/profits.png'
import Comms_list from '../../assets/newUI/global.png'
import Semis from '../../assets/newUI/semis.png'
import Cannabis from '../../assets/newUI/cannabis.png'
import EV from '../../assets/newUI/ev.png'
import Energy from '../../assets/newUI/energy.png'
import Finance from '../../assets/newUI/finance.png'
import Healthcare from '../../assets/newUI/healthcare.png'
import SaaS from '../../assets/newUI/saas.png'
import Active from '../../assets/newUI/active.png'
import Etf from '../../assets/newUI/etf.jpg'
import NoImage from '../../assets/newUI/logo_notfound.png'
import ESG from '../../assets/newUI/lsg_3x.png'
import XIcon from '../../assets/x_icon.svg'
import ENDPOINT from '../Endpoint'

import { NavLink /*Redirect*/ } from 'react-router-dom'
import SearchCard from './SearchCard'

import DoubleNegative from '../../assets/double-negative.png'
import SingleNegative from '../../assets/single-negative.png'
import Neutral from '../../assets/neutral.png'
import SinglePositive from '../../assets/single-positive.png'
import DoublePositive from '../../assets/double-positive.png'
import SearchEndpoint from '../SearchEndpoint'
import Disclaimer from '../Disclaimer'

const brandColor = '#00A99D'

const THEME = {
  SelectTheme: 'Choose an Investment Theme',
  Brazil: 'Brazil',
  Cannabis: 'Cannabis',
  China: 'China',
  Communications: 'Communications',
  Crypto: 'Crypto',
  EV: 'Electronic Vehicles ',
  ETFs: 'ETFs',
  Energy: 'Energy',
  FAANG: 'FAANG',
  Finance: 'Finance',
  Germany: 'Germany',
  Healthcare: 'Healthcare',
  India: 'India',
  Japan: 'Japan',
  most_active: 'Most Active Stocks Today',
  Recovery: 'Recovery',
  SaaS: 'Software as a Service',
  Semis: 'Semiconductors',
  Technology: 'Technology',
  'Top ESG': 'Top ESG',
}

const THEMETITLE = {
  SelectTheme: 'Choose an Investment Theme',
  Brazil: 'BRAZIL',
  Cannabis: 'CANNABIS',
  China: 'CHINA',
  Communications: 'COMMS',
  Crypto: 'CRYPTO',
  EV: 'EV',
  ETFs: 'ETFs',
  Energy: 'ENERGY',
  FAANG: 'FAANG',
  Finance: 'FINANCE',
  Germany: 'GERMANY',
  Healthcare: 'HEALTHCARE',
  India: 'INDIA',
  Japan: 'JAPAN',
  most_active: 'TOP 30',
  Recovery: 'RECOVERY',
  SaaS: 'SaaS',
  Semis: 'SEMIS',
  Technology: 'TECH',
  'Top ESG': 'TOP ESG',
}

const THEMEIMG = {
  Brazil: Brazil,
  Cannabis: Cannabis,
  China: China,
  Communications: Comms_list,
  Crypto: Crypto,
  EV: EV,
  ETFs: Etf,
  Energy: Energy,
  FAANG: Active,
  Finance: Finance,
  Germany: Germany,
  Healthcare: Healthcare,
  India: India,
  Japan: Japan,
  most_active: Active,
  Recovery: Recovery,
  SaaS: SaaS,
  Semis: Semis,
  Technology: Comms_list,
  'Top ESG': ESG,
}

const THEMEDESC = {
  SelectTheme: 'Investment Themes',
  Brazil: 'A collection of the largest companies based in Brazil.',
  Cannabis:
    'Also known as "pot stocks” which cover a range of applications across the entire supply chain. From consumer goods focused on the emerging CBD trend to cannabis-focused bio-tech. this theme includes stocks such as  like Aurora Cannabis and Canopy Growth, to heritage brands like ScottsMiracle-Gro.',
  China: 'A collection of the largest companies based in China',
  Communications:
    'The communications sector is comprised of a broad range of companies that sell phone and Internet services via traditional landline, broadband, or wireless.',
  Crypto: 'These are crypto curriencies with their value shown in US Dollars.',
  EV: 'Companies who have a direct impact on Electric Vehicles. These companies can involved in  creating the batteries, the car itself, or other specific parts of electric vehicles.',
  Energy:
    'A stock in a company whose predominant business is the production or sale of energy. Energy stock may include shares in both upstream companies, such as oil exploration firms, and downstream companies, such as oil refineries.',
  FAANG:
    'FAANG is an acronym referring to the stocks of the five most popular and best-performing American technology companies: Facebook, Amazon, Apple, Netflix and Google (now known as Alphabet).',
  Finance:
    'A wide range of companies involved in retail and commercial banking, accounting, insurance, asset management, credit cards, and brokerage. Well-known companies in the sector include JP Morgan, Bank of America, Goldman Sachs Group and more.',
  Germany: 'A collection of the largest companies based in Germany.',
  Healthcare:
    'Healthcare stocks fall into six categories: pharmaceuticals, biotechnology, medical equipment, sales, insurance, and facilities. While the healthcare industry has good growth prospects and is often economy-proof, it also carries some unique investment risks.',
  India: 'A collection of the largest companies based in India.',
  Japan: 'A collection of the largest companies based in Japan.',
  most_active:
    'This list represents the 20 most actively traded stocks. Updated daily.',
  Recovery:
    "Companies that benefit from both an increase in revenue as we proceed toward a general reopening of the economy, as well as from an influx of retail investors' stimulus money, are kind of the ultimate recovery stocks.",
  SaaS: 'Software as a service is a software licensing and delivery model in which software is licensed on a subscription basis and is centrally hosted. It is sometimes referred to as "on-demand software.',
  Semis:
    'The term SaaS stands for Software as a Service. SaaS companies are centrally-hosted and tend to be subscription-based, which enables many of them to combine a relatively low product delivery cost with a recurring revenue model. The companies that make up this theme—Oracle, Salesforce, and Shopify to name a few—are helping to power thousands of enterprise, mid-sized, and small businesses globally.',
  Technology:
    'Any stock involved in the technology sector, from semiconductor producers to software providers. Tech stocks are often a leading indicator for the economy and the stock market.',
}

export default class PriceTrendPredictor extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      searchMomentumText: '',
      isSmallScreen: false,
      momDataSource: [],
      isMomDataLoading: false,
      showMore: true,
      selectedPreset: 'most_active',
      presetTickers: null,
      showVideo: false,
      hideHowTo: false,
      themeDesc: {},
      itemHashSet: null,
    }
  }

  componentDidMount() {
    this.fetchCommonStocks()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    this.input.current.focus()
    let itemHashSet = new Set()
    itemHashSet.add('most_active')
    itemHashSet.add('Crypto')
    itemHashSet.add('TOP ESG')
    itemHashSet.add('Energy')
    itemHashSet.add('ETFs')
    itemHashSet.add('EV')
    itemHashSet.add('Finance')
    itemHashSet.add('Semis')
    itemHashSet.add('Technology')
    this.setState({ itemHashSet: itemHashSet })
    // document.title = `AllocateRite - Search`;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  setSelectedPreset = (sector) => {
    if (sector !== 'SelectTheme') {
      let selectedPreset
      if (this.state.presetTickers) {
        selectedPreset = sector
        // if (this.state.selectedPreset === sector){
        //     selectedPreset = null
        // }
        // else{
        //     selectedPreset = sector
        // }
        this.setState({ selectedPreset, searchMomentumText: '' })
      }
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  // fetchPresetTickers = ()=>{
  //     let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
  //     fetch(url, {
  //         "Access-Control-Allow-Headers": {
  //             "Content-Type": "application/json",
  //             "Cache-Control": "no-cache",
  //             "Access-Control-Request-Headers": "*",
  //             "Access-Control-Allow-Origin": "*",
  //             "Access-Control-Allow-Methods": "*",
  //         },
  //     })
  //         .then((res) => res.json())
  //         .then((responseJson)=>{
  //             this.setState({presetTickers: responseJson.lists})
  //         })

  // }

  fetchCommonStocks = () => {
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
    let url = `${SearchEndpoint}/ticker-list`
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('keys', Object.keys(responseJson.lists))
        this.setState({
          presetTickers: responseJson.lists,
          themeDesc: responseJson.descriptions,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  createBox = (ele, index) => {
    let url = `/Dashboard/Trade/${ele.symbol.toUpperCase()}`
    if (this.props.isSmallScreen) {
      return (
        <NavLink
          exact={true}
          to={url}
          id={'PriceTrend_CompanyCard_' + index}
          style={{ textDecoration: 'none' }}
          key={index}
        >
          <div
            onMouseEnter={() => this.setState({ hover: ele })}
            onMouseLeave={() => this.setState({ hover: '' })}
            style={{
              height: 200,
              marginBottom: 10,
              marginRight: 15,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: 'solid',
              borderColor: '#dddddd',
              borderWidth: 1,
              backgroundColor:
                this.state.hover === ele
                  ? 'rgb(220,220,220)'
                  : 'rgb(243,243,243)',
              paddingLeft: 20,
              paddingRight: 20,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              marginTop: 10,
              cursor: 'pointer',
            }}
          >
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.hover === ele
                    ? 'rgb(225,225,225)'
                    : 'rgb(255,255,255)',
                borderRadius: '50%',
                overflow: 'hidden',
                minWidth: 125,
                minHeight: 125,
                justifySelf: 'flex-end',
                border: 'solid',
                borderWidth: '0.25px',
                borderColor: 'rgb(17,17,17)',
              }}
            >
              {ele.imageURL === 'not-found' ? (
                <div
                  style={{
                    objectFit: 'contain',
                    width: 50,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgb(17,17,17)',
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 24,
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    {ele.symbol[0]}
                  </p>
                </div>
              ) : (
                <img
                  // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                  src={ele.imageURL}
                  style={{
                    objectFit: 'contain',
                    width: 80,
                    height: 80,
                    padding: 10,
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 10,
              }}
            >
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#272748',
                }}
              >
                {ele.symbol}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#272748',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {ele.company}
              </p>
              {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
            </div>
          </div>
        </NavLink>
      )
    } else
      return (
        <NavLink
          exact={true}
          to={url}
          id={'PriceTrend_CompanyCard_' + index}
          style={{ textDecoration: 'none' }}
          key={index}
        >
          <div
            onMouseEnter={() => this.setState({ hover: ele })}
            onMouseLeave={() => this.setState({ hover: '' })}
            style={{
              display: 'flex',
              width: '100%',
              padding: '10px 0',
              backgroundColor:
                this.state.hover === ele ? 'rgb(225,225,225)' : 'transparent',
              alignItems: 'center',
              borderBottom: '.25px solid rgb(211,211,211)',
            }}
          >
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  minWidth: 85,
                  minHeight: 85,
                  justifySelf: 'flex-end',
                }}
              >
                {ele.imageURL === 'not-found' ? (
                  <div
                    style={{
                      objectFit: 'contain',
                      width: 50,
                      height: 50,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgb(17,17,17)',
                      borderRadius: 10,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 19,
                        fontFamily: 'Akkurat-Bold',
                        color: 'white',
                      }}
                    >
                      {ele.symbol[0]}
                    </p>
                  </div>
                ) : (
                  <img
                    src={ele.imageURL}
                    style={{
                      objectFit: 'contain',
                      width: 70,
                      height: 70,
                      marginLeft: 5,
                    }}
                  />
                )}
              </div>
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  marginLeft: 25,
                  fontWeight: 'bold',
                  color: '#272748',
                }}
              >
                {ele.symbol}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <p
                style={{
                  textDecoration: 'none',
                  color: '#272748',
                  fontSize: '16px',
                  alignItems: 'center',
                  display: 'flex',
                  marginBottom: 0,
                  marginLeft: '5px',
                }}
              >
                {this.props.formatCurrency(ele.priceUSD)}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  textDecoration: 'none',
                  color: '#272748',
                  fontSize: '16px',
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: '5px',
                  marginBottom: 0,
                }}
              >
                {this.getCurrentTrend(ele.direction)}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                justifyContent: 'right',
                alignContent: 'center',
              }}
            >
              <p
                style={{
                  textDecoration: 'none',
                  color: '#272748',
                  fontSize: '16px',
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: '5px',
                  marginBottom: 0,
                }}
              >
                {this.props.formatCurrency(ele.returnForecastValue)}
              </p>
            </div>
            {/* <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold', color:'rgb(17,17,17)' }}>{ele.symbol}</p>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold', color:'rgb(17,17,17)',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>{ele.company}</p>
                </div> */}
          </div>
        </NavLink>
      )
  }

  getCurrentTrend(direction) {
    switch (direction) {
      case -2:
        return (
          <img
            src={DoubleNegative}
            style={{ objectFit: 'contain', width: 60, height: 60, padding: 10 }}
          />
        )
      case -1:
        return (
          <img
            src={SingleNegative}
            style={{ objectFit: 'contain', width: 60, height: 60, padding: 10 }}
          />
        )
      case 0:
        return (
          <img
            src={Neutral}
            style={{ objectFit: 'contain', width: 60, height: 60, padding: 10 }}
          />
        )
      case 1:
        return (
          <img
            src={SinglePositive}
            style={{ objectFit: 'contain', width: 60, height: 60, padding: 10 }}
          />
        )
      case 2:
        return (
          <img
            src={DoublePositive}
            style={{ objectFit: 'contain', width: 60, height: 60, padding: 10 }}
          />
        )
      default:
        return null
    }
  }

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state
    this.setState({ isMomDataLoading: true })
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    let url = `${SearchEndpoint}/ticker?search=${searchMomentumText.toUpperCase()}`
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        this.setState({
          isMomDataLoading: false,
          momDataSource: data,
        })
      })
      .catch((err) => {
        this.setState({ isMomDataLoading: false })
        console.log(err)
      })
  }

  howToBox = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: '#F2F2F999',
          padding: 15,
          borderRadius: 15,
          width: '100%',
        }}
      >
        <a
          href='https://www.youtube.com/watch?v=8KNaR9o-Qik'
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            color: 'rgb(109,105,173)',
          }}
        >
          How to use the Stock Price Trend Predictor
        </a>
        {/* {this.state.showMore? */}
        <p style={{ fontSize: 14 }}>
          Try using the AllocateRite Stock Price Trend Predictor to help you
          with your short-term trading. Short-Term trading is highly risky, and
          a lot of traders based their trading on unreliable or outdated
          information. Instead, you can leverage the power of AllocateRite's
          Data Science and Predictive Analytics to help forecast short-term
          Price Trends.
          {/* <strong onClick={() => this.setState({ showMore: false })} style={{ color: '#00A99D', cursor: 'pointer', }}> Show less...</strong> */}
        </p>
        {/* :
                <p style={{fontSize: 14}}>
                        Try using the AllocateRite Stock Price Trend Predictor to help you with your short-term trading. Short-Term trading is highly risky, <strong onClick={() => this.setState({ showMore: true })} style={{ color: '#00A99D', cursor: 'pointer',}}> Show more...</strong>
                </p>} */}
      </div>
    )
  }

  individuleCompanyCard = (location, sector) => {
    let columns = window.innerWidth / 280
    let data
    if (location === 'search') {
      data = this.state.momDataSource
    } else {
      data = this.state.presetTickers[sector]
    }
    let mapped = data.map((item, index) => this.createBox(item, index))
    return (
      <div
        style={{
          padding: '2%',
          textDecoration: 'none',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {!this.props.isSmallScreen ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginBottom: '25px',
              color: '#272748',
              padding: '15px',
            }}
          >
            <div
              style={{
                width: '25%',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'left',
                alignContent: 'center',
                fontSize: '12px',
              }}
            >
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  color: '#272748',
                }}
              >
                {this.props.t('stocks')}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                fontSize: '12px',
              }}
            >
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  color: '#272748',
                }}
              >
                {this.props.t('currentprice')}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                fontSize: '12px',
              }}
            >
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  color: '#272748',
                }}
              >
                {this.props.t('currenttrend')}
              </p>
            </div>
            <div
              style={{
                width: '25%',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'right',
                alignContent: 'center',
                fontSize: '12px',
              }}
            >
              <p
                style={{
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  color: '#272748',
                }}
              >
                {this.props.t('priceforecast')} (1-2 {this.props.t('months')})
              </p>
            </div>
          </div>
        ) : null}
        <div
          style={{
            width: '100%',
            display: 'block',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: this.props.isSmallScreen ? 'center' : '',
          }}
        >
          {data.length > 0 ? mapped : <p>No Results Found</p>}
        </div>
      </div>
    )
  }

  appLinks() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: 50,
          marginTop: 10,
        }}
      >
        <a href='https://apps.apple.com/us/app/forescite/id1567589436'>
          <img
            className='features-store-download'
            style={{ height: 50, width: 'auto' }}
            src='../images/app_download_icon.png'
            alt='apple-store'
          />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.forescite'>
          <img
            className='features-store-download'
            style={{ height: 50, width: 'auto' }}
            src='../images/google_download_icon.png'
            alt='google-store'
          />
        </a>
      </div>
    )
  }

  presetIcons = () => {
    const { isSmallScreen } = this.props
    if (isSmallScreen) {
      if (this.state.presetTickers) {
        let arr = ['SelectTheme', ...Object.keys(this.state.presetTickers)]
        let mapped = []
        arr.forEach((option, index) => {
          if (this.state.itemHashSet.has(option)) {
            mapped.push(
              <option
                id={'TickerCategory_' + index + '_Small'}
                value={option}
                selected={this.state.theme == option}
              >
                {this.props.t(
                  THEMETITLE[option].toLowerCase().replace(/\s/g, '')
                )}
              </option>
            )
          }
        })
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <select
              id='PriceTrend_Select_Small'
              placeholder='Select Below'
              onChange={(e) => this.setSelectedPreset(e.target.value)}
              value={this.state.selectedPreset}
            >
              {mapped}
            </select>
          </div>
        )
      } else {
        return null
      }
    } else {
      if (this.state.presetTickers) {
        let arr = [...Object.keys(this.state.presetTickers)]
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {/* <p style={{margin:'auto',width:'100%', color:'#878b95', textAlign:'center', color:'rgb(82,140,139)', marginBottom:8, fontWeight:'bold'}}>Investing Themes</p> */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                width: '100%',
                margin: 'auto',
                marginBottom: 5,
              }}
            >
              {arr.map((ticker, index) => {
                if (this.state.itemHashSet.has(ticker)) {
                  return (
                    <div
                      id={'TickerCategory_' + index}
                      style={{
                        display: 'flex',
                        background:
                          this.state.selectedPreset === ticker
                            ? '#3F46F6'
                            : '#D8D8D8',
                        cursor: 'pointer',
                        borderRadius: '15px',
                        padding: '5px 15px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 2,
                      }}
                      onClick={() => this.setSelectedPreset(ticker)}
                      key={index}
                    >
                      {/* <img
                                                src={THEMEIMG[ticker]}
                                                style={{height: 20, marginRight: 10}}
                                                /> */}
                      <p
                        style={{
                          margin: 0,
                          color:
                            this.state.selectedPreset === ticker
                              ? 'white'
                              : 'grey',
                          fontSize: 12,
                        }}
                      >
                        {this.props.t(
                          THEMETITLE[ticker].toLowerCase().replace(/\s/g, '')
                        )}
                      </p>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        )
      } else {
        return null
      }
    }
  }

  showTickers = (sector) => {
    const { isSmallScreen, isMomCurSelected } = this.state

    let loadingdiv = (
      <div
        style={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>loading...</p>
      </div>
    )
    return (
      <Card
        style={{
          width: '100%',
          marginTop: 20,
          backgroundColor: '#f6f6f6',
          border: 'none',
        }}
      >
        {/* <div style={{display: 'flex',flexDirection: 'column', alignItems: 'center',justifyContent:'center' }}>
                    {this.state.selectedPreset && !this.props.isSmallScreen?
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold',fontSize:'28px',textAlign:'center'}}>
                        {THEME[this.state.selectedPreset]}
                    </p>
                    :null}
                    {this.state.selectedPreset && !this.props.isSmallScreen?
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'normal',fontSize:'22px',textAlign:'center'}}>
                        {this.state.themeDesc[this.state.selectedPreset]}
                    </p>
                    :null}
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'normal'}}>
                        Select a ticker for more information 
                    </p>
                </div> */}
        {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
                </div>*/}
        <div>
          {this.individuleCompanyCard('x', sector)}
          {/* <SearchCard /> */}
        </div>
      </Card>
    )
  }

  trendPredictordiv = () => {
    const {
      isMomDataLoading,
      momDataSource,
      searchMomentumText,
      isSmallScreen,
      isMomCurSelected,
    } = this.state

    return (
      <Card
        style={{
          width: '100%',
          marginTop: 5,
          backgroundColor: '#f6f6f6',
          border: 'none',
        }}
      >
        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        select a ticker for more information
                    </p>
                </div> */}
        <div>{this.displayMomResults()}</div>
      </Card>
    )
  }

  displayMomResults = () => {
    const {
      isMomDataLoading,
      momDataSource,
      searchMomentumText,
      isSmallScreen,
      isMomCurSelected,
    } = this.state
    if (isMomDataLoading === false && momDataSource.length > 0) {
      return this.individuleCompanyCard('search', '')
    } else if (isMomDataLoading === false) {
      return (
        <div
          style={{
            height: 300,
            paddingTop: isSmallScreen ? 80 : null,
            paddingBottom: isSmallScreen ? 80 : null,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h5
            style={{
              margin: isSmallScreen ? 40 : 20,
              color: '#808080',
              fontSize: isSmallScreen ? 15 : 18,
            }}
          >
            {searchMomentumText.length < 2
              ? null
              : this.props.t('noresultsfound')}
          </h5>
        </div>
      )
    } else {
      return (
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>loading...</p>
        </div>
      )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ backgroundColor: '#f6f6f6' }}>
          <div
            style={{
              paddingTop: 10,
              // backgroundColor: '#F2F2F999',
              height: '100%',
              width: '100%',
              borderRadius: '50px',
              backgroundColor: '#f6f6f6',
            }}
          >
            {/* <div style={{display:'flex', backgroundColor: 'transparent', justifyContent:'center',alignItems:'center', marginBottom:20, marginTop: 20,padding: this.props.isSmallScreen? 10:null  }}>
                            <p style={{margin:0, fontSize: 24, textAlign:'center'}}>{this.props.t('trade')}</p>
                       </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                margin: 'auto',
                flex: 1,
                backgroundColor: 'transparent',
                alignItems: 'flex-start',
              }}
            >
              <div
                // style={{
                //     backgroundColor: '#f6f6f6', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(220,220,220)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250,
                // }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  width: this.state.isSmallScreen ? '80%' : '40%',
                  borderRadius: 20,
                  backgroundColor: 'rgb(231,232,233)',
                  paddingLeft: this.state.isSmallScreen ? 0 : 20,
                  padding: this.state.isSmallScreen ? 8 : '',
                  margin: this.state.isSmallScreen ? '25px auto' : '25px 0',
                  paddingRight: 15,
                }}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                {/* <div style={{ minWidth: 20, width: '5%', justifyContent: "center", display: 'flex', alignItems: 'center', backgroundColor: 'transparent'}}>
                                    <FaSearch size={'50%'} style={{color: '#ddd'}} />                        
                                </div> */}
                <FaSearch
                  style={{
                    color: '#ddd',
                    width: '25px',
                    height: '25px',
                    margin: '0 10px',
                  }}
                />
                <input
                  id='PriceTrend_Search'
                  type='text'
                  style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    paddingTop: 5,
                    paddingBottom: 5,
                    backgroundColor: 'transparent',
                  }}
                  value={this.state.searchMomentumText}
                  onChange={(event) => {
                    let searchMomentumText = event.target.value
                    if (searchMomentumText === '') {
                      this.setState({
                        searchMomentumText,
                        selectedPreset: 'most_active',
                      })
                    } else {
                      this.setState(
                        { searchMomentumText, selectedPreset: null },
                        () => {
                          const { isSearching } = this.state
                          if (isSearching) {
                            return
                          } else {
                            this.setState({ isSearching: true })
                            setTimeout(() => {
                              this.setState(
                                { isSearching: false },
                                this.onSubmitSearchMomentum
                              )
                            }, 2000)
                          }
                        }
                      )
                    }
                  }}
                  onFocus={() => {
                    this.setState({ showTrendPredictor: true })
                  }}
                  placeholder={this.props.t('search')}
                  ref={this.input}
                />
                {/* <p
                  onClick={() => {
                    this.setState({
                      searchMomentumText: '',
                      selectedPreset: 'most_active',
                    })
                  }}
                  style={{
                    color: 'grey',
                    fontsize: 5,
                    margin: 0,
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                >
                  X
                </p> */}
                <img
                  src={XIcon}
                  width={20}
                  height={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      searchMomentumText: '',
                      selectedPreset: 'most_active',
                    })
                  }}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {this.presetIcons()}
              </div>
            </div>
            {this.state.momDataSource || this.state.selectedPreset ? null : (
              <div
                style={{
                  width: '80%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: 40,
                  marginBottom: 10,
                }}
              >
                {this.howToBox()}
              </div>
            )}
          </div>
          <div style={{ width: '100%', margin: 'auto' }}>
            {this.state.selectedPreset && this.state.presetTickers
              ? this.showTickers(this.state.selectedPreset)
              : null}
            {this.state.searchMomentumText.length > 0
              ? this.trendPredictordiv()
              : null}
          </div>
          <Disclaimer />
        </div>
      )
    } else return null
  }
}
