import React from 'react'
import { NavLink } from 'react-router-dom'
import upArrow from '../../assets/single_up.svg'
import downArrow from '../../assets/single_down.svg'
import strongUpArrow from '../../assets/double_up.svg'
import strongDownArrow from '../../assets/double_down.svg'
import flatArrow from '../../assets/flat.svg'

export default class SearchCard extends React.Component {
  state = {
    hover: false,
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return downArrow
      case 1:
        return upArrow
      case 2:
        return strongUpArrow
      case -2:
        return strongDownArrow
      default:
        return flatArrow
    }
  }

  render() {
    // console.log('SC state:', this.state)
    // console.log('SC props:', this.props)

    return (
      <NavLink
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        to={`/Dashboard/Trade/${this.props.item.symbol}`}
        style={{
          backgroundColor: this.state.hover ? '#dddddd' : 'rgb(255,255,255)',
          width: '100%',
          borderRadius: 12,
          marginBottom: 15,
          height: 200,
        }}
      >
        <div style={{ padding: 12 }}>
          <div style={{}}>
            <p
              style={{
                margin: 0,
                color: '#242a38',
                fontSize: 24,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.props.item.symbol}
            </p>
          </div>
          <div style={{}}>
            <p
              style={{
                margin: 0,
                color: '#242a38',
                fontSize: 16,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.props.item.company}
            </p>
          </div>
          <div style={{}}>
            <p
              style={{
                margin: 0,
                color: '#878b95',
                fontSize: 14,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.props.item.industry}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <img
              src={this.getArrow(this.props.item.direction)}
              style={{ height: 20, marginRight: 7 }}
            />
            <p style={{ margin: 0, color: '#242a38', fontSize: 24 }}>
              {this.props.item.priceUSD
                ? `$${this.props.item.priceUSD.toFixed(2)}`
                : null}
            </p>
          </div>
        </div>
        {/* <div style={{width:'100%', backgroundImage:'linear-gradient(to bottom, #3b8e8c 2%, rgba(59, 142, 140, 0) 94%);'}}>
                    GRAPH
                </div> */}
      </NavLink>
    )
  }
}
