//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import { Dropdown } from 'primereact/dropdown'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/TransactionsHistory.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function TransactionsHistory() {
  const { t, budgetingData } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const [selected, setSelected] = useState('Income')

  const tabs = () => {
    return (
      <div className='transactions-history-tabs'>
        {details?.transactions?.categories?.map((item, index) => {
          return (
            <div
              className='transactions-history-tab'
              key={index}
              onClick={() => {
                setSelected(item.name)
              }}
            >
              <span
                className={`${
                  selected === item.name
                    ? 'transactions-history-tab-label selected'
                    : 'transactions-history-tab-label'
                }`}
              >
                {t(item.name?.toLowerCase()?.replace(/ /g, '_'))}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  const flipCategory = (category) => {
    return (
      category === 'Income' ||
      category === 'Transfer In' ||
      category === 'Loan Payments' ||
      category === 'Bank Fees'
    )
  }

  const getOptions = () => {
    const options = []
    details?.transactions?.categories?.forEach((item) => {
      options.push({
        value: item.name,
        label: t(item.name?.toLowerCase()?.replace(/ /g, '_')),
      })
    })
    return options
  }

  let i = 0

  return (
    <div className='transactions-history'>
      {/* <p className='transactions-title'>{t('transactions')}</p> */}
      <div className='shadow-card'>
        {/* {tabs()} */}
        <div className='tab-container'>
          <Dropdown
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value)
            }}
            options={getOptions()}
            style={{ width: 250 }}
          />
        </div>
        <div className='transactions-table-values'>
          {details?.transactions?.transactionDetails?.map((item, index) => {
            if (item.category === selected) {
              i++
              return (
                <div className='transactions-table-value' key={index}>
                  <div className='budgeting-row'>
                    <p className='transactions-row-value blue-text'>
                      {`${item.source}-${item.mask}`}
                    </p>
                    <p className='transactions-row-value'>{item.date}</p>
                  </div>
                  <div className='budgeting-row'>
                    <p className='transactions-row-value light-text'>
                      {item.sub_category}
                    </p>
                    <p className='transactions-row-value'>
                      {flipCategory(item?.category)
                        ? formatCurrency(item?.amount * -1)
                        : formatCurrency(item?.amount)}
                    </p>
                  </div>
                  <p className='transactions-row-value no-margin'>
                    {item.name}
                  </p>
                </div>
              )
            }
          })}
          {i === 0 && (
            <div className='no-msg-container'>
              <p className='no-msg'>{t('no_transactions')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
