import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import Axios from 'axios'
import '../../scss/components/SearchAndAddDialog.scss'
import SearchEndpoint from '../SearchEndpoint'
import DoubleNegative from '../../assets/double-negative.png'
import SingleNegative from '../../assets/single-negative.png'
import Neutral from '../../assets/neutral.png'
import SinglePositive from '../../assets/single-positive.png'
import DoublePositive from '../../assets/double-positive.png'
export default class SearchAndAddDialog extends Component {
  state = {
    searchText: '',
    searchResults: [],
  }

  handleSearchTextChange = (value) => {
    this.setState({ searchText: value }, () => {
      this.searchResults()
    })
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return SingleNegative
      case 1:
        return SinglePositive
      case 2:
        return DoublePositive
      case -2:
        return DoubleNegative
      default:
        return Neutral
    }
  }

  searchResults = async () => {
    await Axios.get(
      `https://api.allocaterite.com/search/ticker?search=${this.state.searchText.toUpperCase()}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    ).then((response) => {
      if (response?.data?.content) {
        this.setState({ searchResults: response.data.content })
      }
    })
  }

  getRow(item) {
    return (
      <div
        className='item-row'
        onClick={() => {
          this.add(item)
        }}
      >
        <div className='add-crypto-row-container'>
          <div className='img-container'>
            {item.imageURL === 'not-found' ? (
              <div className='not-found'>{item.symbol.charAt(0)}</div>
            ) : (
              <img src={item.imageURL} className='image' />
            )}
          </div>
          <div className='info'>
            <p className='symbol'>{item.symbol}</p>
            <p className='company'>{item.company}</p>
          </div>
        </div>
        <img
          src={this.getArrow(item.direction)}
          style={{
            width: 20,
            height: 20,
          }}
        />
      </div>
    )
  }

  add = (item) => {
    this.props.toggleAddDialog()
    this.props.add(item)
  }

  render() {
    if (this.props.t) {
      return (
        <Dialog
          visible={this.props.addDialog}
          header={this.props.t(`adda${this.props.type}toaitrader`)}
          onHide={() => this.props.toggleAddDialog()}
          style={{
            width: this.props.isSmallScreen ? '90vw' : '30vw',
            height: '50vh',
          }}
        >
          <p className='error-message'></p>
          <InputText
            value={this.state.searchText}
            onChange={(e) => this.handleSearchTextChange(e.target.value)}
            placeholder={this.props.t('searchbysymbol')}
          />
          {this.state.searchResults.map((item) => {
            if (this.props.type === 'crypto' && item.type === 'Crypto')
              return this.getRow(item)
            if (this.props.type === 'stock' && item.type !== 'Crypto')
              return this.getRow(item)
          })}
        </Dialog>
      )
    } else return null
  }
}
