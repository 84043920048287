//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/ExternalAccountsList.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function ExternalAccountsList() {
  const { t, budgetingData, setSelectedAccount, setPage } =
    useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const [externalAccounts, setExternalAccounts] = useState({})

  useEffect(() => {
    categorizeExternalAccounts()
  }, [])

  const categorizeExternalAccounts = () => {
    const loans = details?.finStat?.liability?.loan
    const creditCards = details?.finStat?.liability?.credit
    const brokerages = []
    details?.finStat?.asset?.investment?.forEach((item) => {
      if (
        item.accountDetails?.source !== 'DriveWealth' &&
        item.accountDetails?.source !== 'Apex'
      ) {
        brokerages.push(item)
      }
    })
    const depositoryAccounts = details?.finStat?.asset?.depository
    const properties = []
    const vehicles = []
    const valuables = []
    details?.properties?.forEach((item) => {
      if (item.type === 'home') {
        properties.push(item)
      } else if (item.type === 'auto') {
        vehicles.push(item)
      } else if (item.type === 'valuable') {
        valuables.push(item)
      }
    })

    setExternalAccounts({
      loans,
      creditCards,
      brokerages,
      depositoryAccounts,
      properties,
      vehicles,
      valuables,
    })
  }

  const depositoryAccounts = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.depositoryAccounts?.map((item, index) => {
          return (
            <div
              className='shadow-card external-card'
              key={index}
              onClick={() => {
                setSelectedAccount(item)
                setPage((prevState) => [...prevState, 'external-account'])
              }}
            >
              <div className='budgeting-row'>
                <p className='budgeting-label no-margin'>{`${item.accountDetails?.source} ${item.accountDetails?.mask}`}</p>
                <p className='budgeting-value no-margin'>
                  {formatCurrency(item.amount)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const creditCards = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.creditCards?.map((item, index) => {
          return (
            <div
              className='shadow-card external-card'
              key={index}
              onClick={() => {
                setSelectedAccount(item)
                setPage((prevState) => [...prevState, 'external-account'])
              }}
            >
              <div className='budgeting-row'>
                <p className='budgeting-label no-margin'>{`${item.accountDetails?.source} ${item.accountDetails?.mask}`}</p>
                <p className='budgeting-value no-margin'>
                  {formatCurrency(item.amount)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const loans = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.loans?.map((item, index) => {
          return (
            <div
              className='shadow-card external-card'
              key={index}
              onClick={() => {
                setSelectedAccount(item)
                setPage((prevState) => [...prevState, 'external-account'])
              }}
            >
              <div className='budgeting-row'>
                <p className='budgeting-label no-margin'>{`${item.accountDetails?.source} ${item.accountDetails?.mask}`}</p>
                <p className='budgeting-value no-margin'>
                  {formatCurrency(item.amount)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const brokerages = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.brokerages?.map((item, index) => {
          if (
            item.accountDetails?.source !== 'DriveWealth' &&
            item.accountDetails?.source !== 'Apex'
          ) {
            return (
              <div
                className='shadow-card external-card'
                key={index}
                onClick={() => {
                  setSelectedAccount(item)
                  setPage((prevState) => [...prevState, 'external-account'])
                }}
              >
                <div className='budgeting-row'>
                  <p className='budgeting-label no-margin'>{`${item.accountDetails?.source} ${item.accountDetails?.mask}`}</p>
                  <p className='budgeting-value no-margin'>
                    {formatCurrency(item.amount)}
                  </p>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  const properties = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.properties?.map((item, index) => {
          if (item.type === 'home') {
            return (
              <div
                className='shadow-card external-card'
                key={index}
                onClick={() => {
                  setSelectedAccount(item)
                  setPage((prevState) => [...prevState, 'external-account'])
                }}
              >
                <div className='budgeting-row'>
                  <p className='budgeting-label no-margin'>{item.nickname}</p>
                  <p className='budgeting-value no-margin'>
                    {formatCurrency(item.estimated_value)}
                  </p>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  const vehicles = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.vehicles?.map((item, index) => {
          if (item.type === 'auto') {
            return (
              <div
                className='shadow-card external-card'
                key={index}
                onClick={() => {
                  setSelectedAccount(item)
                  setPage((prevState) => [...prevState, 'external-account'])
                }}
              >
                <div className='budgeting-row'>
                  <p className='budgeting-label no-margin'>{item.nickname}</p>
                  <p className='budgeting-value no-margin'>
                    {formatCurrency(item.estimated_value)}
                  </p>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  const valuables = () => {
    return (
      <div className='external-account-cards'>
        {externalAccounts?.valuables?.map((item, index) => {
          if (item.type === 'valuable') {
            return (
              <div
                className='shadow-card external-card'
                key={index}
                onClick={() => {
                  setSelectedAccount(item)
                  setPage((prevState) => [...prevState, 'external-account'])
                }}
              >
                <div className='budgeting-row'>
                  <p className='budgeting-label no-margin'>{item.nickname}</p>
                  <p className='budgeting-value no-margin'>
                    {formatCurrency(item.estimated_value)}
                  </p>
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }

  const digitalWallets = () => {
    return (
      <div className='external-account-cards'>
        {budgetingData?.digital_wallets?.map((item, index) => {
          return (
            <div
              className='shadow-card external-card'
              key={index}
              onClick={() => {
                setSelectedAccount({ ...item, type: 'wallet' })
                setPage((prevState) => [...prevState, 'external-account'])
              }}
            >
              <div className='budgeting-row'>
                <p className='budgeting-label no-margin'>{item?.nickname}</p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='external-accounts-list'>
      {externalAccounts?.depositoryAccounts?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('depository')}</p>
          {depositoryAccounts()}
        </div>
      )}

      {externalAccounts?.creditCards?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('credit')}</p>
          {creditCards()}
        </div>
      )}

      {externalAccounts?.loans?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('loans')}</p>
          {loans()}
        </div>
      )}

      {externalAccounts?.brokerages?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('brokerages')}</p>
          {brokerages()}
        </div>
      )}

      {externalAccounts?.properties?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('properties')}</p>
          {properties()}
        </div>
      )}

      {externalAccounts?.vehicles?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('vehicles')}</p>
          {vehicles()}
        </div>
      )}
      {externalAccounts?.valuables?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('valuables')}</p>
          {valuables()}
        </div>
      )}
      {budgetingData?.digital_wallets?.length > 0 && (
        <div className='external-accounts-section'>
          <p className='external-accounts-header'>{t('digital_wallets')}</p>
          {digitalWallets()}
        </div>
      )}
    </div>
  )
}
