import React, { Component } from 'react'
import PL from '../PL'
import '../../../../scss/pages/account-page/goals-account-info/GoalsBasicInfo.scss'

export default class GoalsBasicInfo extends Component {
  getGoalsBasicInfo() {
    const name = this.props.selectedAccount.name
      ? this.props.selectedAccount.name
      : '-'
    const totalAccountValue =
      this.props.selectedAccount.balances[0].virtualAccountValue !== null
        ? this.props.selectedAccount.balances[0].virtualAccountValue.toFixed(2)
        : '-'
    const equityValue =
      this.props.selectedAccount.balances[0].equityValue !== null
        ? this.props.selectedAccount.balances[0].equityValue.toFixed(2)
        : '-'
    const netPL = this.props.productInstanceData?.profit?.toFixed(2)

    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
        }}
        className='goals-basic-info-container'
      >
        <p className='goals-name'>
          {this.props.t(this.props.selectedName)} (
          {this.props.selectedAccount?.name_unmasked})
        </p>
        <p className='goals-value'>
          {this.props.formatCurrency(totalAccountValue)}
        </p>
        <div className='details-container'>
          {' '}
          <div className='pl-container'>
            <p className='pl-label'>{this.props.t('pl')}</p>
            {netPL === '-' ? (
              <p className='pl-value'>{'-'}</p>
            ) : (
              <PL formatCurrency={this.props.formatCurrency} value={netPL} />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.t) return <div>{this.getGoalsBasicInfo()}</div>
    else return null
  }
}
