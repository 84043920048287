//REACT
import React from 'react'

export default function CustomImage({ label, containerStyle, labelStyle }) {
  //PROPS
  //label: capital first letter
  return (
    <div style={containerStyle}>
      <p style={labelStyle}>{label}</p>
    </div>
  )
}
