import React from "react";
import "../scss/components/Disclaimer.scss";

export default function Disclaimer(props) {
  if (props.t) {
    return (
      <div className="disclaimer-container">
        <p className="disclaimer-heading">{props.t("disclaimer")}:</p>
        <p className="disclaimer-text">{props.t("disclaimerdescription")}</p>
      </div>
    );
  } else return null;
}
