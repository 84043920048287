import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { LandingPageContext } from '.'
import BarChart from '../../../assets/zero-emotion-bar-chart.png'
import { handleAppRedirect } from '../../../utility-functions/handleAppRedirect'
import { Dialog } from 'primereact/dialog'
import BrexitVote from '../../../assets/brexit-vote.png'
import FinancialCrisis from '../../../assets/financial-crisis.png'
import PresidentialElection from '../../../assets/presidential-election.png'
import MarchChart from '../../../assets/march-chart.png'
import DecChart from '../../../assets/dec-chart.png'

export default function ZeroEmotions() {
  const { t } = useContext(LandingPageContext)
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general
  const [showImages, setShowImages] = useState(false)
  return (
    <div className='zero-emotions'>
      <div className='zero-emotions-content'>
        <p className='zero-header'>{t('tradewithzeroemotion')}</p>
        <div className='zero-content'>
          <div className='zero-left'>
            <div className='zero-top'>
              <p className='left-sub-header'>{t('tradewithzeroemotioninfo')}</p>
            </div>
            {!isSmallScreen && (
              <div className='zero-bottom'>
                <button
                  className='zero-btn'
                  onClick={() => {
                    handleAppRedirect()
                  }}
                >
                  {t('gettheapp')}
                </button>
              </div>
            )}
          </div>
          <div className='zero-right'>
            <p className='right-title'>{t('automatedaiinvestor')}</p>
            <img src={BarChart} className='zero-bar-chart' />
            <p
              className='right-footer'
              onClick={() => {
                setShowImages(true)
              }}
            >
              {t('seeinvestallshistoricalmarketforecast')}
            </p>
            {isSmallScreen && (
              <button
                className='zero-btn'
                onClick={() => {
                  handleAppRedirect()
                }}
              >
                {t('gettheapp')}
              </button>
            )}
          </div>
        </div>
      </div>
      <Dialog
        visible={showImages}
        onHide={() => setShowImages(false)}
        style={{
          width: isSmallScreen ? '90vw' : '80vw',
          maxHeight: '80vh',
        }}
        header={t('investallshistoricalmarketforecast')}
        className='images-dialog'
      >
        <>
          <div className='images'>
            <div className='image-container'>
              <img src={FinancialCrisis} className='image' />
            </div>
            <div className='image-container'>
              <img src={PresidentialElection} className='image' />
            </div>
            <div className='image-container'>
              <img src={BrexitVote} className='image' />
            </div>
            <div className='image-container'>
              <img src={MarchChart} className='image' />
            </div>
            <div className='image-container'>
              <img src={DecChart} className='image' />
            </div>
          </div>
          {/* <p className='small-disclaimer'>{t('disclaimerdescription')}</p> */}
          <div className='custom-legend'>
            <p className='legend ar'>
              {'AllocateRite US Domestic Composite (ARUSDOM)'}
            </p>
            <p className='legend sp'>{'S&P 500 Index'}</p>
          </div>
        </>
      </Dialog>
    </div>
  )
}
