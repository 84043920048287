import React, { Component } from "react";
import { Container } from "react-bootstrap";
import ElementOfPlatfrom from "../ElementOfPlatfrom";

class ChineseElementsOfPlatfrom extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      isSmallScreen: false,
      elements: [
        {
          id: 1,
          isLeft: true,
          title: `核心`,
          iconUrl: `/images/artificial-intelligence.png`,
          subtitle: `AI驱动的投资策略`,
          text: `AllocateRite平台背后的驱动力是其动态资产配置模型，该模型由专有的AI算法提供支持。`,
          details: [
            {
              id: 1,
              title: `更全面的了解市场信息`,
              text: `我们的技术通过使用一系列的技术分析，基本面分析和相对价值指标，结合市场实际情况，吸收并评估​​大量的市场数据。在这个基础上，我们的算法综合配置投资组合并做出一系列关于风险与收益的预测。`
            },
            {
              id: 2,
              title: `人工智能驱动的全局宏算法以提高灵活性和速度`,
              text: `我们的分配决策优先考虑资本保值，目标是实现70％的上涨和30％的下跌，以产生更平稳（更强劲）的长期业绩。为此，我们利用ETF的灵活性，流动性和低价格的特点以极低的成本提供最大的可操作性。`
            },
            {
              id: 3,
              title: `基于未来去优化投资组合，而不是过去`,
              text: `我们不依赖基于收益分配的假设或者依赖事物“固有“的运行方式。相反，我们展望未来的风险和机遇 – 并帮助投资者避免静态的，“向后看”的投资管理模式的缺陷。`
            },
            {
              id: 4,
              title: `聚焦风险分析`,
              text: `风险是持续 – 风险分析也应该如此。我们基于算法的自适应智能提供积极主动的风险监控和严格的投资决策执行，帮助引导投资组合（和个人）在持续动荡的市场中获得收益。`
            }
          ]
        },
        {
          id: 2,
          isLeft: false,
          title: `优势`,
          iconUrl: `/images/robot-arm.png`,
          subtitle: `自动的功能封装平台`,
          text: `我们的所有策略封装在一个直观的平台上，与您的业务无缝集成，简化操作并自动执行关键性功能。`,
          details: [
            {
              id: 1,
              title: `便捷的申请`,
              text: `今天就开始更聪明地投资。我们的申请流程可帮助您便捷的创建账户并开始使用AllocateRite投资平台。`
            },
            {
              id: 2,
              title: `完整的经纪人整合`,
              text: `我们坚持为客户提供充分的灵活性来控制您的投资和资金。通过FIS Broker / RIA Connect平台，在2500家托管人和经纪商中选择您更偏好的供应商。`
            },
            {
              id: 3,
              title: `定制化的投资组合构建`,
              text: `使用我们的模型或选择最适合您商业模式的基金系列或特定ETF。我们的平台会自动回溯您的选择，以确保与AllocateRite战略的兼容性。`
            },
            {
              id: 4,
              title: `自动化贸易再平衡`,
              text: `您指定每个账户内的总投资资金，我们的分配引擎会优化交易订单以帮助您或您的客户的最佳执行。`
            },
            {
              id: 5,
              title: `全面的组合报告`,
              text: `在需要时获取所需的信息。我们的投资组合报告平台可让您深入了解交易表现，最佳执行，回报与基准，风险指标以及风险调整后的回报。`
            },
            {
              id: 6,
              title: `信息资源中心`,
              text: `建立每月的市场评论，投资收益报告，白皮书和演示文稿。`
            },
            {
              id: 7,
              title: `灵活的税务优化`,
              text: `ETF本身具有税收效率，我们会根据税收效率选择适合的投资。但是我们的系统可以进一步的让您从优化选项菜单中选择您的税收服务，包括完全自动化的基于AI的解决方案。`
            },
            {
              id: 8,
              title: `区块链存储`,
              text: `为进一步提高透明性和安全性，所有资产配置信息将被存储到使用一致性和密码算法的AllocateRite 区块链中。`
            }
          ]
        },
        {
          id: 3,
          isLeft: true,
          title: `动力`,
          iconUrl: `/images/strategy.png`,
          subtitle: `长期财富管理`,
          text: `我们的平台和策略共同为寻求精简并加强业务的个人投资者和顾问提供关键性便利。`,
          details: [
            {
              id: 1,
              title: `针对个人投资者的高科技风险管理`,
              text: `我们专有的算法优先考虑保本，并绕过传统投资策略（和人性）的缺点。这是一个合理的策略。我们认为，较少的假设和较少的情绪意味着更明智的决策和更快的反应时间，而更少的下行意味着投资者可以在需要时保持储蓄 – 无论是现在还是未来30年。`
            },
            {
              id: 2,
              title: `对风险的反应已经过时：从现在开始，你可以预见它`,
              text: `我们的风险管理流程持续检查当前风险环境下的分配和投资决策。这意味着持续的实时风险监测和动态分配建议。通过积极监测和管理风险，我们相信投资组合可以在潜在问题成为全面危机之前进行调整。`
            },
            {
              id: 3,
              title: `我们的技术是专有的，但我们的过程是一本开放的书`,
              text: `我们坚信我们的结果：我们的回测是经过审核的，并且每年都会收到GIPS认证。我们也将控制权交到您手中：我们永远不会监管或控制客户资金。并且通过我们的经理人和托管网络，您可以将资产保留在任何最便利的地方，并以对您业务合理的方式接收交易信号。`
            },
            {
              id: 4,
              title: `我们帮助您利用ETF的优势为您的企业服务`,
              text: `ETF的成本很低，但某些基金家族可能对您的业务更具优势。我们的技术可以帮助您使用基金系列甚至是您喜欢的特定基金来量身定制每个投资组合。我们会自动运行反向测试，以确保您的选择优化您的风险管理选项。`
            },
            {
              id: 5,
              title: `保持流动性：风险管理的终极武器`,
              text: `流动性不仅仅是通过减少价差来帮助管理成本，它还是一种强大的缓冲风险的手段。当市场下跌时，操纵能力至关重要，ETF的流动性为投资者提供了具有风险管理的长期回报，且不会不必要地锁定资产。对于个人投资者来说，这意味着更好的全面风险管理 – 不仅仅是针对市场，而且针对经济周期本身的起伏。`
            }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ isSmallScreen: true });
    }
  }

  render() {
    return (
      <Container>
        {this.state.elements.map(element => (
          <ElementOfPlatfrom
            key={element.id}
            value={element}
            isSmallScreen={this.state.isSmallScreen}
          />
        ))}
      </Container>
    );
  }
}

export default ChineseElementsOfPlatfrom;
