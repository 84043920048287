import React from 'react'
import '../../../scss/pages/account-page/AccountStatusMessage.scss'
export default function AccountStatusMessage({ status, t }) {
  const getMessage = () => {
    switch (status) {
      case 'PENDING':
        return (
          <div className='account-status-msg'>
            <p className='message'>{t('youraccountisinpendingstatus')}</p>
          </div>
        )
      case 'OPEN_NO_NEW_TRADES':
        return (
          <div className='account-status-msg'>
            <p className='message'>{t('youraccountisonliquidateonlymode')}</p>
          </div>
        )
      case 'OPEN_LIQUIDATE_ONLY':
        return (
          <div className='account-status-msg'>
            <p className='message'>{t('youraccountisonliquidateonlymode')}</p>
          </div>
        )
      default:
        return null
    }
  }

  return getMessage()
}
