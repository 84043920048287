import React, { Component } from "react";
import { Card, Row, Button, Form, } from "react-bootstrap";
import { FaArrowLeft, FaSearch, FaTimes, FaQuestionCircle } from "react-icons/fa";
import SingleCompanyCard from "../singleCompanyCard"
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Youtube from 'react-youtube'
import Comms_list from "../../assets/homeIcons/comms_list.svg"
import Crypto  from "../../assets/homeIcons/crypto.svg"
import Recovery  from "../../assets/homeIcons/recovery.svg"
import Semiconductor  from "../../assets/homeIcons/semiconductor.svg"
import Japan  from "../../assets/homeIcons/japan.svg"
import India  from "../../assets/homeIcons/india.svg"
import Germany  from "../../assets/homeIcons/germany.svg"
import China  from "../../assets/homeIcons/china.svg"
import Brazil  from "../../assets/homeIcons/brazil.svg"
import ARlogo from "../../assets/ar_logo.png"

import ENDPOINT from '../Endpoint'

const brandColor = "#00A99D"

export default class PriceTrendPredictor extends Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.state = {
            searchMomentumText: "",
            isSmallScreen: false,
            momDataSource: [],
            isMomDataLoading: false,
            showMore: false,
            selectedPreset: null,
            presetTickers: null,
            showVideo: false,
        };
    }

    componentDidMount() {
        this.fetchPresetTickers()
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        this.input.current.focus();
        // document.title = `AllocateRite - Search`;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    setSelectedPreset = (sector)=>{
        let selectedPreset
        if (this.state.presetTickers) {
            if (this.state.selectedPreset === sector){
                selectedPreset = null
            } 
            else{
                selectedPreset = sector
            }
            this.setState({selectedPreset})
        }
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
            this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
            this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
    };

    fetchPresetTickers = ()=>{
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson)=>{
                this.setState({presetTickers: responseJson.lists})
            })
        
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // console.log(searchMomentumText);
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;

        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                // console.log("SEARCHING RESULT: " , data);

                for (var i = 0; i < data.length; i++) {
                    var item = data[i];
                    var color = "";
                    if (item.rar) {
                        switch (item.rar) {
                            case "H":
                                color = "#dc143c";
                                break;
                            case "M":
                                color = "#fcac0c";
                                break;
                            default:
                                color = "#00A99D";
                        }
                    } else {
                        color = "rgb(0,0,0)";
                    }

                    item.symbolColor = color;
                    data[i] = item;
                }
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    howToBox = ()=>{
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: '#F2F2F999', padding:15, borderRadius: 15, width: '100%'}}>
                <a href='https://www.youtube.com/watch?v=8KNaR9o-Qik' style={{ fontSize: 14, fontWeight: 'bold', color: '#00A99D'}}>How to use the Stock Price Trend Predictor</a>
                {this.state.showMore?
                <p style={{fontSize: 14}}>
                        Try using the AllocateRite Stock Price Trend Predictor to help you with your short-term trading. Short-Term trading is highly risky, and a lot of traders based their trading on unreliable or outdated information. Instead, you can leverage the power of AllocateRite's Data Science and Predictive Analytics to help forecast short-term Price Trends.
                <strong onClick={() => this.setState({ showMore: false })} style={{ color: '#00A99D', cursor: 'pointer', }}> Show less...</strong></p>:
                <p style={{fontSize: 14}}>
                        Try using the AllocateRite Stock Price Trend Predictor to help you with your short-term trading. Short-Term trading is highly risky, <strong onClick={() => this.setState({ showMore: true })} style={{ color: '#00A99D', cursor: 'pointer',}}> Show more...</strong>
                </p>}
            </div>
        )
    }

    individuleCompanyCard = (location, sector) => {
        let columns = window.innerWidth / 280
        let data
        if (location === 'search'){
            data = this.state.momDataSource;
        } else{
            data = this.state.presetTickers[sector]
        }
        // console.log('sector',sector)
        return (
            <Row
                style={{
                    padding: "2%",
                    textDecoration: "none",
                }}
            >
                {data.map((item, index) => {
                    return (
                        <SingleCompanyCard sector={sector} item={item} index={index} />
                    );
                })}
            </Row>
        );
    };

    appLinks(){
        return(
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: 50, marginTop: 10}}>
                <a href="https://apps.apple.com/us/app/forescite/id1567589436">
                    <img
                        className="features-store-download"
                        style={{ height: 50, width: 'auto' }}
                        src="../images/app_download_icon.png"
                        alt="apple-store"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.forescite">
                    <img
                        className="features-store-download"
                        style={{height: 50, width: 'auto'}}
                        src="../images/google_download_icon.png"
                        alt="google-store"
                    />
                </a>
            </div>
        )
    }

    presetIcons = ()=>{
        const {isSmallScreen} = this.state
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: isSmallScreen ? '80%' :'60%'}}>
                <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                    <div 
                        style={{display: 'flex', flexDirection: 'column',cursor: 'pointer',}}
                        onClick={() => this.setSelectedPreset('Communications')}
                    >
                    <img
                    src={Comms_list}
                    style={{height: 20}}
                    />
                    <p>comm</p> 
                    </div>
                    <div 
                        style={{display: 'flex', flexDirection: 'column',cursor: 'pointer',}}
                        onClick={() => this.setSelectedPreset('Recovery')}
                    >
                    <img
                    src={Recovery}
                    style={{height: 20}}
                    />
                    <p>recovery</p> 
                    </div>
                    <div 
                        style={{display: 'flex', flexDirection: 'column',cursor: 'pointer',}}
                        onClick={() => this.setSelectedPreset('Semis')}
                    >
                    <img
                    src={Semiconductor}
                    style={{height: 20}}
                    />
                    <p>semis</p> 
                    </div>
                    <div 
                        style={{display: 'flex', flexDirection: 'column',cursor: 'pointer',}}
                        onClick={() => this.setSelectedPreset('Crypto')}
                    >
                    <img
                    src={Crypto}
                    style={{height: 20}}
                    />
                    <p>crypto</p> 
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    
                    <div
                        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', }}
                        onClick={() => this.setSelectedPreset('Brazil')}
                    >
                        <img
                            src={Brazil}
                            style={{ height: 20 }}
                        />
                        <p>Brazil</p>
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', }}
                        onClick={() => this.setSelectedPreset('India')}
                    >
                        <img
                            src={India}
                            style={{ height: 20 }}
                        />
                        <p>India</p>
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', }}
                        onClick={() => this.setSelectedPreset('Japan')}
                    >
                        <img
                            src={Japan}
                            style={{ height: 20 }}
                        />
                        <p>Japan</p>
                    </div>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', }}
                        onClick={() => this.setSelectedPreset('China')}
                    >
                        <img
                            src={China}
                            style={{ height: 20 }}
                        />
                        <p>China</p>
                    </div>
                    
                   
                    <div
                        style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', }}
                        onClick={() => this.setSelectedPreset('Germany')}
                    >
                        <img
                            src={Germany}
                            style={{ height: 20 }}
                        />
                        <p>Germany</p>
                    </div>
                </div>
            </div>
        )
    }

    showTickers = (sector)=>{
        const {
            isSmallScreen,
            isMomCurSelected,
        } = this.state;

        let loadingdiv = (
            <div
                style={{
                    height: 300,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <p>loading...</p>
            </div>
        );
        return (
            <Card
                style={{
                    width: "90%",
                    marginTop: 20,
                }}
            >
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold'}}>
                        select a ticker for more information 
                    </p>
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                    <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
                </div>*/}
                <div>
                    {this.individuleCompanyCard('x', sector) }
                </div>
            </Card>
        );
    }

    trendPredictordiv = () => {
        const {
            isMomDataLoading,
            momDataSource,
            searchMomentumText,
            isSmallScreen,
            isMomCurSelected,
        } = this.state;

        return (
            <Card
                style={{
                    width: "90%",
                    marginTop: 5,
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p style={{ color: 'rgb(211,211,211)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        select a ticker for more information
                    </p>
                </div>
                <div>
                    {this.displayMomResults()}
                </div>
            </Card>
        );
    };

    displayMomResults = ()=>{
        const {
            isMomDataLoading,
            momDataSource,
            searchMomentumText,
            isSmallScreen,
            isMomCurSelected,
        } = this.state;
        if (isMomDataLoading === false && momDataSource.length > 0){
            return this.individuleCompanyCard('search', '')
        } else if (isMomDataLoading === false){
            return(
            < div
                style = {{
                    height: 300,
                    paddingTop: isSmallScreen ? 80 : null,
                    paddingBottom: isSmallScreen ? 80 : null,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h5
                    style={{
                        margin: isSmallScreen ? 40 : 20,
                        color: "#808080",
                        fontSize: isSmallScreen ? 15 : 18,
                    }}
                >
                    {searchMomentumText.length < 2
                        ? null
                        : "no results try a different ticker or company name"}
                </h5>
            </div >
            
            )
        }
        else{
            return (
                <div
                    style={{
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <p>loading...</p>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {/* <Header hiddenSearch={true} user={this.props.user} signOut={this.props.signOut}/> */}
                <div
                    style={{
                        paddingTop: 10,
                        // backgroundColor: '#F2F2F999',
                        height: '100%',
                        width:'100%'
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginBottom: 10}}>
                        {(this.state.showVideo && !this.state.isSmallScreen)? <div style={{width: 280}} />:null}
                        <div>
                            {this.props.dash?
                                <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                    src={ARlogo}
                                    style={{height: 50}}
                                    />
                                </div>
                            :null}
                            {this.props.isSmallScreen?
                            <div style={{display:'flex',justifyContent:'center', alignItems:'center', marginBottom: 20}}>
                                <h4>Price Trend Predictor</h4>
                            </div>:null}
                        </div>
                        {(this.state.showVideo && !this.state.isSmallScreen) ? <div style={{ display: 'flex' }}> 
                        <Youtube
                            videoId={'8KNaR9o-Qik'}              // defaults -> null
                            // id={string}                       // defaults -> null
                            // className={string}                // defaults -> null
                            // containerClassName={string}       // defaults -> ''
                            opts={{ height: 315, width: 560 }}        // defaults -> {}
                            // onReady={func}                    // defaults -> noop
                            // onPlay={func}                     // defaults -> noop
                            // onPause={func}                    // defaults -> noop
                            onEnd={()=>this.setState({showVideo:!this.state.showVideo})}                      // defaults -> noop
                            
                            // onError={func}                    // defaults -> noop
                            // onStateChange={func}              // defaults -> noop
                            // onPlaybackRateChange={func}       // defaults -> noop
                            // onPlaybackQualityChange={func}    // defaults -> noop
                        /> 
                        </div> : null}
                    </div>
                    {/* {console.log('searching')} */}
                    {(this.state.isSmallScreen && this.state.showVideo)?
                    <div style={{width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}> 
                            {/* <iframe 
                                id="player" 
                                src="https://www.youtube.com/embed/8KNaR9o-Qik?rel=0" 
                                allowfullscreen
                                height="315" 
                                width="560"
                                >
                            </iframe> */}
                                <Youtube
                                videoId={'8KNaR9o-Qik'}              // defaults -> null
                                // id={string}                       // defaults -> null
                                // className={string}                // defaults -> null
                                // containerClassName={string}       // defaults -> ''
                                opts={{height:315,width:560}}        // defaults -> {}
                                // onReady={func}                    // defaults -> noop
                                // onPlay={func}                     // defaults -> noop
                                // onPause={func}                    // defaults -> noop
                                onEnd={()=>this.setState({showVideo:!this.state.showVideo})}                      // defaults -> noop
                                // onEnd={()=>console.log('the video has ended i think')}                      // defaults -> noop
                                // onError={func}                    // defaults -> noop
                                // onStateChange={func}              // defaults -> noop
                                // onPlaybackRateChange={func}       // defaults -> noop
                                // onPlaybackQualityChange={func}    // defaults -> noop
                            />
                        </div> 
                    </div>
                        : null}
                    
                    {/* {this.state.isSmallScreen?
                    <div onClick={()=>this.setState({showVideo:true})} style={{cursor:'pointer', width: '100%', display: 'flex', justifyContent: 'center', alignContent:'center', marginBottom: 10}}>
                            <div style={{ width: '30%', borderRadius: 12, backgroundColor: 'rgb(247,247,251)', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                            <p style={{margin:0, fontWeight: 'bold', color: brandColor}}>
                                Video
                            </p>
                        </div>
                    </div>:null
                    } */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            flex: 1,
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'space-between', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                            }}
                            onMouseEnter={() => this.setState({ hover: true })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        >
                            <input
                                type="text"
                                style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                value={this.state.searchMomentumText}
                                onChange={(event) => {
                                    let searchMomentumText = event.target.value;
                                    this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                        const { isSearching } = this.state;
                                        if (isSearching) {
                                            return;
                                        } else {
                                            this.setState({ isSearching: true });
                                            setTimeout(() => {
                                                this.setState(
                                                    { isSearching: false },
                                                    this.onSubmitSearchMomentum
                                                );
                                            }, 2000);
                                        }
                                    });
                                }}
                                onFocus={() => {
                                    this.setState({ showTrendPredictor: true });
                                }}
                                placeholder="Enter stock symbol or company name"
                                ref={this.input}
                            />
                            {/* {!this.state.isSmallScreen?
                                <div style={{display:'flex', justifyContent:'center', alignContent:'center', backgroundColor: 'rgb(247,247,251)', cursor: 'pointer'}} onClick={()=>this.setState({showVideo:!this.state.showVideo})}>
                                    <p style={{margin: 0, paddingLeft:10, paddingRight:10, marginTop: 5, fontWeight:'bold', color: brandColor}}>
                                        Video
                                    </p>
                                </div>
                                :null
                            } */}
                            <div style={{ minWidth: 20, width: '5%', backgroundColor: '#00A99D', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 15px 15px 0px'}}>
                                <FaSearch size={10} style={{color: 'rgb(255,255,255)'}} />                        
                            </div>
                            </div>
                            {this.state.searchMomentumText.length > 0 ?this.trendPredictordiv(): this.presetIcons()}
                            {this.state.selectedPreset ? this.showTickers(this.state.selectedPreset): null}
                    </div>
                    <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop: 40, marginBottom: 10,}}>
                        {this.howToBox()}
                    </div>
                    <div style={{marginTop:20}}>
                        {this.appLinks()}
                    </div>
                    <div style={{height: 80}}/>
                    {/* <div style={{ width: '80%', margin: 'auto', borderRadius: 10 }}>
                        <Footer/>
                    </div> */}
                </div>
            </div>
        );
    }
}
