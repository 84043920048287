//REACT
import React from 'react'
import { useState, useContext, useEffect, useRef } from 'react'
import { Line } from 'react-chartjs-2'

import axios from 'axios'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//UTILS
import formatCurrency from '../../../utility-functions/formatCurrency'
import Footer from '../../layout/Footer'

export default function InvestallRobo() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  const [initContribution, setInitContribution] = useState(500)
  const [displayInitContribution, setDisplayInitContribution] = useState(500)
  const [contributionAmount, setContributionAmount] = useState(0)
  const [displayContributionAmount, setDisplayContributionAmount] = useState(0)
  const [totalReturn, setTotalReturn] = useState((1361.6).toFixed(2))
  const graphRef = useRef()

  useEffect(() => {
    async function getGraphData() {
      let body = {
        goalData: {
          compoundInterest: 'Compound Monthly',
          contributionFrequency: 'Monthly',
          contributionAmount: contributionAmount,
          startingAmount: initContribution,
          targetedAmount: 10000,
          yearsToAccumulate: 5,
          roi: 0.089,
          totalTaxRate: 0.15,
          inflationRate: 0.1,
        },
        option: { action: 'calculate' },
      }
      let { data } = await axios.post(
        'https://api.allocaterite.com/mobile/investment-forecasting',
        body
      )
      if (graphRef.current) {
        // graphRef.current.chartInstance.data.datasets[0].data = Object.values(
        //   data.balanceData.balance
        // )

        graphRef.current.chartInstance.data.datasets[0].data = increase(
          data.balanceData.balance
        )

        graphRef.current.chartInstance.data.datasets[1].data = decrease(
          data.balanceData.balance
        )
        graphRef.current.chartInstance.update()
        setTotalReturn(data.balanceData.balance['10'].toFixed(2))
      }
    }
    getGraphData()
  }, [initContribution, contributionAmount])

  const decrease = (data) => {
    const res = []
    if (data) {
      Object.values(data).forEach((item, index) => {
        res.push(Math.pow(2, index))
      })
    }
    return res
  }
  const increase = (data) => {
    const res = []
    if (data) {
      Object.values(data).forEach((item, index) => {
        res.push(Math.pow(2.1, index))
      })
    }
    return res
  }

  const graphOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: { enabled: false },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            fontColor: 'black', // this here
            // min: 500,
            // max: 225000,
            stepSize: 25000,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  return (
    <div className='center-container-big'>
      <p className='big-text light-text'>{t('investall_robo')}</p>
      <div className='product-page-row'>
        <div className='item-small'>
          <p className='final-amount thin space-bottom'>
            {formatCurrency(totalReturn)} (
            {parseInt(
              parseFloat(totalReturn) /
                (parseInt(contributionAmount) * 60 + parseInt(initContribution))
            )}
            X)
          </p>
          <p className='small-text'>{t('initial_amount')}</p>
          <div className='slider-container'>
            <p className='medium-text light-text space-bottom'>
              {formatCurrency(displayInitContribution)}
            </p>
            <input
              type='range'
              min='500'
              max='50000'
              step={100}
              value={displayInitContribution}
              onChange={(e) => setDisplayInitContribution(e.target.value)}
              onMouseUp={(e) => setInitContribution(e.target.value)}
              onTouchEnd={(e) => setInitContribution(e.target.value)}
              className='space-bottom'
            />
          </div>
          <p className='small-text'>{t('contribution_amount')}</p>
          <p className='medium-text light-text space-bottom'>
            {formatCurrency(displayContributionAmount)}
          </p>
          <div className='slider-container'>
            <input
              type='range'
              min='0'
              max='500'
              step={20}
              value={displayContributionAmount}
              onChange={(e) => setDisplayContributionAmount(e.target.value)}
              onMouseUp={(e) => setContributionAmount(e.target.value)}
              onTouchEnd={(e) => setContributionAmount(e.target.value)}
            />
          </div>
          <p className='small-text'>{t('investment_period_30_years')}</p>
        </div>
        <div className='item-big graph'>
          <Line
            data={{
              labels: ['', '', 3, '', 5, '', '', '', '', 10],
              datasets: [
                {
                  data: [
                    500.0, 556.9819654883372, 619.0353259051365,
                    686.611435399031, 760.2018186378821, 840.341745984991,
                    927.6141268659927, 1022.6537496454036, 1126.1518988521823,
                    1238.861383338364, 1361.602011943816,
                  ],
                  // borderColor: '#3627e4',
                  borderColor: '#3627e4',
                  label: 'Investall Robo',
                  backgroundColor: 'transparent',
                },
                {
                  data: [
                    500.0, 556.9819654883372, 619.0353259051365,
                    686.611435399031, 760.2018186378821, 840.341745984991,
                    927.6141268659927, 1022.6537496454036, 1126.1518988521823,
                    1238.861383338364, 1361.602011943816,
                  ],
                  borderColor: 'black',
                  label: 'S&P 500',
                  backgroundColor: 'transparent',
                },
              ],
            }}
            options={graphOptions}
            width={300}
            height={300}
            ref={graphRef}
          />
          {/* TRANSLATE LINE BELOW */}
          <p className='chart-disclaimer-text'>
            Chart is not indicative of future performance, it is for simulation
            purposes only.
          </p>
        </div>
      </div>
      <div className='product-page-row'>
        <div className='same-size'>
          <p className='small-text bold-text space-bottom'>
            {t('trusted_by_institutional_traders')}
          </p>
          <p className='small-text space-bottom'>
            {t('audited_track_record_msg')}
          </p>
          {/* <p className='small-text'>{t('other_top_robo_advisors_msg')}</p> */}
        </div>
        {/* <div className='same-size'>
          <p className='small-text bold-text space-bottom'>
            {t('tailored_to_your_choices')}
          </p>
          <p className='small-text'>{t('complete_an_investment_msg')}</p>
        </div> */}
        <div className='same-size'>
          <p className='small-text bold-text space-bottom'>
            {t('highly_liquid')}
          </p>
          <p className='small-text'>{t('keep_full_custody_msg')}</p>
        </div>
      </div>
    </div>
  )
}
