import React, { useMemo } from 'react'
import { useEffect, useSearchParams } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import { useLocation } from 'react-router-dom'
import { setErrorMessage } from '../../features/general'
import { useDispatch } from 'react-redux'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function AsaLogin({ history, setUser, t }) {
  const devPool = '3g4jeiaqa5pvp168op31nj1kbj'
  const prodPool = '2d1an4cgt8feims3uqljke0fe7'
  const dispatch = useDispatch()
  let query = useQuery()

  useEffect(() => {
    const username = query.get('username')
    const accessToken = query.get('access_token')
    const idToken = query.get('id_token')
    window.localStorage.clear()
    window.localStorage.setItem(
      `CognitoIdentityServiceProvider.${prodPool}.LastAuthUser`,
      username
    )
    window.localStorage.setItem(
      `CognitoIdentityServiceProvider.${prodPool}.${username}.accessToken`,
      accessToken
    )
    window.localStorage.setItem(
      `CognitoIdentityServiceProvider.${prodPool}.${username}.idToken`,
      idToken
    )
    Auth.currentSession()
      .then((resp) => {
        let user = resp
        if (user) {
          setUser(user)
        } else {
          dispatch(setErrorMessage(t('somethingwentwrong')))
          history.push('signin')
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(setErrorMessage(t('somethingwentwrong')))
        history.push('signin')
      })
  }, [])
  return null
}

//CognitoIdentityServiceProvider.3g4jeiaqa5pvp168op31nj1kbj.5ee15e55-4285-4c4d-b255-b4dc5f881eac.clockDrift

//CognitoIdentityServiceProvider.3g4jeiaqa5pvp168op31nj1kbj.username.
