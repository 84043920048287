//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'

//STYLES
import '../../scss/components/budgeting/ActivityDetails.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function ActivityLineChart() {
  const { t, budgetingData, setPage } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const [graphTab, setGraphTab] = useState('all')
  const [graphData, setGraphData] = useState(null)

  useEffect(() => {
    changeGraphData()
  }, [graphTab])

  const changeGraphData = () => {
    const graphData = []
    const essDataAvg = details?.expense?.expense_compare?.average?.essExpense
    const essDataCurr = details?.expense?.expense_compare?.current?.essExpense
    const nonEssDataAvg =
      details?.expense?.expense_compare?.average?.nonEssExpense
    const nonEssDataCurr =
      details?.expense?.expense_compare?.current?.nonEssExpense

    let averageLast = 0
    let currentLast = 0
    switch (graphTab) {
      case 'essential':
        if (essDataAvg) {
          Object.entries(essDataAvg)?.forEach(([key, value]) => {
            averageLast = essDataCurr?.[key] ? value : averageLast
            currentLast = essDataCurr?.[key] ? essDataCurr?.[key] : currentLast
            graphData.push({
              name: parseInt(key) + 1,
              [t('average')]: value,
              [t('this_month')]: essDataCurr?.[key],
            })
          })
        }
        break
      case 'non_essential':
        if (nonEssDataAvg) {
          Object.entries(nonEssDataAvg)?.forEach(([key, value]) => {
            averageLast = nonEssDataCurr?.[key] ? value : averageLast
            currentLast = nonEssDataCurr?.[key]
              ? nonEssDataCurr?.[key]
              : currentLast
            graphData.push({
              name: parseInt(key) + 1,
              [t('average')]: value,
              [t('this_month')]: nonEssDataCurr?.[key],
            })
          })
        }
        break
      case 'all':
        for (let i = 0; i < 31; i++) {
          averageLast =
            essDataCurr?.[i] || nonEssDataCurr?.[i]
              ? essDataAvg?.[i] + nonEssDataAvg?.[i]
              : averageLast
          currentLast =
            essDataCurr?.[i] || nonEssDataCurr?.[i]
              ? essDataCurr?.[i] + nonEssDataCurr?.[i]
              : currentLast
          graphData.push({
            name: i + 1,
            [t('average')]:
              essDataAvg || nonEssDataAvg
                ? essDataAvg?.[i] + nonEssDataAvg?.[i]
                : null,
            [t('this_month')]:
              essDataCurr?.[i] || nonEssDataCurr?.[i]
                ? essDataCurr?.[i] + nonEssDataCurr?.[i]
                : null,
          })
        }
        break
      default:
        break
    }
    setGraphData({
      graph: graphData,
      averageLast: averageLast,
      currentLast: currentLast,
    })
  }

  const renderGraph = () => {
    return (
      <ResponsiveContainer width='100%' height={350}>
        <LineChart
          width={500}
          height={300}
          data={graphData?.graph}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='2' />
          <XAxis dataKey='name' interval={5} />
          <YAxis tickFormatter={(value) => formatCurrency(value)} />
          <Tooltip formatter={(value) => formatCurrency(value)} />
          {/* <Legend /> */}
          <Line
            type='monotone'
            dataKey={t('this_month')}
            stroke='#3627E4'
            dot={false}
          />
          <Line
            type='monotone'
            dataKey={t('average')}
            stroke='black'
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  const message = () => {
    let i = 1
    while (
      i < graphData?.graoh?.length &&
      (graphData?.graph?.[i]?.Average ||
        graphData?.graph?.[i]?.Average === 0) &&
      (graphData?.graph?.[i]?.['This month'] ||
        graphData?.graph?.[i]?.['This month'] === 0)
    ) {
      i++
    }
    const lastCurrentValue = graphData?.graph?.[i - 1]?.['This month']
    const lastRealAverage = graphData?.graph?.[i - 1]?.Average
    console.log(lastCurrentValue, lastRealAverage)
    if (lastCurrentValue > lastRealAverage) {
      return t('you_are_spending_more_this_month_than_you_do_on_average')
    } else if (lastCurrentValue < lastRealAverage) {
      return t('you_are_spending_less_this_month_than_you_do_on_average')
    } else {
      return ''
    }
  }

  const legend = () => {
    return (
      <div className='graph-legend'>
        <div>
          <div className='legend-container'>
            <div className='purple-dot'></div>
            <p className='legend-label purple'>{t('this_month')}</p>
          </div>
          <p className='legend-price purple'>
            {formatCurrency(graphData?.currentLast)}
          </p>
        </div>
        <div>
          <div className='legend-container'>
            <div className='black-dot'></div>
            <p className='legend-label black'>{t('average')}</p>
          </div>
          <p className='legend-price black'>
            {formatCurrency(graphData?.averageLast)}
          </p>
        </div>
      </div>
    )
  }

  const graphTabs = () => {
    return (
      <div className='graph-tabs'>
        <p
          className={`graph-tab-big ${graphTab === 'all' ? 'selected' : ''}`}
          onClick={() => {
            setGraphTab('all')
          }}
        >
          {t('all')}
        </p>
        <p
          className={`graph-tab-big ${
            graphTab === 'essential' ? 'selected' : ''
          }`}
          onClick={() => {
            setGraphTab('essential')
          }}
        >
          {t('essential')}
        </p>
        <p
          className={`graph-tab-big ${
            graphTab === 'non_essential' ? 'selected' : ''
          }`}
          onClick={() => {
            setGraphTab('non_essential')
          }}
        >
          {t('non_essential')}
        </p>
      </div>
    )
  }

  return (
    <div className='activity-line-charts'>
      <p className='activity-line-message'>{message()}</p>
      {legend()}
      {renderGraph()}
      {graphTabs()}
    </div>
  )
}
