import React, { Component } from 'react'

export class PressBar extends Component {
    
    render() {
        return (
            <div className="press-bar">
                <a className="press-title" href={this.props.value.url}>{this.props.value.title}</a>
                <p className="press-date">{this.props.value.date}</p>
                <p className="press-content">{this.props.value.content}</p>
            </div>
        )
    }
}

export default PressBar
