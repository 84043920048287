import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

class Commentary extends Component {

    render() {
        return (
            <Col xs={3} sm={3} md={3} lg={3} className="monthly-commentary-lines">
                <a className="monthly-commentary" href={"https://arweb-prod.s3.amazonaws.com/" + this.props.value.url}>
                    {this.props.value.month}
                </a>
            </Col>
        )
    }
}

export default Commentary;