import React, { useContext, useEffect, useState } from 'react'
import { LandingPageContext } from '.'
import PlayStoreIcon from '../../../assets/play-store-icon-black-background.png'
import AppStoreIcon from '../../../assets/app-store-icon-black-background.png'
import ApkIcon from '../../../assets/apk-download-black-background.png'
import { useDispatch, useSelector } from 'react-redux'
import { setUsername } from '../../../features/navbar'
import { sendApkIncrement } from '../../../services/generalService'
import { NavLink } from 'react-router-dom'

export default function MainScreen(props) {
  const { t, apkData } = useContext(LandingPageContext)
  const { general, navbar } = useSelector((state) => state)
  const { isSmallScreen } = general
  const { email } = navbar
  const dispatch = useDispatch()

  const incrementApk = async () => {
    const response = await sendApkIncrement(apkData?.version)
    console.log('res', response)
  }

  return (
    <div className='main-screen'>
      <div className='content'>
        <div className='info-container'>
          <p className='title'>{t('landingpagetitle')}</p>
          <p className='info'>{t('landingpageinfo')}</p>
        </div>
        {isSmallScreen ? (
          <div className='get-started-container'>
            <input
              placeholder={t('emailaddress')}
              value={email}
              className='email-input'
              onChange={(e) => {
                dispatch(setUsername(e.target.value))
              }}
            ></input>
            {/* <button
              className='signup-btn'
              onClick={() => {
                dispatch(setSignUpModalOpen(true))
              }}
            >
              {t('signup')}
            </button> */}
            <NavLink
              to={'signup'}
              exact={true}
              style={{ width: '100%', textDecoration: 'none' }}
            >
              <button
                className='signup-btn'
                onClick={() => {
                  // dispatch(setSignUpModalOpen(true))
                }}
              >
                {t('signup')}
              </button>
            </NavLink>
            <NavLink
              to={'signin'}
              exact={true}
              style={{ width: '100%', textDecoration: 'none' }}
            >
              <p className='text-btn'>
                {t('alreadyhaveanaccount')}{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontFamily: 'Akkurat-Bold',
                  }}
                >
                  {t('login')}
                </span>
              </p>
              {/* <p className='text-btn bold-text'>{t('clickheretologin')}</p> */}
            </NavLink>
          </div>
        ) : (
          <div className='get-the-app-container'>
            {/* <button
              className='get-the-app-btn'
              onClick={() => {
                dispatch(setSignUpModalOpen(true))
              }}
            >
              {t('getstarted')}
            </button> */}
            <div className='get-started-container'>
              <div style={{ width: '65%' }}>
                <input
                  placeholder={t('emailaddress')}
                  value={email}
                  className='email-input'
                  onChange={(e) => {
                    dispatch(setUsername(e.target.value))
                  }}
                ></input>
                {/* <NavLink
                  to={'signin'}
                  exact={true}
                  style={{ width: '100%', textDecoration: 'none' }}
                >
                  <p className='text-btn'>
                    {t('alreadyhaveanaccount')}
                    {t('clickheretologin')}
                  </p>
                </NavLink> */}
              </div>
              <NavLink
                to={'signup'}
                exact={true}
                style={{ width: '32%', textDecoration: 'none' }}
              >
                <button
                  className='signup-btn'
                  onClick={() => {
                    // dispatch(setSignUpModalOpen(true))
                  }}
                >
                  {t('signup')}
                </button>
              </NavLink>
            </div>
            <div className='get-the-app-icons'>
              <div>
                <a
                  href='https://apps.apple.com/us/app/forescite/id1567589436'
                  target='_blank'
                >
                  <img src={AppStoreIcon} className='app-store-icon' />
                </a>
                {/* <p className='notice-text'>*{t('notavailableinchina')}</p> */}
              </div>
              <div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.forescite'
                  target='_blank'
                >
                  <img src={PlayStoreIcon} className='play-store-icon' />
                </a>
                {/* <p className='notice-text'>*{t('notavailableinchina')}</p> */}
              </div>
              <div>
                <a
                  href={apkData?.url}
                  target='_blank'
                  onClick={() => {
                    incrementApk()
                  }}
                >
                  <img src={ApkIcon} className='apk-download-icon' />
                </a>
                <p className='notice-text'>{t('downloadapkmessage')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
