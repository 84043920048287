import React from 'react'
import { useTranslation } from 'react-i18next'
import '../src/translations/i18n'

export default function hookWrapper(Component) {
  return function WrappedComponent(props) {
    const { t } = useTranslation()
    return <Component t={t} {...props} />
  }
}
