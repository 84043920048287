import React, { Component } from 'react'
import '../../../scss/reuse/variables.scss'

export default class PL extends Component {
  //takes in value
  // takes in percent
  formatCurrency(val) {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    if (!val) return numberFormat.format(0)
    return numberFormat.format(val)
  }

  showValue() {
    let value = this.props.value
    if (this.props.small) {
      //LESS THAN 1
      if (value < 0) {
        //NEGATIVE
        return (
          <span style={{ color: '#3D5062' }}>
            -${Math.abs(this.props.value?.toFixed(8))}
          </span>
        )
      } else if (value > 0) {
        //POSITIVE
        return (
          <span style={{ color: '#3F46F6' }}>
            +{this.props.value?.toFixed(8)}
          </span>
        )
      } else {
        //IS 0
        return <span style={{ color: '#272748' }}>{this.props.value}</span>
      }
    } else {
      //GREATER THAN 1
      if (value < 0) {
        return (
          <span style={{ color: '#3D5062' }}>
            {this.formatCurrency(this.props.value)}
          </span>
        )
      } else if (value > 0) {
        // if(value < 1){
        //   return <span style={{color: 'blue'}}>+${this.props.value.toFixed(5)}</span>
        // }
        // else{
        //   return <span style={{color: 'blue'}}>+{this.formatCurrency(this.props.value)}</span>
        // }
        return (
          <span style={{ color: '#3F46F6' }}>
            +{this.formatCurrency(this.props.value)}
          </span>
        )
      } else {
        return (
          <span style={{ color: '#272748' }}>
            {this.formatCurrency(this.props.value)}
          </span>
        )
      }
    }
  }

  showPercentage() {
    let percentage = this.props.percentage
    if (percentage < 0) {
      return (
        <span style={{ color: '#3D5062' }}>({percentage.toFixed(2)}%)</span>
      )
    } else if (percentage > 0) {
      return (
        <span style={{ color: '#3F46F6' }}>({percentage.toFixed(2)}%)</span>
      )
    } else {
      return (
        <span style={{ color: '#272748' }}>({percentage.toFixed(2)}%)</span>
      )
    }
  }
  render() {
    // return <p style={{marginBottom: '0'}}>
    //   {
    //     this.props.value ?
    //     this.showValue() : null
    //   }
    //   {
    //     this.props.percentage ?
    //     this.showPercentage() : null
    //   }
    // </p>;
    return (
      <>
        {' '}
        {this.props.value ? this.showValue() : null}
        {this.props.percentage ? this.showPercentage() : null}
      </>
    )
  }
}
