import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../../../../scss/pages/account-page/non-product-account-info/NonProdInvestments.scss'

export default class NonProdInvestments extends Component {
  state = {
    showAllInvestmentList: false,
  }

  displayMyInvestments() {
    const holdings = this.props.selectedAccount.holdings
    if (holdings.length > 0) {
      return (
        <div className='non-prod-investments-container'>
          <p className='investments-header'>{this.props.t('myinvestments')}</p>
          <div className='investments-container'>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            >
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='my-investments-table-header'>
                  {this.props.t('symbol')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='my-investments-table-header'>
                  {this.props.t('shares')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='my-investments-table-header'>
                  {this.props.t('costbasis')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='my-investments-table-header'>
                  {this.props.t('marketvalue')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='my-investments-table-header'>
                  {this.props.t('pl')}
                </p>
              </div>
            </div>
            <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
              {' '}
              {holdings.map((stock, index) => {
                return this.renderInvestmentRow(stock, index)
              })}
            </div>
            {/* {holdings.length > 5 ? (
              <div>
                {this.state.showAllInvestmentList ? (
                  <div>
                    {holdings.map((stock) => {
                      return this.renderInvestmentRow(stock);
                    })}
                  </div>
                ) : (
                  <div>
                    {holdings.map((stock, index) => {
                      if (index < 5) return this.renderInvestmentRow(stock);
                    })}
                  </div>
                )}
              </div>
            ) : (
              holdings.map((stock) => {
                return this.renderInvestmentRow(stock);
              })
            )} */}
          </div>
          {/* {holdings.length > 5 ? (
            <div>
              {this.state.showAllInvestmentList ? (
                <button
                  style={{
                    width: "100%",
                    borderRadius: "0 0 25px 25px",
                    background: "black",
                    color: "grey",
                    padding: "8px",
                  }}
                  onClick={() => {
                    this.setState({ showAllInvestmentList: false });
                  }}
                >
                  Show Less
                </button>
              ) : (
                <button
                  style={{
                    width: "100%",
                    borderRadius: "0 0 25px 25px",
                    background: "black",
                    color: "grey",
                    padding: "8px",
                  }}
                  onClick={() => {
                    this.setState({ showAllInvestmentList: true });
                  }}
                >
                  Show More
                </button>
              )}
            </div>
          ) : null} */}
        </div>
      )
    } else {
      return null
    }
  }

  renderInvestmentRow(stock, index) {
    return (
      <NavLink
        style={{ textDecoration: 'none' }}
        key={stock.symbol}
        to={`/Dashboard/Trade/${stock.symbol}`}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            borderBottom:
              index !== this.props.selectedAccount.holdings?.length - 1
                ? '1px solid rgba(128,128,128,.1)'
                : '',
            padding: '10px 0',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '20%' }}>
            <p
              style={{
                marginBottom: '0',
                color: '#272748',
                fontWeight: 'bold',
              }}
            >
              {stock.symbol}
            </p>
          </div>
          <div style={{ width: '20%' }}>
            <p style={{ color: '#272748', margin: 0 }}>
              {stock.quantity.toFixed(4)}
            </p>
          </div>
          <div style={{ width: '20%' }}>
            <p style={{ color: '#272748', margin: 0 }}>
              {this.props.formatCurrency(stock.averagePrice)}
            </p>
          </div>
          <div style={{ width: '20%' }}>
            {stock.type === 'Crypto' ? (
              <p style={{ color: '#272748', margin: 0 }}>
                {this.props.formatCurrency(stock.instValue)}
              </p>
            ) : (
              <p style={{ color: '#272748', margin: 0 }}>
                {this.props.formatCurrency(stock.instValue)}
              </p>
            )}
          </div>
          <div style={{ width: '20%' }}>
            {stock.unrealizedPL > 0 ? (
              <p style={{ color: '#3F46F6', margin: 0 }}>
                +{this.props.formatCurrency(stock.unrealizedPL)}
              </p>
            ) : (
              <p style={{ color: '#3D5062', margin: 0 }}>
                {this.props.formatCurrency(stock.unrealizedPL)}
              </p>
            )}
          </div>
        </div>
      </NavLink>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ width: this.props.isSmallScreen ? '100%' : '100%' }}>
          {this.displayMyInvestments()}
        </div>
      )
    }
  }
}
