import React from 'react'
import ReactHighcharts from 'react-highcharts'
import { Card, Row, Button, } from "react-bootstrap";

// const ReactHighstock = require("react-highcharts/ReactHighstock");
const brandColor = "#00A99D"
const options = {
  global: {
    useUTC: false
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
    rangeSelectorZoom: ""
  }
}
export default class DoubleLine extends React.Component{

    config = (actual, optimized)=>{
        // console.log('actual', actual)
        // console.log('optimized', optimized)
        let dataActual = []
        let dataOpt = []
        let tickers = []

        for(let i = 0; i < actual.length; i++) {
            if(!(actual[i][0] === 'cash' ||  actual[i][0] === '$$' || (actual[i][0] === 'BIL' && actual[i][1] === 0 && optimized[i][1] === 0))){
                tickers.push(actual[i][0])
                dataActual.push(actual[i][1])
                dataOpt.push(optimized[i][1])
            }
        }

        return {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: '{y}%'
                    }
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            xAxis: {
                categories: tickers,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: null,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: 'Actual',
                    colorByPoint: false,
                    data: dataActual,
                    color: '#242a38'
                },
                {
                    name: "Optimized",
                    colorByPoint: false,
                    data: dataOpt,
                    color: '#3b8e8c'
                }
            ],
            legend: {
                enabled: true,
            }
        }
    }
    render(){
        // console.log('rbs props', this.props)
        let temp1 = this.props
        // ARRAY OF OBJECTS
        // let act = Object.entries(temp1.actual).map((e)=>({[e[0]]:e[1]}))
        // let opt = Object.entries(temp1.optimized).map((e)=>({[e[0]]:e[1]}))
        // Array of Arrays
        let act = Object.entries(temp1.actual).map(e=>([e[0],parseFloat((e[1]*100).toFixed(2))]))
        let opt = Object.entries(temp1.optimized).map(e=>([e[0],parseFloat((e[1]*100).toFixed(2))]))
        // let act = Object.entries(temp1.actual)
        // let opt = Object.entries(temp1.optimized)
        return(
            <div style={{width:'100%',}}>
                <ReactHighcharts config={this.config(act,opt)} options={options}/>
            </div>
        )
    }

}