import React from 'react'
import { NavLink } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const brandColor = "rgb(70,182,179)";


export default class BankAcc extends React.Component{

    state={

    }


    addCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render(){
        let acc = this.props.acc
        return(
             <div style={{ width:'45%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',flexDirection:'column',marginRight:10 }}>
                <div style={{paddingLeft:'5%',paddingRight:'5%',paddingTop:40,paddingBottom:40, borderRadius: '10px',  backgroundColor:'rgb(255,255,255)',/*backgroundImage:'linear-gradient(97deg, #ffffff 1%, #f2f2f2)',*/ width:'100%',  marginTop:25}}>
                    <div style={{width:'100%', margin:'auto',marginBottom:10}}>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div>
                                <p style={{margin:0, fontSize:22, textAlign:'left'}}><strong>{acc.inst} {acc.mask}</strong></p>
                                 <p style={{margin:0, fontSize:18, textAlign:'left',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}><strong>{acc.name}</strong></p>
                            </div>
                            <div style={{flex: 1}}>
                                <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', position: 'relative'}}>
                                    <p style={{fontSize: 18, margin: 0}}>Balance: </p>
                                    {
                                        this.state.showBankInfo ? 
                                            <p style={{fontSize: 18, margin: 0}}><strong style={{color: brandColor}}> ${acc.balances[0].current}</strong></p> :
                                            <p style={{fontSize:18, margin:0}}>••••••••••</p>
                                    }
                                    <div style={{display:'flex', alignItems:'center'}} onClick={()=>this.setState({showBankInfo:!this.state.showBankInfo})}>
                                        {this.state.showBankInfo ?<FaEye style={{width:20, marginLeft:10}}/> : <FaEyeSlash  style={{width:20, marginLeft:10}}/> }
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent: 'flex-end'}}>
                                    
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.props.deposit()}
                                    
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Deposit</p>
                                    </div>
                                    
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius:'5%',borderWidth:'1.25px', borderColor: '#ee5e68', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:10,paddingRight:10, }}
                                    onClick={()=>this.props.unlink()}
                                    >
                                        <p style={{margin:0,fontWeight:'', color:'#ee5e68'}}>Unlink</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}
