import React, { Component } from "react";
import DdChart from "../../../companyPage3/DdChart";

export default class NonProdHistGraph extends Component {

  renderHistoricalRiskGraph() {
    let maxDrawdownVal = this.props.showStats.data.maxDrawdownVal.toFixed(2);
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? "100%" : "30%",
          boxShadow: "0px 5px 15px 0px #c4c4c4",
          borderRadius: "25px",
          padding: "25px",
          display: "block",
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: "20px" }}>
          Historical Stress Test
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Account Risk</p>
          <div>
            <p
              style={{
                marginBottom: "0",
                color: maxDrawdownVal < 0 ? "red" : "black",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              {maxDrawdownVal}%
            </p>
            <p style={{ fontSize: "10px", color: "rgba(128,128,128,.5)" }}>
              Max Drawdown
            </p>
          </div>
        </div>
        <DdChart drawdownChart={this.props.showStats.data.maxDrawdown} />
      </div>
    );
  }

  render() {
    return <div>{this.renderHistoricalRiskGraph()}</div>;
  }
}
