import React, { Component } from 'react'
import InactiveBasicInfo from './inactiveProduct/InactiveBasicInfo'
import InactivePendingTransfers from './inactiveProduct/InactivePendingTransfers'
import TransferFundsACH from './TransferFundsACH'
import ActivateProduct from './inactiveProduct/ActiveProduct'
import Loader from './Loader'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import TransferFundsDW from './TransferFundsDW'
import AccountActivity from '../AccountActivity'
import Statements from '../Statements'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
export default class InactiveAccountInfo extends Component {
  state = {
    showInfo: true,
    activeTab: 'General Info',
    isLoading: false,
  }
  toggleLoading = () => {
    this.setState({ isLoading: !this.state.isLoading })
  }
  tabs() {
    let color
    switch (this.props.selectedName) {
      case 'GOALS':
        color = '#3D32E7'
        break
      case 'ROBO':
        color = '#7147E9'
        break
      case 'STOCK':
        color = '#3F46F6'
        break
      case 'CRYPTO':
        color = '#3F46F6'
        break
      default:
        color = '#5C21D8'
        break
    }
    const customSelectedTab = { ...selectedTab, backgroundColor: color }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          onClick={() =>
            this.setState({ activeTab: 'General Info', showInfo: true })
          }
          style={
            this.state.activeTab === 'General Info'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t(this.props.selectedName.toLowerCase())}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      if (this.props.selectedName === 'AI CRYPTO TRADER') {
        return (
          <TransferFundsACH
            accounts={this.props.accounts}
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            cryptoAccount={this.props.selectedAccount}
            t={this.props.t}
          />
        )
      } else {
        return (
          <TransferFundsDW
            accounts={this.props.accounts}
            isSmallScreen={this.props.isSmallScreen}
            formatCurrency={this.props.formatCurrency}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            t={this.props.t}
            selectedAccount={this.props.selectedAccount}
          />
        )
      }
    } else if (this.state.activeTab === 'Account Activity')
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  toggleShowInfo = () => {
    this.setState({ showInfo: !this.state.showInfo })
  }

  generalInfo = () => {
    return (
      <div
        style={{
          height: '100%',
        }}
      >
        {this.state.showInfo ? (
          <>
            <InactiveBasicInfo {...this.props} />
          </>
        ) : null}
        <ActivateProduct {...this.props} toggleShowInfo={this.toggleShowInfo} />
        {this.state.showInfo ? (
          <>
            {this.props.accountTransactionsData ? (
              <InactivePendingTransfers {...this.props} />
            ) : (
              <Loader
                isSmallScreen={this.props.isSmallScreen}
                logoLoader={false}
              />
            )}
          </>
        ) : null}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      if (this.state.isLoading) {
        return (
          <div>
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          </div>
        )
      } else {
        return (
          <div>
            <div
              style={{
                width: this.props.isSmallScreen ? '100%' : '60%',
              }}
            >
              <img
                src={ProductActivationBackIcon}
                height={25}
                width={25}
                onClick={() => {
                  this.props.resetSelectedAccount()
                }}
                style={{ cursor: 'pointer', marginBottom: '25px' }}
              />
              {this.tabs()}
              <div
                style={{
                  margin: 'auto',
                  height: '100%',
                  width: '80%',
                }}
              >
                {this.showSelectedTab()}
              </div>
            </div>
          </div>
        )
      }
    } else return null
  }
}
