import React from 'react'
import { useEffect } from 'react'
import { handleAppRedirect } from '../../utility-functions/handleAppRedirect'

export default function AppRedirect(props) {
  useEffect(() => {
    if (window.innerWidth < 1000) handleAppRedirect()
    props.history.push('/')
  }, [])
  return null
}
