import React, { useContext } from 'react'
import { LandingPageContext } from '.'
import SeeFutureImg from '../../../assets/see-future-img.png'
import TickerCard from './TickerCard'
import { handleAppRedirect } from '../../../utility-functions/handleAppRedirect'
import { useSelector } from 'react-redux'

export default function SeeFuture() {
  const { t } = useContext(LandingPageContext)
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general

  return (
    <div className='see-future'>
      <div className='left'>
        <p className='header'>{t('seeintothefuture')}</p>
        <p className='description'>
          {/* Explore the world’s top cryptocurrencies from Bitcoin to Ethereum and
          beyond. Use our premium AI Trading tool to protect your investment.
          Buy, Sell and trade now by downloading our app. */}
          {t('seeintothefutureinfo')}
        </p>
        <div className='see-future-img-container'>
          <img src={SeeFutureImg} className='see-future-img' />
        </div>
        {!isSmallScreen && (
          <div className='get-the-app-btn-container'>
            <button
              className='get-the-app-btn'
              onClick={() => {
                handleAppRedirect()
              }}
            >
              {t('gettheapp')}
            </button>
          </div>
        )}
      </div>
      <div className='right'>
        <p className='header'>{t('pricetrendforecaster')}</p>
        <div className='ticker-card-container'>
          <TickerCard />
        </div>
        {isSmallScreen && (
          <div className='get-the-app-btn-container'>
            <button
              className='get-the-app-btn'
              onClick={() => {
                handleAppRedirect()
              }}
            >
              {t('gettheapp')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
