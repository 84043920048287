import React, { Component } from "react";
import TeamMember from "../TeamMember";
import { Container, Row } from "react-bootstrap";

class ChineseTeamMembers extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "BALKRISHNA E. SHAGRITHAYA",
        position: "首席执行官",
        introduction: `Balkrishna是着名的技术专家和成功的企业家，Balkrishna在计算机系统工程和企业级前端银行自动化解决方案设计方面拥有数十年的领导地位。
          他也是ESMT Group的首席执行官，ESMT Group是一家位于达拉斯的技术公司，为使用SAS的云计算提供可扩展的企业应用程序工具，
          支持大量并发应用程序和用户。此前，Shagrithaya先生是达拉斯Argo数据资源公司的联合创始人，执行副总裁兼首席技术官。
          该公司在20世纪80年代从头开始制造和设计，是最早提供零售软件的公司之一，主要为银行机构提供方自动化前端系统（分支机构，呼叫中心和互联网应用程序）。
          他在建立银行客户蓝筹榜上发挥了重要作用，该公司现在为前30家银行中的16家提供服务（包括美国银行和摩根大通）。 
          Shagrithaya先生拥有俄克拉荷马州立大学工业工程学士学位和印度孟买印度理工学院机械工程学士学位。`
      },
      {
        id: 2,
        name: "JOHN REBUTH",
        position: "首席风险官",
        introduction: `John Rebuth是AllocateRite的首席风险官。John拥有几十年在华尔街各大公司的金融业和监管合规工作经验。 
        他担任过纽约市对冲基金SūmitCapital的首席财务官/首席运营官。在加入SūmitCapital之前，他曾担任德意志银行的董事总经理，
        负责监督全球市场中的全球股票专有交易集团。在此之前，Rebuth先生在NatWest Markets和Prudential担任过各种职务，同时，Rebuth先生是注册会计师（CPA）。`
      },
      {
        id: 3,
        name: "ADAM BAUMAN",
        position: "首席运营官",
        introduction: `Adam Bauman是一位富有经验的互联网技术和运营主管，是AllocateRite的首席运营官。
        作为早期的互联网从业者，Adam在为环球影业建立了在线商店，为梦工厂S.K.G建立企业内联网并且帮助启动了latimes.com网站。
        之后，他在VWR Scientific Products，Young＆Rubicam，AIG和Deloitte Consulting担任各种高管职务，
        之后担任STI执行管理公司的首席运营官兼合规主管.Adar拥有Peter Stark的MFA学位。
        电影制作计划和南加州大学马歇尔商学院的MBA。他还是加州州立大学长滩广播电视学院的毕业生。`
      },
      {
        id: 4,
        name: "MICHAEL SPECE",
        position: "首席数据科学家",
        introduction: `Spece博士是非平稳时间序列分析的专家，他认为历史财务数据不是未来的代表。
        他在卡内基梅隆大学发表的论文提出了人工智能和机器学习的框架，为他的理论提供了强有力的数学证明。
        他将这些担保纳入财务分配策略，无论经济条件如何，客户都会受到保护。
        在加入AllocateRite之前，他是一家FinTech公司的AI和数据科学和顾问。
        Michael毕业于加州理工学院，并获得卡内基梅隆大学4.0GPA学位。
        他拥有在四个跨学科领域的学位：应用与计算数学（BS），商业经济与管理（BS），
        机器学习（PhD）和统计学数据科学（PhD）。`
      },
      {
        id: 5,
        name: "PETER TENG",
        position: "首席技术官",
        introduction: `Peter是一位有成就的技术专家，拥有超过12年相关领域的经验。
        作为AllocateRite的首席技术官，Peter负责管理多个项目，以不断改进AllocateRite的技术堆栈。
        在加入AllocateRite之前，Peter曾在Interactive Brokers的网络交易应用程序中工作。
        他以前是Legg Mason的应用程序开发人员，在那里他帮助开发了每天数千名顾问使用的门户软件。
        Teng先生拥有康涅狄格大学计算机科学与工程学士学位。`
      }
    ]
  };

  render() {
    return (
      <div>
        <Container className="team-member-form">
          <Row className="page-blank-row" />
          <Row className="team-member-row">
            {this.state.members.map(member => (
              <TeamMember value={member} key={member.id} />
            ))}
          </Row>
          <Row className="page-blank-row" />
        </Container>
      </div>
    );
  }
}

export default ChineseTeamMembers;
