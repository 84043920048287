import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../assets/black-logo.svg'
import Appstore from '../assets/landingPage/icon-apple-white.png'
import GooglePlay from '../assets/landingPage/icon-google-white.png'
import Twitter from '../assets/landingPage/icon-twitter-white.png'
import Facebook from '../assets/landingPage/icon-fb-white.png'
import YoutubeIcon from '../assets/landingPage/icon-youtube-white.png'
import InstagramIcon from '../assets/landingPage/IG-Icon.png'
import { HashLink } from 'react-router-hash-link'
export default class PublicFooter extends React.Component {
  state = {
    scrolled: false,
    emailInput: '',
    emailError: false,
    sent: false,
  }

  validateEmail() {
    let email = this.state.emailInput
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return re.test(email.toLowerCase())
  }

  subscribeToNewsLetter = () => {
    if (this.validateEmail()) {
      fetch('https://app.allocaterite.com/doLeads', {
        method: 'POST',
        body: JSON.stringify({
          action: 'subscribe',
          email: this.state.emailInput,
          source: 'RETAIL',
        }),
      })
        .then((res) => res.json())
        .then((responseJson) => {
          this.setState({ emailInput: '', sent: true })
        })
        .catch((err) => console.log('fetch error:', err))
    } else {
      this.setState({ emailError: true })
    }
  }

  renderSmall = () => {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ backgroundColor: 'lightgrey', padding: 20 }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              maxWidth: 1920,
            }}
          >
            <div style={{ width: '98%', margin: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-evenly',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color: '#000',
                      fontWeight: 'bold',
                    }}
                  >
                    Company
                  </p>
                  <NavLink
                    exact={true}
                    to='/'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/about'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    About
                  </NavLink>
                  {/* <NavLink
                    exact={true}
                    to="/products-info"
                    style={{ margin: 0, color: "#888", float: "left" }}
                  >
                    Products
                  </NavLink> */}
                  <NavLink
                    exact={true}
                    to='/stocks-info'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Stocks
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/crypto-info'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Crypto
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/ai-tools-info'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Tools
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/Contact'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Contact
                  </NavLink>
                  <NavLink
                    exact={true}
                    to='/Careers'
                    style={{ margin: 0, color: '#888', float: 'left' }}
                  >
                    Careers
                  </NavLink>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p
                    style={{
                      margin: 0,
                      color: '#000',
                      fontWeight: 'bold',
                    }}
                  >
                    Legal
                  </p>
                  <a
                    href='/Legal/ForeSCITE-Privacy-Policy.pdf'
                    className='link'
                  >
                    {this.props.t('privacypolicy')}
                  </a>
                  {/* <NavLink
                    exact={true}
                    to='/TermsofUse'
                    style={{ margin: 0, color: '#888' }}
                  >
                    Terms of Use
                  </NavLink> */}
                  <a href='/Legal/Terms-of-use.pdf' className='link'>
                    {this.props.t('termsofuse')}
                  </a>
                  {/* <NavLink
                    exact={true}
                    to='/EULA'
                    style={{ margin: 0, color: '#888' }}
                  >
                    EULA
                  </NavLink> */}
                  <a href='/Legal/EULA.pdf' className='link'>
                    {this.props.t('eula')}
                  </a>
                  <a href='/Legal/General-Disclaimer.pdf' className='link'>
                    {this.props.t('generaldisclaimer')}
                  </a>
                  {/* <NavLink exact={true} to="/InvestingTerms" style={{margin:0, color:"#888"}}>Investing Terms</NavLink> */}
                  <a
                    target='_blank'
                    href='https://www.forescite.ai/Legal/ForeSCITE-2021-CRS.pdf '
                    style={{ margin: 0, color: '#888' }}
                  >
                    ForeSCITE CRS
                  </a>
                  <NavLink
                    exact={true}
                    to='/Disclosures'
                    style={{ margin: 0, color: '#888' }}
                  >
                    Disclosures
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#f4f4f4', padding: 20 }}>
          {/* footer of footer */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginBottom: 10,
              flexDirection: 'column',
            }}
          >
            <p
              style={{
                display: 'flex',
                margin: 0,
                color: '#888',
                fontSize: 14,
                textAlign: 'center',
                flexDirection: 'column',
              }}
            >
              ForeSCITE © 2021
            </p>
            <div style={{ alignSelf: 'flex-start' }}>
              <a
                href='mailto: help@forescite.ai'
                style={{
                  margin: 0,
                  color: '#888',
                  fontSize: 14,
                  textAlign: 'center',
                }}
              >
                help@forescite.ai
              </a>
              <p
                style={{
                  margin: 0,
                  color: '#888',
                  fontSize: 14,
                  textAlign: 'flex-start',
                }}
              >
                (212) 995-9191
              </p>
            </div>
          </div>
          <p style={{ color: '#888', fontSize: 14, textAlign: 'flex-start' }}>
            100 Wall Street | Suite 901 | New York, NY 10005
          </p>
          {/* <p style={{ color: '#888', fontSize: 14, textAlign: 'flex-start' }}>
            2500 North Military Trail | Suite 455 | Boca Raton, Florida 33431
          </p> */}
        </div>
      </div>
    )
  }

  renderBig = () => {
    return (
      // <div style={{ width: "100%" }}>
      //   <div style={{ backgroundColor: "#f4f4f4" }}>{/* blue top */}</div>
      //   <div
      //     style={{
      //       backgroundColor: "#f4f4f4",
      //       paddingTop: 40,
      //     }}
      //   >
      //     <div
      //       style={{
      //         width: "100%",
      //         display: "flex",
      //         justifyContent: "space-around",
      //       }}
      //     >
      //       <div style={{ width: "100%", display: "flex", maxWidth: 1920, paddingLeft: 200, paddingRight: 200,}}>
      //         <div style={{ width: "40%" }}>
      //           {" "}
      //           <div
      //             style={{
      //               display: "flex",
      //               flexDirection: "column",
      //               alignItems: "left",
      //             }}
      //           >
      //             <p
      //               style={{
      //                 margin: 0,
      //                 color: "#000",
      //                 fontWeight: "bold",
      //               }}
      //             >
      //               Company
      //             </p>
      //             <NavLink
      //               exact={true}
      //               to="/"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Home
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/about"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               About
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/stocks-info"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Stocks
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/crypto-info"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Crypto
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/ai-tools-info"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Tools
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/Contact"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Contact
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/Careers"
      //               style={{ margin: 0, color: "#888", float: "left" }}
      //             >
      //               Careers
      //             </NavLink>
      //           </div>
      //         </div>

      //         <div style={{ width: "40%" }}>
      //           {" "}
      //           <div
      //             style={{
      //               display: "flex",
      //               flexDirection: "column",
      //               alignItems: "left",
      //             }}
      //           >
      //             <p
      //               style={{
      //                 margin: 0,
      //                 color: "#000",
      //                 fontWeight: "bold",
      //               }}
      //             >
      //               Legal
      //             </p>
      //             <NavLink
      //               exact={true}
      //               to="/PrivacyPolicy"
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               Privacy Policy
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/TermsofUse"
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               Terms of Use
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/EULA"
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               EULA
      //             </NavLink>
      //             <NavLink
      //               exact={true}
      //               to="/GeneralDisclaimer"
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               General Disclaimer
      //             </NavLink>
      //             <a
      //               target="_blank"
      //               href="https://www.forescite.ai/Legal/ForeSCITE-2021-CRS.pdf "
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               ForeSCITE CRS
      //             </a>
      //             <NavLink
      //               exact={true}
      //               to="/Disclosures"
      //               style={{ margin: 0, color: "#888" }}
      //             >
      //               Disclosures
      //             </NavLink>
      //           </div>
      //         </div>
      //         <div style={{ width: "20%" }}>
      //           {" "}
      //           <div
      //             style={{
      //               display: "flex",
      //               flexDirection: "column",
      //               alignItems: "left",
      //             }}
      //           >
      //             <img src={Logo} alt="" style={{width: "50%", objectFit: "contain", minWidth: 125, marginBottom: 5}}/>
      //             <p
      //               style={{
      //                 margin: 0,
      //                 color: "#888",
      //                 fontSize: 14,
      //                 marginRight: 20,
      //               }}
      //             >
      //               ForeSCITE © 2021
      //             </p>
      //             <a
      //               href="mailto: help@forescite.ai"
      //               style={{
      //                 margin: 0,
      //                 color: "#888",
      //                 fontSize: 14,
      //                 marginRight: 20,
      //               }}
      //             >
      //               help@forescite.ai
      //             </a>
      //             <p style={{ margin: 0, color: "#888", fontSize: 14 }}>
      //               (212) 995-9191
      //             </p>
      //           </div>
      //           <div
      //             style={{
      //               display: "flex",
      //               alignItems: "center",
      //               justifyContent: "space-around",
      //             }}
      //           >
      //             <p style={{ margin: 0, color: "#888", fontSize: 14 }}>
      //               100 Wall Street | Suite 901 | New York, NY 10005
      //             </p>
      //           </div>
      //           <div
      //             style={{
      //               display: "flex",
      //               alignItems: "center",
      //               justifyContent: "space-around",
      //             }}
      //           >
      //             <p
      //               style={{
      //                 margin: 0,
      //                 color: "#888",
      //                 fontSize: 14,
      //                 textAlign: "left",
      //               }}
      //             >
      //               150 East Palmetto Park Road | Suite 800 | Boca Raton,
      //               Florida 33432
      //             </p>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   <div
      //     style={{
      //       backgroundColor: "#f4f4f4",
      //       padding: 20,
      //       display: "flex",
      //       justifyContent: "space-evenly",
      //     }}
      //   >
      //     <div
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         justifyContent: "space-between",
      //       }}
      //     ></div>
      //   </div>
      //   <div style={{ backgroundColor: "#f4f4f4", padding: 20 }}>
      //   </div>
      // </div>
      <div className='center-container'>
        <div className='footer-main-container'>
          <div className='top-container'>
            <div className='footer-column'>
              <p className='column-title bold-text'>
                {this.props.t('products2')}
              </p>
              {/* <NavLink exact={true} to='/products-info' className='link'>
                {this.props.t('Invest Robo')}
              </NavLink> */}
              {/* <NavLink exact={true} to='/products-info' className='link'>
                {this.props.t('AI Trader')}
              </NavLink>
              <NavLink exact={true} to='/products-info' className='link'>
                {this.props.t('Goals')}
              </NavLink>
              <NavLink exact={true} to='/products-info' className='link'>
                {this.props.t('Budgeting')}
              </NavLink> */}
              <HashLink smooth to='/products-info#invest-robo' className='link'>
                {this.props.t('Invest Robo')}
              </HashLink>
              <HashLink smooth to='/products-info#ai-trader' className='link'>
                {this.props.t('AI Trader')}
              </HashLink>
              <HashLink smooth to='/products-info#goals' className='link'>
                {this.props.t('Goals')}
              </HashLink>
              <HashLink smooth to='/products-info#budgeting' className='link'>
                {this.props.t('Budgeting')}
              </HashLink>
            </div>
            <div className='footer-column'>
              <p className='column-title bold-text'>
                {this.props.t('company')}
              </p>
              <NavLink exact={true} to='/' className='link'>
                {this.props.t('home')}
              </NavLink>
              {/* <NavLink exact={true} to="/about" className="link">
                Team
              </NavLink> */}
              <NavLink exact={true} to='/about' className='link'>
                {this.props.t('about')}
              </NavLink>
              <NavLink exact={true} to='/Contact' className='link'>
                {this.props.t('contact')}
              </NavLink>
              <NavLink exact={true} to='/Careers' className='link'>
                {this.props.t('careers')}
              </NavLink>
              <NavLink exact={true} to='/Support' className='link'>
                {this.props.t('support')}
              </NavLink>
            </div>
            <div className='footer-column'>
              <p className='column-title bold-text'>{this.props.t('legal')}</p>
              <a href='/Legal/ForeSCITE-Privacy-Policy.pdf' className='link'>
                {this.props.t('privacypolicy')}
              </a>
              <a href='/Legal/Terms-of-use.pdf' className='link'>
                {this.props.t('termsofuse')}
              </a>
              {/* <NavLink exact={true} to='/Legal/EULA.pdf' className='link'>
                {this.props.t('eula')}
              </NavLink> */}
              <a href='/Legal/EULA.pdf' className='link'>
                {this.props.t('eula')}
              </a>
              <a href='/Legal/General-Disclaimer.pdf' className='link'>
                {this.props.t('generaldisclaimer')}
              </a>
              <NavLink exact={true} to='/Disclosures' className='link'>
                {this.props.t('disclosures')}
              </NavLink>
            </div>
            <div className='footer-column'>
              <img src={Logo} alt='' />
              <p className='motto'>
                {this.props.t('automatedinvestingmadeeasy')}
              </p>
            </div>
          </div>
          <div className='bottom-container'>
            <p>100 Wall Street, Suite 901, New York, NY 10005</p>
            {/* <p>
              2500 North Military Trail | Suite 455 | Boca Raton, Florida 33431
            </p> */}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return this.renderBig()
    }
  }
}
