import React, { Component } from 'react'
import AllocationPieChart from '../../AllocationPieChart'
import StrategyInfoGraph from '../StrategyInfoGraph'
import ContributionEditModal from '../../ContributionEditModal'
import PL from '../PL'
import { InputSwitch } from 'primereact/inputswitch'
import { ConfirmDialog } from 'primereact/confirmdialog'
import Axios from 'axios'
import Endpoint from '../../../Endpoint'
import ConfirmationDialog from '../../ConfirmationDialog'
import '../../../../scss/pages/account-page/robo-account-info/RoboSpecificInfo.scss'
import { Dialog } from 'primereact/dialog'

export default class RoboSpecificInfo extends Component {
  state = {
    //edit contribution modal
    showEditContributionModal: false,
    showConfirmationDialog: false,
    showAllocationPieChart: false,
    nonTradingHoursDialog: false,
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.accounts !== this.props.accounts) {
  //     this.props.toggleLoading();
  //   }
  // };

  deactivateRobo = async () => {
    return await Axios.get(
      `${Endpoint}/rebalance-mobile/liquidate-robo?dw_account_id=${this.props.selectedAccount.accountId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((response) => {
        if (response.data) {
          this.props.getAllAccountInfo()
          return true
        } else return false
      })
      .catch((response) => {
        return false
      })
  }

  toggleShowConfirmationDialog = () => {
    if (this.props.canTrade?.status) {
      this.setState({
        showConfirmationDialog: !this.state.showConfirmationDialog,
      })
    } else {
      this.toggleNonTradingHoursDialog()
    }
  }

  toggleNonTradingHoursDialog = () => {
    this.setState({ nonTradingHoursDialog: !this.state.nonTradingHoursDialog })
  }

  toggleEditContributionModal = () => {
    this.setState({
      showEditContributionModal: !this.state.showEditContributionModal,
    })
  }

  toggleAllocationPieChart = () => {
    this.setState({
      showAllocationPieChart: !this.state.showAllocationPieChart,
    })
  }

  intro() {
    const strategyInfo =
      this.props.strategyInfo[this.props.productInstanceData.tracker.stratId]
    let seriesData = []
    if (strategyInfo && strategyInfo.graph) {
      strategyInfo.graph.forEach((ele) => {
        seriesData.push([
          Date.parse(ele.date),
          parseFloat((ele.aggregate_return * 100).toFixed(2)),
        ])
      })
    }
    return (
      <div>
        <p
          style={{
            color: '#7147E9',
            fontWeight: 'bold',
            fontSize: '25px',
            textAlign: 'center',
          }}
        >
          {strategyInfo?.fullname}
        </p>
        <p>{strategyInfo?.description}</p>
        <StrategyInfoGraph seriesData={seriesData} />
        <p
          style={{
            fontSize: '15px',
            color: 'rgba(128,128,128,.5)',
            textAlign: 'right',
          }}
        >
          {strategyInfo?.disclaimer}
        </p>
      </div>
    )
  }

  closeAndResetEditContributionModal = () => {
    // if (
    //   this.props.editContributionStatus &&
    //   this.props.editContributionStatus === "success"
    // )
    this.props.resetEditContributionModal()
    this.toggleEditContributionModal()
  }

  overview() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('overview')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{this.props.t('active')}</p>
          </div>
          <InputSwitch
            checked={true}
            onChange={(e) => this.toggleShowConfirmationDialog()}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('startdate')}</p>
          <p>{this.props.productInstanceData.tracker.createdAt}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('investmentamount')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.tracker.totalContributed
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <p>{this.props.t('contribution')}</p>
            <p
              onClick={() => {
                this.toggleEditContributionModal()
              }}
              style={{
                border: '1px solid #7147E9',
                borderRadius: '25px',
                padding: '0px 3px',
                marginLeft: '5px',
                cursor: 'pointer',
                background: '#7147E9',
                color: 'white',
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {this.props.t('edit')}
            </p>
          </div>
          {this.props.productInstanceData.roundUpData.roundUp ? (
            <p>{this.props.t('roundup')}</p>
          ) : (
            <p>
              {this.props.formatCurrency(
                this.props.productInstanceData.roundUpData.recurringDepositData
                  .amount
              )}{' '}
              {this.props.t('monthly')}
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('currentvalue')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData.cashData?.cashAvailable +
                this.props.productInstanceData.cashData?.equityValue
            )}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('return')}</p>
          <p>${this.props.productInstanceData.profit?.toFixed(2)}</p>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(128,128,128,.5)",
          }}
        >
          <p>Return</p>
          <PL
            formatCurrency={this.props.formatCurrency}
            value={this.props.productInstanceData.tracker.profit}
            percentage={
              this.props.productInstanceData.tracker.profit_percentage
            }
          />
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
          }}
        >
          <p>{this.props.t('monthtodate')}</p>
          {this.props.productInstanceData.strategyInfo.mtd > 0 ? (
            <p>
              {(this.props.productInstanceData.strategyInfo.mtd * 100).toFixed(
                2
              )}
              %
            </p>
          ) : (
            <p style={{ color: '#3D5062' }}>
              {(this.props.productInstanceData.strategyInfo.mtd * 100).toFixed(
                2
              )}
              %
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('yeartodate')}</p>
          {this.props.productInstanceData.strategyInfo.ytd > 0 ? (
            <p>
              {(this.props.productInstanceData.strategyInfo.ytd * 100).toFixed(
                2
              )}
              %
            </p>
          ) : (
            <p style={{ color: '#3D5062' }}>
              {(this.props.productInstanceData.strategyInfo.ytd * 100).toFixed(
                2
              )}
              %
            </p>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ maxWidth: '55%' }}>
            {this.props.t('expectedpotentialrisk')}
          </p>
          <p>
            {Math.abs(
              this.props.productInstanceData.strategyInfo.cvar * 100
            ).toFixed(2)}
            %
          </p>
        </div>
      </div>
    )
  }

  rebalanceInformation() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('rebalanceinformation')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('nextrebalance')}</p>
          <p>{this.props.productInstanceData.rebalanceInfo.nextRebalance}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('lastrebalancedate')}</p>
          <p>
            {this.props.productInstanceData.rebalanceInfo.lastRebalanceDate}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('daysuntilnextrebalance')}</p>
          <p>
            {this.props.productInstanceData.rebalanceInfo.daysUntilRebalance}
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('intramonth')}</p>
          {this.props.productInstanceData.rebalanceInfo.isIntraMonth ? (
            <p>YES</p>
          ) : (
            <p>NO</p>
          )}
        </div>
      </div>
    )
  }

  allocation() {
    if (
      this.props.productInstanceData.allocations &&
      this.props.productInstanceData.allocations.length > 0
    ) {
      let seriesData = []
      this.props.productInstanceData.allocations.forEach((allocation) => {
        if (allocation.allocation > 0) {
          seriesData.push({
            name: allocation.symbol,
            value: parseFloat((allocation.allocation * 100).toFixed(2)),
          })
        }
      })
      return (
        <div className='robo-allocation-container'>
          <p
            style={{
              fontSize: '15px',
              fontFamily: 'Akkurat-Bold',
              textAlign: 'center',
              marginBottom: '0',
              cursor: 'pointer',
              color: '#7147E9',
            }}
            onClick={() => {
              this.toggleAllocationPieChart()
            }}
          >
            {this.props.t('viewallocations')}
          </p>
          <Dialog
            visible={this.state.showAllocationPieChart}
            style={{
              width: this.props.isSmallScreen ? '90vw' : '30vw',
              height: this.props.isSmallScreen ? '90vh' : '50vh',
            }}
            onHide={() => this.toggleAllocationPieChart()}
            header={
              <p className='allocation-dialog-header'>
                {this.props.t('allocation')}
              </p>
            }
          >
            <AllocationPieChart
              seriesData={seriesData}
              t={this.props.t}
              verticalAlign={false}
              isSmallScreen={this.props.isSmallScreen}
            />
          </Dialog>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
          }}
          className='robo-specific-info-container'
        >
          {this.intro()}
          {this.overview()}
          {this.rebalanceInformation()}
          {this.allocation()}
          {this.state.showEditContributionModal ? (
            <ContributionEditModal
              toggleEditContributionModal={this.toggleEditContributionModal}
              showDialog={this.state.showEditContributionModal}
              closeAndResetEditContributionModal={
                this.closeAndResetEditContributionModal
              }
              editContributionStatus={this.props.editContributionStatus}
              editContributionRoundUp={this.props.editContributionRoundUp}
              editContributionPeriodic={this.props.editContributionPeriodic}
              accounts={this.props.accounts}
              periodicContributions={
                this.props.productInstanceData.roundUpData.roundUp
                  ? 0
                  : this.props.productInstanceData.roundUpData
                      .recurringDepositData.amount
              }
              roundUp={this.props.productInstanceData.roundUpData.roundUp}
              isSmallScreen={this.props.isSmallScreen}
              selectedAccount={this.props.selectedAccount}
              t={this.props.t}
            />
          ) : null}
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            name={this.props.t('deactivaterobo')}
            action={this.deactivateRobo}
            successMessage={this.props.t('robohasbeensuccessfullydeactivated')}
            t={this.props.t}
          />
          <Dialog
            visible={this.state.nonTradingHoursDialog}
            onHide={this.toggleNonTradingHoursDialog}
            header={this.props.t('cannotdeactivateroboduringnontradinghours')}
          ></Dialog>
        </div>
      )
    } else return null
  }
}
