import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'

import { Button, FormGroup, FormControl } from 'react-bootstrap'

import ARlogo from '../assets/ar_logo.png'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../aws-config'

Amplify.configure(awsExports)

const brandColor = '#00A99D'

export default class Login extends React.Component {
  state = {
    username: '',
    password: '',
    showFP: false,
    confirmationCode: '',
    errors: false,
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 3
  }

  showCodeAndSuccess = () => {
    if (this.state.errors) {
      this.setState({ showCode: true, signUpClicked: true })
    } else {
      return null
    }
  }

  showErrors = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(253,180,181)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p style={{ margin: 0 }}>{this.state.errors}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  sendCode = () => {
    if (this.state.username.length > 5) {
      Auth.forgotPassword(this.state.username)
        .then(() => console.log('sent'))
        .catch((error) => this.setState({ errors: error.message }))
    }
  }

  forgotForm = () => {
    const { username, password, confirmationCode } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId='username'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: brandColor,
                fontSize: 24,
                paddingBottom: 10,
                fontWeight: 'bold',
                margin: 'auto',
              }}
            >
              Investor Login
            </p>
          </div>
          <FormControl
            autoFocus
            placeholder='Username'
            type='text'
            value={username}
            onChange={(e) => this.setState({ ['username']: e.target.value })}
          />
          <Button
            block
            bsSize='large'
            style={{
              backgroundColor: brandColor,
              width: '60%',
              margin: 'auto',
              marginTop: 10,
            }}
            onClick={() => this.sendCode()}
            type='submit'
          >
            Send Code
          </Button>
        </FormGroup>
        <FormGroup controlId='password' bsSize='large'>
          <FormControl
            placeholder='New Password'
            value={password}
            onChange={(e) => this.setState({ ['password']: e.target.value })}
            type='password'
          />
          <FormControl
            style={{ marginTop: 10 }}
            placeholder='Confirmation Code'
            value={confirmationCode}
            onChange={(e) =>
              this.setState({ ['confirmationCode']: e.target.value })
            }
            type='text'
          />
        </FormGroup>
        <Button
          block
          bsSize='large'
          disabled={!this.validateForm()}
          style={{ backgroundColor: brandColor, width: '60%', margin: 'auto' }}
          type='submit'
        >
          Login
        </Button>
      </form>
    )
  }

  showErrors = () => {
    if (this.props.errors) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(253,180,181)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p style={{ margin: 0 }}>{this.props.errors}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  showSuccess = () => {
    if (!this.state.errors && this.state.signUpClicked) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(234,255,219)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p
              style={{ margin: 0 }}
            >{`A confirmation code has been sent to you ${this.state.username}`}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  signInRedirect = () => {
    const { history } = this.props
    this.props.signIn(this.state.username, this.state.password)
    if (this.props.errors) {
      return null
    } else {
    }
  }

  loginForm = () => {
    const { username, password } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId='username' bsSize='large'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: brandColor,
                fontSize: 24,
                paddingBottom: 10,
                fontWeight: 'bold',
                margin: 'auto',
              }}
            >
              Login
            </p>
          </div>
          <FormControl
            autoFocus
            placeholder='Username'
            type='username'
            value={username}
            onChange={(e) => this.setState({ ['username']: e.target.value })}
          />
        </FormGroup>
        <FormGroup controlId='password' bsSize='large'>
          <FormControl
            placeholder='Password'
            value={password}
            onChange={(e) => this.setState({ ['password']: e.target.value })}
            type='password'
          />
        </FormGroup>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.showErrors()}
        </div>
        <Button
          block
          bsSize='large'
          onClick={() => this.signInRedirect()}
          disabled={!this.validateForm()}
          style={{ backgroundColor: brandColor, width: '60%', margin: 'auto' }}
          type='submit'
        >
          Login
        </Button>
      </form>
    )
  }

  cutURL() {
    return window.location.pathname.split('/Login')[1]
  }

  render() {
    return (
      <div>
        <div>
          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NavLink
              to={'/Login'}
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                backgroundColor: 'rgb(247,247,247)',
                color: 'rgb(17,17,17)',
                borderRadius: 12,
                padding: '10px 20px',
              }}
            >
              Please Login to see your Dashboard
            </NavLink>
          </div>
        </div>
      </div>
    )
    // return (
    //         <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop: 20 }}>
    //             {/* <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
    //                 <img
    //                     src={ARlogo}
    //                     style={{ height: 50 }}
    //                 />
    //             </div> */}
    //             <div className="Login" style={{ backgroundColor: 'rgb(247,247,247)', borderRadius: 20, width: '100%', margin: 'auto' }}>
    //                 <div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 30, }}>
    //                     {this.state.showFP ? this.forgotForm() : this.loginForm()}
    //                 </div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20, }}>
    //                     <div>
    //                         <NavLink
    //                             to={'/SignUp'}
    //                             style={{ color: brandColor, fontWeight: 'bold' }}
    //                         >
    //                             Go To Sign Up
    //                     </NavLink>
    //                     </div>
    //                     <div style={{ cursor: 'pointer' }}>
    //                         <p
    //                             onClick={() => this.setState({ showFP: !this.state.showFP })}
    //                             style={{ color: brandColor, fontWeight: 'bold', }}
    //                         >
    //                             {this.state.showFP ? 'Return to Login' : ' Forgot Password'}
    //                         </p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    // );
  }
}
