import React from 'react'
import Twitter from '../assets/newUI/twitter.png'
import Facebook from '../assets/newUI/facebook.png'
import Youtube from '../assets/newUI/youtube.png'
import GooglePlay from '../assets/newUI/googlePlay.png'
import Appstore from '../assets/newUI/appStore.png'
import InstagramIcon from '../assets/landingPage/IG-Icon.png'
import { NavLink } from 'react-router-dom'
import { FaPaperPlane } from 'react-icons/fa'

export default class Footer extends React.Component {
  state = {
    hoverButton: false,
    emailInput: '',
    emailError: false,
    placeholder: 'Enter your email address',
    sent: false,
  }

  validateEmail() {
    let email = this.state.emailInput
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email.toLowerCase())
  }

  subscribeToNewsLetter = () => {
    if (this.validateEmail()) {
      fetch('https://app.allocaterite.com/doLeads', {
        method: 'POST',
        body: JSON.stringify({
          action: 'subscribe',
          email: this.state.emailInput,
          source: 'RETAIL',
        }),
      })
        .then((res) => res.json())
        .then((responseJson) => {
          this.setState({ emailInput: '', sent: true })
        })
        .catch((err) => console.log('fetch error:', err))
    } else {
      this.setState({ emailError: true })
    }
  }

  render() {
    if (this.props.isSmallScreen) {
      return (
        <div
          style={{
            backgroundColor: 'rgb(33,37,49)',
            padding: 50,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              margin: 0,
              color: '#ffffff',
              fontSize: 'larger',
              fontWeight: 'bold',
            }}
          >
            Company
          </p>
          <NavLink
            exact={true}
            to='/'
            style={{ margin: 0, color: '#ffffff', float: 'left' }}
          >
            Home
          </NavLink>
          <NavLink
            exact={true}
            to='/About'
            style={{ margin: 0, color: '#ffffff', float: 'left' }}
          >
            About
          </NavLink>
          <NavLink
            exact={true}
            to='/Contact'
            style={{ margin: 0, color: '#ffffff', float: 'left' }}
          >
            Contact
          </NavLink>
          <NavLink
            exact={true}
            to='/Careers'
            style={{ margin: 0, color: '#ffffff', float: 'left' }}
          >
            Careers
          </NavLink>
          <hr />
          <p
            style={{
              margin: 0,
              color: '#ffffff',
              fontSize: 'larger',
              fontWeight: 'bold',
            }}
          >
            Legal
          </p>
          <NavLink
            exact={true}
            to='/PrivacyPolicy'
            style={{ margin: 0, color: '#ffffff' }}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            exact={true}
            to='/TermsofUse'
            style={{ margin: 0, color: '#ffffff' }}
          >
            Terms of Use
          </NavLink>
          <NavLink
            exact={true}
            to='/EULA'
            style={{ margin: 0, color: '#ffffff' }}
          >
            EULA
          </NavLink>
          {/* <NavLink
            exact={true}
            to='/GeneralDisclaimer'
            style={{ margin: 0, color: '#ffffff' }}
          >
            General Disclaimer
          </NavLink> */}
          <a
            href='/Legal/General-Disclaimer.pdf'
            style={{ margin: 0, color: '#ffffff' }}
          >
            General Disclaimer
          </a>
          <a href='/Legal/EULA.pdf' style={{ margin: 0, color: '#ffffff' }}>
            {this.props.t('eula')}
          </a>
          <a
            target='_blank'
            href='https://www.forescite.ai/Legal/ForeSCITE-2021-CRS.pdf '
            style={{ margin: 0, color: '#ffffff' }}
          >
            ForeSCITE CRS
          </a>
          {/* <NavLink exact={true} to="/InvestingTerms" style={{margin:0, color:"#ffffff"}}>Investing Terms</NavLink> */}
          <NavLink
            exact={true}
            to='/Disclosures'
            style={{ margin: 0, color: '#ffffff' }}
          >
            Disclosures
          </NavLink>
          <hr />
          <p
            style={{
              margin: 0,
              color: '#ffffff',
              fontSize: 'larger',
              fontWeight: 'bold',
            }}
          >
            Stay in Touch
          </p>
          <p style={{ color: '#ffffff' }}>
            Join our newsletter for new products, promotions & other updates
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '40%',
              alignItems: 'center',
              marginTop: 5,
              marginBottom: 5,
              height: 26,
              minWidth: 219,
            }}
          >
            <input
              onChange={(e) =>
                this.setState({ emailInput: e.target.value, emailError: false })
              }
              type='text'
              value={this.state.emailInput}
              placeholder={this.state.placeholder}
              style={{
                backgroundColor: '#ffffff',
                color: this.state.emailError ? 'rgb(222,94,83)' : '#000000',
                border: 'none',
                outline: 'none',
                width: '99%',
                opacity: 0.6,
              }}
            />
            <div
              onClick={() => this.subscribeToNewsLetter()}
              onMouseLeave={() => this.setState({ hoverButton: false })}
              onMouseEnter={() => this.setState({ hoverButton: true })}
              style={{
                backgroundColor: '#ffffff',
                opacity: this.state.hoverButton ? 0.8 : 0.6,
                color: '#242a38',
                height: 26,
                width: 26,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 2,
              }}
            >
              <FaPaperPlane style={{}} />
            </div>
          </div>
          <hr />
          <div style={{ display: 'flex', marginTop: 10 }}>
            <a
              href='https://apps.apple.com/us/app/forescite/id1567589436'
              target='_blank'
            >
              <img
                target
                style={{ height: 32, marginRight: 20, cursor: 'pointer' }}
                src={Appstore}
              />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.forescite'
              target='_blank'
            >
              <img
                style={{ height: 30, marginRight: 20, cursor: 'pointer' }}
                src={GooglePlay}
              />
            </a>
            <a href='https://twitter.com/allocaterite' target='_blank'>
              <img
                style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                src={Twitter}
              />
            </a>
            <a
              href='https://www.facebook.com/allocateriteapp/?ref=py_c'
              target='_blank'
            >
              <img
                style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                src={Facebook}
              />
            </a>
            <a
              href='https://www.youtube.com/channel/UCx3Dz23r0nLH8eGriN_v_Xg'
              target='_blank'
            >
              <img
                style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                src={Youtube}
              />
            </a>
            <a href='https://www.instagram.com/foresciteapp/' target='_blank'>
              <img
                style={{
                  height: 40,
                  width: 40,
                  marginRight: 20,
                  cursor: 'pointer',
                  opacity: 0.5,
                  borderRadius: 20,
                }}
                src={InstagramIcon}
              />
            </a>
          </div>
          <hr />
          <p style={{ margin: 0, color: '#ffffff' }}>(212) 995-9191</p>
          <p style={{ margin: 0, color: '#ffffff' }}>help@forescite.ai</p>
          <p style={{ margin: 0, color: '#ffffff' }}>
            100 Wall Street | Suite 901 | New York, NY 10005
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ backgroundColor: 'rgb(33,37,49)', padding: 50 }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              {' '}
              {/**RIGHT BOX */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#ffffff',
                    fontSize: 'larger',
                    fontWeight: 'bold',
                  }}
                >
                  Company
                </p>
                <NavLink
                  exact={true}
                  to='/'
                  style={{ margin: 0, color: '#ffffff', float: 'left' }}
                >
                  Home
                </NavLink>
                <NavLink
                  exact={true}
                  to='/About'
                  style={{ margin: 0, color: '#ffffff', float: 'left' }}
                >
                  About
                </NavLink>
                <NavLink
                  exact={true}
                  to='/Contact'
                  style={{ margin: 0, color: '#ffffff', float: 'left' }}
                >
                  Contact
                </NavLink>
                <NavLink
                  exact={true}
                  to='/Careers'
                  style={{ margin: 0, color: '#ffffff', float: 'left' }}
                >
                  Careers
                </NavLink>
              </div>
            </div>

            <div style={{ width: '20%' }}>
              {' '}
              {/**LEFT BOX */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#ffffff',
                    fontSize: 'larger',
                    fontWeight: 'bold',
                  }}
                >
                  Legal
                </p>
                <NavLink
                  exact={true}
                  to='/PrivacyPolicy'
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  Privacy Policy
                </NavLink>
                <NavLink
                  exact={true}
                  to='/TermsofUse'
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  Terms of Use
                </NavLink>
                <NavLink
                  exact={true}
                  to='/EULA'
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  EULA
                </NavLink>
                <NavLink
                  exact={true}
                  to='/GeneralDisclaimer'
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  General Disclaimer
                </NavLink>
                <a
                  target='_blank'
                  href='https://www.forescite.ai/Legal/ForeSCITE-2021-CRS.pdf '
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  ForeSCITE CRS
                </a>
                {/* <NavLink exact={true} to="/InvestingTerms" style={{margin:0, color:"#ffffff"}}>Investing Terms</NavLink> */}
                <NavLink
                  exact={true}
                  to='/Disclosures'
                  style={{ margin: 0, color: '#ffffff' }}
                >
                  Disclosures
                </NavLink>
              </div>
            </div>

            <div style={{ width: '30%' }} />
            <div style={{ width: '30%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#ffffff',
                    fontSize: 'larger',
                    fontWeight: 'bold',
                  }}
                >
                  Stay in Touch
                </p>
                <p style={{ color: '#ffffff' }}>
                  Join our newsletter for new products, promotions & other
                  updates
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '50%',
                    alignItems: 'center',
                    marginTop: 5,
                    marginBottom: 5,
                    height: 26,
                    minWidth: 219,
                  }}
                >
                  {this.state.sent ? (
                    <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>
                      Added to mailing list!
                    </p>
                  ) : (
                    <input
                      onChange={(e) =>
                        this.setState({
                          emailInput: e.target.value,
                          emailError: false,
                        })
                      }
                      type='text'
                      style={{
                        backgroundColor: '#ffffff',
                        color: this.state.emailError
                          ? 'rgb(222,94,83)'
                          : '#000000',
                        border: 'none',
                        outline: 'none',
                        width: '99%',
                        opacity: 0.6,
                      }}
                      placeholder='Enter your email address'
                    />
                  )}
                  <div
                    onClick={() => this.subscribeToNewsLetter()}
                    onMouseLeave={() => this.setState({ hoverButton: false })}
                    onMouseEnter={() => this.setState({ hoverButton: true })}
                    style={{
                      backgroundColor: '#ffffff',
                      opacity: this.state.hoverButton ? 0.8 : 0.6,
                      color: '#242a38',
                      height: 26,
                      width: 26,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 2,
                    }}
                  >
                    <FaPaperPlane style={{}} />
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <a
                    href='https://apps.apple.com/us/app/forescite/id1567589436'
                    target='_blank'
                  >
                    <img
                      target
                      style={{ height: 32, marginRight: 20, cursor: 'pointer' }}
                      src={Appstore}
                    />
                  </a>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.forescite'
                    target='_blank'
                  >
                    <img
                      style={{ height: 30, marginRight: 20, cursor: 'pointer' }}
                      src={GooglePlay}
                    />
                  </a>
                  <a href='https://twitter.com/allocaterite' target='_blank'>
                    <img
                      style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                      src={Twitter}
                    />
                  </a>
                  <a
                    href='https://www.facebook.com/allocateriteapp/?ref=py_c'
                    target='_blank'
                  >
                    <img
                      style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                      src={Facebook}
                    />
                  </a>
                  <a
                    href='https://www.youtube.com/channel/UCx3Dz23r0nLH8eGriN_v_Xg'
                    target='_blank'
                  >
                    <img
                      style={{ height: 25, marginRight: 20, cursor: 'pointer' }}
                      src={Youtube}
                    />
                  </a>
                  <a
                    href='https://www.instagram.com/foresciteapp/'
                    target='_blank'
                  >
                    <img
                      style={{
                        height: 40,
                        width: 40,
                        marginRight: 20,
                        cursor: 'pointer',
                        opacity: 0.5,
                        borderRadius: 20,
                      }}
                      src={InstagramIcon}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 10,
            }}
          >
            <p style={{ margin: 0, color: '#ffffff' }}>(212) 995-9191</p>
            <p style={{ margin: 0, color: '#ffffff' }}>help@forescite.ai</p>
            <p style={{ margin: 0, color: '#ffffff' }}>
              100 Wall Street | Suite 901 | New York, NY 10005
            </p>
            <p style={{ margin: 0, color: '#ffffff' }}>
              2500 North Military Trail | Suite 455 | Boca Raton, Florida 33431
            </p>
          </div>
        </div>
      )
    }
  }
}
