import React, { Component } from 'react'
import '../../../scss/components/TransferFundsACH.scss'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { TabView, TabPanel } from 'primereact/tabview'
import { Dialog } from 'primereact/dialog'
import ENDPOINT from '../../Endpoint'
import axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { TailSpin } from 'react-loader-spinner'
import { ConfirmDialog } from 'primereact/confirmdialog'
import LinkACH from '../LinkACH'
import ConfirmACH from '../ConfirmACH'
import Loader from './Loader'
import Endpoint from '../../Endpoint'
import LinkPlaidBtn from '../LinkPlaidBtn'
import RelinkPlaid from './RelinkPlaid'
import ConfirmationDialog from '../ConfirmationDialog'

export default class TransferFundsACH extends Component {
  state = {
    activeTab: 0,
    amountValue: 0,
    isLoading: false,
    fetchingAccountsStatus: true,
    formErrorMessage: '',
    formStatusMessage: '',
    achAccount: null,
    cryptoAccount: null,
    achPending: null,
    bankingAccounts: [],
    selectedFromAccount: null,
    selectedToAccount: null,
    noOptions: [{ label: 'No available options', value: null }],
    showRelinkDialog: false,
    showApexLinkDialog: false,
    accountToLink: null,
    //EDIT ACH POP UP
    showLinkACHdialog: false,

    //Confirm ACH POP UP
    showConfirmACHdialog: false,

    //EDIT OR DELETE POP UP
    showEditOrDeleteForm: false,

    //CONFIRMATION POP UP
    showDeleteConfirmationForm: false,
  }

  componentDidMount = () => {
    this.getCryptoAccount()
    this.filterAccounts()
  }

  //UPDATE CRYPTO ACCOUNT WHEN NEW PROPS COME IN
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.accounts !== this.props.accounts) {
      this.getACHaccount()
    }
  }

  filterAccounts = () => {
    let bankingAccounts = []

    this.props.accounts.forEach((account) => {
      if (account.type && account.type === 'depository') {
        bankingAccounts.push({ label: account.name, value: account })
        if (account.name === this.state.achAccount?.nickname)
          this.setState({ selectedFromAccount: account })
      }
    })
    this.setState({
      bankingAccounts: bankingAccounts,
    })
  }

  getCryptoAccount = () => {
    this.props.accounts.forEach((account) => {
      if (account.inst === 'Apex') {
        this.setState({ cryptoAccount: account }, () => {
          this.getACHaccount()
        })
        return
      }
    })
  }

  getACHaccount = async () => {
    return await axios
      .get(
        `${ENDPOINT}/apex-crypto/ach-relationships/list?account_id=${this.props.cryptoAccount.accountId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response?.data?.approved && response?.data?.approved.length > 0) {
          this.setState({
            achAccount: response.data.approved[0],
          })
          this.state.bankingAccounts.forEach((account) => {
            if (account.value.name === response.data.approved[0].nickname) {
              this.setState({
                selectedFromAccount: account.value,
                selectedToAccount: account.value,
              })
            }
          })
        } else if (
          response?.data?.pending &&
          response?.data?.pending.length > 0
        ) {
          this.setState({ achPending: response.data.pending[0] })
        }
        this.setState({ fetchingAccountsStatus: false })
        return true
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        this.setState({ fetchingAccountsStatus: false })
        return false
      })
  }

  toggleRelinkDialog = () => {
    this.setState({ showRelinkDialog: !this.state.showRelinkDialog })
  }

  linkAccount = async () => {
    try {
      return fetch(`${ENDPOINT}/apex-crypto/plaid/ach-relationships/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify({
          account_id: this.state.cryptoAccount?.accountId,
          plaid_account_id: this.state.accountToLink,
        }),
      }).then(async () => {
        return await this.getACHaccount()
      })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false })
      return false
    }
  }

  deposit = async () => {
    this.setState({ isLoading: true })
    await axios
      .post(
        `${ENDPOINT}/apex-crypto/ach-relationships/deposit?account_id=${this.props.cryptoAccount.accountId}`,
        {
          memo: 'achdeposit',
          relationshipId: this.state.achAccount.id,
          amount: this.state.amountValue,
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response?.data?.status) {
          this.toggleFormStatusMessage('Successfully deposited')
          this.props.getAllAccountInfo()
        } else {
          this.toggleError(this.props.t('somethingwentwrong'))
        }
      })
      .catch((err) => {
        this.toggleError(this.props.t('somethingwentwrong'))
      })
    this.setState({ isLoading: false })
  }
  //NOT TESTED
  withdraw = async () => {
    this.setState({ isLoading: true })
    if (
      this.state.activeTab === 1 &&
      (!this.state.achAccount ||
        this.state.selectedToAccount.name !== this.state.achAccount?.nickname)
    ) {
      await this.linkAccount(this.state.selectedToAccount)
    }
    await axios
      .post(
        `${ENDPOINT}/apex-crypto/ach-relationships/withdraw`,
        {
          memo: 'achWithdraw',
          relationshipId: this.state.achAccount.id,
          amount: this.state.amountValue,
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {})
      .catch((err) => {
        console.log('ach withdraw error', err)
      })
  }

  deleteACHaccount = async () => {
    this.setState({ editFormLoading: true })
    await axios
      .get(
        `${ENDPOINT}/apex-crypto/ach-relationships/delete?account_id=${this.props.cryptoAccount.accountId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data?.status) {
          this.toggleError('Account Removed Successfully')
          this.getACHaccount()
        } else {
          this.toggleError('Somthing went wrong. Please try again later')
        }
      })
      .catch((err) => {
        console.log('delete ach account error', err)
        this.toggleError('Somthing went wrong. Please try again later')
      })
  }

  validateForm() {
    if (
      this.props.cryptoAccount &&
      this.state.amountValue &&
      this.state.amountValue > 0 &&
      (this.state.selectedFromAccount || this.state.activeTab !== 0) &&
      (this.state.selectedToAccount || this.state.activeTab !== 1)
    ) {
      if (this.state.activeTab === 0) {
        if (this.state.amountValue <= 5000) {
          this.submitAPI()
        } else {
          this.toggleError(this.props.t('amountcannotbegreaterthan5000'))
        }
      } else {
        if (
          this.state.amountValue <=
          this.props.cryptoAccount.balances[0].cashAvailableForWithdrawal
        ) {
          this.submitAPI()
        } else {
          this.toggleError(this.props.t('cannotwithdrawmorethanyouown'))
        }
      }
    } else {
      this.toggleError(this.props.t('allfieldsarerequired'))
    }
  }

  handleAmountChange = (value) => {
    this.setState({ amountValue: value })
  }

  showMessage() {
    return <p className='status-message'>{this.state.formStatusMessage}</p>
  }

  showFormError() {
    return <p className='failure'>{this.state.formErrorMessage}</p>
  }

  toggleFormStatusMessage(msg) {
    this.setState({ formStatusMessage: msg })
    setTimeout(() => {
      this.setState({ formStatusMessage: '' })
    }, 5000)
  }

  toggleError(msg) {
    this.setState({ formErrorMessage: msg })
    setTimeout(() => {
      this.setState({ formErrorMessage: '' })
    }, 5000)
  }

  //TOGGLE DIALOGS
  toggleShowLinkACHdialog = () => {
    this.setState({ showLinkACHdialog: !this.state.showLinkACHdialog })
  }

  toggleShowConfirmACHdialog = () => {
    this.setState({ showConfirmACHdialog: !this.state.showConfirmACHdialog })
  }

  toggleEditOrDeleteForm() {
    this.setState({ showEditOrDeleteForm: !this.state.showEditOrDeleteForm })
  }

  toggleDeleteConfirmationForm() {
    this.setState({
      showDeleteConfirmationForm: !this.state.showDeleteConfirmationForm,
    })
  }

  closeAndToggleLinkACHform() {
    this.toggleEditOrDeleteForm()
    this.toggleShowLinkACHdialog()
  }

  closeAndToggleDeleteConfirmationForm() {
    this.toggleEditOrDeleteForm()
    this.toggleDeleteConfirmationForm()
  }

  tabs() {
    let selectedTab = {
      cursor: 'pointer',
      background: '#3F46F6',
      color: 'white',
      alignItems: 'center',
      marginRight: '15px',
      borderRadius: '15px',
      fontWeight: 'bold',
    }
    let unSelectedTab = {
      cursor: 'pointer',
      background: 'transparent',
      color: 'grey',
      marginRight: '15px',
      borderRadius: '15px',
      fontWeight: 'bold',
    }
    return (
      <div className='tabs-container'>
        <Button
          label={this.props.t('deposit')}
          onClick={() => this.setState({ activeTab: 'Add Cash' })}
          style={
            this.state.activeTab === 'Add Cash' ? selectedTab : unSelectedTab
          }
        />
        <Button
          label={this.props.t('withdraw')}
          onClick={() => this.setState({ activeTab: 'Cash Out' })}
          style={
            this.state.activeTab === 'Cash Out' ? selectedTab : unSelectedTab
          }
        />
      </div>
    )
  }

  handleFromAccountChange = (value) => {
    if (value.reconnect_plaid === 1) {
      this.setState({ showRelinkDialog: true })
    } else if (value.name === this.state.achPending?.nickname) {
      this.toggleError(this.props.t('thisaccountispendingapproval'))
    } else if (
      !this.state.achAccount ||
      this.state.achAccount.nickname !== value.name
    ) {
      this.toggleShowConfirmationDialog()
      this.setState({ accountToLink: value.accountId })
    } else {
      this.setState({
        selectedFromAccount: value,
      })
    }
  }

  handleToAccountChange = (value) => {
    if (value.reconnect_plaid === 1) {
      this.setState({ showRelinkDialog: true })
    } else if (value.name === this.state.achPending?.nickname) {
      this.toggleError(this.props.t('thisaccountispendingapproval'))
    } else if (
      !this.state.achAccount ||
      this.state.achAccount.nickname !== value.name
    ) {
      this.toggleShowConfirmationDialog()
      this.setState({ accountToLink: value.accountId })
    } else {
      this.setState({
        selectedToAccount: value,
      })
    }
  }

  fromAccountForm(label, showBalance) {
    return (
      <div className='from-account-form-container'>
        <p className='label'>{this.props.t('from')}:</p>
        {this.state.activeTab === 0 ? (
          <Dropdown
            value={
              this.state.selectedFromAccount?.length === 0
                ? this.state.noOptions
                : this.state.selectedFromAccount
            }
            options={this.state.bankingAccounts}
            onChange={(e) => this.handleFromAccountChange(e.value)}
            placeholder={this.props.t('selectanaccount')}
          />
        ) : (
          <InputText value={label} disabled />
        )}
        {showBalance ? (
          <p className='balance'>
            {this.props.t('available')}:{' '}
            <span className='dollar-amount'>
              {this.props.formatCurrency(this.displayFromBalance())}
            </span>
          </p>
        ) : null}
        {this.state.activeTab === 0 ? (
          <div className='edit-bank-button-container'>
            <LinkPlaidBtn
              user={this.props.user}
              getAllAccountInfo={this.props.getAllAccountInfo}
              isBank={true}
              showButton={true}
              reLink={false}
              t={this.props.t}
            />
            {/* {this.state.achAccount ? (
              <Button
                label='Edit Bank'
                onClick={() => {
                  this.toggleEditOrDeleteForm()
                }}
              />
            ) : (
              <>
                {this.state.achPending ? (
                  <Button
                    label='Verify Bank Account'
                    onClick={() => {
                      this.toggleShowConfirmACHdialog()
                    }}
                  />
                ) : (
                  <Button
                    label='Add Bank'
                    onClick={() => {
                      this.toggleShowLinkACHdialog()
                    }}
                  />
                )}
              </>
            )} */}
          </div>
        ) : null}
      </div>
    )
  }

  toAccountForm(label, showBalance) {
    return (
      <div className='to-account-form-container'>
        <p className='label'>{this.props.t('to')}:</p>
        {this.state.activeTab === 1 ? (
          <Dropdown
            value={
              this.state.selectedToAccount?.length === 0
                ? this.state.noOptions
                : this.state.selectedToAccount
            }
            options={this.state.bankingAccounts}
            onChange={(e) => this.handleToAccountChange(e.value)}
            placeholder={this.props.t('selectanaccount')}
          />
        ) : (
          <InputText value={label} disabled />
        )}
        {showBalance ? (
          <p className='balance'>
            {this.props.t('available')}:{' '}
            <span className='dollar-amount'>
              {this.props.formatCurrency(this.displayToBalance())}
            </span>
          </p>
        ) : null}
        {this.state.activeTab === 1 ? (
          <div className='edit-bank-button-container'>
            <LinkPlaidBtn
              user={this.props.user}
              getAllAccountInfo={this.props.getAllAccountInfo}
              isBank={true}
              showButton={true}
              reLink={false}
              t={this.props.t}
            />
            {/* {this.state.achAccount ? (
              <Button
                label='Edit Bank'
                onClick={() => {
                  this.toggleEditOrDeleteForm()
                }}
              />
            ) : (
              <>
                {this.state.achPending ? (
                  <Button
                    label='Verify Bank Account'
                    onClick={() => {
                      this.toggleShowConfirmACHdialog()
                    }}
                  />
                ) : (
                  <Button
                    label='Add Bank'
                    onClick={() => {
                      this.toggleShowLinkACHdialog()
                    }}
                  />
                )}
              </>
            )} */}
          </div>
        ) : null}
      </div>
    )
  }

  amountForm(type) {
    return (
      <div className='amount-form-container'>
        <p className='label'>{type}: </p>
        <InputNumber
          value={this.state.amountValue}
          onValueChange={(e) => this.handleAmountChange(e.value)}
          mode='currency'
          currency='USD'
          locale='en-US'
          maxFractionDigits={2}
          minFractionDigits={0}
        />
      </div>
    )
  }

  submitButton() {
    return (
      <div className='submit-button-container'>
        {!this.state.isLoading ? (
          <Button
            label={this.props.t('submit')}
            onClick={() => {
              this.validateForm()
            }}
          />
        ) : (
          <Button loading />
        )}
      </div>
    )
  }

  displayFromBalance() {
    return this.props.cryptoAccount?.balances[0]?.cashAvailableForWithdrawal
  }

  displayToBalance() {
    return this.props.cryptoAccount?.balances[0]?.current
  }

  submitAPI = async () => {
    switch (this.state.activeTab) {
      case 0:
        this.deposit()
        break
      case 1:
        this.withdraw()
        break
      default:
        break
    }
  }

  setActiveIndex = (index) => {
    this.setState({
      activeTab: index,
      amountValue: 0,
      isLoading: false,
      formError: false,
    })
  }

  loader() {
    return (
      <div style={{ width: '10%', margin: 'auto' }}>
        <Loader logoLoader={false} />
      </div>
    )
  }

  toggleShowConfirmationDialog = () => {
    this.setState({ showApexLinkDialog: !this.state.showApexLinkDialog })
  }

  render() {
    if (this.props.t) {
      return (
        <div className='transfer-funds-container'>
          <div className='tabs-container'>
            {!this.state.fetchingAccountsStatus ? (
              <TabView
                activeIndex={this.state.activeTab}
                onTabChange={(e) => this.setActiveIndex(e.index)}
              >
                <TabPanel header={this.props.t('deposit')}>
                  {this.fromAccountForm(this.state.achAccount?.nickname, false)}
                  {this.toAccountForm(this.props.cryptoAccount?.name, true)}
                  {this.amountForm(this.props.t('depositamount'))}
                  {this.showMessage()}
                  {this.showFormError()}
                  {this.submitButton()}
                </TabPanel>
                <TabPanel header={this.props.t('withdraw')}>
                  {this.fromAccountForm(this.props.cryptoAccount?.name, true)}
                  {this.toAccountForm(this.state.achAccount?.nickname, false)}
                  {this.amountForm(this.props.t('withdrawalamount'))}
                  {this.showMessage()}
                  {this.showFormError()}
                  {this.submitButton()}
                </TabPanel>
              </TabView>
            ) : (
              this.loader()
            )}
          </div>
          <Dialog
            visible={this.state.showEditOrDeleteForm}
            header={this.props.t('chooseanoption')}
            onHide={() => this.toggleEditOrDeleteForm()}
            style={{ width: this.props.isSmallScreen ? '90vw' : '25vw' }}
          >
            <div className='buttons-row-double'>
              <Button
                icon='pi pi-check'
                style={{
                  color: 'white',
                  background: '#3F46F6',
                  borderRadius: '15px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  this.closeAndToggleLinkACHform()
                }}
                label={this.props.t('editexistingachlinking')}
              />
              <Button
                icon='pi pi-trash'
                style={{
                  color: 'white',
                  background: '#3D5062',
                  borderRadius: '15px',
                }}
                label={this.props.t('deleteexistingachlinking')}
                onClick={() => {
                  this.closeAndToggleDeleteConfirmationForm()
                }}
              />
            </div>
          </Dialog>
          <LinkACH
            isSmallScreen={this.props.isSmallScreen}
            showDialog={this.state.showLinkACHdialog}
            toggleShowLinkACHdialog={this.toggleShowLinkACHdialog}
            cryptoAccount={this.props.cryptoAccount}
            user={this.props.user}
            getCryptoAccount={this.state.getCryptoAccount}
            getAllAccountInfo={this.props.getAllAccountInfo}
            achAccount={this.state.achAccount}
          />
          <ConfirmACH
            isSmallScreen={this.props.isSmallScreen}
            showDialog={this.state.showConfirmACHdialog}
            toggleShowConfirmACHdialog={this.toggleShowConfirmACHdialog}
            cryptoAccount={this.props.cryptoAccount}
            user={this.props.user}
            getACHaccount={this.getACHaccount}
            achPending={this.state.achPending}
          />
          <ConfirmDialog
            visible={this.state.showDeleteConfirmationForm}
            onHide={() => this.toggleDeleteConfirmationForm()}
            message={this.props.t('areyousureyouwanttoproceed')}
            header={this.props.t('confirmation')}
            accept={this.deleteACHaccount}
          />
          <RelinkPlaid
            showRelinkDialog={this.state.showRelinkDialog}
            toggleRelinkDialog={this.toggleRelinkDialog}
            user={this.props.user}
            isSmallScreen={this.props.isSmallScreen}
            getAllAccountInfo={this.props.getAllAccountInfo}
            t={this.props.t}
          />
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showApexLinkDialog}
            action={this.linkAccount}
            name={this.props.t('linkaccount')}
            successMessage={this.props.t('accountsuccessfullylinked')}
            customHeader={this.props.t('linkaccount')}
            customMessage={this.props.t('doyouwanttolinkthisaccount')}
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
