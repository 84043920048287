import React, { Component } from 'react'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import '../../../src/scss/components/DepositDialog.scss'
import LinkPlaidBtn from './LinkPlaidBtn'
export default class DepositDialog extends Component {
  state = {
    //DEPOSIT FORM
    depositAmount: 0,
    depositBankAccount: null,
    depositErrorMessage: '',
    depositSuccessMessage: '',
    isLoadingDeposit: false,
    bankAccounts: null,
  }

  componentDidMount = () => {
    this.getBankAccounts()
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (
  //     prevProps.accounts !== this.props.accounts &&
  //     this.state.isLoadingDeposit
  //   ) {
  //     this.setState({ isLoadingDeposit: false })
  //     this.toggleDepositSuccessMessage('Successfully deposited')
  //   }
  // }

  deposit = () => {
    this.setState({ isLoadingDeposit: true })
    let body = {
      dwAccountID: this.props.selectedAccount.accountId,
      plaidAccountID: this.state.depositBankAccount.accountId,
      sourceType: 'plaid',
      amount: this.state.depositAmount,
    }
    fetch(`${Endpoint}/dw/deposits/onetime?sandbox=${false}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(async (resp) => {
        if (resp.success) {
          await this.props.getAllAccountInfo()
          this.setState({ isLoadingDeposit: false })
          this.toggleDepositSuccessMessage(
            this.props.t('successfullydeposited')
          )
        } else {
          this.setState({ isLoadingDeposit: false })
          this.toggleDepositErrorMessage(this.props.t('somethignwentwrong'))
        }
      })
      .catch((err) => {
        this.setState({ isLoadingDeposit: false })
        this.toggleDepositErrorMessage(this.props.t('somethignwentwrong'))
      })
  }

  getBankAccounts = () => {
    let bankAccounts = []
    if (this.props.accounts) {
      this.props.accounts.forEach((account) => {
        if (account.type === 'depository')
          bankAccounts.push({ label: account.name, value: account })
      })
    }
    bankAccounts.push({ label: 'None', value: null })
    this.setState({ bankAccounts: bankAccounts })
  }

  handleDepositAmountChange = (value) => {
    this.setState({ depositAmount: value })
  }

  handleDepositBankAccountChange = (value) => {
    this.setState({ depositBankAccount: value })
  }

  //DEPOSIT FORM MESSAGE TOGGLES
  toggleDepositErrorMessage = (msg) => {
    this.setState({ depositErrorMessage: msg })
    setTimeout(() => {
      this.setState({ depositErrorMessage: '' })
    }, 2500)
  }

  toggleDepositSuccessMessage = (msg) => {
    this.setState({ depositSuccessMessage: msg })
    setTimeout(() => {
      this.setState({ depositSuccessMessage: '' })
    }, 2500)
  }

  validateDepositForm() {
    if (
      this.state.depositAmount &&
      this.state.depositAmount > 0 &&
      this.state.depositBankAccount
    ) {
      this.deposit()
    } else {
      this.toggleDepositErrorMessage(this.props.t('allfieldsarerequired'))
    }
  }

  depositDialog() {
    return (
      <Dialog
        visible={this.props.showDepositForm}
        closeOnEscape={!this.state.isLoadingDeposit}
        closable={!this.state.isLoadingDeposit}
        header={this.props.t('addcash')}
        onHide={() => this.props.toggleShowDepositForm()}
        style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
      >
        <div className='deposit-container'>
          <div className='input-container'>
            <p className='label'>{this.props.t('enteramount')}: </p>
            <InputNumber
              value={this.state.depositAmount}
              onValueChange={(e) => this.handleDepositAmountChange(e.value)}
              mode='currency'
              currency='USD'
              locale='en-US'
              className='amount-input'
              minFractionDigits={0}
              maxFractionDigits={2}
            />
          </div>
          <div className='input-container'>
            <p className='label'>{this.props.t('selectanaccount')}: </p>
            <Dropdown
              value={this.state.depositBankAccount}
              options={this.state.bankAccounts}
              onChange={(e) => this.handleDepositBankAccountChange(e.value)}
              placeholder={this.props.t('selectanaccount')}
              className='account-dropdown'
            />
          </div>
          <div className='link-btn-container'>
            <LinkPlaidBtn
              user={this.props.user}
              getAllAccountInfo={this.props.getAllAccountInfo}
              isBank={true}
              showButton={true}
              reLink={false}
              t={this.props.t}
            />
          </div>
          <p className='deposit-error-message'>
            {this.state.depositErrorMessage}
          </p>
          <p className='deposit-success-message'>
            {this.state.depositSuccessMessage}
          </p>
          {this.depositSubmitButton()}
        </div>
      </Dialog>
    )
  }

  depositSubmitButton = () => {
    return (
      <div className='deposit-button-container'>
        {!this.state.isLoadingDeposit ? (
          <Button
            label={this.props.t('submit')}
            className='deposit-submit-button'
            onClick={() => {
              this.validateDepositForm()
            }}
          />
        ) : (
          <Button
            label={this.props.t('submit')}
            className='deposit-submit-button'
            loading
          />
        )}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return <div>{this.depositDialog()}</div>
    } else return null
  }
}
