import React from 'react'
import Header from '../layout/Header'
// import Footer from "../layout/Footer";
import Portfolio from '../dashboard/Portfolio2'
import PortConstructor from '../dashboard/PortConstructor2'
import References from '../dashboard/References'
import PriceTrendPredictor from '../dashboard/PriceTrendPredictor2'
import MyStrats from '../dashboard/MyStrats2'
import Watchlist from '../dashboard/Watchlist2'
import WealthManagement from '../dashboard/WealthManagement2'
// import Invest from '../dashboard/WealthManagement2'
import Invest from '../dashboard/Invest'
import Invested from '../dashboard/Accounts'
// import Invested from '../dashboard/Invested2'
// import Invest from '../dashboard/Invested'
// import Budgeting from '../dashboard/Budgeting'
import Budgeting from '../budgeting/Budgeting'
import Account from '../dashboard/Account'
import { connect } from 'react-redux'
import ESGDashboard from '../dashboard/ESGDashboard'
import LoginHalf from '../loginHalf'
import SubModal from '../SubModal'
import { NavLink, withRouter } from 'react-router-dom'
import ARlogo from '../../assets/ar_logo.png'
import PrivateHeader from '../PrivateHeader'
import InSCITELogo from '../../assets/newUI/InSCITE_Final_Logo_Dark.svg'
import ForeSCITELogo from '../../assets/newUI/ForeSCITE_Final_Logo_Dark.png'
import BudgetingActive from '../../assets/newUI/budgetingActive.svg'
import BudgetingInactive from '../../assets/newUI/budgetingInactive.svg'
import ForeSciteNoText from '../../assets/newUI/ForesciteNoText.png'
import Footer from '../Footer'
import GetApp from '../../assets/newUI/getApp.png'
import ESGActive from '../../assets/newUI/lsg_active_3x.png'
import ESGInactive from '../../assets/newUI/lsg_inactive_3x.png'

import ConstructorActive from '../../assets/newUI/constructor-active.svg'
import ConstructorInactive from '../../assets/newUI/constructor-inactive.png'
import MypsActive from '../../assets/newUI/myps-active.svg'
import MypsInactive from '../../assets/newUI/myps-inactive.png'
import PtrendActive from '../../assets/newUI/ptrend-active.svg'
import PAccountActive from '../../assets/newUI/ptrend-active.svg'
import PAccountInactive from '../../assets/newUI/watchlist-inactive.png'

import PtrendInactive from '../../assets/newUI/ptrend-inactive.png'
import WatchlistActive from '../../assets/newUI/watchlist-active.svg'
import WatchlistInactive from '../../assets/newUI/watchlist-inactive.png'
import WmActive from '../../assets/newUI/wm-active.svg'
import WmInactive from '../../assets/newUI/wm-inactive.png'
import AppStore from '../../assets/newUI/apple-download@2x.png'
import GpStore from '../../assets/newUI/android-download@2x.png'
import DashBoardIcon from '../../assets/dashboard_icon.svg'
import WatchListIcon from '../../assets/watchlist_icon.svg'
import AccountsIcon from '../../assets/accounts_icon.svg'
import TradeIcon from '../../assets/trade_icon.svg'
import BudgetingIcon from '../../assets/budgeting_icon.svg'
import DashBoardIconSelected from '../../assets/dashboard_icon_selected.svg'
import WatchListIconSelected from '../../assets/watchlist_icon_selected.svg'
import AccountsIconSelected from '../../assets/accounts_icon_selected.svg'
import TradeIconSelected from '../../assets/trade_icon_selected.svg'
import BudgetingIconSelected from '../../assets/budgeting_icon_selected.svg'
import CompanyPage from '../../components/pages/Cp3'
import Axios from 'axios'
import Dashboard from '../dashboard/Dashboard'
import ENDPOINT from '../Endpoint'
import SearchEndpoint from '../SearchEndpoint'
import history from '../History'
import NotificationIcon from '../../assets/notifications_icon.svg'
import NotificationIconActive from '../../assets/notifications_icon_active.svg'
import { TailSpin } from 'react-loader-spinner'
import Endpoint from '../Endpoint'
import Notifications from '../Notifications'
import ChangeLanguage from '../components/ChangeLanguage'
import { Dialog } from 'primereact/dialog'
import FinraQuestionnaire from '../FinraQuestionnaire'
import '../../scss/pages/Dashboardv2.scss'
import DwApexQuestionnaire from '../DwApexQuestionnaire'
import Loader from '../components/accountInfo/Loader'
// import i18next from 'i18next'
import i18n from 'i18next'
import Cp3 from './Cp3'
import { fetchExistingInfo, fetchWatchList } from '../../services/userServices'
import { setIsCryptoAllowed, setUserData } from '../../features/user'
import { truncate } from 'lodash'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
const brandColor = '#00A99D'

class UserDashboard extends React.Component {
  state = {
    activeTab: '',
    // activeTab: 'Portfolio Constructor',
    myWatchlist: [],
    myWatchlistGroup: {},
    presetTickers: [],
    optimizedResults: {},
    userInputs: [],
    showListOptions: false,
    prevSet: [],
    subLevel: null,
    customPorts: {},
    userEmail: '',
    itemsResp: 'loading',
    // accounts: [],
    addAcc: false,
    holdings: [],
    optCash: 0,
    invStep: -1,
    prod: '',
    allAccounts: [],
    wlCanUpdate: true,
    unusualActivity: [],
    nameInitials: '-',
    holdingsMerged: null,
    allocations: null,
    userType: null,
    canTrade: null,
    finraStatus: true,
    finraAPIstatus: false,
    driveWealthStatus: false,
    apexStatus: true,
    dwApexAPIstatus: false,
    apexKYCunapprovedIds: [], //ids of unapproved ids
    internationalUser: false,
    dwKYCstatus: false,
    dwKYCid: '',
    userDetails: null,
    unlinkedAccounts: 0,
    defaultAccountTab: 'personal',
    dwExtendedHours: 0,
    extendedHoursAgreement: false,
    loading: false,
    allowExtendedHours: false,
    setExtendedLoading: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
    if (window.location.pathname !== `/Dashboard/${this.state.activeTab}`) {
      // console.log('inside CDM if')
      let tab = window.location.pathname.split('/')[2]
      this.setState({ activeTab: tab })
    }
    console.log('here', this.props.user)
    if (this.props.user) {
      this.subStatus()
      this.fetchWatchList()
      this.fetchUnusualActivity()
      // this.getPlaidItems()
      // this.getPlaidHoldings()
      this.getAllAccountInfo().then((res) => {
        this.checkApexKYC(res)
      })
      // this.props.getAccounts(this.props.user.idToken.jwtToken).then(()=>{this.setState({})})
      this.fetchCommonStocks()
      this.fetchCustomStrategies()
      // this.getPlaidAccounts()
      this.getMarketStatus()
      this.getUserFullName()
      this.checkDwKYC()
      this.fetchUserInfo()
    } else {
      this.props.history.push('/')
    }

    this.updateWindowDimensions()
    document.title = `Investall`
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  fetchUserInfo = async () => {
    // try {
    //   const res = await Axios.get(`${Endpoint}/mobile/fetch-existing-info`, {
    //     headers: {
    //       Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
    //     },
    //   })
    //   this.setState({ internationalUser: res?.data?.dwData?.country !== 'USA' })
    //   console.log('gere', res)
    // } catch (error) {
    //   console.log(error)
    // }

    const res = await fetchExistingInfo(this.props.user?.idToken?.jwtToken)
    console.log('exits', res)
    this.setState({ internationalUser: res?.dwData?.country !== 'USA' })
    this.props.setIsCryptoAllowed(res?.userData?.isCryptoAllowed)
    this.props.setUserData(res?.userData)
  }

  fetchCommonStocks = () => {
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?lists=true`
    let url = `${SearchEndpoint}/ticker-list`
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('asdsad', responseJson)
        this.setState({ presetTickers: responseJson.lists })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getMarketStatus = () => {
    // console.log('inside can trade')
    fetch(`${SearchEndpoint}/istradinghour`, {
      method: 'GET',
    })
      .catch((err) => {
        console.log(err)
      })
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('can trade resp: ', responseJson)
        this.setState({ canTrade: responseJson })
      })
      .catch((err) => {
        console.log('get market status error', err)
      })
  }

  getUserFullName = async () => {
    let url = `${ENDPOINT}/mobile/get-email`
    await Axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.firstName &&
          response.data.lastName
        ) {
          if (
            response.data.firstName.length !== 0 &&
            response.data.firstName.length !== 0
          ) {
            const initials =
              response.data.firstName.charAt(0).toUpperCase() +
              response.data.lastName.charAt(0).toUpperCase()
            this.setState({
              nameInitials: initials,
            })
          }
        }
        if (response?.data?.userType) {
          this.setState({ userType: response.data.userType })
        }
        if (response?.data?.prefLanguage) {
          if (response?.data?.prefLanguage === 'zh' && i18n.language !== 'zh') {
            i18n.changeLanguage('zh')
          }
          if (response?.data?.prefLanguage === 'en' && i18n.language !== 'en') {
            i18n.changeLanguage('en')
          }
          if (response?.data?.prefLanguage === 'pt' && i18n.language !== 'pt') {
            i18n.changeLanguage('pt')
          }
        }
        if (!this.state.nameInitials) {
          this.setState({
            nameInitials: '--',
          })
        }
      })
      .catch((err) => console.log(`catching errors`, err))
  }

  fetchCustomStrategies() {
    // fetch(`${ENDPOINT}/risk-monkey/portfolios/ALL_PORTFOLIOS`, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
    //     'Content-Type': 'application/json',
    //   },
    // })
    //   .then((res) => res.json())
    //   .catch((err) => console.log('custom strategy error message: ', err))
    //   .then((responseJson) => {
    //     // console.log('custom strategy success resp: ',responseJson)
    //     this.setState({ customPorts: { ...responseJson } })
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  toInvest = () => {
    new Promise(() => this.getAllAccountInfo())
      .then(this.setState({ addAcc: false }))
      .catch((err) => {
        console.log(err)
      })
  }

  //FINRA STUFF
  setFinraStatus = () => {
    this.setState({ finraStatus: true })
  }

  toggleFinraAPIstatus = (val) => {
    this.setState({ finraAPIstatus: val })
  }

  //DWAPEX STUFF
  setDwApexStatus = () => {
    this.setState({ driveWealthStatus: true })
  }
  toggleDWAPEXAPIstatus = (val) => {
    this.setState({ dwApexAPIstatus: val })
  }

  addAcc = (step, prod) => {
    console.log('stepVest: ', step)
    console.log('prodVest: ', prod)
    let invStep = -1
    if (step) {
      invStep = step
    }
    this.setState({ addAcc: true, prod, invStep })
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  changeTab = (tab) => {
    console.log(tab)
    this.setState({ activeTab: tab })
  }

  checkDwKYC = async () => {
    await Axios.get(`${Endpoint}/mobile/dw/kyc`, {
      headers: {
        Authorization: 'Bearer ' + this.props?.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        // if (res) {
        //   if (res?.data?.payload?.kyc?.status?.name === 'KYC_PROCESSING') {
        //     this.setState({ dwKYCpending: true })
        //   } else if (res?.data?.payload?.kyc?.status?.name !== 'KYC_APPROVED') {
        //     this.setState({ dwKYCapproved: res?.data?.payload?.userID })
        //   }
        // }
        const status = res?.data?.payload?.kyc?.status?.name
        if (
          status === 'KYC_DOC_REQUIRED' ||
          status === 'KYC_NOT_READY' ||
          status === 'KYC_READY' ||
          status === 'DOC_REQUIRED'
        ) {
          this.setState({
            dwKYCstatus: 'not approved',
            dwKYCid: res?.data?.payload?.userID,
          })
        } else if (status === 'KYC_APPROVED') {
          this.setState({
            dwKYCstatus: 'approved',
            dwKYCid: res?.data?.payload?.userID,
          })
        } else {
          this.setState({
            dwKYCstatus: 'pending',
            dwKYCid: res?.data?.payload?.userID,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  checkApexKYC = async (allAccounts) => {
    let cryptoId = null
    let aiCryptoId = null

    allAccounts.forEach((account) => {
      if (account.product_assigned === 'crypto') {
        cryptoId = account.accountId
      }
      if (account.product_assigned === 'group-crypto') {
        aiCryptoId = account.accountId
      }
    })

    if (aiCryptoId) {
      await Axios.get(
        `${Endpoint}/apex-crypto/apex-kyc?account_id=${aiCryptoId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props?.user?.idToken?.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => {
          console.log(res)
          if (res) {
            if (
              res?.data?.status !== 'SUCCESS' &&
              res?.data?.status !== 'COMPLETE'
            ) {
              let apexKYCunapprovedIds = [...this.state.apexKYCunapprovedIds]
              apexKYCunapprovedIds.push({
                id: aiCryptoId,
                message: res?.data?.messages[0]?.message,
              })
              this.setState({
                apexKYCunapprovedIds: apexKYCunapprovedIds,
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    if (cryptoId) {
      await Axios.get(
        `${Endpoint}/apex-crypto/apex-kyc?account_id=${cryptoId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props?.user?.idToken?.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => {
          if (res) {
            if (
              res?.data?.status !== 'SUCCESS' &&
              res?.data?.status !== 'COMPLETE'
            ) {
              let apexKYCunapprovedIds = [...this.state.apexKYCunapprovedIds]
              apexKYCunapprovedIds.push({
                id: cryptoId,
                message: res?.data?.messages[0]?.message,
              })
              this.setState({
                apexKYCunapprovedIds: apexKYCunapprovedIds,
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  subStatus = () => {
    // subscription status and user email
    console.log('fetching sub status')
    let URL = `${ENDPOINT}/users?details=false`
    fetch(URL, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(async (responseJson) => {
        // console.log('details=trueyes resp',responseJson)
        // let userEmail = responseJson.email
        // console.log('emailX:', userEmail)
        console.log('substats', responseJson)
        this.setState({ userDetails: responseJson })
        if (
          responseJson?.accountSub?.source === 'apple' ||
          responseJson?.accountSub?.source === 'manual'
        ) {
          // console.log('fetching apple data')
          // await Axios.get(`${Endpoint}/stripe/subscriptions/status`, {
          //   headers: {
          //     Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          //     'Content-Type': 'application/json',
          //   },
          // }).then((res2) => {
          //   console.log(res2)
          //   this.setState({
          //     // subLevel: responseJson.accountSub.productId,
          //     subStatus: responseJson?.status?.sub,
          //     // freeTrial: responseJson.validSub,
          //     userEmail: responseJson?.email,
          //     subStatusExpirationDate: responseJson?.accountSub?.expiration,
          //     subStatusMessage: responseJson?.accountSub?.status,
          //     appleStatus: true,
          //   })
          // })
          this.setState({
            // subLevel: responseJson.accountSub.productId,
            subStatus: responseJson?.status?.sub,
            // freeTrial: responseJson.validSub,
            userEmail: responseJson?.email,
            subStatusExpirationDate: responseJson?.accountSub?.expiration,
            subStatusMessage: responseJson?.accountSub?.status,
            appleStatus: true,
          })
        } else {
          this.setState({
            // subLevel: responseJson.accountSub.productId,
            subStatus: responseJson?.status?.sub,
            // freeTrial: responseJson.validSub,
            userEmail: responseJson?.email,
            subStatusExpirationDate: responseJson?.accountSub?.expiration,
            subStatusMessage: responseJson?.accountSub?.status,
            appleStatus: false,
          })
        }
      })
      .catch((err) => console.log('err sub status: ', err))
  }

  alterWatchlist(symbol, addRemove) {
    // add and remove from watchlist
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({ [addRemove]: [symbol] }),
    })
      .then(this.fetchWatchList())
      .catch((err) => {
        console.log(err)
      })
  }

  // fetchWatchList = () => {
  //   // get someones watchlist data
  //   fetch(`${ENDPOINT}/mobile/watchlist`, {
  //     method: 'GET',
  //     headers: {
  //       Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       let myWatchlist = responseJson.watchlist
  //       if (this.state.wlCanUpdate) {
  //         this.setState({ myWatchlist })
  //       }
  //     })
  //     .catch((error) => console.log(error))
  // }

  fetchWatchList = async () => {
    const res = await fetchWatchList(this.props.user?.idToken?.jwtToken)
    let myWatchlist = []
    if (res) {
      Object.values(res.watchlist).forEach((item) => {
        myWatchlist = [...myWatchlist, ...item]
      })

      this.setState({
        myWatchlist: myWatchlist,
        myWatchlistGroup: res,
      })
    }
  }

  fetchUnusualActivity = () => {
    fetch(`${SearchEndpoint}/unusual-activity`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          unusualActivity: responseJson,
        })
      })
      .catch((error) => console.log(error))
  }

  turnOnWlUpdates = () => {
    this.setState({ wlCanUpdate: true })
  }

  turnOffWlUpdates = () => {
    this.setState({ wlCanUpdate: false })
  }
  /*
    getPlaidHoldings = ()=>{ 
        // all holdings of all linked accounts combined
        fetch(`${ENDPOINT}/plaid/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }
    */

  formatCurrency(val) {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    if (!val) return numberFormat.format(0)
    return numberFormat.format(val)
  }

  getAllAccountInfo = async () => {
    // SUPER IMPORTANT gets the holdings and account information
    console.log('getting it all')
    return new Promise((resolve, reject) => {
      fetch(`${ENDPOINT}/mobile/user/holdings`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        //   .then(responseJson => this.setState({holdings: responseJson}))
        .then((responseJson) => {
          if (responseJson) {
            console.log('allaccountsinfo', responseJson)
            let unlinkedAccounts = 0
            let allAccounts = []
            this.setState({
              holdingsMerged: responseJson.holdingsMerged,
              allocations: responseJson.allocations,
              totalInvestment: responseJson.investment_allocations?.sum,
              finraStatus: responseJson.finraStatus === 'Complete',
              dwExtendedHours: responseJson.dwExtendedHours,
            })
            let driveWealthStatus = false
            let apexStatus = false
            responseJson.items.forEach((inst) => {
              inst.accounts.forEach((acc) => {
                //   console.log(acc.name,acc.type )
                if (
                  acc.type === 'depository' ||
                  acc.type === 'investment' ||
                  acc.type === 'credit'
                ) {
                  if (acc.reconnect_plaid === 1) unlinkedAccounts++
                  allAccounts.push({ ...acc, inst: inst.instName })
                }
              })
              if (inst.instName === 'DriveWealth') driveWealthStatus = true
              if (inst.instName === 'Apex') apexStatus = true
            })
            //   console.log('neten all parsed: ',allAccounts)
            this.setState({
              allAccounts,
              itemsResp: responseJson.items,
              driveWealthStatus,
              apexStatus,
              unlinkedAccounts,
            })
            console.log('updated accounts: ', allAccounts)
            resolve(allAccounts)
          }
        })
        .catch((err) => console.log(`catching errors`, err))
    })
  }

  getAllAccountInfoV2 = async () => {
    console.log('getting it all v2')
    return await Axios.get(`${ENDPOINT}/mobile/user/holdings`, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      //   .then(responseJson => this.setState({holdings: responseJson}))
      .then(async (responseJson) => {
        if (responseJson?.data) {
          let unlinkedAccounts = 0
          console.log('allaccountsinfov2', responseJson?.data)
          let allAccounts = []
          this.setState({
            holdingsMerged: responseJson.data.holdingsMerged,
            allocations: responseJson.data.allocations,
            totalInvestment: responseJson.data.investment_allocations?.sum,
            finraStatus: responseJson.data.finraStatus === 'Complete',
          })
          let driveWealthStatus = false
          let apexStatus = false
          responseJson.data.items.forEach((inst) => {
            inst.accounts.forEach((acc) => {
              //   console.log(acc.name,acc.type )
              if (
                acc.type === 'depository' ||
                acc.type === 'investment' ||
                acc.type === 'credit'
              ) {
                if (acc.reconnect_plaid === 1) unlinkedAccounts++
                allAccounts.push({ ...acc, inst: inst.instName })
              }
            })
            if (inst.instName === 'DriveWealth') driveWealthStatus = true
            if (inst.instName === 'Apex') apexStatus = true
          })
          //   console.log('neten all parsed: ',allAccounts)
          this.setState({
            allAccounts,
            itemsResp: responseJson.data.items,
            driveWealthStatus,
            apexStatus,
            unlinkedAccounts,
          })
          console.log('updated accounts v2: ', allAccounts)
          await this.checkApexKYC(allAccounts)
          await this.checkDwKYC()
          await this.fetchUserInfo()
          return true
        } else return false
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        return false
      })
  }

  // getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
  //     fetch(`${ENDPOINT}/plaid/items`,{
  //         method: "GET",
  //         headers:{
  //           Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //           'Content-Type': 'application/json'
  //         },
  //       })
  //       .then(res => res.json())
  //       .then(responseJson => {
  //         //   console.log('plaid items resp: ',responseJson)
  //           this.setState({itemsResp: responseJson})
  //         })
  //       .catch(err=>console.log(`catching errors`, err))
  // }

  // getPlaidAccounts = ()=>{ // an Account is one account at one place (ie. one account at robinhood)
  //     fetch(`${ENDPOINT}/plaid/accounts`,{
  //         method: "GET",
  //         headers:{
  //           Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //           'Content-Type': 'application/json'
  //         },
  //       })
  //       .then(res => res.json())
  //       .then(responseJson => {
  //           console.log('plaid Accounts response', responseJson)
  //           this.setState({accounts:responseJson})
  //       })
  //       .catch(err=>console.log(`catching errors`, err))
  // }

  // updatePaymentStatus = ()=>{
  //     this.setState({subLevel:'Wealth Management', freeTrial: false, subStatus:true})
  // }

  downgradePaymentStatus = () => {
    this.setState({ subLevel: 'Basic', freeTrial: false, subStatus: false })
  }

  arrayToObject = (arr) => {
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].listTitle] = arr[i].tickers
    }
    return result
  }

  optimizeButton = (newData, userInputs, prevSet, excluded, optCash) => {
    // PASSED THROUGH PROPS TO CONSTRUCTOR PARSES RESULTS OF OPTIMIZATION AND BRINGS YOU TO PORTFOLIO2
    console.log('optimizeButton = running')
    // let sorted = userInputs.sort((a, b) => (a.symbol > b.symbol) ? 1 : -1)
    if (window.location.pathname !== `/Dashboard/Portfolio`) {
      history.push(`/Dashboard/Portfolio`)
    }
    this.setState({
      activeTab: 'Portfolio',
      optimizedResults: { ...newData },
      userInputs: { ...userInputs },
      prevSet: [...prevSet],
      excluded: [...excluded],
      optCash,
    })
  }

  resetPrevSet = () => {
    // NEEDED FOR CLEARING CONSTRUCTOR
    this.setState({ prevSet: [], optCash: 0 })
  }

  toPc = () => {
    // TO CONSTRUCTOR
    window.scrollTo(0, 325)
    if (window.location.pathname !== `/Dashboard/PortfolioConstructor`) {
      history.push(`/Dashboard/PortfolioConstructor`)
    }
    this.setState({ activeTab: 'PortfolioConstructor' })
  }
  // toMs = ()=>{
  //     window.scrollTo(0, 325)
  //     if (window.location.pathname !== `/Dashboard/MyStrategies`){
  //         history.push(`/Dashboard/MyStrategies`)
  //     }
  //     this.setState({activeTab:'MyStrategies'})
  // }

  toWm = () => {
    window.scrollTo(0, 325)
    if (window.location.pathname !== `/Dashboard/WealthManagement`) {
      history.push(`/Dashboard/WealthManagement`)
    }
    this.setState({ activeTab: 'WealthManagement' })
  }

  updateCustoms = (customPorts) => {
    this.setState({ customPorts })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  changeDefaultAccountTab = (val) => {
    this.setState({ defaultAccountTab: val })
  }

  turnOnExternalHours = async () => {
    try {
      this.setState({ setExtendedLoading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=1`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.getAllAccountInfo()
      this.setState({ setExtendedLoading: false })
    } catch (error) {
      console.log(error)
    }
  }

  turnOffExternalHours = async () => {
    try {
      this.setState({ setExtendedLoading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=0`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.getAllAccountInfo()
      this.setState({ setExtendedLoading: false })
    } catch (error) {
      console.log(error)
    }
  }

  showAllTabs = () => {
    if (this.props.user) {
      // switch (this.state.activeTab) {
      let locations = window.location.pathname.split('/')
      let path = locations[2]
      switch (
        path /** PREVIOUSLY THE SWITCH WAS BASED ON ACTIVETAB. DUE TO LACK OF REDUX OR MOBX WE HAVE HACKED THIS UP TO CHANGE THE PATHNAME AND STATE   */
      ) {
        case 'PortfolioConstructor':
          return (
            <PortConstructor
              resetPrevSet={this.resetPrevSet}
              linkedPorts={this.state.itemsResp}
              customPorts={this.state.customPorts}
              prevSet={this.state.prevSet}
              toWm={this.toWm}
              myWatchlist={this.state.myWatchlist}
              optCash={this.state.optCash}
              addWl={this.addToWl}
              removeWl={this.removeFromWl}
              optimizeButton={this.optimizeButton}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Constructor':
          return (
            <PortConstructor
              resetPrevSet={this.resetPrevSet}
              linkedPorts={this.state.itemsResp}
              customPorts={this.state.customPorts}
              prevSet={this.state.prevSet}
              toWm={this.toWm}
              myWatchlist={this.state.myWatchlist}
              optCash={this.state.optCash}
              addWl={this.addToWl}
              removeWl={this.removeFromWl}
              optimizeButton={this.optimizeButton}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Portfolio':
          return (
            <Portfolio
              accounts={this.state.allAccounts}
              linkedPorts={this.state.itemsResp}
              userEmail={this.state.userEmai}
              updatePaymentStatus={this.updatePaymentStatus}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              subLevel={this.state.subLevel}
              fetchCustomStrategies={this.fetchCustomStrategies}
              refreshStrats={this.fetchCustomStrategies}
              toPc={this.toPc}
              data={this.state.optimizedResults}
              userInputs={this.state.userInputs}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'WealthManagement':
          return (
            <WealthManagement
              toMs={this.toMs}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Accounts':
          if (this.state.itemsResp === 'loading') {
            return (
              // <div
              //   style={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     width: "100%",
              //     marginBottom: 100,
              //   }}
              // >
              //   <p style={{ fontSize: 28, fontWeight: "bold" }}>Loadings...</p>
              // </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader logoLoader={true} />
              </div>
            )
          }
          if (this.state.itemsResp.length > 0 && this.state.addAcc === false) {
            //MY ACCOUNTS
            return (
              <Invested
                userType={this.state.userType}
                t={this.props.t}
                getAllAccountInfo={this.getAllAccountInfo}
                accounts={this.state.allAccounts}
                subStatus={this.state.subStatus}
                updateAccs={this.getAllAccountInfo}
                presetTickers={this.state.presetTickers}
                addAcc={this.addAcc}
                toMs={this.toMs}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
                canTrade={this.state.canTrade}
                scrollToTop={this.props.scrollToTop}
                driveWealthStatus={this.state.driveWealthStatus}
                apexStatus={this.state.apexStatus}
                getAllAccountInfoV2={this.getAllAccountInfoV2}
                dwKYCstatus={this.state.dwKYCstatus}
                dwKYCid={this.state.dwKYCid}
                apexKYCunapprovedIds={this.state.apexKYCunapprovedIds}
                internationalUser={this.state.internationalUser}
                getUserFullName={this.getUserFullName}
                signOut={this.props.signOut}
                downgradePaymentStatus={this.downgradePaymentStatus}
                updateCustoms={this.updateCustoms}
                customPorts={this.state.customPorts}
                userEmail={this.state.userEmail}
                subStatusMessage={this.state.subStatusMessage}
                subStatusExpirationDate={this.state.subStatusExpirationDate}
                getSubStatus={this.subStatus}
                appleStatus={this.state.appleStatus}
                userDetails={this.state.userDetails}
              />
            )
          } else {
            return (
              <Invested
                userType={this.state.userType}
                t={this.props.t}
                getAllAccountInfo={this.getAllAccountInfo}
                accounts={this.state.allAccounts}
                subStatus={this.state.subStatus}
                updateAccs={this.getAllAccountInfo}
                presetTickers={this.state.presetTickers}
                addAcc={this.addAcc}
                toMs={this.toMs}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
                canTrade={this.state.canTrade}
                scrollToTop={this.props.scrollToTop}
                driveWealthStatus={this.state.driveWealthStatus}
                apexStatus={this.state.apexStatus}
                getAllAccountInfoV2={this.getAllAccountInfoV2}
                dwKYCstatus={this.state.dwKYCstatus}
                dwKYCid={this.state.dwKYCid}
                apexKYCunapprovedIds={this.state.apexKYCunapprovedIds}
                internationalUser={this.state.internationalUser}
                getUserFullName={this.getUserFullName}
                signOut={this.props.signOut}
                downgradePaymentStatus={this.downgradePaymentStatus}
                updateCustoms={this.updateCustoms}
                customPorts={this.state.customPorts}
                userEmail={this.state.userEmail}
                subStatusMessage={this.state.subStatusMessage}
                subStatusExpirationDate={this.state.subStatusExpirationDate}
                getSubStatus={this.subStatus}
                appleStatus={this.state.appleStatus}
                userDetails={this.state.userDetails}
              />
              // <Invest
              //   userType={this.state.userType}
              //   t={this.props.t}
              //   getAllAccountInfo={this.getAllAccountInfo}
              //   subStatus={this.state.subStatus}
              //   updateAccs={this.getAllAccountInfo}
              //   presetTickers={this.state.presetTickers}
              //   prod={this.state.prod}
              //   invStep={this.state.invStep}
              //   accounts={this.state.allAccounts}
              //   toInvested={this.toInvest}
              //   toMs={this.toMs}
              //   customPorts={this.state.customPorts}
              //   user={this.props.user}
              //   toPc={this.toPc}
              //   isSmallScreen={this.state.isSmallScreen}
              //   linkedPorts={this.state.itemsResp}
              //   canTrade={this.state.canTrade}
              //   scrollToTop = {this.props.scrollToTop}
              // />
            )
          }
        case 'Watchlist':
          return (
            <Watchlist
              userType={this.state.userType}
              t={this.props.t}
              turnOffWlUpdates={this.turnOffWlUpdates}
              turnOnWlUpdates={this.turnOnWlUpdates}
              activeTab={this.state.activeTab}
              refreshWl={this.fetchWatchList}
              refreshUA={this.fetchUnusualActivity}
              unusualActivity={this.state.unusualActivity}
              myWatchlist={this.state.myWatchlist}
              myWatchlistGroup={this.state.myWatchlistGroup}
              removeFromWl={this.removeFromWl}
              addWl={this.addToWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        // case 'TradeCenter':
        //     return <Invest p updateAccs={this.getAllAccountInfo}resetTickers={this.state.presetTickers} prod={this.state.prod} invStep={this.state.invStep} accounts={this.state.allAccounts} toInvested={this.toInvest} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
        case 'References':
          return <References isSmallScreen={this.state.isSmallScreen} />
        // case 'PriceTrendPredictor':
        //     return <PriceTrendPredictor presetTickers={this.state.presetTickers} dash={false} isSmallScreen={this.state.isSmallScreen}/>
        case 'MyStrategies':
          return (
            <MyStrats
              linkedPorts={this.state.itemsResp}
              refreshStrats={this.fetchCustomStrategies}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'MyWatchlist':
          return (
            <Watchlist
              userType={this.state.userType}
              t={this.props.t}
              turnOffWlUpdates={this.turnOffWlUpdates}
              turnOnWlUpdates={this.turnOnWlUpdates}
              activeTab={this.state.activeTab}
              refreshWl={this.fetchWatchList}
              refreshUA={this.fetchUnusualActivity}
              unusualActivity={this.state.unusualActivity}
              myWatchlist={this.state.myWatchlist}
              myWatchlistGroup={this.state.myWatchlistGroup}
              removeFromWl={this.removeFromWl}
              addWl={this.addToWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Budgeting':
          return (
            // <Budgeting
            //   bankConnected={false}
            //   user={this.props.user}
            //   isSmallScreen={this.state.isSmallScreen}
            // />
            <Budgeting
              userType={this.state.userType}
              {...this.props}
              isSmallScreen={this.state.isSmallScreen}
              getAllAccountInfo={this.getAllAccountInfo}
              subStatus={this.state.subStatus}
              accounts={this.state.allAccounts}
              updateAccs={this.getAllAccountInfo}
              presetTickers={this.state.presetTickers}
              addAcc={this.addAcc}
              toMs={this.toMs}
              toPc={this.toPc}
              canTrade={this.state.canTrade}
              scrollToTop={this.props.scrollToTop}
              driveWealthStatus={this.state.driveWealthStatus}
              apexStatus={this.state.apexStatus}
              getAllAccountInfoV2={this.getAllAccountInfoV2}
              dwKYCstatus={this.state.dwKYCstatus}
              dwKYCid={this.state.dwKYCid}
              apexKYCunapprovedIds={this.state.apexKYCunapprovedIds}
              internationalUser={this.state.internationalUser}
              getUserFullName={this.getUserFullName}
              signOut={this.props.signOut}
              downgradePaymentStatus={this.downgradePaymentStatus}
              updateCustoms={this.updateCustoms}
              customPorts={this.state.customPorts}
              userEmail={this.state.userEmail}
              subStatusMessage={this.state.subStatusMessage}
              subStatusExpirationDate={this.state.subStatusExpirationDate}
              getSubStatus={this.subStatus}
              appleStatus={this.state.appleStatus}
              userDetails={this.state.userDetails}
              changeTab={this.changeTab}
            />
          )
        case 'ESGDashboard':
          return (
            <ESGDashboard
              presetTickers={this.state.presetTickers}
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Account':
          return (
            <Account
              userType={this.state.userType}
              signOut={this.props.signOut}
              t={this.props.t}
              downgradePaymentStatus={this.downgradePaymentStatus}
              toPc={this.toPc}
              updateCustoms={this.updateCustoms}
              customPorts={this.state.customPorts}
              accounts={this.state.allAccounts}
              userEmail={this.state.userEmail}
              subStatus={this.state.subStatus}
              subStatusMessage={this.state.subStatusMessage}
              subStatusExpirationDate={this.state.subStatusExpirationDate}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
              getAllAccountInfo={this.getAllAccountInfo}
              dwKYCstatus={this.state.dwKYCstatus}
              dwKYCid={this.state.dwKYCid}
              apexKYCunapprovedIds={this.state.apexKYCunapprovedIds}
              getSubStatus={this.subStatus}
              appleStatus={this.state.appleStatus}
              getAllAccountInfoV2={this.getAllAccountInfoV2}
              apexStatus={this.state.apexStatus}
              driveWealthStatus={this.state.driveWealthStatus}
              defaultAccountTab={this.state.defaultAccountTab}
              dwExtendedHours={this.state.dwExtendedHours}
            />
          )
        case 'Trade':
          if (this.state.itemsResp === 'loading') {
            return (
              // <div
              //   style={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     width: "100%",
              //     marginBottom: 100,
              //   }}
              // >
              //   <p style={{ fontSize: 28, fontWeight: "bold" }}>Loadings...</p>
              // </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader logoLoader={true} />
              </div>
            )
          } else {
            if (locations.length > 3) {
              return (
                <CompanyPage
                  t={this.props.t}
                  user={this.props.user}
                  symbol={locations[3]}
                  allAccounts={this.state.allAccounts}
                  getAllAccountInfo={this.getAllAccountInfoV2}
                  myWatchlist={this.state.myWatchlist}
                  myWatchlistGroup={this.state.myWatchlistGroup}
                  refreshWl={this.fetchWatchList}
                  removeFromWl={this.removeFromWl}
                  dwExtendedHours={this.state.dwExtendedHours}
                  updateUserHoldings={this.getAllAccountInfo}
                />
              )
            } else {
              return (
                <PriceTrendPredictor
                  userType={this.state.userType}
                  t={this.props.t}
                  presetTickers={this.state.presetTickers}
                  dash={false}
                  isSmallScreen={this.state.isSmallScreen}
                  formatCurrency={this.formatCurrency}
                />
              )
            }
          }
        default:
          if (this.state.itemsResp === 'loading') {
            return (
              // <div
              //   style={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     width: "100%",
              //     marginBottom: 100,
              //   }}
              // >
              //   <p style={{ fontSize: 28, fontWeight: "bold" }}>Loading...</p>
              // </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader logoLoader={true} />
              </div>
            )
          } else {
            return (
              <Dashboard
                userType={this.state.userType}
                t={this.props.t}
                formatCurrency={this.formatCurrency}
                accounts={this.state.allAccounts}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
                holdingsMerged={this.state.holdingsMerged}
                allocations={this.state.allocations}
                totalInvestment={this.state.totalInvestment}
                apexKYCunapprovedIds={this.state.apexKYCunapprovedIds}
                getAllAccountInfoV2={this.getAllAccountInfoV2}
                dwKYCstatus={this.state.dwKYCstatus}
                dwKYCid={this.state.dwKYCid}
                unlinkedAccounts={this.state.unlinkedAccounts}
                history={history}
                changeDefaultAccountTab={this.changeDefaultAccountTab}
              />
            )
          }
      }
    } else {
      switch (this.state.activeTab) {
        // case 'Portfolio Constructor':
        //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        // case 'WealthManagement':
        //     return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
        case 'PriceTrendPredictor':
          return (
            <PriceTrendPredictor
              t={this.props.t}
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        default:
          return (
            <LoginHalf
              t={this.props.t}
              errors={this.props.errors}
              signIn={this.props.signIn}
              signOut={this.props.signOut}
              user={this.props.user}
            />
          )
      }
    }
  }

  showTabsPaywall = () => {
    if (this.props.user) {
      // console.log('paywall freeTrial',this.state.freeTrial)
      // console.log('paywall sublevel',this.state.subLevel)
      // console.log('paywall substatus',this.state.subStatus)
      // if(this.state.freeTrial === true || this.state.subLevel === "arapp_monthly" || this.state.subLevel === "prod_FaN0loj0oRP7de"){

      // console.log('basic')
      let path = window.location.pathname.split('/')[2]
      switch (
        path /** PREVIOUSLY THE SWITCH WAS BASED ON ACTIVETAB. DUE TO LACK OF REDUX OR MOBX WE HAVE HACKED THIS UP TO CHANGE THE PATHNAME AND STATE   */
      ) {
        case 'PortfolioConstructor':
          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
          return (
            <PortConstructor
              resetPrevSet={this.resetPrevSet}
              linkedPorts={this.state.itemsResp}
              customPorts={this.state.customPorts}
              prevSet={this.state.prevSet}
              toWm={this.toWm}
              myWatchlist={this.state.myWatchlist}
              optCash={this.state.optCash}
              addWl={this.addToWl}
              removeWl={this.removeFromWl}
              optimizeButton={this.optimizeButton}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Budgeting':
          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
          return (
            <Budgeting
              bankConnected={false}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'ESGDashboard':
          return (
            <ESGDashboard
              presetTickers={this.state.presetTickers}
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Constructor':
          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
          return (
            <PortConstructor
              resetPrevSet={this.resetPrevSet}
              linkedPorts={this.state.itemsResp}
              customPorts={this.state.customPorts}
              prevSet={this.state.prevSet}
              toWm={this.toWm}
              myWatchlist={this.state.myWatchlist}
              optCash={this.state.optCash}
              addWl={this.addToWl}
              removeWl={this.removeFromWl}
              optimizeButton={this.optimizeButton}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Portfolio':
          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
          return (
            <Portfolio
              accounts={this.state.allAccounts}
              linkedPorts={this.state.itemsResp}
              userEmail={this.state.userEmai}
              updatePaymentStatus={this.updatePaymentStatus}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
              fetchCustomStrategies={this.fetchCustomStrategies}
              refreshStrats={this.fetchCustomStrategies}
              toPc={this.toPc}
              data={this.state.optimizedResults}
              userInputs={this.state.userInputs}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'WealthManagement':
          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
          return (
            <WealthManagement
              toMs={this.toMs}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Invest':
          if (this.state.itemsResp.length > 0 && this.state.addAcc === false) {
            return (
              <Invested
                updateAccs={this.getAllAccountInfo}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                presetTickers={this.state.presetTickers}
                addAcc={this.addAcc}
                toMs={this.toMs}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
                accounts={this.state.allAccounts}
              />
            )
          } else {
            return (
              <Invest
                updateAccs={this.getAllAccountInfo}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                presetTickers={this.state.presetTickers}
                prod={this.state.prod}
                invStep={this.state.invStep}
                accounts={this.state.allAccounts}
                toInvested={this.toInvest}
                toMs={this.toMs}
                customPorts={this.state.customPorts}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
                linkedPorts={this.state.itemsResp}
              />
            )
          }
        // case 'References':
        //     return <References isSmallScreen={this.state.isSmallScreen}/>
        case 'PriceTrendPredictor':
          return (
            <PriceTrendPredictor
              presetTickers={this.state.presetTickers}
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        // case 'MyStrategies':
        //     return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
        case 'MyWatchlist':
          return (
            <Watchlist
              turnOffWlUpdates={this.turnOffWlUpdates}
              turnOnWlUpdates={this.turnOnWlUpdates}
              activeTab={this.state.activeTab}
              refreshWl={this.fetchWatchList}
              refreshUA={this.fetchUnusualActivity}
              unusualActivity={this.state.unusualActivity}
              myWatchlist={this.state.myWatchlist}
              myWatchlistGroup={this.state.myWatchlistGroup}
              removeFromWl={this.removeFromWl}
              addWl={this.addToWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case '':
          return (
            <Watchlist
              turnOffWlUpdates={this.turnOffWlUpdates}
              turnOnWlUpdates={this.turnOnWlUpdates}
              activeTab={this.state.activeTab}
              refreshWl={this.fetchWatchList}
              refreshUA={this.fetchUnusualActivity}
              unusualActivity={this.state.unusualActivity}
              myWatchlist={this.state.myWatchlist}
              myWatchlistGroup={this.state.myWatchlistGroup}
              removeFromWl={this.removeFromWl}
              addWl={this.addToWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        // case 'Budgeting':
        //     return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        case 'Account':
          return (
            <Account
              updatePaymentStatus={this.updatePaymentStatus}
              downgradePaymentStatus={this.downgradePaymentStatus}
              toPc={this.toPc}
              updateCustoms={this.updateCustoms}
              customPorts={this.state.customPorts}
              accounts={this.state.allAccounts}
              userEmail={this.state.userEmail}
              subStatus={this.state.subStatus}
              user={this.props.user}
              signOut={this.props.signOut}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        default:
          return (
            <Watchlist
              turnOffWlUpdates={this.turnOffWlUpdates}
              turnOnWlUpdates={this.turnOnWlUpdates}
              activeTab={this.state.activeTab}
              refreshWl={this.fetchWatchList}
              refreshUA={this.fetchUnusualActivity}
              unusualActivity={this.state.unusualActivity}
              myWatchlistGroup={this.state.myWatchlistGroup}
              myWatchlist={this.state.myWatchlist}
              removeFromWl={this.removeFromWl}
              addWl={this.addToWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )

          return (
            <SubModal
              updatePaymentStatus={this.updatePaymentStatus}
              userEmail={this.state.userEmai}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
            />
          )
      }
    }
  }

  // showTabsPaywallOLD = () => {
  //     if(this.props.user){
  //         // console.log('this.state.freeTrial',this.state.freeTrial)
  //         // console.log('this.state.subLevel',this.state.subLevel)
  //         if (this.state.freeTrial === true) {
  //             // console.log('free trial')
  //             //Free Trial
  //             switch (this.state.activeTab) {
  //                 case 'PortfolioConstructor':
  //                     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Portfolio':
  //                     return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'WealthManagement':
  //                     return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'References':
  //                     return <References isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'PriceTrendPredictor':
  //                     return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Invest':
  //                     return <Invest subStatus={this.state.subStatus} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
  //                 case 'Budgeting':
  //                     return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Account':
  //                     return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'MyStrategies':
  //                     return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
  //                     // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'MyWatchlist':
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 default:
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             }
  //         }
  //         else if(this.state.subLevel === "arapp_monthly" || this.state.subLevel === "prod_FaN0loj0oRP7de"){
  //             // BASIC SUB
  //             // console.log('basic sub')

  //             switch (this.state.activeTab) {
  //                 case 'MyStrategies':
  //                     return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
  //                     // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'PortfolioConstructor':
  //                     return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
  //                     // return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Portfolio':
  //                     return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
  //                     // return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'WealthManagement':
  //                     return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}/>
  //                     // return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Invest':
  //                     return <Invest toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
  //                 case 'Budgeting':
  //                     return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Account':
  //                     return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'References':
  //                     return <References isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'PriceTrendPredictor':
  //                     return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'MyWatchlist':
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 default:
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             }
  //         }
  //         else if (!this.state.subLevel){
  //             // No Sub
  //             // console.log('nosub')
  //             return <SubModal updatePaymentStatus={this.updatePaymentStatus} userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subStatus={this.state.subLevel}/>
  //             // switch (this.state.activeTab) {
  //             //     case 'Portfolio Constructor':
  //             //         return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             //     case 'Portfolio':
  //             //         return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             //     case 'Wealth Management':
  //             //         return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //             //     case 'References':
  //             //         return <References isSmallScreen={this.state.isSmallScreen}/>
  //             //     case 'Price Trend Predictor':
  //             //         return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
  //             //     case 'My Watchlist':
  //             //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             //     default:
  //             //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             // }
  //         }
  //         else{
  //             // console.log('WMsub')
  //             // WM Sub
  //             switch (this.state.activeTab) {
  //                 case 'Account':
  //                     return <Account toPc={this.toPc} updateCustoms={this.updateCustoms} customPorts={this.state.customPorts} accounts={this.state.allAccounts} userEmail={this.state.userEmail} subStatus={this.state.subStatus} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'PortfolioConstructor':
  //                     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Portfolio':
  //                     return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}wm={true} fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'WealthManagement':
  //                     return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'References':
  //                     return <References isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'PriceTrendPredictor':
  //                     return <PriceTrendPredictor presetTickers={this.state.presetTickers} dash={false} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Invest':
  //                     return <Invest presetTickers={this.state.presetTickers} toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen} linkedPorts={this.state.itemsResp}/>
  //                 case 'Budgeting':
  //                     return <Budgeting bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'Account':
  //                     return <Account bankConnected={false} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'MyStrategies':
  //                     return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //                 case 'MyWatchlist':
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //                 default:
  //                     return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             }
  //         }
  //     }
  //     else{
  //         switch (this.state.activeTab) {
  //             // case 'Portfolio Constructor':
  //             //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
  //             case 'WealthManagement':
  //                 return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
  //             case 'PriceTrendPredictor':
  //                 return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
  //             default:
  //                 return <LoginHalf errors={this.props.errors} signIn={this.props.signIn} signOut={this.props.signOut} user={this.props.user}/>
  //         }
  //     }
  // }
  cutURL() {
    return window.location.pathname.split('/Dashboard')[1]
  }

  showLists = () => {
    //Small screen
    if (this.state.showListOptions) {
      let categories = [
        'Price Trend Predictor',
        'My Watchlist',
        'Portfolio Constructor',
        'Wealth Management',
        'Account',
      ]
      // categories = categories.filter(ele => ele !== this.state.activeTab)
      let mapped = categories.map((ele) => (
        <li
          style={{
            marginBottom: 5,
            cursor: 'pointer',
            backgroundColor:
              this.state.activeTab !== ele ? 'rgb(146,146,146)' : brandColor,
            padding: 7,
            borderRadius: 5,
            width: '100%',
          }}
          onClick={() =>
            this.setState({ activeTab: ele, showListOptions: false })
          }
        >
          {ele}
        </li>
      ))
      return (
        <ul
          style={{
            backgroundColor: 'rgb(248,248,250)',
            listStyleType: 'none',
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '60%',
            borderRadius: 12,
            marginTop: -10,
            zIndex: 100,
          }}
        >
          {mapped}
        </ul>
      )
    }
  }

  showHeader = () => {
    if (!this.state.isSmallScreen) {
      return (
        <div
          style={{
            borderRight: 'solid .25px rgb(221, 221, 221)',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '98%',
              margin: 'auto',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                flexDirection: 'column',
              }}
            >
              <NavLink
                to={`/Dashboard/Account`}
                exact={true}
                style={{ textDecoration: 'none' }}
              >
                <p
                  style={{
                    textDecoration: 'none',
                    display: 'block',
                    height: '60px',
                    width: '60px',
                    lineHeight: '60px',
                    borderRadius: '50%',
                    backgroundColor: '#3F46F6',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '25px',
                    marginBottom: '50px',
                    marginTop: '25px',
                  }}
                >
                  {this.state.nameInitials}
                </p>
              </NavLink>

              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  margin: 'auto',
                  marginBottom: '25px',
                }}
                to={`/Dashboard`}
                exact={true}
              >
                <div
                  id='DashBoard_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.fetchWatchList()
                    this.setState({ activeTab: 'Dashboard' })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname === '/Dashboard'
                          ? DashBoardIconSelected
                          : DashBoardIcon
                      }
                      alt='Dashboard Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname === '/Dashboard'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    {this.props.t('dashboard')}
                  </p>
                </div>
              </NavLink>
              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  margin: 'auto',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Watchlist`}
                exact={true}
              >
                <div
                  id='WatchList_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.fetchWatchList()
                    this.setState({ activeTab: 'Watchlist' })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] === 'Watchlist'
                          ? WatchListIconSelected
                          : WatchListIcon
                      }
                      alt='Watch List Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] === 'Watchlist'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    {this.props.t('watchlistTab')}
                  </p>
                </div>
              </NavLink>

              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Trade`}
                exact={true}
              >
                <div
                  id='Trade_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => {
                    this.setState({ activeTab: 'Trade', addAcc: false })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] === 'Trade'
                          ? TradeIconSelected
                          : TradeIcon
                      }
                      alt='Trade Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] === 'Trade'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    {this.props.t('trade')}
                  </p>
                </div>
              </NavLink>

              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  margin: 'auto',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Budgeting`}
                exact={true}
              >
                <div
                  id='Budgeting_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.fetchWatchList()
                    this.setState({ activeTab: 'Budgeting' })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] === 'Budgeting'
                          ? BudgetingIconSelected
                          : BudgetingIcon
                      }
                      alt='Watch List Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] === 'Budgeting'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                      textTransform: 'capitalize',
                    }}
                  >
                    {this.props.t('budgeting')}
                  </p>
                </div>
              </NavLink>

              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Accounts`}
                exact={true}
              >
                <div
                  id='Invest_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => {
                    this.setState({ activeTab: 'Invest', addAcc: false })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] ===
                          'WealthManagement' ||
                        window.location.pathname.split('/')[2] === 'Accounts'
                          ? AccountsIconSelected
                          : AccountsIcon
                      }
                      alt='Accounts Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] ===
                          'WealthManagement' ||
                        window.location.pathname.split('/')[2] === 'Accounts'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    {this.props.t('accounts')}
                  </p>
                </div>
              </NavLink>
              <ChangeLanguage user={this.props.user} t={this.props.t} />
            </div>
          </div>
        </div>
      )
    } else if (this.state.showListOptions) {
      return (
        <div
          style={{
            borderRight: 'solid .25px rgb(221, 221, 221)',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '98%',
              margin: 'auto',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10,
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  display: 'block',
                  height: '60px',
                  width: '60px',
                  lineHeight: '60px',
                  borderRadius: '50%',
                  backgroundColor: '#3525e4',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '25px',
                  marginBottom: '50px',
                  marginTop: '25px',
                }}
              >
                PP
              </p>
              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  margin: 'auto',
                  marginBottom: '25px',
                }}
                to={`/Dashboard`}
                exact={true}
              >
                <div
                  id='DashBoard_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.fetchWatchList()
                    this.setState({ activeTab: 'Dashboard' })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname === '/Dashboard'
                          ? DashBoardIconSelected
                          : DashBoardIcon
                      }
                      alt='Dashboard Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname === '/Dashboard'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    Dashboard
                  </p>
                </div>
              </NavLink>
              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  margin: 'auto',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Watchlist`}
                exact={true}
              >
                <div
                  id='WatchList_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.fetchWatchList()
                    this.setState({ activeTab: 'Watchlist' })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] === 'Watchlist'
                          ? WatchListIconSelected
                          : WatchListIcon
                      }
                      alt='Watch List Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] === 'Watchlist'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    WatchList
                  </p>
                </div>
              </NavLink>
              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Invest`}
                exact={true}
              >
                <div
                  id='Invest_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => {
                    this.setState({ activeTab: 'Invest', addAcc: false })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] ===
                          'WealthManagement' ||
                        window.location.pathname.split('/')[2] === 'Invest'
                          ? AccountsIconSelected
                          : AccountsIcon
                      }
                      alt='Accounts Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] ===
                          'WealthManagement' ||
                        window.location.pathname.split('/')[2] === 'Invest'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    Accounts
                  </p>
                </div>
              </NavLink>

              <NavLink
                style={{
                  width: '100%',
                  textDecoration: 'none',
                  marginBottom: '25px',
                }}
                to={`/Dashboard/Trade`}
                exact={true}
              >
                <div
                  id='Trade_Nav'
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    margin: 'auto',
                    display: 'block',
                  }}
                  onClick={() => {
                    this.setState({ activeTab: 'Trade', addAcc: false })
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={
                        window.location.pathname.split('/')[2] === 'Trade'
                          ? TradeIconSelected
                          : TradeIcon
                      }
                      alt='Trade Icon'
                    />
                  </div>
                  <p
                    style={{
                      color:
                        window.location.pathname.split('/')[2] === 'Trade'
                          ? '#3525e4'
                          : 'black',
                      textAlign: 'center',
                    }}
                  >
                    Trade
                  </p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  showMobileHeader() {
    return (
      <div style={{ borderBottom: '1px solid black', padding: '25px 5px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '98%',
            margin: 'auto',
            overflowX: 'auto',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: 10,
            }}
          >
            <NavLink
              to={`/Dashboard/Account`}
              exact={true}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <p
                style={{
                  textDecoration: 'none',
                  display: 'block',
                  height: '60px',
                  width: '60px',
                  lineHeight: '60px',
                  borderRadius: '50%',
                  backgroundColor: '#3525e4',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '25px',
                  margin: 0,
                }}
              >
                {this.state.nameInitials}
              </p>
            </NavLink>

            <NavLink
              style={{
                width: '100%',
                textDecoration: 'none',
                // margin: "auto auto 25px",
              }}
              to={`/Dashboard`}
              exact={true}
            >
              <div
                id='DashBoard_Nav'
                style={{
                  // width: '100%',
                  width: 100,
                  cursor: 'pointer',
                  display: 'block',
                  alignItems: 'center',
                }}
                onClick={() => {
                  this.fetchWatchList()
                  this.setState({ activeTab: 'Dashboard' })
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      window.location.pathname === '/Dashboard'
                        ? DashBoardIconSelected
                        : DashBoardIcon
                    }
                    alt='Dashboard Icon'
                  />
                </div>
                <p
                  style={{
                    color:
                      window.location.pathname === '/Dashboard'
                        ? '#3525e4'
                        : 'black',
                    textAlign: 'center',
                    fontSize: '12px',
                    margin: 0,
                  }}
                >
                  {this.props.t('dashboard')}
                </p>
              </div>
            </NavLink>
            <NavLink
              style={{
                width: '100%',
                textDecoration: 'none',
                // margin: "auto auto 25px",
              }}
              to={`/Dashboard/Watchlist`}
              exact={true}
            >
              <div
                id='WatchList_Nav'
                style={{
                  // width: '100%',
                  width: 100,
                  cursor: 'pointer',
                  display: 'block',
                  alignItems: 'center',
                }}
                onClick={() => {
                  this.fetchWatchList()
                  this.setState({ activeTab: 'Watchlist' })
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      window.location.pathname.split('/')[2] === 'Watchlist'
                        ? WatchListIconSelected
                        : WatchListIcon
                    }
                    alt='Watch List Icon'
                  />
                </div>
                <p
                  style={{
                    color:
                      window.location.pathname.split('/')[2] === 'Watchlist'
                        ? '#3525e4'
                        : 'black',
                    textAlign: 'center',
                    fontSize: '12px',
                    margin: 0,
                  }}
                >
                  {this.props.t('watchlist')}
                </p>
              </div>
            </NavLink>

            <NavLink
              style={{
                width: '100%',
                textDecoration: 'none',
                // margin: "auto auto 25px",
              }}
              to={`/Dashboard/Trade`}
              exact={true}
            >
              <div
                id='Trade_Nav'
                style={{ width: 100, cursor: 'pointer', display: 'block' }}
                onClick={() => {
                  this.setState({ activeTab: 'Trade', addAcc: false })
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      window.location.pathname.split('/')[2] === 'Trade'
                        ? TradeIconSelected
                        : TradeIcon
                    }
                    alt='Trade Icon'
                  />
                </div>
                <p
                  style={{
                    color:
                      window.location.pathname.split('/')[2] === 'Trade'
                        ? '#3525e4'
                        : 'black',
                    textAlign: 'center',
                    fontSize: '12px',
                    margin: 0,
                  }}
                >
                  {this.props.t('trade')}
                </p>
              </div>
            </NavLink>

            <NavLink
              style={{
                width: '100%',
                textDecoration: 'none',
                // margin: "auto auto 25px",
              }}
              to={`/Dashboard/Budgeting`}
              exact={true}
            >
              <div
                id='Budgeting_Nav'
                style={{ width: 100, cursor: 'pointer', display: 'block' }}
                onClick={() => {
                  this.fetchWatchList()
                  this.setState({ activeTab: 'Budgeting' })
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      window.location.pathname.split('/')[2] === 'Budgeting'
                        ? BudgetingIconSelected
                        : BudgetingIcon
                    }
                    alt='Watch List Icon'
                  />
                </div>
                <p
                  style={{
                    color:
                      window.location.pathname.split('/')[2] === 'Budgeting'
                        ? '#3525e4'
                        : 'black',
                    textAlign: 'center',
                    fontSize: '12px',
                    margin: 0,
                    textTransform: 'capitalize',
                  }}
                >
                  {this.props.t('budgeting')}
                </p>
              </div>
            </NavLink>
            <NavLink
              style={{
                width: '100%',
                textDecoration: 'none',
                // margin: "auto auto 25px",
              }}
              to={`/Dashboard/Accounts`}
              exact={true}
            >
              <div
                id='Invest_Nav'
                style={{
                  width: 100,
                  cursor: 'pointer',
                  display: 'block',
                  alignItems: 'center',
                }}
                onClick={() => {
                  this.setState({ activeTab: 'Invest', addAcc: false })
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      window.location.pathname.split('/')[2] ===
                        'WealthManagement' ||
                      window.location.pathname.split('/')[2] === 'Accounts'
                        ? AccountsIconSelected
                        : AccountsIcon
                    }
                    alt='Accounts Icon'
                  />
                </div>
                <p
                  style={{
                    color:
                      window.location.pathname.split('/')[2] ===
                        'WealthManagement' ||
                      window.location.pathname.split('/')[2] === 'Accounts'
                        ? '#3525e4'
                        : 'black',
                    textAlign: 'center',
                    margin: 0,
                    fontSize: '12px',
                  }}
                >
                  {this.props.t('accounts')}
                </p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    )
  }

  toAcc = () => {
    if (window.location.pathname !== `/Dashboard/Account`) {
      history.push(`/Dashboard/Account`)
    }
    this.setState({ showListOptions: false, activeTab: 'Account' })
  }

  addToWl = (item) => {
    this.alterWatchlist(item.symbol, 'add')

    // let myWatchlist = [item,...this.state.myWatchlist]
    let myWatchlist = [...this.state.myWatchlist]
    this.setState({ myWatchlist })
  }

  removeFromWl = (symbol) => {
    this.alterWatchlist(symbol, 'delete')
    let filtered = this.state.myWatchlist.filter((ele) => ele.symbol !== symbol)
    this.setState({ myWatchlist: filtered })
  }

  combineAccountsAndHoldings = () => {
    let holdings = {}
    this.state.holdings.forEach((ele) => {
      if (holdings[ele.accountId]) {
        holdings[ele.accountId].push(ele)
      } else {
        holdings[ele.accountId] = [ele]
      }
    })
    let accounts = []
    this.state.itemsResp.forEach((inst) => {
      inst.accounts.forEach((account) => {
        accounts.push({
          ...account,
          inst: inst.instName,
          holdings: holdings[account.accountId]
            ? holdings[account.accountId]
            : [],
        })
      })
    })
    return accounts
  }

  checkUserVerification = () => {
    if (!this.state.finraStatus) {
      return (
        <Dialog
          header={
            <div className='unremoveable-header-container'>
              <p className='unremoveable-header'>Investment Questionnaire</p>
              <p
                onClick={() => {
                  this.props.signOut()
                }}
                className='unremoveable-signout'
              >
                Sign out
              </p>
            </div>
          }
          // visible={!this.state.finraStatus}
          visible={!this.state.finraStatus}
          style={{ width: this.state.isSmallScreen ? '90vw' : '50vw' }}
          closeOnEscape={this.state.finraAPIstatus}
          closable={this.state.finraAPIstatus}
          onHide={this.setFinraStatus}
        >
          <FinraQuestionnaire
            user={this.props.user}
            action={this.getAllAccountInfoV2}
            t={this.props.t}
          />
        </Dialog>
      )
    } else if (!this.state.driveWealthStatus && !this.state.apexStatus) {
      return (
        <Dialog
          header={
            <div className='unremoveable-header-container'>
              <p className='unremoveable-header'>Create Trading Account</p>
              <p
                onClick={() => {
                  this.props.signOut()
                }}
                className='unremoveable-signout'
              >
                Sign out
              </p>
            </div>
          }
          visible={true}
          style={{ width: this.state.isSmallScreen ? '90vw' : '50vw' }}
          closeOnEscape={this.state.dwApexAPIstatus}
          closable={this.state.dwApexAPIstatus}
          onHide={this.setDwApexStatus}
        >
          <DwApexQuestionnaire
            apexStatus={this.state.apexStatus}
            driveWealthStatus={this.state.driveWealthStatus}
            user={this.props.user}
            action={this.getAllAccountInfoV2}
            getUserFullName={this.getUserFullName}
          />
        </Dialog>
      )
    } else if (
      this.state.driveWealthStatus &&
      this.state.dwExtendedHours === -1
    ) {
      return (
        <Dialog
          header={
            <div className='unremoveable-header-container'>
              <p className='unremoveable-header'>
                {this.props.t('extended_hours_trading_for_stock')}
              </p>
              <p
                onClick={() => {
                  this.props.signOut()
                }}
                className='unremoveable-signout'
              >
                {this.props.t('signout')}
              </p>
            </div>
          }
          visible={true}
          style={{ width: '50vw' }}
          closeOnEscape={false}
          closable={false}
        >
          <div className='extended-hours-agreement-container'>
            <p>
              {this.props.t('extended_trading_description')}
              {/* Would you like to make use of Extended Hours of Trading? Extended
              Hours Trading offering has two components: the Pre Market Session
              (04:00AM - 9.30AM ET) and After Market Session (04:00PM - 8.00PM
              ET) */}
            </p>
            <div className='agreement-row'>
              <Checkbox
                onChange={(e) => {
                  this.setState({
                    extendedHoursAgreement: !this.state.extendedHoursAgreement,
                  })
                }}
                checked={this.state.extendedHoursAgreement}
              ></Checkbox>
              <p className='agreement-label'>
                {this.props.t('i_agree_to_the')}{' '}
                <a
                  href='https://legal.drivewealth.com/extended-hours-trading-disclosures'
                  target='_blank'
                  rel='noreferrer'
                >
                  {this.props.t(
                    'drivewealth_extended_hours_trading_disclosures'
                  )}
                </a>
              </p>
            </div>
            <div className='button-container'>
              <Button
                label={this.props.t('enable')}
                style={{ minWidth: '200px' }}
                className='dw-apex-submit-btn'
                onClick={() => this.turnOnExternalHours()}
                loading={this.state.setExtendedLoading}
                disabled={!this.state.extendedHoursAgreement}
              />
              <Button
                label={this.props.t('disable_for_now')}
                style={{ minWidth: '200px' }}
                className='dw-apex-submit-btn'
                loading={this.state.setExtendedLoading}
                onClick={() => this.turnOffExternalHours()}
              />
            </div>
          </div>
        </Dialog>
        // null
      )
    } else {
      return null
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ background: 'rgb(246, 246, 246)' }}>
          {this.props.showOnlyNavBar ? (
            <div style={{ width: '100%', display: 'flex', height: '100%' }}>
              {/* {((this.state.isSmallScreen && this.state.showListOptions) || !this.state.isSmallScreen)?
                                <div style={{height:'100%',  position:'fixed', width:'7%', top:0, left:0,}}>
                                    {this.showHeader()}
                                </div>
                                :null
                            }    */}
              {this.state.isSmallScreen ? (
                <div
                  style={{ height: '10%', position: 'static', width: '100%' }}
                >
                  {/* {this.showHeader()} */}
                  {this.showMobileHeader()}
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    position: 'fixed',
                    width: '7%',
                    top: 0,
                    left: 0,
                  }}
                >
                  {/* {this.showHeader()} */}
                  {this.showHeader()}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: this.state.isSmallScreen ? 'block' : 'flex',
                height: '100%',
              }}
            >
              {this.state.isSmallScreen ? (
                <div
                  style={{ height: '10%', position: 'static', width: '100%' }}
                >
                  {this.showMobileHeader()}
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    position: 'fixed',
                    width: '7%',
                    top: 0,
                    left: 0,
                  }}
                >
                  {this.showHeader()}
                </div>
              )}
              <div
                onClick={
                  this.state.showListOptions
                    ? () => this.setState({ showListOptions: false })
                    : null
                }
                style={{
                  width: this.state.isSmallScreen ? '100%' : '93%',
                  height: this.state.isSmallScreen ? '90vh' : '100vh',
                  backgroundColor: '#f6f6f6',
                  position: this.state.isSmallScreen ? '' : 'fixed',
                  left:
                    (this.state.isSmallScreen && this.state.showListOptions) ||
                    !this.state.isSmallScreen
                      ? '7%'
                      : '0%',
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#f6f6f6',
                    height: '100%',
                  }}
                >
                  <div style={{ width: '100%', height: '100%' }}>
                    {this.props.user ? (
                      <>
                        {this.checkUserVerification()}
                        {this.showAllTabs()}
                      </>
                    ) : (
                      <div
                        style={{
                          marginTop: 200,
                          marginBottom: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Loader
                          isSmallScreen={this.state.isSmallScreen}
                          logoLoader={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )
    } else return null
  }
}

// const mapStateToProps = (state) => {
//   return {
//     signUpModalOpen: state.navbar.signUpModalOpen,
//     username: state.navbar.username,
//   }
// }

const mapDispatchToProps = { setIsCryptoAllowed, setUserData }

export default withRouter(connect(null, mapDispatchToProps)(UserDashboard))
