import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

class TeamMember extends Component {
    render() {
        return (
            <Col sm={6} className="member-rectangle">
                <p className="member-name">{this.props.value.name}</p>
                <p className="member-position">{this.props.value.position}</p>
                <p className="member-position">{this.props.value.school}</p>
                <p className="member-introduction">{this.props.value.introduction}</p>
            </Col>
        )
    }
}

export default TeamMember
