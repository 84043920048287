import React, { Component } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import SearchAndAddDialog from '../../../SearchAndAddDialog'
import '../../../../../scss/pages/account-activation/aicrypto/AIcryptoActivation.scss'
import DepositDialog from '../../../DepositDialog'
import Endpoint from '../../../../Endpoint'
import ConfirmationDialog from '../../../ConfirmationDialog'
import Axios from 'axios'
import ProductActivationBackIcon from '../../../../../assets/product-activation-back-icon.svg'
import { Dialog } from 'primereact/dialog'
import DepositAchDialog from '../../../DepositAchDialog'
import axios from 'axios'
import ENDPOINT from '../../../../Endpoint'
import LinkACH from '../../../LinkACH'
import ConfirmACH from '../../../ConfirmACH'
import { ConfirmDialog } from 'primereact/confirmdialog'
export default class AIcryptoActivation extends Component {
  state = {
    initialAmount: Math.max(
      500,
      this.props.selectedAccount?.balances[0]?.available
    ),
    cryptos: [],
    addCryptoDialog: false,
    errorMessage: '',
    successMessage: '',
    showDepositForm: false,
    showConfirmationDialog: false,
    canTrade: {
      status: true,
    },
    achAccount: null,
    achPending: null,

    //EDIT ACH POP UP
    showLinkACHdialog: false,

    //Confirm ACH POP UP
    showConfirmACHdialog: false,

    //EDIT OR DELETE POP UP
    showEditOrDeleteForm: false,

    //CONFIRMATION POP UP
    showDeleteConfirmationForm: false,
  }

  componentDidMount() {
    this.getACHaccount()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getACHaccount()
    }
    if (prevProps.selectedAccount !== this.props.selectedAccount) {
      this.setState({
        initialAmount: Math.max(
          500,
          this.props.selectedAccount?.balances[0]?.available
        ),
      })
    }
  }

  hideDepositAndShowForm = () => {
    this.setState({ showDepositForm: false })
    if (this.state.achAccount) {
      this.toggleEditOrDeleteForm()
    } else if (this.state.achPending) {
      this.toggleShowConfirmACHdialog()
    } else {
      this.toggleShowLinkACHdialog()
    }
  }

  deleteACHaccount = async () => {
    this.setState({ editFormLoading: true })
    await axios
      .get(
        `${ENDPOINT}/apex-crypto/ach-relationships/delete?account_id=${this.props.selectedAccount.accountId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data?.status) {
          this.setState({
            successMessage: this.props.t('accountremovedsuccessfully'),
          })
          this.getACHaccount()
        } else {
          this.setState({
            errorMessage: this.props.t('somethingwentwrong'),
          })
        }
      })
      .catch((err) => {
        console.log('delete ach account error', err)
        this.setState({
          errorMessage: this.props.t('somethingwentwrong'),
        })
      })
  }

  getACHaccount = async () => {
    await axios
      .get(
        `${ENDPOINT}/apex-crypto/ach-relationships/list?account_id=${this.props.selectedAccount.accountId}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response?.data?.approved && response?.data?.approved.length > 0) {
          this.setState({ achAccount: response.data.approved[0] })
        } else if (
          response?.data?.pending &&
          response?.data?.pending.length > 0
        ) {
          this.setState({ achPending: response.data.pending[0] })
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
      })
  }

  startAIcryptoTrader = async () => {
    this.setState({ isLoading: true })
    let body = {
      groupName: 'AI Trader',
      dw_account_id: this.props.selectedAccount.accountId,
      stocks: this.state.cryptos.map((obj) => obj.symbol),
      amount: this.state.initialAmount,
    }
    return await Axios.post(
      `${Endpoint}/apex-cp/trade-groups/create-new`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then(async (response) => {
        if (response?.data?.Status && response?.data?.Status === 'Success') {
          await this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log('ai crypto trader activation error', error)
        return false
      })
  }

  handleInitialAmountChange = (value) => {
    this.setState({ initialAmount: value })
  }

  toggleAddCryptoDialog = () => {
    this.setState({ addCryptoDialog: !this.state.addCryptoDialog })
  }

  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 5000)
  }

  header() {
    return (
      <div className='header-container'>
        <div className='left-col'>
          <p className='label'>{this.props.t('accountvalue')}</p>
          <p className='value'>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].virtualAccountValue
            )}
          </p>
        </div>
        <div className='right-col'>
          <p className='label'>{this.props.t('accountbuyingpower')}</p>
          <p className='value'>
            {this.props.formatCurrency(
              this.props.selectedAccount.balances[0].buyingPower
            )}
          </p>
        </div>
      </div>
    )
  }

  initialInvestmentForm() {
    return (
      <div className='initial-investment-form-container'>
        <p className='label'>{this.props.t('initialinvestment')}</p>
        <InputNumber
          value={this.state.initialAmount}
          min={Math.max(
            500,
            this.props.selectedAccount.balances[0].virtualAccountValue
          )}
          onValueChange={(e) => this.handleInitialAmountChange(e.value)}
          mode='currency'
          currency='USD'
          minFractionDigits={0}
          maxFractionDigits={2}
          locale='en-US'
        />
      </div>
    )
  }

  requirementMessage() {
    let value =
      this.state.initialAmount -
      this.props.selectedAccount?.balances[0]?.available
    if (value > 0) {
      return (
        <div className='requirement-message-container'>
          <p className='message'>
            {this.props.t('additional')} {this.props.formatCurrency(value)}{' '}
            {this.props.t('isrequiredtomeetyourinitialinvestment')}
          </p>
        </div>
      )
    }
  }

  cryptosList() {
    return (
      <div className='cryptos-list-container'>
        <p className='header'>
          {this.props.t('crypto')} ({this.state.cryptos.length})
        </p>
        {this.state.cryptos.map((crypto, index) => {
          return this.getCryptoRow(crypto, index)
        })}
        <div className='btn-container'>
          <Button
            label={this.props.t('addcrypto')}
            className='add-cryptos-btn'
            onClick={() => {
              this.toggleAddCryptoDialog()
            }}
          />
        </div>
      </div>
    )
  }

  getCryptoRow(crypto, index) {
    return (
      <div className='crypto-row'>
        <div className='image-and-info'>
          <div className='img-container'>
            {crypto.imageURL === 'not-found' ? (
              <div className='not-found'>{crypto.symbol.charAt(0)}</div>
            ) : (
              <img src={crypto.imageURL} className='image' />
            )}
          </div>
          <div className='info'>
            <p className='symbol'>{crypto.symbol}</p>
            <p className='company'>{crypto.company}</p>
          </div>
        </div>
        <Button
          className='delete-btn'
          icon='pi pi-times'
          onClick={() => {
            this.removeCrypto(crypto)
          }}
        />
      </div>
    )
  }

  addCrypto = (crypto) => {
    let cryptoList = this.state.cryptos
    if (cryptoList.length < 8) {
      if (crypto.apexCryptoSupported) {
        cryptoList.push(crypto)
        this.setState({ cryptos: cryptoList })
      } else {
        this.toggleErrorMessage('Please select a valid crypto')
      }
    } else {
      this.toggleErrorMessage(this.props.t('cannotselectmorethan8cryptos'))
    }
  }

  removeCrypto = (crypto, index) => {
    let cryptoList = this.state.cryptos
    cryptoList.splice(index, 1)
    this.setState({ cryptos: cryptoList })
  }

  toggleShowDepositForm = () => {
    this.setState({ showDepositForm: !this.state.showDepositForm })
  }

  submitButton() {
    return (
      <div className='button-container'>
        {this.props.selectedAccount &&
        this.props.selectedAccount?.balances[0]?.available >=
          this.state.initialAmount ? (
          <Button
            className='submit-button'
            label={this.props.t('startaitrader')}
            onClick={() => {
              this.validateForm()
            }}
          />
        ) : (
          <Button
            className='submit-button'
            label={this.props.t('addcash')}
            onClick={() => {
              this.toggleShowDepositForm()
            }}
          />
        )}
      </div>
    )
  }

  validateForm = () => {
    if (this.state.cryptos.length >= 2) {
      if (this.state.canTrade.status) {
        this.toggleShowConfirmationDialog()
      } else {
        this.toggleErrorMessage(this.props.t('marketsareonlyopenfrom'))
      }
    } else {
      this.toggleErrorMessage(this.props.t('pleaseselectatleast2cryptos'))
    }
  }

  toggleFormStatusMessage(msg) {
    this.setState({ formStatusMessage: msg })
    setTimeout(() => {
      this.setState({ formStatusMessage: '' })
    }, 5000)
  }

  toggleError(msg) {
    this.setState({ formErrorMessage: msg })
    setTimeout(() => {
      this.setState({ formErrorMessage: '' })
    }, 5000)
  }

  toggleShowLinkACHdialog = () => {
    this.setState({ showLinkACHdialog: !this.state.showLinkACHdialog })
  }

  toggleShowConfirmACHdialog = () => {
    this.setState({ showConfirmACHdialog: !this.state.showConfirmACHdialog })
  }

  toggleEditOrDeleteForm() {
    this.setState({ showEditOrDeleteForm: !this.state.showEditOrDeleteForm })
  }

  toggleDeleteConfirmationForm() {
    this.setState({
      showDeleteConfirmationForm: !this.state.showDeleteConfirmationForm,
    })
  }

  closeAndToggleLinkACHform() {
    this.toggleEditOrDeleteForm()
    this.toggleShowLinkACHdialog()
  }

  closeAndToggleDeleteConfirmationForm() {
    this.toggleEditOrDeleteForm()
    this.toggleDeleteConfirmationForm()
  }

  render() {
    if (this.props.t) {
      return (
        <div className='ai-crypto-activation-container'>
          <img
            src={ProductActivationBackIcon}
            height={25}
            width={25}
            onClick={() => {
              this.props.toggleActivation()
            }}
            style={{ cursor: 'pointer', marginTop: '25px' }}
          />
          <p className='ai-crypto-header'>{this.props.t('ai crypto trader')}</p>
          {this.header()}
          {this.initialInvestmentForm()}
          {this.requirementMessage()}
          {this.cryptosList()}
          <p className='error'>{this.state.errorMessage}</p>
          <p className='success'>{this.state.successMessage}</p>
          {this.submitButton()}
          {/* <DepositDialog
            toggleShowDepositForm={this.toggleShowDepositForm}
            selectedAccount={this.props.selectedAccount}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            showDepositForm={this.state.showDepositForm}
            isSmallScreen={this.props.isSmallScreen}
            accounts={this.props.accounts}
            t={this.props.t}
          /> */}
          <Dialog
            visible={this.state.showDepositForm}
            header={this.props.t('deposit')}
            onHide={() => this.toggleShowDepositForm()}
            style={{ width: this.props.isSmallScreen ? '90vw' : '40vw' }}
          >
            <DepositAchDialog
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              achAccount={this.state.achAccount}
              getAllAccountInfo={this.props.getAllAccountInfo}
              achPending={this.state.achPending}
              hideDepositAndShowForm={this.hideDepositAndShowForm}
              t={this.props.t}
            />
          </Dialog>
          <SearchAndAddDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleAddDialog={this.toggleAddCryptoDialog}
            add={this.addCrypto}
            addDialog={this.state.addCryptoDialog}
            type='crypto'
            t={this.props.t}
          />
          <Dialog
            visible={this.state.showEditOrDeleteForm}
            header={this.props.t('chooseanoption')}
            onHide={() => this.toggleEditOrDeleteForm()}
            style={{ width: this.props.isSmallScreen ? '90vw' : '25vw' }}
          >
            <div className='buttons-row-double'>
              <Button
                icon='pi pi-check'
                style={{
                  color: 'white',
                  background: '#3F46F6',
                  borderRadius: '15px',
                }}
                onClick={() => {
                  this.closeAndToggleLinkACHform()
                }}
                label={this.props.t('editexistingachlinking')}
              />
              <Button
                icon='pi pi-trash'
                style={{
                  color: 'white',
                  background: '#3D5062',
                  borderRadius: '15px',
                  marginTop: '25px',
                }}
                label={this.props.t('deleteexistingachlinking')}
                onClick={() => {
                  this.closeAndToggleDeleteConfirmationForm()
                }}
              />
            </div>
          </Dialog>
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            name={this.props.t('startaicryptotrader')}
            action={this.startAIcryptoTrader}
            successMessage={this.props.t(
              'aicryptotraderhasbeensuccessfullyactivated'
            )}
            btnClassName='group'
            t={this.props.t}
          />
          <LinkACH
            isSmallScreen={this.props.isSmallScreen}
            showDialog={this.state.showLinkACHdialog}
            toggleShowLinkACHdialog={this.toggleShowLinkACHdialog}
            cryptoAccount={this.props.selectedAccount}
            user={this.props.user}
            getCryptoAccount={this.state.getCryptoAccount}
            getAllAccountInfo={this.props.getAllAccountInfo}
            achAccount={this.state.achAccount}
          />
          <ConfirmACH
            isSmallScreen={this.props.isSmallScreen}
            showDialog={this.state.showConfirmACHdialog}
            toggleShowConfirmACHdialog={this.toggleShowConfirmACHdialog}
            cryptoAccount={this.props.selectedAccount}
            user={this.props.user}
            getACHaccount={this.getACHaccount}
            achPending={this.state.achPending}
          />
          <ConfirmDialog
            visible={this.state.showDeleteConfirmationForm}
            onHide={() => this.toggleDeleteConfirmationForm()}
            message={this.props.t('areyousureyouwanttoproceed')}
            header={this.props.t('confirmation')}
            accept={this.deleteACHaccount}
          />
        </div>
      )
    } else return null
  }
}
