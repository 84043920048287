//REACT
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

//PACKAGES
import { Button } from 'primereact/button'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//COMPONENTS
import MainNavBar from '../components/MainNavBar'
import { validateUsername } from '../../utility-functions/validateUsername'
import { validatePassword } from '../../utility-functions/validatePassword'
import PasswordInput from '../PasswordInput'
import '../../scss/pages/SignUp.scss'
import { Link, NavLink, useParams } from 'react-router-dom'
import CodeVerification from './CodeVerification'
import PasswordMessage from '../PasswordMessage'
import AsaLoginButton from '../AsaLoginButton'
import ReCAPTCHA from 'react-google-recaptcha'

export default function SignUp(props) {
  const { t } = props
  const { general, navbar } = useSelector((state) => state)
  const defaultEmail = navbar.username
  const { isSmallScreen } = general
  const { promoCode } = useParams()

  const recaptchaRef = useRef()

  //SIGN UP
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [agree, setAgree] = useState(false)

  //UTILS
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('signup') //signup -> codeverify
  const [showPasswordErrors, setShowPasswordErrors] = useState(false)
  const [captchaCheck, setCaptchaCheck] = useState(false)
  // useEffect(() => {
  //   resetMessages()
  // }, [page])

  const validateForm = () => {
    if (validateUsername(email)) {
      if (phone) {
        if (validatePassword(password)) {
          if (agree) {
            setPage('codeverify')
            resetMessages()
          } else {
            setErrorMsg(t('pleaseagreetoprivacypolicyandeula'))
          }
        } else {
          // setErrorMsg(t('passwordmustbegreaterthan8characters'))
          setShowPasswordErrors(true)
          //setErrorMsg(t('pleaseentervalidpassword'))
        }
      } else {
        setErrorMsg(t('pleaseenterphonenumber'))
      }
    } else {
      setErrorMsg(t('pleaseentervalidemail'))
    }
  }

  const resetMessages = () => {
    setErrorMsg('')
    setSuccessMsg('')
  }

  const goBack = (msg) => {
    setPage('signup')
    setErrorMsg(msg)
  }

  const onChange = (e) => {
    console.log(e)
    console.log(recaptchaRef.value)
    setCaptchaCheck(true)
  }

  const showSignUpForm = () => {
    return (
      <>
        {/* <div className='space-bottom'>
          <AsaLoginButton t={t} />
        </div>
        <p className='or-text space-bottom space-top'>{t('or')}</p> */}
        <input
          placeholder={t('email')}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value?.toLowerCase())
          }}
          name='username'
          autoComplete='username'
          className='signup-input'
        />
        <PhoneInput
          containerClass='phone-input-container space-top space-bottom'
          inputClass='phone-input'
          // disableDropdown
          country={'us'}
          value={phone}
          onChange={(e) => setPhone(e)}
          placeholder={t('phone')}
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          placeHolder={t('password')}
          className={'signup-input'}
        />
        {showPasswordErrors && <PasswordMessage t={t} password={password} />}
        <div className='check__container space-top'>
          <input
            type='checkbox'
            onChange={() => setAgree((prevState) => !prevState)}
            className='agreement-checkbox'
            checked={agree}
          />
          <span>
            {t('iagreetothe')}{' '}
            <Link
              to='/Legal/ForeSCITE-Privacy-Policy.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('privacypolicy')}
            </Link>{' '}
            {' & '}
            <a href='/Legal/EULA.pdf' className='link' target='_blank'>
              {t('eula')}
            </a>
          </span>
        </div>
        <div className='recaptcha-container'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey='6LfgQpMjAAAAAHqdVpA6qtw3gpwik3jARl_NCZLL'
            onChange={onChange}
          />
        </div>
        <div className='signup-btn-container'>
          <Button
            className='signup-button'
            disabled={!captchaCheck}
            label={t('signup')}
            onClick={() => {
              resetMessages()
              validateForm()
            }}
            loading={loading}
          />
        </div>
        <p className='signup-footer space-top'>
          <NavLink to={'/signin'} exact={true} className='signin-link'>
            {t('signin')}
          </NavLink>{' '}
          {t('toyourinvestallaccount')}
        </p>
      </>
    )
  }

  return (
    <>
      {' '}
      <MainNavBar
        isSmallScreen={isSmallScreen}
        t={props.t}
        whiteBorder={true}
      />
      <div className='signup-container'>
        <div className='signup-form-container'>
          <p className='signup-header'>{t('signup')}</p>
          <div className='signup-form'>
            {page === 'signup' && showSignUpForm()}
            {page === 'codeverify' && (
              <CodeVerification
                email={email}
                password={password}
                t={t}
                phone={phone}
                setUser={props.setUser}
                goBack={goBack}
                promoCode={promoCode}
              />
            )}
            {errorMsg && <p className='error'>{errorMsg}</p>}
            {successMsg && <p className='success'>{successMsg}</p>}
          </div>
        </div>
      </div>
    </>
  )
}
