import React from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'

class Notifications extends React.Component {
  state = {
    hover: false,
  }
  //   createNotification = (type) => {
  //     return () => {
  //       switch (type) {
  //         case 'info':
  //           NotificationManager.info('Info message');
  //           break;
  //         case 'success':
  //           NotificationManager.success('Success message', 'Title here');
  //           break;
  //         case 'warning':
  //           NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
  //           break;
  //         case 'error':
  //           NotificationManager.error('Error message', 'Click me!', 5000, () => {
  //             alert('callback');
  //           });
  //           break;
  //       }
  //     };
  //   };

  //   render() {
  //     return (
  //       <div>
  //         <button className='btn btn-info'
  //           onClick={this.createNotification('info')}>Info
  //         </button>
  //         <hr/>
  //         <button className='btn btn-success'
  //           onClick={this.createNotification('success')}>Success
  //         </button>
  //         <hr/>
  //         <button className='btn btn-warning'
  //           onClick={this.createNotification('warning')}>Warning
  //         </button>
  //         <hr/>
  //         <button className='btn btn-danger'
  //           onClick={this.createNotification('error')}>Error
  //         </button>

  //         <NotificationContainer/>
  //       </div>
  //     );
  //   }
  parseDate = (dt) => {
    let full = new Date(dt * 1000)
    let months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ]
    let month = full.getMonth() + 1
    let day = full.getDate()
    let year = full.getFullYear()
    let hours = full.getHours()
    let mins = full.getMinutes()
    // console.log('mins',mins)
    let secs = full.getSeconds()
    let ampm = 'AM'
    if (hours > 12) {
      ampm = 'PM'
      hours = hours - 12
    }
    if (secs === 0) {
      secs = '00'
    }
    return `${hours}:${
      mins < 10 ? `0${mins}` : mins
    } ${ampm} ${month}/${day}/${year}`
  }

  oneNotif = (n, index) => {
    return (
      <div
        style={{
          color: 'black',
          padding: '12px 16px',
          textDecoration: 'none',
          display: 'block',
          cursor: 'pointer',
          backgroundColor: this.state.hover === index ? 'rgb(200,200,200)' : '',
        }}
        onClick={null}
        onMouseEnter={() => this.setState({ hover: index })}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p style={{ margin: 0, fontSize: '22', color: 'rgb(17,17,17)' }}>
            {n.title}
          </p>
          <p
            style={{
              width: '40%',
              margin: 0,
              fontSize: '14',
              color: 'rgb(100,100,100)',
              textAlign: 'right',
            }}
          >
            {this.parseDate(n.time)}
          </p>
        </div>
        <p style={{ margin: 0, fontWeight: '18', color: 'rgb(17,17,17)' }}>
          {n.message}
        </p>
      </div>
    )
  }

  render() {
    let mapped = this.props.notifs.map((n, i) => this.oneNotif(n, i))
    if (this.props.t) {
      return (
        <div style={{ maxHeight: 400, overflowY: 'scroll', maxWidth: 550 }}>
          {mapped.length === 0 ? (
            <div style={{ padding: 10 }}>
              <p style={{ margin: 0 }}>{this.props.t('youareallcaughtup')}</p>
            </div>
          ) : (
            mapped.reverse()
          )}
        </div>
      )
    } else {
      return null
    }
  }
}

export default Notifications
