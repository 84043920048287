import DoubleNegative from '../assets/double-negative.png'
import SingleNegative from '../assets/single-negative.png'
import Neutral from '../assets/neutral.png'
import SinglePositive from '../assets/single-positive.png'
import DoublePositive from '../assets/double-positive.png'

export const getTrend = (trend) => {
  switch (trend) {
    case -1:
      return SingleNegative
    case 1:
      return SinglePositive
    case 2:
      return DoublePositive
    case -2:
      return DoubleNegative
    default:
      return Neutral
  }
}
