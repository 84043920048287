import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import ChineseTeamMembers from "../layout/ChineseTeamMembers";
import ChineseFooter from "../layout/ChineseFooter";
import Header from "../layout/Header"

class ChineseTeam extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header user={this.props.user} signOut={this.props.signOut}/>
        <div>
          <Row className="page-blank-row-md" />
          <ChineseTeamMembers />
          <ChineseFooter />
        </div>
      </div>
    );
  }
}

export default ChineseTeam;
