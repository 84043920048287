import React from 'react'
import { FaSearch, FaShareSquare, FaCheckCircle } from 'react-icons/fa'
import NoImage from '../../assets/newUI/logo_notfound.png'
import { NavLink } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import { PlaidLink } from 'react-plaid-link'

import ENDPOINT from '../Endpoint'
import { isInteger } from 'lodash-es'
import SearchEndpoint from '../SearchEndpoint'
import { Button } from 'aws-amplify-react'
const brandColor = 'rgb(70,182,179)'

export default class TradeModal extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      canTrade: false,
      //step 0
      noSelection: false,
      acc: this.props.acc,
      allAccounts: [],
      holdings: [],
      itemsResp: 'loading',
      // step 1
      searchMomentumText: '',
      momDataSource: [],
      theme: 'most_active',
      isSearching: false,
      selected: this.props.selected,
      hover: '',
      //step 2
      price: '',
      quantity: '',
      pcnt: '',
      buySell: 'buy',
      priceType: 'MARKET',
      limitPrice: '0',
      purchaseMethod: 'numShares',
      errors: '',
      lastChanged: 'price',
      //step 3
      summary: false,
      orderPlaced: false,
      sharesOwned: 0,
      orderStatus: 'new',
      orderId: '',
      orderResp: false,
      // all
      titleText: '',
      //
      recBuy: false,
      holdingsData: [],
      firstTime: true,
      recurringBuyStep: -1,
      recurringAmount: null,
      recurringMonthly: true,
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.acc === 'NO') {
      // this.getPlaidItems()
      this.getAllAccountInfo()
      // this.getPlaidHoldings()
      this.getLivePrice()
      this.setState({ theme: 'most_active' })
    } else {
      this.getHoldingsInfo()
    }
  }

  componentWillUnmount() {
    clearInterval(this.priceInterval)
    clearInterval(this.orderStatusInterval)
  }

  getHoldingsInfo = () => {
    let something = []
    this.props.acc.holdings.forEach((ele) => {
      something.push(ele.symbol)
    })
    console.log('holdings INFO')
    fetch(`${SearchEndpoint}/ticker-bulk?search=${something}`)
      .catch((err) => console.log('catching: ', err))
      .then((resp) => resp.json())
      .then((responseJson) => {
        console.log('holdings resp', responseJson.content_list)
        this.setState({ holdingsData: responseJson.content_list })
        // responseJson.content_list.forEach((ele, i)=>{
      })
  }

  startPriceTimer = () => {
    // console.log('this.state.orderStatus',this.state.orderStatus)
    // console.log('this.state.selected.symbol',this.state.selected.symbol)
    // console.log('this.state.summary',this.state.summary)
    if (
      this.state.orderStatus === 'new' &&
      this.state.selected.symbol &&
      !this.state.summary
    ) {
      // console.log('starting price timer')
      this.priceInterval = setInterval(() => this.getLivePrice(), 60000)
    }
    // }else{
    //     // console.log('stopping price timer')
    // }
  }

  startTradeStatusTimer = () => {
    if (this.state.orderStatus === 'new') {
      // console.log('starting trade status timer')
      // setTimeout(()=>this.checkOrderStatus(),1000)
      this.orderStatusInterval = setInterval(
        () => this.checkOrderStatus(),
        1000
      )
    } else {
      console.log('stopping trade status timer')
      clearInterval(this.orderStatusInterval)
    }
  }

  getLivePrice = async (sym) => {
    console.log('getting live price')
    // User ticker bulk live/?search=AAPL
    // let url = `${ENDPOINT}/search/ticker-bulk-live?search=${sym.toUpperCase()}`
    let url = `${SearchEndpoint}/ticker-bulk-live?search=`
    // let url = `${ENDPOINT}/risk-monkey/ticker/`;
    if (this.state.selected) {
      // url = url + `${this.state.selected.symbol.toUpperCase()}/live`
      url = url + `${this.state.selected.symbol.toUpperCase()}`
    } else {
      // url = url + `${sym.toUpperCase()}/live`
      url = url + `${sym.toUpperCase()}`
    }
    // fetch(url, {
    //     method: "GET",
    //     headers:{
    //       Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
    //       'Content-Type': 'application/json'
    //     },
    // })
    // .then((res) => res.json())
    // .then((responseJson) => {
    //     console.log('getLivePrice:', responseJson)
    //     this.setState({selected:{...this.state.selected,live:responseJson}})
    //     // this.startPriceTimer()
    // })
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log(responseJson.content[0][this.state.selected.symbol.toUpperCase()])
        if (this.state.selected) {
          this.setState({
            selected: {
              ...this.state.selected,
              live: responseJson.content[0][
                this.state.selected.symbol.toUpperCase()
              ],
            },
          })
        } else {
          this.setState({
            selected: {
              ...this.state.selected,
              live: responseJson.content[0][sym.toUpperCase()],
            },
          })
        }
      })
  }

  getNBBOPrice = (sym) => {
    console.log('getting NBBO')
    let url = `${ENDPOINT}/risk-monkey/ticker/${sym}/live?nbbo=true`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        // console.log('getNBBO:', responseJson)
        let live2 = {
          ...this.state.selected.live,
          ...responseJson,
        }
        console.log('IDAHFAODFHIS')
        console.log(live2)
        this.setState({ selected: { live: live2 } })
      })
  }

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state
    this.setState({ isMomDataLoading: true })
    // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    let url = `${SearchEndpoint}/ticker?search=${searchMomentumText}`
    fetch(url)
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        this.setState({
          isMomDataLoading: false,
          momDataSource: data,
        })
      })
      .catch((err) => {
        this.setState({ isMomDataLoading: false })
        console.log(err)
      })
  }

  // getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
  //     fetch(`${ENDPOINT}/plaid/items`,{
  //         method: "GET",
  //         headers:{
  //           Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //           'Content-Type': 'application/json'
  //         },
  //       })
  //       .then(res => res.json())
  //       .then(responseJson => {
  //         //   console.log('plaid items resp: ',responseJson)
  //           this.setState({itemsResp: responseJson})
  //         })
  //       .catch(err=>console.log(`catching errors`, err))
  // }

  // getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
  //     fetch(`${ENDPOINT}/plaid/holdings`,{
  //         method: "GET",
  //         headers:{
  //           Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
  //           'Content-Type': 'application/json'
  //         },
  //       })
  //       .then(res => res.json())
  //       .then(responseJson => this.setState({holdings: responseJson}))
  //       .catch(err=>console.log(`catching errors`, err))
  // }

  checkOrderStatus = () => {
    let orderId = this.state.orderId
    fetch(`${ENDPOINT}/mobile/order-status?orderID=${orderId}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        //   console.log('check order status success resp: ',responseJson)
        if (responseJson.status !== 'new') {
          clearInterval(this.orderStatusInterval)
        }
        this.setState({
          orderStatus: responseJson.status,
          orderResp: responseJson,
        })
      })
      .catch((err) => console.log(`check order status error: `, err))
  }

  combineAccountsAndHoldings = () => {
    let holdings = {}
    this.state.holdings.forEach((ele) => {
      if (holdings[ele.accountId]) {
        holdings[ele.accountId].push(ele)
      } else {
        holdings[ele.accountId] = [ele]
      }
    })
    let accounts = []
    this.state.itemsResp.forEach((inst) => {
      inst.accounts.forEach((account) => {
        accounts.push({
          ...account,
          inst: inst.instName,
          holdings: holdings[account.accountId]
            ? holdings[account.accountId]
            : [],
        })
      })
    })
    // console.log('all accounts with holdings', accounts)
    return accounts
  }

  displayErrors = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 25,
          }}
        >
          <div
            style={{
              padding: 20,
              backgroundColor: 'rgb(238,139,132)',
              borderRadius: 15,
            }}
          >
            <p style={{ margin: 0 }}>{this.state.errors}</p>
          </div>
        </div>
      )
    }
  }

  checkLogo = (symbol) => {
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      return true
    } else {
      return false
    }
  }

  createBox = (ele) => {
    // console.log('inside create box', ele)
    return (
      <div
        onClick={() => {
          console.log('in cretae box on click')
          if (ele.isDwSupported || this.state.theme === 'MyHoldings') {
            console.log('is dw supported?', ele.isDwSupported)
            this.owned(this.state.acc, ele.symbol)
            this.getLivePrice(ele.symbol)
            this.setState({
              searchMomentumText: '',
              limitPrice: ele.priceUSD ? ele.priceUSD.toFixed(2) : '0.00',
            })
          } else {
            this.setState({
              errors: `DriveWealth does not currently support ${ele.symbol}`,
            })
          }
        }}
        onMouseEnter={() => this.setState({ hover: ele })}
        onMouseLeave={() => this.setState({ hover: '' })}
        style={{ width: 100, height: 175, marginBottom: 10, marginRight: 15 }}
        // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
      >
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              this.state.hover === ele
                ? 'rgb(225,225,225)'
                : 'rgb(255,255,255)',
            borderRadius: 7,
            overflow: 'hidden',
            width: 100,
            height: 100,
            justifySelf: 'flex-end',
            border: 'solid',
            borderWidth: '0.25px',
          }}
        >
          {ele.imageURL === 'not-found' ? (
            <div
              style={{
                objectFit: 'contain',
                width: 50,
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(17,17,17)',
                borderRadius: 10,
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                }}
              >
                {ele.symbol[0]}
              </p>
            </div>
          ) : (
            <img
              // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
              src={ele.imageURL}
              style={{
                objectFit: 'contain',
                width: 80,
                height: 80,
                padding: 10,
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
          }}
        >
          <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold' }}>
            {ele.symbol}
          </p>
          {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
        </div>
      </div>
    )
  }

  getHeight = () => {
    let totalHeight = window.innerHeight
    return totalHeight * 0.7
  }

  selectStock = () => {
    let presetData = this.props.presetTickers
    console.log('presetData', presetData)
    let holdingsData = this.state.holdingsData
    console.log('holdingsData', holdingsData)
    let allData = { ...presetData, MyHoldings: holdingsData }
    console.log('allData', allData)
    if (this.state.titleText !== 'Make a Trade') {
      this.setState({ titleText: 'Make a Trade' })
    }
    let data = []
    if (this.props.presetTickers.most_active) {
      data = allData[this.state.theme]
    }
    let mapped = data.map((ele) => this.createBox(ele))
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgb(255,255,255)',
          padding: 20,
          borderRadius: 12,
          marginBottom: 25,
        }}
      >
        {/* <div style={{width:'90%', margin:'auto', marginTop: 25, marginBottom: 25}}>
                            <p style={{fontSize:22}}>
                                Search for any stock by company name or symbol
                            </p>
                            <p style={{fontSize:22}}>
                                Click on any result to invest in that company
                            </p>
                        </div> */}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgb(255,255,255)',
              width: '75%',
              marginBottom: 20,
              borderRadius: 20,
              paddingLeft: 10,
              display: 'flex',
              justifyContent: 'flex-start',
              boxShadow: this.state.hover
                ? '0 0 10px rgb(223,225,229)'
                : 'none',
              borderColor: 'rgb(237,238,240)',
              borderStyle: 'solid',
              borderWidth: 'thin',
              minWidth: 250,
            }}
            onMouseEnter={() => this.setState({ hover: 'input' })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <div
              style={{
                minWidth: 20,
                width: '5%',
                /*backgroundColor: '#00A99D',*/ display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FaSearch size={'50%'} style={{ color: '#dddddd' }} />
            </div>
            <input
              ref={this.input}
              style={{
                width: '90%',
                border: 'none',
                outline: 'none',
                paddingTop: 5,
                paddingBottom: 5,
              }}
              placeholder='Enter stock symbol'
              type='text'
              value={this.state.searchMomentumText}
              onChange={(event) => {
                let searchMomentumText = event.target.value.toUpperCase()
                this.setState(
                  { searchMomentumText, selectedPreset: null, errors: false },
                  () => {
                    const { isSearching } = this.state
                    if (isSearching) {
                      return
                    } else {
                      this.setState({ isSearching: true })
                      setTimeout(() => {
                        this.setState(
                          { isSearching: false },
                          this.onSubmitSearchMomentum
                        )
                      }, 2000)
                    }
                  }
                )
              }}
            />
          </div>
        </div>
        <div style={{ width: '100%', marginBottom: 10, marginTop: 10 }}>
          <div
            style={{
              width: '95%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'most_active'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'most_active' })
              }
            >
              <p style={{ margin: 0 }}>Top 30</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'MyHoldings'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'MyHoldings' })
              }
            >
              <p style={{ margin: 0 }}>My Holdings</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Energy'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Energy' })
              }
            >
              <p style={{ margin: 0 }}>Energy</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Communications'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({
                  searchMomentumText: '',
                  theme: 'Communications',
                })
              }
            >
              <p style={{ margin: 0 }}>Comms</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'ETFs'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'ETFs' })
              }
            >
              <p style={{ margin: 0 }}>ETFs</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Finance'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Finance' })
              }
            >
              <p style={{ margin: 0 }}>Finance</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'SaaS'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'SaaS' })
              }
            >
              <p style={{ margin: 0 }}>Saas</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Cannabis'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Cannabis' })
              }
            >
              <p style={{ margin: 0 }}>Cannabis</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'EV'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'EV' })
              }
            >
              <p style={{ margin: 0 }}>EV</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Recovery'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Recovery' })
              }
            >
              <p style={{ margin: 0 }}>Recovery</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Semis'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Semis' })
              }
            >
              <p style={{ margin: 0 }}>Semis</p>
            </div>
            <div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                cursor: 'pointer',
                borderRadius: 10,
                marginRight: 5,
                marginBottom: 5,
                backgroundColor:
                  this.state.theme === 'Healthcare'
                    ? 'rgb(218,240,240)'
                    : 'rgb(238, 238, 238)',
              }}
              onClick={() =>
                this.setState({ searchMomentumText: '', theme: 'Healthcare' })
              }
            >
              <p style={{ margin: 0 }}>Healthcare</p>
            </div>
          </div>
        </div>
        <div
          style={{
            maxHeight: 350,
            overflowY: 'scroll',
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
            minHeight: this.getHeight(),
          }}
        >
          {this.state.searchMomentumText.length > 0 ? (
            this.state.isSearching ? (
              <div style={{ width: '100%', marginTop: 75 }}>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: 22,
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  Loading...
                </p>
              </div>
            ) : (
              <div
                style={{
                  width: '95%',
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  marginLeft: 15,
                }}
              >
                {this.state.momDataSource.map((ele) => this.createBox(ele))}
              </div>
            )
          ) : (
            <div
              style={{
                width: '95%',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginLeft: 15,
              }}
            >
              {mapped.length === 0 ? (
                <Spinner
                  animation='border'
                  id='spinner'
                  variant='dark'
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                mapped
              )}
            </div>
          )}
          {this.displayErrors()}
        </div>
      </div>
    )
  }

  owned = (account, symbol) => {
    let total = 0
    account.holdings.forEach((stock) => {
      if (stock.symbol === symbol) {
        total = stock.quantity
      }
    })
    this.setState({ sharesOwned: total })
  }

  cost = () => {
    if (this.state.purchaseMethod === 'numShares') {
      if (this.state.quantity > 0) {
        if (this.state.priceType === 'LIMIT') {
          return parseFloat(this.state.quantity) * this.state.limitPrice
        } else {
          return (
            parseFloat(this.state.quantity) * this.state.selected.live.price
          )
        }
      } else {
        return 0
      }
    } else if (this.state.purchaseMethod === 'dollarAmt') {
      if (this.state.price > 0) {
        if (this.state.priceType === 'LIMIT') {
          return parseFloat(this.state.limitPrice)
        } else {
          return parseFloat(this.state.price)
        }
      } else {
        return 0
      }
    } else {
      console.log('LIMIT ORDER HERE')
      return this.availableFunds() * parseFloat(this.state.pcnt)
    }
  }

  setBuyPercent = (e) => {
    let shareprice
    console.log('changing')
    console.log(e.target.value)
    if (e.target.value === '.') {
      this.setState({
        price: 0,
        quantity: 0,
        pcnt: '0.',
        lastChanged: 'cost',
        errors: '',
      })
    } else if (this.numberOnlyInput(e.target.value)) {
      if (this.state.priceType === 'MARKET') {
        shareprice = this.state.selected.live.price
      } else {
        shareprice = this.state.limitPrice
      }
      let price = ((this.availableFunds() * e.target.value) / 100).toFixed(2)
      let quantity = (price / shareprice).toFixed(8)
      // if(quantity <= 10000){
      this.setState({
        pcnt: e.target.value,
        quantity,
        price,
        lastChanged: 'percent',
        errors: '',
      })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  setMaxShares = () => {
    let shareprice
    if (this.state.priceType === 'MARKET') {
      shareprice = this.state.selected.live.price
    } else {
      shareprice = this.state.limitPrice
    }
    let price = (10000 * shareprice).toFixed(2)
    let pcnt = ((price / this.availableFunds()) * 100).toFixed(2)
    this.setState({
      price,
      quantity: 10000,
      pcnt,
      lastChanged: 'quantity',
      errors: 'maximum order quanity is 10,000 shares',
    })
  }

  setBuyCost = (e) => {
    let shareprice
    if (e.target.value === '.') {
      this.setState({
        price: '0.',
        quantity: 0,
        pcnt: 0,
        lastChanged: 'cost',
        errors: '',
      })
    } else if (this.numberOnlyInput(e.target.value)) {
      if (this.state.priceType === 'MARKET') {
        shareprice = this.state.selected.live.price
      } else {
        shareprice = this.state.limitPrice
      }
      let pcnt = ((e.target.value / this.availableFunds()) * 100).toFixed(2)
      let quantity = (e.target.value / shareprice).toFixed(8)
      // if(quantity <= 10000){
      this.setState({
        price: e.target.value,
        quantity,
        pcnt,
        lastChanged: 'cost',
        errors: '',
      })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  setLimit = (e) => {
    let shareprice
    if (e.target.value === '.') {
      this.setState({ limitPrice: '0.' })
    } else if (this.numberOnlyInput(e.target.value)) {
      this.setState({
        limitPrice: e.target.value,
        price: (e.target.value * this.state.quantity).toFixed(2),
      })
    }
  }

  setBuyNumShares = (e) => {
    let shareprice
    if (e.target.value === '.') {
      this.setState({
        price: 0,
        quantity: '0.',
        pcnt: 0,
        lastChanged: 'cost',
        errors: '',
      })
    } else if (this.numberOnlyInput(e.target.value, 'shares')) {
      if (this.state.priceType === 'MARKET') {
        shareprice = this.state.selected.live.price
      } else {
        shareprice = this.state.limitPrice
      }
      let price = (e.target.value * shareprice).toFixed(2)
      console.log('asdf' + shareprice)
      let pcnt = ((price / this.availableFunds()) * 100).toFixed(2)
      // if(e.target.value <= 10000){
      this.setState({
        price,
        quantity: e.target.value,
        pcnt,
        lastChanged: 'quantity',
        errors: '',
      })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  showShares = () => {
    if (this.state.quantity === '.' || this.state.quantity === '0.') {
      return '0.'
    } else if (this.state.quantity === '') {
      return ''
    } else {
      // return parseFloat(this.state.quantity)
      return this.state.quantity
    }
  }

  setSellPercent = (e) => {
    let shareprice
    let mv
    if (e.target.value === '.') {
      this.setState({
        price: 0,
        quantity: 0,
        pcnt: '0.',
        lastChanged: 'cost',
        errors: '',
      })
    } else if (this.numberOnlyInput(e.target.value)) {
      if (this.state.priceType === 'MARKET') {
        shareprice = this.state.selected.live.price
      } else {
        shareprice = this.state.limitPrice
      }
      mv = shareprice * this.state.sharesOwned
      let price = ((mv * e.target.value) / 100).toFixed(2)
      let quantity = (
        (parseFloat(e.target.value) * this.state.sharesOwned) /
        100
      ).toFixed(8)
      // if(quantity <= 10000){
      this.setState({
        pcnt: e.target.value * 100,
        quantity,
        price,
        lastChanged: 'percent',
        errors: '',
      })
      // }
      // else{
      //     this.setMaxShares()
      // }
    }
  }

  setSellCost = (e) => {
    let shareprice
    let mv
    if (e.target.value === '.') {
      this.setState({
        price: '0.',
        quantity: 0,
        pcnt: 0,
        lastChanged: 'cost',
        errors: '',
      })
    } else {
      if (this.numberOnlyInput(e.target.value)) {
        if (this.state.priceType === 'MARKET') {
          shareprice = this.state.selected.live.price
        } else {
          shareprice = this.state.limitPrice
        }
        mv = shareprice * this.state.sharesOwned
        let pcnt = (e.target.value / mv).toFixed(2)
        let quantity = (e.target.value / shareprice).toFixed(8)
        this.setState({
          price: e.target.value,
          quantity,
          pcnt,
          lastChanged: 'cost',
          errors: '',
        })
      }
    }
  }

  setSellNumShares = (e) => {
    let shareprice
    if (e.target.value === '.') {
      console.log('in decimal')
      this.setState({
        price: 0,
        quantity: '0.',
        pcnt: 0,
        lastChanged: 'cost',
        errors: '',
      })
    } else {
      if (this.numberOnlyInput(e.target.value, 'shares')) {
        if (this.state.priceType === 'MARKET') {
          shareprice = this.state.selected.live.price
        } else {
          shareprice = this.state.limitPrice
        }
        let price = (e.target.value * shareprice).toFixed(2)
        let pcnt = ((e.target.value / this.state.sharesOwned) * 100).toFixed(2)
        this.setState({
          price,
          quantity: e.target.value,
          pcnt,
          lastChanged: 'quantity',
          errors: '',
        })
      }
    }
  }

  numberOnlyInput(val, inputType) {
    let goodNum = true
    let arr = val.split('')
    let acceptable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    let i = 0
    let dots = 0
    if (inputType === 'shares' && this.state.priceType === 'LIMIT') {
      console.log('NEEDS TO BE INT')
      if (arr.includes('.')) {
        return false
      }
    }
    if (arr.includes('.')) {
      if (arr.length > 11) {
        return false
      }
    } else {
      if (arr.length > 8) {
        return false
      }
    }
    while (i < arr.length) {
      if (arr[i] === '.' && dots === 0) {
        console.log(1)
        dots = 1
        i++
      } else if (arr[i] === '.' && dots === 1) {
        console.log(2)
        goodNum = false
        i += arr.length
      } else if (acceptable.includes(arr[i])) {
        console.log(3)
        i++
      } else {
        console.log(4)
        goodNum = false
        i += arr.length
      }
    }
    if (arr.includes('.')) {
      let i = arr.indexOf('.')
      console.log('index ' + i + ',' + arr.length)
      if (inputType !== 'shares') {
        if (arr.length - i > 3) {
          return false
        }
      }
    }
    return goodNum
  }

  purchaseInput = () => {
    if (this.state.purchaseMethod === 'numShares') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ margin: 0, marginRight: 20, verticalAlign: 'middle' }}>
            Number of Shares
          </p>
          <input
            type='text'
            value={this.state.quantity}
            onChange={(e) => {
              if (this.numberOnlyInput(e.target.value)) {
                this.setState({ quantity: e.target.value })
              }
            }}
          />
        </div>
      )
    } else if (this.state.purchaseMethod === 'dollarAmt') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ margin: 0, marginRight: 20 }}>Price</p>
          <input
            type='text'
            value={this.state.price}
            onChange={(e) => {
              if (this.numberOnlyInput(e.target.value)) {
                this.setState({ price: e.target.value })
              }
            }}
          />
        </div>
      )
    } else {
      return (
        <div style={{}}>
          <p style={{ margin: 0, textAlign: 'center' }}>
            {(this.state.pcnt * 100).toFixed(0)}%
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '2%',
            }}
          >
            <input
              style={{}}
              type='range'
              min='0.01'
              max='1'
              step={0.01}
              value={this.state.pcnt}
              onChange={(e) => this.setState({ pcnt: e.target.value })}
            />
          </div>
        </div>
      )
    }
  }

  addCommas(x) {
    if (x === '') {
      return ''
    } else {
      let arr = x.toString().split('.')
      if (arr[1]) {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + arr[1]
      } else {
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    }
  }

  setTrade = () => {
    // console.log('this.availableFunds()', this.availableFunds())
    // this.startTradeStatusTimer()
    if (this.state.titleText !== 'Make a Trade') {
      this.setState({ titleText: 'Make a Trade' })
    }

    let price =
      this.state.selected.live && this.state.selected.live.price
        ? this.addCommas(this.state.selected.live.price.toFixed(2))
        : this.state.selected.priceUSD
        ? this.addCommas(this.state.selected.priceUSD.toFixed(2))
        : 0
    let so = this.state.sharesOwned.toFixed(8).split('.')
    let sharesOwned = this.addCommas(so[0]) + '.' + so[1]
    let limit = this.state.limitPrice
    console.log('limit', limit)
    if (limit.split('.')[1] && limit.split('.')[1].length > 2) {
      parseFloat(limit).toFixed(2)
    }
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '2%',
            paddingBottom: '2%',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 7,
                overflow: 'hidden',
                width: 100,
                height: 100,
                justifySelf: 'flex-end',
              }}
            >
              {this.state.selected.live.imageURL === 'not-found' ? (
                <div
                  style={{
                    objectFit: 'contain',
                    width: 50,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgb(17,17,17)',
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 24,
                      fontWeight: 'bold',
                      color: 'rgb(255,255,255)',
                    }}
                  >
                    {this.state.selected.live.symbol[0]}
                  </p>
                </div>
              ) : (
                <img
                  // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                  src={this.state.selected.live.imageURL}
                  style={{
                    objectFit: 'contain',
                    width: 80,
                    height: 80,
                    padding: 10,
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 28 }}>
              {this.state.selected.live.symbol}
            </p>
            <p style={{ margin: 0, fontSize: 24 }}>
              {this.state.acc
                ? `${this.state.acc.inst} ${this.state.acc.mask_full}`
                : null}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.state.selected.live.company}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Shares Owned:{' '}
              </strong>
              {parseFloat(sharesOwned) === 0 ? 0 : sharesOwned}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {this.state.selected.live.industry}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Position Value:{' '}
              </strong>
              $
              {this.state.sharesOwned === 0
                ? 0
                : this.addCommas((this.state.sharesOwned * price).toFixed(2))}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Price:{' '}
              </strong>
              ${price}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Buying Power:{' '}
              </strong>
              ${this.addCommas(this.availableFunds())}
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Medium Term Forecast*:{' '}
              </strong>
              {/* if need optimization is false show high risk */}
              {/* this.state.selected.returnForecast*this.state.selected.priceUSD+this.state.selected.priceUSD */}
              {console.log(this.state.selected)}
              {this.state.selected.live.returnForecastValue
                ? this.state.selected.live.needOptimization
                  ? `$${this.addCommas(
                      this.state.selected.live.returnForecastValue.toFixed(2)
                    )}`
                  : 'HIGH RISK'
                : 'N/A'}
            </p>
            <p
              style={{
                margin: 0,
                color: 'rgb(88,183,180)',
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              <strong style={{ fontWeight: 'normal', color: 'rgb(17,17,17)' }}>
                Account Value:{' '}
              </strong>
              ${this.addCommas(this.state.acc.balances[0].current.toFixed(2))}
            </p>
          </div>
          <hr></hr>
          <div
            style={{
              paddingTop: 0,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
              justifyContent: 'space-around',
            }}
          >
            <div
              id='TradeModal_BuyOption'
              style={{
                borderRight: 'solid',
                borderColor: 'rgb(255,255,255)',
                borderWidth: '0.5px',
                width: '48%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.buySell === 'buy'
                    ? 'rgb(52,131,129)'
                    : 'rgb(200,200,200)',
                padding: 5,
                borderRadius: 8,
              }}
              onClick={() => {
                if (this.state.acc.status === 'OPEN_NO_NEW_TRADES') {
                  this.setState({
                    errors:
                      'Your account status is "liquidate only" so you can only sell stocks for now. Please contact ForeSCITE support to rectify this issue.',
                  })
                } else if (this.availableFunds() > 0.01) {
                  this.setState({ buySell: 'buy', errors: '' })
                } else {
                  this.setState({
                    errors: 'You do not have any cash to invest with',
                  })
                }
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: this.state.buySell === 'buy' ? 'rgb(255,255,255)' : '',
                }}
              >
                Buy
              </p>
            </div>
            <div
              id='TradeModal_SellOption'
              style={{
                width: '48%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.buySell === 'sell'
                    ? 'rgb(52,131,129)'
                    : 'rgb(200,200,200)',
                padding: 5,
                borderRadius: 8,
              }}
              onClick={() => {
                if (this.state.sharesOwned !== 0) {
                  this.setState({ buySell: 'sell', errors: '' })
                } else {
                  this.setState({
                    errors: 'You cannot sell shares you do not own',
                  })
                }
              }}
            >
              <p
                style={{
                  margin: 0,
                  color:
                    this.state.buySell === 'sell' ? 'rgb(255,255,255)' : '',
                }}
              >
                Sell
              </p>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>Price Type:</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 7,
                paddingBottom: 7,
                background: 'rgb(225,238,238)',
                borderRadius: 5 /*width:'40%'*/,
              }}
            >
              <select
                id='TradeModal_PriceTypeSelect'
                placeholder='Select Below'
                onChange={(e) => {
                  this.setState({ priceType: e.target.value }, () => {
                    if (this.state.priceType === 'LIMIT') {
                      console.log('CHANING TO LIMIT')
                      if (this.state.quantity) {
                        console.log(this.state.quantity)
                        let toInt = parseInt(this.state.quantity)
                        console.log(toInt)
                        let shareprice
                        shareprice = this.state.limitPrice
                        let price = (toInt * shareprice).toFixed(2)
                        let pcnt = (
                          (price / this.availableFunds()) *
                          100
                        ).toFixed(2)
                        this.setState({
                          price,
                          quantity: toInt,
                          pcnt,
                          lastChanged: 'quantity',
                          errors: '',
                        })
                      }
                    }
                  })
                }}
                value={this.state.priceType}
                style={{
                  border: 'none',
                  textAlign: 'right',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                  outline: 'none',
                  background: 'transparent',
                }}
              >
                <option
                  id='PriceType_Market'
                  value='MARKET'
                  selected={this.state.priceType == 'MARKET'}
                >
                  Market Order
                </option>
                <option
                  id='PriceType_Limit'
                  value={'LIMIT'}
                  selected={this.state.priceType == 'LIMIT'}
                >
                  Limit Order
                </option>
                {/*                                 
                                <option value={'Stop on Quote'} selected={this.state.priceType == 'Stop on Quote'}>Stop on Quote</option>
                                <option value={'Stop Limit on Quote'} selected={this.state.priceType == 'Stop Limit on Quote'}>Stop Limit on Quote</option>
                                <option value={'Trailing Stop $'} selected={this.state.priceType == 'Trailing Stop $'}>Trailing Stop $</option>
                                <option value={'Trailing Stop %'} selected={this.state.priceType == 'Trailing Stop %'}>Trailing Stop %</option> 
                                */}
              </select>
            </div>
          </div>

          {this.state.priceType === 'LIMIT' ? (
            <div
              style={{
                paddingTop: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <p style={{ margin: 0, marginRight: 25 }}>Limit Price:</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 10,
                  background: 'rgb(225,238,238)',
                  borderRadius: 5,
                  width: '40%',
                }}
              >
                <input
                  id='LimitPrice_Input'
                  style={{
                    border: 'none',
                    textAlign: 'right',
                    margin: 0,
                    color: 'rgb(52,131,129)',
                    outline: 'none',
                    background: 'transparent',
                  }}
                  type='text'
                  value={limit}
                  onChange={(e) => {
                    this.setLimit(e)
                  }}
                />
              </div>
            </div>
          ) : null}

          {/* <div style={{paddingTop:10, width:'100%', }}>
                    
                    <div style={{display:'flex', width:'100', alignItems:'center', cursor:'pointer'}}>
                        <div onClick={()=>this.setState({purchaseMethod:'numShares'})} style={{width:'33%', borderRight:'solid', borderColor:'rgb(255,255,255)', borderWidth:'0.25px', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'numShares'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center', color: this.state.purchaseMethod === 'numShares'? 'rgb(255,255,255)':'',}}>Number of Shares</p>
                        </div>
                        <div onClick={()=>this.setState({purchaseMethod:'dollarAmt'})} style={{width:'33%', borderRight:'solid', borderColor:'rgb(255,255,255)', borderWidth:'0.25px', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'dollarAmt'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center',color: this.state.purchaseMethod === 'dollarAmt'? 'rgb(255,255,255)':'',}}>US Dollar Amount</p>
                        </div>
                        <div onClick={()=>this.setState({purchaseMethod:'pcnt'})} style={{width:'33%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.purchaseMethod === 'pcnt'? 'rgb(52,131,129)':'rgb(243,243,243)', padding: 13, }}>
                            <p style={{margin:0, textAlign:'center',color: this.state.purchaseMethod === 'pcnt'? 'rgb(255,255,255)':'',}}>Funds Percentage</p>
                        </div>
                    </div>
                </div> */}
          {/* <div style={{paddingTop:10, width:'100%', }}>
                    {this.purchaseInput()}          
                </div> */}
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>
              {this.state.lastChanged === 'quantity' ? '' : 'Approx '} Number of
              Shares{' '}
              {this.state.priceType === 'LIMIT' ? '(Must be whole number)' : ''}
              :
            </p>
            {this.state.buySell === 'sell' ? (
              <div
                style={{
                  borderRight: 'solid',
                  cursor: 'pointer',
                  borderColor: 'rgb(255,255,255)',
                  borderWidth: '0.5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgb(52,131,129)',
                  padding: 5,
                  borderRadius: 8,
                }}
                id='SellAll_Button'
                onClick={() => {
                  let p = (this.state.sharesOwned * price).toFixed(2)
                  if (this.state.priceType === 'LIMIT') {
                    if (isInteger(this.state.sharesOwned) === false) {
                      this.setState({
                        errors:
                          'You cannot sell fractional shares with limit orders',
                      })
                    }
                    this.setState({
                      quantity: this.state.sharesOwned,
                      pcnt: 100,
                      price: p,
                      lastChanged: 'quantity',
                      errors: '',
                      purchaseMethod: 'numShares',
                    })
                  } else {
                    if (this.state.priceType === 'MARKET') {
                      p = parseFloat(this.state.sharesOwned) * parseFloat(price)
                    }
                    this.setState({
                      quantity: this.state.sharesOwned,
                      pcnt: 100,
                      price: p,
                      lastChanged: 'quantity',
                      errors: '',
                      purchaseMethod: 'numShares',
                    })
                  }
                }}
              >
                <p
                  style={{ margin: 0, color: 'rgb(255,255,255)', minWidth: 50 }}
                >
                  Sell All
                </p>
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 10,
                background: 'rgb(225,238,238)',
                borderRadius: 5,
                width: '40%',
              }}
            >
              <input
                id='NumberOfShares_Input'
                style={{
                  border: 'none',
                  textAlign: 'right',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                  outline: 'none',
                  background: 'transparent',
                }}
                type='text'
                value={this.showShares()}
                onChange={(e) => {
                  if (this.state.buySell === 'sell') {
                    this.setSellNumShares(e)
                  } else {
                    this.setBuyNumShares(e)
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {/* <p style={{margin:0, marginRight:25}}>
                    {this.state.lastChanged !== 'quantity' ? '':'Approx '} {this.state.buySell === 'sell'?'% Position':'% Funds'}: 
                    </p>
                        <input
                            id='Range_Slider' 
                            style={{}}type="range" 
                            min="1"
                            max="100" 
                            step={1}
                            value={this.state.pcnt}
                            onChange={(e)=>{
                                if(this.state.buySell === 'sell'){
                                    this.setSellPercent(e)
                                }else{
                                    this.setBuyPercent(e)
                                }
                            }
                        }/> */}
            {/* <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'20%'}}>
                            <input
                                id='Range_Input' 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent'}}
                                type="text" 
                                value={this.state.pcnt} 
                                onChange={(e)=>{
                                    if(this.state.buySell === 'sell'){
                                        this.setSellPercent(e)
                                    }else{
                                        this.setBuyPercent(e)
                                    }
                                }
                            }/>
                            <p style={{margin:0,color:'rgb(52,131,129)'}}>%</p>
                    </div> */}
          </div>
          <div
            style={{
              paddingTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p style={{ margin: 0, marginRight: 25 }}>
              {this.state.lastChanged !== 'quantity' ? '' : ''}Total Value
              (USD):
            </p>
            {this.state.priceType === 'LIMIT' ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 10,
                  borderRadius: 5,
                  width: '40%',
                  minHeight: 45,
                }}
              >
                {/* <input
                                id='TotalValue_Input' 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}
                                type="text" 
                                value={this.state.price} 
                                onChange={(e)=>{
                                    if(this.state.buySell === 'sell'){
                                        this.setSellCost(e)
                                    }else{
                                        this.setBuyCost(e)
                                    }
                                }
                            }/> */}
                <p style={{ margin: 0, color: 'rgb(52,131,129)' }}>
                  {this.state.price}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 10,
                  background: 'rgb(225,238,238)',
                  borderRadius: 5,
                  width: '40%',
                }}
              >
                <input
                  id='TotalValue_Input'
                  style={{
                    border: 'none',
                    textAlign: 'right',
                    margin: 0,
                    color: 'rgb(52,131,129)',
                    outline: 'none',
                    background: 'transparent',
                  }}
                  type='text'
                  value={this.state.price}
                  onChange={(e) => {
                    if (this.state.buySell === 'sell') {
                      this.setSellCost(e)
                    } else {
                      this.setBuyCost(e)
                    }
                  }}
                />
                {/* <p style={{margin:0,color:'rgb(52,131,129)'}}>{this.state.price}</p> */}
              </div>
            )}
          </div>

          <div style={{ paddingTop: 10, width: '100%' }}>
            {this.state.errors.length > 0 ? this.displayErrors() : null}
          </div>
          <div
            style={{
              width: '100%',
              paddingTop: 25,
              paddingBottom: 10,
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <div
              id='GoBack_Button'
              onClick={
                this.props.stockPage
                  ? () => this.setState({ acc: 'NO', errors: '' })
                  : () => {
                      this.setState({
                        selected: false,
                        quantity: '',
                        price: '',
                        limitPrice: '',
                        pcnt: '',
                        errors: '',
                      })
                      clearInterval(this.priceInterval)
                    }
              }
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Go Back</p>
            </div>
            <div
              id='PreviewTrade_Button'
              onClick={() => this.canTrade()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(52,131,129)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(52,131,129)' }}>
                Preview Trade
              </p>
            </div>
          </div>
          <div>
            <p style={{ fontSize: 12 }}>
              *Price forecast is based on ForeSCITE's AI powered forecasting
              model. It is neither advice nor recommendation to Buy or Sell
              stocks.
            </p>
          </div>
        </div>
      </div>
    )
  }

  time = () => {
    let both
    if (this.state.firstTime === true) {
      both = new Date().toLocaleString()
      this.setState({ firstTime: both })
    } else {
      both = this.state.firstTime
    }

    let spl = both.split(',')
    return spl[1] + ' ' + spl[0]
  }

  availableFunds = () => {
    let total = 0
    this.state.acc.holdings.forEach((stock) => {
      if (
        (stock.symbol === 'CASH' || stock.symbol === 'Us dollar') &&
        stock.instPrice === 1
      ) {
        total += stock.quantity
      }
    })
    return this.state.acc.balances[0].buyingPower
  }

  canTrade = () => {
    let availableFunds = this.availableFunds()
    let cost = this.cost()
    // console.log('cost',cost)
    // console.log('price',this.state.price)
    let errors = ''
    if (!this.state.buySell) {
      errors = 'Please select buy or sell'
    } else if (cost <= 0 || this.state.price === '0.00') {
      errors = 'You can not trade for less than $0'
    }
    // else if(this.state.priceType === 'LIMIT' && this.state.quantity === Math.floor(this.state.quantity)) {
    //     errors = 'You cannot do LIMIT orders for fractional shares'
    // }
    else if (this.state.buySell === 'buy' && cost > availableFunds) {
      errors = 'You do not have enough funds for this trade'
    } else if (
      this.state.buySell === 'sell' &&
      this.state.quantity > this.state.sharesOwned
    ) {
      errors = 'You cannot sell more shares than you own'
    } else if (this.state.quantity > 10000) {
      errors =
        'Maximum number of shares per order is 10,000. Please send multiple orders if necessary.'
    } else if (
      this.state.priceType === 'LIMIT' &&
      this.state.quantity % 1 !== 0
    ) {
      errors = 'Limit orders cannnot include fractional shares'
    }

    if (errors.length > 0) {
      this.setState({ errors })
    } else {
      // GO TO SUMMARY
      if (this.state.acc.inst === 'DriveWealth') {
        this.setState({ summary: true })
      }
    }
  }

  successErrorLoadingBackground = () => {
    if (this.state.orderPlaced === 'Order successfully submitted') {
      return 'rgb(234,255,219)'
    } else if (this.state.orderPlaced === 'Placing Order...') {
      return 'rgb(225,225,235)'
    } else {
      return 'rgb(253,180,181)'
    }
  }

  shareWithFriends = () => {
    return (
      <div
        onClick={() => this.setState({ showShare: true })}
        style={{
          width: '40%',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'solid',
          borderWidth: '0.5px',
          borderColor: 'rgb(30,30,30)',
          paddingTop: 7,
          paddingBottom: 7,
          borderRadius: 8,
          cursor: 'pointer',
        }}
      >
        <p style={{ margin: 0, color: 'rgb(30,30,30)', marginRight: 5 }}>
          Share
        </p>
        <FaShareSquare />
      </div>
    )
  }

  sendMessage = () => {
    console.log('sending message')
  }
  shareMessage = () => {
    if (this.state.showShare) {
      return (
        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex', width: '10%', alignItems: 'center' }}>
            <p style={{ margin: 0, marginRight: 10 }}>To:</p>
            <input
              type='text'
              placeholder='name@email.com'
              value={this.state.recipient}
              onChange={(e) => this.setState({ recipient: e.target.value })}
            />
          </div>
          <div style={{ display: 'flex', width: '10%', alignItems: 'center' }}>
            <p style={{ margin: 0, marginRight: 10 }}>From:</p>
            <input
              type='text'
              placeholder='name@email.com'
              value={this.state.sender}
              onChange={(e) => this.setState({ sender: e.target.value })}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <p style={{ margin: 0 }}>Message:</p>
            <p style={{ margin: 0 }}>
              {`I just ${
                this.state.buySell === 'buy' ? 'invested in' : 'sold'
              } ${parseFloat(this.state.quantity).toFixed(2)} shares of ${
                this.state.selected.symbol
              }. ForeSCITE projects that ${
                this.state.selected.symbol
              } will be worth $${
                this.state.selected.returnForecastValue &&
                this.state.selected.returnForecastValue + 1 >
                  this.state.selected.priceUSD
                  ? this.state.selected.returnForecastValue.toFixed(0)
                  : null
              } in the next 30 days. You can trade with ForeSCITE at `}
              <a href='https://www.foreSCITE.ai' target='_blank'>
                ForeSCITE.ai
              </a>
              .
            </p>
          </div>
          <div>
            <div
              onClick={() => this.sendMessage()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)', marginRight: 5 }}>
                Send
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  setRecBuy = () => {
    let body = {
      dw_account_id: this.state.acc.accountId,
      amount: this.state.price,
      stock: this.state.selected.live.symbol,
    }

    fetch(`${ENDPOINT}/mobile/recurring/buy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((responseJson) => {
        console.log('rec buy resp:', responseJson)
        if (responseJson.Status === 'Success') {
          console.log('in rb success@')
          this.setState({ recBuy: true })
        } else {
          this.setState({ errors: responseJson.status })
        }
      })
      .catch((err) => {
        console.log('trade submission errors: ', err)
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
      })
  }

  plaidHandleOnSuccess = (public_token, metadata) => {
    // send token to client server
    // fetch("/auth/public_token", {
    //   public_token: public_token
    // });
    let accountId = metadata.account_id
    let itemId = metadata.institution.institution_id
    this.linkPlaidAccount(public_token, metadata)
    // let resp = this.findOtherPortfoliosSameInstitution(itemId)
    // if (resp.length > 0){
    //     this.addOtherPortSameInstituion(accountId, itemId)
    // }
    // this.setState({respMeta:metadata, respToken:public_token})
  }

  linkPlaidAccount = (token, meta) => {
    // console.log('plaid token' ,token)
    // console.log('plaid meta' ,meta)
    let id = meta.account_id
    let mask = meta.account.mask
    let name = meta.account.name
    let subType = meta.account.subtype

    let inst_id = meta.institution.institution_id
    let inst_name = meta.institution.name
    let public_token = token

    let body = {
      accounts: [
        {
          id,
          mask,
          name,
          subType,
          type: 'depository',
        },
      ],
      inst_id,
      inst_name,
      public_token,
      type: 'depository',
    }
    // console.log('body: ', body)

    // fetch(`${ENDPOINT}/plaid/link`,{
    fetch(`${ENDPOINT}/mobile-plaid/plaid/link`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((respJSON) => {
        console.log('respJSON: ', respJSON)
        if (respJSON.Error === 'Successful Linked') {
          this.props.updateAccs()
          this.setState({
            errorsSucceses: 'Your account has been linked',
            plusDropdown: false,
          })
        } else {
          this.setState({ errorsSucceses: respJSON.Error, plusDropdown: false })
        }
      })
      .catch((err) => console.log('catching: ', err))
  }

  handleSetupRecurringBuy = () => {
    this.setState({ recurringLoading: true })
    if (this.state.recurringMonthly) {
      let num = parseFloat(this.state.recurringAmount).toFixed(2)
      let body = {
        dw_account_id: this.state.acc.accountId,
        plaid_account_id: this.state.checkingAccount,
        periodic: num,
        stock: this.state.selected.live.symbol,
        roundup: false,
      }
      console.log('SENDING')
      fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify(body),
      }).then((res) => {
        console.log(res)
        this.setState({ recurringBuyStep: 1, recurringLoading: false })
      })
    } else {
      let body = {
        dw_account_id: this.state.acc.accountId,
        plaid_account_id: this.state.checkingAccount,
        // "periodic": 0.0,
        stock: this.state.selected.live.symbol,
        roundup: true,
      }
      console.log('SENDING')
      fetch(`${ENDPOINT}/mobile/recurring/buy-new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        },
        body: JSON.stringify(body),
      }).then((res) => {
        // console.log(res.json());
        // if(res.json().Status === 'false') {
        //     console.log('Error')
        // } else {
        this.setState({ recurringBuyStep: 1, recurringLoading: false })
        // }
      })
    }
  }

  createDropdown = () => {
    let accounts = this.props.everyAccount.filter((account) => {
      if (account.type === 'depository') return account
    })
    accounts.unshift({ name: 'Please Select an Account' })
    return (
      <select
        onChange={(e) => {
          console.log(e.target.value)
          this.setState({ checkingAccount: e.target.value })
        }}
        style={{
          border: 'none',
          textAlign: 'left',
          margin: 0,
          color: 'rgb(52,131,129)',
          outline: 'none',
          background: 'transparent',
          width: '100%',
        }}
      >
        {accounts.map((account, index) => (
          <option key={index} value={account.accountId}>
            {account.name}
          </option>
        ))}
      </select>
    )
  }

  renderRecurringBuy = () => {
    switch (this.state.recurringBuyStep) {
      case 0:
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '5%',
                paddingRight: '5%',
                paddingTop: '2%',
                paddingBottom: '2%',
                width: '100%',
              }}
            >
              <p style={{ fontSize: 24 }}>Recurring Buy</p>
              <p>Select Method</p>
              <div style={{ width: '100%', margin: 'auto', marginBottom: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <input
                    type='radio'
                    checked={this.state.recurringMonthly === true}
                    onChange={() => {
                      this.setState({ recurringMonthly: true })
                    }}
                  />
                  <p style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                    Monthly
                  </p>
                  <input
                    type='number'
                    onChange={(e) => {
                      if (this.numberOnlyInput(e.target.value)) {
                        this.setState({ recurringAmount: e.target.value })
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <input
                    type='radio'
                    checked={this.state.recurringMonthly === false}
                    onChange={() => {
                      this.setState({ recurringMonthly: false })
                    }}
                  />
                  <p style={{ margin: 0, marginLeft: 10 }}>Round Up</p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {this.props.everyAccount.filter((account) => {
                  if (account.type === 'depository') return account
                }).length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <p>
                      Please select the checking account you would like to use
                    </p>
                    {this.createDropdown()}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                    }}
                  >
                    <p>
                      You do not have any checking accounts linked to ForeSCITE.
                      Please click the link below to link a checking account
                      with Plaid
                    </p>
                    <PlaidLink
                      id='LinkBankAccount_Button'
                      clientName='ForeSCITE'
                      // env="sandbox"
                      env='production'
                      product={['transactions']}
                      publicKey='3e65a03171afbab180fa7198f87cef'
                      onSuccess={this.plaidHandleOnSuccess}
                      linkCustomizationName={'bank'}
                      // className="test"
                      style={{
                        marginRight: '5%',
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                    >
                      <div
                        style={{
                          padding: 20,
                          border: '1px solid black',
                          borderRadius: 10,
                        }}
                      >
                        <p style={{ textDecoration: 'underline' }}>
                          Link a Checking Account
                        </p>
                      </div>
                    </PlaidLink>
                  </div>
                )}
              </div>
              {this.state.recurringLoading ? (
                <div>
                  <p>Loading</p>
                </div>
              ) : (this.state.recurringMonthly &&
                  this.state.recurringAmount &&
                  this.state.checkingAccount !== null &&
                  this.state.checkingAccount !== 'Please Select an Account') ||
                (!this.state.recurringMonthly &&
                  this.state.checkingAccount !== null &&
                  this.state.checkingAccount !== 'Please Select an Account') ? (
                <div
                  style={{
                    width: '40%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'solid',
                    borderWidth: '0.5px',
                    borderColor: 'rgb(30,30,30)',
                    borderRadius: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handleSetupRecurringBuy()}
                >
                  <p>Continue</p>
                </div>
              ) : (
                <div>
                  <p>Please Select all options</p>
                </div>
              )}
            </div>
          </>
        )
      case 1:
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FaCheckCircle color={brandColor} size={50} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>
                Success! You have set up a monthly recurring investment for{' '}
                {this.state.selected.live.company}
              </p>
            </div>
            <div
              id='TradeModal_BackButton'
              onClick={() => this.props.closeTrade()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Close</p>
            </div>
          </div>
        )
    }
  }

  summary = () => {
    // this.checkOrderStatus()
    if (this.state.titleText !== 'Review Trade') {
      this.setState({ titleText: 'Review Trade' })
    }

    let body = {
      dwAccountID: this.state.acc.accountId,
      orderType: this.state.priceType,
      symbol: this.state.selected.symbol,
      side: this.state.buySell.toUpperCase(),
      orderPrice:
        this.state.priceType === 'LIMIT' ? this.state.limitPrice : null,
      quantity: this.state.quantity,
      amount: this.state.price,
    }
    if (this.state.showShare) {
      return this.shareMessage()
    } else if (this.state.orderPlaced === 'Order successfully submitted') {
      this.startTradeStatusTimer()
      clearInterval(this.priceInterval)
      if (this.state.orderStatus !== 'new') {
        let message = ''
        if (this.state.priceType === 'MARKET') {
          message =
            this.state.lastChanged !== 'quantity'
              ? `You have successfully ${
                  this.state.buySell === 'buy' ? 'bought' : 'sold'
                } $${parseFloat(
                  this.state.orderResp.averagePrice *
                    this.state.orderResp.quantity
                ).toFixed(2)} of ${
                  this.state.selected.live.symbol
                } stock equal to ${
                  parseFloat(this.state.orderResp.quantity) > 100 ||
                  this.state.orderResp.quantity % 1 === 0
                    ? parseFloat(this.state.orderResp.quantity)
                    : parseFloat(this.state.orderResp.quantity).toFixed(8) * 1
                } ${
                  this.state.quantity === 1 ? 'share' : 'shares'
                } at a price of $${parseFloat(
                  this.state.orderResp.averagePrice
                ).toFixed(2)} each.`
              : `You have successfully ${
                  this.state.buySell === 'buy' ? 'bought' : 'sold'
                } ${
                  parseFloat(this.state.quantity) > 100 ||
                  this.state.quantity % 1 === 0
                    ? parseFloat(this.state.quantity)
                    : parseFloat(this.state.quantity).toFixed(8)
                } ${this.state.quantity === 1 ? 'share' : 'shares'} of ${
                  this.state.selected.live.symbol
                } at a price of $${
                  this.state.priceType === 'MARKET'
                    ? parseFloat(this.state.orderResp.averagePrice).toFixed(2)
                    : parseFloat(this.state.limitPrice).toFixed(2)
                } and a total ${
                  this.state.buySell === 'buy' ? 'investment' : 'sale'
                } amount of $${parseFloat(
                  this.state.orderResp.averagePrice *
                    this.state.orderResp.quantity
                ).toFixed(2)}.`
        } else {
          message =
            this.state.lastChanged !== 'quantity'
              ? `You have successfully placed an ${
                  this.state.priceType === 'MARKET' ? 'market ' : 'limit '
                } order to ${
                  this.state.buySell === 'buy' ? 'buy' : 'sell'
                } $${parseFloat(this.state.price).toFixed(2)} of ${
                  this.state.selected.live.symbol
                } stock, which is approximately ${
                  parseFloat(this.state.quantity) > 100 ||
                  this.state.quantity % 1 === 0
                    ? parseFloat(this.state.quantity)
                    : parseFloat(this.state.quantity).toFixed(8) * 1
                } ${this.state.quantity === 1 ? 'share' : 'shares'}.`
              : `You have successfully placed an ${
                  this.state.priceType === 'MARKET' ? 'market ' : 'limit '
                } order to ${this.state.buySell === 'buy' ? 'buy' : 'sell'} ${
                  parseFloat(this.state.quantity) > 100 ||
                  this.state.quantity % 1 === 0
                    ? parseFloat(this.state.quantity)
                    : parseFloat(this.state.quantity).toFixed(8)
                } ${this.state.quantity === 1 ? 'share' : 'shares'} of ${
                  this.state.selected.live.symbol
                } at a price of $${parseFloat(this.state.limitPrice).toFixed(
                  2
                )} and a total ${
                  this.state.buySell === 'buy' ? 'investment' : 'sale'
                } amount of approximately $${parseFloat(
                  this.state.limitPrice * this.state.quantity
                ).toFixed(2)}.`
        }
        return this.state.recurringBuyStep === -1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingTop: '2%',
              paddingBottom: '2%',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: -20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgb(255,255,255)',
                  borderRadius: 7,
                  overflow: 'hidden',
                  width: 100,
                  height: 100,
                  justifySelf: 'flex-end',
                }}
              >
                <img
                  // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
                  src={
                    this.state.selected.live.imageURL === 'not-found'
                      ? NoImage
                      : this.state.selected.live.imageURL
                  }
                  style={{ objectFit: 'contain', width: 100, height: 100 }}
                />
              </div>
            </div>
            {/* <div style={{display:'flex', justifyContent:'flex-start',marginBottom:25, marginTop:25 }}>
                                <p style={{margin:0, fontSize:28}}>
                                {this.state.buySell === 'buy' ? 'Investment ':'Sale '}Successful
                                </p>
                            </div> */}
            {this.state.orderResp.status === 'REJECTED' ? (
              <div style={{ marginBottom: 25, marginTop: 25 }}>
                <p style={{ margin: 0, fontSize: 24 }}>Trade Incomplete</p>
                <p style={{ margin: 0, fontSize: 24 }}>
                  {this.state.orderResp.statusMessage.message.split('[')[0]}
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: 25,
                  marginTop: 25,
                  textAlign: 'center',
                }}
              >
                {/* <p style={{margin:0, fontSize:24}}> */}
                {/* { 
                                    this.state.lastChanged !== "quantity" ? 
                                    `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)} of ${this.state.selected.symbol} stock equal to ${parseFloat(this.state.orderResp.quantity) > 100 || this.state.orderResp.quantity % 1 === 0? parseFloat(this.state.orderResp.quantity): parseFloat(this.state.orderResp.quantity).toFixed(8)*1} ${this.state.quantity === 1 ? 'share':'shares'} at a price of $${parseFloat(this.state.orderResp.averagePrice).toFixed(2)} each.`
                                        :
                                    `You have successfully ${this.state.buySell === 'buy'?'bought':'sold'} ${parseFloat(this.state.quantity) > 100 ||  this.state.quantity % 1 === 0? parseFloat(this.state.quantity): parseFloat(this.state.quantity).toFixed(8)} ${this.state.quantity === 1 ? 'share':'shares'} of ${this.state.selected.symbol} at a price of $${this.state.priceType === 'MARKET'? parseFloat(this.state.orderResp.averagePrice).toFixed(2):parseFloat(this.state.limitPrice).toFixed(2)} and a total ${this.state.buySell === 'buy'?'investment':'sale'} amount of $${parseFloat(this.state.orderResp.averagePrice*this.state.orderResp.quantity).toFixed(2)}.`
                                    } */}
                {/* {message} */}

                {/* </p> */}

                {this.state.priceType === 'MARKET' ? (
                  <p
                    style={{
                      fontSize: 30,
                      fontWeight: 'bold',
                      marginBottom: 30,
                    }}
                  >
                    Market {this.state.buySell} order has been successfully
                    placed!
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: 30,
                      fontWeight: 'bold',
                      marginBottom: 30,
                    }}
                  >
                    Limit {this.state.buySell} order has been successfully
                    placed!
                  </p>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <p style={{ fontSize: 20 }}>Symbol: </p>
                  <p style={{ fontSize: 20 }}>
                    {this.state.selected.live.symbol}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <p style={{ fontSize: 20 }}>Order Type: </p>
                  <p style={{ fontSize: 20 }}>{this.state.priceType}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <p style={{ fontSize: 20 }}>Quantity: </p>
                  <p style={{ fontSize: 20 }}>{this.state.quantity}</p>
                </div>
                {this.state.priceType ? (
                  this.state.priceType === 'MARKET' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <p style={{ fontSize: 20 }}>Market Price: </p>
                      <p style={{ fontSize: 20 }}>{this.state.price}</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <p style={{ fontSize: 20 }}>Limit Price: </p>
                      <p style={{ fontSize: 20 }}>{this.state.limitPrice}</p>
                    </div>
                  )
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: 25,
                  }}
                >
                  {this.state.priceType === 'MARKET' ? (
                    <p style={{ margin: 0, fontSize: 20 }}>Time of Trade: </p>
                  ) : (
                    <p style={{ margin: 0, fontSize: 20 }}>
                      Limit Order Created at:{' '}
                    </p>
                  )}

                  <p style={{ fontSize: 20 }}>{this.time()}</p>
                </div>
              </div>
            )}
            {this.state.buySell === 'buy' ? (
              <div
                style={{
                  width: '100%',
                  paddingBottom: 10,
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                {/* {this.shareWithFriends()} */}
                {/* onClick={()=>this.setRecBuy()}  */}

                <div
                  onClick={() => this.setState({ recurringBuyStep: 0 })}
                  style={{
                    width: '60%',
                    margin: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: 'solid',
                    borderWidth: '0.5px',
                    borderColor: 'rgb(30,30,30)',
                    paddingTop: 7,
                    paddingBottom: 7,
                    borderRadius: 8,
                    cursor: 'pointer',
                  }}
                >
                  <Button style={{ margin: 0, color: 'rgb(30,30,30)' }}>
                    Set up Recurring Buy
                  </Button>
                </div>
              </div>
            ) : null}

            <div
              style={{
                width: '100%',
                paddingBottom: 10,
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {/* {this.shareWithFriends()} */}
              <div
                onClick={() => this.props.closeTrade()}
                style={{
                  width: '40%',
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: 'rgb(30,30,30)',
                  paddingTop: 7,
                  paddingBottom: 7,
                  borderRadius: 8,
                  cursor: 'pointer',
                }}
              >
                <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Close</p>
              </div>
            </div>
          </div>
        ) : (
          this.renderRecurringBuy()
        )
      } else {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3>
              {this.state.selected.symbol}{' '}
              {this.capitalizeFirstLetter(this.state.priceType)}{' '}
              {this.capitalizeFirstLetter(this.state.buySell)} Order In
              Progress...
            </h3>
          </div>
        )
      }
    }
    let total = this.total(body.quantity)
    let commision = this.commisions(total, body.quantity)
    let totalCost = total - commision
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              id='CheckNBBO_Button'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginBottom: 5,
                border: 'solid',
                borderRadius: '5%',
                borderWidth: '1.25px',
                borderColor: 'rgb(52,131,129)',
                marginLeft: 10,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              onClick={() => this.getNBBOPrice(this.state.selected.live.symbol)}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: '',
                  color: 'rgb(52,131,129)',
                  textAlign: 'center',
                }}
              >
                See Additional Details
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 7,
              overflow: 'hidden',
              width: 100,
              height: 100,
              justifySelf: 'flex-end',
            }}
          >
            <img
              // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
              src={
                this.state.selected.live.imageURL === 'not-found'
                  ? NoImage
                  : this.state.selected.live.imageURL
              }
              style={{ objectFit: 'contain', width: 100, height: 100 }}
            />
          </div>
          <div style={{ width: '20%' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '2%',
            paddingBottom: '2%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <p style={{ margin: 0, fontSize: 36, fontWeight: 'bold' }}>
                {body.side === 'BUY' ? 'BUY' : 'SELL'}
              </p>
            </div>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                {this.state.selected.live.symbol}
              </p>
              <p style={{ margin: 0, fontSize: 18, textAlign: 'right' }}>
                {this.state.selected.live.company}
              </p>
            </div>
          </div>
          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Market Price</p>
              <p>${this.state.selected.live.price.toFixed(2)}</p>
            </div>
            {this.state.priceType === 'LIMIT' ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p>Limit Price</p>
                <p>${this.state.limitPrice}</p>
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Estimated Quantity</p>
              <p>{parseFloat(body.quantity).toFixed(8)}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Order Type</p>
              <p>{this.state.priceType}</p>
            </div>
            {this.state.selected.live ? (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Bid / Ask Price</p>
                  <p>
                    {this.state.selected.live.bid}
                    {' - '}
                    {this.state.selected.live.ask}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Bid / Ask Size</p>
                  <p>
                    {this.state.selected.live.bidSize}
                    {' - '}
                    {this.state.selected.live.askSize}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Bid / Ask Exchange</p>
                  <p>
                    {this.state.selected.live.bidExchange}
                    {' - '}
                    {this.state.selected.live.askExchange}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Day's Range</p>
                  <p>
                    {this.state.selected.live.low}
                    {' - '}
                    {this.state.selected.live.high}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ margin: 0 }}>Volume</p>
                  <p style={{ margin: 0 }}>
                    {this.addCommas(this.state.selected.live.volume)}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Estimated Commision</p>
              {/* <p>$0.00</p> */}
              <p>
                ${this.state.buySell === 'sell' ? commision.toFixed(8) : '0.00'}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Estimated Total</p>
              {/* <p style={{margin:0}}>${(body.quantity*this.state.selected.live.price).toFixed(2)}</p> */}
              {this.state.priceType === 'LIMIT' ? (
                <p style={{ margin: 0 }}>${this.state.price}</p>
              ) : (
                <p style={{ margin: 0 }}>${totalCost.toFixed(2)}</p>
              )}
            </div>
            <hr></hr>
          </div>
          {/* <div style={{display:'flex', flexDirection:'column',}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <p>Order Messages</p>
                        </div>
                    </div> */}
          {this.state.orderPlaced ? (
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                width: '60%',
                backgroundColor: this.successErrorLoadingBackground(),
                padding: 12,
                marginBottom: 10,
                borderRadius: 8,
              }}
            >
              <p style={{ margin: 'auto' }}>{this.state.orderPlaced}</p>
            </div>
          ) : null}
          <div
            style={{
              width: '100%',
              paddingBottom: 10,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div
              id='TradeModal_BackButton'
              onClick={() => this.setState({ summary: false, errors: '' })}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(30,30,30)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(30,30,30)' }}>Go back</p>
            </div>
            <div
              id='TradeModal_SubmitTradeButton'
              onClick={() => this.tradeStockDW()}
              style={{
                width: '40%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'solid',
                borderWidth: '0.5px',
                borderColor: 'rgb(52,131,129)',
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: 'pointer',
              }}
            >
              <p style={{ margin: 0, color: 'rgb(52,131,129)' }}>
                Submit Trade
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getAllAccountInfo = () => {
    fetch(`${ENDPOINT}/mobile/user/holdings`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      //   .then(responseJson => this.setState({holdings: responseJson}))
      .then((responseJson) => {
        let allAccounts = []
        //   console.log('neten all resp: ',responseJson)
        responseJson.items.forEach((inst) =>
          inst.accounts.forEach((acc) => {
            //   console.log(acc.name,acc.type )
            if (
              acc.type === 'depository' ||
              acc.type === 'investment' ||
              acc.type === 'credit'
            ) {
              allAccounts.push({ ...acc, inst: inst.instName })
            }
          })
        )
        //   console.log('neten all parsed: ',allAccounts)
        this.setState({ allAccounts, itemsResp: responseJson.items })
      })
      .catch((err) => console.log(`catching errors`, err))
  }

  chooseAcc = () => {
    if (this.state.titleText !== 'Make a Trade') {
      this.setState({ titleText: 'Make a Trade' })
    }
    let mapped = []
    let accounts
    if (this.state.itemsResp === 'loading') {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Loading... </h2>
          </div>
        </div>
      )
    }
    if (this.state.itemsResp.length > 0) {
      // accounts = this.combineAccountsAndHoldings()
      accounts = this.state.allAccounts
      console.log('list of accounts: ' + JSON.stringify(accounts))
      accounts.forEach((account, index) => {
        // console.log('account: ', account)
        if (
          account.inst === 'DriveWealth' &&
          !account.product &&
          !account.ria
        ) {
          mapped.push(
            <div
              id={'TradeModal_Account_' + index}
              style={{
                padding: 25,
                borderRadius: 8,
                margin: 10,
                backgroundColor:
                  this.state.hover === account.itemId
                    ? '#F8F9FA'
                    : 'rgb(248,249,250)',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (
                  account.status === 'OPEN' ||
                  account.status === 'OPEN_NO_NEW_TRADES'
                ) {
                  if (account.balances[0].current === 0) {
                    this.setState({
                      errors: 'Please deposit funds to continue',
                    })
                  } else {
                    this.owned(account, this.state.selected.symbol)
                    this.setState({ acc: account })
                  }
                } else {
                  this.setState({ errors: 'Account pending approval' })
                }
              }}
            >
              <div
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <div
                  style={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ margin: 0 }}>{account.inst}</p>
                </div>
                <div
                  style={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ margin: 0 }}>{account.mask_full}</p>
                </div>
                {/* {this.props.isSmallScreen?null: 
                                <div style={{width:'10%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                                    <p style={{margin:0}}>{inner.mask}</p>
                                </div>} */}
                <div
                  style={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {account.status === 'OPEN' ||
                  account.status === 'OPEN_NO_NEW_TRADES' ? (
                    <p style={{ margin: 0 }}>
                      <strong style={{ fontSize: 20 }}>
                        $
                        {account.balances[0].current
                          ? this.addCommas(
                              account.balances[0].current.toFixed(2)
                            )
                          : '0.00'}
                      </strong>
                    </p>
                  ) : (
                    <p style={{ margin: 0 }}>
                      <strong style={{ fontSize: 20 }}>
                        {account.status.charAt(0) +
                          account.status.slice(1).toLowerCase()}
                      </strong>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        }
      })
    }
    let windowheight = window.innerHeight * 0.7
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '90%',
            margin: 'auto',
            marginBottom: 10,
            marginTop: 25,
          }}
        >
          {mapped.length > 0 ? (
            <h4 style={{ color: 'rgb(17,17,17)' }}>Choose account</h4>
          ) : (
            <a href='/CreateDw' target='_blank'>
              <h5
                style={{
                  color: 'rgb(52,131,129)',
                  textDecoration: 'underline',
                }}
              >
                You do not have any Self Directed DriveWealth Accounts linked.
                Click here to open a new DriveWealth Account.
              </h5>
            </a>
          )}
        </div>
        {/* <div style={{width:'90%', margin:'auto',marginBottom:25, }}>
                    <p>Investing is currently only avaiable through DriveWealth Accounts. If you do not have one you can open one <NavLink to="/Dashboard">here</NavLink>.</p>
                </div> */}
        {mapped.length > 0 ? (
          <div style={{ width: '95%', margin: 'auto' }}>
            <div
              style={{
                borderRadius: 12,
                overflowY: 'scroll',
                height: '80%',
                maxHeight: windowheight,
              }}
            >
              {mapped}
            </div>
          </div>
        ) : null}
        {mapped.length > 0 ? (
          <div
            style={{
              width: '95%',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 12,
              marginTop: 10,
            }}
          >
            <a href='/CreateDw' target='_blank'>
              <p
                style={{
                  textAlign: 'center',
                  margin: 0,
                  color: 'rgb(52,131,129)',
                }}
              >
                Open another DriveWealth Brokerage Account
              </p>
            </a>
          </div>
        ) : null}
        <div style={{ paddingTop: '5%' }}>{this.displayErrors()}</div>
      </div>
    )
  }

  commisions = (total, quantity) => {
    if (this.state.buySell === 'sell') {
      let sec = (total * 5.1) / 1000000
      let taf = quantity * 0.000119
      if (taf > 5.95) {
        taf = 5.95
      }
      console.log('sec', sec)
      console.log('taf', taf)
      console.log('total', sec + taf)
      return sec + taf
    } else {
      return 0
    }
  }

  total = (quantity) => {
    return this.state.selected.live.price * quantity
  }

  tradeStockDW = () => {
    /// HERE
    this.setState({ orderPlaced: 'Placing Order...' })
    let body = {
      dwAccountID: this.state.acc.accountId,
      orderType: this.state.priceType,
      symbol: this.state.selected.live.symbol,
      side: this.state.buySell.toUpperCase(),
    }
    if (this.state.lastChanged === 'quantity') {
      body['quantity'] = this.state.quantity
    } else {
      body['amount'] = this.state.price
    }
    if (this.state.priceType === 'LIMIT') {
      body['orderPrice'] = parseFloat(this.state.limitPrice)
    }
    console.log('SubmittingTrade')
    console.log(body)
    fetch(`${ENDPOINT}/mobile/dw/trades/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .catch((err) => {
        console.log('trade submission errors: ', err)
        this.setState({
          errors: 'Something went wrong, please try again later',
        })
      })
      .then((responseJson) => {
        console.log('resp:', responseJson)
        if ('success' in responseJson && responseJson.success) {
          this.setState({
            orderPlaced: responseJson.payload.message,
            orderId: responseJson.payload.orderID,
          })
          this.startTradeStatusTimer()
        } else {
          this.setState({ orderPlaced: responseJson.payload })
        }
      })
  }

  utcToLocal(timeAndDate) {
    if (timeAndDate === 'Market is closed today') {
      return ''
    }
    // console.log('mkt open',timeAndDate )
    let date = new Date(timeAndDate).toString().split(' ')
    if (date && date.length > 3) {
      let hour = date[4].substring(0, 2)
      let min = date[4].substring(3, 5)
      let day =
        date[1] + ' ' + date[2].replace(/^0(?:0:0?)?/, '') + ', ' + date[3]
      let amPm = 'AM'
      if (hour > 12) {
        hour = hour - 12
        amPm = 'PM'
      } else if (hour === '00') {
        hour = '12'
      }
      let time = `${hour}:${min} ${amPm}`
      return `The market will open at ${time.replace(
        /^0(?:0:0?)?/,
        ''
      )} on ${day}`
    }
  }

  etcToLocal(dateInput) {
    // EST - UTC offset: 5 hours
    var offset = 5.0,
      /*
              - calculate the difference between the server date and UTC
              - the value returned by the getTime method is the number of milliseconds since 1 January 1970 00:00:00 UTC.
              - the time-zone offset is the difference, in minutes, between UTC and local time
              - 60000 milliseconds = 60 seconds = 1 minute
            */
      serverDate = new Date(dateInput),
      utc = serverDate.getTime() - serverDate.getTimezoneOffset() * 60000,
      /*
              - apply the offset between UTC and EST (5 hours)
              - 3600000 milliseconds = 3600 seconds = 60 minutes = 1 hour
            */
      clientDate = new Date(utc + 3600000 * offset)

    return clientDate.toLocaleString()
  }
  capitalizeFirstLetter(string) {
    let x = string.toLowerCase()
    return x.charAt(0).toUpperCase() + x.slice(1)
  }

  render() {
    // console.log('all accounts',this.state.allAccounts)
    // console.log('all accounts filtered',this.state.allAccounts.filter(acc => acc.inst === "DriveWealth" && !acc.product))
    if (this.props.canTrade.status) {
      //abcd
      // if(true){
      // let titleText = this.state.summary? 'Review Trade':'Make a Trade'
      let titleText = ''
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '95%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {this.state.titleText}
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.props.closeTrade()}
              >
                {' '}
                X{' '}
              </p>
            </div>
            {this.state.acc === 'NO' ? (
              <div>{this.chooseAcc()}</div>
            ) : this.state.selected === false ? (
              <div>{this.selectStock()}</div>
            ) : this.state.summary ? (
              <div
                style={{
                  width: '95%',
                  height: '100%',
                  margin: 'auto',
                  overflowY: 'scroll',
                }}
              >
                {this.summary()}
              </div>
            ) : (
              <div
                style={{
                  width: '95%',
                  height: '100%',
                  margin: 'auto',
                  overflowY: 'scroll',
                }}
              >
                {this.setTrade()}
              </div>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '40%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                Market Closed
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.props.closeTrade()}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {this.state.allAccounts.filter(
                (acc) => acc.inst === 'DriveWealth' && !acc.product
              ).length < 1 && !this.state.acc ? (
                <div
                  style={{
                    width: '90%',
                    margin: 'auto',
                    marginBottom: 25,
                    marginTop: 25,
                  }}
                >
                  <a href='/CreateDw' target='_blank'>
                    <h5 style={{ color: 'rgb(52,131,129)' }}>
                      You do not have any Self Directed DriveWealth Accounts
                      linked. Click here to open a new DriveWealth Account.
                    </h5>
                  </a>
                </div>
              ) : null}
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                  marginBottom: 25,
                  marginTop: 25,
                }}
              >
                <h4 style={{ color: 'rgb(17,17,17)' }}>
                  The market is currently closed, please try again during
                  trading hours.
                </h4>
              </div>
              <div
                style={{
                  width: '90%',
                  margin: 'auto',
                  marginBottom: 25,
                  marginTop: 25,
                }}
              >
                <h4 style={{ color: 'rgb(17,17,17)' }}>
                  {this.utcToLocal(this.props.canTrade.market_open)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
