export const TRANSLATIONS_PT = {
  addnew: 'Add New',
  assets: 'Assets',
  company: 'Companhia',
  name: 'Nome',
  current: 'Atual',
  price: 'Preço',
  trend: 'Tendência',
  editlist: 'Edit List',
  mywatchlist: 'My Watchlist',
  pleaselogintoseeyourwatchlist:
    'Faça login para ver sua lista de acompanhamento',
  priceforecast: 'Previsão de Preços*',
  setupyourwatchlistbyaddingstocks: 'Set up your Watchlist by adding stocks!',
  stopedit: 'Stop Edit',
  stocks: 'Ações',
  options: 'Opções',
  symbol: 'Símbolo',
  unusualactivity: 'Atividade incomum',
  welcome: 'Welcome',
  watchlistTab: 'Lista de acompanhamento',
  forecast: 'Previsão',
  months: 'Meses',
  month: 'Mês',
  changeinprice: 'Mudança de Preço',
  volumechange: 'Variação de volume',
  volume: 'Volume',
  removestock: 'Retirar',
  fromwatchlist: 'Da Lista de acompanhamento',
  remove: 'Retirar',
  stocksearch: 'Pesquisa de ações',
  searchforanystock:
    'Procurar qualquer ação pelo seu nome ou símbolo da empresa',
  clickonanyresult:
    'Clicar em qualquer resultado para adicioná-lo à sua lista de acompanhamento',
  enterstocksymbol: 'Digitar ações por símbolo ou nome da empresa',
  searchabove: 'Pesquisar acima para exibir os resultados',
  noresultsfound: 'Nenhum resultado foi encontrado tente um ticker diferente',
  dashboard: 'Painel de Controle',
  watchlist: 'Lista de acompanhamento',
  accounts: 'Contas',
  trade: 'Negociação',
  portfolioamount: 'Valor da carteira',
  investments: 'Investimentos',
  netchange: 'Net Change',
  portfolioallocations: 'Alocações de carteira',
  cash: 'Disponibilidade',
  crypto: 'Cripto',
  equity: 'Títulos patrimoniais',
  fixedincome: 'Renda Fixa',
  other: 'Outra',
  myinvestments: 'Meus Investimentos',
  shares: 'Ações',
  costbasis: 'Base de custos',
  marketvalue: 'Valor de mercado',
  pl: 'L/P',
  showless: 'Show Less',
  showmore: 'Show More',
  investingthemes: 'Investing Themes',
  top30: 'Top 30',
  etfs: 'ETFs',
  ev: 'VE',
  energy: 'Energia',
  finance: 'Finanças',
  semis: 'Semicondutores',
  tech: 'Tecnologia',
  language: 'Idioma',
  allocation: 'Alocação',
  search: 'Procurar',
  unusual: 'Incomum',
  stock: 'Ações',
  robo: 'Robô',
  goal: 'Meta',
  aistock: 'AI Stock',
  aicrypto: 'AI Crypto',
  oneday: '1D',
  onemonth: '1M',
  ytd: 'YTD',
  oneyear: '1A',
  year: 'ano',
  years: 'anos',
  all: 'TUDO',
  transferfunds: 'Transferir Fundos',
  accountactivity: 'Atividade da conta',
  documents: 'Documentos',
  equityvalue: 'Valor patrimonial',
  netpl: 'L/P líquido',
  pendingtransfers: 'Transferências Pendentes',
  pendingorders: 'Ordens pendentes',
  recurringbuys: 'Compra Recorrente',
  nextbuy: 'Próxima Compra',
  amount: 'Quantidade',
  stop: 'Parar',
  edit: 'Editar',
  summary: 'Resumo',
  gainloss: 'Ganho/Perda',
  availableshares: 'Ações disponíveis',
  buyingpower: 'Poder de compra',
  marketorder: 'Ordem de Mercado',
  limitorder: 'Ordem limite',
  ordertype: 'Tipo de pedido',
  approxamount: 'Aproximadamente.',
  youcannotsellmorethanyouown: 'Você não pode vender mais do que possui.',
  buy: 'Comprar',
  estimatedquantity: 'Quantidade Estimada',
  estimatedcommission: 'Comissão Estimada',
  estimatedtotal: 'Estimated Total',
  submittrade: 'Submit Trade',
  from: 'De',
  to: 'Para',
  depositamount: 'Valor do depósito',
  withdrawalamount: 'Valor do Saque',
  wiredeposit: 'Depósito de transferência',
  wirewithdraw: 'Saque de transferência',
  youcanonlydeposit5000perday:
    'Você só pode depositar US$ 5.000 por dia e fazer um total de 5 depósitos por dia em todas as suas Contas de Negociação.',
  submit: 'Enviar',
  tradeconfirmation: 'Confirmação da negociação',
  accountstatements: 'Extratos de contas',
  taxdocuments: 'Documentos Fiscais',
  pleaseenteramountgreaterthan0: 'Por favor, digitar valor superior a 0',
  notenoughbuyingpower: 'Não há poder de compra suficiente',
  pleaseentersharesgreaterthan0:
    'Por favor, digitar número de ações superior a 0',
  onlymarketordersaresupportedwithfractionalshares:
    'Apenas as ordens de mercado são suportadas com ações fracionadas',
  selectanaccount: 'Selecionar uma conta',
  quantity: 'Quantidade',
  filledprice: 'Preço atendido',
  cannotenterfractionalsharesforlimitorder:
    'Não é possível digitar ações fracionárias para ordem limite',
  limitordersaregooduntilnextmarketclose:
    'As ordens  limite são válidas até o próximo final do pregão',
  somethingwentwrong: 'Algo deu errado. Por favor, tente novamente mais tarde.',
  areyousureyouwanttocancelthisorder:
    'Tem certeza que quer cancelar esta ordem?',
  confirmation: 'Confirmação',
  notsupportedfortrading: 'Não suportado para negociação',
  approxnumberofshares: 'Número aprox. de ações',
  approxtotalamount: 'Aproximadamente. Valor total',
  amountcannotbegreaterthan5000: 'O valor não pode ser superior a 5000',
  transfersarenotavailableatthistime:
    'Transferências não estão disponíveis no momento',
  transfersareavailabefrom430to130:
    'Transferências estão disponíveis das 4h30 às 13h30 (horário de Brasília).',
  cannottransfermorethanyouown: 'Você não pode transferir mais do que possui',
  cannotwithdrawmorethanyouown: 'Você não pode sacar mais do que possui',
  allfieldsarerequired: 'Todos os campos são necessários',
  transactionhasbeensuccessfullycompleted:
    'A transação foi concluída com sucesso',
  wiredepositinstructions: 'Instruções de depósito de Transferência',
  wiredepositinstructions1:
    'Por favor, inclua este número de conta na descrição do memorando eo fazer a transferência bancária.',
  wiredepositinstructions2:
    '*Nota: Algumas transferências internacionais exigem um número IBAN, mas as contas bancárias nos EUA não têm um IBAN. Ainda assim você precisa fornecer o Número de Conta do Beneficiário do DriveWealthbs, juntamente com o Código SWIFT, para iniciar uma transferência bancária internacional.',
  wiredepositinstructions3:
    'Observe que todos os fundos mantidos nesta conta são segurados pelo SIPC (governo dos EUA) até US$ 500.000',
  wiredepositinstructions4:
    'A conta bancária deve ter a mesma titularidade da sua conta Investall. Todas transferências de terceiros serão rejeitadas.',
  wiredepositinstructions5:
    'O nome do beneficiário deve ter a mesma titularidade da conta da Investall. Todas transferências de terceiros serão rejeitadas',
  remittanceinformation: 'Informações de remessa',
  usdollaramount: 'Valor em dólar americano',
  recipientbankname: 'Nome do banco do destinatário',
  bankaddress: 'Endereço do banco',
  abanumber: 'Número de ABA',
  swiftcode: 'Código Swift',
  bankaccountnumber: 'Número da conta bancária',
  forfinalcreditto: 'Crédito final para (FFC)',
  selectaccounttoview: 'Selecionar Conta para Exibir',
  wirewithdrawal: 'Saque de Transferência',
  fromaccount: 'Da Conta',
  beneficiaryname: 'Nome do Beneficiário',
  fullname: 'Nome completo',
  beneficiaryaccountnumber: 'Número da conta do beneficiário',
  accountnumber: 'Número da Conta',
  beneficiaryaccounttype: 'Tipo de conta do beneficiário',
  accounttype: 'Tipo de conta',
  beneficiaryswiftaba: 'Beneficiário Swift/ABA',
  swiftcodeoraba: 'Código de Swift ou ABA',
  beneficiarybankname: 'Nome do Banco do Beneficiário',
  beneficiarybankaddress: 'Endereço do Banco do Beneficiário',
  beneficiarybankcity: 'Cidade do Banco do Beneficiário',
  bankcity: 'Cidade do Banco',
  beneficiarybankprovince: 'Estado do Banco do beneficiário',
  bankprovincestate: 'Província/Estado do Banco',
  beneficiarybankzipcode: 'Cep do Beneficiário',
  bankzipcode: 'Cep do Banco',
  beneficiarybankcountry: 'País do Banco do Beneficiário',
  bankcountry: 'País do Banco',
  bankname: 'Nome do banco',
  amounttowithdraw: 'Valor para sacar',
  notes: 'Observações',
  enternoteshere: 'Digitar observações aqui',
  withdraw: 'Saque',
  sell: 'Vender',
  stockaccount: 'Ações',
  cryptoaccount: 'Cripto',
  'ai stock traderaccount': 'Negociador de ações IA',
  'ai crypto traderaccount': 'Negociador de criptos IA',
  roboaccount: 'Robô',
  goalsaccount: 'META',
  goalaccount: 'META',
  dollars: 'Dólares',
  estimatedcost: 'Custo Estimado',
  revieworder: 'Revisar Ordem',
  ordersuccessful: 'ordem bem sucedida',
  numberofshares: 'Número de ações',
  sellall: 'Vender tudo',
  limitprice: 'Preço limite',
  chart: 'Gráfico',
  analysis: 'Análise',
  stats: 'Estatísticas',
  oneweek: '1S',
  open: 'Abrir',
  high: 'Alto',
  low: 'Baixo',
  portfoliodetails: 'DETALHES DA CARTEIRA',
  accountvalue: 'Saldo da conta',
  products: 'Produtos',
  products2: 'Produtos',
  market: 'Mercado',
  limit: 'Limite',
  investin: 'Investir em',
  pleaseenterlimitpricegreaterthan0:
    'Por favor, digitar preço limite superior a 0',
  deposit: 'Depósito',
  orderhistory: 'Histórico de Pedido',
  transferhistory: 'Histórico de transferências',
  disclaimer: 'Disclaimer',
  disclaimerdescription:
    'All forecasts and trends are generated by our AI forecasting model. They are not intended to be advice or recomendation to buy or sell any stocks. The results do not represent actual trading and actual results may significantly differ from the theoretical results presented. Past performance is not indicative of future performance.',
  currenttrend: 'Tendência atual',
  selectyouraccounttodeposit: 'Selecionar sua conta para depositar',
  amountmustbeatleast5dollars: 'O valor deve ser  pelo menos 5 dólares',
  amountmustbeatleast1dollar1: 'O valor deve ser  pelo menos 1 dólares',
  pleaseopenanaccounttotrade: 'Por favor, abra uma conta para negociar',
  gfv1: 'Se você efetuar uma venda antes que os fundos sejam compensados isso pode causar uma Violação de Boa Fé. Depois de três Violações de Boa Fé dentro de um período de 12 meses, você não poderá negociar ações com fundos não compensados por 90 dias',
  gfv2: 'Se você efetuar uma vende antes que os fundos sejam compensados isto pode ser sua terceira Violação de Boa Fé dentro de um período de 12 meses . Isso significa que você não poderá negociar ações com fundos fundos não compensados por 90 dias',
  thismarketorderwillexecuteaftermarkethours:
    'Esta ordem de mercado será executada após as 9:30 EST',
  goodfaithviolationalert: 'Alerta de Violação de Boa Fé',
  youareallcaughtup: 'Você está atualizado',
  transfer: 'Transferência',
  bearish: 'Em Baixa',
  bullish: 'Em Alta',
  percentchange: '%Mudança',
  riskforecast: 'Previsão de Risco',
  historicaldrawdown: 'Retração Histórica',
  risk: 'Risco',
  maxdrawdown: 'Retração máximA',
  historicalstresstest: 'Teste de histórico de estresse',
  neutral: 'Neutro',
  marketcap: 'Capitalização de Mercado',
  peratio: 'Relação preço/rendimento',
  dailyvolume: 'Volume Diário',
  volume10davg: 'Volume Médio de 10D',
  dividendamount: 'Valor de Dividendos',
  dividendyield: 'Rendimento de Dividendos',
  sharesoutstanding: 'Ações em Circulação',
  shortinterest: 'Juros a descoberto',
  personalinformation: 'Informações Pessoais',
  linkedaccounts: 'Contas Vinculadas',
  manage: 'Gerenciar',
  faqs: 'Perguntas frequentes',
  support: 'Assistência',
  email: 'Email',
  phone: 'Telefone',
  accountinformation: 'Informações da conta',
  signout: 'Sair',
  banking: 'Bancário',
  investment: 'Investimento',
  linkabankaccount: 'Vincular uma conta bancária',
  linkainvestmentaccount: 'Vincular uma conta de investimento',
  subscriptionmanage: 'Gerenciamento de assinaturas',
  investmentquestionnaireupdate: 'Questionário de investimento-ATUALIZAÇÃO',
  changepassword: 'Alterar senha',
  oldpassword: 'Senha antiga',
  password: 'Senha',
  newpassword: 'Nova senha',
  confirmnewpassword: 'Confirmar nova senha',
  activaterobo: 'Ativar o  robô',
  'activateai crypto trader': 'Ativar o Negociador de Cripto IA',
  'activateai stock trader': 'Ativar o Negociador IA de ações',
  continueautomatedtradingby:
    'Continue a negociação automatizada ao ativar sua conta',
  activate: 'Ativar',
  investallrobo: 'Investall Robo',
  investmentamount: 'Valor do Investimento',
  periodiccontribution: 'Contribuição Periódica',
  strategyusedbyrobo: 'Strategy used by Robo',
  roboisnotactiveyet: 'Robo is not active yet',
  startrobonowtoinvest:
    "Start Robo now to invest based on Investall's automated strategy tailored to your profile",
  monthtodate: 'Início do mês até a data',
  yeartodate: 'Ano a ano',
  expectedpotentialrisk: 'Risco potencial esperado (previsão de 30 dias)',
  viewallocations: 'Exibir alocações',
  gipscompliant: 'Compatível com GIPS',
  investmentcalculator: 'Calculadora de Investimentos',
  values: 'Valores',
  in10years: 'em 10 anos',
  growth: 'Crescimento',
  aminimumof500isrequired:
    'Um mínimo de US$ 500 é necessário para iniciar o Robô da Investall. O valor total desta conta será utilizado pelo Robô da Investall.',
  initialinvestment: 'Investimento Inicial',
  monthlycontribution: 'Contribuição Mensal',
  weeklyroundup: 'Roundup semanal',
  addcash: 'Adicionar dinheiro',
  additional: 'Adicional',
  donthaveanaccount: 'Não tem uma conta?',
  yoursubscription: 'Sua assinatura',
  status: 'Situação',
  autorenewson: 'Renovação automática',
  downgradetobasic: 'Rebaixar para Básico',
  boosttopro: 'Melhorar para Pro',
  includesallfeaturesofinvestallbasicandmore:
    'Inclui todas as características da Investall e Mais!',
  freetrial: '30 dias de teste gratuito',
  afterward: '/$3,99 posteriormente',
  start1monthsfreetrial: 'Iniciar teste gratuito de 1 mês',
  cancelanytime: 'Cancelar a qualquer hora',
  investingmaderight: 'Investir corretamente',
  investinoursignaturerobo: 'Invest in our Signature Robo-Strategies',
  convenience: 'Conveniência',
  letouraidaytradeforyou:
    'Deixe nossa IA de Day Trade negociar por você em um dos nossos Grupos de Negociação especializados',
  improveyourspending: 'Melhore suas despesas',
  letourbudgetingsoftware:
    'Deixe nosso software de orçamento ajudá-lo a entender seus gastos',
  peaceofmind: 'Paz de Espírito',
  prepareforyourfuture:
    'Prepare-se para o seu futuro usando nossas tecnologias de planejamento de metas',
  financialsecurity: 'Segurança Financeira',
  getthestatstobackup:
    'Obtenha estatísticas para fundamentar suas decisões usando ferramentas de análise de risco',
  trialmessage:
    'O período de teste começa imediatamente após se inscrever. Você será cobrado somente após 1 mês',
  trialmessage2: 'Alguns produtos exigem um mínimo de US$ 500 para ativar',
  whatisthemainreasonforyourinvestment:
    'Qual é o principal motivo do seu investimento?',
  shortterm: 'Curto Prazo',
  mediumterm: 'Médio Prazo',
  longterm: 'Longo Prazo',
  howdoyouwishtoinvest: 'Durante quanto tempo deseja investir?',
  whatisyourrisktolerance: 'Qual é a sua tolerância a risco?',
  minimizelosses: 'Minimizar perdas',
  gainswhilelimitinglosses: 'Ganhos enquanto limita as perdas',
  maximizegains: 'Maximizar ganhos',
  whatisyourinvestmentexperience: 'Qual é a sua experiência em investimentos?',
  noexperience: 'Sem experiência',
  someexperience: 'Alguma experiência',
  considerableexperience: 'Experiência considerável',
  whatisyourinvestmentobjective: 'Qual é a sua meta de investimento?',
  longtermbuyhold: 'Compra e manutenção de longo prazo',
  tradinginfrequently: 'Negocia com pouca frequência',
  frequenttrader: 'Negociador Frequente',
  activedailytrader: 'Negociador diário ativo',
  newtoinvesting: 'Novo investidor',
  investmentperiod: 'Período de Investimento',
  pleasecompleteallfields: 'Por favor preencha todos os campos',
  questionnaireupdatedsuccessfully: 'Questionário atualizado com sucesso',
  investmentquestionnaire: 'Questionário de Investimento',
  cannotactivateaccountduringnontradinghours:
    'Não é possível ativar a conta fora do pregão',
  pleaseselectoneofthevaluesabove: 'Selecionar um dos valores acima',
  pleasedonotleavethispage: 'Por favor, não saia desta página',
  pleasechooseavaluegreaterthan0orroundupoption:
    'Por favor, escolha um valor superior a 0 ou opção round up',
  roundup: 'Round Up',
  areyousureyouwanttodelete: 'Você tem certeza que deseja excluir',
  recurringbuy: 'Compra Recorrente',
  daily: 'Diário',
  weekly: 'Semanalmente',
  biweekly: 'Quinzenalmente',
  monthly: 'Mensal',
  youhavebeensuccessfullyunsubscribedtoinvetallpro:
    'Sua assinatura da Investall Pro foi removida  com sucesso',
  youhavebeensuccessfullysubscribedtoinvestallpro:
    'Você assinou Investall Pro com sucesso',
  successfullysubscribed: 'Assinou com sucesso',
  thisaccountneedstoberelinked: 'Esta conta precisa ser revinculada',
  areyousureyouwanttoremovethisaccount:
    'Você tem certeza que deseja remover esta conta?',
  areyousureyouwanttoproceed: 'Tem certeza que deseja prosseguir?',
  youhavebeenloggedout: 'Você fez logout. Clique aqui para fazer login',
  pleasechooseabankaccount: 'Por favor, escolha uma conta bancária',
  selectfrequency: 'Selecionar a frequência',
  changesmadesuccessfully: 'Mudanças feitas com sucesso',
  successfullyunsubscribed: 'Retirada de assinatura com sucesso',
  availabletowithdraw: 'Disponível para saque',
  business: 'Negócio',
  signup: 'Inscrição',
  login: 'Iniciar sessão',
  landingpagetitle: 'Investimento inteligente alimentado por IA',
  landingpageinfo:
    'Invista em ações fracionárias e criptomoedas com apenas US $ 1 e Comissão Zero',
  investallforecast: 'PREVISÃO DA INVESTALL',
  threemopriceforecast: 'Previsão de preços de 3 meses',
  currentprice: 'Preço atual',
  findouttheshortterm:
    'Descubra as previsões de curto e longo prazo para suas moedas favoritas.',
  gettheapp: 'Obtenha o Aplicativo',
  features: 'Características',
  automatedinvestingmadeeasy: 'Investimento automatizado facilitado',
  'Invest Robo': 'Investall Robo',
  'AI Trader': 'Negociador IA',
  Goals: 'Metas',
  Budgeting: 'Orçamento',
  budgeting: 'Orçamento',
  learnmore: 'Saiba Mais',
  roboinfo:
    'Our AI provides proactive risk monitoring and disciplined execution of investment decisions. Keeping you informed about your portfolio every step of the way.',
  aitraderinfo:
    'Melhore seu desempenho e economize tempo ativando o Negociador IA.  Este sistema automatizado depende da previsão de tendência de preços e sinais de IA para executar negociações no melhor momento.',
  goalsinfo:
    'Economizar para o futuro é interessante, e podemos simplificar também. Seja fundos para faculdade, uma viagem divertida ou a compra de uma casa, as metas podem ajudá-lo a chegar lá com contribuições periódicas e estratégias especializadas',
  budgetinginfo:
    'Agregue todas as suas contas financeiras em um só lugar. Quanto mais contas vincular, mais nossa IA pode ajudá-lo a avaliar seus riscos, hábitos de gastos e muito mais.',
  home: 'Página inicial',
  about: 'Quem somos',
  contact: 'Contato',
  careers: 'Carreiras',
  legal: 'Legal',
  privacypolicy: 'Política de privacidade',
  termsofuse: 'Termos de Uso',
  eula: 'Contrato de licença de usuário final',
  generaldisclaimer: 'Isenção geral',
  disclosures: 'Divulgações',
  createanaccount: 'Criar uma conta',
  phonenumber: 'Número de telefone',
  forgotyourpassword: 'Esqueceu sua senha.',
  pleaseagreetoprivacypolicyandeula:
    'Por favor, concorde com a Política de Privacidade e a EULA',
  pleaseenterpassword: 'Digite a senha',
  pleaseentervalidemail: 'Por favor, insira um e-mail válido',
  chooseaverificationmethod: 'Escolha um método de verificação',
  alreadyreceivedcode: 'Já recebeu o código?',
  entercode: 'Digite o código',
  verificationcodesentmessage:
    'Código de verificação enviado. Por favor, digite o código para começar',
  didntgetthecode: 'Não conseguiu o código?',
  resendcode: 'Re-enviar o código',
  failedtoresentcode: 'Falha ao re-enviar código',
  invalidcode: 'Código inválido',
  pleaseenteryouremaildownbelow: 'Por favor, insira seu e-mail abaixo',
  confirm: 'Confirmar',
  creataccount: 'Criar conta',
  iagreetothe: 'Concordo com',
  invalidphonenumber: 'Número de telefone inválido',
  invalidpassword: 'Senha inválida',
  codehasbeensent: 'O código foi enviado.',
  getstarted: 'Começar',
  seeproducts: 'Ver Produtos',
  cryptotabtitle: 'Automatize sua criptomoeda com o Negociador IA.',
  cryptotabinfo:
    'Explore as principais criptomoedas desde Bitcoin a Ethereum e outras. Use nossa ferramenta de negociação de IA premium para proteger seu investimento. Compre, venda e negocie agora baixando nosso aplicativo.',
  cryptocurrencyforecast: 'PREVISÃO DE CRIPTOMOEDAS',
  investing: 'INVESTIR',
  institutionallevelroboinvesting: 'Institutional level ROBO Investing',
  producttabinfo:
    'Obtenha nossas estratégias de negociação mais avançadas, com o histórico auditado do GIPS de superar outros principais conselheiros robotizados.',
  dynamicassetmanagement: 'Gestão dinâmica de ativos',
  startrobo: 'Inicializar o Robô',
  investrobo: 'INVEST ROBO',
  whypickstockswhenyoucanletouraiinvestforyou:
    'Por que escolher ações quando você pode deixar nossa IA investir por você',
  trustedbythebigbanks: 'Confiado por grandes bancos',
  bigbanksinfo:
    'Histórico de desempenho comprovado, que supera os outros principais consultores robotizados, agora disponível para as massas',
  tailoredtoyourchoices: 'Adaptado às suas opções',
  tailoredinfo:
    'Preencha um questionário de investimento e nossa IA escolhe a melhor estratégia para sua conta',
  highlyliquid: 'Altamente Líquido',
  highlyinfo:
    'Mude de ideia com facilidade, pegue seu dinheiro quando precisar.Reequilíbrio mensal com base no mercado atual',
  aitrader: 'NEGOCIADOR IA',
  aitraderheader:
    'Selecione até quatro ações ou quatro criptomoedas para o nosso Negociador IA negociar',
  aitraderinfo1:
    'Todas as negociações diárias ocorrem com base nas mudanças de tendência de preços detectadas pela nossa IA.',
  aitraderinfo2:
    'Aproveite as estratégias de negociação automatizadas alimentadas por IA,  como fazem os fundos de hedge',
  aitraderinfo3:
    'As negociações são automaticamente colocadas com base em mudanças de tendência - uma combinação de múltiplos fatores',
  aitraderinfo4:
    'Nossa IA otimiza o tamanho das negociações para melhor reduzir o risco e maximizar os retornos',
  goals: 'Metas',
  goalsheader:
    'Defina suas metas. Visualize, alcance seu alvo. O céu é o limite',
  goalsinfo1: 'Economize para sua próxima hipoteca',
  goalsinfo2: 'Economize para uma meta de Férias de 25.000',
  goalsinfo3: 'Fundo de Emergência para saúde',
  goalsinfo4: 'Ou meta de longo prazo para a aposentadoria',
  budgetingheader: 'Planejamento financeiro num único painel centralizado',
  budgetinginfo1:
    'Adequado para qualquer meta de poupança de longo prazo - para se preparar para a aposentadoria, fundo para a faculdade, ou finalmente economizar para uma compra vultosa',
  budgetinginfo2:
    'Basta definir uma meta de poupança e estratégia de investimento para sua carteira e deixar a ForeSCITE orientar seus hábitos de investimento e gastos.',
  budgetinginfo3:
    'Configure contribuições mensais automatizadas para ajudá-lo a ficar no caminho certo',
  freeversion: 'Versão gratuita',
  freeversioninfo:
    'Comece imediatamente com nosso investimento auto-direcionado, Robô investidor e previsão de tendência de preço.',
  upgradetools: 'Ferramentas de upgrade',
  upgradetoolsinfo:
    'Atualize sua experiência de negociação com Orçamento, Metas, nosso Trader de Grupo Alimentado por IA.',
  enteryourpasswordbelow: 'Digite sua senha abaixo',
  yourpasswordhasbeenreset: 'Sua senha foi redefinida',
  incorrectpassword: 'Senha incorreta',
  advisoryagreement: 'Acordo de consultoria',
  enteryournewpasswordbelow: 'Digite sua nova senha abaixo',
  pleaseenterthecodetoverifythisisyouremail:
    'Por favor, digite o código para verificar se este é o seu e-mail',
  usernamenotfound: 'Nome de usuário não encontrado',
  userisnotconfirmed:
    'O usuário não está confirmado. Digite o código de confirmação enviado anteriormente ou solicite um novo código',
  wesentaverificationcodeto: 'Enviamos um código de verificação para',
  back: 'Voltar',
  technicalanalysis: 'Análise Técnica',
  realtiveindexstrength: 'Índice de Força Relativa',
  nineday: '9 dias',
  expmovingaverage: 'Média móvel exponencial',
  threeday: '3 dias',
  tenday: '10 dias',
  chaikinmoneyflow: 'Fluxo de Dinheiro Chaikin (FDC)',
  twentyoneday: '21 dias',
  technicallevels: 'Níveis Técnicos',
  resistance: 'Resistência',
  moderate: 'Moderado',
  change: 'Mudar',
  roboinvestinginfo1:
    'Our AI will choose the right strategy for you that fits your profile',
  roboinvestinginfo2:
    'Our investment strategies are rebalanced periodically based on the current market conditions',
  roboinvestinginfo3:
    'Automatically fund your robo trader with periodic contributions or round up with the spare change from your investments',
  investingmadeeasy:
    'Facilitamos investimentos, compre ações fracionárias com apenas $1',
  investingmadeeasyinfo1:
    'Invista sem comissão em ações individuais ou pacotes de investimentos (ETFs)',
  investingmadeeasyinfo2:
    'Use nossas tendências de preços e previsões de preços de 30 dias para direcionar seus investimentos',
  investingmadeeasyinfo3:
    'Compre ações fracionárias de ações de alto custo ou qualquer ação que você gostaria de investir',
  setanalert: 'Definir um alerta',
  set: 'Definir',
  selectfromindicators: 'Selecionar indicadores',
  oneweeksupport: '1 Semana de Suporte',
  oneweekresistance: '1 Semana de Resistência',
  onemonthsupport: '1 mês de Suporte',
  onemonthresistance: '1 mês de Resistência',
  bollingersupport: 'Suporte de banda de Bollinger',
  bollingerresistance: 'Resistência de banda de Bollinger',
  selectfromcrossovers: 'Selecione crossovers',
  bullishcrossover: 'Crossover de alta',
  bearishcrossover: 'Crossover de baixa',
  alerts: 'Alertas',
  cancelalert: 'Cancelar alerta',
  areyousureyouwanttocancelthisalert: 'Tem certeza que quer cancelar o alerta?',
  createaccount: 'Criar conta',
  logintoyourinvestallaccount: 'Faça login na sua conta Investall',
  enteramount: 'Digite Valor',
  temporarypassword: 'Senha temporária',
  clickheretologin: 'Clique aqui para fazer login',
  passwordupdatedsuccessfully: 'Senha atualizada com sucesso',
  inprogress: 'Em andamento...',
  producthasbeensuccessfullyactivated: 'O produto foi ativado com sucesso',
  overview: 'Visão geral',
  active: 'Ativar',
  startdate: 'Data de início',
  contribution: 'Contribuição',
  currentvalue: 'Valor atual',
  rebalanceinformation: 'Informações de reequilíbrio',
  nextrebalance: 'Próximo Reequilíbrio',
  lastrebalancedate: 'Próxima data de reequilíbrio',
  daysuntilnextrebalance: 'Dias até o próximo Reequilíbrio',
  intramonth: 'Intramensal',
  clickheretodownload: 'Clique aqui para baixar',
  noinvestments: 'Sem Investimentos',
  noorderhistory: 'Sem histórico de ordens',
  notransferhistory: 'Sem histórico de transferência',
  notaxdocuments: 'Sem documentos fiscais',
  noaccountstatements: 'Sem extratos de conta',
  notradeconfirmations: 'Sem confirmações de negociações',
  deactivaterobo: 'Deactivate Robo',
  robohasbeensuccessfullydeactivated: 'Robo has been successfully de-activated',
  cannotdeactivateroboduringnontradinghours:
    'Cannot de-activate Robo during non-trading hours',
  periodiccontributionupdatedsuccessfully:
    'Contribuição Periódica atualizada com sucesso',
  thesparechangemessage1:
    'As sobras de suas compras diárias serão agregadas e investidas nesta conta diarieamente',
  thesparechangemessage2:
    'Este recurso agregará as sobras de todas as suas contas bancárias e de crédito vinculadas',
  clickhereforfrequentlyaskedquestions:
    'Clicar aqui para fazer perguntas frequentes',
  pleaseenteryouroldpassword: 'Por favor, digite sua senha antiga',
  pleaseconfirmyournewpassword: 'Por favor, confirme sua nova senha',
  yournewpasswordmustbeeightormorecharacters:
    'Sua nova senha deve ter oito ou mais caracteres',
  newpasswordandconfirmdoesnotmatch:
    'A nova senha e confirmação não correspondem',
  incorrectusernameorpassword: 'Nome de usuário incorreto para esta senha',
  youroldpasswordisincorrect: 'Sua senha antiga está incorreta',
  pleaseenteryournewpassword: 'Por favor, digite sua nova senha',
  passwordmustbegreaterthan8characters:
    'A senha deve ter pelo menos 8 caracteres',
  userverification: 'Verificação do usuário / KYC',
  pleaseclickheretoverifykyc:
    'Sua conta não foi aprovada. Clicar aqui para verificar kyc',
  kycverification: 'Verificação de KYC',
  pleaseremovepreviousimagetouploadanotherone:
    'Por favor, remova a imagem anterior para carregar outra',
  documenthasbeenuploadedforapproval: 'O documento foi enviado para aprovação',
  failedtouploaddocument: 'Falha no upload do documento',
  pleasewaituntilverificationiscomplete:
    'Sua conta está pendente. Aguarde até que a verificação seja concluída.',
  kycapproved: 'KYC APROVADO',
  selectdocumenttype: 'Selecione o tipo de documento',
  documenttype: 'Tipo de documento',
  uploadfrontside: 'Carregar o anverso',
  uploadbackside: 'Carregar o verso',
  thiswillcreatea: 'Isso vai criar um',
  accountforyou: 'para você',
  wouldyouliketoproceed: 'Gostaria de prosseguir?',
  getrobo: 'Obter Robô',
  createroboaccount: 'Create Robo Account',
  createastockaccount: 'Criar Conta de ações',
  setuprecurringbuy: 'Configurar compra recorrente',
  editrecurringbuy: 'Editar compra recorrente',
  recurringbuyupdatedsuccessfully: 'Compra recorrente atualizada com sucesso',
  achlinking: 'Vínculo ACH',
  realtimeverification: 'Verificação em tempo real',
  transferamount: 'Valor da transferência',
  highrisk: 'Alto Risco',
  lowrisk: 'Baixo Risco',
  moderaterisk: 'Risco Moderado',
  logintoyour: 'Faça login na sua',
  beneficiaryaddress: 'Endereço do Beneficiário',
  account: 'conta',
  usbankaccountsonly: 'Apenas contas bancárias dos EUA',
  recurringbuysetupsuccessfully: 'Compra recorrente configurada com sucesso',
  orderplacedsuccessfully: 'ordem bem sucedida',
  'ai crypto trader': 'Negociador de criptos IA',
  'ai stock trader': 'Negociador de ações IA',
  sessiontimeout: 'Tempo limite da sessão. Faça login novamente',
  return: 'Retornar',
  successfullydeposited: 'Depósito bem sucedido',
  accountbuyingpower: 'Poder de Compra da Conta',
  addstocks: 'Adicionar ações',
  startaitrader: 'Inicie o Negociador IA',
  pleaseselectatleast2stocks: 'Selecione pelo menos 2 ações',
  addastocktoaitrader: 'Adicione uma ação ao Negociador IA',
  searchbysymbol: 'Pesquisar por símbolo',
  isrequiredtomeetyourinitialinvestment:
    'é necessário para atender seu investimento inicial',
  deactivateaistocktrader: 'Desativar o Negociador de Ações IA',
  aistocktraderhasbeensuccessfullydeactivated:
    'Negociador de Ações IA foi desativado com sucesso',
  cannotdeactivateaistocktraderduringnontradinghours:
    'Não é possível desativar o Negociador de Ações IA  fora do pregão',
  removedsuccessfully: 'Removido com sucesso',
  accountremovedsuccessfully: 'Conta removida com sucesso',
  addcrypto: 'Adicionar cripto',
  cannotselectmorethan8cryptos:
    'Não é possível selecionar mais de 8 criptomoedas',
  marketsareonlyopenfrom:
    'Os mercados só ficam abertos das 9h30 (horário de Brasília) às 16h30 (horário de Brasília)',
  pleaseselectatleast2cryptos: 'Selecione pelo menos 2 criptomoedas',
  editexistingachlinking: 'Editar vinculação de ACH existente',
  deleteexistingachlinking: 'Excluir a vinculação de ACH existente',
  startaicryptotrader: 'Inicie o Negociador de Cripto IA',
  aicryptotraderhasbeensuccessfullyactivated:
    'O Negociador de Cripto IA foi ativado com sucesso',
  aistocktraderhasbeensuccessfullyactivated:
    'Negociador de Ações IA foi ativado com sucesso',
  chooseanoption: 'Escolha uma opção',
  productdetails: 'Detalhes do produto',
  signupsuccessful: 'Inscrição bem sucedida',
  emailaddress: 'Endereço  de e-mail',
  buystocks: 'Comprar Ações',
  signin: 'Entrar',
  cryptoisnotsupportedinyourarea:
    'A negociação de criptomoedas não está disponível para não residentes dos EUA',
  thiscryptoisnotsupporedfornewyorkresidents:
    'Esta criptomoeda não é suportada para residentes de Nova York',
  getstock: 'Obter Ação',
  getcrypto: 'Obter Cripto',
  createcryptoaccount: 'Criar Conta Cripto',
  unopenedrobodetails:
    'Answer a few questions and we will pick one of our strategies that best suits your investment goals and risk level. All of our strategies are highly liquid and rebalanced monthly to keep you positioned to minimize losses and maximize gains.',
  unopenedaistockdetails:
    'Selecione até quatro ações para nossa IA negociar em seu nome. Todas as negociações ocorrem com base em mudanças de preço detectadas por nossa IA.',
  getaistocktrader: 'Obter Negociador de Ações IA',
  createaistocktraderaccount: 'Crie uma conta de negociador de ações de IA',
  unopenedaicryptodetails:
    'Selecione até quatro criptomoedas para que nossa IA negocie em seu nome. Todas as negociações ocorrem com base nas tendências de preço detectadas por nossa IA.',
  getaicryptotrader: 'Obter Negociador de Cripto IA',
  unopenedgoalsdetails:
    'As Metas de Investimento estabelecem planos rastreáveis para colocar e agregar suas economias que são investidas em nossas principais estratégias para proporcionar os melhores retornos para metas futuras.',
  getgoals: 'Obter Metas',
  creategoalsaccount: 'Criar Conta de Metas',
  thiswillcreatecrypto:
    'Isso criará uma conta Cripto para você. Gostaria de prosseguir?',
  thiswillcreateaistock:
    'Isso criará uma conta de negociador de Ações IA para você. Gostaria de prosseguir?',
  thiswillcreateaicrypto:
    'Isso criará uma conta de negociador de Cripto IA  para você. Gostaria de prosseguir?',
  thiswillcreategoals:
    'Isso criará uma Conta de Metas para você. Gostaria de prosseguir?',
  noallocations: 'Sem alocações',
  oldpasswordcannotbethesameasnewpassword:
    'Senha antiga não pode ser idêntica à nova senha',
  thisaccountispendingapproval: 'Esta conta está pendente de aprovação',
  available: 'Disponível',
  linkaccount: 'Vincular Conta',
  accountsuccessfullylinked:
    'Conta vinculada com sucesso. Pode demorar até 2 minutos para ser ativado',
  doyouwanttolinkthisaccount: 'Deseja vincular essa conta?',
  depositcompletedsuccessfully: 'Depósito concluído com sucesso',
  withdrawcompletedsuccessfully: 'Saque concluído com sucesso',
  transfercompletedsuccessfully: 'Transferência concluída com sucesso',
  deactivateaicryptotrader: 'Desativar o Negociador de Cripto IA',
  aicryptotraderhasbeensuccessfullydeactivated:
    'Negociador de Cripto IA foi desativado com sucesso',
  totalaccountvalue: 'Valor total da conta',
  currently: 'Atualmente',
  ontrack: 'Alinhado',
  offtrack: 'Fora de linha',
  target: 'Alvo',
  deactivategoals: 'Desativar Metas',
  goalshasbeensuccessfullydeactivated: 'Metas foram desativadas com sucesso',
  documentupdatesuccessfully:
    'Documento carregado com sucesso. Você será atualizado em breve',
  failedtosenddocument: 'Falha ao enviar documento',
  relinkbankaccount: 'Revincular Conta Bancária',
  relinkbrokerageaccount: 'Revincular Conta de Corretagem',
  addbankaccount: 'Adicionar conta bancária',
  addbrokerageaccount: 'Adicionar Conta de Corretagem',
  forgotpassword: 'Esqueci a senha',
  pleaseenterphonenumber: 'Por favor, digitar o número de telefone',
  toyourinvestallaccount: 'para sua conta Investall',
  failedtoresendcode: 'Falha ao re-enviar o código',
  bidaskprice: 'Preço de Compra/Venda',
  bidaskexchange: 'Compra/Venda para Troca',
  daysrange: 'Intervalo de dias',
  time: 'Hora',
  lasttrade: 'Última Negociação',
  lasttradesize: 'Último valor de negociação',
  lasttradeexchange: 'Última negociação em Bolsa',
  showdetails: 'Exibir Detalhes',
  hidedetails: 'Ocultar Detalhes',
  youraccountisinpendingstatus: 'Sua conta está em situação pendente',
  youraccountisonliquidateonlymode:
    'Sua conta está no modo de liquidação. Entre em contato com o suporte para corrigir este problema',
  fullydilutedvaluation: 'Valorização totalmente diluída (FDV)',
  circulatingsupply: 'Oferta circulante',
  totalsupply: 'Oferta Total',
  nextearningsdate: 'Próxima data de anúncio de rendimentos',
  nextdividenddate: 'Próxima data de dividendos',
  earningsexpectedon: 'Ganhos: Esperado em',
  signintoinvestall: 'Faça login na Investall',
  thefutureofinvesting: 'Futuro em Investimentos',
  automatedprecision: 'Precisão Automatizada',
  aggregateallyouraccountsseeinsifhtsandtrendsandtrackyourexpenses:
    'Agregue todas as suas contas, veja insights e tendências e acompanhe suas despesas',
  cryptotrader: 'Negociador de criptomoedas',
  exploretheworlds:
    'Explore as principais criptomoedas do mundo, desde Bitcoin a Ethereum e outros',
  seeintothefuture: 'Enxergue o futuro',
  seeintothefutureinfo:
    'Use a Investall para prever quaisquer ações ou criptomoedas com vários prazos. Alimentada por nosso sofisticado algoritmo de IA/ML, cada previsão tem riscos e estatísticas de precisão para ajudá-lo na sua negociação.',
  pricetrendforecaster: 'Previsão de tendência de preços',
  tradewithzeroemotion: 'Negocie com zero emoção',
  tradewithzeroemotioninfo:
    'Aproveite o poder da IA e relaxe enquanto nossas ferramentas automatizadas cuidam de suas necessidades de negociação e investimento. Evite desastres de mercado e use estratégias especializadas. Selecione suas próprias ações ou cripto com Negociador IA ou escolha  Robô Investall para aplicar uma estratégia especializada. Além disso, estabeleça metas e use a Investall para alcançar a liberdade financeira.',
  automatedaiinvestor: 'Investidor automatizado IA',
  aggregateallyouraccounts: 'Agregar todas as suas contas',
  aggregateallyouraccountsinfo:
    'Comece vinculando todas as suas contas e tenha uma visão geral das suas finanças. Importe de outras corretoras, contas bancárias e cartões de crédito com um simples clique. Use nossa previsão para estimar o valor do seu imóvel e acompanhar todas as suas despesas em um só lugar.',
  seeinvestallshistoricalmarketforecast:
    'Ver a previsão histórica do mercado da Investall',
  investallshistoricalmarketforecast:
    'Previsão histórica do mercado da Investall',
  signupsuccessfulyouwillberedirectshortly:
    'Inscrição bem sucedida. Você será redirecionado em breve',
  'SHORT TERM TARGET': 'META DE CURTO PRAZO',
  'MEDIUM TERM TARGET': 'META DE MÉDIO PRAZO',
  'LONG TERM TARGET': 'META DE LONGO PRAZO',
  addacryptotoaitrader: 'Adicione uma criptomoeda ao Negociador IA',
  pleaseselectabankaccount: 'Por favor, selecione uma conta bancária',
  pleaseselectavalidstock: 'Por favor, selecione uma ação válida',
  cannotselectmorethan4stocks: 'Não é possível selecionar mais de 4 ações',
  yes: 'Sim',
  no: 'Não',
  goalplanningwilluseyourentireaccountvalue:
    'O planejamento de metas usará o valor integral da sua conta',
  investmentgoal: 'Meta de Investimento',
  yearstoaccumulate: 'Anos para acúmulo',
  investmentgoalamountmustbegreaterthaninitialinvestment:
    'A meta de investimento deve ser superior ao investimento inicial',
  investmentforyourgoal: 'Investimento para a sua Meta',
  availablebalance: 'Saldo Disponível',
  rateofreturnofinvestment:
    'Taxa de Retorno do Investimento (Com base no seu plano)',
  chooseabankaccountforperiodiccontribution:
    'Escolha uma conta bancária para contribuição periódica',
  goalpreview: 'Visualização de metas',
  startgoal: 'Iniciar meta',
  activategoals: 'Ativar metas',
  goalshasbeensuccessfullyactivated: 'As Metas foram ativadas com sucesso',
  shorttermgoallabel: 'Metas de curto prazo de 1 a 3 anos',
  shorttermgoalexample: 'Exemplo: Acumular economias emergenciais',
  shorttermgoaldescription:
    'Meta SMART: Economizar US$ 200 por mês nos próximos 12 meses',
  mediumtermgoallabel: 'Metas de Médio Prazo de 3 a 5 anos',
  mediumtermgoalexample: 'Exemplo: Comprar uma casa',
  mediumtermgoaldescription:
    'Meta SMART: Economizar US$ 10.000 nos próximos três anos para dar entrada num imóvel',
  longtermgoallabel: 'Metas de longo prazo de 5 a 10 anos',
  longtermgoalexample: 'Exemplo: Economizar para a aposentadoria',
  longtermgoaldescription:
    'Meta SMART: Investir pelo menos US$ 5.000 por ano nos meus fundos de aposentadoria nos próximos cinco anos',
  areyousureyouwanttocancelrecurringbuyof:
    'Você tem certeza que quer cancelar a compra recorrente de',
  goodfaithviolation: 'Violação de Boa Fé',
  pleaseupgradetoinvestallprotoaccessthisproduct:
    'Por favor, faça upgrade para Investall Pro para acessar este produto',
  upgradetoinvestallpro: 'Upgrade para Investall Pro',
  frequentlyaskedquestions: 'Perguntas frequentes',
  internationalwiredepositinstructions:
    'Instruções de depósito de transferência internacional',
  passwordmessage:
    'A senha deve conter pelo menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial.',
  passwordmust: 'A senha deve',
  beatleast8characters: 'ter pelo menos 8 caracteres',
  haveatleastoneuppercase: 'ter pelo menos um maiúsculo',
  haveatleastonelowercase: 'ter pelo menos um minúsculo',
  haveatleastonenumber: 'ter pelo menos um número',
  haveatleastonespecialcharacter:
    "ter pelo menos um caractere especial (-!$%^&*()_+|~='{}[]:/;<>?,.@#'\")",
  pleaseentervalidpassword: 'Digite uma senha válida',
  notavailableinchina: 'não disponível na China',
  addtowatchlist: 'Adicionar à lista de acompanhamento',
  removefromwatchlist: 'Remover da lista de acompanhamento',
  tradingandinvesting: 'Negociação/Investimento',
  investallprosubscription: 'Assinatura de Investall Pro',
  keystats: 'Estatísticas-chave',
  ratiosprofitability: 'Coeficientes/Rentabilidade',
  peratiottm: 'Relação P/E (TTM)',
  netmarginttm: 'Margem Líquida (TTM)',
  pricesalesttm: 'Preço/Receita (TTM)',
  enterprisevaluettm: 'Valor empresarial/EBITDA',
  operatingmarginttm: 'Margem operacional (TTM)',
  returnonequityttm: 'Retorno sobre o Patrimônio Líquido (TTM)',
  events: 'Eventos',
  description: 'descrição',
  news: 'Novidades',
  lowhigh: 'Baixo-Alto',
  yearrange: 'Intervalo de 52 semanas',
  pleaseselectatleastonegroup: 'Por favor, selecione pelo menos um grupo',
  addedsuccessfully: 'Adicionado com sucesso',
  createwatchlist: 'Criar lista de acompanhamento',
  nameofwatchlist: 'Nome da lista de acompanhamento',
  entername: 'Digite o nome',
  entersymbol: 'Digite o Símbolo',
  watchlistcreatesuccessfully: 'Lista de acompanhamento criada com sucesso',
  watchlistwiththisnamealreadyexists:
    'Uma lista de acompanhamento com este nome já existe',
  pleaseselectatleastonesymbol: 'Selecione pelo menos um símbolo',
  pleaseenternameofwatchlist:
    'Por favor, digite o nome desta lista de acompanhamento',
  settledcash: 'Liquidação financeira',
  cashavailableforwithdraw: 'Fundos disponíveis para saque',
  createnewwatchlist: 'Criar nova lista de acompanhamento',
  add: 'Adicionar',
  towatchlist: 'na lista de acompanhamento',
  your: 'Sua',
  accounthasbeenunlinked: 'conta foi desvinculada',
  relinktocontinue: 'Revincular para continuar',
  alreadyhaveanaccount: 'Já tem uma conta?',
  continuewithmyasaaccount: 'Continuar com minha conta ASA',
  or: 'ou',
  someofyouraccountshavebeenunlinked:
    'Algumas de suas contas foram desvinculadas.',
  oneofyouraccounthasbeenunlinked: 'Uma das suas contas foi desvinculada.',
  pleaseenteramountgreaterthan1: 'Por favor, digite valor superior a 1',
  pleaseentergreatersharesamount:
    'Por favor, insira uma quantidade maior de ações',
  cryptomaintenance:
    'Você não pode negociar criptos entre 17:30 e 18:05 EST devido à manutenção programada',
  unsettledcashavailableby: 'Fundos não compensados disponíveis por',
  downloadapkmessage: 'Disponível para usuários de Android na China',
  rsi: 'IFR',
  standard: 'Padrão',
  fibonacci: 'Fibonacci',
  getexpertstrategies: 'Obter estratégias de especialistas',
  meetallgoals: 'Cumprir todas as metas',
  automateyourtrades: 'Automatize seus negócios',
  substitute: 'Substituir',
  suggestedcomparables: 'Comparáveis sugeridos',
  stockreplacedsuccessfully: 'Ações substituídas com sucesso',

  //BUDGETING
  activity: 'Atividade',
  monthly_average_expenditure: 'Despesa Média Mensal',
  income: 'Renda',
  expenses: 'Expenses',
  ne_expense: 'Despesas não essenciais',
  based_on_an_emergency_fund_of: 'Com base em um fundo de emergência de',
  one_week: 'Uma semana',
  one_month: 'Um mês',
  three_month: 'Tres meses',
  six_month: 'Seis meses',
  total_income: 'Renda total',
  total_expense: 'Custo total',
  total: 'Custo total',
  income_n_expense: 'Renda &amp; Despesa',
  bank_fees: 'Taxas bancárias',
  entertainment: 'Entretenimento',
  food_and_drink: 'Comida &amp; Bebida',
  general_merchandise: 'General Merchandise',
  general_services: 'Serviços Gerais',
  government_and_non_profit: 'Governamentais e Sem Fins Lucrativos',
  home_improvement: 'Benfeitorias',
  loan_payments: 'Pagamentos de Empréstimos',
  medical: 'Despesas Médicas',
  personal_care: 'Cuidados Pessoais',
  rent_and_utilities: 'Aluguel &amp; Serviços Públicos',
  transportation: 'Transporte',
  travel: 'Viagens',
  transfer_in: 'Crédito de Transferência',
  transfer_out: 'Débito de Transferência',
  you_are_spending_more_this_month_than_you_do_on_average:
    'Você está gastando mais este mês do que em média',
  you_are_spending_less_this_month_than_you_do_on_average:
    'Você está gastando menos este mês do que gasta em média',
  get_your_investall_credit_score: 'Obtenha sua pontuação de crédito Investall',
  add_credit_score: 'Adicionar pontuação de crédito',
  credit_score_disclaimer: 'Isenção de pontuação de crédito',
  link_asset_or_property: 'Vincular ativo ou propriedade',
  find_your_homes_estimated_value: 'Encontre o valor estimado da sua casa',
  enter_home_estimated_value: 'Insira o valor estimado da casa',
  enter_home_estimate: 'Insira a estimativa da casa',
  enter_your_mortgage_balance: 'Insira o saldo da sua hipoteca',
  enter_mortgage_balance: 'Insira o saldo da hipoteca',
  give_your_property_a_nickname: 'Dê um apelido à sua propriedade',
  add_property: 'Adicionar propriedade',
  enter_vehicle_model: 'Digite o modelo do veículo',
  enter_vehicle_make: 'Digite a marca do veículo',
  enter_vehicle_year: 'Insira o ano do veículo',
  enter_vehicle_estimate: 'Insira a estimativa do veículo',
  enter_vehicle_loan: 'Insira a estimativa do veículo',
  vehicle_nickname: 'Apelido do veículo',
  enter_vehicle_nickname: 'Digite o apelido do veículo',
  add_vehicle: 'Adicionar veículo',
  ex_art_jewelery: 'Ex: Arte, Jóias, etc.',
  add_valuable: 'Adicione pertence',
  net_worth: 'Patrimônio líquido',
  all_budgeting_notifications: 'Todas as notificações de orçamento',
  credt: 'Crédito',
  based_on_investall_credit_rating:
    'Com base na classificação de crédito da Investall',
  credit_analysis: 'Análise de crédito',
  credit_details_message:
    'Sua pontuação de crédito depende da sua saúde financeira. Verifique as estatísticas abaixo para ver seu desempenho',
  credit_sore_added_successfully: 'Pontuação de Crédito',
  select_the_highest_education_degree_you_have:
    'Selecione seu mais alto grau de escolaridade',
  high_school: 'Ensino médio',
  bachelor: 'Bacharel',
  master: 'Mestrado',
  phd: 'Doutorado',
  others: 'Outros',
  when_was_your_first_line_of_credit_opened:
    'Quando foi aberta sua primeira linha de crédito?',
  when_did_you_start_your_current_job:
    'Quando você começou seu trabalho atual?',
  start_year: 'Ano de início',
  what_is_the_first_year_of_your_first_employment:
    'Qual é o primeiro ano do seu primeiro emprego?',
  employment_year: 'Ano de emprego',
  select_the_option_that_applies_to_you:
    'Selecione a opção que se aplica a você',
  option: 'Opção',
  renting_a_home: 'Aluga imóvel',
  own_a_mortgaged: 'Possui uma casa hipotecada',
  own_a_home: 'Proprietário de imóvel',
  next: 'proximo',
  address: 'endereco',
  mortgage_balance: 'Saldo da hipoteca',
  external_accounts: 'Contas externas',
  depository: 'Depositário',
  brokerages: 'Corretoras',
  properties: 'Propriedades',
  vehicles: 'Veículos',
  valuables: 'Objetos de valor',
  transactions: 'Transações',
  add_account: 'Adicionar Conta',

  view_trends: 'Ver tendências',
  insights: 'introspecção',
  balance: 'Saldo',
  physical_assets: 'Ativos físicos',
  liabilities: 'Passivos',
  credit_cards: 'Cartões de crédito',
  loans: 'Empréstimos',
  enter_your_valuable_details: 'Insira seus valores',
  name: 'Nome',
  loan_balance: 'Insira seus valores',
  estimated_value: 'Valor estimado',
  enter_nickname: 'Digite o apelido',
  select_education: 'Selecionar educação',
  credit: 'Crédito',
  vehicle_model: 'Modelo do veículo',
  account_number: 'Número da conta',
  emergency_funds: 'Fundos de Emergência',
  available_funds: 'Fundos disponíveis',
  essential: 'Essencial',
  non_essential: 'Não essencial',
  info_credit_score:
    'A pontuação de crédito é calculada a partir de informações sobre todas as suas contas vinculadas, em uma escala de 300 a 850. A razão pela qual sua pontuação de crédito Investall é diferente de suas outras pontuações de crédito é que essa pontuação é agregada usando um algoritmo avançado de IA que leva todas as',
  add_an_account: 'Adicionar uma conta',
  link_bank_account: 'Vincular conta bancária',
  link_brokerage_account: 'Link brokerage account',
  add_an_asset: 'Adicionar um recurso',
  add_a_property: 'Add a property',
  add_a_vehicle: 'Adicionar um veículo',
  add_other_valuable: 'Adicione outros ativos',
  enter_full_address: 'Digite o endereço completo',
  vehicle_make: 'Marca do veículo',
  vehicle_year: 'Ano do veículo',
  no_transactions: 'Nenhuma transação',
  please_subscribe_to_access_this_feature:
    'Inscreva-se para acessar este recurso',
  link_digital_wallet: 'Vincular carteira digital',
  enter_wallet_address: 'Digite o endereço da carteira',
  add_wallet: 'Adicionar carteira',
  successfully_added_account: 'Successfully added account',
  edit_asset: 'Editar recurso',
  six_mo_expenses: '(6 meses de despesas).',
  you_have_recommended_cash_reserve_for_emergency:
    'Você recomendou reserva de dinheiro para emergências',
  you_are_below_recommended_cash_reserved_for_emergencies:
    'Você está abaixo do dinheiro recomendado reservado para emergências',
  remove_account: 'Remover conta',
  account_removed_successfully: 'Conta removida com sucesso',
  remove_account_msg:
    'Isso removerá todas as outras subcontas associadas a esta conta. Deseja remover esta conta?',
  remove_asset_msg:
    'Isso removerá todas as outras informações associadas a este ativo. Deseja remover este ativo?',
  last_month_expenditure: 'Despesas do último mês',
  fund_account: 'Depositar em sua conta',
  investall_pro: 'Investall Pro',
  sub_message:
    'Atualize para ter acesso a ferramentas e recursos premium. Inicie uma avaliação gratuita por 30 dias e pague US$ 3,99 por mês depois disso.',
  budgeting_subs_info:
    'Agregue todas as suas contas em um só lugar para obter seu patrimônio líquido. Faça uma estimativa do valor do seu imóvel e muito mais.',
  ai_trader_stock_n_crypto: 'AI Trader - Ações e criptomoedas',
  ai_trader_stock_n_crypto_sub_info:
    'Selecione até quatro ações ou criptomoedas para negociar automaticamente usando algoritmos avançados com previsão de ML.',
  goals_sub_info:
    'Acompanhe seus gastos e veja onde você pode economizar mais. Nossas tendências e insights fornecem gráficos e alertas intuitivos para mantê-lo informado sobre seu fluxo de caixa.',
  free_trial_msg:
    'A avaliação gratuita de um mês começará imediatamente após a assinatura. Você será cobrado $ 3,99 após 30 dias para o mês seguinte.',
  congratulations: 'Parabéns',
  activity_trends: 'Atividade e tendências',
  activity_trends_info:
    'Acompanhe seus gastos e veja onde você pode economizar mais. Nossas tendências e insights fornecem gráficos e alertas intuitivos para mantê-lo informado sobre seu fluxo de caixa.',
  manage_subscription: 'Gerenciar assinatura',
  you_have_successfully_unsubscribed: 'Você cancelou a inscrição com sucesso',
  successfully_unsubscribed: 'Cancelado com Sucesso',
  self_trading_accounts: 'Contas de autonegociação',
  automated_products: 'Produtos automatizados',
  explore: 'Explorar',

  //SEND TO HUMBERTO
  successfully_added_wallet: 'Carteira adicionada com sucesso',
  remove_asset: 'Remover recurso',
  digital_wallets: 'Carteiras Digitais',
  this_month: 'Este mês',
  average: 'Média',
  below_averge: 'Abaixo da média',
  good: 'Bom',
  excellent: 'Excelente',
  download_app_text:
    'Obtenha o aplicativo Investall para o seu dispositivo móvel abaixo. Também disponível como download APK para usuários internacionais.',
  you_have_spent_less_last_month:
    'Você gastou menos no mês passado do que costuma gastar em média em 6 meses',
  you_have_spent_more_last_month:
    'Você gastou mais no mês passado do que costuma gastar em média em 6 meses',
  you_have_spent_the_same_amount:
    'Você gastou o mesmo valor no mês passado que sua média de 6 meses',
  'Net Worth': 'Patrimônio líquido',
  'Liquidity Ratio': 'Taxa de liquidez',
  'Solvency Ratio': 'Coeficiente de solvabilidade',
  'Debt/Income Ratio': 'Relação dívida/renda',
  'Debt/Asset Ratio': 'Relação Dívida/Ativo',
  'Non-Essential Expense Ratio': 'Índice de Despesas Não Essenciais',
  'Essential Expense Ratio': 'Índice de Despesa Essencial',
  'Emergency Reserve Required': 'Reserva de Emergência Necessária',
  'Saving Ratio': 'Taxa de economia',
  'Mortgage Ratio': 'Índice de Hipoteca',
  'Life Insurance Ratio': 'Índice de Seguro de Vida',
  'Retirement Saving Ratio': 'Índice de Poupança de Aposentadoria',
  'Investing Ratio': 'Índice de Investimento',

  //SENT
  this_wallet_does_not_own_any_nfts: 'This wallet does not own any NFTs',
  powered_by_honely: 'Powered by Honely',
  get_personalized_dashboard_with_your_full_financial_picture:
    'Get a personalized dashboard with your full financial picture.',
  connect_your_accounts_to_investall_msg:
    'Connecting your accounts to Investall allows you to see a complete view of your portfolio in an intuitive interface so you can make better decisions.  See notifications about important events and access automated trading tools.',
  get_insights_on_all_your_accounts: 'Get insights on all your accounts',
  our_ai_aggregates_message:
    'Our AI aggregates the current value of your net worth accross all your assets and liabilities. Easily connect a home property and use our AI to determine current value.',
  my_assets_and_income_managed_regularly:
    'My assets and income managed regularily',
  check_the_current_msg:
    'Check the current price estimates of your home and other assets using our embeded forecasting AI. Check your income activity with trends and insights.',
  know_your_net_worth: 'Know your net worth',
  our_ai_aggregates_the_current_value_msg:
    'Our AI aggregates the current value of your net worth accross all your assets and liabilities. Keeping tabs has never been easier with smart alerts and the activity panel. Easily connect a home property and use our AI to determine current value.',
  buy_fractional_shares_msg:
    'Buy fractional shares with as little as $1 and zero comission',
  automate_trading_with: 'Automate trading with',
  upgraded_tools: 'Upgrade Tools',
  trades_are_automatically_msg:
    'Trades are automatically placed based on momentym shifts - a  combination of over a hundred thousand factors. Our AI optimizes trade orders to best lower risk and reap the reward',
  automate_your_trading_msg:
    'Automate your trading with up to four stocks or cryptocurrencies.',
  its_important_to_set_goals_msg:
    'It’s important to set goals for your financial future. Whether it’s for your next mortgage or a family vacation. Define your goals, visualize and reach for your target in short, medium or long term time frames.',
  free: 'Free',
  get_robo_for_automated_trading_msg:
    'Get Robo for automated trading, see all our Forecast data, Forecast Screener and use Self Trading of Stocks and Crypto with as little as $1 and zero comission.',
  download: 'Download',
  forecasting: 'Forecasting',
  forecast_screener: 'Forecast Screener',
  fractional_trading: 'Fractional trading',
  all_free_features: 'All free features',
  upgrade: 'Upgrade',
  per_month: 'per month',
  everything_from_free_version_msg:
    'Everything from free version with upgraded tools including AI Trader Stocks & Crypto, Goals and the comprehensive Budgeting dashboard.',
  initial_amount: 'Initial Amount',
  contribution_amount: 'Contribution Amount',
  investment_period_30_years: 'Investment period: 5 years',
  trusted_by_institutional_traders: 'Used by Institutions',
  audited_track_record_msg:
    'Democratizing RIA strategies for consumers. Simply fill out a questionaire and Investall will determine a strategy that is right for you. ',
  other_top_robo_advisors_msg:
    'Other top robo advisors, now available to the masses. ',
  tailored_to_your_choices: 'Tailored to your choices',
  complete_an_investment_msg:
    'Complete an investment questionaire and our AI picks the best strategy for your account. Montly rebalancing based on the current market.',
  highly_liquid: 'Highly Liquid',
  keep_full_custody_msg:
    'Keep full custody of your investment account. Change your mind with ease, get your money when you need it. ',
  investall_robo: 'Investall Robo',
  financial: 'Financial',
  revenue: 'Revenue',
  eps: 'Eps',
  ebitda: 'ebitda',
  quarter: 'Quarter',

  //SENT 2
  promo_applied_successfully: 'Promo applied successfully',
  user_is_an_already_existing_user:
    'User is an already existing user or has already applied this promotion',
  invalid_promo_code: 'Invalid promo code',
  enter_promo_code: 'Enter promo code',
  please_enter_promo_code: 'Please enter promo code',
  my_code: 'My code',
  promo_code: 'Promo code',
  rewards: 'Rewards',
  promotions: 'Promotions',
  no_rewards_available: 'No rewards available',
  you_have_been_subscribed_to_a_new_reward:
    'You have been subscribed to a new reward',
  eligibility: 'Eligibility',
  eligibility_not_met: 'Eligibility not met',
  success: 'Success',
  requirements_not_met: 'Requirements not met',
  reward_amount: 'Reward amount',
  must_be_a_new_user: 'Must be a new user',
  deposit_minimum_100_dollars: 'Deposit a minimum of $100 dollars',
  welcome_to_investall: 'Welcome to Investall',
  get_20_free_with_and_initial_deposit_of_100:
    'Get $20 free with and initial deposit of $100',
  download_the_app: 'Download App',
  open_an_account: 'Open an account',
  make_an_minimum_initial_deposit_of_100:
    'Make a minimum initial deposit of $100',
  use_faares_code: 'The following code is automatically applied: FAARES',
  faares_has_invited_you_to: 'FAARES has invited you to',
  why_investall: 'Why Investall',
  trade_stocks_etfs_and_crypto_msg:
    'Trade stocks, ETFs and Crypto currencies with as little as $1 and 0 commission.',
  use_expert_strategyies_msg:
    'Use expert strategies for automated investing. Get Investasll Robo when you sign up, and upgrade to use AI Trader and Goals.',
  accessible_on_multi_platform_msg:
    'Accessible on multi-platforms including iOS, Android, APK and Web APP.',
  complimentary_upgrade_to: 'Complimentary upgrade to',
  receive_one_month_subscription_msg:
    'Receive 1-month subsctiption to Investall Pro from the day you sign up with us!',
  forecast_achieved: 'Forecast Achieved',
  forecasted_date: 'Forecasted date',
  forecasted_price: 'Forecasted price',
  subscription_disclaimer:
    'LEIA ESTA DECLARAÇÃO COM ATENÇÃO. AO FAZER DOWNLOAD OU USAR NOSSO APLICATIVO, VOCÊ CONCORDA EM ESTAR VINCULADO AOS TERMOS E CONDIÇÕES ABAIXO. A versão gratuita de nosso aplicativo é destinada apenas a residentes dos EUA. Se você é um usuário internacional com um saldo de conta de mais de US$ 50, será cobrada uma taxa de plataforma de US$ 3,99. Esta taxa será cobrada automaticamente no saldo da sua conta. Reservamos o direito de modificar esta declaração a qualquer momento e sem aviso prévio. Tais modificações serão efetivas imediatamente após serem publicadas no aplicativo. Você concorda em revisar esta declaração periodicamente para ficar ciente de quaisquer tais modificações. O uso continuado de nosso aplicativo por você será considerado como sua aceitação da declaração modificada. Se você tiver alguma dúvida sobre esta declaração, entre em contato conosco.',

  //TRANSLATE
  financial_forecast: 'Financial forecast',
  price_forecast: 'Price forecast',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
}
