import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

class MobileAppPromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChinese: this.props.isChinese
    };
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={3} className="mobile-app-phone-img d-none d-md-flex">
            <img src = "/images/mobilePromoSingle.png" />
          </Col>
          <Col md={5} className="mobile-app-promo-text order-md-2 order-2 mt-4 mt-md-0">
            <p>Download AllocateRite mobile app to help you better manage your investment accounts with AI-powered cutting-edge tools.</p>
            <p>Easily link your brokerage account to our AI-powered analytics tools.</p>
            <p>RiskMonkey&trade;, cutting-edge AI-based risk platform.</p>
            <p><strong>Suitable for 401k, Retirement</strong></p>
          </Col>
          <Col md={4} className="mobile-app-phone-img order-md-3 order-1">
            <img src = "/images/mobilePromoMulti.png" className = "d-none d-md-inline" />
            <img className = "mobile-app-phone-img-multi d-inline d-md-none" src = "/images/mobilePromoMulti.png" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MobileAppPromo;
