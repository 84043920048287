import { Button } from 'primereact/button'
import '../scss/components/SubscriptionCard.scss'
import React, { useState } from 'react'
import Protection from '../assets/submodal/protection.svg'
import { ConfirmDialog } from 'primereact/confirmdialog'
import BudgetingSub from '../assets/submodal/budgeting-sub.svg'
import BudgetingSub2 from '../assets/submodal/budgeting2-sub.svg'
import AiTraderSub from '../assets/submodal/ai-trader-stock-sub.svg'
import GoalsSub from '../assets/submodal/goals-sub.svg'
import { Dialog } from 'primereact/dialog'

export default function SubscriptionCard(props) {
  const [loading, setLoading] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [showSubCard, setShowSubCard] = useState(false)
  const getMessage = (message) => {
    switch (message) {
      case 'subscribed':
        return 'PRO'
      case 'pro':
        return 'PRO'
      case 'active':
        return 'PRO'
      case 'problems':
        return 'UNLINKED'
      case 'trialing':
        return '3 MONTHS FREE TRIAL'
    }
  }

  const callAction = async () => {
    setLoading(true)
    const response = await props.downgradeSub()
    if (response) {
      setLoading(false)
      // setShowDialog(true)
      window.location.reload(false)
    } else {
      setLoading(false)
    }
  }

  if (props.t) {
    return (
      // <div className='subscription-card'>
      //   <div className='subscription-row'>
      //     <p className='subscription-label'>{props.t('status')}: </p>
      //     <p className='subscription-value highlight'>
      //       {props.subStatusMessage ? getMessage(props.subStatusMessage) : '-'}
      //     </p>
      //   </div>
      // <div className='subscription-row'>
      //   <p className='subscription-label'>{props.t('autorenewson')}: </p>
      //   <p className='subscription-value'>
      //     {props.subStatusExpirationDate
      //       ? props.subStatusExpirationDate
      //       : '-'}
      //   </p>
      // </div>
      //   <div className='downgrade-btn-container'>
      //     <Button
      //       label={props.t('downgradetobasic')}
      //       className='downgrade-btn'
      //       onClick={() => {
      //         callAction()
      //       }}
      //       loading={loading}
      //     />
      //   </div>
      // </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2%',
          paddingBottom: '2%',
          borderRadius: 10,
          width: '100%',
          margin: 'auto',
        }}
      >
        <div
          style={{
            borderRadius: 10,
            padding: 25,
            backgroundColor: '#3F46F6',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              color: 'rgb(255,255,255)',
              fontSize: 30,
              textAlign: 'center',
              fontFamily: 'Akkurat-Bold',
            }}
          >
            {props.t('investall_pro')}
          </p>
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 20,
              textAlign: 'center',
              letterSpacing: '0.01em',
              color: '#DADCFF',
              margin: 0,
            }}
          >
            {props.t('congratulations')}!
          </p>
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 20,
              textAlign: 'center',
              letterSpacing: '0.01em',
              color: '#DADCFF',
            }}
          >
            {props.t('sub_message')}
          </p>
        </div>
        <div style={{ marginTop: 15 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={AiTraderSub}
              style={{ width: 50, height: 45, marginRight: 25, marginTop: 5 }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {props.t('ai_trader_stock_n_crypto')}
              </p>
              <p style={{ fontSize: 15 }}>
                {props.t('ai_trader_stock_n_crypto_sub_info')}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={BudgetingSub2}
              style={{ width: 50, height: 45, marginRight: 25, marginTop: 5 }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {props.t('budgeting')}
              </p>
              <p style={{ fontSize: 15 }}>{props.t('budgeting_subs_info')}</p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={BudgetingSub}
              style={{ width: 50, height: 45, marginRight: 25, marginTop: 5 }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {props.t('activity_trends')}
              </p>
              <p style={{ fontSize: 15 }}>{props.t('activity_trends_info')}</p>
            </div>
          </div>
        </div>
        <div className='subscription-row'>
          <p className='subscription-label'>{props.t('autorenewson')}: </p>
          <p className='subscription-value'>
            {props.subStatusExpirationDate
              ? props.subStatusExpirationDate
              : '-'}
          </p>
        </div>
        <p
          style={{ fontSize: 15, textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setShowSubCard(true)
          }}
        >
          {props.t('manage_subscription')}
        </p>

        <Dialog
          visible={showSubCard}
          header={props.t('manage_subscription')}
          onHide={() => setShowSubCard(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '30vw',
            height: props.isSmallScreen ? '90vh' : '30vh',
          }}
        >
          <div className='subscription-card'>
            <div className='subscription-row'>
              <p className='subscription-label'>{props.t('status')}: </p>
              <p className='subscription-value highlight'>
                {props.subStatusMessage
                  ? getMessage(props.subStatusMessage)
                  : '-'}
              </p>
            </div>
            <div className='subscription-row'>
              <p className='subscription-label'>{props.t('autorenewson')}: </p>
              <p className='subscription-value'>
                {props.subStatusExpirationDate
                  ? props.subStatusExpirationDate
                  : '-'}
              </p>
            </div>
            <div className='downgrade-btn-container'>
              <Button
                label={props.t('downgradetobasic')}
                className='downgrade-btn'
                onClick={() => {
                  callAction()
                }}
                loading={loading}
              />
            </div>
          </div>
        </Dialog>
      </div>
    )
  } else return null
}
